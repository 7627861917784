import React, { useState } from 'react';

interface TabProps {
  title: string;
  omit?: boolean;
  children: React.ReactNode;
}

interface TabListProps {
  children: React.ReactElement<TabProps>[];
  defaultActiveTab?: number;
  activeTab?: number;
  setActiveTab?: (index: number) => void;
}

const TabList = ({
  children,
  defaultActiveTab = 0,
  activeTab,
  setActiveTab,
}: TabListProps) => {
  const [internalActiveTab, setInternalActiveTab] = useState(defaultActiveTab);
  const activeTabState = activeTab ?? internalActiveTab;
  const setActiveTabState = setActiveTab ?? setInternalActiveTab;

  return (
    <div className="w-full mx-auto mt-2 mr-2">
      <div className="inline-flex gap-4">
        {children
          .filter((t) => !t.props.omit)
          .map((tab, index) => (
            <button
              key={index}
              className={`py-2 px-3 text-sm font-medium rounded ${
                index === activeTabState
                  ? 'bg-[#42389d] text-white'
                  : 'bg-neutral-100 text-gray-600'
              }`}
              onClick={() => setActiveTabState(index)}
            >
              {tab.props.title}
            </button>
          ))}
      </div>
      <div className="p-4">
        {children.filter((t) => !t.props.omit)[activeTabState]
          ? children.filter((t) => !t.props.omit)[activeTabState].props.children
          : null}
      </div>
    </div>
  );
};

const Tab = ({ children }: TabProps) => {
  return <div className="tab">{children}</div>;
};

const Tabs = {
  TabList,
  Tab,
};

export default Tabs;
