import { useEffect, useRef, useState } from 'react';
import environment from '../../../../../services/environment';
import { useHistory, useParams } from 'react-router';
import SecondaryTapsLayout from '../../../../../layouts/secondaryTapsLayout';
import { surveySettingTabs } from '../data';
import { programsIcon } from '../../../../../assets/icons/Index';
import NewTable from '../../../../../components/NewTable';
import {
  axiosPostRequest,
  get,
  post,
  put,
} from '../../../../../services/Request';
import { useTranslation } from 'react-i18next';
import {
  formatDate,
  isDefined,
  permissionsCheck,
} from '../../../../../helpers/utils';
import { faListDots, faPen } from '@fortawesome/free-solid-svg-icons';
import {
  DropdownMultiSelect,
  FModal,
  InputLabel,
  SModal,
  Title,
} from '../../../../../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NewTooltip from '../../../../../components/NewTooltip';
import NewTimer from '../../../../../components/NewTimer';
import { toast } from 'react-toastify';
import { ErrorMessage, Formik } from 'formik';
import { NewForm } from '../../../../../components/form/NewForm';
import useAxiosGetRequest from '../../../../../helpers/customHooks/useAxiosGetRequest';
import NewSwitch from '../../../../../components/NewSwitch';
import AppModal from '../../../../../components/Models/appModal';
import { useQuery } from '@tanstack/react-query';

const ServeyPhases = () => {
  const history = useHistory();
  const { surveyId, indicatorId } = useParams();
  const { t } = useTranslation();
  const [message, setMessage] = useState({});
  const [open, setOpen] = useState(false);
  const [openExtend, setOpenExtend] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingModel, setLoadingModel] = useState(false);
  const [stages, setStages] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(null);
  const [id, setId] = useState(0);
  const [title, setTitle] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [noticePeriod, setNoticePeriod] = useState(null);
  const [standards, setStandards] = useState([]);
  const [selectedStandard, setSelectedStandard] = useState([]);
  const [selectedAgencies, setSelectedAgencies] = useState([]);
  const [hoursCount, setHoursCount] = useState(0);
  const [headerTitle, setHeaderTitle] = useState('');

  const { isFetching: isStandardLoading } = useQuery({
    queryKey: [],
    queryFn: getAllStandards,
    enabled: !!openExtend,
    refetchOnWindowFocus: false,
  });

  const columns = [
    {
      name: 'المرحلة',
      selector: (row) => row.name,
      header: 'name',
      accessorKey: 'name',
      footer: (props) => props.column.id,
      cell: (row) => (
        <div className="text-slate-400 text-[15px] text-right">{row.title}</div>
      ),
    },
    {
      name: 'تاريخ البداية',
      selector: (row) => row.name,
      header: 'name',
      accessorKey: 'name',
      center: true,
      footer: (props) => props.column.id,
      cell: (row) => (
        <div className="text-slate-400 text-[15px] text-right">
          {formatDate(new Date(row.startDate))}
        </div>
      ),
    },
    {
      name: 'تاريخ النهاية',
      selector: (row) => row.name,
      header: 'name',
      accessorKey: 'name',
      center: true,
      footer: (props) => props.column.id,
      cell: (row) => (
        <div className="text-slate-400 text-[15px] text-right">
          {formatDate(new Date(row.endDate))}
        </div>
      ),
    },
    {
      name: 'الحالة',
      selector: (row) => row.name,
      header: 'name',
      accessorKey: 'name',
      center: true,
      footer: (props) => props.column.id,
      cell: (row) => (
        <div className="text-slate-400 text-[15px] text-right">
          {getStatusText(row)}
        </div>
      ),
    },
    // ------------------------------//
    {
      name: 'الوقت المتبقي لفتح المرحلة',
      selector: (row) => row.name,
      header: 'name',
      accessorKey: 'name',
      center: true,
      footer: (props) => props.column.id,
      cell: (row) => {
        const [timeUntilStart] = NewTimer({
          startDate: row.startDate,
          endDate: row.endDate,
        });
        return (
          <div className="text-slate-400 text-[15px]">{timeUntilStart}</div>
        );
      },
    },
    {
      name: 'الوقت المتبقي لاٍغلاق المرحلة',
      selector: (row) => row.name,
      header: 'name',
      accessorKey: 'name',
      center: true,
      footer: (props) => props.column.id,
      cell: (row) => {
        const [, timeUntilEnd] = NewTimer({
          startDate: row.startDate,
          endDate: row.endDate,
        });
        return <div className="text-slate-400 text-[15px]">{timeUntilEnd}</div>;
      },
    },
    // ------------------------------//
    {
      name: t('modules.main.mailbox.actions'),
      selector: (row) => row.id,
      header: 'id',
      accessorKey: 'id',
      center: true,
      footer: (props) => props.column.id,
      cell: (row) =>
        permissionsCheck('Pages.UserType.Admin') && (
          // ((new Date(row.startDate) < new Date() &&
          //   new Date(row.endDate) >= new Date()) ||
          //   (new Date(row.startDate) < new Date() &&
          //     new Date(row.endDate) < new Date())) &&
          <div className="flex gap-1">
            <NewTooltip content={'تعديل'}>
              <FontAwesomeIcon
                icon={faPen}
                className="text-yellow-400 bg-orange-100 mr-1 rounded-full p-2 cursor-pointer"
                onClick={(e) => editStage(row)}
              />
            </NewTooltip>
            <NewTooltip content={'تمديد الفترة'}>
              <FontAwesomeIcon
                icon={faListDots}
                className="text-yellow-400 bg-orange-100 mr-1 rounded-full p-2 cursor-pointer"
                onClick={(e) => extendStage(row)}
              />
            </NewTooltip>
          </div>
        ),
      // ),
    },
  ];

  useEffect(() => {
    getAllList(0);
    // Moved to use Query we can delete
    // getAllStandards();
    getAllAgencies(environment.getAllAgencyWithoutPagination);
  }, []);

  const getAllList = (page) => {
    setStages([]);
    setCurrentPage(page);
    getAllStages(page);
  };

  const getAllStages = (page) => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    get(
      environment.getSurveyStagesBySuveyId + '?surveyId=' + surveyId,
      config,
      (res) => {
        if (res.status == 403) {
          setMessage({ type: 'error', message: t('general.authError') });
          setOpenModal(true);
          setLoading(false);
        } else if (res.status == 500) {
          setMessage({ type: 'error', message: t('general.serverError') });
          setOpenModal(true);
          setLoading(false);
        } else if (res.status == 200) {
          setStages(res.data.result.items);
          setTotalPages(Math.ceil(res.data.result.totalCount / 10));
          setTimeout(() => {
            setLoading(false);
          }, 500);
        }
      }
    );
  };

  function getAllStandards() {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    return get(environment.getStandardsLookUp, config, (res) => {
      if (res.status == 403) {
        setMessage({ type: 'error', message: t('general.authError') });
        setOpenModal(true);
      } else if (res.status == 500) {
        setMessage({ type: 'error', message: t('general.serverError') });
        setOpenModal(true);
      } else if (res.status == 200) {
        if (res.data.result && res.data.result.length > 0) {
          var array = [];
          for (var i = 0; i < res.data.result.length; i++) {
            array.push({
              value: res.data.result[i].id,
              label: res.data.result[i].title,
              isSelected: false,
            });
          }
          setStandards(array);
        }
      }
    });
  }

  const [getAllAgencies, allAgencies] = useAxiosGetRequest();

  useEffect(() => {
    getAllAgencies(environment.getAllAgencyWithoutPagination);
  }, []);

  const allAgenciesOptions =
    allAgencies?.result &&
    allAgencies?.result?.map((item) => {
      return {
        label: item.nameInArabic || '',
        value: item.id,
        name: item.name || '',
      };
    });

  const handlePageClick = (event) => {
    const newOffset = event.selected;
    setCurrentPage(newOffset);
    getAllList(newOffset);
  };

  function getStatusText(row) {
    var statusName = '';
    if (
      new Date(row.startDate) < new Date() &&
      new Date(row.endDate) >= new Date()
    ) {
      statusName = t('الحالية');
    } else if (
      new Date(row.startDate) > new Date() &&
      new Date(row.endDate) > new Date()
    ) {
      statusName = t('لم تبدء');
    }
    if (
      new Date(row.startDate) < new Date() &&
      new Date(row.endDate) < new Date()
    ) {
      statusName = t('انتهت');
    }
    return statusName;
  }

  const addStage = () => {
    setHeaderTitle('اٍضافة مرحلة');
    setOpen(true);
    setId(0);
    setTitle('');
    setStartDate('');
    setEndDate('');
    setNoticePeriod('');
  };

  const editStage = (row) => {
    setHeaderTitle('تعديل المرحلة');
    setOpen(true);
    setId(row.id);
    setTitle(row.title);
    setStartDate(row.startDate.split('T')[0]);
    setEndDate(row.endDate.split('T')[0]);
    setNoticePeriod(row.noticePeriod);
  };

  const extendStage = (row) => {
    setOpenExtend(true);
    setId(row.id);
    setHoursCount(0);
    setSelectedStandard([]);
    setSelectedAgencies([]);
  };

  const handleChangeTitle = (e) => {
    setTitle(e.target.value);
  };

  const handleChangeStartDate = (e) => {
    setStartDate(e.target.value);
  };

  const handleChangeEndDate = (e) => {
    setEndDate(e.target.value);
  };

  const handleNoticePeriodChange = (e) => {
    setNoticePeriod(e.target.value);
  };

  const handleChangeHoursCount = (e) => {
    setHoursCount(e.target.value);
  };

  const handleChangeSelectedStandard = (value) => {
    setSelectedStandard(value);
  };

  const handleChangeSelectedAgencies = (value) => {
    setSelectedAgencies(value);
  };

  const saveStage = () => {
    if (id == 0) {
      createStage();
    } else {
      updateStage();
    }
  };

  const createStage = () => {
    setLoadingModel(true);
    if (!title) {
      toast.error(t('لابد من ادخال اسم المرحلة'));
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    let data = {
      title: title,
      startDate: startDate,
      endDate: endDate,
      noticePeriod: noticePeriod,
    };
    post(
      `${environment.createSurveyStage}?surveyId=${surveyId}`,
      data,
      config,
      (res) => {
        setLoadingModel(false);
        if (res.status == 403) {
          toast.error(t('general.authError'));
        } else if (res.status == 500) {
          toast.error(t('general.serverError'));
        } else if (res.status == 200) {
          toast.success('تم اٍضافة مرحلة جديدة بنجاح');
          getAllList(0);
          setOpen(false);
        }
      }
    );
  };

  const updateStage = () => {
    setLoadingModel(true);
    if (!title) {
      toast.error(t('لابد من ادخال اسم المرحلة'));
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    let data = {
      surveyStageId: id,
      surveyId: surveyId,
      title: title,
      startDate: startDate,
      endDate: endDate,
      noticePeriod: noticePeriod,
    };
    put(environment.updateSurveyStage, data, config, (res) => {
      setLoadingModel(false);
      if (res.status == 403) {
        toast.error(t('general.authError'));
      } else if (res.status == 500) {
        toast.error(t('general.serverError'));
      } else if (res.status == 200) {
        toast.info('تم تحديث المرحلة بنجاح');
        getAllList(0);
        setOpen(false);
      }
    });
  };

  const saveExtendStage = () => {
    setLoadingModel(true);

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    let data = {
      surveyStageId: id,
      standardsIds:
        selectedStandard?.map((x) => {
          return x.value;
        }) || [],
      agencyIds:
        selectedAgencies?.map((x) => {
          return x.value;
        }) || [],
      hoursCount: hoursCount,
    };
    post(environment.extendAgencyAnswerStage, data, config, (res) => {
      setLoadingModel(false);
      if (res.status == 403) {
        toast.error(t('general.authError'));
      } else if (res.status == 500) {
        toast.error(t('general.serverError'));
      } else if (res.status == 200) {
        toast.info('تم تمديد المرحلة بنجاح');
        getAllList(0);
        setOpenExtend(false);
      }
    });
  };

  // ---------------------------------------------- //
  const formRef = useRef(null);

  const handleSubmitAdd = () => {
    if (formRef.current) {
      formRef.current?.handleSubmit();
    }
  };

  // --------------------------------------------- //
  const formRefEdit = useRef(null);

  const handleFormik = () => {
    if (formRefEdit.current) {
      formRefEdit.current?.handleSubmit();
    }
  };

  const initialValues = {
    hoursCount: 0,
    selectedStandard: [],
    selectedAgencies: [],
  };

  const validate = (values) => {
    const errors = {};
    if (hoursCount < 0) {
      errors.hoursCount = 'هذا الحقل مطلوب';
    }
    if (!selectedStandard || !selectedStandard.length) {
      errors.selectedStandard = 'هذا الحقل مطلوب';
    }
    if (!selectedAgencies || selectedAgencies.length == 0) {
      errors.selectedAgencies = 'هذا الحقل مطلوب';
    }
    return errors;
  };

  // ----------------------------------------------------- //
  const [publish, setPublish] = useState(false);
  const [openpublish, setOpenPublish] = useState(false);
  const [publishLoading, setPublishLoading] = useState(false);

  const handlePublish = async () => {
    setPublishLoading(true);
    const res = await axiosPostRequest(
      environment.addProgram,
      null,
      {
        surveyId: surveyId,
      },
      false
    );
    setPublishLoading(false);
    if (res?.success) {
      toast.success('تم نشر هذا الاستبيان بنجاح');
      setOpenPublish(false);
    } else {
      toast.error(res?.response?.data?.error?.message);
    }
  };

  return (
    <SecondaryTapsLayout
      linksArr={surveySettingTabs(
        `/indicators/${indicatorId}/surveys/${surveyId}`
      )}
      breadcrumbs={
        <div className="-mx-5">
          <Title
            titleButton={t('إضافة مرحلة')}
            iconTitle={programsIcon}
            thired={true}
            onClick={addStage}
            subTitleSeconed={'المراحل'}
            subTitle={'اعدادات الاستبيان'}
            title={t('المؤشرات')}
            toPage={() => history.push(`/indicators`)}
            toPageSeconed={() => history.push(`/indicators/${indicatorId}`)}
            withoutButton={true}
          />
        </div>
      }
      bgWhite
    >
      <div className="bg-gray_light p-6 flex justify-between items-center rounded-lg">
        <h3 className="text-blue_text text-lg">{t('المراحل')}</h3>
        <div className="flex justify-end flex-grow">
          <button
            className=" text-white py-2 pb-4 px-3 min-w-[140px] pt-1 mt-0 rounded-full bg-blue hover:bg-blue_text"
            onClick={addStage}
          >
            {t('إضافة مرحلة')}
          </button>
        </div>
      </div>
      <div>
        <NewTable
          loading={loading}
          columns={columns}
          data={stages}
          pagination={false}
          totalPages={totalPages}
          onPageChange={handlePageClick}
          currentPage={currentPage}
        />

        <div className="mt-7">
          <NewSwitch
            value={publish}
            setValue={setPublish}
            label={'هل تريد نشر هذا الاستبيان؟'}
            onsubmit={() => setOpenPublish(true)}
          />
        </div>

        <SModal
          type={message.type}
          open={openModal}
          setOpen={() => setOpenModal(false)}
          subTitle={message.message}
        />
        <FModal
          loading={loadingModel}
          headerTitle={headerTitle}
          content={
            <Formik
              innerRef={formRef}
              initialValues={{
                title: '',
                startDate: '',
                endDate: '',
                noticePeriod: null,
              }}
              validate={(values) => {
                const errors = {};
                if (!title) {
                  errors.title = 'هذا الحقل مطلوب';
                }
                if (!startDate) {
                  errors.startDate = 'هذا الحقل مطلوب';
                }
                if (!endDate) {
                  errors.endDate = 'هذا الحقل مطلوب';
                }
                if (!isDefined(noticePeriod)) {
                  errors.noticePeriod = 'هذا الحقل مطلوب';
                } else if (isNoticePeriodLessThanOne(noticePeriod)) {
                  errors.noticePeriod = 'يجب ان يكون عدد الايام اكبر من 1';
                }

                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                saveStage();
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <div className="flex flex-col gap-3">
                  <div>
                    <InputLabel
                      label={t('المرحلة')}
                      type={'text'}
                      value={title}
                      name={'title'}
                      onChange={handleChangeTitle}
                    />
                    <ErrorMessage
                      className="text-red-700"
                      name="title"
                      component="div"
                    />
                  </div>

                  <div>
                    <InputLabel
                      label={t('تاريخ البداية')}
                      type={'date'}
                      value={startDate}
                      name={'startDate'}
                      onChange={handleChangeStartDate}
                    />
                    <ErrorMessage
                      className="text-red-700"
                      name="startDate"
                      component="div"
                    />
                  </div>

                  <div>
                    <InputLabel
                      label={t('تاريخ النهاية')}
                      type={'date'}
                      value={endDate}
                      name={'endDate'}
                      onChange={handleChangeEndDate}
                    />
                    <ErrorMessage
                      className="text-red-700"
                      name="endDate"
                      component="div"
                    />
                  </div>
                  <div>
                    <InputLabel
                      label={t(' فترة الاشعار ')}
                      type={'number'}
                      value={noticePeriod}
                      name={'noticePeriod'}
                      onChange={handleNoticePeriodChange}
                      isRequired={true}
                    />
                    <ErrorMessage
                      className="text-red-700"
                      name="noticePeriod"
                      component="div"
                    />
                  </div>
                </div>
              )}
            </Formik>
          }
          open={open}
          setOpen={() => setOpen(false)}
          action={handleSubmitAdd}
          type={'submit'}
        />

        {/* publish */}
        <AppModal
          headerTitle={'نشر الاستبيان'}
          open={openpublish}
          setOpen={() => setOpenPublish(false)}
          handleSubmit={handlePublish}
          loading={publishLoading}
          onClose={() => setPublish(false)}
          submitLabel="نشر"
        >
          <div className="flex  text-xl h-full">
            <p>هل تريد نشر هذا الاستبيان؟</p>
          </div>
        </AppModal>

        {/* تمديد المرحلة */}
        <FModal
          headerTitle={t('تمديد المرحلة')}
          action={handleFormik}
          type={'submit'}
          content={
            <NewForm
              initialValues={initialValues}
              validate={validate}
              innerRef={formRefEdit}
              onSubmit={saveExtendStage}
            >
              <div className="flex flex-col gap-3">
                <div className="flex flex-col gap-1">
                  <InputLabel
                    label={t('عدد الساعات')}
                    type={'number'}
                    value={hoursCount}
                    name={'hoursCount'}
                    onChange={handleChangeHoursCount}
                  />
                  <ErrorMessage
                    className="text-red-700"
                    name="hoursCount"
                    component="div"
                  />
                </div>

                <div className="flex flex-col gap-1">
                  <DropdownMultiSelect
                    withoutLabel={false}
                    isSearchable={true}
                    value={selectedStandard}
                    handleChange={handleChangeSelectedStandard}
                    placeholder={t('modules.main.tickets.standard')}
                    label={t('modules.main.tickets.standard')}
                    options={standards}
                    isMultiple={true}
                    loading={isStandardLoading}
                    name="selectedStandard"
                  />
                  <ErrorMessage
                    className="text-red-700"
                    name="selectedStandard"
                    component="div"
                  />
                </div>

                <div className="flex flex-col gap-1">
                  <DropdownMultiSelect
                    withoutLabel={false}
                    isSearchable={true}
                    value={selectedAgencies}
                    handleChange={handleChangeSelectedAgencies}
                    placeholder={t('modules.main.tickets.agency')}
                    label={t('modules.main.tickets.agency')}
                    options={allAgenciesOptions}
                    isMultiple={true}
                    name="selectedAgencies"
                  />
                  <ErrorMessage
                    className="text-red-700"
                    name="selectedAgencies"
                    component="div"
                  />
                </div>
              </div>
            </NewForm>
          }
          open={openExtend}
          setOpen={() => setOpenExtend(false)}
        />
      </div>
    </SecondaryTapsLayout>
  );
};

export default ServeyPhases;

function isNoticePeriodLessThanOne(value) {
  return value < 1;
}
