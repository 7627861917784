import { useContext, useEffect, useState } from 'react';
import AppCheckbox from '../../../../../../../components/form/appCheckbox';
import { StageContext } from '../../../../../../../contexts/stageContext';
import { useAnswerContext } from '../../answerContext';
import StandardFinalAnswer from '../../standardAgencyAnswer';
import useCreateAgencyAnswer from '../useCreateAnswer/useCreateAgencyAnswer';
import { useParams } from 'react-router';

const AgencyFinalAnswer = ({
  setMenuElement,
  hideSaveBtn,
  label = 'اجابة الجهة',
}) => {
  const { currentStage, stageRoles } = useContext(StageContext);
  const { entityId, standardId, programId } = useParams();
  const { createAgencyAnswer } = useCreateAgencyAnswer({
    entityId,
    standardId,
    programId,
  });

  const { newAgencyAnswer, setNewAgencyAnswer } = useAnswerContext();
  const [checkedDegree, setCheckedDegree] = useState(null);

  const handleDegreeChange = (degree) => {
    setCheckedDegree(degree);

    setNewAgencyAnswer({ ...newAgencyAnswer, checkedAgencyDegree: degree });
  };

  useEffect(() => {
    setCheckedDegree(newAgencyAnswer?.checkedAgencyDegree);
  }, []);

  const renderedDegrees = newAgencyAnswer?.surveyDegrees?.map((item) => (
    <AppCheckbox
      key={item?.value}
      id={item.value}
      name={item.label}
      label={item.label}
      value={item.value}
      checked={checkedDegree?.value === item?.value}
      onChange={() => handleDegreeChange(item)}
      disabled={!stageRoles.includes(localStorage.getItem('userType'))}
    />
  ));

  return (
    <StandardFinalAnswer
      setMenuElement={setMenuElement}
      currentStage={currentStage}
      createAgencyAnswer={createAgencyAnswer}
      hideSaveBtn={hideSaveBtn}
      label={label}
    >
      {renderedDegrees}
    </StandardFinalAnswer>
  );
};

export default AgencyFinalAnswer;
