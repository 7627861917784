import { useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useQuery } from '@tanstack/react-query';
import HeaderWithBreadcrumbLayout from '../../../../layouts/headerWithBreadcrumbLayout/index.jsx';
import NewTable from '../../../../components/NewTable.jsx';
import {
  documentsFileCheckmark,
  documentsFileExcel,
  documentsFilesCopy,
  programsIcon,
} from '../../../../assets/icons/Index.js';
import { axiosGetRequest } from '../../../../services/Request.jsx';
import environment from '../../../../services/environment.jsx';
import SurveyResponsesFilter from './SurveyResponsesFilter.jsx';
import useExcelExport from '../../../../helpers/customHooks/useExcelExport.jsx';
import { getSurveyAnswerOfQuestion } from '../utils.js';
import DashboardStats from '../../../../components/dashboard/DashboardStats.jsx';
import NewTooltip from '../../../../components/NewTooltip.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import undefinedToString from '../../../../helpers/utils.jsx';

const sortColumnField = {
  agencyCode: 0,
  agencyTitle: 1,
  answerDate: 2,
  userName: 3,
};

export default function SurveyResponses() {
  const downloadExcel = useExcelExport();
  const history = useHistory();

  const { satisfictionSurveyId } = useParams();
  const [currentPage, setCurrentPage] = useState(0);
  const [maxResult, setMaxResult] = useState({ label: '5', value: 5 });
  const [filterInputs, setFilterInputs] = useState({});
  const [sortData, setSortData] = useState({
    sortType: 'asc',
    sortColumn: 'answerDate',
  });

  const { data: surveyDetails } = useQuery({
    queryKey: ['GetRateSurveyById', satisfictionSurveyId],
    queryFn: () =>
      axiosGetRequest(environment.GetRateSurveyById, {
        rateSurveyId: satisfictionSurveyId,
      }),
    select: (res) => res?.result,
  });

  const {
    data: AnswersOnRateSurvey = { items: [] },
    isLoading: isGettingAnswersOnRateSurvey,
    refetch: refetchGetAnswersOnRateSurvey,
  } = useQuery({
    queryKey: [
      'GetAnswersOnRateSurveyById',
      satisfictionSurveyId,
      currentPage,
      maxResult.value,
      filterInputs,
      sortData,
    ],
    queryFn: () =>
      axiosGetRequest(environment.GetAnswersOnRateSurveyById, {
        rateSurveyId: satisfictionSurveyId,
        skipCount: currentPage * maxResult.value,
        maxResultCount: maxResult.value,
        orderType: sortData?.sortType === 'asc' ? '0' : '1',
        orderIndex: sortColumnField[sortData.sortColumn],
        ...filterInputs,
      }),
    select: (res) => res?.result,
  });

  const {
    data: AnswersOnRateSurveyForExcel = { items: [] },
    isLoading: isGettingAnswersOnRateSurveyForExcel,
  } = useQuery({
    queryKey: [
      'GetAnswersOnRateSurveyById',
      satisfictionSurveyId,
      currentPage,
      100000,
      filterInputs,
      sortData,
    ],
    queryFn: () =>
      axiosGetRequest(environment.GetAnswersOnRateSurveyById, {
        rateSurveyId: satisfictionSurveyId,
        skipCount: currentPage * maxResult.value,
        maxResultCount: 100000,
        ...filterInputs,
      }),
    select: (res) => res?.result,
  });

  const { data: surveyStatistics } = useQuery({
    queryKey: ['GetRateSurveyStatisticsById', satisfictionSurveyId],
    queryFn: () =>
      axiosGetRequest(environment.GetRateSurveyStatisticsById, {
        rateSurveyId: satisfictionSurveyId,
      }),
    select: (res) => res?.result,
  });

  const surveyQuestionsBreadcumbs = [
    {
      icon: programsIcon,
      title: 'الاستبيانات',
      url: '/satisfiction-survey',
    },
    {
      icon: null,
      title: 'اجابات ' + undefinedToString(surveyDetails?.title),
      url: `/satisfiction-survey/${satisfictionSurveyId}/surveyResponses`,
    },
  ];

  const columns = [
    surveyDetails?.isExternal && {
      name: 'كود الجهة',
      center: true,
      accessorKey: 'agencyCode',
      id: 'agencyCode',
      sortable: true,
      selector: (row) => row.agencyCode ?? '',
    },
    surveyDetails?.isExternal && {
      name: 'اسم الجهة',
      center: true,
      accessorKey: 'agencyTitle',
      id: 'agencyTitle',
      sortable: true,
      selector: (row) => row.agencyTitle ?? '',
    },
    {
      name: 'المستخدم',
      center: true,
      accessorKey: 'userName',
      id: 'userName',
      sortable: true,
      selector: (row) => row.userName ?? '',
    },
    {
      name: 'التاريخ',
      center: true,
      accessorKey: 'answerDate',
      id: 'answerDate',
      sortable: true,
      selector: (row) => new Date(row.answerDate).toLocaleDateString() ?? '',
    },
    {
      name: 'الاجراءات',
      selector: (row) => row.actions,
      header: 'actions',
      accessorKey: 'actions',
      footer: (props) => props.column.actions,
      cell: (row) => (
        <NewTooltip content={'عرض الاجابات'}>
          <div className="w-[80px]">
            <FontAwesomeIcon
              icon={faEye}
              className="text-sky-400 bg-sky-100 mr-1 rounded-full p-2 cursor-pointer"
              onClick={() => {
                history.push(
                  `/satisfiction-survey/${satisfictionSurveyId}?userId=${row.userId}`
                );
              }}
            />
          </div>
        </NewTooltip>
      ),
    },
  ].filter(Boolean);

  const handlePageClick = (event) => {
    const newOffset = event.selected;
    setCurrentPage(newOffset);
  };

  const handleSort = (column, sortDirection) => {
    setSortData({
      sortColumn: column.accessorKey,
      sortType: sortDirection,
    });
  };

  const handleExcelExport = async () => {
    if (AnswersOnRateSurveyForExcel?.items?.length) {
      const dataForExcel = AnswersOnRateSurveyForExcel?.items?.map((ele) => {
        const questionAndAnswer = ele?.questionAndAnswer?.map((item) => ({
          ...item.question,
          ...item.answer,
          parsedAnswer: getSurveyAnswerOfQuestion(item.question, item.answer),
        }));

        // Create a map of question titles to their parsed answers
        const questionAnswerMap = Object.fromEntries(
          questionAndAnswer.map((item, index) => [
            index + 1 + ' - ' + item.title,
            item.parsedAnswer,
          ])
        );

        const record = {
          'تاريخ الاجابة': ele.answerDate,
          'اسم المستخدم': ele.userName,
          ...questionAnswerMap, // Dynamically add question titles and their answers as key-value pairs
        };

        if (surveyDetails?.isExternal) {
          record['كود الجهة'] = ele.agencyCode;
          record['اسم الجهة'] = ele.agencyTitle;
        }

        return record;
      });
      downloadExcel(` ${surveyDetails?.title}  اجابات استبيان`, dataForExcel);
    }
  };

  const statisticsCardsList = [
    {
      id: 1,
      title: 'عدد الأسئلة',
      number: surveyStatistics?.questionsNumber,
      icon: documentsFilesCopy,
      color: '#67d4ff26',
    },
    {
      id: 2,
      title: 'عدد الأسئلة المجابة',
      number: surveyStatistics?.questionsAnswered,
      icon: documentsFileCheckmark,
      color: '#25f81e26',
    },
    {
      id: 3,
      title: 'عدد الأسئلة غير المجابة',
      number: surveyStatistics?.questionsNotAnswered,
      icon: documentsFileExcel,
      color: '#f8251e26',
    },
    surveyDetails?.isExternal && {
      id: 4,
      title: 'عدد الجهات',
      number: surveyStatistics?.agenciesNumber,
      icon: documentsFilesCopy,
      color: '#67d4ff26',
    },
    surveyDetails?.isExternal && {
      id: 5,
      title: 'عدد الجهات التي قامت بالإجابة',
      number: surveyStatistics?.agenciesAnswered,
      icon: documentsFileCheckmark,
      color: '#25f81e26',
    },
    surveyDetails?.isExternal && {
      id: 6,
      title: 'عدد الجهات التي  لم تقم بالإجابة',
      number: surveyStatistics?.agenciesNotAnswered,
      icon: documentsFileExcel,
      color: '#f8251e26',
    },
    {
      id: 7,
      title: 'عدد المستخدمين',
      number: surveyStatistics?.usersNumber,
      icon: documentsFilesCopy,
      color: '#67d4ff26',
    },
    {
      id: 8,
      title: 'عدد المستخدمين الذين قاموا بالإجابة',
      number: surveyStatistics?.usersAnswered,
      icon: documentsFileCheckmark,
      color: '#25f81e26',
    },
    {
      id: 9,
      title: 'عدد المستخدمين الذين لم يقوموا بالإجابة',
      number: surveyStatistics?.usersNotAnswered,
      icon: documentsFileExcel,
      color: '#f8251e26',
    },
    {
      id: 10,
      title: 'عدد الاجابات المطلوبة',
      number: surveyStatistics?.surveyTotalQuestionsAnswer,
      icon: documentsFilesCopy,
      color: '#67d4ff26',
    },
    {
      id: 11,
      title: 'عدد الاجابات المتحققة',
      number: surveyStatistics?.surveyTotalQuestionsAnswered,
      icon: documentsFileCheckmark,
      color: '#25f81e26',
    },
    {
      id: 12,
      title: 'عدد الاجابات الغير متحققة',
      number: surveyStatistics?.surveyTotalQuestionsNotAnswer,
      icon: documentsFileExcel,
      color: '#f8251e26',
    },
  ].filter(Boolean);

  return (
    <HeaderWithBreadcrumbLayout breadcrumbArr={surveyQuestionsBreadcumbs}>
      <DashboardStats lists={statisticsCardsList} loading={false} />
      <SurveyResponsesFilter
        setFilterInputs={setFilterInputs}
        isExternal={surveyDetails?.isExternal}
      />
      <NewTable
        withMaxResultChange
        maxResultValue={maxResult}
        setMaxResultValue={setMaxResult}
        columns={columns}
        data={AnswersOnRateSurvey?.items}
        pagination={true}
        totalPages={Math.ceil(
          AnswersOnRateSurvey?.totalCount / maxResult.value
        )}
        onPageChange={handlePageClick}
        currentPage={currentPage}
        loading={isGettingAnswersOnRateSurvey}
        handleSort={handleSort}
        defaultSortAsc={sortData.sortType === 'asc'}
        defaultSortFieldId={sortData.sortColumn}
        withExcel
        onExportExcel={handleExcelExport}
      />
    </HeaderWithBreadcrumbLayout>
  );
}
