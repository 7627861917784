import { createContext, useContext, useState } from 'react';

const DashboardContext = createContext();

function DashboardContextProvider({ children }) {
  const [adminDashboardStats, setAdminDashboardStats] = useState({});
  const [agencyDashboardStats, setAgencyDashboardStats] = useState({});
  const [auditorsDashboardStats, setAuditorsDashboardStats] = useState({});
  const [pocOfficersDashboardStats, setPocOfficersDashboardStats] = useState(
    {}
  );
  const [totalStandardsCount, setTotalStandardsCount] = useState(0);
  const [includedAgenciesCount, setIncludedAgenciesCount] = useState(0);
  const [loading, setLoading] = useState(false);

  return (
    <DashboardContext.Provider
      value={{
        adminDashboardStats,
        setAdminDashboardStats,
        agencyDashboardStats,
        setAgencyDashboardStats,
        auditorsDashboardStats,
        setAuditorsDashboardStats,
        pocOfficersDashboardStats,
        setPocOfficersDashboardStats,
        totalStandardsCount,
        setTotalStandardsCount,
        includedAgenciesCount,
        setIncludedAgenciesCount,
        loading,
        setLoading,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
}

const useDashboardContext = () => {
  const context = useContext(DashboardContext);
  if (!context)
    throw new Error(
      'Calling useDashboardContext Outside DashboardContextProvider'
    );
  return context;
};

export { useDashboardContext, DashboardContextProvider };
