import * as Yup from 'yup';

export const addStandardValidations = Yup.object().shape({
  title: Yup.string().required('يجب ادخال اسم المعيار'),
  titleArabic: '',
  royalOrdersAndCabinetDecisions: '',
  answerWay: '',
  code: Yup.string()
    .matches(/^\d+\.\d+\.\d+$/, 'تنسيق رقم المعيار على الطريقة 1.0.0')
    .required('يجب ادخال رقم المعيار'),
  target: Yup.string().required('يجب ادخال هدف المعيار'),
  weight: Yup.number()
    .nullable()
    .transform((value, originalValue) => {
      if (originalValue === '') {
        return null;
      }
      return value;
    })
    .required('يجب ادخال وزن المعيار')
    .min(0, 'اقل وزن هو صفر')
    .max(100, 'أعلى وزن هو 100'),
  standardRelations: Yup.array().nullable(),
  agencyStandards: Yup.array()
    .min(1, 'يجب ادخال جهة حكومية واحدة على الأقل')
    .required('يجب ادخال جهة حكومية واحدة على الأقل'),
  commonAgencies: Yup.array().nullable(),
  mainAgency: Yup.object().when('commonAgencies', {
    is: (array) => array && array.length > 0,
    then: (schema) => schema.required('يجب اختيار جهة رئيسية'),
    otherwise: (schema) => schema.nullable(),
  }),
});

export const addStandardRequirementValidations = Yup.object().shape({
  title: Yup.string().required('يجب ادخال عنوان المتطلب'),
  recommendation: Yup.string().required('يجب ادخال التوصية'),
});

export const addStandardProofsValidations = Yup.object().shape({
  title: Yup.string().required('يجب ادخال اسم المستند'),
  fileCount: Yup.number('يجب ان يكون عدد الملفات رقم')
    .nullable()
    .transform((value, originalValue) => {
      if (originalValue === '') {
        return null;
      }
      return value;
    })
    .required('يجب ادخال عدد الملفات'),
  fileSize: Yup.number('يجب ان يكون حجم الملف رقم')
    .nullable()
    .transform((value, originalValue) => {
      if (originalValue === '') {
        return null;
      }
      return value;
    })
    .required('يجب ادخال حجم الملف'),
  standardProofTypeId: Yup.string().required('يجب اختيار القيود'),
  standardProofFileSizeTypeId: Yup.string()
    .nullable()
    .transform((value, originalValue) => {
      if (originalValue === '') {
        return null;
      }
      return value;
    })
    .required('يجب اختيار نوع حجم الملف'),
  standardProofFileTypeAssociations: Yup.array()
    .min(1, 'يجب اختيار نوع الملف')
    .required('يجب اختيار نوع الملف'),
  agencyStandardProofs: Yup.array()
    .min(1, 'يجب اختيار الجهات الحكومية')
    .required('يجب اختيار الجهات الحكومية'),
});
