import { useTranslation } from 'react-i18next';
import { DropdownMultiSelect, Input } from '../../../components';
import { useEffect, useState } from 'react';
import environment from '../../../services/environment';
import { get, put } from '../../../services/Request';
import { useHistory } from 'react-router-dom';
import NewTable from '../../../components/NewTable';
import AppButton from '../../../components/buttons/appButton';
import { toast } from 'react-toastify';
import { permissionsCheck } from '../../../helpers/utils';

const PrioritiesSlaList = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState({});
  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [list, setList] = useState([]);
  const [options, setOptions] = useState([
    { value: 1, label: t('modules.main.tickets.time.minute') },
    { value: 0, label: t('modules.main.tickets.time.hour') },
    { value: 2, label: t('modules.main.tickets.time.day') },
    { value: 3, label: t('modules.main.tickets.time.month') },
    { value: 4, label: t('modules.main.tickets.time.workingDay') },
  ]);

  const columns = [
    {
      name: t('modules.main.tickets.priority'),
      selector: (row) => row.priorityName,
      header: 'priorityName',
      accessorKey: 'priorityName',
      footer: (props) => props.column.id,
      cell: (row) => <div>{row.priorityName}</div>,
    },
    {
      name: t('modules.main.tickets.responsePriority'),
      selector: (row) => row.responseWithin,
      header: 'responseWithin',
      accessorKey: 'responseWithin',
      footer: (props) => props.column.responseWithin,
      center: true,
      cell: (row) => (
        <div className="flex items-center gap-2">
          <div className="flex-1">
            <Input
              className={'input_simple h-[46px]'}
              value={row.responseWithin}
              type={'number'}
              placeholder={''}
              onChange={(e) => handleChangeResponseInput(e, row)}
            />
          </div>
          <div className="flex-1">
            <DropdownMultiSelect
              withoutLabel={true}
              isSearchable={false}
              value={row.selectedResponseTimeType}
              options={options}
              isMultiple={false}
              handleChange={(e) => handleChangeResponse(e, row)}
            />
          </div>
        </div>
      ),
    },
    {
      name: t('modules.main.tickets.resolvePriority'),
      selector: (row) => row.resolveWithin,
      header: 'resolveWithin',
      accessorKey: 'resolveWithin',
      footer: (props) => props.column.resolveWithin,
      center: true,
      cell: (row) => (
        <div className="flex items-center gap-2">
          <div className="flex-1">
            <Input
              className={'input_simple h-[46px]'}
              value={row.resolveWithin}
              type={'number'}
              placeholder={''}
              onChange={(e) => handleChangeResolveInput(e, row)}
            />
          </div>
          <div className="flex-1">
            <DropdownMultiSelect
              withoutLabel={true}
              isSearchable={false}
              value={row.selectedResolveWithinTimeType}
              options={options}
              isMultiple={false}
              handleChange={(e) => handleChangeResolve(e, row)}
            />
          </div>
        </div>
      ),
    },
  ];

  useEffect(() => {
    getAllList();
  }, []);

  const getAllList = () => {
    setList([]);
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    get(environment.getAllSLAPolicies, config, (res) => {
      if (res.status == 403) {
        setMessage({ type: 'error', message: res.data.error.message });
        setOpenModal(true);
        setLoading(false);
      } else if (res.status == 500) {
        setMessage({ type: 'error', message: res.data.error.message });
        setOpenModal(true);
        setLoading(false);
      } else if (res.status == 200) {
        var array = [];
        for (var i = 0; i < res.data.result.items.length; i++) {
          var obj = res.data.result.items[i];
          obj.selectedResolveWithinTimeType = options.find((element) => {
            return (
              element.value == res.data.result.items[i].resolveWithinTimeType
            );
          });
          obj.selectedResponseTimeType = options.find((element) => {
            return element.value == res.data.result.items[i].responseTimeType;
          });
          array.push(obj);
        }
        setList(array);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      }
    });
  };

  const saveData = () => {
    setSaveLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    let data = list;
    put(environment.saveSLAPoliciy, data, config, (res) => {
      setSaveLoading(false);
      if (res.status == 403) {
        toast.error(res.data.error.message);
      } else if (res.status == 500) {
        toast.error(res.data.error.message);
      } else if (res.status == 200) {
        toast.info('تم حفظ البيانات بنجاح');
        getAllList();
      }
    });
  };

  const handleChangeResponse = (obj, row) => {
    row.selectedResponseTimeType = obj;
    row.responseTimeType = obj.value;
    var array = [...list];
    const index = array.findIndex((e) => e.id === row.id);
    array[index] = row;
    setList(array);
  };

  const handleChangeResponseInput = (e, row) => {
    row.responseWithin = e.target.value;
    var array = [...list];
    const index = array.findIndex((e) => e.id === row.id);
    array[index] = row;
    setList(array);
  };

  const handleChangeResolve = (obj, row) => {
    row.selectedResolveWithinTimeType = obj;
    row.resolveWithinTimeType = obj.value;
    var array = [...list];
    const index = array.findIndex((e) => e.id === row.id);
    array[index] = row;
    setList(array);
  };

  const handleChangeResolveInput = (e, row) => {
    row.resolveWithin = e.target.value;
    var array = [...list];
    const index = array.findIndex((e) => e.id === row.id);
    array[index] = row;
    setList(array);
  };

  return (
    <div className="flex flex-col gap-3">
      {permissionsCheck('Pages.SLA.GetAll') ? (
        <NewTable
          loading={loading}
          columns={columns}
          data={list}
          pagination={false}
        />
      ) : (
        <div className="text-[#bb2124] text-xl text-center flex justify-center items-center">
          {'ليس لديك صلاحية لهذا'}
        </div>
      )}
      {permissionsCheck('Pages.SLA.Edit') ? (
        <div className="">
          <AppButton onClick={() => saveData()} loading={saveLoading}>
            حفظ
          </AppButton>
        </div>
      ) : null}
    </div>
  );
};

export default PrioritiesSlaList;
