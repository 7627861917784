// cardConfigurations.js

import AuditorsCard from '../admin/phase2/cards/AuditorsCard';
import ExpertsCard from '../admin/phase2/cards/ExpertsCard';
import Phase2Standards from '../admin/phase2/cards/Phase2Standards';
import PocOfficerCard from '../admin/phase2/cards/PocOfficerCard';
import AuditorAxis from './phase2/cards/AuditorAxis';
import AuditorFinishedStandards from './phase2/cards/AuditorFinishedStandards';
import AuditorPerspective from './phase2/cards/AuditorPerspective';
import AuditorRemainingStandards from './phase2/cards/AuditorRemainingStandards';
import AuditorStandards from './phase2/cards/AuditorStandards';

import AuditorsCardPhase4 from '../admin/phase4/cards/AuditorsCard';
import ExpertsCardPhase4 from '../admin/phase4/cards/ExpertsCard';
import Phase4Standards from '../admin/phase4/cards/Phase4Standards';
import PocOfficerCardPhase4 from '../admin/phase4/cards/PocOfficerCard';
import AuditorAxisPhase4 from './phase4/cards/AuditorAxis';
import AuditorFinishedStandardsPhase4 from './phase4/cards/AuditorFinishedStandards';
import AuditorPerspectivePhase4 from './phase4/cards/AuditorPerspective';
import AuditorRemainingStandardsPhase4 from './phase4/cards/AuditorRemainingStandards';
import AuditorStandardsPhase4 from './phase4/cards/AuditorStandards';

const cardConfigurations = {
  expertManager: [
    { id: 1, fullWidth: true, component: <AuditorsCard /> },
    { id: 2, fullWidth: true, component: <ExpertsCard /> },
    { id: 3, fullWidth: true, component: <PocOfficerCard /> },
    { id: 4, fullWidth: true, component: <AuditorPerspective /> },
    { id: 5, fullWidth: true, component: <AuditorAxis /> },
    { id: 6, fullWidth: true, component: <Phase2Standards /> },
  ],
  expert: [
    { id: 1, fullWidth: true, component: <AuditorsCard /> },
    { id: 2, fullWidth: true, component: <PocOfficerCard /> },
    { id: 3, fullWidth: true, component: <AuditorPerspective /> },
    { id: 4, fullWidth: true, component: <AuditorAxis /> },
    { id: 5, fullWidth: true, component: <AuditorStandards /> },
    { id: 6, fullWidth: false, component: <AuditorFinishedStandards /> },
    { id: 7, fullWidth: false, component: <AuditorRemainingStandards /> },
  ],
  pocOfficer: [
    { id: 1, fullWidth: true, component: <AuditorsCard /> },
    { id: 2, fullWidth: true, component: <ExpertsCard /> },
    { id: 3, fullWidth: true, component: <AuditorPerspective /> },
    { id: 4, fullWidth: true, component: <AuditorAxis /> },
    { id: 5, fullWidth: true, component: <AuditorStandards /> },
    { id: 6, fullWidth: false, component: <AuditorFinishedStandards /> },
    { id: 7, fullWidth: false, component: <AuditorRemainingStandards /> },
  ],
  auditor: [
    { id: 1, fullWidth: true, component: <AuditorPerspective /> },
    { id: 2, fullWidth: true, component: <AuditorAxis /> },
    { id: 3, fullWidth: true, component: <AuditorStandards /> },
    { id: 4, fullWidth: false, component: <AuditorFinishedStandards /> },
    { id: 5, fullWidth: false, component: <AuditorRemainingStandards /> },
  ],
};

export const cardConfigurationsPhase4 = {
  expertManager: [
    { id: 1, fullWidth: true, component: <AuditorsCardPhase4 /> },
    { id: 2, fullWidth: true, component: <ExpertsCardPhase4 /> },
    { id: 3, fullWidth: true, component: <PocOfficerCardPhase4 /> },
    { id: 4, fullWidth: true, component: <AuditorPerspectivePhase4 /> },
    { id: 5, fullWidth: true, component: <AuditorAxisPhase4 /> },
    { id: 6, fullWidth: true, component: <Phase4Standards /> },
  ],
  expert: [
    { id: 1, fullWidth: true, component: <AuditorsCardPhase4 /> },
    { id: 2, fullWidth: true, component: <PocOfficerCardPhase4 /> },
    { id: 3, fullWidth: true, component: <AuditorPerspectivePhase4 /> },
    { id: 4, fullWidth: true, component: <AuditorAxisPhase4 /> },
    { id: 5, fullWidth: true, component: <AuditorStandardsPhase4 /> },
    { id: 6, fullWidth: false, component: <AuditorFinishedStandardsPhase4 /> },
    { id: 7, fullWidth: false, component: <AuditorRemainingStandardsPhase4 /> },
  ],
  pocOfficer: [
    { id: 1, fullWidth: true, component: <AuditorsCardPhase4 /> },
    { id: 2, fullWidth: true, component: <ExpertsCardPhase4 /> },
    { id: 3, fullWidth: true, component: <AuditorPerspectivePhase4 /> },
    { id: 4, fullWidth: true, component: <AuditorAxisPhase4 /> },
    { id: 5, fullWidth: true, component: <AuditorStandardsPhase4 /> },
    { id: 6, fullWidth: false, component: <AuditorFinishedStandardsPhase4 /> },
    { id: 7, fullWidth: false, component: <AuditorRemainingStandardsPhase4 /> },
  ],
  auditor: [
    { id: 1, fullWidth: true, component: <AuditorPerspectivePhase4 /> },
    { id: 2, fullWidth: true, component: <AuditorAxisPhase4 /> },
    { id: 3, fullWidth: true, component: <AuditorStandardsPhase4 /> },
    { id: 4, fullWidth: false, component: <AuditorFinishedStandardsPhase4 /> },
    { id: 5, fullWidth: false, component: <AuditorRemainingStandardsPhase4 /> },
  ],
};

export { cardConfigurations };
