import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Title } from '../../../../../components';
import { programsIcon } from '../../../../../assets/icons/Index';
import UploadAdapter from '../../../../../components/UploadAdapter';
import AppButton from '../../../../../components/buttons/appButton';
import { get, post } from '../../../../../services/Request';
import environment from '../../../../../services/environment';
import NewHeader from '../../../../../layouts/header/NewHeader';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import AppEditor from '../../../../../components/form/AppEditor';

const Add = () => {
  const { surveyId, indicatorId } = useParams();
  const history = useHistory();
  const [content, setContent] = useState('');

  useEffect(() => {
    getSurvey();
  }, []);

  const getSurvey = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    get(environment.getSurvey + '?Id=' + surveyId, config, (res) => {
      if (res?.status == 403) {
        toast.error(t('general.authError'));
      } else if (res?.status == 500) {
        toast.error(t('general.serverError'));
      } else if (res?.status == 200) {
        setContent(
          res?.data?.result?.termsAndConditions == null
            ? ''
            : res?.data?.result?.termsAndConditions
        );
      }
    });
  };

  const handleSubmit = (e) => {
    if (!content?.trim()?.length) {
      toast.error('يجب ادخال البيانات المطلوبة قبل الحفظ');
      return;
    }

    let config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    let data = {
      surveyId: surveyId,
      surveyTerms: content,
    };
    post(environment.SetSurveyTerms, data, config, (res) => {
      if (res?.status == 500) {
        toast.error(res?.data?.error?.message);
      } else {
        toast.success('تم اضافة الشروط و الاحكام بنجاح');
        history.push(
          `/indicators/${indicatorId}/surveys/${surveyId}/settings/polices`
        );
      }
    });
  };

  return (
    <div className="bg-white rounded-[30px] m-5">
      <NewHeader />
      <Title
        subTitle={'اضافة'}
        withoutButton={true}
        iconTitle={programsIcon}
        title={'السياسات و الاحكام'}
        toPage={() =>
          history.push(
            `/indicators/${indicatorId}/surveys/${surveyId}/settings/polices`
          )
        }
        seconed={true}
      />
      <div className="mb-3 p-5">
        <AppEditor
          value={content}
          onChange={(event, editor) => {
            const data = editor.getData();
            setContent(data);
            editor.plugins.get('FileRepository').createUploadAdapter =
              function (loader) {
                return new UploadAdapter(loader);
              };
          }}
        />

        <div className="mt-3 text-end">
          <AppButton onClick={handleSubmit}>حفظ</AppButton>
        </div>
      </div>
    </div>
  );
};

export default Add;
