import React from 'react';
import AppButton from '../../../../../../../components/buttons/appButton';

const SaveDraftBtn = ({ onClick, isDisabled, loading }) => {
  return (
    <AppButton
      disabled={isDisabled}
      className="text-xl font-semibold px-12"
      onClick={onClick}
      loading={loading}
      variant="warning"
    >
      حفظ كمسودة
    </AppButton>
  );
};

export default SaveDraftBtn;
