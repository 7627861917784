import React from 'react';
import { EntityContextProvider } from '../../../../contexts/entityDetailsContext';
import Details from './Details';

const EntityDetailsIndex = ({}) => {
  return (
    <EntityContextProvider>
      <Details />
    </EntityContextProvider>
  );
};

export default EntityDetailsIndex;
