import { createContext, useState } from 'react';

const IdentitiesContext = createContext();

function IdentitiesProvider(props) {
  let [selectedRole, setSelectedRole] = useState([]);
  let [selectedEntity, setSelectedEntity] = useState(null);
  let [selectedStatus, setSelectedStatus] = useState({
    id: 1,
    label: 'الحالة',
    labelEn: 'Status',
    value: null,
  });

  return (
    <IdentitiesContext.Provider
      value={{
        selectedRole,
        setSelectedRole,
        selectedStatus,
        setSelectedStatus,
        selectedEntity,
        setSelectedEntity,
      }}
    >
      {props.children}
    </IdentitiesContext.Provider>
  );
}

export { IdentitiesContext, IdentitiesProvider };
