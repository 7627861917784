import axios from 'axios';
import { logoutUser } from '../helpers/utils';

const apiRequest = axios.create({
  baseURL: process.env.REACT_APP_URL,
});

apiRequest.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      logoutUser();
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

export default apiRequest;
