import { Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { ScaleItem } from '../components/ScaleItem';
import { ProgressBar2 } from '../components/ProgressBar2';
import { Footer } from '../components/Footer';
import { AGENCIES_ENUM, fixText } from '../AgencyAnswerReport';

const SCALE_ITEMS = [
  { text: 'الإبداع', style: 'scaleItemLast', color: '#085133' },
  { text: 'التكامل', style: 'scaleItemSecond', color: '#1DC183' },
  { text: 'التحسين', style: 'scaleItemThird', color: '#FF9D00' },
  { text: 'الإتاحة', style: 'scaleItemFourth', color: '#FF6B02' },
  { text: 'البناء', style: 'scaleItemFirst', color: '#B40002' },
];

function numberToFixed(num = 0, d = 2) {
  const number = Number.isFinite(num) ? Number(num) : 0;
  return number.toFixed(d) + '%';
}

export function Page9({
  agencyTitle,
  qiyasObj,
  agencyResults,
  globalStyles,
  agenciesByClassification,
}) {
  const styles = StyleSheet.create({
    ...globalStyles,
  });

  const Equivalent_Result = [
    {
      percentage:
        (agencyResults?.benaaStageCount /
          agencyResults?.agencyClassificationCount) *
        100,
      count: agencyResults?.benaaStageCount,
      label: 'البناء',
      style: 'scaleItemFirst',
      color: '#B40002',
    },
    {
      percentage:
        (agencyResults?.etahaStageCount /
          agencyResults?.agencyClassificationCount) *
        100,
      count: agencyResults?.etahaStageCount,
      label: 'الإتاحة',
      style: 'scaleItemFourth',
      color: '#FF6B02',
    },
    {
      percentage:
        (agencyResults?.tahseenStageCount /
          agencyResults?.agencyClassificationCount) *
        100,
      count: agencyResults?.tahseenStageCount,
      label: 'التحسين',
      style: 'scaleItemThird',
      color: '#FF9D00',
    },
    {
      percentage:
        (agencyResults?.takamolStageCount /
          agencyResults?.agencyClassificationCount) *
        100,
      count: agencyResults?.takamolStageCount,
      label: 'التكامل',
      style: 'scaleItemSecond',
      color: '#1DC183',
    },
    {
      percentage:
        (agencyResults?.ebdaaStageCount /
          agencyResults?.agencyClassificationCount) *
        100,
      count: agencyResults?.ebdaaStageCount,
      label: 'الإبداع',
      style: 'scaleItemLast',
      color: '#085133',
    },
  ];
  const cleanText = (input) => {
    if (input.includes('ئ'))
      return input.normalize('NFKC').replace(/\s+/g, ' ').trim();
    else return input.normalize('NFKD').replace(/\s+/g, ' ').trim();
  };

  const titleFormatter = function (agencyTitle) {
    if (AGENCIES_ENUM.includes(agencyTitle)) {
      return agencyTitle;
    } else if (agencyTitle === 'هيئة الاتصالات والفضاء والتقنية') {
      return 'هيئة الإتصالات والفضاء والتقنية';
    } else if (agencyTitle === 'وزارة الاتصالات وتقنية المعلومات') {
      return 'وزارة الإتصالات وتقنية المعلومات';
    } else {
      return fixText(agencyTitle, true);
    }
  };

  return (
    <Page style={styles.page} author="test">
      <Text
        style={{ ...styles.sectionTitle, fontWeight: 'bold', marginTop: 40 }}
      >
        04. مقارنة نتيجة الجهة مع نتائج المجموعة{' '}
        {qiyasObj?.agencyClassificationTitle.split(' ').map((word) => {
          return <Text>{cleanText(word)} </Text>;
        })}
      </Text>

      <Text style={styles.subtitle}>
        يبين الشكل أدناه نتيجة الجهة بالمقارنة مع متوسط نتيجة المجموعة التي
        تنتمي لها:
      </Text>

      <View
        style={{ ...styles.scaleContainer, marginBottom: 0, width: '100%' }}
      >
        <Text style={styles.sectionSecondaryTitle}> النتيجة المكافئة​</Text>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
          }}
        >
          <View
            label="indicator"
            style={{
              width: '50%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              opacity: qiyasObj?.finalResult >= 90 ? 1 : 0,
            }}
          >
            <Text style={{ fontSize: 10 }}>{titleFormatter(agencyTitle)}</Text>
            <Text
              style={{
                color: '#2A2069',
                fontSize: 10,
                margin: 'auto',
                fontWeight: 'bold',
              }}
            >
              {numberToFixed(qiyasObj?.finalResult)}
            </Text>
            <View style={styles.indicatorArrowUp}></View>
          </View>
          <View
            label="indicator"
            style={{
              width: '50%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              opacity:
                qiyasObj?.finalResult >= 80 && qiyasObj?.finalResult < 90
                  ? 1
                  : 0,
            }}
          >
            <Text style={{ fontSize: 10, margin: 'auto' }}>
              {titleFormatter(agencyTitle)}
            </Text>
            <Text
              style={{
                color: '#2A2069',
                fontSize: 10,
                margin: 'auto',
                fontWeight: 'bold',
              }}
            >
              {numberToFixed(qiyasObj?.finalResult)}
            </Text>
            <View style={styles.indicatorArrowUp}></View>
          </View>
          <View
            label="indicator"
            style={{
              width: '50%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              opacity:
                qiyasObj?.finalResult >= 40 && qiyasObj?.finalResult < 80
                  ? 1
                  : 0,
            }}
          >
            <Text style={{ fontSize: 10, margin: 'auto' }}>
              {titleFormatter(agencyTitle)}
            </Text>
            <Text
              style={{
                color: '#2A2069',
                fontSize: 10,
                margin: 'auto',
                fontWeight: 'bold',
              }}
            >
              {numberToFixed(qiyasObj?.finalResult)}
            </Text>
            <View style={styles.indicatorArrowUp}></View>
          </View>
          <View
            label="indicator"
            style={{
              width: '50%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              opacity:
                qiyasObj?.finalResult >= 10 && qiyasObj?.finalResult < 40
                  ? 1
                  : 0,
            }}
          >
            <Text style={{ fontSize: 10, margin: 'auto' }}>
              {titleFormatter(agencyTitle)}
            </Text>
            <Text
              style={{
                color: '#2A2069',
                fontSize: 10,
                margin: 'auto',
                fontWeight: 'bold',
              }}
            >
              {numberToFixed(qiyasObj?.finalResult)}
            </Text>
            <View style={styles.indicatorArrowUp}></View>
          </View>
          <View
            label="indicator"
            style={{
              width: '50%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              opacity: qiyasObj?.finalResult < 10 ? 1 : 0,
            }}
          >
            <Text style={{ fontSize: 10, margin: 'auto' }}>
              {titleFormatter(agencyTitle)}
            </Text>
            <Text style={{ color: '#2A2069', fontSize: 10 }}>
              {numberToFixed(qiyasObj?.finalResult)}
            </Text>
            <View style={styles.indicatorArrowUp}></View>
          </View>
        </View>

        <View style={styles.scale}>
          {SCALE_ITEMS.slice().map((item, index) => (
            <ScaleItem key={index} style={styles[item.style]}>
              {item.text}
            </ScaleItem>
          ))}
        </View>

        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
          }}
        >
          <View
            label="indicator"
            style={{
              width: '50%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              marginTop: 6,
              opacity: qiyasObj?.agencyClassificationFinalResult >= 90 ? 1 : 0,
            }}
          >
            <View style={styles.indicatorArrowDown}></View>
            <Text
              style={{
                color: '#2A2069',
                fontSize: 10,
                margin: 'auto',
                fontWeight: 'bold',
              }}
            >
              {numberToFixed(qiyasObj?.agencyClassificationFinalResult)}
            </Text>
            <Text style={{ fontSize: 10, margin: 'auto' }}>
              {agenciesByClassification?.agencyClassificationName}
            </Text>
          </View>
          <View
            label="indicator"
            style={{
              width: '50%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              marginTop: 6,
              opacity:
                qiyasObj?.agencyClassificationFinalResult >= 80 &&
                qiyasObj?.agencyClassificationFinalResult < 90
                  ? 1
                  : 0,
            }}
          >
            <View style={styles.indicatorArrowDown}></View>
            <Text
              style={{
                color: '#2A2069',
                fontSize: 10,
                margin: 'auto',
                fontWeight: 'bold',
              }}
            >
              {numberToFixed(qiyasObj?.agencyClassificationFinalResult)}
            </Text>
            <Text style={{ fontSize: 10, margin: 'auto' }}>
              {agenciesByClassification?.agencyClassificationName}
            </Text>
          </View>
          <View
            label="indicator"
            style={{
              width: '50%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              marginTop: 6,
              opacity:
                qiyasObj?.agencyClassificationFinalResult >= 40 &&
                qiyasObj?.agencyClassificationFinalResult < 80
                  ? 1
                  : 0,
            }}
          >
            <View style={styles.indicatorArrowDown}></View>
            <Text
              style={{
                color: '#2A2069',
                fontSize: 10,
                margin: 'auto',
                fontWeight: 'bold',
              }}
            >
              {numberToFixed(qiyasObj?.agencyClassificationFinalResult)}
            </Text>
            <Text style={{ fontSize: 10, margin: 'auto' }}>
              {agenciesByClassification?.agencyClassificationName}
            </Text>
          </View>
          <View
            label="indicator"
            style={{
              width: '50%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              marginTop: 6,
              opacity:
                qiyasObj?.agencyClassificationFinalResult >= 10 &&
                qiyasObj?.agencyClassificationFinalResult < 40
                  ? 1
                  : 0,
            }}
          >
            <View style={styles.indicatorArrowDown}></View>
            <Text
              style={{
                color: '#2A2069',
                fontSize: 10,
                margin: 'auto',
                fontWeight: 'bold',
              }}
            >
              {numberToFixed(qiyasObj?.agencyClassificationFinalResult)}
            </Text>
            <Text style={{ fontSize: 10, margin: 'auto' }}>
              {agenciesByClassification?.agencyClassificationName}
            </Text>
          </View>
          <View
            label="indicator"
            style={{
              width: '50%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              marginTop: 6,
              opacity: qiyasObj?.agencyClassificationFinalResult < 10 ? 1 : 0,
            }}
          >
            <View style={styles.indicatorArrowDown}></View>

            <Text
              style={{
                color: '#2A2069',
                fontSize: 10,
                margin: 'auto',
                fontWeight: 'bold',
              }}
            >
              {numberToFixed(qiyasObj?.agencyClassificationFinalResult)}
            </Text>
            <Text style={{ fontSize: 10, margin: 'auto' }}>
              {agenciesByClassification?.agencyClassificationName}
            </Text>
          </View>
        </View>
      </View>

      {Equivalent_Result.map((item, index) => (
        <ProgressBar2
          key={index}
          label={item.label}
          percentage={item.percentage}
          count={item.count}
          color={item.color || '#7C32C9'}
        />
      ))}

      <Text style={{ color: '#2A2069', fontSize: 8, marginHorizontal: 'auto' }}>
        يبين الشكل أعلاه مقارنة نتيجة الجهة مع الجهات الحكومية الأخرى لنفس
        المجموعة​{' '}
      </Text>
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: 20,
          alignItems: 'center',
          justifyContent: 'flex-end',
          marginTop: 15,
        }}
      >
        {Equivalent_Result.reverse().map((item) => (
          <View style={{ display: 'flex', flexDirection: 'row', gap: 5 }}>
            <Text style={{ color: '#2A2069', fontSize: 8 }}>{item.label}</Text>
            <View
              style={{
                width: 6,
                height: 6,
                backgroundColor: `${item.color}`,
                borderRadius: 2,
              }}
            ></View>
          </View>
        ))}
      </View>

      <Footer agencyTitle={agencyTitle} />
    </Page>
  );
}
