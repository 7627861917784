import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import AppActions from '../../../../../components/appActions';
import AppMoreButton from '../../../../../components/buttons/appMoreButton';
import AppDeleteItemModal from '../../../../../components/Models/appDeleteItemModal';
import AppModal from '../../../../../components/Models/appModal';
import NewDropdownMultiSelect from '../../../../../components/NewDropDownMultiSelect';
import environment from '../../../../../services/environment';
import {
  axiosGetRequest,
  axiosPostRequest,
} from '../../../../../services/Request';

const StanderdItem = ({
  id,

  perspectiveTitle,
  title,
  interlocutorTitle,
  code,
  weight,
  agencyStandards,
  indicatorId,
  getAllStanderds,
  experts,
  reviewers,
  setReviewers,
  setExperts,
  pocOfficers,
  setPocOfficers,
  standardTitle,
}) => {
  const { interlocutorId, perspectiveId, surveyId } = useParams();

  const history = useHistory();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAssignModal, setShowAssignModal] = useState(false);

  const [selectedReviewers, setSelectedReviewers] = useState([]);
  const [selectedPOCOfficers, setSelectedPOCOfficers] = useState([]);
  const [selectedExperts, setSelectedExperts] = useState([]);

  const handleChangeExperts = (value) => {
    setSelectedExperts(value || []);
  };
  const handleChangeReviewers = (value) => {
    setSelectedReviewers(value || []);
  };

  const handleChangePOCOfficers = (value) => {
    setSelectedPOCOfficers(value || []);
  };

  useEffect(() => {
    getAssignedUsers();
  }, []);

  const getAssignedUsers = async () => {
    const res = await axiosGetRequest(
      environment.getExpertAndReviewerAndPocOfficerByStandardId,
      {
        standardId: id,
      }
    );

    if (res?.success) {
      let newExperts = [];
      let newPocOfficers = [];
      let newReviewers = [];
      res?.result?.assignedExperts.forEach((expert, i) => {
        newExperts.push({
          label: expert.fullName,
          value: expert.id,
          id: expert.id,
        });
      });
      setSelectedExperts(newExperts);
      res?.result?.assignedPocOfficiers.forEach((pocOfficer, j) => {
        newPocOfficers.push({
          label: pocOfficer.fullName,
          value: pocOfficer.id,
          id: pocOfficer.id,
        });
      });
      setSelectedPOCOfficers(newPocOfficers);
      res?.result?.assignedReviewer.forEach((reviewer, j) => {
        newReviewers.push({
          label: reviewer.fullName,
          value: reviewer.id,
          id: reviewer.id,
        });
      });
      setSelectedReviewers(newReviewers);
    }
  };

  const assignStandardToReviewer = async () => {
    setLoading(true);
    let newAssignedReviewers = [];
    selectedReviewers.forEach((reviewer, index) => {
      newAssignedReviewers.push({ reviewerId: reviewer.id });
    });
    let data = {
      standardId: id,
      assignedReviewers: newAssignedReviewers,
    };
    const res = await axiosPostRequest(
      environment.assignStandardToReviewer,
      data
    );
    if (res?.success) {
      let arr = [];

      res?.result?.items?.forEach((element) => {
        let obj = {
          id: element.id,
          value: element.id,
          label: element.fullName,
        };
        arr.push(obj);
      });

      setReviewers(arr);
      toast.success('تم تعيين مدققين بنجاح', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      setLoading(false);
    }
  };
  const assignStandardToExpert = async () => {
    setLoading(true);
    let newAssignedExperts = [];

    selectedExperts.forEach((expert, index) => {
      newAssignedExperts.push({ expertId: expert.id });
    });
    let data = {
      standardId: id,
      assignedExperts: newAssignedExperts,
    };
    const res = await axiosPostRequest(
      environment.assignStandardToExpert,
      data
    );
    if (res?.success) {
      let arr = [];

      res?.result?.items?.forEach((element) => {
        let obj = {
          id: element.id,
          value: element.id,
          label: element.fullName,
        };
        arr.push(obj);
      });

      setExperts(arr);
      toast.success('تم تعيين خبراء بنجاح', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      setLoading(false);
    }
  };

  const assignStandardToPOCOfficer = async () => {
    setLoading(true);
    let newAssignedPOCOfficers = [];
    selectedPOCOfficers.forEach((reviewer, index) => {
      newAssignedPOCOfficers.push({ pocOfficierId: reviewer.id });
    });
    let data = {
      standardId: id,
      assignedPocOfficiers: newAssignedPOCOfficers,
    };
    const res = await axiosPostRequest(
      environment.assignStandardToPocOfficer,
      data
    );
    if (res?.success) {
      let arr = [];

      res?.result?.items?.forEach((element) => {
        let obj = {
          id: element.id,
          value: element.id,
          label: element.fullName,
        };
        arr.push(obj);
      });

      setPocOfficers(arr);
      toast.success('تم تعيين ضباط اتصال بنجاح', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      setLoading(false, setShowAssignModal(false));
    }
  };
  const [loading, setLoading] = useState(false);

  const handleRemoveItemFromList = () => {
    getAllStanderds();
  };
  const handleAssignStandard = () => {
    assignStandardToExpert();

    assignStandardToReviewer();

    assignStandardToPOCOfficer();
  };
  return (
    <div className="m-1 p-5 transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-[1.02] duration-300 hover:shadow-lg border-brdr border-[1px] rounded-lg ">
      <div className="mb-3 flex justify-end">
        {}
        <AppActions
          handleDeleteClick={() => {
            setShowDeleteModal(true);
          }}
          page="standards"
          handleEditClick={() => {
            history.push(
              `/indicators/${indicatorId}/programs/${surveyId}/perspectives/${perspectiveId}/interlocutor/${interlocutorId}/standards/${id}/edit`
            );
          }}
          handleSettingClick={() => setShowAssignModal(true)}
          handleAssignAgenciesToExpertAndReviewerClick={() =>
            history.push(`/standard/${id}/assign-agencies`)
          }
        />
        <AppModal
          handleSubmit={handleAssignStandard}
          open={showAssignModal}
          setOpen={setShowAssignModal}
          loading={loading}
          submitLabel="إضافة"
        >
          <NewDropdownMultiSelect
            label={'الخبراء'}
            placeholder={'اختر خبراء'}
            options={experts}
            handleChange={handleChangeExperts}
            value={selectedExperts}
            isMultiple={true}
          />
          {/* <div className="text-sm text-red-600 mt-3">
            {errors.experts && touched.experts && errors.experts}
          </div> */}
          <div className="mt-3">
            <NewDropdownMultiSelect
              label={'المدققين'}
              placeholder={'اختر مدققين'}
              options={reviewers}
              handleChange={handleChangeReviewers}
              value={selectedReviewers}
              isMultiple={true}
            />
          </div>

          <div className="mt-3">
            <NewDropdownMultiSelect
              label={'ضباط الاتصال'}
              placeholder={'اختر ضباط اتصال'}
              options={pocOfficers}
              handleChange={handleChangePOCOfficers}
              value={selectedPOCOfficers}
              isMultiple={true}
            />
          </div>
        </AppModal>

        <AppDeleteItemModal
          api={environment.deleteStandard}
          id={id}
          itemTitle={title}
          openModal={showDeleteModal}
          setOpenModal={setShowDeleteModal}
          modalTitle="حذف معيار"
          params={{ Id: id }}
          removeItemFromList={handleRemoveItemFromList}
        />
      </div>
      <h2 className="text-blue_text">{title}</h2>
      <div className="flex gap-3 items-center mt-6 mb-8">
        <div className="w-1/2">
          <p className="text-light text-xs mb-1">المنظور</p>
          <h3 className="text-sm">{perspectiveTitle}</h3>
        </div>
        <div className="w-1/2">
          <p className="text-light text-xs mb-1">المحور</p>
          <h3 className="text-sm">{interlocutorTitle}</h3>
        </div>
      </div>
      <div className="flex gap-3 items-center mt-3 mb-8">
        <div className="w-1/2">
          <p className="text-light text-xs mb-1">رقم المعيار</p>
          <h3 className="text-sm">{code}</h3>
        </div>
        <div className="w-1/2">
          <p className="text-light text-xs mb-1"> وزن المعيار</p>
          <h3 className="text-sm">{weight?.toFixed(2)}</h3>
        </div>
      </div>
      <AppMoreButton
        fullwidth
        onClick={() => {
          history.push({
            pathname: `/indicators/${indicatorId}/programs/${surveyId}/perspectives/${perspectiveId}/interlocutor/${interlocutorId}/standards/${id}/entities`,
            state: { standardTitle: standardTitle },
          });
        }}
      >
        الجهات ( {agencyStandards?.length || '0'} )
      </AppMoreButton>
    </div>
  );
};

export default StanderdItem;
