const AppRadio = ({ id, name, value, onChange, checked, label, disabled }) => {
  return (
    <div className="container_input mb-2 md:w-1/2 sm:w-full">
      <label
        htmlFor={id}
        className="flex items-center gap-3 cursor-pointer select-none"
      >
        <input
          type="radio"
          name={name}
          id={id}
          disabled={disabled}
          value={value}
          onChange={onChange}
          checked={checked}
          className="p-[10px] w-3"
        />
        {label && <span className="mb-2"> {label} </span>}
      </label>
    </div>
  );
};

export default AppRadio;
