import { plusLight, programsIcon } from '../../../../../assets/icons/Index';
import { Title } from '../../../../../components';
import NewHeader from '../../../../../layouts/header/NewHeader';
import NewTable from '../../../../../components/NewTable';
import FilePreview from '../../../../../components/upload/filePreview';
import { Badge } from 'flowbite-react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import useExcelExport from '../../../../../helpers/customHooks/useExcelExport';
import { axiosGetRequest } from '../../../../../services/Request';
import environment from '../../../../../services/environment';
import { useGetAnswersAnalysis } from './useGetAnswersAnalysis';
import AppBadge from '../../../../../components/AppBadge';

const AnswerAnalysis = () => {
  const { id, indicatorId } = useParams();
  const [currentPage, setCurrentPage] = useState(0);
  const [maxResult, setMaxResult] = useState({ label: '50', value: 50 });

  const { agencyDetails, isGettingAnswersAnalysis, totalPages, agencyAnswers } =
    useGetAnswersAnalysis({
      currentPage,
      maxResult: maxResult.value,
      agencyId: id,
    });

  const columns = [
    {
      name: 'كود المعيار',
      selector: (row) => 'standardCode',
      header: 'standardCode',
      accessorKey: 'standardCode',
      cell: (row) => (
        <Link
          to={`/indicators/${indicatorId}/programs/${localStorage.getItem('surveyId')}/program/${
            agencyDetails?.agencyId
          }/entity/${row?.standardId}/standard`}
          className="whitespace-nowrap font-bold truncate hover:text-sky-600"
        >
          {row.standardCode}
        </Link>
      ),
      width: '100px',
    },
    {
      name: 'اجابة الجهة',
      selector: (row) => 'selfEvaluationDegree',
      header: 'selfEvaluationDegree',
      accessorKey: 'selfEvaluationDegree',
      cell: (row) => (
        <AppBadge badgeColor={row.finalCommitmentLevelColor}>
          {row.finalCommitmentLevelTitle}
        </AppBadge>
      ),
      center: true,
      width: '150px',
    },
    {
      name: 'الدرجة المقترحة',
      selector: (row) => 'finalDegree',
      header: 'finalDegree',
      accessorKey: 'finalDegree',
      cell: (row) => (
        <AppBadge badgeColor={row.selfEvaluationCommitmentLevelColor}>
          {row.selfEvaluationCommitmentLevelTitle}
        </AppBadge>
      ),
      center: true,
      width: '150px',
    },
    {
      name: 'المستندات',
      selector: (row) => 'agencyProofAnswers',
      header: 'agencyProofAnswers',
      accessorKey: 'agencyProofAnswers',
      cell: (row) => (
        <div className="flex-wrap my-2 flex gap-2">
          {row?.agencyProofAnswers?.length > 0 &&
            row?.agencyProofAnswers?.map((ele) => {
              return ele?.standardProofAttachments?.length > 0 ? (
                ele?.standardProofAttachments?.map((item) => (
                  <div className=" ">
                    <Badge
                      className="text-indigo-700 pt-0 font-medium bg-indigo-100 pb-0 px-2  rounded-xl"
                      size="sm"
                    >
                      <div className="flex items-center gap-3 pr-1">
                        <FilePreview
                          fileName={item?.attachment?.name}
                          id={item?.attachment?.id}
                        >
                          <p className="mb-2 cursor-pointer">
                            {item?.attachment?.name}
                          </p>
                        </FilePreview>
                        <FilePreview
                          fileName={item?.attachment?.name}
                          id={item?.attachment?.id}
                        />
                      </div>
                    </Badge>
                  </div>
                ))
              ) : (
                <p></p>
              );
            })}
        </div>
      ),
    },
    {
      name: 'عرض الإجابة',
      selector: (row) => 'standardCode',
      header: 'standardCode',
      accessorKey: 'standardCode',
      cell: (row) => (
        <Link
          to={`/indicators/${indicatorId}/programs/${localStorage.getItem('surveyId')}/program/${
            agencyDetails?.agencyId
          }/entity/${row?.standardId}/standard`}
          className="whitespace-nowrap font-bold pb-2 px-4 rounded text-white bg-indigo-600 truncate hover:bg-indigo-800"
        >
          عرض الاجابة
        </Link>
      ),
      width: '150px',
    },
  ];

  const handlePageClick = (event) => {
    const newOffset = event.selected;
    setCurrentPage(newOffset);
  };

  // excel
  const [excelLoading, setExcelLoading] = useState(false);

  const downloadExcel = useExcelExport();

  const handleExcelExport = async () => {
    setExcelLoading(true);
    const res = await axiosGetRequest(environment.GetAgencyAnswerByAgencyId, {
      AgencyId: id,
      SkipCount: 0,
      MaxResultCount: 100000,
    });

    setExcelLoading(false);

    downloadExcel(
      `اجابات جهة ${agencyDetails?.agencyNameInArabic}`,
      res?.result?.items
    );
  };

  return (
    <div className="bg-white rounded-[30px] m-5 pb-4">
      <NewHeader />
      <Title
        iconTitle={programsIcon}
        title={'إجابات الجهة'}
        subTitle={agencyDetails?.agencyNameInArabic}
        textStyle={{ color: 'white', fontSize: 18 }}
        seconed={true}
        titleButton={'modules.main.users.entities.titleAddButton'}
        withoutButton
        withIcon={true}
        icon={plusLight}
        style={{ backgroundColor: '#2B2969', width: 200 }}
      />
      <div className="bg-[#F9F9FF] rounded-[30px] p-5 m-5">
        <h2 className="text-xl mb-3">
          إجابــات {agencyDetails?.agencyNameInArabic}
        </h2>
        <NewTable
          withMaxResultChange
          maxResultValue={maxResult}
          setMaxResultValue={setMaxResult}
          withExcel
          excelLoading={excelLoading}
          onExportExcel={handleExcelExport}
          loading={isGettingAnswersAnalysis}
          columns={columns}
          data={agencyAnswers}
          pagination
          totalPages={totalPages}
          onPageChange={handlePageClick}
          currentPage={currentPage}
        />
      </div>
    </div>
  );
};

export default AnswerAnalysis;
