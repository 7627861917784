import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';
import AppButton from '../../../../components/buttons/appButton';
import { isAdmin } from '../../../../helpers/isPermission';
import { byId, jsonParse } from '../../../../helpers/utils';
import useQueryParams from '../../../../hooks/useQueryParams';
import environment from '../../../../services/environment';
import { axiosPutRequest } from '../../../../services/Request';
import { QuestionType } from './QuestionType';
import { SurveyRateAnswerFormHeader } from './SurveyRateAnswerFormHeader';
import ThanksSubmittingMessage from './ThanksSubmittingMessage';
import { useQueryClient } from '@tanstack/react-query';

const REQUIRED_MESSAGE = 'هذا السؤال مطلوب';

const getQuestionsAnswers = (questions, defaultAnswer) => {
  return questions.map((question) => {
    const { answer, attachment: attachmentId } =
      defaultAnswer?.find((item) => item.id === question.id) || {};
    return {
      id: question.id,
      value: answer || null,
      attachmentId: attachmentId || null,
      isRequired: question.isRequired,
    };
  });
};

const validateForm = (formState) => {
  const newFormState = formState.map((question) => {
    return {
      ...question,
      requiredMessage:
        question.isRequired && !question.value?.trim()
          ? REQUIRED_MESSAGE
          : null,
    };
  });
  const hasRequiredMessage = newFormState.some(
    (question) => question.requiredMessage
  );
  return {
    hasRequiredMessage,
    newFormState,
  };
};

export function SurveyRateAnswerForm({
  questions,
  defaultAnswer,
  disabled,
  isSubmmited,
  inActive,
}) {
  const queryClient = useQueryClient();
  const history = useHistory();
  let { satisfictionSurveyId } = useParams();
  const searchParams = useQueryParams();
  const userId = jsonParse(searchParams.get('userId'));

  const [openShowModal, setOpenShowModal] = useState(false);

  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [isSubmittedLoading, setIsSubmittedLoading] = useState(false);
  const [formState, setFormState] = useState(
    getQuestionsAnswers(questions, defaultAnswer)
  );
  const [isUploadingFile, setIsUploadingFile] = useState([]);

  useEffect(() => {
    if (defaultAnswer?.length) {
      const questionAndAnswer = getQuestionsAnswers(questions, defaultAnswer);
      setFormState([...questionAndAnswer]);
    }
  }, [defaultAnswer, questions]);

  function handleChange({ questionId, value }) {
    const newFormState = formState.map((question) => {
      if (question.id === questionId) {
        return {
          ...question,
          value,
          requiredMessage:
            question.isRequired && !value?.trim() ? REQUIRED_MESSAGE : null,
        };
      }
      return question;
    });

    setFormState(newFormState);
  }

  function handleAttachmentChange({ questionId, attachmentId }) {
    const newFormState = formState.map((question) => {
      if (question.id === questionId) {
        return {
          ...question,
          attachmentId,
        };
      }
      return question;
    });

    setFormState(newFormState);
  }

  async function handleSubmit() {
    const { hasRequiredMessage, newFormState } = validateForm(formState);

    if (hasRequiredMessage) {
      setFormState(newFormState);
      return;
    }

    const payload = {
      rateSurveyId: satisfictionSurveyId,
      isSubmmited: true,
      questionAnswers: formState.map((question) => ({
        rateSurveyQuestionId: question.id,
        answer: question.value,
        attachmentId: question.attachmentId,
      })),
    };

    setIsSubmittedLoading(true);
    const res = await axiosPutRequest(
      environment.UpdateRateSurveyQuestionAnswers,
      payload
    );
    setIsSubmittedLoading(false);
    if (res?.success) {
      toast.success('تم الإرسال بنجاح');
      setOpenShowModal(true);
      queryClient.invalidateQueries([
        environment.GetAnswersOnRateSurveyByUserId,
        satisfictionSurveyId,
        userId,
      ]);
    }
  }

  async function handleSave() {
    // const { hasRequiredMessage, newFormState } = validateForm(formState);

    // if (hasRequiredMessage) {
    //   setFormState(newFormState);
    //   return;
    // }

    const payload = {
      rateSurveyId: Number(satisfictionSurveyId),
      isSubmmited: false,
      questionAnswers: formState.map((question) => ({
        rateSurveyQuestionId: question.id,
        answer: question.value,
        attachmentId: question.attachmentId,
      })),
    };

    setIsSaveLoading(true);
    const res = await axiosPutRequest(
      environment.UpdateRateSurveyQuestionAnswers,
      payload
    );
    setIsSaveLoading(false);
    if (res?.success) {
      toast.success('تم الحفظ بنجاح');
      history.goBack();
      queryClient.invalidateQueries([
        environment.GetAnswersOnRateSurveyByUserId,
        satisfictionSurveyId,
        userId,
      ]);
    }
  }

  function onUploadStart(questionId) {
    setIsUploadingFile([...isUploadingFile, questionId]);
  }

  function onUploadEnd(questionId) {
    setIsUploadingFile(isUploadingFile.filter((id) => id !== questionId));
  }

  const numOfQuestions = questions.length;
  const numOfAnsweredQuestions = formState.filter((question) =>
    question.value?.trim()
  ).length;
  return (
    <div className="bg-white rounded-[20px] m-5 p-12 flex-1 max-w-[1000px] border-t-8 border-[#292069]">
      <SurveyRateAnswerFormHeader
        numOfQuestions={numOfQuestions}
        numOfAnsweredQuestions={numOfAnsweredQuestions}
        inActive={inActive}
      />

      {questions.map((question) => (
        <QuestionType
          key={question.id}
          disabled={disabled || isAdmin || isSubmmited}
          question={question}
          value={byId(formState, question.id)?.value}
          attachmentId={byId(formState, question.id)?.attachmentId}
          requiredMessage={byId(formState, question.id)?.requiredMessage}
          onChange={(value) => handleChange({ questionId: question.id, value })}
          onUploadStart={() => onUploadStart(question.id)}
          onUploadEnd={() => onUploadEnd(question.id)}
          onAttachmentChange={(attachmentId) =>
            handleAttachmentChange({ questionId: question.id, attachmentId })
          }
        />
      ))}

      <div className="flex justify-end mt-5 gap-2">
        {!inActive && !isSubmmited && !isAdmin && (
          <>
            <AppButton
              loading={isSaveLoading}
              onClick={handleSave}
              disabled={
                numOfAnsweredQuestions === 0 || !!isUploadingFile.length
              }
            >
              حفظ كمسودة
            </AppButton>
            <AppButton
              loading={isSubmittedLoading}
              onClick={handleSubmit}
              disabled={
                numOfAnsweredQuestions === 0 || !!isUploadingFile.length
              }
            >
              إرسال
            </AppButton>
          </>
        )}
        {!(isAdmin && !userId) && (
          <button
            type="button"
            class="bg-danger cursor-pointer  text-white py-2 pb-4  px-4 min-w-[140px] pt-1 mt-0 rounded-full flex items-center justify-center"
            onClick={() => {
              history.goBack();
            }}
          >
            <span class="block mb-[-5px]">
              {isSubmmited ? 'رجوع' : 'إلغاء'}
            </span>
          </button>
        )}
      </div>

      <ThanksSubmittingMessage
        open={openShowModal}
        setOpen={setOpenShowModal}
      />
    </div>
  );
}
