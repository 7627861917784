import React, { useState } from 'react';
import { axiosDeleteRequest } from '../../services/Request';
import AppModal from './appModal';
import { toast } from 'react-toastify';

const AppDeleteItemModal = ({
  id,
  openModal,
  setOpenModal,
  api,
  params,
  modalTitle,
  itemTitle,
  removeItemFromList,
}) => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    const res = await axiosDeleteRequest(api, params);
    setLoading(false);
    if (res?.success) {
      if (removeItemFromList) removeItemFromList(id);
      toast.success('تم الحذف بنجاح');
      setOpenModal(false);
    }
  };

  return (
    <AppModal
      headerTitle={modalTitle}
      open={openModal}
      setOpen={setOpenModal}
      handleSubmit={handleSubmit}
      loading={loading}
      submitLabel="حذف"
    >
      <div className="flex  text-xl h-full">
        <p>
          هل تريد حذف <span className="text-danger">{itemTitle}</span> ؟
        </p>
      </div>
    </AppModal>
  );
};

export default AppDeleteItemModal;
