import { Tooltip } from 'flowbite-react';

const NewTooltip = ({ content, children, placement = 'top' }) => {
  return (
    <Tooltip
      content={content}
      theme={{
        target: 'w-fit',
        animation: 'transition-opacity',
        arrow: {
          base: 'absolute z-10 h-2 w-2 rotate-45',
          style: {
            dark: 'bg-purple dark:bg-gray-700',
            light: 'bg-white',
            auto: 'bg-white dark:bg-gray-700',
          },
          placement: '',
        },
        base: 'absolute inline-block z-10 rounded-lg py-2 pb-4 px-3 text-sm font-medium shadow-sm',
        hidden: 'invisible opacity-0',
        style: {
          dark: 'bg-purple text-white dark:bg-gray-700',
          light: 'border border-gray-200 bg-white text-gray-900',
          auto: 'border border-gray-200 bg-white text-gray-900 dark:border-none dark:bg-gray-700 dark:text-white',
        },
        content:
          'relative z-[9999] flex items-center justify-center mt-[5px] mb-[-5px]',
      }}
      placement={placement}
    >
      {children}
    </Tooltip>
  );
};

export default NewTooltip;
