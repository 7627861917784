import { useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import HeaderWithBreadcrumbLayout from '../../../../layouts/headerWithBreadcrumbLayout/index.jsx';
import NewTable from '../../../../components/NewTable.jsx';
import { FModal } from '../../../../components/index.js';
import { AddEditSurveyForm } from './AddEditSurveyForm.jsx';
import DeleteIcon from '../../../../components/DeleteIcon.jsx';
import { programsIcon } from '../../../../assets/icons/Index.js';
import leftArrow from '../../../../assets/icons/leftArrow.svg';
import {
  axiosDeleteRequest,
  axiosGetRequest,
  put,
} from '../../../../services/Request.jsx';
import environment from '../../../../services/environment.jsx';
import NewSwitch from '../../../../components/NewSwitch.jsx';
import ViewSurveysFilter from './ViewSurveysFilter.jsx';
import NewTooltip from '../../../../components/NewTooltip.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import useExcelExport from '../../../../helpers/customHooks/useExcelExport.jsx';

const viewSurveysBreadcumbs = [
  {
    icon: programsIcon,
    title: 'الاستبيانات',
    url: '/satisfiction-survey',
  },
];

const sortColumnField = {
  title: 0,
  description: 1,
  startDate: 2,
  endDate: 3,
};

function ViewSurveysForAdmin() {
  const downloadExcel = useExcelExport();
  const history = useHistory();
  const formRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [addEditLoading, setAddEditLoading] = useState(false);
  const [excelLoading, setExcelLoading] = useState(false);
  const [surveyForEdit, setSurveyForEdit] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [maxResult, setMaxResult] = useState({ label: '5', value: 5 });

  const [filterInputs, setFilterInputs] = useState({});
  const [sortData, setSortData] = useState({
    sortType: 'asc',
    sortColumn: '',
  });

  const {
    data: surveys,
    isLoading: isGettingSurveys,
    refetch: refetchSurveys,
  } = useQuery({
    queryKey: [
      'getRateSurvey',
      maxResult.value,
      currentPage,
      filterInputs,
      sortData,
    ],
    queryFn: () =>
      axiosGetRequest(environment.getRateSurvey, {
        skipCount: currentPage * maxResult.value,
        maxResultCount: maxResult.value,
        orderType: sortColumnField[sortData.sortColumn]
          ? sortData?.sortType === 'asc'
            ? '0'
            : '1'
          : null,
        orderIndex: sortColumnField[sortData.sortColumn],
        ...filterInputs,
      }),
    select: (res) => res?.result,
  });

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current?.handleSubmit();
    }
  };

  const editSurvey = (
    {
      id,
      title,
      description,
      startDate,
      endDate,
      agenciesIds,
      usersIds,
      isPublished,
    },
    from
  ) => {
    if (surveyForEdit?.isPublished) {
      toast.error('لا يمكن تعديل الاستبيان حيث إن الاستبيان منشور.');
      return;
    }

    if (!title || !description || !startDate || !endDate) {
      toast.error('الرجاء ملء جميع الحقول');
      return;
    }

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };

    const data = {
      surveyId: +localStorage.getItem('surveyId'),
      id,
      title,
      description,
      startDate: new Date(startDate).toISOString(),
      endDate: new Date(endDate).toISOString(),
      agenciesIds,
      usersIds,
      isPublished: isPublished,
    };

    setAddEditLoading(true);
    put(environment.updateRateSurvey, data, config, (res) => {
      if (res.status === 200) {
        const switchMsg = isPublished
          ? 'تم نشر الاستبيان بنجاح'
          : 'تم ايقاف نشر الاستبيان بنجاح';
        toast.success(
          from === 'switch' ? switchMsg : 'تم تعديل الاستبيان بنجاح'
        );
      } else {
        toast.error(res.response.data.error.message);
      }
      setAddEditLoading(false);
      setOpen(false);
      setSurveyForEdit(null);
      refetchSurveys();
    });
  };

  const deleteRateSurvey = async (id) => {
    setDeleteLoading(true);
    const res = await axiosDeleteRequest(environment.deleteRateSurvey, {
      id,
    });
    setDeleteLoading(false);
    if (res?.success) {
      toast.success('تم الحذف بنجاح');
      refetchSurveys();
    }
  };

  const handleSort = (column, sortDirection) => {
    setSortData({
      sortColumn: column.accessorKey,
      sortType: sortDirection,
    });
  };

  const columns = [
    {
      name: 'اسم الاستبيان',
      center: true,
      accessorKey: 'title',
      id: 'title',
      sortable: true,
      selector: (row) => row.title,
    },
    {
      name: 'الوصف',
      center: true,
      accessorKey: 'description',
      id: 'description',
      sortable: true,
      selector: (row) => row.description,
    },
    {
      name: 'تاريخ البداية',
      center: true,
      accessorKey: 'startDate',
      id: 'startDate',
      sortable: true,
      selector: (row) => new Date(row.startDate).toLocaleDateString(),
    },
    {
      name: 'تاريخ النهاية',
      center: true,
      accessorKey: 'endDate',
      id: 'endDate',
      sortable: true,
      selector: (row) => new Date(row.endDate).toLocaleDateString(),
    },
    {
      name: 'استبيان منشور',
      center: true,
      cell: (row) => (
        <div>
          <NewSwitch
            value={row.isPublished}
            onsubmit={() =>
              !row.isPublished &&
              editSurvey({ ...row, isPublished: true }, 'switch')
            }
            onFalseClick={() =>
              row.isPublished &&
              editSurvey({ ...row, isPublished: false }, 'switch')
            }
            setValue={() => {}}
          />
        </div>
      ),
    },
    {
      name: 'الاجراءات',
      selector: (row) => row.actions,
      header: 'actions',
      accessorKey: 'actions',
      footer: (props) => props.column.actions,
      cell: (row) => (
        <div className="flex gap-2 items-center">
          <NewTooltip content={'تعديل'}>
            <FontAwesomeIcon
              icon={faPen}
              className="text-yellow-400 bg-orange-100 mr-1 rounded-full p-2 cursor-pointer"
              onClick={() => {
                setOpen(true);
                setSurveyForEdit(row);
              }}
            />
          </NewTooltip>
          <DeleteIcon
            id={row.id}
            loading={deleteLoading}
            deleteFunction={() => deleteRateSurvey(row.id)}
            deletedName={'الاستبيان'}
          />

          <button
            title="عرض الاجابات"
            className={
              'cursor-pointer w-[40px] h-[40px] transform scale-85 mr-2 rounded-full bg-[#1CC081] text-center'
            }
            onClick={() => {
              history.push(`/satisfiction-survey/${row.id}/surveyResponses`);
            }}
          >
            <img src={leftArrow} alt="arrow" className="mx-auto" />
          </button>
        </div>
      ),
    },
  ];

  const handleExcelExport = async () => {
    setExcelLoading(true);
    const res = await axiosGetRequest(environment.getRateSurvey, {
      SkipCount: 0,
      MaxResultCount: 1000,
    });
    const dataForExcel = res?.result?.items?.map((ele) => {
      return {
        الاستبيان: ele.title,
        الوصف: ele.description,
        'تاريخ البداية': new Date(ele.startDate).toLocaleDateString(),
        'تاريخ النهاية': new Date(ele.endDate).toLocaleDateString(),
        'حالة الاستبيان': ele.isPublished ? 'منشور' : 'غير منشور',
      };
    });
    downloadExcel('الاستبيانات', dataForExcel);
    setExcelLoading(false);
  };

  const handlePageClick = (event) => {
    const newOffset = event.selected;
    setCurrentPage(newOffset);
  };

  return (
    <HeaderWithBreadcrumbLayout
      breadcrumbArr={viewSurveysBreadcumbs}
      btnLabel={'إضافة استبيان'}
      handleBtnClick={() => {
        setOpen(true);
      }}
    >
      <ViewSurveysFilter setFilterInputs={setFilterInputs} />

      <NewTable
        withMaxResultChange
        maxResultValue={maxResult}
        setMaxResultValue={(value) => {
          setMaxResult(value);
          setCurrentPage(0);
        }}
        columns={columns}
        data={surveys?.items || []}
        pagination={true}
        totalPages={Math.ceil(surveys?.totalCount / maxResult.value)}
        onPageChange={handlePageClick}
        currentPage={currentPage}
        loading={isGettingSurveys}
        withExcel
        excelLoading={excelLoading}
        onExportExcel={handleExcelExport}
        handleRowClick={(row) =>
          history.push(`/satisfiction-survey/${row.id}/questions`)
        }
        handleSort={handleSort}
        defaultSortAsc={sortData.sortType === 'asc'}
        defaultSortFieldId={sortData.sortColumn}
      />
      <FModal
        content={
          <AddEditSurveyForm
            formRef={formRef}
            refetchSurveys={refetchSurveys}
            editSurvey={editSurvey}
            setAddEditLoading={setAddEditLoading}
            survey={surveyForEdit}
            showModal={(val) => setOpen(val)}
            isAdd={!surveyForEdit}
          />
        }
        open={open}
        onClose={() => {
          setOpen(false);
          setSurveyForEdit(null);
        }}
        action={handleSubmit}
        type={'submit'}
        headerTitle={surveyForEdit ? 'تعديل الاستبيان' : 'إضافة استبيان'}
        submitLabel={surveyForEdit ? 'تعديل' : 'إضافة'}
        loading={addEditLoading}
      />
    </HeaderWithBreadcrumbLayout>
  );
}

export default ViewSurveysForAdmin;
