import { useQuery } from '@tanstack/react-query';
import {
  axiosGetRequest,
  axiosPostRequest,
} from '../../../../services/Request';
import environment from '../../../../services/environment';
import { StageContext } from '../../../../contexts/stageContext';
import { useContext } from 'react';

export const useGetBestFiveAgencies = () => {
  const { currentStage } = useContext(StageContext);
  const phaseThreeStageId = currentStage?.stageIds?.[2];

  const { data, isLoading: isGettingBestFiveAgencies } = useQuery({
    queryKey: ['AdminBestFiveAgencies', phaseThreeStageId],
    queryFn: ({ signal }) =>
      axiosGetRequest(
        environment.GetTopFiveAgencyAnswered,
        { stageId: phaseThreeStageId },
        true,
        signal
      ),
  });

  const bestFiveAgencies = data?.result;

  return { data, bestFiveAgencies, isGettingBestFiveAgencies };
};
