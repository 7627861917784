import React, { useEffect, useState } from 'react';
import AppButton from '../../../../../../components/buttons/appButton';
import { DropdownMultiSelect, Title } from '../../../../../../components';
import UploadAdapter from '../../../../../../components/UploadAdapter';
import { useParams } from 'react-router';
import {
  axiosGetRequest,
  axiosPostRequest,
} from '../../../../../../services/Request';
import environment from '../../../../../../services/environment';
import { t } from 'i18next';
import SecondaryTapsLayout from '../../../../../../layouts/secondaryTapsLayout';
import { surveySettingTabs } from '../../data';
import { programsIcon } from '../../../../../../assets/icons/Index';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ErrorMessage, Form, Formik } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import AppEditor from '../../../../../../components/form/AppEditor';

const SurveyAddTemplate = () => {
  const [content, setContent] = useState('');
  const [attachment, setAttachment] = useState('');
  const [correctorDegrees, setCorrectorDegrees] = useState('');
  const [agencyDegrees, setAgencyDegrees] = useState('');

  const { surveyId, indicatorId } = useParams();

  const [addLoading, setAddLoading] = useState(false);
  const [commitmentLevel, setCommitmentLevel] = useState([]);
  const history = useHistory();

  const getCommitmentLevel = async () => {
    const res = await axiosGetRequest(
      environment.getCommitmentLevelBySurveyId,
      {
        SurveyId: surveyId,
      }
    );
    if (res?.success) {
      setCommitmentLevel(res?.result?.items);
    }
  };

  useEffect(() => {
    getCommitmentLevel();
  }, []);

  const commitment = commitmentLevel?.map((item) => ({
    id: item.id,
    label: item.title,
    value: item.id,
  }));

  const handleChangeCorrectorDegrees = (value) => {
    setCorrectorDegrees(value || '');
  };

  const handleChangeAgencyDegrees = (value) => {
    setAgencyDegrees(value || '');
  };

  const handleChangeAttachment = (value) => {
    setAttachment(value);
  };

  const handleSubmit = async (e) => {
    const correctorDegreeIds = [
      {
        commitmentLevelId: correctorDegrees.value,
      },
    ];

    const agencyDegreeIds = [{ commitmentLevelId: agencyDegrees.value }];

    const requestValues = {
      surveyId: Number(surveyId),
      correctorCommitmentLevels: correctorDegreeIds,
      agencyCommitmentLevels: agencyDegreeIds,
      templateContent: content,
      agencyHasAttachment: attachment?.value,
    };

    setAddLoading(true);
    const res = await axiosPostRequest(
      environment.createNoteTemplate,
      requestValues
    );
    setAddLoading(false);
    if (res?.success) {
      toast.success('تم إضافة قالب بنجاح');
      history.push(
        `/indicators/${indicatorId}/surveys/${surveyId}/settings/templates`
      );
    }
  };

  const initialValues = {
    content: '',
    correctorDegrees: '',
    agencyDegrees: '',
    attachment: '',
  };

  const validate = (values) => {
    const errors = {};
    if (!content) {
      errors.content = 'هذا الحقل مطلوب';
    }
    if (!correctorDegrees) {
      errors.correctorDegrees = 'هذا الحقل مطلوب';
    }
    if (!agencyDegrees) {
      errors.agencyDegrees = 'هذا الحقل مطلوب';
    }

    return errors;
  };

  return (
    <>
      <SecondaryTapsLayout
        linksArr={surveySettingTabs(
          `/indicators/${indicatorId}/surveys/${surveyId}`
        )}
        breadcrumbs={
          <div className="-mx-5">
            <Title
              iconTitle={programsIcon}
              subTitle={'اعدادات الاستبيان'}
              thired={true}
              subTitleSeconed={'قوالب الملاحظة'}
              title={t('المؤشرات')}
              withoutButton={true}
              toPage={() => history.push(`/indicators`)}
              toPageSeconed={() => history.push(`/indicators/${indicatorId}`)}
            />
          </div>
        }
        bgWhite
      >
        <Formik
          initialValues={initialValues}
          validate={validate}
          onSubmit={() => {
            handleSubmit();
          }}
        >
          {({ handleChange, values }) => (
            <Form className="">
              <div className="px-1 mt-4">
                <h2 className="text-lg text-blue_text mb-3">إضافة قالب</h2>
                <div className="mb-3">
                  <div className="mb-3">
                    <p className="mb-2">
                      المحتوى
                      <span className="mr-1">
                        <FontAwesomeIcon
                          className="w-2 text-red-700"
                          icon={faAsterisk}
                        />
                      </span>
                    </p>
                    <AppEditor
                      value={content}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setContent(data);
                        editor.plugins.get(
                          'FileRepository'
                        ).createUploadAdapter = function (loader) {
                          return new UploadAdapter(loader);
                        };
                      }}
                    />
                    <ErrorMessage
                      className="text-red-700"
                      name="content"
                      component="div"
                    />
                  </div>
                </div>

                <div className="mb-3">
                  <DropdownMultiSelect
                    isSearchable={true}
                    value={correctorDegrees}
                    handleChange={handleChangeCorrectorDegrees}
                    placeholder={t('اختر درجة المصحح')}
                    label={t(' درجة المصحح (خبير - مدقق - ضابط اتصال)')}
                    options={commitment}
                    name={'correctorDegrees'}
                    isMultiple={false}
                    isRequired={true}
                  />
                  <ErrorMessage
                    className="text-red-700"
                    name="correctorDegrees"
                    component="div"
                  />
                </div>

                <div className="mb-3">
                  <DropdownMultiSelect
                    isSearchable={true}
                    value={agencyDegrees}
                    handleChange={handleChangeAgencyDegrees}
                    placeholder={t('اختر درجة الجهة ')}
                    label={t(' درجة الجهة')}
                    options={commitment}
                    name={'agencyDegrees'}
                    isMultiple={false}
                    isRequired={true}
                  />
                  <ErrorMessage
                    className="text-red-700"
                    name="agencyDegrees"
                    component="div"
                  />
                </div>

                <div className="mb-3">
                  <DropdownMultiSelect
                    isSearchable={true}
                    value={attachment}
                    name={'attachment'}
                    handleChange={handleChangeAttachment}
                    placeholder={t('اختر حالة إرفاق المستند ')}
                    label={t(' حالة إرفاق المستند')}
                    options={[
                      {
                        id: 1,
                        label: 'تم ارفاق ملف',
                        value: true,
                      },
                      {
                        id: 2,
                        label: 'لم يتم ارفاق ملف',
                        value: false,
                      },
                    ]}
                    isMultiple={false}
                  />
                </div>

                <div className="my-4 flex justify-end gap-3 text-end">
                  <AppButton type="submit" loading={addLoading}>
                    إضافة
                  </AppButton>
                  <Link
                    to={`/indicators/${indicatorId}/surveys/${surveyId}/settings/templates`}
                  >
                    <AppButton variant="danger"> رجوع </AppButton>
                  </Link>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </SecondaryTapsLayout>
    </>
  );
};

export default SurveyAddTemplate;
