import { t } from 'i18next';
import { useContext, useEffect, useState } from 'react';
import lamp from '../../../../../../assets/icons/lamp.svg';
import procedures from '../../../../../../assets/icons/procedures.svg';
import { DropdownMultiSelect } from '../../../../../../components';
import AppEditor from '../../../../../../components/form/AppEditor';
import UploadAdapter from '../../../../../../components/UploadAdapter';
import { StandardProgramContext } from '../../../../../../contexts/StandardProgramContext';
import useAxiosGetRequest from '../../../../../../helpers/customHooks/useAxiosGetRequest';
import { permissionsCheck } from '../../../../../../helpers/utils';
import environment from '../../../../../../services/environment';
import SaveBtn from '../components/buttons/saveBtn';
import ExpertAnswerNotes from './expertAnswerNotes';

const StandardAnswerNotes = ({ addAnswerReviwer, addAnswerExpert }) => {
  const [selectedTemplates, setSelectedTemplates] = useState('');
  const standardProgramContext = useContext(StandardProgramContext);

  const [getTemplates, templateList] = useAxiosGetRequest();

  useEffect(() => {
    getTemplates(environment.getAllNoteTemplate);
  }, []);

  function extractTextFromHtml(htmlString) {
    var text = htmlString.replace(/<[^>]*>/g, '');

    text = text.replace(/\\/g, '');

    return text;
  }

  const templateListOptions =
    templateList?.result?.items &&
    templateList?.result?.items?.map((item) => {
      return {
        label: extractTextFromHtml(item.templateContent) || '',
        value: item.id,
      };
    });

  const handleChangeTemplates = (value) => {
    setSelectedTemplates(value || '');
    var myUl = '<ul>';

    for (var i = 0; i < value.length; i++) {
      myUl += '<li>' + value[i].label + '</li>';
    }

    myUl += '</ul>';

    if (permissionsCheck('Pages.UserType.Reviewer')) {
      standardProgramContext.setNoteReviewer(myUl);
    } else if (
      permissionsCheck('Pages.UserType.Expert') ||
      permissionsCheck('Pages.UserType.ExpertManager')
    ) {
      standardProgramContext.setNoteExpert(myUl);
    }
  };

  return (
    <>
      <div className="min-h-[440px]  p-3">
        <div className="w-[100%] flex flex-row justify-between items-center">
          <div className="flex flex-row items-center">
            <img
              className="bg-transparent ml-2"
              src={procedures}
              alt="procedures"
            />
            <h1 className="text-[#292069] font-black text-[20px]">
              {' '}
              ملاحظات التدقيق
            </h1>
          </div>
          {standardProgramContext?.standard?.answerWay && (
            <div>
              <div className="group cursor-pointer relative inline-block  w-28 text-center">
                {/* <img src={lamb} className="w-[25px]"/> */}
                <img className="bg-transparent" src={lamp} alt="lamp" />
                <div className="opacity-0 w-28 bg-[#292069] text-white text-center text-xs rounded-lg py-2 absolute z-10 group-hover:opacity-100 bottom-full -left-1/2 ml-14 px-3 pointer-events-none">
                  {standardProgramContext?.standard?.answerWay == ''
                    ? 'لا يوجد'
                    : standardProgramContext?.standard?.answerWay}
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="w-[100%] mt-7 pr-[88px] flex flex-col items-start overflow-y-auto scrollbar scrollbar-thumb-[#292069] scrollbar-track-gray-100 scrollGroup">
          {permissionsCheck('Pages.UserType.Reviewer') ? (
            <>
              <div className="w-[87%] mb-2">
                <h2 className="text-[#292069] text-lg font-bold mb-1">
                  ملاحظات الفريق الوطني للقياس
                </h2>
                <div
                  className="text=[#7D8BAB] text-[14px] mb-7"
                  dangerouslySetInnerHTML={{
                    __html:
                      standardProgramContext?.noteExpertForReviewer == null
                        ? '<p>لا يوجد ملاحظات للخبير</p>'
                        : standardProgramContext?.noteExpertForReviewer,
                  }}
                ></div>

                <div className="text=[#7D8BAB] text-[14px]">
                  {standardProgramContext?.standard?.programReviewerAnswer ==
                  null
                    ? null
                    : standardProgramContext?.standard
                        ?.expertToReviewerNoteDate}
                </div>
              </div>
              <div className="w-[87%] mb-2">
                <h2 className="text-[#292069] text-lg font-bold mb-3">
                  ملاحظات الفريق الوطني للقياس
                </h2>
                <div className="mb-4">
                  <DropdownMultiSelect
                    isSearchable={true}
                    value={selectedTemplates}
                    handleChange={handleChangeTemplates}
                    placeholder={t('اختر من القوالب')}
                    label={t('قوالب الملاحظات')}
                    options={templateListOptions}
                    isMultiple={false}
                  />
                </div>
                <AppEditor
                  value={
                    selectedTemplates?.label ||
                    standardProgramContext?.noteReviewer
                  }
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    standardProgramContext?.setNoteReviewer(data);

                    editor.plugins.get('FileRepository').createUploadAdapter =
                      function (loader) {
                        return new UploadAdapter(loader);
                      };
                  }}
                />
                <div className="text=[#7D8BAB] text-[14px]">
                  {standardProgramContext?.standard?.programReviewerAnswer ==
                  null
                    ? null
                    : standardProgramContext?.standard.reviewerNoteDate}
                </div>
              </div>
            </>
          ) : null}
          {permissionsCheck('Pages.UserType.POC') ||
          permissionsCheck('Pages.UserType.CIO') ||
          permissionsCheck('Pages.UserType.Delegate') ? (
            <div className="w-[87%] mb-2">
              <h2 className="text-[#292069] text-[16px] mb-2">
                ملاحظات الفريق الوطني للقياس
              </h2>
              <div
                className="text=[#7D8BAB] text-[14px]"
                dangerouslySetInnerHTML={{
                  __html:
                    standardProgramContext?.noteExpert == null
                      ? '<p>لا يوجد ملاحظات للخبير</p>'
                      : standardProgramContext?.noteExpert,
                }}
              ></div>
              <div className="text=[#7D8BAB] text-[14px]">
                {standardProgramContext?.standard?.programExpertAnswer == null
                  ? null
                  : standardProgramContext?.standard?.agencyNoteDate}
              </div>
            </div>
          ) : null}
          {permissionsCheck('Pages.UserType.Expert') ||
          permissionsCheck('Pages.UserType.ExpertManager') ? (
            <ExpertAnswerNotes templateList={templateList} />
          ) : null}
        </div>
      </div>
      {permissionsCheck('Pages.UserType.Reviewer') && (
        <SaveBtn onClick={addAnswerReviwer} />
      )}
      {(permissionsCheck('Pages.UserType.Expert') ||
        permissionsCheck('Pages.UserType.ExpertManager')) && (
        <SaveBtn onClick={addAnswerExpert} />
      )}
    </>
  );
};

export default StandardAnswerNotes;
