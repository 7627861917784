import { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAnswerContext } from '../../answerContext';
import ReadOnlyRequirements from '../../components/readOnlyRequirements';
import StandardAnswerRequirement from '../../standardAnswerRequirements';
import MainRequirement from '../../standardAnswerRequirements/ui/mainRequirement';
import SubRequirement from '../../standardAnswerRequirements/ui/subRequirement';
import AgencyRequirementAnswer from '../Agency/agencyRequirementAnswer';
import { StageContext } from '../../../../../../../contexts/stageContext';

const ExpertRequirementAnswerPhase4 = ({ setMenuElement }) => {
  const { currentStage } = useContext(StageContext);
  const isFifthStage = currentStage?.stageNumber === 5;

  const { standardId, entityId } = useParams();
  const { getSelfEvaluation, newExpertAnswer, setNewExpertAnswer } =
    useAnswerContext();

  const [selectedRequirementsTab, setSelectedRequirementsTab] =
    useState('expert');

  const handleRequirementChange = (requirement, property = 'decision') => {
    const updatedRequirements = newExpertAnswer?.standardRequirements?.map(
      (item) => {
        if (item?.id === requirement?.id) {
          const subStandardRequirements =
            requirement?.subStandardRequirements?.map((subReq) => {
              if (subReq.isDisabled) return subReq;
              return property === 'decision'
                ? {
                    ...subReq,
                    decision: requirement?.decision ? 0 : 1,
                    donotApply: 0,
                  }
                : {
                    ...subReq,
                    donotApply: !requirement?.donotApply,
                    decision: !requirement?.donotApply
                      ? 0
                      : requirement?.decision,
                  };
            });

          return property === 'decision'
            ? {
                ...requirement,
                subStandardRequirements,
                decision: requirement?.decision ? 0 : 1,
                donotApply: 0,
              }
            : {
                ...requirement,
                subStandardRequirements,
                donotApply: !requirement?.donotApply,
                decision: !requirement?.donotApply ? 0 : 1,
              };
        }

        return item;
      }
    );

    getSelfEvaluation(standardId, updatedRequirements, entityId);

    setNewExpertAnswer({
      ...newExpertAnswer,
      standardRequirements: updatedRequirements,
    });
  };

  const handleSubRequirementChange = (
    requirement,
    subReqId,
    property = 'decision'
  ) => {
    const updatedSubRequirements = newExpertAnswer?.standardRequirements?.map(
      (item) => {
        if (item?.id === requirement?.id) {
          const newSubReqs = requirement?.subStandardRequirements?.map(
            (subRequire) => {
              // if(subRequire.isDisabled) return subRequire;
              if (subReqId === subRequire?.id) {
                return property === 'decision'
                  ? {
                      ...subRequire,
                      decision: subRequire?.decision ? 0 : 1,
                      donotApply: 0,
                    }
                  : {
                      ...subRequire,
                      donotApply: !subRequire?.donotApply,
                      decision: !subRequire?.donotApply ? 0 : 1,
                    };
              }

              return subRequire;
            }
          );

          const isAllSubReqChecked = newSubReqs?.every(
            (value) => value?.decision == 1 || value?.donotApply === true
          );
          const isAllSubReqDonotApply = newSubReqs?.every(
            (value) => value?.donotApply === true
          );

          if (isAllSubReqChecked) requirement.decision = 1;
          else requirement.decision = 0;
          if (isAllSubReqDonotApply) requirement.donotApply = true;
          else requirement.donotApply = false;

          return { ...requirement, subStandardRequirements: newSubReqs };
        }

        return item;
      }
    );
    getSelfEvaluation(standardId, updatedSubRequirements, entityId);

    setNewExpertAnswer({
      ...newExpertAnswer,
      standardRequirements: updatedSubRequirements,
    });
  };

  const handleSelectAllReqs = (action = 'selectAll') => {
    const updatedRequirements = newExpertAnswer?.standardRequirements?.map(
      (req) => {
        if (req.isDisabled) return req;

        const subStandardRequirements = req?.subStandardRequirements?.map(
          (subReq) => {
            if (subReq.isDisabled) return subReq;
            return {
              ...subReq,
              donotApply: 0,
              decision: action === 'selectAll' ? 1 : 0,
            };
          }
        );

        return {
          ...req,
          subStandardRequirements,
          donotApply: 0,
          decision: action === 'selectAll' ? 1 : 0,
        };
      }
    );

    getSelfEvaluation(standardId, updatedRequirements, entityId);

    setNewExpertAnswer({
      ...newExpertAnswer,
      standardRequirements: updatedRequirements,
    });
  };
  const renderedExpertRequirements =
    newExpertAnswer?.standardRequirements?.length > 0 &&
    newExpertAnswer?.standardRequirements?.map((requirement) => {
      const hasSubRequirements =
        requirement?.subStandardRequirements?.length > 0;

      return (
        <>
          <MainRequirement
            key={requirement?.id}
            title={requirement?.title}
            hasSubRequirements={hasSubRequirements}
            checked={requirement?.decision}
            requirementDonotApplyChecked={requirement?.donotApply}
            onChange={() => {
              handleRequirementChange(requirement);
            }}
            handleDonotApplyRequirementChange={() =>
              handleRequirementChange(requirement, 'donotApply')
            }
            readOnly={requirement?.isDisabled || isFifthStage}
            disableDonotApply={isFifthStage}
          >
            {hasSubRequirements &&
              requirement?.subStandardRequirements?.map((subRequirement) => {
                return (
                  <>
                    <SubRequirement
                      key={subRequirement?.id}
                      title={subRequirement?.title}
                      checked={subRequirement?.decision}
                      onChange={() =>
                        handleSubRequirementChange(
                          requirement,
                          subRequirement?.id
                        )
                      }
                      subRequirementDonotApplyChecked={
                        subRequirement?.donotApply
                      }
                      readOnly={subRequirement?.isDisabled || isFifthStage}
                      disableDonotApply={isFifthStage}
                      handleDonotApplySubRequirementChange={() =>
                        handleSubRequirementChange(
                          requirement,
                          subRequirement?.id,
                          'donotApply'
                        )
                      }
                    />
                  </>
                );
              })}
          </MainRequirement>
        </>
      );
    });

  return (
    <div>
      <StandardAnswerRequirement setMenuElement={setMenuElement}>
        <div className="flex py-5 w-full justify-center">
          <div className="flex bg-[#F9F9FF] py-2 px-8 rounded-3xl">
            <p
              className={
                selectedRequirementsTab == 'reviewer'
                  ? 'bg-[#2B47AD] text-white px-5 pb-2.5  rounded-2xl ml-4 cursor-pointer'
                  : 'bg-transparent px-5 pb-2.5  rounded-2xl ml-4 cursor-pointer'
              }
              onClick={() => setSelectedRequirementsTab('reviewer')}
            >
              المدقق
            </p>

            <p
              className={
                selectedRequirementsTab == 'expert'
                  ? 'bg-[#2B47AD] text-white px-5 pb-2.5  rounded-2xl ml-4 cursor-pointer'
                  : 'bg-transparent px-5 pb-2.5  rounded-2xl ml-4 cursor-pointer'
              }
              onClick={() => setSelectedRequirementsTab('expert')}
            >
              الخبير
            </p>
            <p
              className={
                selectedRequirementsTab == 'pocOfficer'
                  ? 'bg-[#2B47AD] text-white px-5 pb-2.5  rounded-2xl cursor-pointer'
                  : 'bg-transparent px-5 pb-2.5  rounded-2xl  cursor-pointer'
              }
              onClick={() => setSelectedRequirementsTab('pocOfficer')}
            >
              ضابط الاتصال
            </p>
          </div>
        </div>

        {selectedRequirementsTab === 'agency' && <AgencyRequirementAnswer />}
        {selectedRequirementsTab === 'reviewer' && (
          <ReadOnlyRequirements
            requirements={newExpertAnswer?.reviewerRequirementsAnswer}
          />
        )}
        {selectedRequirementsTab === 'expert' && (
          <>
            {!isFifthStage && (
              <div className="flex justify-end w-full gap-4">
                <p
                  onClick={() => handleSelectAllReqs()}
                  className="mb-6 cursor-pointer text-end text-emerald-500"
                >
                  تحديد الكل
                </p>
                <p
                  onClick={() => handleSelectAllReqs('cancel')}
                  className="mb-6 cursor-pointer text-end text-red-800"
                >
                  الغاء تحديد الكل
                </p>
              </div>
            )}
            {renderedExpertRequirements}
          </>
        )}
        {selectedRequirementsTab === 'pocOfficer' && (
          <ReadOnlyRequirements
            requirements={newExpertAnswer?.pocOfficerRequirementsAnswer}
          />
        )}
      </StandardAnswerRequirement>
    </div>
  );
};

export default ExpertRequirementAnswerPhase4;
