import { Formik } from 'formik';
import { useQuery } from '@tanstack/react-query';
import NewDropdownMultiSelect from '../../../../components/NewDropDownMultiSelect';
import { InputLabel } from '../../../../components';
import { axiosGetRequest, post } from '../../../../services/Request';
import environment from '../../../../services/environment';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { formatDate, isValidDate } from '../../../../helpers/utils';
import AppRadio from '../../../../components/form/appRadio';

export const AddEditSurveyForm = ({
  formRef,
  survey,
  isAdd,
  refetchSurveys,
  editSurvey,
  showModal,
  setAddEditLoading,
}) => {
  const [internal, setInternal] = useState(survey?.isExternal ? '2' : '1');
  const [selectedUsers, setSelectedUsers] = useState(survey?.usersIds || []);
  const [selectedAgencies, setSelectedAgencies] = useState(
    survey?.agenciesIds || []
  );

  const {
    data: allUsers = [],
    isLoading: isGettingAllUsers,
    isFetched: isAllUsersFetched,
  } = useQuery({
    queryKey: [environment.getAllUsersExceptAgencyUsers],
    queryFn: () =>
      axiosGetRequest(environment.getAllUsersExceptAgencyUsers, null),
    select: (res) => res?.result?.items,
  });

  const {
    data: allAgencies = [],
    isLoading: isGettingAllAgencies,
    isFetched: isAllAgenciesFetched,
  } = useQuery({
    queryKey: ['getAllAgencyWithoutPagination'],
    queryFn: () =>
      axiosGetRequest(environment.getAllAgencyWithoutPagination, null),
    select: (res) => res?.result,
  });

  useEffect(() => {
    if (survey?.agenciesIds && isAllAgenciesFetched) {
      setSelectedAgencies(
        survey?.agenciesIds.map((id) => ({
          label: allAgencies.find((item) => item.id === id)?.nameInArabic || '',
          value: id,
        }))
      );
    }
  }, [isAllAgenciesFetched, survey?.agenciesIds, allAgencies]);

  useEffect(() => {
    if (survey?.usersIds && isAllUsersFetched) {
      setSelectedUsers(
        survey?.usersIds.map((id) => ({
          label: allUsers.find((item) => item.id === id)?.name || '',
          value: id,
        }))
      );
    }
  }, [isAllUsersFetched, survey?.usersIds, allUsers]);

  const handleChangeUsersIds = (value) => {
    setSelectedUsers(value);
  };

  const handleChangeAgencies = (value) => {
    setSelectedAgencies(value);
  };

  const addSurvey = ({ title, description, startDate, endDate }) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };

    let data = {
      surveyId: localStorage.getItem('surveyId'),
      title,
      description,
      startDate,
      endDate,
      agenciesIds: selectedAgencies?.map((item) => Number(item.value)),
      usersIds: selectedUsers?.map((item) => Number(item.value)),
      isPublished: false,
    };
    setAddEditLoading(true);
    post(environment.createRateSurvey, data, config, (res) => {
      if (res.status === 200) {
        toast.success('تم اضافة الاستبيان بنجاح');
        showModal(false);
      } else if (res.status === 500 || res.status === 400) {
        toast.error(res.data.error.message);
      }
      setAddEditLoading(false);
      refetchSurveys();
    });
  };

  const handleSubmit = (values, isAdd) => {
    if (isAdd) {
      addSurvey(values);
    } else {
      editSurvey({
        ...values,
        id: survey.id,
        usersIds: selectedUsers?.map((item) => Number(item.value)),
        agenciesIds: selectedAgencies?.map((item) => Number(item.value)),
        isPublished: survey.isPublished,
      });
    }
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={{
        title: survey?.title || '',
        description: survey?.description || '',
        usersIds: survey?.usersIds || [],
        agenciesIds: survey?.agenciesIds || [],
        startDate: formatDate(new Date(survey?.startDate)) || null,
        endDate: formatDate(new Date(survey?.endDate)) || null,
      }}
      validate={(values) => {
        const formatOptions = {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        };
        const today = new Date().toLocaleString('en-US', formatOptions);
        const startDate = new Date(values.startDate).toLocaleString(
          'en-US',
          formatOptions
        );
        const endDate = new Date(values.endDate).toLocaleString(
          'en-US',
          formatOptions
        );

        const errors = {};
        if (!values.title || !values.title.trim()) {
          errors.title = 'هذا الحقل مطلوب';
        }
        if (!values.description || !values.description.trim()) {
          errors.description = 'هذا الحقل مطلوب';
        }

        // if (selectedUsers?.length === 0) {
        //   errors.usersIds = "هذا الحقل مطلوب";
        // }

        if (selectedAgencies?.length === 0 && internal === '2') {
          errors.agenciesIds = 'هذا الحقل مطلوب';
        }

        if (selectedUsers?.length === 0 && internal === '1') {
          errors.usersIds = 'لا يوجد مستخدمين';
        }

        if (!startDate || !isValidDate(startDate)) {
          errors.startDate = 'هذا الحقل مطلوب';
        }

        if (!endDate || !isValidDate(endDate)) {
          errors.endDate = 'هذا الحقل مطلوب';
        }

        if (new Date(endDate) < new Date(today) && isValidDate(startDate)) {
          errors.endDate = 'تاريخ الانتهاء غير صحيح';
        }

        if (
          new Date(endDate).toString() == new Date(startDate).toString() &&
          isValidDate(startDate)
        ) {
          errors.endDate = 'لا يمكن ان يكون تاريخ البدايه نفس تاريخ الانتهاء';
        }
        if (new Date(startDate) > new Date(endDate) && isValidDate(startDate)) {
          errors.endDate =
            'لا يمكن أن يكون تاريخ البداية أكبر من تاريخ الانتهاء';
        }

        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        handleSubmit(values, isAdd);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <div>
          <InputLabel
            value={values.title}
            onChange={handleChange}
            name="title"
            type="text"
            label="اسم الاستبيان"
            placeholder="اسم الاستبيان"
            max={35}
          />
          <div className="text-sm text-red-600 my-3">
            {errors.title && touched.title && errors.title}
          </div>
          <InputLabel
            value={values.description}
            onChange={handleChange}
            name="description"
            type="text"
            label="الوصف"
            placeholder="الوصف"
            max={35}
          />
          <div className="text-sm text-red-600 my-3">
            {errors.description && touched.description && errors.description}
          </div>

          {isAdd && (
            <>
              <AppRadio
                label="داخلي"
                value="1"
                checked={`${internal}` === '1'}
                onChange={(e) => {
                  setInternal(e.target.value);
                  setSelectedAgencies([]);
                }}
              />
              <AppRadio
                label="خارجي"
                value="2"
                checked={`${internal}` === '2'}
                onChange={(e) => {
                  setInternal(e.target.value);
                  setSelectedUsers([]);
                }}
              />
            </>
          )}

          {internal === '1' && (
            <>
              <NewDropdownMultiSelect
                isSearchable={true}
                value={selectedUsers}
                handleChange={handleChangeUsersIds}
                placeholder="المستخدمين"
                label="المستخدمين"
                options={allUsers?.map((item) => ({
                  label: item.name,
                  value: item.id,
                }))}
                isMultiple={true}
                loading={isGettingAllUsers}
              />
              <div className="text-sm text-red-600 my-3">
                {errors.usersIds && touched.usersIds && errors.usersIds}
              </div>
            </>
          )}

          {internal === '2' && (
            <>
              <NewDropdownMultiSelect
                isSearchable={true}
                value={selectedAgencies}
                handleChange={handleChangeAgencies}
                placeholder="الجهات"
                label="الجهات"
                options={allAgencies?.map((item) => ({
                  label: item.nameInArabic,
                  value: item.id,
                }))}
                isMultiple={true}
                loading={isGettingAllAgencies}
              />
              <div className="text-sm text-red-600 my-3">
                {errors.agenciesIds &&
                  touched.agenciesIds &&
                  errors.agenciesIds}
              </div>
            </>
          )}

          <InputLabel
            placeholder="dd-mm-yyyy"
            label="تاريخ البداية"
            name="startDate"
            onChange={handleChange}
            value={values.startDate}
            type="date"
          />
          <div className="text-sm text-red-600 my-3">
            {errors.startDate && touched.startDate && errors.startDate}
          </div>
          <InputLabel
            placeholder={'22-10-2023'}
            label="تاريخ النهاية"
            name="endDate"
            onChange={handleChange}
            value={values.endDate}
            type="date"
          />
          <div className="text-sm text-red-600 mt-3">
            {errors.endDate && touched.endDate && errors.endDate}
          </div>
        </div>
      )}
    </Formik>
  );
};
