import { ErrorMessage, Form, Formik } from 'formik';
import { t } from 'i18next';
import { useState } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { AppInput, DropdownMultiSelect } from '../../../../../components';
import AppButton from '../../../../../components/buttons/appButton';
import AppColorInput from '../../../../../components/form/appColorInput';
import { axiosPostRequest } from '../../../../../services/Request';
import environment from '../../../../../services/environment';
import { commitmentOperatorsOptions } from './constants';

const defaultDegreesValue = {
  title: '',
  color: '#000000',
  rangeFrom: '',
  rangeTo: '',
  rangeFromOperator: null,
  rangeToOperator: null,
};

const AddServeyDegree = ({ equivalentResultList, setEquivalentResultList }) => {
  const { surveyId } = useParams();
  const [degree, setDegree] = useState(defaultDegreesValue);
  const [loading, setLoading] = useState(false);

  const handleInputsChange = (e) => {
    setDegree({ ...degree, [e.target.name]: e.target.value });
  };

  const handleRangeOperatorChange = (val, key) => {
    setDegree({
      ...degree,
      [key]: val,
    });
  };

  const handleSubmit = async () => {
    setLoading(true);
    const res = await axiosPostRequest(environment.createEquivalentResult, {
      ...degree,
      surveyId,
      rangeFromOperator: degree?.rangeFromOperator?.id,
      rangeToOperator: degree?.rangeToOperator?.id,
    });

    if (res.success == true) {
      toast.success('تم إضافة النتيجة ');
      const newDegrees = [...equivalentResultList, res.result];
      setEquivalentResultList(newDegrees);
      setDegree(defaultDegreesValue);
    }

    setLoading(false);
  };

  const initialValues = {
    title: '',
    color: '#000000',
    rangeFrom: '',
    rangeTo: '',
    rangeFromOperator: null,
    rangeToOperator: null,
  };

  const validate = (values) => {
    const errors = {};
    if (!degree.title) {
      errors.title = 'هذا الحقل مطلوب';
    }
    if (
      !degree.rangeFrom ||
      +degree.rangeFrom > +degree.rangeTo ||
      +degree.rangeFrom < 0 ||
      +degree.rangeFrom > 100
    ) {
      errors.rangeFrom =
        'لابد من اٍدخال نسبة من 0 اٍلي 100 وتكون أصغر من (النسبة اٍلي)';
    }
    if (
      !degree.rangeTo ||
      +degree.rangeTo < +degree.rangeFrom ||
      +degree.rangeTo < 0 ||
      +degree.rangeTo > 100
    ) {
      errors.rangeTo =
        'لابد من اٍدخال نسبة من 0 اٍلي 100 وتكون أكبر من (النسبة من)';
    }

    if (!degree?.rangeFromOperator?.label) {
      errors.rangeFromOperator = 'هذا الحقل مطلوب';
    }
    if (!degree?.rangeToOperator?.label) {
      errors.rangeToOperator = 'هذا الحقل مطلوب';
    }

    return errors;
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={handleSubmit}
    >
      {({ handleChange, values }) => (
        <Form className="">
          <div className="row"></div>
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-lg-4 mb-3">
                  <AppInput
                    id={`title-${0}-${degree.title}`}
                    label={t('النتيجة')}
                    value={degree.title}
                    name="title"
                    onChange={(e) => handleInputsChange(e)}
                  />
                  <ErrorMessage
                    className="text-red-700"
                    name="title"
                    component="div"
                  />
                </div>
                <div className="col-md-4 mb-3">
                  <AppColorInput
                    label={t('اللون')}
                    value={degree.color.toUpperCase()}
                    name="color"
                    onChange={(e) => handleInputsChange(e)}
                  />
                </div>
                <div className="col-lg-4 mb-3">
                  <AppInput
                    id={`title-${0}-${degree.title}`}
                    label={t('النسبة من')}
                    type="number"
                    value={degree.rangeFrom}
                    name="rangeFrom"
                    onChange={(e) => handleInputsChange(e)}
                  />
                  <ErrorMessage
                    className="text-red-700"
                    name="rangeFrom"
                    component="div"
                  />
                </div>

                <div className="col-lg-4 mb-3">
                  <DropdownMultiSelect
                    label={'العلامة'}
                    filter={true}
                    withoutLabel={false}
                    isSearchable={false}
                    value={degree?.rangeFromOperator}
                    handleChange={(val) =>
                      handleRangeOperatorChange(val, 'rangeFromOperator')
                    }
                    placeholder={'أختر علامة'}
                    options={commitmentOperatorsOptions}
                    isMultiple={false}
                  />

                  <ErrorMessage
                    className="text-red-700"
                    name="rangeFromOperator"
                    component="div"
                  />
                </div>

                <div className="col-lg-4 mb-3">
                  <AppInput
                    id={`title-${0}-${degree.title}`}
                    label={t('النسبة إلى')}
                    type="number"
                    value={degree.rangeTo}
                    name="rangeTo"
                    onChange={(e) => handleInputsChange(e)}
                  />
                  <ErrorMessage
                    className="text-red-700"
                    name="rangeTo"
                    component="div"
                  />
                </div>

                <div className="col-lg-4 mb-3">
                  <DropdownMultiSelect
                    label={'العلامة'}
                    filter={true}
                    withoutLabel={false}
                    isSearchable={false}
                    value={degree?.rangeToOperator}
                    handleChange={(val) =>
                      handleRangeOperatorChange(val, 'rangeToOperator')
                    }
                    placeholder={'أختر علامة'}
                    options={commitmentOperatorsOptions}
                    isMultiple={false}
                  />

                  <ErrorMessage
                    className="text-red-700"
                    name="rangeToOperator"
                    component="div"
                  />
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="row"></div>
            </div>

            <div className="col-12 mt-2 mb-2 flex justify-end">
              <div className="text-end ">
                <AppButton type="submit" loading={loading}>
                  إضافة جديد
                </AppButton>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddServeyDegree;
