import React from 'react';
import AppButton from '../../../../../../../components/buttons/appButton';

const NextBtn = ({ onClick, pocAnswer = false }) => {
  return (
    <div className=" sticky bottom-0 left-0 w-full h-16 ">
      <div className="bg-white_gred h-full flex justify-end items-start pl-8">
        {pocAnswer && (
          <p className="text-danger text-lg mt-3 pr-5">
            لقد تم ارسال الاجابات، لا يمكنك الاجابة الآن
          </p>
        )}
        <div className="mr-auto">
          <AppButton className="text-lg" onClick={onClick}>
            التالى
          </AppButton>
        </div>
      </div>
    </div>
  );
};

export default NextBtn;
