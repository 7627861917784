import { produce } from 'immer';
import { useContext, useEffect, useState } from 'react';
import { AddStandardContext } from '../addStandardContext';
import { AppInput, DropdownMultiSelect } from '../../../../../../components';
import NewDropdownMultiSelect from '../../../../../../components/NewDropDownMultiSelect';
import AppAccordion from '../../../../../../components/appAccordion';
import AppButton from '../../../../../../components/buttons/appButton';
import {
  handleFormValidation,
  handleInputValidation,
} from '../../../../../../helpers/utils';
import { addStandardProofsValidations } from '../addStandardValidation';

const StandardProofFileItem = ({
  index,
  title,
  number,
  standardProofTypeId,
  fileCount,
  standardProofFileSizeTypeId,
  fileSize,
  selectedProofFileAgenciesForEdit,
  selectedStandardProofRequirementsForEdit,
  standardProofFileTypeAssociations,
  disabled,
  id,
}) => {
  const {
    addFinalValue,
    setAddFinalValue,
    chooseDocType,
    fileTypes,
    sizeType,
    selectedAgencies,
  } = useContext(AddStandardContext);
  const renderedRequirements = addFinalValue.originalStandardRequirements ?? [];
  const requirementsOptions =
    renderedRequirements?.map((item) => ({
      value: item.id,
      label: item.title,
      id: item.id,
    })) ?? [];

  const itemAgencies = selectedProofFileAgenciesForEdit?.map((item) => ({
    value: item?.agencyId,
    label: item?.agencyNameInArabic?.toString() || '',
    id: item?.agencyId,
  }));

  const itemProofRequirements = selectedStandardProofRequirementsForEdit?.map(
    (item) => ({
      value: item?.standardRequirementId,
      label: renderedRequirements.find(
        (i) => i.id === item?.standardRequirementId
      )?.title,
      id: item?.standardRequirementId,
    })
  );

  const typesAssociations = standardProofFileTypeAssociations?.map(
    (item) => item?.standardProofFileTypeId
  );
  const fileType = fileTypes?.filter((item) =>
    typesAssociations?.includes(item?.id)
  );
  const fileSizeType = sizeType?.find(
    (item) => item?.value == standardProofFileSizeTypeId
  );
  const fileDocType = chooseDocType?.find(
    (item) => item?.value == standardProofTypeId
  );
  const types =
    (fileType?.length > 0 &&
      fileType.map((item) => ({
        standardProofFileTypeId: item.id,
      }))) ||
    [];

  const [isOpen, setIsOpen] = useState(false);
  const [selectedProofFileAgencies, setSelectedProofFileAgencies] =
    useState(itemAgencies);
  const [selectedRelatedRequirements, setSelectedRelatedRequirements] =
    useState(itemProofRequirements);

  const [values, setValues] = useState({
    id: id,
    title: title || '',
    standardProofTypeId:
      chooseDocType?.find((item) => item?.value == standardProofTypeId)?.id ||
      '',
    fileCount: fileCount || '',
    standardProofFileSizeTypeId:
      sizeType?.find((item) => item?.value == standardProofFileSizeTypeId)
        ?.id || '',
    standardProofFileTypeAssociations: [...types] || [],
    fileSize: fileSize || '',
    agencyStandardProofs: itemAgencies,
    standardProofRequirements: selectedStandardProofRequirementsForEdit,
  });

  const [errors, setErrors] = useState({
    title: '',
    standardProofTypeId: '',
    fileCount: '',
    standardProofFileSizeTypeId: '',
    standardProofFileTypeAssociations: [],
    fileSize: '',
    agencyStandardProofs: '',
  });
  const [selectedDocType, setSelectedDocType] = useState('');
  const [selectedFileTypes, setSelectedFileTypes] = useState([]);
  const [selectedSizeType, setSelectedSizeType] = useState('');

  useEffect(() => {
    setSelectedFileTypes(fileType);

    setSelectedDocType(fileDocType);
    setSelectedSizeType(fileSizeType);

    setSelectedProofFileAgencies(itemAgencies);
    setSelectedRelatedRequirements(itemProofRequirements);
    setAddFinalValue(
      produce((draft) => {
        draft.standardProofs[index] = values;
      })
    );
  }, []);

  const handleChangeProofFileAgencies = (value) => {
    setSelectedProofFileAgencies(value);
    validateInput('agencyStandardProofs', value);

    setValues({ ...values, agencyStandardProofs: value });
  };

  const validateInput = async (name, value) => {
    const { valid, errMsg } = await handleInputValidation(
      addStandardProofsValidations,
      name,
      value
    );

    if (valid) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
    } else if (errMsg) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: errMsg }));
    }
  };
  const handleChangeDocType = async (value) => {
    setSelectedDocType(value);
    await validateInput('standardProofTypeId', value?.label);
    setValues({
      ...values,
      standardProofTypeId: value?.id ? value?.id?.toString() : '',
    });
  };

  const handleChangeFileTypes = async (value) => {
    setSelectedFileTypes(value);
    await validateInput('standardProofFileTypeAssociations', value || []);

    const types =
      (value?.length > 0 &&
        value.map((item) => ({
          standardProofFileTypeId: item.id,
        }))) ||
      [];

    setValues({
      ...values,
      standardProofFileTypeAssociations: [...types],
    });
  };
  const handleChangeSizeType = async (value) => {
    setSelectedSizeType(value);
    await validateInput('standardProofFileSizeTypeId', value?.value);

    setValues({
      ...values,
      standardProofFileSizeTypeId: value?.id ? value?.id : '',
    });
  };

  const handleInputChange = async (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
    validateInput(name, value);
  };

  const handleDeleteClick = () => {
    setAddFinalValue(
      produce((draft) => {
        draft.standardProofs =
          draft.standardProofs.filter((item, itemIndx) => itemIndx != index) ||
          [];
      })
    );
  };

  const handleChangeRelatedRequirements = (value) => {
    setSelectedRelatedRequirements(value);
    setValues({
      ...values,
      standardProofRequirements: value,
    });
  };

  const handleSubmit = async () => {
    const { validForm, formErrors } = await handleFormValidation(
      addStandardProofsValidations,
      values
    );
    if (formErrors || !validForm) {
      setErrors({ ...errors, ...formErrors });
      return;
    }

    setAddFinalValue(
      produce((draft) => {
        draft.standardProofs[index] = values;
      })
    );

    setIsOpen(false);
  };

  return (
    <div className="mt-3">
      <AppAccordion
        number={number}
        title={title}
        variant="green"
        handleDeleteClick={handleDeleteClick}
        setIsOpen={setIsOpen}
        isOpen={isOpen}
      >
        <div className=" p-5 pb-7 rounded-lg bg-white mt-3">
          <h2 className="font-semibold mb-4">تعديل المستند</h2>

          <div className="row gap-y-3">
            <div className="col-md-6 col-xl-3">
              <AppInput
                variant="gray"
                label="اسم المستند"
                placeholder="اكتب هنا"
                name="title"
                value={values.title}
                onChange={handleInputChange}
                errorMessage={errors.title}
                disabled={disabled}
              />
            </div>
            <div className="col-md-6 col-xl-3">
              <DropdownMultiSelect
                isClearable={true}
                withoutLabel={false}
                isSearchable={true}
                value={selectedDocType}
                handleChange={handleChangeDocType}
                label="القيود"
                placeholder="اختر القيود"
                options={chooseDocType || []}
                isMultiple={false}
                errorMessage={errors.standardProofTypeId}
                isRequired={true}
                disabled={disabled}
              />
            </div>
            <div className="col-md-6 col-xl-3">
              <DropdownMultiSelect
                isClearable={true}
                withoutLabel={false}
                isSearchable={true}
                value={selectedSizeType}
                handleChange={handleChangeSizeType}
                label="نوع حجم الملف"
                placeholder="اختر نوع حجم الملف"
                options={sizeType || []}
                isMultiple={false}
                errorMessage={errors.standardProofFileSizeTypeId}
                isRequired={true}
                disabled={disabled}
              />
            </div>

            <div className="col-md-6 col-xl-3">
              <AppInput
                variant="gray"
                label="العدد"
                placeholder="اكتب هنا"
                name="fileCount"
                type="number"
                value={values.fileCount || ''}
                onChange={handleInputChange}
                errorMessage={errors.fileCount}
                disabled={disabled}
              />
            </div>
            <div className="col-md-6 col-xl-3">
              <AppInput
                variant="gray"
                label="حجم الملف"
                placeholder="اكتب هنا"
                name="fileSize"
                type="number"
                value={values.fileSize}
                onChange={handleInputChange}
                errorMessage={errors.fileSize}
                disabled={disabled}
              />
            </div>
            <div className="col-md-3">
              <NewDropdownMultiSelect
                isClearable={true}
                withoutLabel={false}
                isSearchable={true}
                value={selectedFileTypes}
                handleChange={handleChangeFileTypes}
                label="نوع الملف"
                placeholder="اختر نوع الملف"
                options={fileTypes || []}
                isMultiple={true}
                errorMessage={errors.standardProofFileTypeAssociations}
                disabled={disabled}
              />
            </div>
            <div className="col-md-3">
              <NewDropdownMultiSelect
                isClearable={true}
                withoutLabel={false}
                isSearchable={true}
                value={selectedProofFileAgencies || []}
                handleChange={handleChangeProofFileAgencies}
                label="الجهات الحكومية"
                options={selectedAgencies}
                isMultiple={true}
                isRequired={true}
                errorMessage={errors?.agencyStandardProofs}
                disabled={disabled}
              />
            </div>
            <div className="col-md-3">
              <NewDropdownMultiSelect
                isClearable={true}
                withoutLabel={false}
                isSearchable={true}
                value={selectedRelatedRequirements || []}
                handleChange={handleChangeRelatedRequirements}
                label="المتطلبات المرتبطة"
                options={requirementsOptions}
                isMultiple={true}
                isRequired={false}
              />
            </div>
            <div className="col-md-6 col-xl-3 self-end">
              <div className="text-end">
                <AppButton onClick={handleSubmit} disabled={false}>
                  تعديل مستند
                </AppButton>
              </div>
            </div>
          </div>
        </div>
      </AppAccordion>
    </div>
  );
};

export default StandardProofFileItem;
