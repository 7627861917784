function AppBlueTabs({ tabs, activeTab, setActiveTab }) {
  return (
    <div className="flex flex-col justify-start rounded-3xl">
      <div className="flex flex-row">
        {tabs?.map((tab, index) => (
          <div
            key={tab.title}
            className={
              'mr-5 cursor-pointer flex flex-row items-center justify-center py-2 px-5 h-12 rounded-t-[20px] ' +
              (activeTab === index
                ? 'text-white bg-[#202969]'
                : 'bg-slate-100 text-[#202969]')
            }
            onClick={() => setActiveTab(index)}
          >
            <button className="text-[17px] mr-2 text-center">
              {tab.title}
            </button>
          </div>
        ))}
      </div>

      <div className="p-5">
        {tabs.map((tab, index) => (
          <div key={index}>{activeTab === index && tab.content}</div>
        ))}
      </div>
    </div>
  );
}

export default AppBlueTabs;
