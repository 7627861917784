import { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  isAdmin,
  isExpertManager,
  isInquiryManager,
} from '../../../../../../../helpers/isPermission';
import { useAnswerContext } from '../../answerContext';
import ReadOnlyRequirements from '../../components/readOnlyRequirements';
import StandardAnswerRequirement from '../../standardAnswerRequirements';
import MainRequirement from '../../standardAnswerRequirements/ui/mainRequirement';
import SubRequirement from '../../standardAnswerRequirements/ui/subRequirement';
import { StageContext } from '../../../../../../../contexts/stageContext';
import { useQuery } from '@tanstack/react-query';
import {
  axiosGetRequest,
  axiosPostRequest,
} from '../../../../../../../services/Request';
import environment from '../../../../../../../services/environment';
import { permissionsCheck } from '../../../../../../../helpers/utils';

const ExpertManagerRequirementAnswerPhase4ShowingPhase2 = ({
  setMenuElement,
}) => {
  const { entityId, standardId } = useParams();
  const { currentStage } = useContext(StageContext);

  const [selectedRequirementsTab, setSelectedRequirementsTab] =
    useState('expertManager');

  const phase2StageId = currentStage?.stageIds?.[1];
  const isForthStage = currentStage?.stageNumber === 4;
  const isFifthStage = currentStage?.stageNumber === 5;

  const { data: expertMangerAnswerOnPhase2 } = useQuery({
    queryKey: [
      'getExpertManagerAnswer',
      entityId,
      phase2StageId,
      standardId,
      localStorage.getItem('userId'),
    ],
    queryFn: () =>
      axiosGetRequest(environment.getExpertManagerAnswer, {
        agencyId: entityId,
        stageId: phase2StageId,
        standardId: standardId,
        ExpertManagerId: localStorage.getItem('userId'),
      }),
    enabled: !!phase2StageId,
  });

  const { data: phaseOneAgencyAnswer } = useQuery({
    queryKey: ['prepareStandardForAnswer', standardId, entityId],
    queryFn: ({ signal }) =>
      axiosPostRequest(
        environment.prepareStandardForAnswer,
        {
          StandardId: standardId,
          AgencyId: entityId,
          StageId: currentStage?.stageIds?.[0],
        },
        null,
        true,
        true,
        false,
        signal
      ),
    enabled: !!(isForthStage || isFifthStage),
  });

  const reviewerRequirementsAnswer =
    phaseOneAgencyAnswer?.result?.standardRequirements?.map((requirement) => {
      const reqAnswer =
        expertMangerAnswerOnPhase2?.result?.lastAnswer?.reviewerRequirementAnswers?.find(
          (reqAns) => reqAns.standardRequirementId === requirement?.id
        );

      if (reqAnswer?.reviewerSubRequirementAnswers) {
        const { reviewerSubRequirementAnswers, ...reqs } = reqAnswer;

        const subRequirements = requirement?.subStandardRequirements?.map(
          (subReq) => {
            const sub = reviewerSubRequirementAnswers?.find(
              (item) => subReq?.id === item?.subStandardRequirementId
            );

            return {
              ...sub,
              title: subReq?.title,
              id: subReq.id,
            };
          }
        );

        return {
          ...reqs,
          title: requirement?.title,
          id: requirement.id,
          recommendation: '',
          subStandardRequirements: [...subRequirements],
        };
      }

      const subStandardRequirements = requirement?.subStandardRequirements?.map(
        (item) => ({
          ...item,
          decision: 0,
          donotApply: false,
        })
      );
      return {
        ...requirement,
        decision: 0,
        donotApply: false,
        subStandardRequirements,
      };
    });

  const pocOfficerRequirementsAnswer =
    phaseOneAgencyAnswer?.result?.standardRequirements?.map((requirement) => {
      const reqAnswer =
        expertMangerAnswerOnPhase2?.result?.lastAnswer?.pocOfficerRequirementAnswers?.find(
          (reqAns) => reqAns.standardRequirementId === requirement?.id
        );

      if (reqAnswer?.pocOfficerSubRequirementAnswers) {
        const { pocOfficerSubRequirementAnswers, ...reqs } = reqAnswer;

        const subRequirements = requirement?.subStandardRequirements?.map(
          (subReq) => {
            const sub = pocOfficerSubRequirementAnswers?.find(
              (item) => subReq?.id === item?.subStandardRequirementId
            );

            return {
              ...sub,
              title: subReq?.title,
              id: subReq.id,
            };
          }
        );

        return {
          ...reqs,
          title: requirement?.title,
          id: requirement.id,
          recommendation: '',
          subStandardRequirements: [...subRequirements],
        };
      }

      const subStandardRequirements = requirement?.subStandardRequirements?.map(
        (item) => ({
          ...item,
          decision: 0,
          donotApply: false,
        })
      );
      return {
        ...requirement,
        decision: 0,
        donotApply: false,
        subStandardRequirements,
      };
    });
  const expertRequirementsAnswer =
    phaseOneAgencyAnswer?.result?.standardRequirements?.map((requirement) => {
      const reqAnswer =
        expertMangerAnswerOnPhase2?.result?.lastAnswer?.expertRequirementAnswers?.find(
          (reqAns) => reqAns.standardRequirementId === requirement?.id
        );

      if (reqAnswer?.expertSubRequirementAnswers) {
        const { expertSubRequirementAnswers, ...reqs } = reqAnswer;

        const subRequirements = requirement?.subStandardRequirements?.map(
          (subReq) => {
            const sub = expertSubRequirementAnswers?.find(
              (item) => subReq?.id === item?.subStandardRequirementId
            );

            return {
              ...sub,
              title: subReq?.title,
              id: subReq.id,
            };
          }
        );

        return {
          ...reqs,
          title: requirement?.title,
          id: requirement.id,
          recommendation: '',
          subStandardRequirements: [...subRequirements],
        };
      }

      const subStandardRequirements = requirement?.subStandardRequirements?.map(
        (item) => ({
          ...item,
          decision: 0,
          donotApply: false,
        })
      );
      return {
        ...requirement,
        decision: 0,
        donotApply: false,
        subStandardRequirements,
      };
    });

  const expertManagerRequirementsAnswer =
    phaseOneAgencyAnswer?.result?.standardRequirements?.map((requirement) => {
      const reqAnswer =
        expertMangerAnswerOnPhase2?.result?.expertManagerAnswer?.expertManagerRequirementAnswers?.find(
          (reqAns) => reqAns.standardRequirementId === requirement?.id
        );

      if (reqAnswer?.expertManagerSubRequirementAnswers) {
        const { expertManagerSubRequirementAnswers, ...reqs } = reqAnswer;

        const subRequirements = requirement?.subStandardRequirements?.map(
          (subReq) => {
            const sub = expertManagerSubRequirementAnswers?.find(
              (item) => subReq?.id === item?.subStandardRequirementId
            );

            return {
              ...sub,
              title: subReq?.title,
              id: subReq.id,
            };
          }
        );

        return {
          ...reqs,
          title: requirement?.title,
          id: requirement.id,
          recommendation: '',
          subStandardRequirements: [...subRequirements],
        };
      }

      const subStandardRequirements = requirement?.subStandardRequirements?.map(
        (item) => ({
          ...item,
          decision: 0,
          donotApply: false,
        })
      );
      return {
        ...requirement,
        decision: 0,
        donotApply: false,
        subStandardRequirements,
      };
    });

  const renderedExpertManagerRequirements =
    expertManagerRequirementsAnswer?.length > 0 &&
    expertManagerRequirementsAnswer?.map((requirement) => {
      const hasSubRequirements =
        requirement?.subStandardRequirements?.length > 0;
      return (
        <>
          <MainRequirement
            key={requirement?.id}
            title={requirement?.title}
            hasSubRequirements={hasSubRequirements}
            checked={requirement?.decision}
            requirementDonotApplyChecked={requirement?.donotApply}
            onChange={() => {}}
            handleDonotApplyRequirementChange={() => {}}
          >
            {hasSubRequirements &&
              requirement?.subStandardRequirements?.map((subRequirement) => {
                return (
                  <>
                    <SubRequirement
                      key={subRequirement?.id}
                      title={subRequirement?.title}
                      checked={subRequirement?.decision}
                      onChange={() => {}}
                      subRequirementDonotApplyChecked={
                        subRequirement?.donotApply
                      }
                      handleDonotApplySubRequirementChange={() => {}}
                    />
                  </>
                );
              })}
          </MainRequirement>
        </>
      );
    });

  return (
    <div>
      <StandardAnswerRequirement setMenuElement={setMenuElement}>
        <div className="flex  pb-5 w-full">
          <p
            className={
              selectedRequirementsTab == 'reviewer'
                ? 'bg-indigo-200 px-3 pb-2 rounded ml-4 cursor-pointer'
                : 'bg-neutral-100 px-3 pb-2 rounded ml-4 cursor-pointer'
            }
            onClick={() => setSelectedRequirementsTab('reviewer')}
          >
            المدقق
          </p>

          <p
            className={
              selectedRequirementsTab == 'expert'
                ? 'bg-indigo-200 px-3 pb-2 rounded ml-4 cursor-pointer'
                : 'bg-neutral-100 px-3 pb-2 rounded ml-4 cursor-pointer'
            }
            onClick={() => setSelectedRequirementsTab('expert')}
          >
            الخبير
          </p>
          <p
            className={
              selectedRequirementsTab == 'pocOfficer'
                ? 'bg-indigo-200 px-3 pb-2 rounded ml-4 cursor-pointer'
                : 'bg-neutral-100 px-3 pb-2 rounded ml-4 cursor-pointer'
            }
            onClick={() => setSelectedRequirementsTab('pocOfficer')}
          >
            ضابط الاتصال
          </p>
          <p
            className={
              selectedRequirementsTab == 'expertManager'
                ? 'bg-indigo-800 text-white px-3 pb-2 rounded ml-4 cursor-pointer'
                : 'bg-emerald-50 px-3 pb-2 rounded ml-4 cursor-pointer'
            }
            onClick={() => setSelectedRequirementsTab('expertManager')}
          >
            رئيس الخبراء
          </p>
        </div>

        {selectedRequirementsTab === 'reviewer' && (
          <ReadOnlyRequirements requirements={reviewerRequirementsAnswer} />
        )}
        {selectedRequirementsTab === 'expert' && (
          <ReadOnlyRequirements requirements={expertRequirementsAnswer} />
        )}
        {selectedRequirementsTab === 'pocOfficer' && (
          <ReadOnlyRequirements requirements={pocOfficerRequirementsAnswer} />
        )}

        {!isExpertManager &&
          (isAdmin || isInquiryManager) &&
          selectedRequirementsTab === 'expertManager' && (
            <ReadOnlyRequirements
              requirements={expertManagerRequirementsAnswer}
            />
          )}
        {(isExpertManager ||
          permissionsCheck('Pages.UserType.Viewer') ||
          isAdmin) &&
          !isInquiryManager &&
          selectedRequirementsTab === 'expertManager' && (
            <>{renderedExpertManagerRequirements}</>
          )}
      </StandardAnswerRequirement>
    </div>
  );
};

export default ExpertManagerRequirementAnswerPhase4ShowingPhase2;
