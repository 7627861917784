import { useQuery } from '@tanstack/react-query';
import { axiosGetRequest } from '../../../../services/Request';
import environment from '../../../../services/environment';
import { StageContext } from '../../../../contexts/stageContext';
import { useContext } from 'react';

export const useGetAdminDashboardStats = () => {
  const surveyId = localStorage.getItem('surveyId');
  const { currentStage } = useContext(StageContext);
  const phaseThreeStageId = currentStage?.stageIds?.[2];

  const { data, isLoading: isGettingDashboardStats } = useQuery({
    queryKey: ['AdminDashboardStats', phaseThreeStageId],
    queryFn: ({ signal }) =>
      axiosGetRequest(
        environment.getAdminDashboardStats,
        {
          surveyId: surveyId,
          stageId: phaseThreeStageId,
        },
        true,
        signal
      ),
  });

  const adminStats = data?.result;

  return { data, adminStats, isGettingDashboardStats };
};
