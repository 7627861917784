import { useQueryClient, useQuery } from '@tanstack/react-query';
import { axiosGetRequest } from '../../services/Request';
import environment from '../../services/environment';

export const useGetAnswerHistory = ({ standardId, agencyId }) => {
  const queryClient = useQueryClient();

  const { data, isLoading: isGettingAnswerHistory } = useQuery({
    queryKey: ['AnswerHistory', standardId, agencyId],
    queryFn: () =>
      axiosGetRequest(environment.GetAnswerHistory, {
        StandardId: standardId,
        AgencyId: agencyId,
      }),
  });

  return {
    queryClient,
    data,
    isGettingAnswerHistory,
  };
};
