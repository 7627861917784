import Skeleton from 'react-skeleton-loader';

function TicketDetailsSkeleton() {
  return (
    <div
      style={{
        height: 'calc(60dvh - 1.25rem)',
      }}
      className="flex flex-row p-5 gap-5 overflow-y-hidden"
    >
      {/* Right Side */}
      <div className="flex-1 flex flex-col gap-5">
        <div className="">
          <label className="text-violet-950 text-lg ml-4">التفاصيل</label>
        </div>
        <div className="py-[18px] px-[25px]  bg-slate-50 rounded-xl flex flex-col gap-4">
          <div className="flex flex-col gap-1">
            <Skeleton height={106} />
            <Skeleton height={106} />
            <Skeleton height={106} />
          </div>
        </div>

        <div className="w-full flex flex-col gap-1">
          <div className="py-[18px] px-[25px] bg-slate-50 rounded-xl flex flex-col gap-1">
            <Skeleton height={106} />
            <Skeleton height={106} />
            <Skeleton height={106} />
          </div>
        </div>
      </div>

      {/* Left Side */}
      <div className="flex-1 overflow-y-auto px-10">
        <div className="flex flex-col gap-5">
          <div className="">
            <label className="text-violet-950 text-lg ml-4">التعليقات</label>
          </div>
          <div className="py-[18px] px-[25px] bg-slate-50 rounded-xl">
            {/* Placeholder for ticket histories */}
            <Skeleton count={4} height={50} />
          </div>

          {/* Placeholder for reply form */}
          <div className="py-[18px] px-[25px] bg-slate-50 rounded-xl">
            {/* Placeholder for reply form */}
            <Skeleton height={20} width={200} />
            <Skeleton count={3} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default TicketDetailsSkeleton;
