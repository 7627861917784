import { Redirect, Route } from 'react-router-dom';

const ProtectedRoute = ({ component, ...rest }) => {
  const surveyId = localStorage.getItem('surveyId');

  return (
    <Route
      {...rest}
      render={(props) =>
        surveyId ? component : <Redirect to="/ChooseCurrentIndicator" />
      }
    />
  );
};

export default ProtectedRoute;
