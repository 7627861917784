import React, { useState } from 'react';
import HeaderWithBreadcrumbLayout from '../../../../layouts/headerWithBreadcrumbLayout';
import { indicatorsBreadcumbs } from './content';
import IndicatorList from './indicatorList/index.jsx';
import AddIndicatorModal from './indicatorList/addIndicatorModal.jsx';
import { IndicatorsContextProvider } from '../../../../contexts/indicatorsContext.jsx';
import { permissionsCheck } from '../../../../helpers/utils';
import CopyIndicatorModal from './indicatorList/copyIndicatorModal.jsx';

const Indicators = () => {
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openCopyModal, setOpenCopyModal] = useState(false);

  return (
    <IndicatorsContextProvider>
      <HeaderWithBreadcrumbLayout
        breadcrumbArr={indicatorsBreadcumbs}
        btnLabel="إضافة مؤشر"
        btnLabelSeconed="نسخ مؤشر"
        handleBtnClick={() => setOpenAddModal(true)}
        handleBtnSeconedClick={() => setOpenCopyModal(true)}
        hideBtn={permissionsCheck('Pages.Indicators.Create') ? false : true}
      >
        <AddIndicatorModal
          openAddModal={openAddModal}
          setOpenAddModal={setOpenAddModal}
        />
        <CopyIndicatorModal
          openAddModal={openCopyModal}
          setOpenAddModal={setOpenCopyModal}
        />
        <IndicatorList />
      </HeaderWithBreadcrumbLayout>
    </IndicatorsContextProvider>
  );
};

export default Indicators;
