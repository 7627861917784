import strategicPlanning from '../../../../../../assets/icons/strategicPlanning.svg';
import Skeleton from 'react-skeleton-loader';
import NextBtn from '../components/buttons/nextBtn';
import { useAnswerContext } from '../answerContext';

const StandardAnswerData = ({ onNext, loading }) => {
  const { newAgencyAnswer } = useAnswerContext();

  return (
    <>
      <div className="min-h-[440px] p-3">
        <div className="w-[100%] flex flex-row justify-between items-center">
          <div className="flex flex-row items-center">
            <img
              className="bg-transparent ml-2"
              src={strategicPlanning}
              alt="strategic planning"
            />
            <h1 className="text-[#292069] font-black text-[20px]">
              {newAgencyAnswer?.title}
            </h1>
          </div>
        </div>
        <div className="mt-7 pr-[88px] max-w-[90%] overflow-y-auto scrollbar scrollbar-thumb-[#292069] scrollbar-track-gray-100 scrollGroup">
          {loading ? (
            <div className="flex border-y-2 pb-5  pt-2 ">
              <h2 className="ml-3 w-24 text-[16px] flex-shrink-0 font-bold">
                <Skeleton width="80px" />
              </h2>
              <p className="text-[17px]">
                <Skeleton width="300px" />
              </p>
            </div>
          ) : (
            <div className="flex border-y-2 pb-5  pt-2 ">
              <h2 className="ml-3 w-24 text-[16px] flex-shrink-0 font-bold">
                {newAgencyAnswer?.code}
              </h2>
              <p className="text-[17px]">{newAgencyAnswer?.title}</p>
            </div>
          )}
          <div className="flex border-b-2 pb-5 pt-2">
            <h2 className="text-[16px] ml-3 flex-shrink-0 w-24 text-[#7D8BAB]">
              الهدف
            </h2>
            {loading ? (
              <p className="text-[17px]">
                <Skeleton width="300px" />
              </p>
            ) : (
              <p className="text-[17px]">{newAgencyAnswer?.target}</p>
            )}
          </div>

          {/* {(localStorage?.getItem("userType") == "expert" ||
            localStorage?.getItem("userType") == "expertManager" ||
            localStorage?.getItem("userType") == "Reviewer" ||
            localStorage?.getItem("userType") == "admin") && (
            <div className="flex border-b-2 pb-5 pt-2">
              <h2 className="ml-3 w-24 text-[16px] flex-shrink-0 text-[#7D8BAB]">
                وزن المعيار
              </h2>
              {loading ? (
                <p className="text-[17px]">
                  <Skeleton width="50px" />
                </p>
              ) : (
                <p className="text-[17px]">{newAgencyAnswer?.weight}%</p>
              )}
            </div>
          )} */}
          <div className="border-b-2 pb-5  pt-2 ">
            <h2 className="ml-3 text-[16px] text-[#7D8BAB]">
              الأوامر والقرارات والتعاميم المرتبطة
            </h2>
            {loading ? (
              <p className="text-[17px] px-5 mt-2">
                <Skeleton width="300px" />
              </p>
            ) : (
              <div className="text-[17px] px-5 mt-2">
                <ul>
                  {newAgencyAnswer?.royalOrdersAndCabinetDecisions
                    ?.split('.')
                    ?.map((item) => {
                      if (!item || item == '\n' || item == '\n\n') return;
                      return (
                        <li className="leading-loose text-gray-900">
                          - {item}.
                        </li>
                      );
                    }) || 'لا يوجد'}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>

      <NextBtn onClick={onNext} />
    </>
  );
};

export default StandardAnswerData;
