import axios from 'axios';
import React, { useEffect, useState } from 'react';
import SecondaryTapsLayout from '../../../../layouts/secondaryTapsLayout';
import { Title } from '../../../../components';
import { plusLight, programsIcon } from '../../../../assets/icons/Index';
import { t } from 'i18next';
import environment from '../../../../services/environment';
import { tokenConfig } from '../../../../constants/contents';
import PermissionsDetail from './PermissionsDetail';
import { useHistory } from 'react-router-dom';
import { permissionsCheck } from '../../../../helpers/utils';

const UserPermissions = () => {
  const history = useHistory();
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(false);

  const getAllRoles = async () => {
    setLoading(true);
    const res = await axios.get(
      `${process.env.REACT_APP_URL}/${environment.getAllRoles}?MaxResultCount=1000`,
      tokenConfig
    );

    setRoles(res.data?.result?.items);
    setLoading(false);
  };

  useEffect(() => {
    getAllRoles();
  }, []);

  const sidebarLinks = roles?.map((role) => ({
    id: role?.id,
    title: role?.displayName,
    url: `/permissions?id=${role?.id}`,
  }));

  return (
    <SecondaryTapsLayout
      linksArr={sidebarLinks}
      loading={loading}
      breadcrumbs={
        <Title
          iconTitle={programsIcon}
          title={t('modules.main.users.permissions.title')}
          seconed={true}
          subTitle={t('modules.main.users.permissions.subTitle')}
          withoutButton={permissionsCheck('Pages.Roles.Create') ? false : true}
          textStyle={{ color: 'white', fontSize: 18 }}
          titleButton={t('modules.main.users.permissions.titleAddButton')}
          withIcon={true}
          icon={plusLight}
          onClick={() => history.push('/permissions/add')}
          style={{ backgroundColor: '#2B2969' }}
        />
      }
    >
      <PermissionsDetail />
    </SecondaryTapsLayout>
  );
};

export default UserPermissions;
