import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import AppDivider from '../../../../../../components/appDivider';
import { axiosGetRequest } from '../../../../../../services/Request';
import environment from '../../../../../../services/environment';
import AddServeyDegree from '../addDegree';
import DegreeItemSkeleton from './DegreeItemSkeleton';
import DegreeItem from './degreeItem';

const SurveyDegreeList = () => {
  const { surveyId } = useParams();
  const [loading, setLoading] = useState(false);
  const [degreeList, setDegreeList] = useState([]);

  const getDegreeList = async () => {
    setLoading(true);
    const res = await axiosGetRequest(
      environment.getCommitmentLevelBySurveyId,
      {
        SurveyId: surveyId,
      }
    );
    if (res?.success) {
      setDegreeList(res?.result?.items);
    }
    setLoading(false);
  };

  useEffect(() => {
    getDegreeList();
  }, []);

  const renderedDegreeList = degreeList?.map((item) => (
    <DegreeItem
      {...item}
      key={item.id}
      surveyId={surveyId}
      setDegreeList={setDegreeList}
      degreeList={degreeList}
    />
  ));
  return (
    <>
      <AddServeyDegree degreeList={degreeList} setDegreeList={setDegreeList} />
      <AppDivider />
      {loading ? (
        <>
          <DegreeItemSkeleton />
          <DegreeItemSkeleton />
        </>
      ) : (
        renderedDegreeList
      )}
    </>
  );
};

export default SurveyDegreeList;
