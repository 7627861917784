import { useState } from 'react';
import { AppInput } from '../../../../../components';
import DrawerFilter from '../../../../../components/DrawerFilter';

function AnswersReportFilter({ setFilterInputs }) {
  const initialValues = {
    agencyName: '',
    standardCode: '',
    auditorName: '',
    expertName: '',
  };
  const [inputs, setInputs] = useState(initialValues);

  const handleChangeInputs = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  return (
    <div className="flex justify-end mx-2">
      <DrawerFilter
        handleSearch={() => {
          setFilterInputs(inputs);
        }}
        handleClear={() => setInputs(initialValues)}
      >
        <div className="w-full">
          <AppInput
            name="agencyName"
            value={inputs.agencyName}
            onChange={handleChangeInputs}
            label={'اسم الجهة'}
            isRequired={false}
          />
        </div>

        <div className="w-full">
          <AppInput
            name="standardCode"
            value={inputs.standardCode}
            onChange={handleChangeInputs}
            label={'رمز المعيار'}
            isRequired={false}
          />
        </div>

        {/* <div className="w-full">
          <AppInput
            name="auditorName"
            value={inputs.auditorName}
            onChange={handleChangeInputs}
            label={"اسم المدقق"}
            isRequired={false}
          />
        </div>

        <div className="w-full">
          <AppInput
            name="expertName"
            value={inputs.expertName}
            onChange={handleChangeInputs}
            label={"اسم الخبير"}
            isRequired={false}
          />
        </div> */}
      </DrawerFilter>
    </div>
  );
}

export default AnswersReportFilter;
