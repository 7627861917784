import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { calendarGreen } from '../../../assets/icons/Index';
import { Button } from '../../../components';
import SCalendar from '../../../components/SCalendar';
import UploadAdapter from '../../../components/UploadAdapter';
import { formatDate, permissionsCheck } from '../../../helpers/utils';
import environment from '../../../services/environment';
import { get, post } from '../../../services/Request';
import { toast } from 'react-toastify';
import Skeleton from 'react-skeleton-loader';
import { errorToast } from '../../../helpers/toast';
import AppEditor from '../../../components/form/AppEditor';

const Visit = ({ setActiveTab, getAllStandardsVisitAgency }) => {
  const { t } = useTranslation();
  const [description, setDescription] = useState('');
  const [date, setDate] = useState('');
  const [selectedDate, setSelectedDate] = useState(Date.now());

  const [day, setDay] = useState('');
  const [noneReservedvisitSlots, setNoneReservedVisitSlots] = useState([]);
  const [reservedDates, setReservedDates] = useState([]);
  const [noneReservedId, setNoneReservedId] = useState('');

  useEffect(() => {
    getAllVisitSlots();
  }, []);

  const [availableDates, setAvailableDates] = useState([]);

  const getAllVisitSlots = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };

    let agencyId = '';
    if (
      permissionsCheck('Pages.UserType.POC') ||
      permissionsCheck('Pages.UserType.CIO') ||
      permissionsCheck('Pages.UserType.Delegate')
    ) {
      agencyId = '?AgencyId=' + localStorage.getItem('agencyId');
    }
    get(environment.getVisitSlot + agencyId, config, (res) => {
      if (res.status == 403) {
        toast.error(t('general.authError'));
      } else if (res.status == 500) {
        toast.error(t('general.serverError'));
      } else if (res.status == 200) {
        let arrDates = [];
        res.data.result.items.forEach((element) => {
          if (element.visitSlotStatus == 1) {
            arrDates.push(new Date(element.visitDate.slice(0, 10)));
          }
        });
        setReservedDates(arrDates);
        let availableArr = [];
        res.data.result.items.forEach((element) => {
          if (element.visitSlotStatus == 2) {
            availableArr.push(element.visitDate.slice(0, 10));
          }
        });
        setAvailableDates(availableArr);

        getAllStandardsVisit();
      }
    });
  };

  const getAllStandardsVisit = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    get(environment.getAllStandardVisitSlot, config, (res) => {
      if (res?.status == 403) {
      } else if (res?.status == 200) {
        getAllStandardsVisitAgency();
      }
    });
  };

  const [nonReservedLoading, setNonReservedLoading] = useState(false);

  const getAllNonReservedVisitSlots = (date) => {
    setNonReservedLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    let agencyId = '';
    if (
      permissionsCheck('Pages.UserType.POC') ||
      permissionsCheck('Pages.UserType.CIO') ||
      permissionsCheck('Pages.UserType.Delegate')
    ) {
      agencyId = '&agenyId=' + localStorage.getItem('agencyId');
    }
    get(
      environment.getAllNonReservedVisitSlotsBySpecificDate +
        '?date=' +
        date +
        agencyId,
      config,
      (res) => {
        setNonReservedLoading(false);
        if (res.status == 200) {
          setNoneReservedVisitSlots(res.data.result.items);
        } else {
          setNoneReservedVisitSlots([]);
        }
      }
    );
  };

  const getDate = (date) => {
    setSelectedDate(date);
    setDate(
      formatDate(new Date(date)),
      getAllNonReservedVisitSlots(formatDate(new Date(date)))
    );
    setDay(date.toString().split(' ')[0]);
  };

  const [reserveSlotLoading, setReserveSlotLoading] = useState(false);

  const reserveSlot = () => {
    if (!noneReservedId) {
      errorToast('لابد من اختيار موعد من الأوقات المتاحة');
      return;
    }
    setReserveSlotLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    let data = {
      agencyId: localStorage.getItem('agencyId'),
      visitSlotId: noneReservedId,
      description: description,
    };
    post(environment.reserveSlot, data, config, (res) => {
      setReserveSlotLoading(false);
      if (res.status == 500) {
        toast.error(res.data.error.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
        getAllVisitSlots();
      } else {
        toast.success('تم حجز  موعد بنجاح', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
        setActiveTab(1);
        getAllVisitSlots();
      }
    });
  };
  return (
    <div className="bg-white rounded-[30px] m-5">
      <div className="flex justify-end p-3"></div>
      <div className="grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 xl:grid-cols-2 px-5">
        <div className="p-3">
          <SCalendar
            available={availableDates}
            dateCalendar={selectedDate}
            getDate={getDate}
            disabledDates={reservedDates}
          />
          <div className="grid sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 xl:grid-cols-3">
            <div className="flex flex-row items-center mt-2">
              <div className="w-[25px] h-[25px] bg-[#A1A9C4] rounded-lg mr-2 ml-2"></div>
              <div className="text-[13px] text-[#7D8BAB]">
                التواريخ غير المتاحة
              </div>
            </div>
            <div className="flex flex-row items-center mt-2">
              <div className="w-[25px] h-[25px] bg-[#E8F9F2] rounded-lg mr-2 ml-2"></div>
              <div className="text-[13px] text-[#7D8BAB]">التواريخ المتاحة</div>
            </div>
            <div className="flex flex-row items-center mt-2">
              <div className="w-[25px] h-[25px] bg-[#554AA7] rounded-lg mr-2 ml-2"></div>
              <div className="text-[13px] text-[#7D8BAB]">التاريخ المختار</div>
            </div>
          </div>
        </div>
        <div>
          <div className="w-full flex flex-col mt-5 p-3">
            <label className="text-[16px] text-[#292069] mb-3">
              التاريخ المختار
            </label>
            <div className="w-full bg-[#292069] rounded-3xl py-3 px-6 flex flex-row justify-between items-center">
              <div className="flex flex-row items-center">
                <div className="text-white text-[13px]">{day}</div>
                <div className="text-white text-[13px] mr-5 ml-5">{date}</div>
              </div>
              <img src={calendarGreen} />
            </div>
          </div>
          <div className="w-full flex flex-col mt-5 p-3">
            <label className="text-[16px] text-[#292069] mb-3">
              الاوقات المتاحة
            </label>
            {nonReservedLoading ? (
              <div className="w-full flex flex-col gap-5  mt-2">
                <Skeleton width={'500px'} height={'40px'} color={'#F9F9FF'} />
                <Skeleton width={'500px'} height={'40px'} color={'#F9F9FF'} />
                <Skeleton width={'500px'} height={'40px'} color={'#F9F9FF'} />
              </div>
            ) : noneReservedvisitSlots.length == 0 ? (
              <div className="flex flex-row items-center">لا يوجد اوقات </div>
            ) : (
              <>
                {noneReservedvisitSlots.map((item, index) => (
                  <div
                    className={`w-full bg-[${
                      noneReservedId == item.id ? '#292069' : '#F9F9FF'
                    }] rounded-3xl py-3 px-6 flex flex-row justify-between items-center cursor-pointer mt-2`}
                    onClick={() => setNoneReservedId(item.id)}
                  >
                    <div className="flex flex-row items-center">
                      <div
                        className={`${
                          noneReservedId == item.id
                            ? 'text-white'
                            : 'text-[#7D8BAB]'
                        } text-[13px]`}
                      >
                        من {`${item.startTime}`}{' '}
                      </div>
                      <div
                        className={`${
                          noneReservedId == item.id
                            ? 'text-white'
                            : 'text-[#7D8BAB]'
                        } text-[13px] mr-2 ml-2`}
                      >
                        الي {`${item.endTime}`}{' '}
                      </div>
                    </div>
                    <img src={calendarGreen} />
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
        <div></div>
      </div>

      {permissionsCheck('Pages.UserType.POC') ||
      permissionsCheck('Pages.UserType.CIO') ||
      permissionsCheck('Pages.UserType.Delegate') ? (
        <div className="px-5 mt-5 w-full">
          <label className="text-[16px] text-[#292069]">أجندة الإجتماع</label>
          <div className="mt-5">
            <AppEditor
              value={description}
              onChange={(event, editor) => {
                const data = editor.getData();
                setDescription(data);
                editor.plugins.get('FileRepository').createUploadAdapter =
                  function (loader) {
                    return new UploadAdapter(loader);
                  };
              }}
            />
          </div>
        </div>
      ) : null}

      {permissionsCheck('Pages.UserType.POC') ||
      permissionsCheck('Pages.UserType.CIO') ||
      permissionsCheck('Pages.UserType.Delegate') ? (
        <div className="px-5 flex flex-row justify-end">
          <Button
            onClick={() => reserveSlot()}
            loading={reserveSlotLoading}
            title="حجز الموعد"
            style={{ marginBottom: '50px', marginTop: '50px', width: '12rem' }}
          />
        </div>
      ) : null}
    </div>
  );
};

export default Visit;
