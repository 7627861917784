import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactPaginate from 'react-paginate';

function AppPagination({ loading, handlePageClick, currentPage, totalPages }) {
  return (
    <div className="pb-5 pt-5">
      <ReactPaginate
        nextLabel={
          loading == true || currentPage == totalPages - 1 ? null : (
            <div>
              <span className="ml-2">التالي</span>
              <FontAwesomeIcon
                icon={faChevronLeft}
                style={{ color: '#A1A9C4', fontSize: 14 }}
              />
            </div>
          )
        }
        onPageChange={handlePageClick}
        forcePage={currentPage}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        pageCount={totalPages}
        previousLabel={
          loading == true || currentPage == 0 ? null : (
            <div>
              <FontAwesomeIcon
                icon={faChevronRight}
                style={{ color: '#A1A9C4', fontSize: 14 }}
              />
              <span className="mr-2">السابق</span>
            </div>
          )
        }
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item-previous"
        previousLinkClassName="page-link-previous"
        nextClassName="page-item-next"
        nextLinkClassName="page-link-next"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active"
        renderOnZeroPageCount={null}
      />
    </div>
  );
}

export default AppPagination;
