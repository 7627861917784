import { Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { Footer } from '../components/Footer';
import { ProgressBar2 } from '../components/ProgressBar2';

export function Page12({ agencyTitle, agencyStandardsCount, globalStyles }) {
  const styles = StyleSheet.create({
    ...globalStyles,
  });

  const commitmentLevels = [
    {
      commitmentLevelId: 384,
      commitmentLevelTitle: 'التزام كلى',
      commitmentLevelColor: '#1dc182',
      standardCounts: 0,
      asPercentage: 0,
      fixedDegree: 0,
    },
    {
      commitmentLevelId: 385,
      commitmentLevelTitle: 'التزام جزئى',
      commitmentLevelColor: '#ffa92b',
      standardCounts: 0,
      asPercentage: 0,
      fixedDegree: 0,
    },
    {
      commitmentLevelId: 386,
      commitmentLevelTitle: 'عدم التزام',
      commitmentLevelColor: '#f15768',
      standardCounts: 10,
      asPercentage: 10.989,
      fixedDegree: 0,
    },
    {
      commitmentLevelId: 394,
      commitmentLevelTitle: 'لا ينطبق',
      commitmentLevelColor: '#8b817b',
      standardCounts: 0,
      asPercentage: 0,
      fixedDegree: 0,
    },
    {
      commitmentLevelId: 393,
      commitmentLevelTitle: 'لا يوجد اجابة',
      commitmentLevelColor: '#000000',
      standardCounts: 0,
      asPercentage: 0,
      fixedDegree: 0,
    },
  ];

  return (
    <Page style={styles.page}>
      <Text style={{ ...styles.sectionTitle, marginTop: 20 }}>
        05. النتيجة التفصيلية
      </Text>
      <Text style={{ ...styles.sectionSecondaryTitle, marginBottom: 20 }}>
        الالتزام بالمعايير
      </Text>
      <View>
        {commitmentLevels?.map((item, index) => (
          <ProgressBar2
            key={index}
            label={item.commitmentLevelTitle}
            percentage={
              agencyStandardsCount?.find(
                (x) => x.commitmentLevelId === item.commitmentLevelId
              )?.asPercentage ?? 0
            }
            standardsCount={
              agencyStandardsCount?.find(
                (x) => x.commitmentLevelId === item.commitmentLevelId
              )?.standardCounts ?? 0
            }
            barlabel="معيار"
            count={
              agencyStandardsCount?.find(
                (x) => x.commitmentLevelId === item.commitmentLevelId
              )?.standardCounts ?? 0
            }
            color={item.commitmentLevelColor || '#7C32C9'}
          />
        ))}
      </View>

      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: 20,
          alignItems: 'center',
          justifyContent: 'flex-end',
          marginTop: 20,
        }}
      >
        {commitmentLevels?.reverse().map((item) => (
          <View style={{ display: 'flex', flexDirection: 'row', gap: 5 }}>
            <Text style={{ color: '#2A2069', fontSize: 8 }}>
              {item.commitmentLevelTitle}
            </Text>
            <View
              style={{
                width: 6,
                height: 6,
                backgroundColor: `${item.commitmentLevelColor}`,
                borderRadius: 2,
              }}
            ></View>
          </View>
        ))}
      </View>

      <Footer agencyTitle={agencyTitle} />
    </Page>
  );
}
