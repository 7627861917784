import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UploadFile from '../../../../components/UploadFile';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';

export default function Text({
  question,
  value,
  attachmentId,
  onChange,
  onUploadStart,
  onUploadEnd,
  onAttachmentChange,
  requiredMessage,
  disabled = false,
}) {
  return (
    <div className="px-5 flex flex-col mb-3 py-2">
      <div className="flex flex-row gap-2 mb-1 py-1">
        <h2 className="text-xl pb-2">
          {question.title}
          {question?.isRequired && (
            <span className="mr-2">
              <FontAwesomeIcon className="w-2 text-red-700" icon={faAsterisk} />
            </span>
          )}
        </h2>
      </div>
      <div className="flex flex-row gap-2 mb-1 py-1">
        <p> {question.description} </p>
      </div>
      <div className="flex flex-row gap-2 mb-2">
        <textarea
          disabled={disabled}
          onChange={(e) => onChange(e.target.value)}
          style={{
            border: '2px solid #f4f7fe',
          }}
          className="text-[14px] w-full h-full  text-gray-600 bg-[#F9F9FF]  rounded-3xl py-3 px-5 resize-none"
          placeholder="أكتب هنا"
          spellCheck={true}
          value={value}
        />
      </div>

      {question.hasAttachment && (
        <div className="flex gap-2 w-full items-center">
          <UploadFile
            onUploadStart={onUploadStart}
            onUploadEnd={onUploadEnd}
            value={attachmentId}
            withOutToolTip
            onChange={onAttachmentChange}
            readOnly={disabled}
          />
        </div>
      )}
      {requiredMessage && (
        <span className="text-red-600">{requiredMessage}</span>
      )}
    </div>
  );
}
