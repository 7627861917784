import React, { useContext, useEffect } from 'react';
import IndicatorDetailsItem from './indicatorSurveyItem';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import IndicatorSurveyItemSkeleton from './indicatorSurveyItemSkeleton';
import { IndicatorsContext } from '../../../../../../contexts/indicatorsContext';

const IndicatorSurveys = () => {
  const history = useHistory();
  const { indicatorId } = useParams();
  const { indicatorSurveys, loadingIndicatorSurveys, getIndicatorSurveysList } =
    useContext(IndicatorsContext);

  useEffect(() => {
    getIndicatorSurveysList(indicatorId);
  }, []);

  const renderedCards =
    indicatorSurveys &&
    indicatorSurveys?.map((item) => (
      <IndicatorDetailsItem
        id={item.id}
        key={item.id}
        title={item.title}
        status={item.status}
        interlocutorsCount={item.interlocutorsCount}
        standardsCount={item.standardsCount}
        perspectivesCount={item.perspectivesCount}
        indicatorId={item.indicatorId}
        goPageSetting={() =>
          history.push({
            pathname: `/indicators/${indicatorId}/surveys/${item.id}/settings`,
            state: item,
          })
        }
      />
    ));
  return (
    <div className=" p-4 pt-3 mx-2 mt-5  bg-gray_light rounded-lg ">
      <h2 className="text-blue text-lg  mt-2">استبيانات المؤشر </h2>
      <div className="flex flex-wrap gap-3 box-border mt-5 pb-5">
        {loadingIndicatorSurveys ? (
          <IndicatorSurveyItemSkeleton />
        ) : renderedCards?.length > 0 ? (
          renderedCards
        ) : (
          <p className="text-light">لا يوجد استبيانات بعد</p>
        )}
      </div>
    </div>
  );
};

export default IndicatorSurveys;
