import NewTooltip from './NewTooltip';
import table from '../assets/icons/table.svg';
import activeTable from '../assets/icons/activeTable.svg';
import blocks from '../assets/icons/blocks.svg';
import activeBlocks from '../assets/icons/activeBlocks.svg';

function TableCardSwitch({ showTable, setShowTable }) {
  return (
    <div className="flex flex-row gap-2 items-center">
      <NewTooltip content={'عرض في جدول'}>
        <div
          onClick={() => setShowTable(true)}
          className="rounded cursor-pointer"
        >
          <img
            src={showTable ? activeTable : table}
            alt="table view"
            className="w-12 h-12"
          />
        </div>
      </NewTooltip>
      <NewTooltip content={'عرض في بطاقات'}>
        <div
          onClick={() => setShowTable(false)}
          className="rounded cursor-pointer w-12 h-12r"
        >
          <img
            src={showTable ? blocks : activeBlocks}
            alt="blocks view"
            className="w-12 h-12"
          />
        </div>
      </NewTooltip>
    </div>
  );
}

export default TableCardSwitch;
