// ------------------------------------ //
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import FlipCard from '../../../../modules/main/home/FlipCard';
import AppLoader from '../../../../components/loader';
import NewTable from '../../../../components/NewTable';
import { useState } from 'react';
import { useGetFilesNumber } from '../api/useGetFilesNumber';
import environment from '../../../../services/environment';
import { axiosGetRequest } from '../../../../services/Request';
import useExcelExport from '../../../../helpers/customHooks/useExcelExport';

// ------------------------------------ //

function FilesNumber() {
  const [currentPage, setCurrentPage] = useState('');
  const [excelLoading, setExcelLoading] = useState(false);
  const [filterValue, setFilterValue] = useState(null);

  const downloadExcel = useExcelExport();

  const { allFilesCount, filesNumber, isGettingFilesNumber, totalPages } =
    useGetFilesNumber({ currentPage, filterValue });

  const handlePageClick = (event) => {
    const newOffset = event.selected;
    setCurrentPage(newOffset);
  };

  const columns = [
    {
      name: 'كود الجهة',
      selector: (row) => row.code,
      header: 'code',
      accessorKey: 'code',
      width: '100px',
    },
    {
      name: 'اسم الجهة',
      selector: (row) => row.name,
      header: 'name',
      accessorKey: 'name',
      cell: (row) => <div className="text-center">{row.name}</div>,
      center: true,
    },

    {
      name: 'النظام السابق',
      selector: (row) => row.qiyas23FilesCount,
      header: 'qiyas23FilesCount',
      accessorKey: 'qiyas23FilesCount',
      width: '130px',
      center: true,
    },
    {
      name: 'النظام الحالي',
      selector: (row) => row.qiyas24FilesCount,
      header: 'qiyas24FilesCount',
      accessorKey: 'qiyas24FilesCount',
      width: '130px',
      center: true,
    },
  ];

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  ChartJS.defaults.font.family = 'DiodrumArabic';

  const labels = ['القياس السابق', 'القياس الحالي'];

  const chartData = {
    labels,
    datasets: [
      {
        label: 'عدد الملفات في النظام',
        data: [allFilesCount?.oldQiyas, allFilesCount?.currentQiyas],
        backgroundColor: ['#3588cc'],
        barThickness: 75,
      },
    ],
  };

  const options = {
    type: 'bar',
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: false,
        text: 'عدد الملفات في النظام',
        font: {
          size: 17,
          family: 'DiodrumArabic', // Apply custom font
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        minmax: 10,
        ticks: {
          stepSize: 1,
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  const handleExcelExport = async () => {
    setExcelLoading(true);
    const res = await axiosGetRequest(environment.getAgenciesFiles, {
      KeyWord: filterValue,
      SkipCount: 0,
      MaxResultCount: 10000,
    });

    setExcelLoading(false);

    const dataForExcel = res?.result?.items?.map((ele) => {
      return {
        'كود الجهة': ele.code,
        'اسم الجهة': ele.name,
        'عدد الملفات في النظام السابق': ele.qiyas23FilesCount,
        'عدد الملفات في النظام الحالي': ele.qiyas24FilesCount,
      };
    });

    downloadExcel('عدد الملفات في النظام', dataForExcel);
  };

  const handleFilter = (value) => {
    setFilterValue(value);
  };

  return (
    <FlipCard
      title={`عدد الملفات في النظام (${allFilesCount?.currentQiyas})`}
      frontContent={
        <div className="relative h-full w-full flex justify-center items-center">
          <div className="h-[355px] w-full flex justify-center items-center">
            {isGettingFilesNumber ? (
              <AppLoader />
            ) : (
              <Bar options={options} data={chartData} />
            )}
          </div>
        </div>
      }
      backContent={
        <>
          <NewTable
            withSearch
            onSearch={handleFilter}
            withExcel
            excelLoading={excelLoading}
            onExportExcel={handleExcelExport}
            columns={columns}
            data={filesNumber || []}
            loading={isGettingFilesNumber}
            pagination={true}
            totalPages={totalPages}
            onPageChange={handlePageClick}
            currentPage={currentPage}
          />
        </>
      }
    />
  );
}

export default FilesNumber;
