import { useHistory, useParams } from 'react-router';
import NewHeader from '../../../layouts/header/NewHeader';
import { programsIcon } from '../../../assets/icons/Index';
import { InputLabel, Title } from '../../../components';
import AppButton from '../../../components/buttons/appButton';
import { useEffect, useState } from 'react';
import environment from '../../../services/environment';
import { get, put } from '../../../services/Request';
import { formatTime, formatDate } from '../../../helpers/utils';
import { toast } from 'react-toastify';

const SelectedRequest = () => {
  const history = useHistory();
  const { requestId } = useParams();

  const [editRequest, setEditRequest] = useState(null);

  const getEditRequest = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    get(
      environment.GetExpertManagerReAnswerRequestById +
        `?expertManagerReAnswerRequestId=${requestId}`,
      config,
      (res) => {
        if (res.status === 200) {
          setEditRequest(res.data?.result?.items?.[0]);
        }
      }
    );
  };

  const updateEditRequest = (isApproved) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    put(
      environment.UpdateExpertManagerReAnswerRequestApproved,
      {
        isApproved,
        surveyExpertReAnswerRequestIds: [requestId],
      },
      config,
      (res) => {
        if (res.status === 200) {
          toast.success(isApproved ? 'تمت الموافقة بنجاح' : 'تم الرفض بنجاح', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        }
      }
    );
  };

  useEffect(() => {
    getEditRequest();
  }, []);

  return (
    <div className="bg-white rounded-[30px] m-5">
      <NewHeader />
      <div className="p-5">
        <Title
          subTitle="تفاصيل الطلب"
          iconTitle={programsIcon}
          seconed={true}
          title="الطلبات"
          withoutButton={true}
          checked={false}
          withSwitch={false}
          toPageSeconed={() => history.push('/programs')}
          toPage={() => history.push('/programs')}
        />
        <div className="m-5 p-5">
          <div className="text-xl font-bold text-right mb-6">التفاصيل</div>
          <div className="space-y-4 p-5 bg-gray-100 rounded-lg shadow-md">
            <div className="flex justify-between items-center gap-5">
              <div className="w-full flex flex-col justify-between">
                <InputLabel
                  label={'رقم الطلب'}
                  name="id"
                  value={editRequest?.id}
                  isRequired={false}
                  disabled
                />
              </div>
              <div className="w-full flex flex-col justify-between">
                <InputLabel
                  label={'الجهات الحكومية'}
                  name="agencyName"
                  value={editRequest?.agencyName}
                  isRequired={false}
                  disabled
                />
              </div>
            </div>
            <div className="flex justify-between items-center gap-5">
              <div className="w-full flex flex-col justify-between">
                <InputLabel
                  label={'تفاصيل سبب الطلب'}
                  name="notes"
                  value={editRequest?.notes}
                  isRequired={false}
                  disabled
                />
              </div>
              <div className="w-full flex flex-col justify-between">
                <InputLabel
                  label={'الحالة'}
                  name="isApproved"
                  value={
                    editRequest?.isApproved
                      ? 'تمت الموافقة'
                      : editRequest?.isApproved === null
                        ? 'قيد المراجعة'
                        : 'تم الرفض'
                  }
                  isRequired={false}
                  disabled
                />
              </div>
            </div>
            <div className="flex justify-between items-center gap-5">
              <div className="w-full flex flex-col justify-between">
                <InputLabel
                  label={'المعيار'}
                  name="titleArabic"
                  value={editRequest?.standard?.titleArabic}
                  isRequired={false}
                  disabled
                />
              </div>
              <div className="w-full flex flex-col justify-between">
                <InputLabel
                  label={'اسم مقدم الطلب'}
                  name="expert"
                  value={editRequest?.expert?.name}
                  isRequired={false}
                  disabled
                />
              </div>
            </div>
            <div className="flex justify-between items-center gap-5">
              <div className="w-full flex flex-col justify-between">
                <InputLabel
                  label={'تاريخ ارسال الطلب'}
                  name="date"
                  value={
                    formatDate(new Date(editRequest?.date)) +
                    ' / ' +
                    formatTime(new Date(editRequest?.date))
                  }
                  isRequired={false}
                  disabled
                />
              </div>
              <div className="w-full flex flex-col justify-between">
                <InputLabel
                  label={'رقم المعيار'}
                  name="code"
                  value={editRequest?.standard?.code}
                  isRequired={false}
                  disabled
                />
              </div>
            </div>
          </div>
          {editRequest?.isApproved === null && (
            <div className="flex justify-end gap-5 mt-6">
              <AppButton onClick={() => updateEditRequest(true)}>
                موافق
              </AppButton>
              <AppButton
                className="bg-transparent !text-[#FF5353] border border-[#FF5353] hover:!text-white hover:!bg-[#FF5353]"
                onClick={() => updateEditRequest(false)}
              >
                رفض
              </AppButton>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SelectedRequest;
