import { Badge, Progress } from 'flowbite-react';
import { useEffect, useState } from 'react';
import NewCard from '../../../../components/dashboard/NewCard';
import NewTable from '../../../../components/NewTable';
import { useDashboardContext } from '../../../../contexts/DashboardContext';
import useExcelExport from '../../../../helpers/customHooks/useExcelExport';
import {
  expertId,
  pocOfficerId,
  reviewerId,
  surveyId,
} from '../../../../helpers/isPermission';
import environment from '../../../../services/environment';
import { axiosGetRequest } from '../../../../services/Request';
import { useGetAuditorExpertAxis } from '../api/useGetAuditorExpertAxis';

const AuditorAxisPhase4 = ({ title }) => {
  const surveyStageId = localStorage.getItem('surveyStageId');
  const { setAuditorsDashboardStats, setLoading } = useDashboardContext();

  const [currentPage, setCurrentPage] = useState(0);

  const { axis, isGettingAuditorExpertAxis, totalCount, totalPages } =
    useGetAuditorExpertAxis({ currentPage });

  useEffect(() => {
    setAuditorsDashboardStats((prevStats) => ({
      ...prevStats,
      interlocutorsCount: totalCount,
    }));
    setLoading(isGettingAuditorExpertAxis);
  }, [totalCount, isGettingAuditorExpertAxis]);

  const handlePageClick = (event) => {
    const newOffset = event.selected;
    setCurrentPage(newOffset);
  };

  const statusContent = (status) => {
    switch (status) {
      case 0:
        return (
          <Badge
            className="text-white font-medium bg-[#575757] pb-3 px-5 rounded-xl"
            size="sm"
          >
            لم يبدأ
          </Badge>
        );
      case 1:
        return (
          <Badge
            className="text-white font-medium bg-[#ffa92b] pb-3 px-5 rounded-xl"
            size="sm"
          >
            تحت الاٍجراء
          </Badge>
        );
      case 2:
        return (
          <Badge
            className="text-white font-medium bg-[#1cc182] pb-3 px-5 rounded-xl"
            size="sm"
          >
            مكتمل
          </Badge>
        );

      default:
        return (
          <div>
            <span>—</span>
          </div>
        );
    }
  };

  const columns = [
    {
      name: 'المحور',
      selector: (row) => row.interlocutorTitle,
    },
    {
      name: 'الحالة',
      center: true,
      cell: (info) => statusContent(info.interlocutorStatus),
    },
    {
      name: 'عدد المعايير الكلية المدققة',
      selector: (row) => row.completedAgenciesCount,
      center: true,
    },
    {
      name: 'عدد المعايير الكلية المتبقية',
      selector: (row) => row.remainingAgenciesCount,
      center: true,
    },
    {
      name: 'معدل الاكتمال',
      cell: (info) => (
        <div className="px-3 py-4 w-full">
          <span className="text-sm text-[#A2A2A2]">{`${
            Math.round(info.completionRate) || 0
          }%`}</span>

          <Progress
            theme={{
              color: {
                dark:
                  info.status == '0'
                    ? 'bg-red-500'
                    : info.status == '1'
                      ? 'bg-[#FFC000]'
                      : 'bg-green',
              },
            }}
            color={'dark'}
            progress={info.completionRate}
          />
        </div>
      ),
    },
  ];

  // excel
  const [excelLoading, setExcelLoading] = useState(false);

  const downloadExcel = useExcelExport();

  const handleExcelExport = async () => {
    setExcelLoading(true);
    const res = await axiosGetRequest(environment.getAuditorExpertAxis, {
      SurveyStageId: surveyStageId,
      SurveyId: surveyId(),
      ReviewerId: reviewerId,
      ExpertId: expertId,
      POCOfficerId: pocOfficerId,
      SkipCount: 0,
      MaxResultCount: 10000,
    });

    setExcelLoading(false);

    const dataForExcel = res?.result?.items?.map((ele) => {
      return {
        المنظور: ele?.perspectiveTitle,
        الحالة:
          ele?.interlocutorStatus == 0
            ? 'لم يبدأ'
            : ele?.interlocutorStatus == 1
              ? 'تحت الاٍجراء'
              : ' مكتمل',
        'عدد المعايير الكلية المدققة': ele?.completedAgenciesCount,
        'عدد المعايير الكلية المتبقية': ele?.remainingAgenciesCount,
        'معدل الاكتمال': ele?.completionRate?.toFixed(2),
      };
    });

    downloadExcel('المحاور', dataForExcel);
  };

  return (
    <NewCard title={title || `المحاور (${totalCount})`}>
      <NewTable
        withExcel
        excelLoading={excelLoading}
        onExportExcel={handleExcelExport}
        loading={isGettingAuditorExpertAxis}
        columns={columns}
        data={axis || []}
        pagination={true}
        totalPages={totalPages}
        onPageChange={handlePageClick}
        currentPage={currentPage}
      />
    </NewCard>
  );
};

export default AuditorAxisPhase4;
