import environment from '../../../services/environment';
import { useState } from 'react';
import { CModal, SModal } from '../../../components';
import ReactSwitch from 'react-switch';
import { axiosGetRequest, axiosPutRequest } from '../../../services/Request';
import { successToast } from '../../../helpers/toast';

function StatusAction({ ticket, onSuccess }) {
  const [message, setMessage] = useState({});
  const [loading, setLoading] = useState(false);
  const [openChangeStatus, setOpenChangeStatus] = useState(false);
  const [checked, setChecked] = useState(false);

  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const isClosed = ticket.ticketStatus === 2;

  const handleReOpenTicket = async () => {
    setLoading(true);
    const res = await axiosPutRequest(environment.updateTicketStatus, {
      id: ticket.id,
      ticketStatus: 1,
    });
    setLoading(false);
    if (res?.success) {
      successToast('تم اٍعادة فتح الاستفسار');
      onSuccess();
    }
  };

  const handleCloseTicket = async () => {
    setLoading(true);
    // LATER
    const res = await axiosGetRequest(environment.CloseTicketStatus, {
      id: ticket.id,
    });
    setLoading(false);
    if (res?.success) {
      successToast('تم اٍغلاق الاستفسار');
      onSuccess();
    }
  };

  const handleChange = (checked) => {
    setOpenChangeStatus(true, setChecked(checked));
  };

  return (
    <>
      <div className="flex justify-center items-center ml-2">
        <SModal
          type={message.type}
          open={openConfirmModal}
          setOpen={() => setOpenConfirmModal(false)}
          subTitle={message.message}
        />
        <CModal
          status={true}
          loading={loading}
          confirmModal={() =>
            isClosed ? handleReOpenTicket() : handleCloseTicket()
          }
          open={openChangeStatus}
          setOpen={() => setOpenChangeStatus(false)}
          subTitle={
            isClosed
              ? `هل تريد اٍعادة فتح استفسار ${ticket.ticketNumber}؟`
              : `هل تريد غلق استفسار ${ticket.ticketNumber}؟`
          }
        />
        <ReactSwitch
          onChange={handleChange}
          checked={ticket.ticketStatus == 2 ? false : true}
          // disabled={ticket.ticketStatus == 2 ? true : false}
          onColor={'#1F9254'}
          offColor="#E92C2C"
        />
      </div>
    </>
  );
}

export default StatusAction;
