import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AppTextarea = ({
  rows = '4',
  name,
  value,
  onChange,
  placeholder,
  defaultValue,
  style,
  label,
  isRequired = false,
  warningMessage = '',
}) => {
  return (
    <>
      {label && (
        <label
          className="label_input text-lg block text-[#292069] mb-2"
          htmlFor={name}
        >
          {label}
          {isRequired && (
            <span className="mr-1">
              <FontAwesomeIcon className="w-2 text-red-700" icon={faAsterisk} />
            </span>
          )}
        </label>
      )}
      <textarea
        rows={rows}
        id={name}
        defaultValue={defaultValue}
        className="bg-gray_sm rounded w-full border-gray_sm border-1 "
        name={name}
        value={value}
        placeholder={placeholder}
        style={style}
        onChange={onChange}
        spellCheck={true}
      />
      {warningMessage && (
        <p className="-mt-5 ms-5 text-xs text-yellow-400">{warningMessage}</p>
      )}
    </>
  );
};

export default AppTextarea;
