import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
  axiosGetRequest,
  axiosPostRequest,
} from '../../../../services/Request';
import environment from '../../../../services/environment';
import { StageContext } from '../../../../contexts/stageContext';
import { useContext } from 'react';

export const useGetAnsweredStandards = ({ currentPage, filterValue }) => {
  const queryClient = useQueryClient();
  const surveyId = localStorage.getItem('surveyId');
  const { currentStage } = useContext(StageContext);
  const phaseThreeStageId = currentStage?.stageIds?.[2];

  const { data, isLoading: isGettingAnsweredStandards } = useQuery({
    queryKey: [
      'AnsweredStandards',
      currentPage,
      filterValue,
      phaseThreeStageId,
    ],
    queryFn: ({ signal }) =>
      axiosPostRequest(
        environment.PrepareAnsweredStandards,
        {
          KeyWord: filterValue,
          surveyId: surveyId,
          SkipCount: currentPage * 4,
          MaxResultCount: 4,
          stageId: phaseThreeStageId,
        },
        null,
        true,
        true,
        false,
        signal
      ),
  });

  const answeredStandards = data?.result?.items;
  const answeredStandardsCount = data?.result?.totalCount;
  const totalAnsweredNum =
    data?.result?.totalAnsweredAgenciesInAllAnsweredStandardsCount;
  const totalAnsweredStandardsCount = data?.result?.totalAnsweredStandardsCount;
  const totalPages = Math.ceil(answeredStandardsCount / 4);

  return {
    queryClient,
    data,
    answeredStandards,
    answeredStandardsCount,
    totalAnsweredNum,
    totalPages,
    isGettingAnsweredStandards,
    totalAnsweredStandardsCount,
  };
};
