import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import environment from '../../../../../services/environment';
import AppModal from '../../../../../components/Models/appModal';
import { Formik } from 'formik';
import { DropdownMultiSelect, InputLabel } from '../../../../../components';
import { AnswerContext } from './answerContext';
import { useParams } from 'react-router-dom';
import { post } from '../../../../../services/Request';
import { permissionsCheck } from '../../../../../helpers/utils';
import UploadAdapter from '../../../../../components/UploadAdapter';
import { useTranslation } from 'react-i18next';
import { useGetInquiryList } from './useGetInquiryList';
import AppEditor from '../../../../../components/form/AppEditor';

const AddstandardInquiry = ({
  priority,
  priorities,
  handleChangeSubject,
  setDescription,
  onChangeFile,
  selectedFile,
  subject,
  description,
  handleChangePriority,
  openQuestionModal,
  setOpenQuestionModel,
  setLoading,
  setInquiries,
  setStandard,
  selectedStatusInquiry,
}) => {
  const { standardId } = useParams();
  const answerContext = useContext(AnswerContext);

  const { getStandardInquiryList } = useGetInquiryList(
    selectedStatusInquiry,
    setInquiries,
    setStandard,
    setLoading
  );

  const [addLoading, setAddLoading] = useState(false);
  const { t } = useTranslation();

  const onFileUpload = (values) => {
    if (!selectedFile) return;
    setAddLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    const formData = new FormData();
    formData.append('Name', selectedFile.name);
    formData.append('FileType', 'image/*');
    formData.append('EntityType', '');
    formData.append('File', selectedFile);
    post(environment.attachmentFile, formData, config, (res) => {
      setAddLoading(false);
      sendTicket('', res.data.result.id);
    });
  };

  const sendTicket = (values, attachmentId, getAllInqiories) => {
    if (subject == '') {
      toast.error('من فضلك اضف موضوع الاستفسار', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    } else if (description == '') {
      toast.error('من فضلك اضف تفاصيل الاستفسار', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    } else {
      setAddLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      };
      let data = {
        description: description,
        categoryId: 3,
        subject: subject,
        attachmentId: attachmentId,
        priorityId: priority.value,
        standardId: standardId,
      };
      post(environment.addInquiriesForStandard, data, config, (res) => {
        setAddLoading(false);
        if (res.status == 403) {
          toast.error(res?.data?.error?.message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        } else if (res.status == 500) {
          toast.error(res?.data?.error?.message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        } else if (res.status == 200) {
          toast.success(t('تم اٍرسال الاستفسار بنجاح'), {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
          getStandardInquiryList();

          setOpenQuestionModel(false);

          getAllInqiories();
        }
      });
    }
  };

  return (
    <AppModal
      handleSubmit={() =>
        permissionsCheck('Pages.Tickets.Create')
          ? !selectedFile
            ? sendTicket('', null)
            : onFileUpload('')
          : () => {}
      }
      children={
        <div>
          <Formik
            initialValues={{ category: '', priority: '', description: '' }}
            validate={(values) => {
              const errors = {};
              if (!values.description) {
                errors.description = t('modules.main.mailbox.subjectRequired');
              }
              return errors;
            }}
            onSubmit={(values) => {
              return true;
              !selectedFile ? sendTicket(values, null) : onFileUpload(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="p-5 w-12/12">
                  <label className="label_input text-lg text-[#0D062D] mb-3 w-12/12">
                    {t('modules.main.tickets.priority')}
                  </label>
                  <DropdownMultiSelect
                    withoutLabel={true}
                    isSearchable={true}
                    value={priority}
                    handleChange={handleChangePriority}
                    placeholder={t('modules.main.tickets.priority')}
                    label={t('modules.main.tickets.priority')}
                    options={priorities}
                    isMultiple={false}
                    name="priority"
                    style={{
                      borderColor: errors.priority
                        ? 'red'
                        : values.priority != ''
                          ? '#22bb33'
                          : '',
                    }}
                  />
                  <div className="text-sm text-red-600 mt-2">
                    {errors.priority && touched.priority && errors.priority}
                  </div>
                </div>
                <div className="p-5 w-12/12">
                  <InputLabel
                    label={t('modules.main.tickets.subject')}
                    type={'text'}
                    value={subject}
                    onChange={(e) => handleChangeSubject(e)}
                    style={{
                      borderColor: errors.subject
                        ? 'red'
                        : values.subject != ''
                          ? '#22bb33'
                          : '',
                    }}
                    name="subject"
                  />
                  <div className="text-sm text-red-600 mt-3">
                    {errors.subject && touched.subject && errors.subject}
                  </div>
                  <div className="mb-2 w-full flex flex-row items-center justify-between"></div>
                  <div className="mb-2 w-full flex flex-col items-center justify-between">
                    <div className="p-5 w-full">
                      <label className="label_input text-lg text-[#0D062D]">
                        {t('modules.main.tickets.description')}
                      </label>
                      <div className=" mt-3">
                        <AppEditor
                          value={description}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            setDescription(data);
                            editor.plugins.get(
                              'FileRepository'
                            ).createUploadAdapter = function (loader) {
                              return new UploadAdapter(loader);
                            };
                          }}
                        />
                      </div>
                    </div>
                    <div className="p-5 w-full">
                      <InputLabel
                        style={{ display: 'none' }}
                        fileInputWidth={'100%'}
                        type={'file'}
                        id={'fileUpload'}
                        label={t('modules.main.tickets.file')}
                        isImage={true}
                        multiple={false}
                        onChange={onChangeFile}
                        fileName={selectedFile.name}
                        isRequired={false}
                      />
                    </div>
                  </div>

                  <div className="w-full flex flex-row justify-end items-center px-5"></div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      }
      open={openQuestionModal}
      setOpen={() => setOpenQuestionModel(false)}
      headerTitle={'اضافة استفسار'}
      loading={addLoading}
    />
  );
};

export default AddstandardInquiry;
