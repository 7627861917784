import React from 'react';
import { useUploadContext } from './uploadFilesContext';

const FilesUploading = ({ label, proofId }) => {
  const { filesProgress, attachmentFilesCount } = useUploadContext();
  return (
    <div className="flex w-full items-center justify-center ">
      <label className="dark:hover:bg-bray-800 flex w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-emerald-300 bg-emerald-50 hover:bg-emerald-100 mb-2 min-h-[170px] relative">
        <div
          className="absolute inset-0 rounded-lg bg-emerald-200"
          style={{ width: filesProgress + '%' }}
        ></div>

        <div className="absolute inset-0 z-2 flex justify-center items-center">
          <div className="flex flex-col items-center justify-center pb-6 pt-5">
            <svg
              className=" h-8 w-8 text-gray-500 dark:text-gray-400"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 16"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLineJoin="round"
                strokeWidth="2"
                d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
              />
            </svg>
            <p className="mb-3  text-gray-900 dark:text-gray-400">
              <span className="font-semibold">
                جارى إرفاق {attachmentFilesCount[proofId]?.itemsCount} ملف (
                {filesProgress}%){' '}
              </span>
            </p>
            <p className="text-sm max-w-[90%] text-gray-500 text-center dark:text-gray-400">
              {label}
            </p>
          </div>
        </div>
      </label>
    </div>
  );
};

export default FilesUploading;
