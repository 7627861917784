import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';
import { StageContext } from '../../../../contexts/stageContext';
import { surveyId } from '../../../../helpers/isPermission';
import { axiosGetRequest } from '../../../../services/Request';
import environment from '../../../../services/environment';

export const useGetFinishedAnswerAgencies = ({ currentPage, filterValue }) => {
  const queryClient = useQueryClient();
  const { stageForDashboard } = useContext(StageContext);
  const { currentStage } = useContext(StageContext);
  const phaseOneStageId = currentStage?.stageIds?.[0];

  const { data, isLoading: isGettingFinishedAnswerAgencies } = useQuery({
    queryKey: [
      'FinishedAnswerAgencies',
      currentPage,
      filterValue,
      stageForDashboard,
      phaseOneStageId,
    ],
    queryFn: ({ signal }) =>
      axiosGetRequest(
        environment.getAgenciesFinishedAnswering,
        {
          KeyWord: filterValue,
          surveyId: surveyId(),
          StageId: stageForDashboard || 0,
          SkipCount: currentPage * 4,
          MaxResultCount: 4,
          surveyStageId: phaseOneStageId,
        },
        true,
        signal
      ),
    enabled: !!stageForDashboard,
  });

  const agenciesFinishedAnswering = data?.result?.items;
  const agenciesFinishedAnsweringCount = data?.result?.totalCount;
  const totalPages = Math.ceil(agenciesFinishedAnsweringCount / 4);

  return {
    queryClient,
    data,
    agenciesFinishedAnswering,
    agenciesFinishedAnsweringCount,
    totalPages,
    isGettingFinishedAnswerAgencies,
  };
};
