import { useEffect, useState } from 'react';

import { Tooltip } from '@material-tailwind/react';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { Menu, MenuItem, Sidebar, SubMenu } from 'react-pro-sidebar';
import { Link, matchPath, useHistory, useLocation } from 'react-router-dom';
import leftArrow from '../../../assets/icons/leftArrow.svg';
import rightArrow from '../../../assets/icons/rightArrow.svg';
import {
  qiyasLogoWhite,
  qiyasLogoWhiteSmall,
} from '../../../assets/imgs/Index';
import { isViewer } from '../../../helpers/isPermission';
import { list } from '../../../helpers/lists/List';
import {
  getSurveyAnswersRoute,
  newStandardAnswerProgramRoute,
  permissionsCheck,
  standardEntitiesRoute,
} from '../../../helpers/utils';
import { axiosGetRequest } from '../../../services/Request';
import environment from '../../../services/environment';

const Home = () => {
  const { pathname } = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const [links, setLinks] = useState([]);
  const history = useHistory();
  const { i18n } = useTranslation();
  const [selectMenuItem, setSelectMenuItem] = useState(pathname);

  const { data: allAgenciesHavePermissionForFinalReport } = useQuery({
    queryKey: ['GetAllAgenciesHavePermissionForFinalReport'],
    queryFn: () =>
      axiosGetRequest(environment.GetAllAgenciesHavePermissionForFinalReport, {
        skipCount: 0,
        maxResultCount: 1000,
      }),
    select: (res) => res?.result?.items?.map((ele) => ele.id.toString()),
  });

  const isAdmin = permissionsCheck('Pages.UserType.Admin');

  useEffect(() => {
    let arr = [];
    let arrPermissions = [];
    JSON.parse(localStorage.getItem('perm')).forEach((element) => {
      arrPermissions.push(element.name);
    });
    list.forEach((item) => {
      JSON.parse(localStorage.getItem('perm')).forEach((perm, i) => {
        if (item?.subMenus?.length > 0) {
          item.subMenus = item.subMenus.filter((item2, i) =>
            arrPermissions.includes(item2.permission)
          );
        }

        if (perm.name == item.permission) {
          arr.push(item);
          setLinks(arr);
        } else {
          // history.push("/notFound")
        }
      });

      if (
        item.permission === 'Pages.FinalReport' &&
        (allAgenciesHavePermissionForFinalReport?.includes(
          localStorage.getItem('agencyId')
        ) ||
          isAdmin ||
          permissionsCheck('Pages.UserType.ExpertManager') ||
          isViewer)
      ) {
        arr.push(item);
        setLinks(arr);
      }
    });

    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      if (newWidth < 1000) {
        setCollapsed(true);
      }
    };

    window.addEventListener('resize', updateWindowDimensions);

    return () => window.removeEventListener('resize', updateWindowDimensions);
  }, [allAgenciesHavePermissionForFinalReport]);

  useEffect(() => {
    links.forEach((item) => {
      if (item.subMenus.length > 0) {
        item.subMenus.forEach((subItem) => {
          if (
            matchPath(pathname, {
              path: subItem.url,
            })
          ) {
            setSelectMenuItem(subItem.url);
            return;
          }
          if (
            matchPath(pathname, {
              path: newStandardAnswerProgramRoute,
            }) ||
            matchPath(pathname, {
              path: standardEntitiesRoute,
            })
          ) {
            setSelectMenuItem(getSurveyAnswersRoute());
          }
        });
      } else {
        if (
          matchPath(pathname, {
            path: item.url,
          })
        ) {
          setSelectMenuItem(item.url);
        }
      }
    });
  }, [pathname, links, setSelectMenuItem]);

  return (
    <Sidebar
      collapsed={collapsed}
      style={{ color: 'white', backgroundColor: '#292069' }}
      rtl={i18n.language == 'ar' ? true : false}
      backgroundColor={'#292069'}
    >
      <div
        style={{
          backgroundColor: '#292069',
        }}
      >
        <div className="items-center justify-center p-5">
          <img
            className={
              'cursor-pointer h-auto ease-in-out duration-300 ' +
              (!collapsed ? 'w-[200px]' : 'w-[60px]')
            }
            onClick={() => history.push('/')}
            src={collapsed ? qiyasLogoWhiteSmall : qiyasLogoWhite}
            alt="Qiyas"
          />
        </div>
        <div style={{ flex: 1, marginBottom: '32px' }}>
          <div style={{ padding: '0 24px' }}></div>
          <button
            className={
              'cursor-pointer lg:block fixed hidden z-10 w-[45px] h-[45px] rounded-full bg-[#1CC081] text-center ease-in-out duration-300 ' +
              (collapsed ? 'mr-20' : 'mr-[248px]')
            }
            onClick={() => setCollapsed((collapsed) => !collapsed)}
          >
            {collapsed ? (
              <img src={leftArrow} alt="arrow" className="mx-auto" />
            ) : (
              <img src={rightArrow} alt="arrow" className="mx-auto" />
            )}
          </button>
          <Menu
            menuItemStyles={{
              button: ({ level, active, disabled }) => {
                if (level === 0) {
                  return {
                    color: active ? '#1CC081' : '#ffffff',
                    backgroundColor: active ? '#322A6F' : '#292069',
                    '&:hover': {
                      backgroundColor: '#292069',
                      color: '#1CC081',
                    },
                  };
                }

                if (level === 1) {
                  return {
                    color: active ? '#1CC081' : '#ffffff',
                    backgroundColor: active ? '#322A6F' : '#292069',
                    '&:hover': {
                      backgroundColor: '#292069',
                      color: 'white',
                    },
                  };
                }
              },
            }}
          >
            {links.map((menu, index) => (
              <div key={index}>
                {menu.subMenus?.length == 0 ? (
                  <Tooltip content={menu.name} placement="left-start">
                    <MenuItem
                      // onClick={() => {
                      //   setSelectMenuItem(menu.url);
                      // }}
                      active={selectMenuItem === menu.url}
                      label={menu.name}
                      className="text-base bg-[#292069]"
                      icon={
                        <img
                          alt=""
                          src={
                            selectMenuItem === menu.url ? menu.iconD : menu.icon
                          }
                        />
                      }
                      component={<Link to={menu.url} />}
                    >
                      {menu.name}
                    </MenuItem>
                  </Tooltip>
                ) : (
                  <Tooltip content={menu.name} placement="left-start">
                    <SubMenu
                      label={menu.name}
                      icon={
                        <img
                          alt=""
                          src={
                            menu.url === selectMenuItem ? menu.iconD : menu.icon
                          }
                        />
                      }
                      className="text-base text-white"
                    >
                      {menu.subMenus?.map((submenu, i) => (
                        <Tooltip content={submenu.name} placement="left-start">
                          <MenuItem
                            active={selectMenuItem === submenu.url}
                            key={i}
                            className="text-base text-white"
                            onClick={() => {
                              // setSelectMenuItem(submenu.url);
                              if (submenu.action) {
                                submenu.action();
                              }
                            }}
                            component={
                              <Link
                                to={
                                  submenu.url === '/permissions'
                                    ? submenu.url + '?id=1'
                                    : submenu.url
                                }
                              />
                            }
                            label={submenu.name}
                            icon={
                              <img
                                alt=""
                                src={
                                  submenu.url === selectMenuItem
                                    ? submenu.iconD
                                    : submenu.icon
                                }
                              />
                            }
                          >
                            {submenu.name}
                          </MenuItem>
                        </Tooltip>
                      ))}
                    </SubMenu>
                  </Tooltip>
                )}
              </div>
            ))}
          </Menu>
        </div>
      </div>
    </Sidebar>
  );
};

export default Home;
