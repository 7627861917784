import { Badge } from 'flowbite-react';
import {
  drawLeftGreen,
  plusLight,
  programsIcon,
} from '../../assets/icons/Index';
import { Title } from '../../components';
import NewTable from '../../components/NewTable';
import NewHeader from '../../layouts/header/NewHeader';
import { Link } from 'react-router-dom';
import { useGetAgenciesAnswersForStandard } from './useGetAgenciesAnswersForStandard';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import Skeleton from 'react-skeleton-loader';
import NoAnswerBadge from '../../components/NoAnswerBadge';
import { permissionsCheck } from '../../helpers/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowLeft } from '@fortawesome/free-solid-svg-icons';

function AgenciesAnswerForStandard() {
  const { standardId, stageId, indicatorId } = useParams();
  const surveyId = localStorage.getItem('surveyId');
  const [filterValue, setFilterValue] = useState('');

  const [currentPage, setCurrentPage] = useState(0);

  let reviewerId = null;
  let expertId = null;
  let pocOfficerId = null;

  if (permissionsCheck('Pages.UserType.Expert')) {
    expertId = localStorage.getItem('userId');
  }

  if (permissionsCheck('Pages.UserType.Reviewer')) {
    reviewerId = localStorage.getItem('userId');
  }

  if (permissionsCheck('Pages.UserType.POCOfficer')) {
    pocOfficerId = localStorage.getItem('userId');
  }

  const { data, agenciesAnswers, isGettingAgenciesAnswers, totalPages } =
    useGetAgenciesAnswersForStandard({
      currentPage,
      expertId,
      reviewerId,
      pocOfficerId,
      standardId,
      filterValue,
      stageId,
    });
  // tables columns
  const columns = [
    {
      name: 'كود الجهة',
      accessorKey: 'agencyCode',
      selector: (row) => row.agencyCode,
      sortable: true,
      width: '150px',
      cell: (row) => (
        <Link to={`/entities/${row.agencyId}/details`}>
          <div className="text-[#292069] font-semibold cursor-pointer">
            {row.agencyCode}
          </div>
        </Link>
      ),
    },
    {
      name: 'اسم الجهة',
      accessorKey: 'agencyNameInArabic',
      selector: (row) => row.agencyNameInArabic,
      sortable: true,
      center: true,
      cell: (row) => (
        <Link to={`/entities/${row.agencyId}/details`}>
          <div className="text-center cursor-pointer">
            {row.agencyNameInArabic}
          </div>
        </Link>
      ),
    },
    {
      name: 'اٍجابة الجهة',
      accessorKey: 'agencyAnswer',
      selector: (row) => row.agencyAnswer,
      center: true,
      cell: (row) => (
        <>
          {row?.agencyAnswer ? (
            <Badge
              style={{
                backgroundColor: row?.agencyAnswerColor,
              }}
              className="text-white text-center font-medium pb-3 px-5 rounded-xl"
              size="sm"
            >
              {row?.agencyAnswer}
            </Badge>
          ) : (
            <NoAnswerBadge />
          )}
        </>
      ),
      sortable: true,
    },
    {
      name: 'التفاصيل',
      selector: (row) => row.details,
      sortable: true,
      center: true,
      cell: (row) => (
        <>
          {row?.agencyAnswer && (
            <Link
              target="_blank"
              to={`/Standard/${standardId}/agency/${row.agencyId}/answer-details`}
            >
              <img
                src={drawLeftGreen}
                alt="viewNew"
                className="w-[40px] cursor-pointer"
              />
            </Link>
          )}
        </>
      ),
    },

    (permissionsCheck('Pages.UserType.Reviewer') ||
      permissionsCheck('Pages.UserType.Expert') ||
      permissionsCheck('Pages.UserType.ExpertManager')) && {
      name: 'تدقيق الان',
      selector: (row) => row.details,
      sortable: true,
      center: true,
      cell: (row) => (
        <>
          <Link
            target="_blank"
            to={`/indicators/${indicatorId}/programs/${surveyId}/program/${row.agencyId}/entity/${standardId}/standard`}
          >
            <FontAwesomeIcon
              icon={faCircleArrowLeft}
              className="text-qiyas_blue text-3xl"
            />
          </Link>
        </>
      ),
    },
  ].filter(Boolean);

  const handlePageClick = (event) => {
    const newOffset = event.selected;
    setCurrentPage(newOffset);
  };

  const handleSearch = (value) => {
    setFilterValue(value);
  };
  return (
    <div className="bg-white rounded-[30px] m-5 pb-4">
      <NewHeader />
      <Title
        single
        iconTitle={programsIcon}
        title={'إجابات الجهات'}
        textStyle={{ color: 'white', fontSize: 18 }}
        titleButton={'ssss'}
        seconed={false}
        withoutButton
        withIcon={true}
        icon={plusLight}
        style={{ backgroundColor: '#2B2969', width: 200 }}
      />
      <div className="flex flex-col gap-3 p-5 m-5">
        <h2 className="text-xl mb-5">
          إجابــات الجهات على معيار{' '}
          {isGettingAgenciesAnswers ? (
            <span className="mr-3">
              <Skeleton height="20px" width="100px" />
            </span>
          ) : (
            data?.result?.standardCode
          )}
        </h2>
        <NewTable
          withSearch
          onSearch={handleSearch}
          loading={isGettingAgenciesAnswers}
          columns={columns}
          data={agenciesAnswers || []}
          pagination={true}
          totalPages={totalPages}
          onPageChange={handlePageClick}
          currentPage={currentPage}
        />
      </div>
    </div>
  );
}

export default AgenciesAnswerForStandard;
