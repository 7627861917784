import { Formik } from 'formik';
import { t } from 'i18next';
import { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { plusLight, programsIcon } from '../../../../../assets/icons/Index';
import {
  DropdownMultiSelect,
  InputLabel,
  Title,
} from '../../../../../components';
import Inquiries from '../../../../../components/Inquiries';
import AppModal from '../../../../../components/Models/appModal';
import UploadAdapter from '../../../../../components/UploadAdapter';
import AppEditor from '../../../../../components/form/AppEditor';
import AppLoader from '../../../../../components/loader';
import { useUploadContext } from '../../../../../components/upload/uploadFilesContext';
import { StandardProgramContext } from '../../../../../contexts/StandardProgramContext';
import { StageContext } from '../../../../../contexts/stageContext';
import { errorToast, successToast } from '../../../../../helpers/toast';
import {
  formatDate,
  formatTime,
  isDefined,
  permissionsCheck,
} from '../../../../../helpers/utils';
import NewHeader from '../../../../../layouts/header/NewHeader';
import {
  axiosGetRequest,
  axiosPostRequest,
  axiosPutRequest,
  get,
  post,
} from '../../../../../services/Request';
import environment from '../../../../../services/environment';
import StandardAgencyAnswer from './standardAgencyAnswer';
import StandardAnswerData from './standardAnswerData';
import StandardAnswerDegrees from './standardAnswerDegrees';
import StandardAnswerNotes from './standardAnswerNotes';
import StandardAnswerProofFiles from './standardAnswerProofFiles';
import StandardAnswerRequirement from './standardAnswerRequirements';
import StandardAnswerSelectedRequirements from './standardAnswerRequirements/standardAnswerSelectedRequirements';
import StandardAnswerTemplates from './standardAnswerTemplates';
import StandardAnswerSideTaps from './taps/standardAnswerSideTaps';
import StandardAnswerTopTaps from './taps/standardAnswerTopTaps';

const StandardAnswerMain = () => {
  const history = useHistory();
  const { stageRoles } = useContext(StageContext);
  const standardProgramContext = useContext(StandardProgramContext);
  const { programId, entityId, standardId, indicatorId } = useParams();
  const [showSection, setShowSection] = useState('standard');
  const [ids, setIds] = useState([]);
  const [description, setDescription] = useState('');

  const [addLoading, setAddLoading] = useState(false);
  const [priority, setPriority] = useState({});
  const [selectedFile, setSelectedFile] = useState('');
  const [selectedFileComment, setSelectedFileComment] = useState('');

  const [categories, setCategories] = useState([]);
  const [priorities, setPriorities] = useState([]);
  const [donotApplyRequirementsIds, setDonotApplyRequirementsIds] = useState(
    []
  );
  const [donotApplySubRequirementsIds, setDonotApplySubRequirementsIds] =
    useState([]);

  const [requirementId, setRequirementId] = useState('');

  const [openQuestionModal, setOpenQuestionModel] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [selectedReviewerDegreeId, setSelectedReviewerDegreeId] = useState('');
  const [idsRequirement, setIdsRequirement] = useState([]);
  const [reviewerSuggestedDegree, setReviewerSuggestedDegree] = useState(null);
  const [
    selectedReviewerDegreePercentage,
    setSelectedReviewerDegreePercentage,
  ] = useState('');

  const [expertSuggestedDegree, setExpertSuggestedDegree] = useState(null);
  const [selectedExpertDegree, setSelectedExpertDegree] = useState(null);
  const [selectedExpertDegreeId, setSelectedExpertDegreeId] = useState('');
  const [selectedReviewerDegree, setSelectedReviewerDegree] = useState(null);
  const [selectedStatusInquiry, setSelectedStatusInquiry] = useState('');
  const [selectedId, setSelectedId] = useState('');
  const [answers, setAnswers] = useState([]);
  const [subject, setSubject] = useState('');

  const [idsSubRequirement, setIdsSubRequirement] = useState([]);
  const [templates] = useState([]);
  const [selectedTemplates, setSelectedTemplates] = useState([]);

  const [selectedAgencyId, setSelectedAgencyId] = useState('');
  const [currentPhase, setCurrentPhase] = useState('');
  const [agencySuggestedDegree, setAgencySuggestedDegree] = useState(null);
  const [selectedDegreePercentage, setSelectedDegreePercentage] = useState('');
  const [selectedAgencyDegree, setSelectedAgencyDegree] = useState(null);
  const [selectedExpertDegreePercentage, setSelectedExpertDegreePercentage] =
    useState('');
  const [role, setRole] = useState('');
  const [roles, setRoles] = useState([]);

  const [loading, setLoading] = useState(false);

  const [message, setMessage] = useState({});

  const [previousSurveyFiles, setPreviousSurveyFiles] = useState([]);
  const [menuElement, setMenuElement] = useState(0);
  const [menuClicked, setMenuClicked] = useState(true);
  const [openFiles, setOpenFiles] = useState(false);

  const [pocAnswer, setPocAnswer] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [openWarningModal, setOpenWarningModal] = useState(false);
  const { files: uploadedFiles, setFiles: setUploadedFiles } =
    useUploadContext();
  const [selectedImage, setSelectedImage] = useState('');
  const [comment, setComment] = useState('');
  const [statusesInquiry] = useState([
    { id: 1, label: 'اختر حالة', value: null },
    { id: 2, label: 'تجاوز مستوي الخدمة', value: 3 },
    { id: 3, label: 'تحت الاجراء ', value: 1 },
    { id: 2, label: 'جديد', value: 4 },
    { id: 3, label: 'مغلق ', value: 2 },
  ]);
  const [selectedPrevfilesArray, setSelectedPrevfilesArray] = useState(
    Array(standardProgramContext?.files?.length).fill([])
  );

  useEffect(() => {
    getStandardInquiryList();
  }, []);

  useEffect(() => {
    getAllCategories();
    getAllPriorities();
  }, []);

  const getSelectedStatusInquiry = (value) => {
    setSelectedStatusInquiry(value);
  };

  const getStandardAgencyPreviuos = async (
    standardTemplate,
    role,
    programPhase
  ) => {
    const res = await axiosGetRequest(environment.getAgencyPreviuosAnswer, {
      StandardId: standardId,
      AgencyId: entityId,
      StageId: programPhase?.id,
    });
    if (res?.success) {
      if (permissionsCheck('Pages.UserType.Reviewer')) {
        res?.result?.programReviewerAnswer?.reviewerRequirementAnswers.forEach(
          (requirement1) => {
            standardTemplate.standardRequirements.forEach((requirement2) => {
              if (requirement2.id == requirement1.standardRequirementId) {
                requirement1.title = requirement2.title;
              }
            });
          }
        );
        standardProgramContext?.setRequirementsPrev(
          res?.result?.programReviewerAnswer?.reviewerRequirementAnswers
        );
      }

      if (
        permissionsCheck('Pages.UserType.Expert') ||
        permissionsCheck('Pages.UserType.ExpertManager')
      ) {
        res?.result?.programExpertAnswer?.expertRequirementAnswers.forEach(
          (requirement1) => {
            standardTemplate.standardRequirements.forEach((requirement2) => {
              if (requirement2.id == requirement1.standardRequirementId) {
                requirement1.title = requirement2.title;
              }
            });
          }
        );
        standardProgramContext?.setRequirementsPrev(
          res?.result?.programExpertAnswer?.expertRequirementAnswers
        );
      }

      if (
        permissionsCheck('Pages.UserType.POC') ||
        permissionsCheck('Pages.UserType.CIO') ||
        permissionsCheck('Pages.UserType.Delegate') ||
        permissionsCheck('Pages.UserType.Admin')
      ) {
        res?.result?.agencyRequirementAnswers.forEach((requirement1) => {
          standardTemplate.standardRequirements.forEach((requirement2) => {
            if (requirement2.id == requirement1.standardRequirementId) {
              requirement1.title = requirement2.title;
            }
          });
        });
        standardProgramContext?.setRequirementsPrev(
          res?.result?.agencyRequirementAnswers
        );
      }
    }
    setLoading(false);
  };

  const getStandardAnswerExpert = async (
    standardTemplate,
    role,
    programPhase
  ) => {
    const res = await axiosGetRequest(environment.getExpertAnswer, {
      StandardId: standardId,
      AgencyId: entityId,
      StageId: programPhase?.id,
    });

    if (res?.success) {
      standardTemplate.expertDegree.label =
        res?.result?.finalCommitmentLevel?.title;
      standardTemplate.expertDegree.value =
        res?.result?.finalCommitmentLevel?.id;

      standardTemplate.expertDegree = res?.result?.finalCommitmentLevel;

      standardTemplate.expertSuggestedDegree.label =
        res?.result?.selfEvaluationCommitmentLevel?.title;
      standardTemplate.expertSuggestedDegree.value =
        res?.result?.selfEvaluationCommitmentLevel?.id;

      standardTemplate.expertSuggestedDegree =
        res?.result?.selfEvaluationCommitmentLevel;

      setRole(role.role);

      setSelectedExpertDegreeId(res?.result?.finalCommitmentLevel?.id);

      setSelectedExpertDegree(res?.result?.finalCommitmentLevel);
      setExpertSuggestedDegree(standardTemplate?.expertSuggestedDegree);

      standardProgramContext?.setNoteExpertForReviewer(
        res?.result?.noteForReviewer?.content
      );
      standardProgramContext?.setNoteExpert(
        res?.result?.noteForAgency?.content
      );
      standardTemplate.agencyNoteDate =
        formatDate(new Date(res?.result?.noteForAgency?.lastModificationTime)) +
        '  ' +
        formatTime(new Date(res?.result?.noteForAgency?.lastModificationTime));
      standardTemplate.expertToReviewerNoteDate =
        formatDate(
          new Date(res?.result?.noteForReviewer?.lastModificationTime)
        ) +
        '  ' +
        formatTime(
          new Date(res?.result?.noteForReviewer?.lastModificationTime)
        );

      standardTemplate.currentPhase = programPhase;

      standardProgramContext?.setFiles(standardTemplate.standardProofs);

      if (
        permissionsCheck('Pages.UserType.Expert') ||
        permissionsCheck('Pages.UserType.ExpertManager')
      ) {
        res?.result?.expertRequirementAnswers.forEach((requirement1) => {
          standardTemplate.standardRequirements.forEach((requirement2) => {
            if (requirement2.id == requirement1.standardRequirementId) {
              requirement2.decision = requirement1.decision;
              requirement2.recommendation = requirement1.recommendation;
              requirement1.expertSubRequirementAnswers.forEach(
                (subRequirement1) => {
                  requirement2.subStandardRequirements.forEach(
                    (subRequirement2) => {
                      if (
                        subRequirement2.id ==
                        subRequirement1.subStandardRequirementId
                      ) {
                        subRequirement2.decision = subRequirement1.decision;
                        subRequirement2.recommendation =
                          subRequirement1.recommendation;
                      }
                    }
                  );
                }
              );
            }
          });
        });
      }

      standardProgramContext?.setRequirements(
        standardTemplate.standardRequirements
      );

      const idsReq = standardTemplate.standardRequirements?.map(
        (requirement) => requirement?.id
      );
      standardProgramContext?.setRequirementListIds(idsReq);

      standardProgramContext?.setStandard(standardTemplate);
      getStandardAgencyPreviuos(standardTemplate, role, programPhase);
    }
  };

  const getStandardAnswerReviewer = async (
    standardTemplate,
    role,
    programPhase
  ) => {
    const res = await axiosGetRequest(environment.getReviewerAnswer, {
      StandardId: standardId,
      AgencyId: entityId,
      StageId: programPhase?.id,
    });

    if (res?.success) {
      if (standardTemplate.reviewerDegree.label) {
        standardTemplate.reviewerDegree.label =
          res?.result?.finalCommitmentLevel?.title;
      }

      standardTemplate.reviewerDegree.value =
        res?.result?.finalCommitmentLevel?.id;

      standardTemplate.reviewerDegree = res?.result?.finalCommitmentLevel;

      standardTemplate.reviewerSuggestedDegree.label =
        res?.result?.selfEvaluationCommitmentLevel?.title;
      standardTemplate.reviewerSuggestedDegree.value =
        res?.result?.selfEvaluationCommitmentLevel?.id;

      standardTemplate.reviewerSuggestedDegree =
        res?.result?.selfEvaluationCommitmentLevel;
      standardTemplate.reviewerDegreePercentage =
        res?.result?.selfEvaluationDegree;

      setRole(role.role);
      setSelectedReviewerDegreeId(res?.result?.finalCommitmentLevel?.id);

      setSelectedReviewerDegree(res?.result?.finalCommitmentLevel);
      setReviewerSuggestedDegree(standardTemplate?.reviewerSuggestedDegree);

      standardProgramContext?.setNoteReviewer(
        res?.result?.noteForAgency?.content
      );

      standardTemplate.reviewerNoteDate =
        formatDate(new Date(res?.result?.noteForAgency?.lastModificationTime)) +
        '  ' +
        formatTime(new Date(res?.result?.noteForAgency?.lastModificationTime));
      standardTemplate.currentPhase = programPhase;

      standardProgramContext?.setFiles(standardTemplate.standardProofs);

      if (permissionsCheck('Pages.UserType.Reviewer')) {
        res?.result?.reviewerRequirementAnswers.forEach((requirement1) => {
          standardTemplate.standardRequirements.forEach((requirement2) => {
            if (requirement2.id == requirement1.standardRequirementId) {
              requirement2.decision = requirement1.decision;
              requirement1.reviewerSubRequirementAnswers.forEach(
                (subRequirement1) => {
                  requirement2.subStandardRequirements.forEach(
                    (subRequirement2) => {
                      if (
                        subRequirement2.id ==
                        subRequirement1.subStandardRequirementId
                      ) {
                        subRequirement2.decision = subRequirement1.decision;
                        subRequirement2.recommendation =
                          subRequirement1.recommendation;
                      }
                    }
                  );
                }
              );
            }
          });
        });
      }

      standardProgramContext?.setRequirements(
        standardTemplate.standardRequirements
      );

      standardProgramContext?.setStandard(standardTemplate);
      getStandardAnswerExpert(standardTemplate, role, programPhase);
    }
  };

  const getStandardAgency = async (standardTemplate, role, programPhase) => {
    setLoadingData(true);

    const res = await axiosGetRequest(environment.getAgencyAnswer, {
      StandardId: standardId,
      AgencyId: entityId,
      StageId: programPhase?.id,
    });

    if (res?.success) {
      const isPocAnswer =
        isDefined(res?.result?.pocAnswer) && res?.result?.pocAnswer === false;

      if (res?.result == null || isPocAnswer) {
        setPocAnswer(false);
      } else {
        setPocAnswer(true);
      }
      if (res?.result == null) {
        if (
          permissionsCheck('Pages.UserType.POC') ||
          permissionsCheck('Pages.UserType.CIO') ||
          permissionsCheck('Pages.UserType.Delegate')
        ) {
          setLoading(false);
        } else if (
          permissionsCheck('Pages.UserType.Reviewer') ||
          permissionsCheck('Pages.UserType.Expert') ||
          permissionsCheck('Pages.UserType.ExpertManager')
        ) {
          setLoading(false);
        }
      } else {
        standardTemplate.agencyDegree = {};
        standardTemplate.agencySuggestedDegree = {};
        standardTemplate.agencyDegreePercentage = '';
        standardTemplate.reviewerDegreePercentage = '';
        standardTemplate.expertDegreePercentage = '';

        standardTemplate.reviewerDegree = {};
        standardTemplate.reviewerSuggestedDegree = {};
        standardTemplate.expertDegree = {};
        standardTemplate.expertSuggestedDegree = {};
        standardTemplate.pocAnswer = res?.result?.agencyAnswer?.pocAnswer;

        standardTemplate.agencyDegree.label =
          res?.result?.agencyAnswer?.finalCommitmentLevel?.title;
        standardTemplate.agencyDegree.value =
          res?.result?.agencyAnswer?.finalCommitmentLevel?.id;

        standardTemplate.agencyDegree =
          res?.result?.agencyAnswer?.finalCommitmentLevel;

        standardTemplate.agencySuggestedDegree.label =
          res?.result?.agencyAnswer?.selfEvaluationCommitmentLevel?.title;
        standardTemplate.agencySuggestedDegree.value =
          res?.result?.agencyAnswer?.selfEvaluationCommitmentLevel?.id;

        standardTemplate.agencySuggestedDegree =
          res?.result?.agencyAnswer?.selfEvaluationCommitmentLevel;
        standardTemplate.agencyDegreePercentage =
          res?.result?.agencyAnswer?.selfEvaluationDegree;

        setSelectedAgencyDegree(
          res?.result?.agencyAnswer?.finalCommitmentLevel
        );
        setSelectedId(res?.result?.agencyAnswer?.finalCommitmentLevel?.id);
        setRole(role.role);
        setSelectedDegreePercentage(standardTemplate.agencyDegreePercentage);

        setSelectedAgencyId(res?.result?.agencyAnswer?.id);
        setAgencySuggestedDegree(standardTemplate?.agencySuggestedDegree);
        standardTemplate.currentPhase = programPhase;
        setAnswers(res?.result.agencyFormAnswers);

        res?.result?.agencyAnswer?.agencyProofAnswers.forEach((proof1) => {
          standardTemplate.standardProofs.forEach((proof2) => {
            proof1.standardProofAttachments.forEach((item, index) => {
              if (proof1.standardProofId == proof2.id) {
                proof2.attachments.push(item);
                proof2.note = proof1.note;
              }
            });
          });
        });
        standardProgramContext?.setFiles(standardTemplate.standardProofs);
        if (
          permissionsCheck('Pages.UserType.POC') ||
          permissionsCheck('Pages.UserType.CIO') ||
          permissionsCheck('Pages.UserType.Delegate') ||
          permissionsCheck('Pages.UserType.Admin')
        ) {
          res?.result?.agencyAnswer?.agencyRequirementAnswers.forEach(
            (requirement1) => {
              standardTemplate.standardRequirements.forEach((requirement2) => {
                if (requirement2.id == requirement1.standardRequirementId) {
                  requirement2.decision = requirement1.decision;
                  requirement1.agencySubRequirementAnswers.forEach(
                    (subRequirement1) => {
                      requirement2.subStandardRequirements.forEach(
                        (subRequirement2) => {
                          if (
                            subRequirement2.id ==
                            subRequirement1.subStandardRequirementId
                          ) {
                            subRequirement2.decision = subRequirement1.decision;
                          }
                        }
                      );
                    }
                  );
                }
              });
            }
          );
        }

        standardProgramContext?.setRequirements(
          standardTemplate.standardRequirements
        );

        standardProgramContext?.setStandard(standardTemplate);
        getStandardAnswerReviewer(standardTemplate, role, programPhase);
      }
    }

    setLoadingData(false);
  };

  const getStandard = async (programPhase) => {
    setLoadingData(true);
    const res = await axiosGetRequest(environment.getStandardForAnswer, {
      id: standardId,
      agencyId: entityId,
    });
    if (res?.success) {
      const pastSurveyStandardsIds = [];
      if (res?.result.pastSurveyStandardAssociations) {
        res?.result.pastSurveyStandardAssociations.forEach((element) => {
          pastSurveyStandardsIds.push(element.pastSurveyStandardId);
        });
      }

      const standardsQuery = pastSurveyStandardsIds?.map((id) => id) || [];

      if (standardsQuery?.length > 0) {
        const getPrevFiles = await axiosPostRequest(
          environment.publishQiyas23AttachmentsForAgency,
          standardsQuery,
          { agencyId: entityId }
        );

        setPreviousSurveyFiles(getPrevFiles?.result);
      }

      res?.result?.standardProofs.forEach((element) => {
        element.note = '';
        element.attachments = [];
      });
      standardProgramContext?.setFiles(res?.result?.standardProofs);
      standardProgramContext?.setStandardId(res?.result?.id);
      res?.result?.standardRequirements.forEach((element) => {
        element.decision = 0;
        element.recommendation = '';
        element.donotApply = false;
        element.subStandardRequirements.forEach((subRequirement) => {
          subRequirement.decision = 0;
          subRequirement.recommendation = '';
          subRequirement.donotApply = false;
        });
      });
      standardProgramContext?.setRequirements(
        res?.result?.standardRequirements
      );
      standardProgramContext?.setRequirementsPrev(
        res?.result?.standardRequirements
      );

      standardProgramContext?.setAnswerWay(res?.result?.answerWay);

      standardProgramContext?.setTitle(res?.result?.title);

      standardProgramContext?.setStandard(res?.result);
      let arr = [];

      programPhase?.surveyStageRoles?.forEach((element) => {
        arr.push(element.role.name);
      });
      setRoles(arr);
      programPhase?.surveyStageRoles?.forEach((role) => {
        if (localStorage.getItem('userType') != role.role.name) {
          getStandardAgency(res?.result, role, programPhase);
        }
      });
    }

    setLoadingData(false);
  };

  const getDegrees = async (programPhase) => {
    const res = await axiosGetRequest(environment.getCommitmentLevels, {
      standardId,
    });

    if (res?.success) {
      res?.result?.forEach((degree) => {
        degree.label = degree.title;
        degree.value = degree.id;
      });
      standardProgramContext?.setDegrees([...res?.result]);
      getStandard(programPhase);
    }
  };

  const getAgency = async (programPhase) => {
    const res = await axiosGetRequest(environment.getAgency, { Id: entityId });

    if (res?.success) {
      standardProgramContext?.setAgency(res?.result);
      getDegrees(programPhase);
    }
  };

  const getCurrentProgramPhase = async () => {
    setLoading(true);

    const res = await axiosGetRequest(environment.getCurrentSurveyStage, {
      surveyId: localStorage.getItem('surveyId'),
    });

    if (res?.success) {
      setCurrentPhase(res?.result);
      getAgency(res?.result);
    }
  };

  async function getStandardInquiryList() {
    let newStatus = '';
    if (selectedStatusInquiry?.value != null) {
      newStatus = '&TicketStatus=' + selectedStatusInquiry?.value;
    }

    const res = await axiosGetRequest(
      environment.listInquiriesForStandard +
        '?StandardId=' +
        standardId +
        '&AgencyId=' +
        entityId +
        newStatus
    );
    if (res?.success) {
      standardProgramContext?.setInquiries(res?.result?.items);
      getCurrentProgramPhase();
    }
  }

  const addAnswerReviwer = async () => {
    if (!selectedReviewerDegreeId) {
      errorToast('يجب الاختيار من اجابة المدقق');
      return;
    }

    setLoading(true);
    standardProgramContext?.requirements.forEach((requirement) => {
      requirement.standardRequirementId = requirement.id;
      requirement.decision = requirement.decision;
      requirement.reviewerSubRequirementAnswers =
        requirement.subStandardRequirements;
      requirement.donotApply = requirement.donotApply;
      delete requirement.id;
      delete requirement.standardRequirementClassificationId;
      delete requirement.title;
      delete requirement.standardRequirementsAnswers;
      delete requirement.recommendation;
      delete requirement.standardRequirementClassificationTitle;
      delete requirement.donotApply;
      delete requirement.subStandardRequirements;
    });

    const reviewerRequirementAnswers =
      standardProgramContext?.requirements?.map((item) => ({
        standardRequirementId: item.standardRequirementId,
        decision: item.decision,
        reviewerSubRequirementAnswers: item.reviewerSubRequirementAnswers?.map(
          (item) => ({
            subStandardRequirementId: item.id,
            decision: item.decision,
          })
        ),
      }));
    let newData = {
      standardId: standardId,

      surveyStageId: currentPhase?.id,
      reviewerRequirementAnswers,
      noteForAgency: { content: standardProgramContext?.noteReviewer },

      reviewerId: parseInt(localStorage.getItem('userId')),
      agencyId: parseInt(entityId),

      finalDegree: reviewerSuggestedDegree?.fixedDegree,

      finalCommitmentLevelId: selectedReviewerDegreeId,
      selfEvaluationCommitmentLevelId: reviewerSuggestedDegree?.id,

      selfEvaluationDegree: selectedReviewerDegreePercentage,
    };

    const res = await axiosPostRequest(
      environment.addReviewerAnswer,
      newData,
      null,
      false
    );

    if (res?.success) {
      successToast('تم اضافة اجابة المدقق بنجاح');
    } else if (
      res?.response?.data?.error?.message ==
      "This Survey's agency answer is already Audited by the reviewer!"
    ) {
      errorToast('تمت الإجابة على هذا المعيار لهذه الجهة من قبل');
    } else {
      errorToast(res?.response?.data?.error?.message);
    }
    getStandardInquiryList();
  };

  const addAnswerExpert = async (type) => {
    if (!selectedExpertDegreeId) {
      errorToast('يجب الاختيار من اجابة الخبير');
      return;
    }
    setLoading(true);

    let newFiles = [];

    standardProgramContext?.requirements.forEach((requirement) => {
      requirement.standardRequirementId = requirement.id;
      requirement.expertSubRequirementAnswers =
        requirement.subStandardRequirements;
      requirement.decision = requirement.decision;
      requirement.recommendation = requirement.recommendation;
      requirement.donotApply = requirement.donotApply;
      delete requirement.id;
      delete requirement.standardRequirementClassificationId;
      delete requirement.title;
      delete requirement.standardRequirementsAnswers;
      delete requirement.content;
      delete requirement.standardRequirementClassificationTitle;
      delete requirement.subStandardRequirements;
    });

    standardProgramContext?.files.forEach((file) => {
      let obj = {};
      obj = {
        isNeedRoundVisit: !!parseInt(file.isNeedRoundVisit),
        standardProofId: file.id,
      };
      newFiles.push(obj);
    });

    const expertRequirementAnswers = standardProgramContext?.requirements?.map(
      (item) => ({
        recommendation: item?.recommendation,
        decision: item?.decision,
        donotApply: item?.donotApply,
        standardRequirementId: item?.standardRequirementId,
        expertSubRequirementAnswers: item.expertSubRequirementAnswers?.map(
          (item) => ({
            recommendation: item?.recommendation,
            decision: item?.decision,
            donotApply: item?.donotApply,
            subStandardRequirementId: item?.id,
          })
        ),
      })
    );
    let newData = {
      standardId: standardId,
      surveyStageId: currentPhase.id,
      expertRequirementAnswers,
      noteForAgency: { content: standardProgramContext?.noteExpert },
      noteForReviewer: {
        content: standardProgramContext?.noteExpertForReviewer,
      },

      isDraft: type == 'draft' ? true : false,
      expertId: parseInt(localStorage.getItem('userId')),
      agencyId: parseInt(entityId),

      finalDegree: expertSuggestedDegree?.fixedDegree,

      finalCommitmentLevelId: selectedExpertDegreeId,
      selfEvaluationCommitmentLevelId: expertSuggestedDegree?.id,

      selfEvaluationDegree: selectedExpertDegreePercentage,
    };

    const res = await axiosPostRequest(
      environment.addExpertAnswer,
      newData,
      null,
      false
    );

    if (res?.success) {
      successToast('تم اضافة اجابة خبير بنجاح');
    } else if (
      res?.response?.data?.error?.message ==
      "This Survey's agency answer is already Audited by the reviewer!"
    ) {
      errorToast('تمت الإجابة على هذا المعيار لهذه الجهة من قبل');
    } else {
      errorToast(res?.response?.data?.error?.message);
    }
    getStandardInquiryList();
  };

  const addAnswer = async () => {
    setLoading(true);
    if (pocAnswer) {
      errorToast('لا يمكنك الاجابة بعد أن تم ارسال الإجابات');
      history.goBack();

      return;
    }

    if (
      (!selectedId || selectedId?.length == 0) &&
      !standardProgramContext.donotApply
    ) {
      errorToast('يجب الاختيار من اجابة الجهة');
      return;
    }

    const mustHaveFiles = standardProgramContext?.files
      ?.filter((item) => item?.standardProofTypeId != 3)
      ?.map((item) => item?.id);

    standardProgramContext?.requirements?.map((requirement, i) => {
      requirement.standardRequirementId = requirement?.id;
      requirement.decision = requirement?.decision;
      requirement.agencySubRequirementAnswers =
        requirement?.subStandardRequirements;
      delete requirement.id;
      delete requirement.standardRequirementClassificationId;
      delete requirement.title;
      delete requirement.standardRequirementsAnswers;
      delete requirement.standardRequirementClassificationTitle;
      delete requirement.recommendation;
      requirement?.agencySubRequirementAnswers?.forEach((element) => {
        element.subStandardRequirementId = element?.id;
        element.decision = element?.decision;
        delete element.id;
        delete element.standardRequirementClassificationId;
        delete element.title;
        delete element.standardRequirementsAnswers;
        delete element.standardRequirementClassificationTitle;
        delete element.recommendation;
        delete element.standardRequirementTitle;
        delete element.standardRequirementId;
      });
      delete requirement.subStandardRequirements;
    });
    let newModels = [];
    standardProgramContext?.models?.map((model, index) => {
      model.standardFormAnswerDetails.forEach((element) => {
        newModels.push({ answer: element.answer, standardFormId: model.id });
      });
      delete model.choices;
      delete model.id;
      delete model.isRequied;
      delete model.standardFormTypeId;
      delete model.standardFormTypeTitle;
      delete model.title;
    });

    standardProgramContext?.files?.map((proof, index) => {
      proof.standardProofId = proof?.id;
      proof.standardProofAttachments = proof?.attachments;
      proof.note = proof?.note;

      delete proof.title;
      delete proof.id;
      delete proof.fileCount;
      delete proof.standardProofFileSizeTypeId;
      delete proof.standardProofFileTypeId;
      delete proof.standardProofTypeId;
      delete proof.fileSize;
      delete proof.isRequired;
      delete proof.standardProofTypeTitle;
      delete proof.standardProofFileTypeAssociations;
      delete proof.standardProofFileSizeTypeTitle;
      delete proof.attachments;
    });

    const agencyRequirementAnswers = standardProgramContext?.requirements?.map(
      (item) => ({
        standardRequirementId: item.standardRequirementId,
        decision: item.decision,
        donotApply: item.donotApply,
        agencySubRequirementAnswers: item.agencySubRequirementAnswers?.map(
          (item) => ({
            subStandardRequirementId: item.subStandardRequirementId,
            decision: item.decision,
            donotApply: item.donotApply,
          })
        ),
      })
    );

    let hasFiles = false;

    const agencyProofAnswers = standardProgramContext?.files?.map((item) => {
      const filesToSend = uploadedFiles
        ?.filter(
          (uploadedFile) =>
            item.standardProofId == uploadedFile.proofId &&
            uploadedFile?.attachmentId
        )
        .map((item) => ({
          attachmentId: item.attachmentId,
          fromPastYear: false,
        }));

      const prevFilesToSend =
        item.standardProofAttachments?.filter((item) => item.prevFiles)?.[0]
          ?.prevFiles || [];

      const prevUploadedFiles = item?.standardProofAttachments
        ?.slice(0, item?.standardProofAttachments?.length / 2)
        .map((file) => ({
          attachmentId: file?.attachment?.id,
          fromPastYear: file?.fromPastYear,
        }));

      const files = [...prevFilesToSend, ...filesToSend];

      if (files?.length > 0) {
        hasFiles = true;
      }

      return {
        standardProofId: item.standardProofId,
        note: item.note,
        standardProofAttachments: [
          ...prevFilesToSend,
          ...filesToSend,
          ...prevUploadedFiles,
        ],
      };
    });

    const hasAttachmentsFiles = agencyProofAnswers
      ?.filter(
        (item) =>
          mustHaveFiles?.includes(item?.standardProofId) &&
          item?.standardProofAttachments?.length > 0
      )
      ?.map((item) => mustHaveFiles?.includes(item?.standardProofId));

    const isAllRequiredFilesUploaded =
      hasAttachmentsFiles?.filter((item) => item == false)?.length == 0 &&
      mustHaveFiles?.length == hasAttachmentsFiles?.length;

    if (
      !isAllRequiredFilesUploaded &&
      selectedId == 384 &&
      standardProgramContext?.agency.isConfidentialAgency == false
    ) {
      errorToast('يجب ارفاق ملفات للمستندات الإجبارية');
      getStandardInquiryList();
      return;
    }

    if (selectedId?.length == 0) {
      errorToast('الرجاء التأكد من الإجابة');
      getStandardInquiryList();
      return;
    }

    let newData = {
      standardId: standardProgramContext?.standardId,
      agencyId: parseInt(entityId),
      surveyStageId: currentPhase?.id,
      agencyRequirementAnswers,

      agencyProofAnswers: agencyProofAnswers,

      finalDegree: agencySuggestedDegree?.fixedDegree,

      finalCommitmentLevelId:
        selectedId?.length == 0 ? 0 : selectedId != -1 ? selectedId : null,
      selfEvaluationCommitmentLevelId: agencySuggestedDegree?.id,

      selfEvaluationDegree: selectedDegreePercentage,
      isDraft: false,
      donotApply: standardProgramContext.donotApply,
      pocAnswer: false,
    };

    const res = await axiosPostRequest(environment.addAgencyAnswer, newData);
    if (res?.success) {
      successToast('تم اضافة الاجابة بنجاح');
      setUploadedFiles([]);
      setSelectedPrevfilesArray(
        Array(standardProgramContext?.files?.length).fill([])
      );
    }
  };

  const getReviewerSuggestedDegree = (res) => {
    setReviewerSuggestedDegree(res);
  };

  const getExpertSuggestedDegree = (res) => {
    setExpertSuggestedDegree(res);
  };

  const getAgencySuggestedDegree = (res) => {
    setAgencySuggestedDegree(res);
  };

  const getDegree = async (queryParams) => {
    const queryString = queryParams.toString();
    const requestURL = `${environment.getDegree}?${queryString}`;

    const res = await axiosGetRequest(requestURL);

    if (res?.success) {
      if (permissionsCheck('Pages.UserType.Reviewer')) {
        setSelectedReviewerDegreePercentage(res?.result);
      } else if (
        permissionsCheck('Pages.UserType.Expert') ||
        permissionsCheck('Pages.UserType.ExpertManager')
      ) {
        setSelectedExpertDegreePercentage(res?.result);
      } else {
        setSelectedDegreePercentage(res?.result);
      }
    }
  };

  const getSuggestedDegrees = async (
    ids,
    subIds = [],
    DonotAppliedRequirementsIds = [],
    DonotAppliedSubRequirementsIds = []
  ) => {
    const queryParams = new URLSearchParams();

    queryParams.append('standardId', standardId);

    ids.forEach((id) => {
      queryParams.append('CheckedRequirementsIds', id);
    });

    subIds.forEach((id) => {
      queryParams.append('CheckedSubRequirementsIds', id);
    });

    DonotAppliedRequirementsIds.forEach((id) => {
      queryParams.append('DonotAppliedRequirementsIds', id);
    });

    DonotAppliedSubRequirementsIds.forEach((id) => {
      queryParams.append('DonotAppliedSubRequirementsIds', id);
    });

    const queryString = queryParams.toString();
    const requestURL = `${environment.getSuggestedDegree}?${queryString}`;

    const res = await axiosGetRequest(requestURL);

    if (res?.success) {
      if (permissionsCheck('Pages.UserType.Reviewer')) {
        getReviewerSuggestedDegree(res?.result);
      } else if (
        permissionsCheck('Pages.UserType.Expert') ||
        permissionsCheck('Pages.UserType.ExpertManager')
      ) {
        getExpertSuggestedDegree(res?.result);
      } else {
        getAgencySuggestedDegree(res?.result);
      }

      getDegree(queryParams);
    }
  };

  const getCommentInquiry = (value) => {
    setComment(value);
  };

  const changeStatusInquiry = async (inquiryId, status) => {
    let data = {
      id: inquiryId,
      ticketStatus: status,
    };

    const res = await axiosPutRequest(environment.updateTicketStatus, data);
    if (res?.success) {
      successToast('تم تغيير حالة الاستفسار بنجاح');
      getStandardInquiryList();
    }
  };

  const addComment = async (inquiryId, attachmentId) => {
    let data = {
      comment: comment,
      attachmentId: attachmentId,
      ticketId: inquiryId,
    };

    const res = await axiosPostRequest(environment.replyOnTicket, data);
    if (res?.success) {
      setComment('');
      setSelectedImage('');
      getStandardInquiryList();
    }
  };

  const onFileUploadComment = (inquiryId) => {
    if (!selectedFileComment) return;
    setAddLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    const formData = new FormData();
    formData.append('Name', selectedFileComment.name);
    formData.append('FileType', 'image/*');
    formData.append('EntityType', '');
    formData.append('File', selectedFileComment);
    post(environment.attachmentFile, formData, config, (res) => {
      setAddLoading(false);
      addComment(inquiryId, res.data.result.id);
    });
  };

  function getAllCategories() {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    get('services/app/Category/GetAll', config, (res) => {
      if (res.status == 403) {
        setMessage({ type: 'error', message: res.data.error.message });

        setLoading(false);
      } else if (res.status == 500) {
        setMessage({ type: 'error', message: res.data.error.message });

        setLoading(false);
      } else if (res.status == 200) {
        if (res.data.result.items && res.data.result.items.length > 0) {
          var array = [];
          for (var i = 0; i < res.data.result.items.length; i++) {
            array.push({
              value: res.data.result.items[i].id,
              label: res.data.result.items[i].name.toString(),
              isSupported: res.data.result.items[i].isSupported,
              isSelected: false,
            });
          }
          setCategories(array);
        }
        setTimeout(() => {
          setLoading(false);
        }, 500);
      }
    });
  }

  function getAllPriorities() {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    get(environment.getAllPriorities, config, (res) => {
      if (res.status == 403) {
        setMessage({ type: 'error', message: res.data.error.message });

        setLoading(false);
      } else if (res.status == 500) {
        setMessage({ type: 'error', message: res.data.error.message });

        setLoading(false);
      } else if (res.status == 200) {
        if (res.data.result.items && res.data.result.items.length > 0) {
          var array = [];
          for (var i = 0; i < res.data.result.items.length; i++) {
            array.push({
              value: res.data.result.items[i].id,
              label: res.data.result.items[i].name,
              isSelected: false,
            });
          }
          setPriorities(array);
        }
        setTimeout(() => {
          setLoading(false);
        }, 500);
      }
    });
  }

  const onFileUpload = (values) => {
    if (!selectedFile) return;
    setAddLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    const formData = new FormData();
    formData.append('Name', selectedFile.name);
    formData.append('FileType', 'image/*');
    formData.append('EntityType', '');
    formData.append('File', selectedFile);
    post(environment.attachmentFile, formData, config, (res) => {
      setAddLoading(false);
      sendTicket('', res.data.result.id);
    });
  };

  const onChangeFile = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const sendTicket = (values, attachmentId) => {
    if (subject == '') {
      toast.error('من فضلك اضف موضوع الاستفسار', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    } else if (description == '') {
      toast.error('من فضلك اضف تفاصيل الاستفسار', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    } else {
      setAddLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      };
      let data = {
        description: description,
        categoryId: 3,
        subject: subject,
        attachmentId: attachmentId,
        priorityId: priority.value,
        standardId: standardProgramContext?.standard?.id,
      };
      post(environment.addInquiriesForStandard, data, config, (res) => {
        setAddLoading(false);
        if (res.status == 403) {
          toast.error(res?.data?.error?.message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        } else if (res.status == 500) {
          toast.error(res?.data?.error?.message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        } else if (res.status == 200) {
          toast.success(t('تم اٍرسال الاستفسار بنجاح'), {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
          getStandardInquiryList();
        }
      });
    }
  };

  const handleChangePriority = (value) => {
    setPriority(value);
  };
  const handleChangeSubject = (e) => {
    setSubject(e.target.value);
  };

  const getSelectedFile = (file) => {
    setSelectedFileComment(file);
  };

  return (
    <div className="bg-white rounded-[30px] m-5">
      <NewHeader />
      <Title
        iconTitle={programsIcon}
        withoutButton={
          permissionsCheck('Pages.UserType.POC') ||
          permissionsCheck('Pages.UserType.CIO') ||
          permissionsCheck('Pages.UserType.Delegate')
            ? false
            : true
        }
        withIcon={true}
        icon={plusLight}
        four={true}
        title={'الدورة الحالية'}
        subTitle={standardProgramContext?.standard?.surveyTitle}
        subTitleThired={
          standardProgramContext?.agency == null
            ? 'الجهة الحكومية'
            : standardProgramContext?.agency?.nameInArabic
        }
        subTitleFour={standardProgramContext?.title}
        titleButton={t('اٍضافة استفسار')}
        onClick={() => setOpenQuestionModel(true)}
        toPage={() => history.push('/programs')}
        toPageSeconed={() =>
          history.push(
            `/indicators/${indicatorId}/programs/${programId}/program`
          )
        }
        toPageThired={() =>
          history.push(
            `/indicators/${indicatorId}/programs/${programId}/program/${entityId}/entity`
          )
        }
        style={{ backgroundColor: '#292069' }}
      />
      <AppModal
        handleSubmit={() =>
          permissionsCheck('Pages.Tickets.Create')
            ? !selectedFile
              ? sendTicket('', null)
              : onFileUpload('')
            : () => {}
        }
        children={
          <div>
            <Formik
              initialValues={{ category: '', priority: '', description: '' }}
              validate={(values) => {
                const errors = {};
                if (!values.description) {
                  errors.description = t(
                    'modules.main.mailbox.subjectRequired'
                  );
                }
                return errors;
              }}
              onSubmit={(values) => {
                return true;
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="p-5 w-12/12">
                    <label className="label_input text-lg text-[#0D062D] mb-3 w-12/12">
                      {t('modules.main.tickets.priority')}
                    </label>
                    <DropdownMultiSelect
                      withoutLabel={true}
                      isSearchable={true}
                      value={priority}
                      handleChange={handleChangePriority}
                      placeholder={t('modules.main.tickets.priority')}
                      label={t('modules.main.tickets.priority')}
                      options={priorities}
                      isMultiple={false}
                      name="priority"
                      style={{
                        borderColor: errors.priority
                          ? 'red'
                          : values.priority != ''
                            ? '#22bb33'
                            : '',
                      }}
                    />
                    <div className="text-sm text-red-600 mt-2">
                      {errors.priority && touched.priority && errors.priority}
                    </div>
                  </div>
                  <div className="p-5 w-12/12">
                    <InputLabel
                      label={t('modules.main.tickets.subject')}
                      type={'text'}
                      value={subject}
                      onChange={(e) => handleChangeSubject(e)}
                      style={{
                        borderColor: errors.subject
                          ? 'red'
                          : values.subject != ''
                            ? '#22bb33'
                            : '',
                      }}
                      name="subject"
                    />
                    <div className="text-sm text-red-600 mt-3">
                      {errors.subject && touched.subject && errors.subject}
                    </div>
                    <div className="mb-2 w-full flex flex-row items-center justify-between"></div>
                    <div className="mb-2 w-full flex flex-col items-center justify-between">
                      <div className="p-5 w-full">
                        <label className="label_input text-lg text-[#0D062D]">
                          {t('modules.main.tickets.description')}
                        </label>
                        <div className=" mt-3">
                          <AppEditor
                            value={description}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setDescription(data);
                              editor.plugins.get(
                                'FileRepository'
                              ).createUploadAdapter = function (loader) {
                                return new UploadAdapter(loader);
                              };
                            }}
                          />
                        </div>
                      </div>
                      <div className="p-5 w-full">
                        <InputLabel
                          style={{ display: 'none' }}
                          fileInputWidth={'100%'}
                          type={'file'}
                          id={'fileUpload'}
                          label={t('modules.main.tickets.file')}
                          isImage={true}
                          multiple={false}
                          onChange={onChangeFile}
                          fileName={selectedFile.name}
                          isRequired={false}
                        />
                      </div>
                    </div>

                    <div className="w-full flex flex-row justify-end items-center px-5"></div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        }
        open={openQuestionModal}
        setOpen={() => setOpenQuestionModel(false)}
        headerTitle={'اضافة استفسار'}
      />
      {loadingData ? (
        <div className="min-h-[400px] flex justify-center items-center">
          <AppLoader />
        </div>
      ) : (
        <div>
          <div className="flex flex-col justify-around">
            <div className="text-center text-lg text-[#292069]">
              {currentPhase?.titleAr}
            </div>
          </div>
          <StandardAnswerTopTaps
            showSection={showSection}
            setShowSection={setShowSection}
          />

          <AppModal
            open={openWarningModal}
            setOpen={setOpenWarningModal}
            headerTitle=""
            submitLabel="اغلاق"
            handleSubmit={() => setOpenWarningModal(false)}
          >
            {' '}
            <h2>الجهة لم تجاوب بعد ، نرجو الانتظار</h2>{' '}
          </AppModal>
          {showSection == 'standard' ? (
            <div className="bg-[#F9F9FF] flex flex-row items-center justify-around mr-3 rounded-xl p-3">
              <StandardAnswerSideTaps
                menuElement={menuElement}
                setMenuElement={setMenuElement}
              />

              <div className="w-full mx-2 bg-white mt-4 overflow-y-scroll relative rounded-md h-[505px] scrollGroup">
                {menuElement === 0 && (
                  <StandardAnswerData
                    loading={loadingData}
                    setMenuElement={setMenuElement}
                    pocAnswer={pocAnswer}
                  />
                )}
                {menuElement === 1 && (
                  <StandardAnswerRequirement
                    menuClicked={menuClicked}
                    requirementId={requirementId}
                    setMenuClicked={setMenuClicked}
                    setRequirementId={setRequirementId}
                    idsSubRequirement={idsSubRequirement}
                    setIdsSubRequirement={setIdsSubRequirement}
                    idsRequirement={idsRequirement}
                    ids={ids}
                    setIds={setIds}
                    setIdsRequirement={setIdsRequirement}
                    getSuggestedDegrees={getSuggestedDegrees}
                    setMenuElement={setMenuElement}
                    setDonotApplyRequirementsIds={setDonotApplyRequirementsIds}
                    donotApplyRequirementsIds={donotApplyRequirementsIds}
                    setDonotApplySubRequirementsIds={
                      setDonotApplySubRequirementsIds
                    }
                    donotApplySubRequirementsIds={donotApplySubRequirementsIds}
                    pocAnswer={pocAnswer}
                  />
                )}
                {menuElement === 2 && (
                  <StandardAnswerSelectedRequirements
                    setMenuElement={setMenuElement}
                  />
                )}
                {menuElement === 3 && (
                  <StandardAgencyAnswer
                    setSelectedAgencyDegree={setSelectedAgencyDegree}
                    setSelectedId={setSelectedId}
                    selectedId={selectedId}
                    setSelectedReviewerDegree={setSelectedReviewerDegree}
                    setSelectedReviewerDegreeId={setSelectedReviewerDegreeId}
                    selectedReviewerDegreeId={selectedReviewerDegreeId}
                    setSelectedExpertDegree={setSelectedExpertDegree}
                    selectedExpertDegreeId={selectedExpertDegreeId}
                    pocAnswer={pocAnswer}
                    setSelectedExpertDegreeId={setSelectedExpertDegreeId}
                    addAnswer={addAnswer}
                    addAnswerExpert={addAnswerExpert}
                    addAnswerReviwer={addAnswerReviwer}
                    setMenuElement={setMenuElement}
                  />
                )}
                {menuElement === 4 && (
                  <StandardAnswerProofFiles
                    setMessage={setMessage}
                    setOpenConfirmModal={setOpenConfirmModal}
                    setOpenFiles={setOpenFiles}
                    previousSurveyFiles={previousSurveyFiles}
                    selectedPrevfilesArray={selectedPrevfilesArray}
                    setSelectedPrevfilesArray={setSelectedPrevfilesArray}
                    setMenuElement={setMenuElement}
                    pocAnswer={pocAnswer}
                  />
                )}
                {menuElement === 5 && (
                  <StandardAnswerTemplates
                    answers={answers}
                    setAnswers={setAnswers}
                    setMenuElement={setMenuElement}
                  />
                )}
                {menuElement !== 0 &&
                  menuElement !== 1 &&
                  menuElement !== 2 &&
                  menuElement !== 3 &&
                  menuElement !== 4 &&
                  menuElement !== 5 && (
                    <StandardAnswerNotes
                      templates={templates}
                      selectedTemplates={selectedTemplates}
                      setSelectedTemplates={setSelectedTemplates}
                      addAnswerReviwer={addAnswerReviwer}
                      addAnswerExpert={addAnswerExpert}
                    />
                  )}
              </div>
            </div>
          ) : (
            <>
              <div className="bg-[#F9F9FF] flex flex-row items-center justify-around mr-3 rounded-xl p-3">
                <div className="w-[100%]">
                  <Inquiries
                    statusesInquiry={statusesInquiry}
                    changeStatusInquiry={changeStatusInquiry}
                    addComment={
                      !selectedFileComment ? addComment : onFileUploadComment
                    }
                    getComment={getCommentInquiry}
                    loading={loading}
                    getSelectedStatus={getSelectedStatusInquiry}
                    getStandardInquiryList={getStandardInquiryList}
                    inquiries={standardProgramContext?.inquiries}
                    code={standardProgramContext?.standard?.code}
                    getSelectedFile={getSelectedFile}
                  />
                </div>
              </div>
            </>
          )}
          {stageRoles.includes(localStorage.getItem('userType')) && (
            <StandardAnswerDegrees
              agencySuggestedDegree={agencySuggestedDegree}
              expertSuggestedDegree={expertSuggestedDegree}
              reviewerSuggestedDegree={reviewerSuggestedDegree}
              role={role}
              roles={roles}
              selectedAgencyDegree={selectedAgencyDegree}
              selectedExpertDegree={selectedExpertDegree}
              selectedReviewerDegree={selectedReviewerDegree}
              setSelectedReviewerDegreePercentage={
                setSelectedReviewerDegreePercentage
              }
              setReviewerSuggestedDegree={setReviewerSuggestedDegree}
              setExpertSuggestedDegree={setExpertSuggestedDegree}
              setAgencySuggestedDegree={setAgencySuggestedDegree}
              setSelectedExpertDegreePercentage={
                setSelectedExpertDegreePercentage
              }
              setSelectedDegreePercentage={setSelectedDegreePercentage}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default StandardAnswerMain;

function objToFormData(obj) {
  const formData = new FormData();
}
