import { Image, Page, StyleSheet } from '@react-pdf/renderer';
import { Footer } from '../components/Footer';
import Slide8 from '../assets/Slide8.jpg';

export function Page8({ agencyTitle, globalStyles }) {
  const styles = StyleSheet.create({
    ...globalStyles,
  });

  return (
    <Page size="A4" style={styles.imagePage}>
      <Image src={Slide8} style={styles.image} />
      <Footer agencyTitle={agencyTitle} />
    </Page>
  );
}
