import { useState } from 'react';
import { AdminDashboardContextProvider } from '../../contexts/AdminDashboardContext';
import DashboardTabs from '../../components/DashboardTabs';
import AdminDashboardPhase1 from './phase1';
import AdminDashboardPhase2 from './phase2';
import AdminDashboardPhase3 from './phase3';
import AdminDashboardPhase4 from './phase4';

function AdminDashboard() {
  const [activeTab, setActiveTab] = useState(0);

  const dashboardPhases = [
    {
      title: 'فتح النظام',
      content: <AdminDashboardPhase1 />,
    },
    {
      title: 'التحقق  الأولى',
      content: <AdminDashboardPhase2 />,
    },
    {
      title: 'إعادة فتح النظام',
      content: <AdminDashboardPhase3 />,
    },
    {
      title: 'التحقق النهائي',
      content: <AdminDashboardPhase4 />,
    },
  ];

  return (
    <AdminDashboardContextProvider>
      <DashboardTabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabs={dashboardPhases}
      />
    </AdminDashboardContextProvider>
  );
}

export default AdminDashboard;
