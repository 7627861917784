import { Link, useLocation } from 'react-router-dom';

const SecondarySideLinks = ({ linksArr }) => {
  const { pathname, search } = useLocation();
  const route = pathname + search;

  const renderedTapLinks = linksArr.map((item, indx) => (
    <li className={` my-1`}>
      <Link
        to={item.url}
        className={`pb-4 pt-2 px-5 block rounded-full cursor-pointer ${
          route === item.url ? 'bg-[#1cc08119]' : ''
        }`}
      >
        {item.title}
      </Link>
      {linksArr.length - 1 !== indx && (
        <span className="block my-2 h-[2px] w-[70%] mx-auto bg-slate-200"></span>
      )}
    </li>
  ));

  return <ul className="mb-6">{renderedTapLinks}</ul>;
};

export default SecondarySideLinks;
