const AnswerDegreeItem = ({ title, circleColor, degree, range }) => {
  return (
    <div className="flex gap-3 my-1 items-center">
      <span className="text-base text-[#7D8BAB]  flex-shrink-0"> {title}</span>
      <div className="flex flex-row items-center  flex-shrink-0">
        <div
          style={{
            backgroundColor: circleColor,
          }}
          className={`rounded-full h-[10px] w-[10px] mr-2 ml-2 mt-2`}
        ></div>
        <span className="text-sm text-[#7C32C9]">{degree}</span>
      </div>
      {range && (
        <span className="text-base text-[#292069] flex-shrink-0">
          ( {range} )
        </span>
      )}
    </div>
  );
};

export default AnswerDegreeItem;
