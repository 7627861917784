import { Text, View } from '@react-pdf/renderer';

const RecomendationRow = ({ requirement }) => {
  const cleanText = (input) => {
    // just this !
    return input
      .replace(/لا/g, 'لـا')
      .replace(/لأ/g, 'لـأ')
      .replace(/لإ/g, 'لـإ')
      .normalize('NFKC')
      .replace(/\s+/g, ' ')
      .trim();
    const arr = [
      'الزمني',
      'الزمنيه',
      'والزمني',
      'الزمنيه',
      'والزمني',
      'الزمنيه',
      'الزمني',
    ];
    const KEYS = [
      'الأعمال',
      'الامن',
      'الاتصالات',
      'والاتصالات',
      'والأمن',
      'إطلاق',
      'لأنشطة',
      'الأدوار',
      'الإدارية',
      'والإجراءات',
      'الإجراءات',
      'الأول',
      'والأرشفة',
      'الأنظمة',
      'الابتكاري',
      'الالتزام',
      'والمجالات',
      'بالاعتبار',
      'والأنظمة',
      'الصلاحيات',
    ];

    const KEYS_REPLACEMENT = {
      الأعمال: 'الـأعمال',
      الامن: 'الـأمن',
      الاتصالات: 'الإتصالـات',
      والأمن: 'والـأمن',
      والاتصالات: 'والإتصالـات',
      إطلاق: 'إطلآق',
      لأنشطة: 'لانشطة',
      الأدوار: 'الادوار',
      الإجراءات: 'الاجراءات',
      والإجراءات: 'والاجراءات',
      الإدارية: 'الادارية',
      الأول: 'الاول',
      والأرشفة: 'والآَرشفة',
      الأنظمة: 'الـانظمة',
      الابتكاري: 'الـابتكاري',
      الالتزام: 'الـالتزام',
      والمجالات: 'والمجالـات',
      بالاعتبار: 'بالـاعتبـار',
      الصلاحيات: 'الـصلـاحيات',
    };

    return input
      .normalize('NFKC')
      .replace(/\s+/g, ' ')
      .trim()
      .split(' ')
      .map((key) => (KEYS.includes(key) ? KEYS_REPLACEMENT[key] : key))
      .join(' ')
      .normalize('NFKC')
      .replace(/\s+/g, ' ')
      .trim();
    // ! do not remove it please
    // return input.normalize("NFKC").replace(/\s+/g, " ").trim().replaceAll("الأعمال", "الأعمال" + '\u200B').replace("الاتصالات", "الإتصالـات").replaceAll("الأمن", "الـأمن").normalize("NFKC").replace(/\s+/g, " ").trim()
    // return input.normalize("NFKC").replace(/\s+/g, " ").trim();
    // if (input.includes("الأعمال"))
    // if (input.includes("الاتصالات")) return input.replace("الاتصالات", "الإتصالات")
    // if (input.includes("الأمن")) return input.replace("الأمن", "----")
  };
  return (
    <>
      <View
        style={{
          flexDirection: 'column',
          alignItemWs: 'flex-end',
        }}
      >
        {requirement.isShowRecommendation && (
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              margin: 0,
              padding: 0,
              paddingLeft: 10,
              justifyContent: 'flex-end',
            }}
          >
            <View
              style={{
                width: '97%',
              }}
            >
              <Text
                style={{
                  textAlign: 'right',
                  direction: 'rtl',
                }}
              >
                {cleanText(requirement.recommendation)}
              </Text>
            </View>
            <View>
              <Text style={{ fontWeight: 900, marginLeft: 5 }}>•</Text>
            </View>
          </View>
        )}
      </View>
      {requirement.subStandardRequirements
        ?.filter((item) => item.isShowRecommendation)
        ?.map((subReq, index) => (
          <RecomendationRow requirement={subReq} key={index} />
        ))}
    </>
  );
};

export function StandardView({ standard, styles }) {
  return (
    <View
      // wrap={false}
      style={{
        borderStyle: 'solid',
        borderRadius: 8,
        borderWidth: 1,
        borderColor: '#ccc',
        overflow: 'hidden',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 4,
      }}
    >
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          fontSize: 8,
          width: '15%',
          justifyContent: 'space-between',
          backgroundColor:
            standard.surveyExpertAnswer?.finalCommitmentLevel?.color,
        }}
      >
        <View
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
            alignSelf: 'flex-end',

            color: getColorBasedOnBGColor(
              standard.surveyExpertAnswer?.finalCommitmentLevel?.color
            ),
          }}
        >
          <Text>
            {standard.surveyExpertAnswer?.finalCommitmentLevel?.title}{' '}
          </Text>
        </View>
      </View>

      <View
        style={{
          fontSize: 8,
          width: '85%',
          borderStyle: 'solid',
        }}
      >
        <View style={styles.tableRow}>
          <View
            style={{
              width: '80%',
              paddingLeft: '5px',
              textAlign: 'right',
            }}
          >
            <Text>{standard.title} </Text>
          </View>
          <View
            style={{
              textAlign: 'right',
              width: '14%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-end',
              gap: 5,
              borderLeft: '1px solid #eee',
            }}
          >
            <Text style={{ color: '#2A2269', fontWeight: 600 }}>المعيار</Text>
          </View>
          <View
            style={{
              textAlign: 'right',
              width: '6%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-end',
              gap: 5,
              borderLeft: '1px solid #eee',
            }}
          >
            <Text>{standard.code} </Text>
          </View>
        </View>
        {standard.standardRequirements?.some(
          (item) =>
            item.isShowRecommendation ||
            item.subStandardRequirements?.some(
              (subReq) => subReq.isShowRecommendation
            )
        ) && (
          <View
            style={{
              ...styles.tableRow,
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <View
              style={{
                width: '80%',
                paddingLeft: '5px',
              }}
            >
              {standard.standardRequirements?.map((requirement) => (
                <RecomendationRow requirement={requirement} />
              ))}
            </View>
            <View
              style={{
                textAlign: 'right',
                width: '14%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-end',
                gap: 5,
                borderLeft: '1px solid #eee',
              }}
            >
              <Text style={{ color: '#2A2269', fontWeight: 600 }}>
                التوصية{' '}
              </Text>
            </View>
            <View
              style={{
                textAlign: 'right',
                width: '6%',
                padding: 4,
                borderLeft: '1px solid #eee',
              }}
            >
              <Text style={{ color: '#2A2269', fontWeight: 600 }}></Text>
            </View>
          </View>
        )}
      </View>
    </View>
  );
}

function getColorBasedOnBGColor(bgColor) {
  if (bgColor === '#000000') {
    return '#fff';
  } else {
    return '#000';
  }
}

// function logAndReturn(value) {

//   // return value;
//   return value.replace(/\s+/g, " ").trim();
// }
