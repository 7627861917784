import React from 'react';
import Skeleton from 'react-skeleton-loader';

const ClassificationCardSkeleton = ({}) => {
  return (
    <div className="bg-white rounded-lg p-5">
      <div className="flex justify-between items-center">
        <div className="flex">
          <div className="flex items-center ml-10">
            <Skeleton width={'250px'} />
          </div>
          <div className="flex items-center">
            <Skeleton width={'150px'} />
          </div>
        </div>
        <div className="flex gap-2">
          <Skeleton width={'50px'} />
          <Skeleton width={'50px'} />
        </div>
      </div>

      <div className="flex items-center mt-5">
        <Skeleton width={'500px'} />
      </div>
    </div>
  );
};

export default ClassificationCardSkeleton;
