import { useState } from 'react';
import AppActions from '../../../../../../components/appActions';
import UpdateDegreeModal from '../UpdateDegreeModal';
import AppDeleteItemModal from '../../../../../../components/Models/appDeleteItemModal';
import environment from '../../../../../../services/environment';
import { commitmentOperatorsOptions } from '../constants';

const DegreeItem = ({
  id,
  title,
  color,
  rangeFrom,
  rangeFromOperator,
  fixedDegree,
  rangeTo,
  rangeToOperator,
  surveyId,
  setDegreeList,
  degreeList,
}) => {
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const rangeFromOperatorObj = commitmentOperatorsOptions?.find(
    (item) => item.id == rangeFromOperator
  );
  const rangeToOperatorObj = commitmentOperatorsOptions?.find(
    (item) => item.id == rangeToOperator
  );

  const removeDeletedDegree = () => {
    const deleteItem = degreeList.filter((item) => item.id != id);

    setDegreeList(deleteItem);
  };

  return (
    <div className="bg-white rounded-lg row gap-y-6 mb-4 mx-1 p-6">
      <div className="col-md-4">
        <div className="flex items-center mb-1">
          <p className="text-blue_text">الدرجة</p>
          <p className="px-3 text-sm text-light">{title}</p>
        </div>
      </div>
      <div className="col-md-4">
        <div className="flex items-center mb-1">
          <p className="text-blue_text">اللون</p>
          <div
            className="mx-3 rounded-lg text-sm w-[100px] h-[30px]"
            style={{ backgroundColor: color || '#09c' }}
          ></div>
        </div>
      </div>
      <div className="col-md-4">
        <div className="flex items-center mb-1">
          <p className="text-blue_text">النسبة الثابتة</p>
          <p className="px-3 text-sm text-green"> {fixedDegree} %</p>
        </div>
      </div>
      <div className="col-md-4">
        <div className="flex items-center mb-1">
          <p className="text-blue_text">النسبة من</p>
          <p className="px-3 text-sm text-green"> {rangeFrom} %</p>
          <p className="px-3 text-sm text-green">
            ( {rangeFromOperatorObj?.label ?? ''} )
          </p>
        </div>
      </div>
      <div className="col-md-4">
        <div className="flex items-center mb-1">
          <p className="text-blue_text">النسبة الى</p>
          <p className="px-3 text-sm text-green"> {rangeTo} %</p>
          <p className="px-3 text-sm text-green">
            ( {rangeToOperatorObj?.label ?? ''} )
          </p>
        </div>
      </div>
      <div className="col-md-4">
        <AppActions
          handleEditClick={() => {
            setOpenUpdateModal(true);
          }}
          handleDeleteClick={() => {
            setOpenDeleteModal(true);
          }}
        />
      </div>
      <UpdateDegreeModal
        id={id}
        openModal={openUpdateModal}
        setOpenModal={setOpenUpdateModal}
        values={{
          id,
          title,
          color,
          rangeFrom,
          fixedDegree,
          rangeTo,
          surveyId,
          rangeFromOperator: rangeFromOperatorObj,
          rangeToOperator: rangeToOperatorObj,
        }}
        setDegreeList={setDegreeList}
        degreeList={degreeList}
      />

      <AppDeleteItemModal
        id={id}
        modalTitle="حذف درجة"
        itemTitle={title}
        openModal={openDeleteModal}
        setOpenModal={setOpenDeleteModal}
        api={environment.deleteCommitmentLevel}
        params={{ Id: id }}
        setDegreeList={setDegreeList}
        degreeList={degreeList}
        removeItemFromList={removeDeletedDegree}
      />
    </div>
  );
};

export default DegreeItem;
