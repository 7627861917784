import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { axiosGetRequest, post } from '../../../../services/Request';
import environment from '../../../../services/environment';
import NewDropdownMultiSelect from '../../../../components/NewDropDownMultiSelect';
import { Formik } from 'formik';

export const CopyQuestion = ({
  copyFormRef,
  refetchSurveyQuestions,
  showModal,
  satisfictionSurveyId,
}) => {
  const [selectedQuestions, setSelectedQuestions] = useState([]);

  const { data: allQuestions = [], isLoading: isGettingAllQuestions } =
    useQuery({
      queryKey: ['GetAllRateSurviesQuestionsBySurveyId'],
      queryFn: () =>
        axiosGetRequest(environment.GetAllRateSurviesQuestionsBySurveyId, {
          surveyId: localStorage.getItem('surveyId'),
          maxResultCount: 1000,
        }),
      select: (res) => res?.result?.items,
    });

  const copyQuestionToSurvey = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };

    const data = selectedQuestions?.map((item) => Number(item.value));

    post(
      `${environment.CopyRateSurveyQuestion}?RateSurveyId=${satisfictionSurveyId}`,
      data,
      config,
      (res) => {
        if (res.status === 200) {
          toast.success('تم نسخ السؤال بنجاح');
          showModal(false);
        } else if (res.status === 500 || res.status === 400) {
          toast.error(res.data.error.message);
        }
        refetchSurveyQuestions();
      }
    );
  };

  const handleQuestionsChange = (value) => {
    setSelectedQuestions(value);
  };

  const handleSubmit = (values) => {
    copyQuestionToSurvey(values);
  };

  return (
    <Formik
      innerRef={copyFormRef}
      initialValues={{}}
      validate={(values) => {}}
      onSubmit={(values, { setSubmitting }) => {
        handleSubmit(values);
      }}
    >
      <NewDropdownMultiSelect
        isSearchable={true}
        value={selectedQuestions}
        handleChange={handleQuestionsChange}
        label="اختر السؤال"
        placeholder="اختر السؤال"
        options={allQuestions?.map((item) => ({
          label: item.title,
          value: item.id,
        }))}
        isMultiple={true}
        loading={isGettingAllQuestions}
      />
    </Formik>
  );
};
