import DeleteIcon from '../../../../components/DeleteIcon';
import NewTable from '../../../../components/NewTable';
import { useEffect, useRef, useState } from 'react';
import environment from '../../../../services/environment';
import EditIcon from '../../../../components/EditIcon';
import { InputLabel } from '../../../../components';
import {
  axiosDeleteRequest,
  axiosPutRequest,
} from '../../../../services/Request';
import { toast } from 'react-toastify';
import { NewForm } from '../../../../components/form/NewForm';
import { ErrorMessage } from 'formik';
import { permissionsCheck } from '../../../../helpers/utils';
import useAxiosGetRequest from '../../../../helpers/customHooks/useAxiosGetRequest';
import NewDropdownMultiSelect from '../../../../components/NewDropDownMultiSelect';
import ShowAgencies from '../../../../components/ShowAgencies';

function AgencyCategoriesTable({
  getAllAgencyCategories,
  allAgencyCategories,
  loading,
}) {
  const [inputs, setInputs] = useState({
    title: '',
    description: '',
  });

  const handleChangeInputs = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const [categoryAgenceisIds, setCategoryAgenceisIds] = useState([]);
  const handleAgenciesChange = (value) => {
    setCategoryAgenceisIds(value || []);
  };
  const [getAllAgencies, allAgencies] = useAxiosGetRequest();

  useEffect(() => {
    getAllAgencies(environment.getAllAgencyWithoutPagination, {});
  }, []);

  const allAgenciesOptions =
    allAgencies?.result &&
    allAgencies?.result?.map((item) => {
      return {
        label: item.nameInArabic || '',
        value: item.id,
        name: item.name || '',
      };
    });

  const [editLoading, setEditLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  // ----------------------------------------------------- //
  const formRef = useRef(null);

  const handleFormik = () => {
    if (formRef.current) {
      formRef.current?.handleSubmit();
    }
  };

  const initialValues = {
    title: '',
    agencies: [],
  };

  const validate = () => {
    const errors = {};

    if (!inputs.title) errors.title = 'هذا الحقل مطلوب';

    if (!categoryAgenceisIds.length) errors.agencies = 'هذا الحقل مطلوب';

    return errors;
  };

  // ----------------------------------------------------- //

  const columns = [
    {
      name: 'اسم الفئة',
      selector: (row) => row.titleArabic,
      header: 'titleArabic',
      accessorKey: 'titleArabic',
      footer: (props) => props.column.titleArabic,
    },
    {
      name: 'الوصف',
      selector: (row) => row.description,
      header: 'description',
      accessorKey: 'description',
      footer: (props) => props.column.description,
    },
    {
      name: 'الاجراءات',
      selector: (row) => row.actions,
      header: 'actions',
      accessorKey: 'actions',
      footer: (props) => props.column.actions,
      cell: (row) => {
        return (
          permissionsCheck('Pages.AgencyCategories.Edit') && (
            <div className="flex gap-2">
              <EditIcon
                initialValuesFunction={() => {
                  setInputs({
                    title: row.titleArabic,
                    description: row.description,
                  });
                  setCategoryAgenceisIds(
                    row.agencies.map((ele) => {
                      return {
                        label: ele.nameInArabic,
                        value: ele.id,
                      };
                    })
                  );
                }}
                loading={editLoading}
                editFunction={handleFormik}
                type={'submit'}
                editedName={row.titleArabic}
                editModalContent={
                  <NewForm
                    initialValues={initialValues}
                    validate={validate}
                    innerRef={formRef}
                    onSubmit={() => handleUpdateAgencyCategory(row)}
                  >
                    <div className="flex flex-col gap-3">
                      <div className="space-y-1">
                        <InputLabel
                          label={'اسم الفئة'}
                          name={'title'}
                          type={'text'}
                          value={inputs.title}
                          onChange={handleChangeInputs}
                        />

                        <ErrorMessage
                          className="text-red-700"
                          name="title"
                          component="div"
                        />
                      </div>

                      <div className="space-y-1">
                        <InputLabel
                          label={'الوصف'}
                          name={'description'}
                          type={'text'}
                          value={inputs.description}
                          onChange={handleChangeInputs}
                          isRequired={false}
                        />
                      </div>

                      <div className="space-y-1">
                        <NewDropdownMultiSelect
                          isClearable={true}
                          withoutLabel={false}
                          isSearchable={true}
                          value={categoryAgenceisIds}
                          handleChange={handleAgenciesChange}
                          placeholder="ادخل اسم الجهات"
                          label="الجهات الخاصة بهذا القطاع"
                          options={allAgenciesOptions || []}
                          isMultiple={true}
                        />

                        <ErrorMessage
                          className="text-red-700"
                          name="agencies"
                          component="div"
                        />
                      </div>
                    </div>
                  </NewForm>
                }
              />
              {permissionsCheck('Pages.AgencyCategories.Delete') ? (
                <DeleteIcon
                  loading={deleteLoading}
                  id={row.id}
                  deleteFunction={() => handleDeleteAgencyCategory(row.id)}
                  deletedName={row.titleArabic}
                />
              ) : null}

              <ShowAgencies
                agenciesInRow={row?.agencies}
                headerTitle="الجهات الخاصة بهذا التصنيف"
              />
            </div>
          )
        );
      },
    },
  ];

  // handleUpdateAgencyCategory
  const handleUpdateAgencyCategory = async (row) => {
    const agenceisIds = categoryAgenceisIds.map((ele) => ele.value);
    const body = {
      id: row.id,
      title: inputs.title,
      titleArabic: inputs.title,
      description: inputs.description,
      agenceisIds: agenceisIds,
    };

    setEditLoading(true);
    const res = await axiosPutRequest(environment.updateCategory, body);
    setEditLoading(false);
    if (res?.success) {
      toast.info(`تم تحديث الفئة بنجاح`);
      getAllAgencyCategories(environment.GetAllAgencyCategory);
    } else {
      toast.error(res?.response?.data?.error?.message);
    }
  };

  // handleDeleteAgencyCategory
  const handleDeleteAgencyCategory = async (id) => {
    setDeleteLoading(true);
    const res = await axiosDeleteRequest(environment.deleteCategory, {
      Id: id,
    });
    setDeleteLoading(false);
    if (res?.success) {
      toast.success('تم الحذف بنجاح');
      getAllAgencyCategories(environment.GetAllAgencyCategory);
    }
  };

  return (
    <div className="">
      {permissionsCheck('Pages.AgencyCategories.GetAll') ? (
        <NewTable
          loading={loading}
          columns={columns}
          data={allAgencyCategories?.result?.items || []}
          pagination={false}
        />
      ) : (
        <div className="text-[#bb2124] text-xl text-center flex justify-center items-center">
          {'ليس لديك صلاحية لهذا'}
        </div>
      )}
    </div>
  );
}

export default AgencyCategoriesTable;
