import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { programsIcon } from '../../../../assets/icons/Index';
import AppBadge from '../../../../components/AppBadge';
import AppModal from '../../../../components/Models/appModal';
import NewTable from '../../../../components/NewTable';
import NewTooltip from '../../../../components/NewTooltip';
import AppDivider from '../../../../components/appDivider';
import HeaderWithBreadcrumbLayout from '../../../../layouts/headerWithBreadcrumbLayout';
import {
  axiosGetRequest,
  axiosPostRequest,
} from '../../../../services/Request';
import environment from '../../../../services/environment';
import { ExpandableRowsComponent } from './components';
import AnswersReportFilter from './components/AnswersReportFilter';
import { useGetAnswersReport } from './useGetAnswersReport';
import { downloadFile, generateRandomString } from './utils';
import { exportColumns } from './constants';
import { set } from 'date-fns';

function AnswersReportPage() {
  const [currentPage, setCurrentPage] = useState(0);
  const [maxResult, setMaxResult] = useState({ label: '50', value: 50 });

  const [standardProofAndItsAttachment, setStandardProofAndItsAttachment] =
    useState([]);
  const [excelLoading, setExcelLoading] = useState(false);
  const [excelExtraLoading, setExcelExtraLoading] = useState(false);
  const [openAttachmentModal, setOpenAttachmentModal] = useState(false);
  const [exportModal, setExportModal] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState(exportColumns);
  const [exportFromDate, setExportFromDate] = useState(null);
  const [exportToDate, setExportToDate] = useState(null);
  const [selectedStageId, setSelectedStageId] = useState(null);

  const [filterInputs, setFilterInputs] = useState({
    agencyName: null,
    standardCode: null,
    auditorName: null,
    expertName: null,
  });

  const surveyId = localStorage.getItem('surveyId');
  const { data: stagesData } = useQuery({
    queryKey: ['surveyStage', surveyId],
    queryFn: () => {
      return axiosGetRequest(environment.getSurveyStagesBySuveyId, {
        surveyId,
      });
    },
    enabled: !!surveyId,
  });

  const handlePageClick = (event) => {
    const newOffset = event.selected;
    setCurrentPage(newOffset);
  };

  const { answersReport, isGettingAnswersReport, totalPages } =
    useGetAnswersReport({
      currentPage,
      maxResult: maxResult.value,
      filterInputs,
    });

  const columns = [
    {
      name: 'رمز الجهة',
      sortable: true,
      cell: (info) => (
        <div className="text-[#292069] font-semibold">{info.agencyCode}</div>
      ),
    },
    {
      name: 'اسم الجهة',
      sortable: true,
      center: true,
      cell: (info) => <div className="text-center">{info.agencyName}</div>,
    },
    {
      name: 'رقم المعيار',
      center: true,
      sortable: true,
      cell: (row) => <div className="text-center">{row.standardCode}</div>,
    },
    {
      name: 'المرفقات',
      sortable: true,
      center: true,
      cell: (row) => (
        <div className="flex justify-center">
          <NewTooltip content={'المرفقات'}>
            <FontAwesomeIcon
              icon={faEye}
              className="text-sky-400 bg-sky-100 mr-1 rounded-full p-2 cursor-pointer"
              onClick={() => {
                setOpenAttachmentModal(true);
                setStandardProofAndItsAttachment(
                  row.standardProofAndItsAttachment
                );
              }}
            />
          </NewTooltip>
        </div>
      ),
    },
    {
      name: 'إجابة الجهة',
      sortable: true,
      center: true,
      cell: (info) => (
        <div className="text-center">
          {info?.donotApply ? (
            'لا ينطبق'
          ) : info.agencyAnswer == null ? (
            'لا يوجد'
          ) : (
            <AppBadge badgeColor={info.agencyAnswerColor}>
              {info.agencyAnswer}
            </AppBadge>
          )}
        </div>
      ),
    },
  ];

  const handleExcelExport = async (withExtraFilters) => {
    if (withExtraFilters) {
      setExcelExtraLoading(true);
    } else {
      setExcelLoading(true);
    }

    const requestId = generateRandomString(10);
    const payload = {
      agencyName: filterInputs.agencyName,
      StandardCode: filterInputs.standardCode,
      ReviewerName: filterInputs.auditorName,
      ExpertName: filterInputs.expertName,
      SkipCount: 0,
      MaxResultCount: 100000,
      connectionId: localStorage.getItem('connectionId'),
      requestId: requestId,
    };

    if (withExtraFilters) {
      payload.startDate = exportFromDate;
      payload.endDate = exportToDate;
      payload.stageId = selectedStageId ?? 0;
      payload.exportAnswerColumns = selectedColumns.map(
        (column) => column.value
      );
    }

    let promise;
    if (withExtraFilters) {
      promise = axiosPostRequest(
        environment.exportLatestExpertAnswers,
        payload
      );
    } else {
      promise = axiosGetRequest(environment.exportAnswerReports, payload);
    }

    setExportModal(false);
    try {
      const delayedPromise = new Promise((resolve, reject) => {
        promise.then(() => {
          document.addEventListener('reportReady', (data) => {
            if (data.detail.isSuccess && data.detail.requestId === requestId) {
              resolve(data.detail.result);
            } else if (
              !data.detail.isSuccess &&
              data.detail.requestId === requestId
            ) {
              setExcelLoading(false);
              setExcelExtraLoading(false);
              reject(data.detail);
            }
          });
        });
      });

      toast.promise(delayedPromise, {
        pending: '...جاري تحميل الملف قد يستغرق بعض الوقت',
        success: 'تم تحميل الملف بنجاح',
        error: 'حدث خطأ أثناء تحميل البيانات',
      });

      const res = await delayedPromise;
      setExcelLoading(false);
      setExcelExtraLoading(false);
      downloadFile(res, 'سجل الاٍجابات.xlsx');
    } catch (error) {
      setExcelLoading(false);
      setExcelExtraLoading(false);
    }

    return;
  };

  function handleExcelExportOpenExportModal() {
    setExportModal(true);
  }

  function handleSelectColumn(e) {
    const column = exportColumns.find((col) => col.name === e.target.name);
    if (e.target.checked) {
      setSelectedColumns([...selectedColumns, column]);
    } else {
      setSelectedColumns(
        selectedColumns.filter((col) => col.name !== e.target.name)
      );
    }
  }

  const breadcrumbArr = [
    { title: 'التقارير', icon: programsIcon },
    { title: 'سجل الاٍجابات' },
  ];

  return (
    <HeaderWithBreadcrumbLayout breadcrumbArr={breadcrumbArr}>
      <div className="py-5 space-y-3">
        <AnswersReportFilter setFilterInputs={setFilterInputs} />
        <NewTable
          withMaxResultChange
          maxResultValue={maxResult}
          setMaxResultValue={setMaxResult}
          withExcel
          excelLoading={excelLoading}
          onExportExcel={handleExcelExport}
          loading={isGettingAnswersReport}
          columns={columns}
          expandableRows
          expandableRowsComponent={ExpandableRowsComponent}
          data={answersReport}
          pagination
          totalPages={totalPages}
          onPageChange={handlePageClick}
          currentPage={currentPage}
          withCustomExcelExport={true}
          customExcelLoading={excelExtraLoading}
          customExportExcelText="استخراج ملف اكسل معدل"
          onCustomExportExcel={handleExcelExportOpenExportModal}
        />

        <AppModal
          open={openAttachmentModal}
          setOpen={setOpenAttachmentModal}
          headerTitle={'المرفقات'}
          isFooter={false}
        >
          <div className="text-xl h-full pb-2 flex flex-col gap-2">
            {standardProofAndItsAttachment.map((item, index) => (
              <React.Fragment key={item.standardProofTitle}>
                <li className="mb-2 flex justify-between gap-3">
                  <p>
                    {index + 1} - {item?.standardProofTitle}
                  </p>

                  <div className="text-nowrap">
                    عدد المرفقات : {item?.attachmentCount}
                  </div>
                </li>

                {index < standardProofAndItsAttachment.length - 1 && (
                  <AppDivider />
                )}
              </React.Fragment>
            ))}
          </div>
        </AppModal>

        <AppModal
          open={exportModal}
          setOpen={setExportModal}
          headerTitle={'اختيار الاعمدة المراد تصديرها'}
          handleSubmit={() => handleExcelExport(true)}
          submitLabel="إستخراج"
        >
          <div className="flex gap-4 p-4">
            <div className="">
              <label className="text-[16px] text-[#292069] mb-3">
                من تاريخ
              </label>
              <input
                style={{ border: '2px solid #f4f7fe' }}
                onChange={(e) => setExportFromDate(e.target.value)}
                value={exportFromDate}
                type="date"
                className="text-[14px] text-[#7D8BAB] bg-[#F9F9FF] border-none rounded-3xl py-3 px-5"
                placeholder="أكتب هنا"
              />
            </div>
            <div className="">
              <label className="text-[16px] text-[#292069] mb-3">
                الى تاريخ
              </label>
              <input
                style={{ border: '2px solid #f4f7fe' }}
                onChange={(e) => {
                  setExportToDate(e.target.value);
                }}
                value={exportToDate}
                type="date"
                className="text-[14px] text-[#7D8BAB] bg-[#F9F9FF] border-none rounded-3xl py-3 px-5"
                placeholder="أكتب هنا"
              />
            </div>
          </div>
          {stagesData && stagesData.result.items.length > 0 && (
            <div className="flex gap-4 p-4">
              <label className="text-[16px] text-[#292069] mb-3">
                اختر المرحلة
              </label>
              <select
                id="stage"
                className="leading-[1.8] pb-2 bg-[#F9F9F9] rounded-lg border border-[#EAECF0] p-0 text-sm w-32 flex-grow"
                onChange={(e) => {
                  setSelectedStageId(e.target.value);
                }}
              >
                <option value="">الكل</option>
                {stagesData.result.items.map((stage) => (
                  <option key={stage.id} value={stage.id}>
                    {stage.title}
                  </option>
                ))}
              </select>
            </div>
          )}

          <div className="flex flex-col gap-2 justify-start">
            {exportColumns.map((column, index) => {
              return (
                <div key={index} className="flex items-center gap-2">
                  <input
                    name={column.name}
                    type="checkbox"
                    className="ml-2 mr-1 bg-[#DBE0EF] border-none rounded-md w-[24px]  h-[24px]"
                    checked={selectedColumns.find(
                      (col) => col.name === column.name
                    )}
                    disabled={column.disabled}
                    onChange={handleSelectColumn}
                  />
                  <label>{column.name}</label>
                </div>
              );
            })}
          </div>
        </AppModal>
      </div>
    </HeaderWithBreadcrumbLayout>
  );
}

export default AnswersReportPage;
