import React, { useState } from 'react';
import UploadFileIcon from './ui/uploadFileIcon';
import UploadItemProgress from './ui/uploadItemProgress';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleExclamation,
  faRotateLeft,
  faTrashCan,
} from '@fortawesome/free-solid-svg-icons';
import { useAbortItem } from '@rpldy/uploady';
import { useRetry } from '@rpldy/retry-hooks';
import FilePreview from './filePreview';
import { useUploadContext } from './uploadFilesContext';

const UploadFileItem = ({
  status,
  fileName,
  size,
  type,
  progress,
  id,
  attachmentId,
  document,
  proofId,
}) => {
  const { files, setFiles, setAttachmentFilesCount } = useUploadContext();
  const abortItem = useAbortItem();
  const retry = useRetry();
  const [canceled, setCanceled] = useState(false);

  const handleDeleteFile = () => {
    const updatedFiles = files?.filter((item) => item?.id != id);
    setFiles(updatedFiles);
    setAttachmentFilesCount((attachmentFilesCount) => ({
      ...attachmentFilesCount,
      [proofId]: {
        ...attachmentFilesCount[proofId],
        uploaded: attachmentFilesCount[proofId].uploaded - 1,
        itemsCount: attachmentFilesCount[proofId].itemsCount - 1,
      },
    }));
  };

  return (
    <div className={status === 'error' ? 'text-red-600' : ''}>
      <div className={'mb-2 flex justify-between items-center'}>
        <div className="flex items-center gap-x-3 ">
          <UploadFileIcon fileType={type} />
          <div className="mb-1">
            <p
              className={
                status === 'error'
                  ? 'text-sm text-red-600 font-semibold'
                  : 'text-sm font-medium text-gray-800 dark:text-white'
              }
            >
              {fileName}
            </p>
            {status === 'error' ? (
              <p className="text-xs text-red-600 mt-1">
                لم يتم ارفاق المستند، يرجى التأكد من صيغة الملف
              </p>
            ) : (
              <p className="text-xs text-gray-500 dark:text-gray-500">
                {(size / (1024 * 1024)).toFixed(2)}MB
              </p>
            )}
          </div>
        </div>
        <div className="flex gap-2 items-center">
          {status == 'error' && (
            <>
              <FontAwesomeIcon
                icon={faCircleExclamation}
                className="text-red-600"
                onClick={() => {
                  abortItem(id);
                  setCanceled(true);
                }}
              />
            </>
          )}
          {status === 'completed' ? (
            <div className="inline-flex items-center gap-x-2">
              <FilePreview id={attachmentId} fileName={fileName} />
              <svg
                className="flex-shrink-0 w-5 h-5 text-emerald-400"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
              </svg>
            </div>
          ) : (
            status !== 'error' && <p className="mb-2">{progress}%</p>
          )}
          {(status == 'completed' || status == 'error') && !canceled && (
            <FontAwesomeIcon
              icon={faTrashCan}
              className="hover:text-red-600 cursor-pointer mr-2"
              onClick={handleDeleteFile}
            />
          )}
        </div>
      </div>

      {status != 'error' && (
        <UploadItemProgress progress={progress} status={status} />
      )}
    </div>
  );
};

export default UploadFileItem;
