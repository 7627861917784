import { useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import HeaderWithBreadcrumbLayout from '../../../../layouts/headerWithBreadcrumbLayout';
import { FModal } from '../../../../components';
import NewTable from '../../../../components/NewTable.jsx';
import { programsIcon } from '../../../../assets/icons/Index';
import {
  axiosGetRequest,
  axiosDeleteRequest,
} from '../../../../services/Request';
import environment from '../../../../services/environment';
import { IconBasedOnHasAttachmentStatus } from './IconBasedOnHasAttachmentStatus';
import { AddEditQuestionForm } from './AddEditQuestionForm';
import DeleteIcon from '../../../../components/DeleteIcon.jsx';
import { CopyQuestion } from './CopyQuestion.jsx';
import NewTooltip from '../../../../components/NewTooltip.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faPen } from '@fortawesome/free-solid-svg-icons';
import useExcelExport from '../../../../helpers/customHooks/useExcelExport.jsx';

export const questionTypesMapper = {
  TrueOrFalse: 'صح او خطا',
  RateNumber: 'تقييم',
  MultiChoices: 'اختيارات متعددة',
  Text: 'نص',
};

export default function SurveyQuestions() {
  const downloadExcel = useExcelExport();
  const history = useHistory();

  const formRef = useRef(null);
  const copyFormRef = useRef(null);
  const { satisfictionSurveyId } = useParams();
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [showCopyModal, setShowCopyModal] = useState(false);
  const [addEditLoading, setAddEditLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [questionForEdit, setQuestionForEdit] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [maxResult, setMaxResult] = useState({ label: '5', value: 5 });

  const { data: surveyDetails, isLoading: isGettingSurveyDetails } = useQuery({
    queryKey: ['GetRateSurveyById', satisfictionSurveyId],
    queryFn: () =>
      axiosGetRequest(environment.GetRateSurveyById, {
        rateSurveyId: satisfictionSurveyId,
      }),
    select: (res) => res?.result,
  });

  const {
    data: surveyQuestions = { items: [] },
    isLoading: isGettingSurveyQuestions,
    refetch: refetchSurveyQuestions,
  } = useQuery({
    queryKey: [
      'GetAllRateSurveyQuestionByRateSurveyId',
      satisfictionSurveyId,
      currentPage,
      maxResult.value,
    ],
    queryFn: () =>
      axiosGetRequest(environment.GetAllRateSurveyQuestionByRateSurveyId, {
        rateSurveyId: satisfictionSurveyId,
        skipCount: currentPage * maxResult.value,
        maxResultCount: maxResult.value,
      }),
    select: (res) => res?.result,
  });

  const {
    data: surveyQuestionsForExcel = { items: [] },
    isLoading: isGettingSurveyQuestionsForExcel,
  } = useQuery({
    queryKey: [
      'GetAllRateSurveyQuestionByRateSurveyId',
      satisfictionSurveyId,
      currentPage,
      10000,
    ],
    queryFn: () =>
      axiosGetRequest(environment.GetAllRateSurveyQuestionByRateSurveyId, {
        rateSurveyId: satisfictionSurveyId,
        skipCount: 0,
        maxResultCount: 10000,
      }),
    select: (res) => res?.result,
  });

  const surveyQuestionsBreadcumbs = [
    {
      icon: programsIcon,
      title: 'الاستبيانات',
      url: '/satisfiction-survey',
    },
    {
      icon: null,
      title: surveyDetails?.title,
      url: `/satisfiction-survey/${satisfictionSurveyId}/questions`,
    },
  ];

  const deleteSurveyQuestion = async (id) => {
    setDeleteLoading(true);
    const res = await axiosDeleteRequest(environment.DeleteRateSurveyQuestion, {
      id,
    });
    if (res?.success) {
      toast.success('تم الحذف بنجاح');
      refetchSurveyQuestions();
    }
    setDeleteLoading(false);
  };

  const columns = [
    {
      name: 'السؤال',
      center: true,
      accessorKey: 'title',
      selector: (row) => row.title,
    },
    {
      name: 'الوصف',
      center: true,
      accessorKey: 'description',
      selector: (row) => row.description,
    },
    {
      name: 'النوع',
      center: true,
      accessorKey: 'questionType',
      selector: (row) => questionTypesMapper[row.questionType.title],
    },
    {
      name: 'اجباري',
      center: true,
      accessorKey: 'isRequired',
      selector: (row) => (row?.isRequired ? 'اجباري' : 'اختياري'),
    },
    {
      name: 'المرفقات',
      center: true,
      cell: (row) => (
        <div className="text-center rounded-full">
          <IconBasedOnHasAttachmentStatus hasAttachment={row.hasAttachment} />
        </div>
      ),
    },

    {
      name: 'الاجراءات',
      selector: (row) => row.actions,
      header: 'actions',
      accessorKey: 'actions',
      footer: (props) => props.column.actions,
      cell: (row) => (
        <div className="flex gap-2">
          {/* <EditIcon
            editFunction={handleSubmit}
            editedName={"السؤال"}
            editModalContent={
              <AddEditQuestionForm
                formRef={formRef}
                refetchSurveyQuestions={refetchSurveyQuestions}
                showModal={(val) => setShowAddEditModal(val)}
                satisfictionSurveyId={satisfictionSurveyId}
                question={row}
                isAdd={false}
              />
            }
          /> */}
          <NewTooltip content={'تعديل'}>
            <FontAwesomeIcon
              icon={faPen}
              className="text-yellow-400 bg-orange-100 mr-1 rounded-full p-2 cursor-pointer"
              onClick={() => {
                setShowAddEditModal(true);
                setQuestionForEdit(row);
              }}
            />
          </NewTooltip>
          <DeleteIcon
            id={row.id}
            loading={deleteLoading}
            deleteFunction={() => deleteSurveyQuestion(row.id)}
            deletedName={'السؤال'}
          />
        </div>
      ),
    },
  ];
  //  !I did this because I want to remove the actions column if i make the condition inside the column it make a empty cell so it not the best way
  const columnsWithoutActions = [
    {
      name: 'السؤال',
      center: true,
      accessorKey: 'title',
      selector: (row) => row.title,
    },
    {
      name: 'الوصف',
      center: true,
      accessorKey: 'description',
      selector: (row) => row.description,
    },
    {
      name: 'النوع',
      center: true,
      accessorKey: 'questionType',
      selector: (row) => questionTypesMapper[row.questionType.title],
    },
    {
      name: 'اجباري',
      center: true,
      accessorKey: 'isRequired',
      selector: (row) => (row?.isRequired ? 'اجباري' : 'اختياري'),
    },
    {
      name: 'المرفقات',
      center: true,
      cell: (row) => (
        <div className="text-center rounded-full">
          <IconBasedOnHasAttachmentStatus hasAttachment={row.hasAttachment} />
        </div>
      ),
    },
  ];

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current?.handleSubmit();
    }
  };

  const handleSubmitCopy = () => {
    if (copyFormRef.current) {
      copyFormRef.current?.handleSubmit();
    }
  };

  const handlePageClick = (event) => {
    const newOffset = event.selected;
    setCurrentPage(newOffset);
  };

  const handleExcelExport = async () => {
    const dataForExcel = surveyQuestionsForExcel?.items?.map((ele) => {
      return {
        السؤال: ele.title,
        الوصف: ele.description,
        النوع: questionTypesMapper[ele.questionType.title],
        المرفقات: ele.hasAttachment ? 'له مرفقات' : 'ليس له مرفقات',
      };
    });
    downloadExcel(`اسئلة استبيان ${surveyDetails?.title}`, dataForExcel);
  };

  return (
    <HeaderWithBreadcrumbLayout
      breadcrumbArr={surveyQuestionsBreadcumbs}
      // here
      btnLabel={
        surveyDetails?.isPublished || isGettingSurveyDetails
          ? null
          : 'إضافة سؤال'
      }
      btnLabelSeconed={
        surveyDetails?.isPublished || isGettingSurveyDetails ? null : 'نسخ سؤال'
      }
      handleBtnClick={() => setShowAddEditModal(true)}
      handleBtnSeconedClick={() => setShowCopyModal(true)}
      customButton={
        surveyQuestions?.items?.length > 0 ? (
          <NewTooltip content={'معاينة الاستبيان'}>
            <FontAwesomeIcon
              icon={faEye}
              className="text-sky-400 bg-sky-100 mr-1 rounded-full p-2 cursor-pointer"
              onClick={() => {
                window.open(
                  `/satisfiction-survey/${satisfictionSurveyId}?userId=null&isPreview=true`,
                  '_blank'
                );
              }}
            />
          </NewTooltip>
        ) : null
      }
    >
      <NewTable
        withMaxResultChange
        maxResultValue={maxResult}
        setMaxResultValue={(value) => {
          setMaxResult(value);
          setCurrentPage(0);
        }}
        columns={
          surveyDetails?.isPublished || isGettingSurveyDetails
            ? columnsWithoutActions
            : columns
        }
        data={surveyQuestions?.items}
        pagination={true}
        totalPages={Math.ceil(surveyQuestions?.totalCount / maxResult.value)}
        onPageChange={handlePageClick}
        currentPage={currentPage}
        loading={isGettingSurveyQuestions}
        withExcel
        onExportExcel={handleExcelExport}
      />
      <FModal
        open={showAddEditModal}
        onClose={() => {
          setShowAddEditModal(false);
          setQuestionForEdit(null);
        }}
        action={handleSubmit}
        type="submit"
        headerTitle={questionForEdit ? 'تعديل سؤال' : 'اضافة سؤال'}
        submitLabel={questionForEdit ? 'تعديل' : 'اضافة'}
        loading={addEditLoading}
        content={
          <AddEditQuestionForm
            formRef={formRef}
            refetchSurveyQuestions={refetchSurveyQuestions}
            showModal={(val) => setShowAddEditModal(val)}
            setAddEditLoading={setAddEditLoading}
            setQuestionForEdit={setQuestionForEdit}
            satisfictionSurveyId={satisfictionSurveyId}
            question={questionForEdit}
            isAdd={!questionForEdit}
          />
        }
      />
      <FModal
        open={showCopyModal}
        setOpen={() => setShowCopyModal(false)}
        action={handleSubmitCopy}
        type="submit"
        headerTitle="نسخ سؤال"
        submitLabel="نسخ"
        handleBtnClick={() => setShowCopyModal(true)}
        content={
          <CopyQuestion
            copyFormRef={copyFormRef}
            refetchSurveyQuestions={refetchSurveyQuestions}
            showModal={(val) => setShowCopyModal(val)}
            satisfictionSurveyId={satisfictionSurveyId}
          />
        }
      />
    </HeaderWithBreadcrumbLayout>
  );
}
