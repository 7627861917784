import React, { useEffect, useState } from 'react';
import StandardAnswerRequirement from '../../standardAnswerRequirements';
import MainRequirement from '../../standardAnswerRequirements/ui/mainRequirement';
import SubRequirement from '../../standardAnswerRequirements/ui/subRequirement';
import { useAnswerContext } from '../../answerContext';
import { useParams } from 'react-router-dom';
import AgencyRequirementAnswer from '../Agency/agencyRequirementAnswer';
import ReadOnlyRequirements from '../../components/readOnlyRequirements';

const ReviewerRequirementAnswer = ({ setMenuElement }) => {
  const { standardId, entityId } = useParams();
  const { getSelfEvaluation, newReviewerAnswer, setNewReviewerAnswer } =
    useAnswerContext();

  const [selectedRequirementsTab, setSelectedRequirementsTab] =
    useState('reviewer');

  const handleRequirementChange = (requirement, property = 'decision') => {
    const updatedRequirements = newReviewerAnswer?.standardRequirements?.map(
      (item) => {
        if (item?.id === requirement?.id) {
          const subStandardRequirements =
            requirement?.subStandardRequirements?.map((subReq) => {
              return property === 'decision'
                ? {
                    ...subReq,
                    decision: requirement?.decision ? 0 : 1,
                    donotApply: 0,
                  }
                : {
                    ...subReq,
                    donotApply: !requirement?.donotApply,
                    decision: !requirement?.donotApply
                      ? 0
                      : requirement?.decision,
                  };
            });

          return property === 'decision'
            ? {
                ...requirement,
                subStandardRequirements,
                decision: requirement?.decision ? 0 : 1,
                donotApply: 0,
              }
            : {
                ...requirement,
                subStandardRequirements,
                donotApply: !requirement?.donotApply,
                decision: !requirement?.donotApply ? 0 : requirement?.decision,
              };
        }

        return item;
      }
    );

    getSelfEvaluation(standardId, updatedRequirements, entityId, 'reviewer');

    setNewReviewerAnswer({
      ...newReviewerAnswer,
      standardRequirements: updatedRequirements,
    });
  };

  const handleSubRequirementChange = (
    requirement,
    subReqId,
    property = 'decision'
  ) => {
    const updatedSubRequirements = newReviewerAnswer?.standardRequirements?.map(
      (item) => {
        if (item?.id === requirement?.id) {
          const newSubReqs = requirement?.subStandardRequirements?.map(
            (subRequire) => {
              if (subReqId === subRequire?.id) {
                return property === 'decision'
                  ? {
                      ...subRequire,
                      decision: subRequire?.decision ? 0 : 1,
                      donotApply: 0,
                    }
                  : {
                      ...subRequire,
                      donotApply: !subRequire?.donotApply,
                      decision: !subRequire?.donotApply
                        ? 0
                        : subRequire?.decision,
                    };
              }

              return subRequire;
            }
          );

          const isAllSubReqChecked = newSubReqs?.every(
            (value) => value?.decision == 1
          );
          const isAllSubReqDonotApply = newSubReqs?.every(
            (value) => value?.donotApply === true
          );

          if (isAllSubReqChecked) requirement.decision = 1;
          else requirement.decision = 0;
          if (isAllSubReqDonotApply) requirement.donotApply = true;
          else requirement.donotApply = false;

          return { ...requirement, subStandardRequirements: newSubReqs };
        }

        return item;
      }
    );
    getSelfEvaluation(standardId, updatedSubRequirements, entityId, 'reviewer');

    setNewReviewerAnswer({
      ...newReviewerAnswer,
      standardRequirements: updatedSubRequirements,
    });
  };

  const handleSelectAllReqs = (action = 'selectAll') => {
    const updatedRequirements = newReviewerAnswer?.standardRequirements?.map(
      (req) => {
        const subStandardRequirements = req?.subStandardRequirements?.map(
          (subReq) => ({
            ...subReq,
            donotApply: 0,
            decision: action === 'selectAll' ? 1 : 0,
          })
        );

        return {
          ...req,
          subStandardRequirements,
          donotApply: 0,
          decision: action === 'selectAll' ? 1 : 0,
        };
      }
    );

    getSelfEvaluation(standardId, updatedRequirements, entityId, 'reviewer');

    setNewReviewerAnswer({
      ...newReviewerAnswer,
      standardRequirements: updatedRequirements,
    });
  };

  const renderedReviewerRequirements =
    newReviewerAnswer?.standardRequirements?.length > 0 &&
    newReviewerAnswer?.standardRequirements?.map((requirement) => {
      const hasSubRequirements =
        requirement?.subStandardRequirements?.length > 0;

      return (
        <>
          <MainRequirement
            key={requirement?.id}
            title={requirement?.title}
            hasSubRequirements={hasSubRequirements}
            checked={requirement?.decision}
            requirementDonotApplyChecked={requirement?.donotApply}
            onChange={() => {
              handleRequirementChange(requirement);
            }}
            handleDonotApplyRequirementChange={() =>
              handleRequirementChange(requirement, 'donotApply')
            }
          >
            {hasSubRequirements &&
              requirement?.subStandardRequirements?.map((subRequirement) => {
                return (
                  <>
                    <SubRequirement
                      key={subRequirement?.id}
                      title={subRequirement?.title}
                      checked={subRequirement?.decision}
                      onChange={() =>
                        handleSubRequirementChange(
                          requirement,
                          subRequirement?.id
                        )
                      }
                      subRequirementDonotApplyChecked={
                        subRequirement?.donotApply
                      }
                      handleDonotApplySubRequirementChange={() =>
                        handleSubRequirementChange(
                          requirement,
                          subRequirement?.id,
                          'donotApply'
                        )
                      }
                    />
                  </>
                );
              })}
          </MainRequirement>
        </>
      );
    });

  return (
    <div>
      <StandardAnswerRequirement setMenuElement={setMenuElement}>
        <div className="flex  py-5 w-full">
          <p
            className={
              selectedRequirementsTab == 'agency'
                ? 'bg-indigo-200 px-3 pb-2 rounded ml-4 cursor-pointer'
                : 'bg-neutral-100 px-3 pb-2 rounded ml-4 cursor-pointer'
            }
            onClick={() => setSelectedRequirementsTab('agency')}
          >
            الجهة
          </p>
          <p
            className={
              selectedRequirementsTab == 'reviewer'
                ? 'bg-indigo-800 text-white px-3 pb-2 rounded ml-4 cursor-pointer'
                : 'bg-emerald-50 px-3 pb-2 rounded ml-4 cursor-pointer'
            }
            onClick={() => setSelectedRequirementsTab('reviewer')}
          >
            المدقق
          </p>

          <p
            className={
              selectedRequirementsTab == 'expert'
                ? 'bg-indigo-200 px-3 pb-2 rounded ml-4 cursor-pointer'
                : 'bg-neutral-100 px-3 pb-2 rounded ml-4 cursor-pointer'
            }
            onClick={() => setSelectedRequirementsTab('expert')}
          >
            الخبير
          </p>
          <p
            className={
              selectedRequirementsTab == 'pocOfficer'
                ? 'bg-indigo-200 px-3 pb-2 rounded ml-4 cursor-pointer'
                : 'bg-neutral-100 px-3 pb-2 rounded ml-4 cursor-pointer'
            }
            onClick={() => setSelectedRequirementsTab('pocOfficer')}
          >
            ضابط الاتصال
          </p>
        </div>

        {selectedRequirementsTab === 'agency' && <AgencyRequirementAnswer />}
        {selectedRequirementsTab === 'reviewer' && (
          <>
            <div className="flex justify-end w-full gap-4">
              <p
                onClick={() => handleSelectAllReqs()}
                className="mb-6 cursor-pointer text-end text-emerald-500"
              >
                تحديد الكل
              </p>
              <p
                onClick={() => handleSelectAllReqs('cancel')}
                className="mb-6 cursor-pointer text-end text-red-800"
              >
                الغاء تحديد الكل
              </p>
            </div>
            {renderedReviewerRequirements}
          </>
        )}
        {selectedRequirementsTab === 'expert' && (
          <ReadOnlyRequirements
            requirements={newReviewerAnswer?.expertRequirementsAnswer}
          />
        )}
        {selectedRequirementsTab === 'pocOfficer' && (
          <ReadOnlyRequirements
            requirements={newReviewerAnswer?.pocOfficerRequirementsAnswer}
          />
        )}
        {/* {selectedRequirementsTab === "expertManager" &&
          renderedReviewerRequirements} */}
      </StandardAnswerRequirement>
    </div>
  );
};

export default ReviewerRequirementAnswer;
