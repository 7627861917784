import AuditorsCard from './cards/AuditorsCard';
import ExpertsCard from './cards/ExpertsCard';
import Phase2Standards from './cards/Phase2Standards';
import PocOfficerCard from './cards/PocOfficerCard';

const cards = [
  { id: 1, fullWidth: true, component: <AuditorsCard /> },
  { id: 2, fullWidth: true, component: <ExpertsCard /> },
  { id: 3, fullWidth: true, component: <PocOfficerCard /> },
  { id: 4, fullWidth: true, component: <Phase2Standards /> },
];

function AdminDashboardCardsPhase2() {
  return (
    <div className="p-5 grid grid-cols-1 lg:grid-cols-2 gap-5">
      {cards.map((card) => (
        <div
          key={card.id}
          className={card.fullWidth ? 'col-span-2' : 'col-span-2 lg:col-span-1'}
        >
          {card.component}
        </div>
      ))}
    </div>
  );
}

export default AdminDashboardCardsPhase2;
