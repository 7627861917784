import { useHistory, useParams } from 'react-router';
import { Title } from '../../../../../components';
import SecondaryTapsLayout from '../../../../../layouts/secondaryTapsLayout';
import { surveySettingTabs } from '../data';
import { t } from 'i18next';
import { programsIcon } from '../../../../../assets/icons/Index';
import AppRadio from '../../../../../components/form/appRadio';
import AppButton from '../../../../../components/buttons/appButton';
import { useState } from 'react';
import { axiosPutRequest } from '../../../../../services/Request';
import environment from '../../../../../services/environment';
import { toast } from 'react-toastify';

const SurveyStrategy = () => {
  const { surveyId, indicatorId } = useParams();
  const [value, setValue] = useState(1);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const handleSubmit = async () => {
    setLoading(true);
    const res = await axiosPutRequest(
      environment.UpdateSurveyCalculatingAgencyFinalDegreeStrategy,
      {
        surveyId: surveyId,
        calculatingAgencyFinalDegreeStrategy: value,
      }
    );
    setLoading(false);

    if (res.success) {
      toast.success('تم تحديث آلية احتساب الدرجة');
    }
  };

  return (
    <SecondaryTapsLayout
      linksArr={surveySettingTabs(
        `/indicators/${indicatorId}/surveys/${surveyId}`
      )}
      breadcrumbs={
        <div className="-mx-5">
          <Title
            iconTitle={programsIcon}
            subTitle={'اعدادات الاستبيان'}
            thired={true}
            subTitleSeconed={'آلية الإحتساب للإستبيان'}
            title={t('المؤشرات')}
            toPage={() => history.push('/indicators')}
            toPageSeconed={() => history.push(`/indicators/${indicatorId}`)}
            withoutButton={true}
          />
        </div>
      }
    >
      <h2 className="mb-8 text-lg text-blue-text ">آلية احتساب الدرجة</h2>
      <AppRadio
        label="التقييم المحتسب"
        name={'name'}
        id={1}
        value="1"
        onChange={(e) => {
          setValue(e.target.value);
        }}
      />
      <AppRadio
        label="درجة مستوى الإلتزام"
        name={'name'}
        id={2}
        value="2"
        onChange={(e) => {
          setValue(e.target.value);
        }}
      />
      <AppRadio
        label="الإثنان معا، مع اعتماد الأدق عند التعارض (النسبة الثابتة)"
        name={'name'}
        id={3}
        value="3"
        onChange={(e) => {
          setValue(e.target.value);
        }}
      />

      <AppButton onClick={handleSubmit} loading={loading} className="mt-4">
        حفظ
      </AppButton>
    </SecondaryTapsLayout>
  );
};

export default SurveyStrategy;
