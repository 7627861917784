import { faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import CModal from './CModal';
import NewTooltip from './NewTooltip';

const ApproveIcon = ({ loading, onClick, subTitle = '' }) => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <NewTooltip content={'قبول'}>
        <FontAwesomeIcon
          icon={faThumbsUp}
          onClick={() => {
            setOpenModal(true);
          }}
          className="text-emerald-500 bg-emerald-100 mr-1 rounded-full p-2 cursor-pointer"
        />
      </NewTooltip>

      <CModal
        loading={loading}
        status={true}
        confirmModal={onClick}
        open={openModal}
        setOpen={() => setOpenModal(false)}
        subTitle={subTitle}
        addMinWidthForTitle
      />
    </>
  );
};

export default ApproveIcon;
