import React from 'react';

import strategicPlanning from './../../../../../../assets/icons/strategicPlanning.svg';

const StandardProofFilesHeader = ({ label = 'مستندات الإثبات' }) => {
  return (
    <div className="w-[100%] flex flex-row justify-between items-center">
      <div className="flex flex-row items-center">
        <img
          className="bg-transparent ml-2"
          src={strategicPlanning}
          alt="strategic planning"
        />
        <h1 className="text-[#292069] font-black text-[20px]">{label}</h1>
      </div>
    </div>
  );
};

export default StandardProofFilesHeader;
