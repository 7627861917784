import { useEffect, useState } from 'react';
import { programsIcon } from '../../../../../assets/icons/Index';
import NewTable from '../../../../../components/NewTable';
import HeaderWithBreadcrumbLayout from '../../../../../layouts/headerWithBreadcrumbLayout';
import { axiosGetRequest } from '../../../../../services/Request';
import environment from '../../../../../services/environment';
import { useParams } from 'react-router-dom';
import useExcelExport from '../../../../../helpers/customHooks/useExcelExport';
import DownloadButton from '../../../../../components/DownloadButton';
import { useLocation } from 'react-router';
import useFileDownloader from '../../../../../hooks/useFileDownloader';

function PreviousAnswers() {
  const { agencyId } = useParams();
  const { state } = useLocation();

  const [oldAnswers, setOldAnswers] = useState([]);
  const [count, setCount] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [filteredData, setFilteredData] = useState(oldAnswers || []);

  const getOldAnswers = async (page) => {
    setLoading(true);
    const res = await axiosGetRequest(environment.getAgencyOldFiles, {
      AgencyId: agencyId,
      SkipCount: page * 30,
      MaxResultCount: 30,
    });

    if (res?.success) {
      setOldAnswers(res?.result?.items);
      setCount(res?.result?.totalCount);
    }
    setLoading(false);
  };

  useEffect(() => {
    getOldAnswers(0);
  }, []);

  useEffect(() => {
    setFilteredData(oldAnswers);
  }, [oldAnswers]);

  const handlePageClick = (event) => {
    const newOffset = event.selected;
    setCurrentPage(newOffset);
    getOldAnswers(newOffset);
  };

  const { downloadPdf } = useFileDownloader();

  const handleDownload = async (filePath, fileName) => {
    const api = `${process.env.REACT_APP_URL}/Reports/DawnloadAgencyOldFileByName`;
    const params = { fileName: filePath };

    await downloadPdf(api, params, fileName, null);
  };

  const columns = [
    {
      name: 'اسم الملف',
      selector: (row) => row.name,
      header: 'اسم الملف',
      accessorKey: 'name',
      sortable: true,
    },
    {
      name: 'رقم المعيار',
      selector: (row) => row.standardCode,
      header: 'رقم المعيار',
      accessorKey: 'standardCode',
      sortable: true,
      center: true,
    },
    {
      name: 'تحميل الملف',
      selector: (row) => row.name,
      header: 'اسم الملف',
      accessorKey: 'name',
      sortable: true,
      center: true,
      cell: (row) => (
        <DownloadButton onClick={() => handleDownload(row.filePath, row.name)}>
          تحميل الملف
        </DownloadButton>
      ),
    },
  ];

  // search
  const handleFilterData = (value) => {
    if (!value) {
      setFilteredData(oldAnswers);
    } else {
      const filtered = oldAnswers?.filter((ele) => {
        const { name, standardCode } = ele;
        return name.includes(value) || standardCode.includes(value);
      });
      setFilteredData(filtered);
    }
  };

  // excel
  const downloadExcel = useExcelExport();

  const dataForExcel = filteredData?.map((ele) => {
    return {
      'اسم الملف': ele.name,
      'رقم المعيار': ele.standardCode,
    };
  });

  const breadcumb = [
    {
      icon: programsIcon,
      title: 'التقرير النهائي',
      url: '/previousSurveys',
    },
    {
      title: `اٍجابة جهة ${state?.agencyName}`,
    },
  ];

  return (
    <HeaderWithBreadcrumbLayout breadcrumbArr={breadcumb}>
      <div className="p-5 ">
        <NewTable
          withSearch
          withExcel
          onExportExcel={() =>
            downloadExcel(`الملفات السابقة_${agencyId}`, dataForExcel)
          }
          onSearch={handleFilterData}
          loading={loading}
          columns={columns}
          data={filteredData}
          pagination={true}
          totalPages={Math.ceil(count / 30)}
          onPageChange={handlePageClick}
          currentPage={currentPage}
        />
      </div>
    </HeaderWithBreadcrumbLayout>
  );
}

export default PreviousAnswers;
