import React, { useContext, useState } from 'react';
import HeaderWithBreadcrumbLayout from '../../../../../layouts/headerWithBreadcrumbLayout';
import AddAboutStandard from '../addStandard/addAboutStandard';
import StandardUploadOldFiles from '../addStandard/uploadOldFiles';
import StandardProofFiles from '../addStandard/standardProofFiles';
import StandardOrders from '../addStandard/standardOrders';
import { axiosGetRequest } from '../../../../../services/Request';
import environment from '../../../../../services/environment';
import { AddStandardContext } from '../addStandard/addStandardContext';
import StandardRequirements from '../addStandard/standardRequirements';
import SubmitEditStandard from './submitEditStandard';
import AppLoader from '../../../../../components/loader';
import { errorToast } from '../../../../../helpers/toast';
import { programsIcon } from '../../../../../assets/icons/Index';
import EditRequirementClassifications from './editRequirementClassifications';
import { useHistory, useParams } from 'react-router-dom';

const EditStandard = () => {
  const { surveyId, perspectiveId, interlocutorId, standardId, indicatorId } =
    useParams();
  const history = useHistory();
  const {
    setAddFinalValue,
    setEditRequirementClassifications,
    setSubRequirementCount,
    setSelectedStandards,
    setSelectedAgencies,
    setSelectedCommonAgencies,
    setMainAgency,
    setNonReTurnableStandard,
    setExpiryDate,
  } = useContext(AddStandardContext);
  const [loadData, setLoadData] = useState(false);

  const disableEditStandard = true;

  const getStandardForEdit = async () => {
    setLoadData(true);
    const res = await axiosGetRequest(environment.getStandardForEdit, {
      id: standardId,
    });
    if (!res) {
      setLoadData(false);
      errorToast('يرجى التواصل مع الدعم الفنى');

      history.goBack();
    }
    if (res?.success) {
      const data = res?.result;

      const mappedStandardRelations =
        data?.standardRelations?.map((item) => ({
          label: item.standardRelatedId?.toString() || '',
          value: item.standardRelatedId,
          id: item.standardRelatedId,
        })) || [];
      setSelectedStandards(mappedStandardRelations);

      const mappedSelectedAgencyStandard =
        data?.agencyStandards
          ?.filter((item) => !item?.parentAgencyId)
          ?.map((item) => ({
            label: item?.agencyTitle?.toString() || '',
            value: item?.agencyId,
            id: item?.agencyId,
            agencyId: item?.agencyId,
          })) || [];
      setSelectedAgencies(mappedSelectedAgencyStandard);

      const mappedCommonAgencies =
        data?.agencyStandards
          ?.filter(
            (item) =>
              item.isParentAgency || item?.parentAgencyId == item?.agencyId
          )
          ?.map((item) => ({
            label: item?.agencyTitle,
            value: item?.agencyId,
            id: item?.agencyId,
            agencyId: item?.agencyId,
          })) || [];
      setSelectedCommonAgencies(mappedCommonAgencies);

      const mainAgencyObj = data?.agencyStandards?.find(
        (item) => item?.parentAgencyId
      );
      const mappedMainAgencies = mainAgencyObj
        ? data?.agencyStandards
            ?.filter((item) => item.agencyId == mainAgencyObj.parentAgencyId)
            .map((item) => ({
              label: item?.agencyTitle,
              value: item?.agencyId,
              id: item?.agencyId,
              agencyId: item?.agencyId,
            }))?.[0]
        : '';

      setMainAgency(mappedMainAgencies);
      setNonReTurnableStandard(data?.nonReTurnableStandard);
      setExpiryDate(data?.expiryDate);

      const originalStandardRequirements =
        data?.standardRequirements?.map((req) => {
          const subReqLength = Number(req?.subStandardRequirements?.length);
          setSubRequirementCount((prev) => prev + subReqLength);

          const standardRequirementsObj = {
            id: req?.id,
            title: req?.title,
            standardRequirementClassificationId:
              req?.standardRequirementClassificationId,
            standardRequirementClassificationTitle:
              req?.standardRequirementClassification?.title,
            subStandardRequirements: req?.subStandardRequirements?.map(
              (subReq) => ({
                id: subReq?.id,
                title: subReq?.title,
                standardRequirementClassificationId:
                  subReq?.standardRequirementClassificationId,
                standardRequirementClassification:
                  subReq?.standardRequirementClassification?.title,
              })
            ),
          };

          return standardRequirementsObj;
        }) || [];

      setEditRequirementClassifications(originalStandardRequirements);

      const standardRequirements =
        data?.standardRequirements?.reverse()?.map((item) => {
          const subReqLength = Number(item?.subStandardRequirements?.length);
          setSubRequirementCount((prev) => prev + subReqLength);

          const standardRequirementsObj = {
            id: item.id,
            title: item?.title,
            recommendation: item?.recommendation,
            standardRequirementClassificationId:
              item?.standardRequirementClassificationId,
            standardRequirementClassificationTitle:
              item?.standardRequirementClassification?.title,
            agencyStandardRequirements: item?.agencyStandardRequirements,
            subStandardRequirements: item?.subStandardRequirements?.map(
              (item) => ({
                id: item?.id,
                title: item?.title,
                recommendation: item?.recommendation,
                standardRequirementClassificationId:
                  item?.standardRequirementClassificationId,
                standardRequirementClassification:
                  item?.standardRequirementClassification?.title,
              })
            ),
          };

          return standardRequirementsObj;
        }) || [];

      const standardProofs = data?.standardProofs?.reverse()?.map((item) => ({
        id: item.id,
        fileCount: item?.fileCount,
        fileSize: item?.fileSize,
        isRequired: true,
        standardProofFileSizeTypeId: item.standardProofFileSizeTypeId,
        standardProofTypeId: item.standardProofTypeId,
        title: item.title,
        agencyStandardProofs: item.agencyStandardProofs,
        standardProofRequirements: item.standardProofRequirements,
        standardProofFileTypeAssociations:
          item?.standardProofFileTypeAssociations?.map((val) => ({
            standardProofFileTypeId: val?.standardProofFileTypeId,
          })),
      }));

      setAddFinalValue({
        ...data,
        originalStandardRequirements,
        standardRequirements,
        standardProofs,
        agencyStandards: {
          agencies:
            mappedSelectedAgencyStandard?.map((item) => ({
              agencyId: item.id,
              parentAgencyId: null,
              isParentAgency: false,
            })) || [],
          commonAgencies:
            mappedCommonAgencies?.map((item) => {
              if (item.agencyId == mainAgencyObj?.parentAgencyId) {
                return {
                  agencyId: item.id,
                  parentAgencyId: mainAgencyObj?.parentAgencyId,
                  isParentAgency: false,
                };
              } else {
                return {
                  agencyId: item.id,
                  parentAgencyId: mainAgencyObj?.parentAgencyId,
                  isParentAgency: true,
                };
              }
            }) || [],
        },
        standardRelations:
          mappedStandardRelations?.map((item) => ({
            standardRelatedId: item.id,
          })) || [],
      });
      setLoadData(false);
    }
  };
  useState(() => {
    getStandardForEdit();
  }, []);

  const editStanderdsBreadcumbs = [
    {
      icon: programsIcon,
      title: 'استبيان التحول الرقمي',
      url: `/indicators/${indicatorId || 1}`,
    },
    {
      title: 'المناظير',
      url: `/indicators/${indicatorId}/programs/${surveyId}/perspectives`,
    },
    {
      title: 'المحاور',
      url: `/indicators/${indicatorId}/programs/${surveyId}/perspectives/${perspectiveId}/interlocutor`,
    },
    {
      title: 'المعايير',
      url: `/indicators/${indicatorId}/programs/${surveyId}/perspectives/${perspectiveId}/interlocutor/${interlocutorId}/standards`,
    },
    {
      title: 'تعديل معيار',
    },
  ];

  return (
    <HeaderWithBreadcrumbLayout breadcrumbArr={editStanderdsBreadcumbs}>
      <div className="bg-gray_light p-5 mb-10 rounded-lg pb-36 relative">
        {loadData ? (
          <div className="min-h-[400px] flex justify-center items-center">
            <AppLoader />
          </div>
        ) : (
          <>
            <AddAboutStandard from={'edit'} disabled={disableEditStandard} />
            <EditRequirementClassifications disabled={disableEditStandard} />
            <StandardUploadOldFiles disabled={disableEditStandard} />
            <StandardRequirements disabled={disableEditStandard} />
            <StandardProofFiles disabled={disableEditStandard} />
            <StandardOrders disabled={disableEditStandard} />
            <SubmitEditStandard disabled={disableEditStandard} />
          </>
        )}
        {/* <SubmitAddStandard /> */}
      </div>{' '}
    </HeaderWithBreadcrumbLayout>
  );
};

export default EditStandard;
