import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction'; // needed for dayClick
import arLocale from '@fullcalendar/core/locales/ar';
import FModal from './FModal';
import SModal from './SModal';
import InputLabel from './InputLabel';
import Input from './Input';
import Title from './Title';
import { useTranslation } from 'react-i18next';
import Button from './Button';
import { useEffect, useState } from 'react';
import environment from '../services/environment';
import { get, post, put } from '../services/Request';
import Skeleton from 'react-skeleton-loader';
import Moment from 'moment';
import NewHeader from '../layouts/header/NewHeader';
import { programsIcon } from '../assets/icons/Index';
import { plusWhite } from '../assets/icons/questions';
import { permissionsCheck } from '../helpers/utils';

const CCalendar = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const [id, setId] = useState(0);
  const [agencyId, setAgencyId] = useState(0);
  const [date, setDate] = useState('');
  const [type, setType] = useState(2);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [count, setCount] = useState(1);
  const [meetingType, setMeetingType] = useState(2);
  const [lists, setLists] = useState([]);
  const [events, setEvents] = useState([]);
  const listAdmin = [
    {
      id: 1,
      name: 'onlineMeeting',
      label: t('modules.main.calendar.type.onlineMeeting'),
    },
    {
      id: 3,
      name: 'fieldVisit',
      label: t('modules.main.calendar.type.fieldVisit'),
    },
  ];
  const listAgency = [
    { id: 2, name: 'Meeting', label: t('modules.main.calendar.type.meeting') },
  ];
  const listExpert = [];
  const listReviewer = [];
  const [fieldVisits, setFieldVisits] = useState([
    {
      id: 0,
      name: 'IsConfidential',
      label: t('modules.main.calendar.fieldVisits.confidential'),
    },
    {
      id: 1,
      name: 'FieldVisit',
      label: t('modules.main.calendar.fieldVisits.fieldVisit'),
    },
  ]);
  const [loading, setLoading] = useState(false);
  const [loadingModel, setLoadingModel] = useState(false);
  const [message, setMessage] = useState({});

  const handleChangeDate = (e) => {
    setDate(e.target.value);
  };

  const handleChangeTitle = (e) => {
    setTitle(e.target.value);
  };

  const handleChangeType = (e) => {
    setType(e.target.value);
    var dateStr = date.split(' ')[0];
    if (e.target.value == 2) setDate(dateStr + ' 00:00');
    else setDate(dateStr);
  };

  const handleChangeDescription = (e) => {
    setDescription(e.target.value);
  };

  const handleChangeCount = (e) => {
    setCount(e.target.value);
  };

  const handleChangeMeetingType = (e) => {
    setMeetingType(e.target.value);
  };

  useEffect(() => {
    setAgencyId(localStorage.getItem('agencyId'));
    getAllList();
    if (permissionsCheck('Pages.UserType.Admin')) {
      setLists(listAdmin);
    } else if (permissionsCheck('Pages.UserType.Expert')) {
      setLists(listExpert);
    } else if (permissionsCheck('Pages.UserType.Reviewer')) {
      setLists(listReviewer);
    } else if (
      permissionsCheck('Pages.UserType.POC') ||
      permissionsCheck('Pages.UserType.CIO') ||
      permissionsCheck('Pages.UserType.Delegate')
    ) {
      setLists(listAgency);
    }
  }, []);

  const saveEvent = () => {
    if (type == 1) createOnlineMeeting();
    else if (type == 3) createFieldVisit();
    else {
      if (id == 0) createEvent();
      else updateEvent();
    }
  };

  const createEvent = () => {
    setLoadingModel(true);
    var currentAgencyId = localStorage.getItem('agencyId');
    if (!title) {
      setMessage(
        { type: 'error', message: t('modules.main.calendar.nameRequired') },
        setOpenModel(true)
      );
      return;
    }
    if (
      currentAgencyId == null ||
      currentAgencyId == 'null' ||
      currentAgencyId == '' ||
      currentAgencyId == '0'
    ) {
      setMessage(
        { type: 'error', message: t('modules.main.calendar.agencyRequired') },
        setOpenModel(true)
      );
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    let data = {
      meetingTitle: title,
      meetingDate: date,
      notes: description,
      agencyId: localStorage.getItem('agencyId'),
    };
    post(environment.createMeetingRequest, data, config, (res) => {
      setLoadingModel(false);
      if (res.status == 403) {
        setMessage(
          { type: 'error', message: t('general.authError') },
          setOpenModel(true)
        );
      } else if (res.status == 500) {
        setMessage(
          { type: 'error', message: t('general.serverError') },
          setOpenModel(true)
        );
      } else if (res.status == 200) {
        setMessage({ type: 'success', message: t('تم الإرسال بنجاح') });
        var array = [...events];
        array.push({
          title: res.data.result.meetingTitle,
          start: res.data.result.meetingDate,
          groupId: 'request' + res.data.result.id,
          editable: true,
          dateStr: res.data.result.meetingDate.split('T')[0],
          notes: res.data.result.notes,
          type: 2,
          agencyId: res.data.result.agencyId,
          id: res.data.result.id,
        });
        setEvents(array);
        setTimeout(() => {
          setOpen(false);
          setOpenModel(true);
        }, 500);
      }
    });
  };

  const updateEvent = () => {
    setLoadingModel(true);
    if (!title) {
      setMessage(
        { type: 'error', message: t('modules.main.calendar.nameRequired') },
        setOpenModel(true)
      );
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    let data = {
      id: id,
      meetingTitle: title,
      meetingDate: date,
      notes: description,
      agencyId: agencyId,
    };
    put(environment.updateMeetingRequest, data, config, (res) => {
      setLoadingModel(false);
      if (res.status == 403) {
        setMessage(
          { type: 'error', message: t('general.authError') },
          setOpenModel(true)
        );
      } else if (res.status == 500) {
        setMessage(
          { type: 'error', message: t('general.serverError') },
          setOpenModel(true)
        );
      } else if (res.status == 200) {
        setMessage({ type: 'success', message: t('تم الإرسال بنجاح') });
        var array = [...events];
        const index = array.findIndex(
          (e) => e.groupId === res.data.result.id && e.type == 2
        );
        array.splice(index, 1);
        array.push({
          title: res.data.result.meetingTitle,
          start: res.data.result.meetingDate,
          groupId: 'request' + res.data.result.id,
          editable: true,
          dateStr: res.data.result.meetingDate.split('T')[0],
          notes: res.data.result.notes,
          type: 2,
          agencyId: res.data.result.agencyId,
          id: res.data.result.id,
        });
        setEvents(array);
        setTimeout(() => {
          setOpen(false);
          setOpenModel(true);
        }, 500);
      }
    });
  };

  const updateEventDate = (id, title, date, description, agencyId) => {
    setLoadingModel(true);
    if (!title) {
      setMessage(
        { type: 'error', message: t('modules.main.calendar.nameRequired') },
        setOpenModel(true)
      );
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    let data = {
      id: id,
      meetingTitle: title,
      meetingDate: date,
      notes: description,
      agencyId: agencyId,
    };
    put(environment.updateMeetingRequest, data, config, (res) => {
      setLoadingModel(false);
      if (res.status == 403) {
        setMessage(
          { type: 'error', message: t('general.authError') },
          setOpenModel(true)
        );
      } else if (res.status == 500) {
        setMessage(
          { type: 'error', message: t('general.serverError') },
          setOpenModel(true)
        );
      } else if (res.status == 200) {
        setMessage({ type: 'success', message: t('تم الإرسال بنجاح') });
        var array = [...events];
        const index = array.findIndex(
          (e) => e.id === res.data.result.id && e.type == 2
        );
        array[index].start = res.data.result.meetingDate;
        array[index].dateStr = res.data.result.meetingDate.split('T')[0];
        setEvents(array);
        setTimeout(() => {
          setOpen(false);
          setOpenModel(true);
        }, 500);
      }
    });
  };

  const getAllList = () => {
    setEvents([]);
    var query = '';
    if (
      permissionsCheck('Pages.UserType.POC') ||
      permissionsCheck('Pages.UserType.CIO') ||
      permissionsCheck('Pages.UserType.Delegate')
    ) {
      query = '?agencyId=' + localStorage.getItem('agencyId');
    }
    getAllMeetingRequest(query);
  };

  const getAllMeetingRequest = (query) => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    var new_query = query + '?meetingStatus=0';
    if (query.length > 0) new_query = query + '&meetingStatus=0';
    get(environment.getAllMeetingRequest + new_query, config, (res) => {
      if (res.status == 403) {
        setMessage({ type: 'error', message: t('general.authError') });
        setOpenModel(true);
        setLoading(false);
      } else if (res.status == 500) {
        setMessage({ type: 'error', message: t('general.serverError') });
        setOpenModel(true);
        setLoading(false);
      } else if (res.status == 200) {
        if (res.data.result.items && res.data.result.items.length > 0) {
          var array = res.data.result.items;
          for (var i = 0; i < array.length; i++) {
            array[i].dateStr = Moment(array[i].meetingDate)
              .locale('ar')
              .format('yyyy-MM-DD');
            array[i].title = array[i].meetingTitle;
            array[i].groupId = 'request' + array[i].id;
            array[i].type = 2;
            array[i].start = array[i].meetingDate;
            array[i].editable =
              permissionsCheck('Pages.UserType.Admin') ||
              localStorage.getItem('agencyId');
          }
          setEvents((old) => [...old, ...array]);
        }
        setTimeout(() => {
          setLoading(false);
          getAllOnlineMeeting(query);
        }, 500);
      }
    });
  };

  const getAllOnlineMeeting = (query) => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    get(environment.getAllAvailableMeetingRoom + query, config, (res) => {
      if (res.status == 403) {
        setMessage({ type: 'error', message: t('general.authError') });
        setOpenModel(true);
        setLoading(false);
      } else if (res.status == 500) {
        setMessage({ type: 'error', message: t('general.serverError') });
        setOpenModel(true);
        setLoading(false);
      } else if (res.status == 200) {
        if (res.data.result.items && res.data.result.items.length > 0) {
          var newArray = [];
          var array = res.data.result.items;
          for (var i = 0; i < array.length; i++) {
            for (var j = 0; j < array[i].onlineMeetingRoom.length; j++) {
              var newRoom = {
                id: array[i].id,
                meetingDate: array[i].meetingDate,
                dateStr: Moment(array[i].meetingDate)
                  .locale('ar')
                  .format('yyyy-MM-DD'),
                notes: array[i].onlineMeetingRoom.length,
                groupId: 'meeting' + array[i].id,
                start: array[i].meetingDate,
                type: 1,
              };
              newRoom.roomId = array[i].onlineMeetingRoom[j].id;
              newRoom.agencyId = array[i].onlineMeetingRoom[j].agencyId;
              newRoom.isReserved = array[i].onlineMeetingRoom[j].isReserved;
              newRoom.title = t('modules.main.calendar.type.onlineMeeting');
              if (array[i].onlineMeetingRoom[j].isReserved == true) {
                newRoom.title =
                  newRoom.title + ' - ' + t('modules.main.calendar.reserved');
              }
              newArray.push(newRoom);
            }
          }
          setEvents((old) => [...old, ...newArray]);
        }
        setTimeout(() => {
          setLoading(false);
          getAllFieldVisit(query);
        }, 500);
      }
    });
  };

  const getAllFieldVisit = (query) => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    get(environment.getAllAvailableFieldVisits + query, config, (res) => {
      if (res.status == 403) {
        setMessage({ type: 'error', message: t('general.authError') });
        setOpenModel(true);
        setLoading(false);
      } else if (res.status == 500) {
        setMessage({ type: 'error', message: t('general.serverError') });
        setOpenModel(true);
        setLoading(false);
      } else if (res.status == 200) {
        if (res.data.result.items && res.data.result.items.length > 0) {
          var newArray = [];
          var array = res.data.result.items;
          for (var i = 0; i < array.length; i++) {
            for (var j = 0; j < array[i].fieldVisitSlot.length; j++) {
              var newRoom = {
                id: array[i].id,
                meetingDate: array[i].meetingDate,
                dateStr: Moment(array[i].meetingDate)
                  .locale('ar')
                  .format('yyyy-MM-DD'),
                meetingTitle: t('modules.main.calendar.type.fieldVisit'),
                notes: array[i].fieldVisitSlot.length,
                groupId: 'visit' + array[i].id,
                start: array[i].meetingDate,
                type: 3,
              };
              newRoom.roomId = array[i].fieldVisitSlot[j].id;
              newRoom.agencyId = array[i].fieldVisitSlot[j].agencyId;
              newRoom.isReserved = array[i].fieldVisitSlot[j].isReserved;
              newRoom.title =
                array[i].meetingType == 0
                  ? t('modules.main.calendar.fieldVisits.confidential')
                  : array[i].meetingType == 1
                    ? t('modules.main.calendar.fieldVisits.fieldVisit')
                    : t('modules.main.calendar.fieldVisits.general');
              if (array[i].fieldVisitSlot[j].isReserved == true) {
                newRoom.title =
                  array[i].title + ' - ' + t('modules.main.calendar.reserved');
              }
              newArray.push(newRoom);
            }
          }
          setEvents((old) => [...old, ...newArray]);
        }
        setTimeout(() => {
          setLoading(false);
        }, 500);
      }
    });
  };

  const handleDateClick = (arg) => {
    var currentAgencyId = localStorage.getItem('agencyId');
    if (Moment() < Moment(arg.dateStr)) {
      setAgencyId(currentAgencyId);
      setId(0);
      setType(permissionsCheck('Pages.UserType.Admin') ? 1 : 2);
      setTitle('');
      setDescription('');
      if (permissionsCheck('Pages.UserType.Admin')) setDate(arg.dateStr);
      else setDate(arg.dateStr + ' 00:00');
      setOpen(true);
    }
  };

  const handleDragClick = (info) => {
    if (new Date() > info.event._instance.range.start) {
      info.revert();
      return;
    }
    if (window.confirm(t('modules.main.calendar.confirmUpdate')) == true) {
      const newDateStr = Moment(info.event._instance.range.start).format(
        'yyyy-MM-DD HH:mm'
      );
      updateEventDate(
        info.event.id,
        info.event.title,
        newDateStr,
        info.event.extendedProps.notes,
        info.event.extendedProps.agencyId
      );
    } else {
      info.revert();
    }
  };

  const editEvent = (event) => {
    if (
      (permissionsCheck('Pages.UserType.POC') ||
        permissionsCheck('Pages.UserType.CIO') ||
        permissionsCheck('Pages.UserType.Delegate')) &&
      (event.extendedProps.type == 1 || event.extendedProps.type == 3) &&
      event.extendedProps.isReserved != true
    ) {
      if (window.confirm(t('modules.main.calendar.confirmReserve')) == true) {
        if (event.extendedProps.type == 1) {
          reserveMeetingRoom(event.extendedProps.roomId);
        } else if (event.extendedProps.type == 3) {
          reserveVisitRoom(event.extendedProps.roomId);
        }
      }
    }
    /*else if(event.extendedProps.type == 2){
      setOpen(true);
      setType(event.extendedProps.type);
      setTitle(event.title);
      setId(event.publicId);
      setDescription(event.extendedProps.notes);
      setAgencyId(event.extendedProps.agencyId);
      setDate(event.extendedProps.dateStr.split(' ')[0]);
    }*/
  };

  const createOnlineMeeting = () => {
    setLoadingModel(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    var rooms = [];
    for (var i = 0; i < count; i++) {
      rooms.push({ title: title });
    }
    let data = {
      meetingDate: date,
      onlineMeetingRoom: rooms,
    };
    post(environment.createOnlineMeeting, data, config, (res) => {
      setLoadingModel(false);
      if (res.status == 403) {
        setMessage({ type: 'error', message: t('general.authError') });
        setOpenModel(true);
      } else if (res.status == 500) {
        setMessage({ type: 'error', message: t('general.serverError') });
        setOpenModel(true);
      } else if (res.status == 200) {
        setMessage({ type: 'success', message: t('تم الإرسال بنجاح') });
        getAllList();
        setTimeout(() => {
          setOpen(false);
          setOpenModel(true);
        }, 500);
      }
    });
  };

  const createFieldVisit = () => {
    setLoadingModel(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    var rooms = [];
    for (var i = 0; i < count; i++) {
      rooms.push({ title: title });
    }
    let data = {
      meetingDate: date,
      MeetingType: meetingType,
      fieldVisitSlot: rooms,
    };
    post(environment.createFieldVisit, data, config, (res) => {
      setLoadingModel(false);
      if (res.status == 403) {
        setMessage({ type: 'error', message: t('general.authError') });
        setOpenModel(true);
      } else if (res.status == 500) {
        setMessage({ type: 'error', message: t('general.serverError') });
        setOpenModel(true);
      } else if (res.status == 200) {
        setMessage({ type: 'success', message: t('تم الإرسال بنجاح') });
        getAllList();
        setTimeout(() => {
          setOpen(false);
          setOpenModel(true);
        }, 500);
      }
    });
  };

  const reserveMeetingRoom = (id) => {
    setLoadingModel(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    let data = {};
    post(
      environment.reserveAvailableRoomToAgency +
        '?roomId=' +
        id +
        '&agencyId=' +
        localStorage.getItem('agencyId'),
      data,
      config,
      (res) => {
        setLoadingModel(false);
        if (res.status == 403) {
          setMessage({ type: 'error', message: t('general.authError') });
          setOpenModel(true);
        } else if (res.status == 500) {
          setMessage({ type: 'error', message: t('general.serverError') });
          setOpenModel(true);
        } else if (res.status == 200) {
          setMessage({ type: 'success', message: t('تم الإرسال بنجاح') });
          setOpenModel(true);
          setTimeout(() => {
            getAllList();
          }, 500);
        }
      }
    );
  };

  const reserveVisitRoom = (id) => {
    setLoadingModel(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    let data = {};
    post(
      environment.reserveAvailableSlotToAgency +
        '?roomId=' +
        id +
        '&agencyId=' +
        localStorage.getItem('agencyId'),
      data,
      config,
      (res) => {
        setLoadingModel(false);
        if (res.status == 403) {
          setMessage({ type: 'error', message: t('general.authError') });
          setOpenModel(true);
        } else if (res.status == 500) {
          setMessage({ type: 'error', message: t('general.serverError') });
          setOpenModel(true);
        } else if (res.status == 200) {
          setMessage({ type: 'success', message: t('تم الإرسال بنجاح') });
          setOpenModel(true);
          setTimeout(() => {
            getAllList();
          }, 500);
        }
      }
    );
  };

  return (
    <div className="bg-white rounded-[30px] m-5">
      <NewHeader />
      <Title
        iconTitle={programsIcon}
        onClick={() => setOpen(true)}
        style={{ backgroundColor: '#2B2969' }}
        subTitle={'الاجندة'}
        seconed={true}
        title={t('modules.main.calendar.title')}
        titleButton={t('modules.main.calendar.add')}
        withIcon={true}
        icon={plusWhite}
        withoutButton={
          permissionsCheck('Pages.UserType.Admin') ||
          permissionsCheck('Pages.UserType.POC') ||
          permissionsCheck('Pages.UserType.CIO') ||
          permissionsCheck('Pages.UserType.Delegate')
            ? false
            : true
        }
      />
      <SModal
        type={message.type}
        open={openModel}
        setOpen={() => setOpenModel(false)}
        subTitle={message.message}
      />
      <FModal
        headerTitle={t('modules.main.calendar.add')}
        content={
          <div>
            <span className="text-base text-[#656565]">
              {t('modules.main.calendar.type.title')}
            </span>
            <div className="flex flex-row mt-3">
              {lists.map(({ name, id, label }) => (
                <div className="flex flex-row items-center">
                  <Input
                    type={'radio'}
                    id={'type' + id}
                    name="event-type"
                    value={id}
                    checked={type == id}
                    onChange={handleChangeType}
                  />
                  <span className="pr-5 pl-5 text-base text-[#656565]">
                    {label}
                  </span>
                </div>
              ))}
            </div>
            {type == 3 && (
              <div>
                <span className="text-base text-[#656565]">
                  {t('modules.main.calendar.fieldVisits.title')}
                </span>
                <div className="flex flex-row mt-3">
                  {fieldVisits.map(({ name, id, label }) => (
                    <div className="flex flex-row items-center">
                      <Input
                        type={'radio'}
                        id={'visit' + id}
                        name="visit-type"
                        value={id}
                        checked={meetingType == id}
                        onChange={handleChangeMeetingType}
                      />
                      <span className="pr-5 pl-5 text-base text-[#656565]">
                        {label}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            )}
            <InputLabel
              label={t('modules.main.calendar.date')}
              type={type == 2 ? 'datetime-local' : 'date'}
              value={date}
              onChange={handleChangeDate}
            />
            <InputLabel
              label={t('modules.main.calendar.name')}
              type={'text'}
              value={title}
              onChange={handleChangeTitle}
            />
            {type == 2 && (
              <InputLabel
                label={t('modules.main.calendar.description')}
                type={'textarea'}
                value={description}
                onChange={handleChangeDescription}
              />
            )}
            {(type == 1 || type == 3) && (
              <InputLabel
                label={t('modules.main.calendar.count')}
                type={'text'}
                value={count}
                onChange={handleChangeCount}
              />
            )}

            {Moment() < Moment(date) && (
              <Button
                loading={loadingModel}
                title={t('modules.main.calendar.save')}
                typeColor={'dark'}
                className="pt-3 mt-3"
                withIcon={false}
                style={{
                  backgroundColor: '#00114E',
                  width: 160,
                  marginTop: 10,
                }}
                onClick={() => saveEvent()}
              />
            )}
          </div>
        }
        open={open}
        setOpen={() => setOpen(false)}
      />
      {loading ? (
        <div className="p-5 bg-transparent w-3/4">
          <Skeleton
            animated={true}
            color={'#2B296969'}
            width={'100%'}
            height={'500px'}
            count={1}
          />
        </div>
      ) : events ? (
        <div className="p-5">
          <FullCalendar
            headerToolbar={{
              start: 'today prev next',
              center: 'title',
              end: 'dayGridMonth dayGridWeek dayGridDay',
            }}
            plugins={[dayGridPlugin, interactionPlugin]}
            initialView="dayGridMonth"
            views={['dayGridMonth', 'dayGridWeek', 'dayGridDay']}
            weekends={false}
            events={events}
            eventContent={renderEventContent}
            dateClick={handleDateClick}
            eventClick={(event) => editEvent(event.event)}
            eventDrop={handleDragClick}
            locale={arLocale}
          />
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

const renderEventContent = (eventInfo) => {
  return (
    <>
      <b>{eventInfo.timeText}</b> - <i>{eventInfo.event.title}</i>
    </>
  );
};

export default CCalendar;
