import React from 'react';
import { t } from 'i18next';
import { useHistory, useParams } from 'react-router';

import SecondaryTapsLayout from '../../../../../layouts/secondaryTapsLayout';
import { surveySettingTabs } from '../data';
import { Title } from '../../../../../components';
import { programsIcon } from '../../../../../assets/icons/Index';
import SurveyDegreeList from './degreeList';

const ServeyDegreesList = () => {
  const { surveyId, indicatorId } = useParams();
  const history = useHistory();

  return (
    <SecondaryTapsLayout
      linksArr={surveySettingTabs(
        `/indicators/${indicatorId}/surveys/${surveyId}`
      )}
      breadcrumbs={
        <div className="-mx-5">
          <Title
            iconTitle={programsIcon}
            subTitle={'اعدادات الاستبيان'}
            thired={true}
            subTitleSeconed={'الإلتزامات والدرجات للمعيار'}
            title={t('المؤشرات')}
            toPage={() => history.push(`/indicators`)}
            toPageSeconed={() => history.push(`/indicators/${indicatorId}`)}
            withoutButton={true}
          />
        </div>
      }
    >
      <SurveyDegreeList />
    </SecondaryTapsLayout>
  );
};

export default ServeyDegreesList;
