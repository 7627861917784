import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { useParams } from 'react-router';

import AppCheckbox from '../../../../../../../components/form/appCheckbox';
import { StageContext } from '../../../../../../../contexts/stageContext';
import { axiosGetRequest } from '../../../../../../../services/Request';
import environment from '../../../../../../../services/environment';
import { useAnswerContext } from '../../answerContext';
import StandardFinalAnswer from '../../standardAgencyAnswer';
import useCreatePocOfficerAnswer from '../useCreateAnswer/useCreatePocOfficerAnswer';

const PocOfficerFinalAnswerPhase4ShowingPhase2 = ({
  setMenuElement,
  pocOfficerAnswerOnPhase2,
}) => {
  const { standardId } = useParams();
  const { currentStage } = useContext(StageContext);

  const { createPocOfficerAnswer } = useCreatePocOfficerAnswer();
  const { newPocOfficerAnswer } = useAnswerContext();

  const { data: degreesResult } = useQuery({
    queryKey: ['getCommitmentLevels', standardId],
    queryFn: () =>
      axiosGetRequest(environment.getCommitmentLevels, {
        standardId: standardId,
      }),
  });

  // const checkedPocOfficerDegree = (degreesResult?.filter && degreesResult?.filter(
  //   (item) =>
  //     item?.id == pocOfficerAnswerOnPhase2?.result?.surveyExpertAnswer?.finalCommitmentLevel?.id
  // )?.[0]) || null;

  const checkedPocOfficerDegree =
    degreesResult?.result?.filter(
      (item) =>
        item?.id ==
        pocOfficerAnswerOnPhase2?.result?.pocOfficerAnswer?.finalCommitmentLevel
          ?.id
    )?.[0] || null;

  const renderedDegrees = newPocOfficerAnswer?.surveyDegrees?.map((item) => (
    <AppCheckbox
      key={item?.value}
      id={item.value}
      name={item.label}
      label={item.label}
      value={item.value}
      checked={checkedPocOfficerDegree?.id === item?.id}
      onChange={() => {}}
    />
  ));

  return (
    <StandardFinalAnswer
      setMenuElement={setMenuElement}
      currentStage={currentStage}
      createPocOfficerAnswer={createPocOfficerAnswer}
      label="إجابة ضابط الاتصال"
    >
      <div className="flex w-full flex-wrap">
        <div className="shrink-0 max-w-[160px]">{renderedDegrees}</div>
      </div>
    </StandardFinalAnswer>
  );
};

export default PocOfficerFinalAnswerPhase4ShowingPhase2;
