import Skeleton from 'react-skeleton-loader';
import { permissionsCheck } from '../../../../../helpers/utils';

function EntityDetailsLoading() {
  return (
    <div className="bg-[#F9F9FF] rounded-[30px] p-5 m-5">
      <div className="flex items-center justify-between mb-3">
        <p className="text-lg mb-4">
          <Skeleton width="150px" height="20px" />
        </p>
        {permissionsCheck('Pages.UserType.Admin') && (
          <div>
            <Skeleton width="150px" height="40px" />
          </div>
        )}
      </div>
      <div className="bg-white rounded-xl py-4 px-6">
        {/* Header */}
        <header className="flex items-start justify-between pb-6">
          <div className="flex items-start gap-8">
            <Skeleton circle width="40px" height="40px" />
            <div className="flex flex-col gap-1">
              <Skeleton width="250px" height="20px" />
              <Skeleton width="150px" height="20px" />
            </div>
          </div>
          <Skeleton width="100px" height="30px" />
        </header>
        <div className="w-[90%] mb-6 border-[1px] mx-auto border-gray-200 border-dashed"></div>

        <div className="text-[#7D8BAB] flex flex-col gap-1">
          <Skeleton width="80%" height="20px" count={1} />
          <Skeleton width="90%" height="20px" count={1} />
        </div>
      </div>

      <p className="text-lg mb-4 mt-9">
        <Skeleton width="150px" height="20px" />
      </p>
      <div className="bg-white rounded-xl p-6 pb-7 flex flex-col gap-3">
        <div className="flex gap-4">
          <div className="w-full">
            <p>
              <Skeleton width="100px" height="20px" />
            </p>
            <div className="flex gap-3 mt-3 pr-6 items-center p-3 rounded-full bg-[#F9F9FF]">
              <Skeleton width="100px" height="20px" />
            </div>
          </div>
          <div className="w-full">
            <p>
              <Skeleton width="100px" height="20px" />
            </p>
            <div className="flex gap-3 mt-3 pr-6 items-center p-3 rounded-full bg-[#F9F9FF]">
              <Skeleton width="100px" height="20px" />
            </div>
          </div>
          <div className="w-full">
            <p>
              <Skeleton width="100px" height="20px" />
            </p>
            <div className="flex gap-3 mt-3 pr-6 items-center p-3 rounded-full bg-[#F9F9FF]">
              <Skeleton width="100px" height="20px" />
            </div>
          </div>
        </div>
        <div className="w-full">
          <p>
            <Skeleton width="100px" height="20px" />
          </p>
          <div className="flex flex-wrap gap-3 mt-3 pr-6 items-center p-3 rounded-full bg-[#F9F9FF]">
            <Skeleton width="100px" height="20px" />
          </div>
        </div>
      </div>

      <p className="text-lg mb-4 mt-10">
        <Skeleton width="150px" height="20px" />
      </p>
      <div className="flex gap-x-6">
        <div className="bg-white rounded-full text-sm flex items-center justify-between py-3 px-6 w-full">
          <Skeleton width="90%" height="20px" />
          <Skeleton circle width="20px" height="20px" />
        </div>
      </div>
    </div>
  );
}

export default EntityDetailsLoading;
