import React, { useContext, useRef, useState } from 'react';
import { AppInput } from '../../../../../components';
import AppTextarea from '../../../../../components/form/appTextarea';
import AppModal from '../../../../../components/Models/appModal';
import { axiosPutRequest } from '../../../../../services/Request';
import environment from '../../../../../services/environment';
import { IndicatorsContext } from '../../../../../contexts/indicatorsContext';
import { toast } from 'react-toastify';
import { ErrorMessage, Formik } from 'formik';

const UpdateIndicatorModal = ({ openModal, setOpenModal, values }) => {
  const { updateEditedIndicator } = useContext(IndicatorsContext);
  const [loading, setLoading] = useState(false);
  const [inputs, setInputs] = useState(
    values || {
      id: 1,
      title: '',
      description: '',
      isActive: true,
    }
  );

  const handleInputChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const handleUpdate = async () => {
    setLoading(true);
    const res = await axiosPutRequest(environment.updateIndicator, inputs);
    setLoading(false);
    if (res?.success) {
      updateEditedIndicator(inputs.id, res?.result);
      toast.info('تم تحديث المؤشر بنجاح');
      setOpenModal(false);
    }
  };

  const formRef = useRef(null);

  const handleFormik = () => {
    if (formRef.current) {
      formRef.current?.handleSubmit();
    }
  };

  const initialValues = {
    title: '',
    description: '',
  };

  const validate = (values) => {
    const errors = {};
    if (!inputs.title || inputs.title.trim() === '') {
      errors.title = 'هذا الحقل مطلوب';
    }

    return errors;
  };

  return (
    <AppModal
      headerTitle="تحديث المؤشر"
      open={openModal}
      setOpen={setOpenModal}
      handleSubmit={handleFormik}
      type={'submit'}
      loading={loading}
      submitLabel="تحديث"
    >
      <Formik
        initialValues={initialValues}
        validate={validate}
        innerRef={formRef}
        onSubmit={handleUpdate}
      >
        {({ handleChange, values }) => (
          <div>
            <div>
              <AppInput
                type={'text'}
                label={'إسم المؤشر'}
                name="title"
                variant="gray"
                value={inputs.title}
                onChange={handleInputChange}
              />
              <ErrorMessage
                className="text-red-700"
                name="title"
                component="div"
              />
            </div>
            <div className="mt-3">
              <AppTextarea
                label={'الوصف'}
                name="description"
                value={inputs.description}
                onChange={handleInputChange}
              />
            </div>
          </div>
        )}
      </Formik>
    </AppModal>
  );
};

export default UpdateIndicatorModal;
