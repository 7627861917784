import { StyleSheet, Text, View } from '@react-pdf/renderer';

export const ProgressBar2 = ({
  label,
  percentage,
  color = '#7C32C9',
  count,
  barlabel = 'عدد الجهات في المجموعة​',
}) => {
  const styles = StyleSheet.create({
    progressLabel: {
      width: '30%',
      fontSize: 10,
      textAlign: 'right',
      paddingLeft: 5,
      fontWeight: 'bold',
    },
    progressWeightLabel: {
      width: '10%',
      fontSize: 8,
      textAlign: 'right',
      paddingLeft: 5,
      fontWeight: 'bold',
    },
    progressBarWrapper: {
      width: '55%',
      backgroundColor: 'transparent',
      borderRadius: 10,
      height: 12,
      overflow: 'hidden',
      transform: 'scaleX(-1)',
    },
    progressBar: {
      height: '100%',
      borderRadius: 10,
      transform: 'scaleY(-1)',
    },
  });

  return (
    <View
      style={{
        marginVertical: 1,
        width: '100%',
        padding: 5,
        backgroundColor: 'transparent',
        border: '1px solid #e0e0e0 ',
        borderRadius: 8,
        marginBottom: 4,
        flexDirection: 'column',
      }}
    >
      <Text
        style={{
          color: `${color}`,
          textAlign: 'center',
          fontSize: 10,
          fontWeight: 'bold',
          marginBottom: -25,
        }}
      >
        {label}
      </Text>
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          alignItems: 'center',
          marginTop: 8,
        }}
      >
        <Text
          style={{ ...styles.progressLabel, paddingLeft: 2, width: 'auto' }}
        >
          {barlabel}{' '}
        </Text>
        <Text
          style={{
            ...styles.progressWeightLabel,
            paddingLeft: 2,
            width: 'auto',
          }}
        >
          {count}
        </Text>
      </View>
      <View style={{ ...styles.progressBarWrapper, width: '100%' }}>
        <View style={{ backgroundColor: '#f2f2f2', width: '100%' }}>
          <View
            style={[
              styles.progressBar,
              { width: `${percentage}%`, backgroundColor: color },
            ]}
          />
        </View>
      </View>
    </View>
  );
};
