import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { programsIcon } from '../../../assets/icons/Index';
import { InputLabel, Title } from '../../../components';
import Button from '../../../components/Button';
import NewHeader from '../../../layouts/header/NewHeader';
import environment from '../../../services/environment';
import { get, post } from '../../../services/Request';

const CardEvaluation = ({ percentage }) => {
  const [innovativeFormIdeaTypes, setInnovativeFormIdeaTypes] = useState([]);
  const history = useHistory();
  const { id } = useParams();
  const [message, setMessage] = useState('');
  const [selectedMaturityIdea, setSelectedMaturiyIdea] = useState('');
  const [selectedTypeIdea, setSelectedTypeIdea] = useState('');
  const [selectedIndicatorRelation, setSelectedIndicatorRelation] =
    useState('');

  const [selectedFinancialImpact, setSelectedFinancialImpact] = useState('');
  const [selectedImpactOnBeneficiaries, setSelectedImpactOnBeneficiaries] =
    useState('');
  const [selectedImpactOnEntity, setSelectedImpactOnEntity] = useState('');
  const [
    selectedReliabilityAndCompetitiveness,
    setSelectedReliabilityAndCompetitiveness,
  ] = useState('');

  const [ideaMaturitys, setIdeaMaturities] = useState([]);
  const [indicatorRelations] = useState([
    { id: 20, value: 20, label: '1' },
    { id: 40, value: 40, label: '2' },
    { id: 60, value: 60, label: '3' },
    { id: 80, value: 80, label: '4' },
    { id: 100, value: 100, label: '5' },
  ]);

  const [financialImpacts] = useState([
    { id: 20, value: 20, label: '1' },
    { id: 40, value: 40, label: '2' },
    { id: 60, value: 60, label: '3' },
    { id: 80, value: 80, label: '4' },
    { id: 100, value: 100, label: '5' },
  ]);

  const [impactOnBeneficiaries] = useState([
    { id: 20, value: 20, label: '1' },
    { id: 40, value: 40, label: '2' },
    { id: 60, value: 60, label: '3' },
    { id: 80, value: 80, label: '4' },
    { id: 100, value: 100, label: '5' },
  ]);

  const [impactOnEntities] = useState([
    { id: 20, value: 20, label: '1' },
    { id: 40, value: 40, label: '2' },
    { id: 60, value: 60, label: '3' },
    { id: 80, value: 80, label: '4' },
    { id: 100, value: 100, label: '5' },
  ]);

  const [reliabilityAndCompetitiveness] = useState([
    { id: 20, value: 20, label: '1' },
    { id: 40, value: 40, label: '2' },
    { id: 60, value: 60, label: '3' },
    { id: 80, value: 80, label: '4' },
    { id: 100, value: 100, label: '5' },
  ]);

  useEffect(() => {
    getInnovativeFormIdeaTypes();
  }, []);

  const getInnovativeFormIdeaTypes = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    get(environment.getInnovativeFormIdeaType, config, (res) => {
      if (res.status == 200) {
        let arr = [];
        res.data.result.items.forEach((item) => {
          let obj = { label: item.title, value: item.id, id: item.id * 20 };
          arr.push(obj);
        });

        setInnovativeFormIdeaTypes(arr, getInnovativeFormIdeaMaturities());
      }
    });
  };

  const getInnovativeFormIdeaMaturities = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    get(environment.getInnovativeFormIdeaMaturity, config, (res) => {
      if (res.status == 200) {
        let arr = [];
        res.data.result.items.forEach((item) => {
          let obj = { label: item.title, value: item.id, id: item.id * 20 };
          arr.push(obj);
        });

        setIdeaMaturities(arr);
      }
    });
  };

  const handleCheckboxTypeIdea = (event) => {
    var updatedAbilities = '';

    if (event.target.checked) {
      updatedAbilities = event.target.value;
    } else {
      updatedAbilities = updatedAbilities.filter(
        (id) => event.target.value != id
      );
    }
    setSelectedTypeIdea(updatedAbilities);
  };

  const isCheckedCheckboxTypeIdea = (id) => {
    return selectedTypeIdea == id;
  };

  const handleCheckboxIndicatorRelation = (event) => {
    var updatedAbilities = '';

    if (event.target.checked) {
      updatedAbilities = event.target.value;
    } else {
      updatedAbilities = updatedAbilities.filter(
        (id) => event.target.value != id
      );
    }
    setSelectedIndicatorRelation(updatedAbilities);
  };

  const isCheckedCheckboxInidcatorRelation = (id) => {
    return selectedIndicatorRelation == id;
  };

  const handleCheckboxMaturityIdea = (event) => {
    var updatedAbilities = '';

    if (event.target.checked) {
      updatedAbilities = event.target.value;
    } else {
      updatedAbilities = updatedAbilities.filter(
        (id) => event.target.value != id
      );
    }
    setSelectedMaturiyIdea(updatedAbilities);
  };

  const isCheckedCheckboxMaturityIdea = (id) => {
    return selectedMaturityIdea == id;
  };

  const handleCheckboxFinancialImpact = (event) => {
    var updatedAbilities = '';

    if (event.target.checked) {
      updatedAbilities = event.target.value;
    } else {
      updatedAbilities = updatedAbilities.filter(
        (id) => event.target.value != id
      );
    }
    setSelectedFinancialImpact(updatedAbilities);
  };

  const isCheckedCheckboxFinancialImpact = (id) => {
    return selectedFinancialImpact == id;
  };

  const handleCheckboxImpactOnBeneficiaries = (event) => {
    var updatedAbilities = '';

    if (event.target.checked) {
      updatedAbilities = event.target.value;
    } else {
      updatedAbilities = updatedAbilities.filter(
        (id) => event.target.value != id
      );
    }
    setSelectedImpactOnBeneficiaries(updatedAbilities);
  };

  const isCheckedCheckboxImpactOnBeneficiaries = (id) => {
    return selectedImpactOnBeneficiaries == id;
  };

  const handleCheckboxImpactOnEntity = (event) => {
    var updatedAbilities = '';

    if (event.target.checked) {
      updatedAbilities = event.target.value;
    } else {
      updatedAbilities = updatedAbilities.filter(
        (id) => event.target.value != id
      );
    }
    setSelectedImpactOnEntity(updatedAbilities);
  };

  const isCheckedCheckboxImpactOnEntity = (id) => {
    return selectedImpactOnEntity == id;
  };

  const handleCheckboxReliabilityAndCompetitiveness = (event) => {
    var updatedAbilities = '';

    if (event.target.checked) {
      updatedAbilities = event.target.value;
    } else {
      updatedAbilities = updatedAbilities.filter(
        (id) => event.target.value != id
      );
    }
    setSelectedReliabilityAndCompetitiveness(updatedAbilities);
  };

  const isCheckedCheckboxReliabilityAndCompetitiveness = (id) => {
    return selectedReliabilityAndCompetitiveness == id;
  };

  const addEvaluation = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    let data = {
      innovativeFormAgencyId: id,
      correctorId: localStorage.getItem('userId'),
      ideaTypeDegree: selectedTypeIdea,
      ideaMaturityDegree: selectedMaturityIdea,
      standardRelatedDegree: selectedIndicatorRelation,
      financialImpactDegree: selectedFinancialImpact,
      impactOnBeneficiariesDegree: selectedImpactOnBeneficiaries,
      impactOnAgencyDegree: selectedImpactOnEntity,
      reliabilityAndCompetitivenessDegree:
        selectedReliabilityAndCompetitiveness,
    };

    post(
      environment.innovativeFormAgencyAnswerCorrectionCreate,
      data,
      config,
      (res) => {
        toast.success('تم اضافة تقييم بنجاح', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
        history.push('/creativeCards');
      }
    );
  };

  return (
    <div className="bg-white rounded-[30px] m-5">
      <NewHeader />
      <Title
        title={'النماذج الابداعية'}
        withoutButton={true}
        iconTitle={programsIcon}
        subTitle={'النموذج'}
        toPage={() => history.push('/creativeCards')}
        toPageSeconed={() => history.push(`/creativeCards/${id}/details`)}
        subTitleSeconed={'تقييم النموذج'}
        thired={true}
      />
      <div className="bg-[#F9F9FF] h-full p-5 rounded-3xl flex flex-col justify-center">
        <section className="flex flex-row justify-center items-center">
          <svg
            width="165"
            height="48"
            viewBox="0 0 165 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              x1="48"
              y1="23"
              x2="117"
              y2="23"
              stroke="#251D5B"
              stroke-width="2"
            />
            <circle cx="141" cy="24" r="24" fill="#1CC081" />
            <rect width="48" height="48" rx="24" fill="#251D5B" />
            <g clip-path="url(#clip0_0_1)">
              <path
                d="M26.3147 31.5274H20.2667C20.2668 32.2786 20.5044 33.0103 20.9449 33.6164C21.3854 34.2224 22.0059 34.6714 22.7167 34.8983V35.9457C22.7167 36.1427 22.7943 36.3315 22.9325 36.4708C23.0708 36.61 23.2582 36.6883 23.4537 36.6883H24.6376C24.833 36.6883 25.0205 36.61 25.1587 36.4708C25.2969 36.3315 25.3746 36.1427 25.3746 35.9457V34.8983C26.0853 34.6714 26.7059 34.2224 27.1464 33.6164C27.5869 33.0103 27.8244 32.2786 27.8245 31.5274V30.2565C27.843 29.156 28.2786 28.1045 29.0421 27.3174C30.0269 26.3213 30.6963 25.0532 30.9655 23.6736C31.2347 22.294 31.0917 20.8649 30.5546 19.5673C30.0174 18.2696 29.1103 17.1617 27.9479 16.3837C26.7856 15.6057 25.4203 15.1927 24.0249 15.1968C22.6295 15.201 21.2667 15.6221 20.1089 16.407C18.9511 17.1919 18.0505 18.3052 17.5209 19.606C16.9914 20.9069 16.8568 22.3368 17.1341 23.7147C17.4114 25.0927 18.0882 26.3568 19.0788 27.347C19.4575 27.7292 19.7574 28.1831 19.9613 28.6825C20.1652 29.1819 20.2691 29.717 20.2669 30.2571"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M25.3745 34.8982H24.0457"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M24.0457 13.5342V12.312"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M19.784 14.6847L19.1775 13.6262"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M16.6645 17.8281L15.614 17.217"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M15.5226 22.1218H14.3096"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M33.6903 22.1218H32.4773"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M31.4268 17.8281L32.4773 17.217"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M28.3071 14.6847L28.9136 13.6262"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M24.4495 18.1783L25.157 19.6227C25.2727 19.859 25.4437 20.0634 25.6553 20.2183C25.8669 20.3732 26.1127 20.4739 26.3715 20.5118L27.9534 20.7434C28.046 20.757 28.1329 20.7964 28.2044 20.8572C28.2759 20.9179 28.3291 20.9977 28.358 21.0873C28.3869 21.1769 28.3904 21.2729 28.368 21.3644C28.3456 21.4559 28.2983 21.5393 28.2314 21.6052L27.0866 22.7295C26.8993 22.9134 26.7593 23.1405 26.6785 23.391C26.5977 23.6416 26.5785 23.9082 26.6227 24.1679L26.893 25.7555C26.9087 25.8484 26.8984 25.9439 26.8631 26.0312C26.8278 26.1185 26.769 26.1941 26.6934 26.2495C26.6177 26.3049 26.5281 26.3379 26.4348 26.3447C26.3415 26.3515 26.2482 26.3319 26.1654 26.2881L24.7504 25.5386C24.5189 25.4159 24.2614 25.3519 23.9999 25.3519C23.7384 25.3519 23.4808 25.4159 23.2494 25.5386L21.8344 26.2881C21.7516 26.3319 21.6582 26.3515 21.5649 26.3447C21.4716 26.3379 21.3821 26.3049 21.3064 26.2495C21.2307 26.1941 21.1719 26.1185 21.1366 26.0312C21.1014 25.9439 21.091 25.8484 21.1068 25.7555L21.377 24.1681C21.4212 23.9084 21.4021 23.6418 21.3213 23.3912C21.2405 23.1406 21.1004 22.9136 20.9131 22.7297L19.7684 21.6052C19.7015 21.5393 19.6541 21.4559 19.6318 21.3644C19.6094 21.2729 19.6128 21.1769 19.6417 21.0873C19.6707 20.9977 19.7239 20.9179 19.7954 20.8572C19.8669 20.7964 19.9538 20.757 20.0463 20.7434L21.6283 20.5118C21.8871 20.4739 22.1328 20.3732 22.3444 20.2183C22.556 20.0634 22.727 19.859 22.8427 19.6227L23.5502 18.1783C23.5917 18.0939 23.6558 18.0228 23.7352 17.973C23.8147 17.9233 23.9063 17.897 23.9999 17.897C24.0934 17.897 24.1851 17.9233 24.2645 17.973C24.344 18.0228 24.4081 18.0939 24.4495 18.1783Z"
                stroke="#251D5B"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M25.2595 24.5631C24.871 24.3573 24.4388 24.2497 23.9999 24.2497C23.5611 24.2497 23.1288 24.3573 22.7404 24.5631L22.3874 24.7503L22.4548 24.3544C22.5291 23.9186 22.497 23.4711 22.3614 23.0506C22.2259 22.63 21.9908 22.2489 21.6765 21.9403L21.3916 21.6601L21.7859 21.6023C22.2201 21.5387 22.6324 21.3697 22.9874 21.1098C23.3423 20.85 23.6292 20.507 23.8234 20.1106L23.9999 19.7502L24.1765 20.1104C24.3706 20.5069 24.6575 20.8499 25.0125 21.1098C25.3674 21.3697 25.7798 21.5387 26.214 21.6023L26.6082 21.6601L26.3234 21.9403C26.009 22.2489 25.7739 22.63 25.6383 23.0506C25.5027 23.4712 25.4707 23.9187 25.5451 24.3545L25.6123 24.7502L25.2595 24.5631Z"
                stroke="#1CC182"
                stroke-miterlimit="10"
              />
            </g>
            <path
              d="M148.651 19.331C148.187 18.8895 147.434 18.8898 146.969 19.331L139.397 26.5249L136.031 23.3277C135.567 22.8862 134.813 22.8862 134.349 23.3277C133.884 23.7692 133.884 24.4848 134.349 24.9263L138.555 28.9227C138.787 29.1433 139.092 29.2538 139.396 29.2538C139.701 29.2538 140.006 29.1435 140.238 28.9227L148.651 20.9296C149.116 20.4884 149.116 19.7725 148.651 19.331Z"
              fill="white"
            />
            <defs>
              <clipPath id="clip0_0_1">
                <rect
                  width="20"
                  height="25"
                  fill="white"
                  transform="translate(14 12)"
                />
              </clipPath>
            </defs>
          </svg>
          <h3 className="mr-2">التقييم</h3>
        </section>
        <section className="flex flex-row justify-between items-center bg-white py-5 px-9 my-5 rounded-xl">
          <div className="text-[#292069] text-[16px]">نسبة تقييمك للنموذج</div>
          <div className="flex flex-row items-center">
            <div className="w-72 h-1.5 ml-3 bg-[#EAEAEA] relative">
              <span
                className="h-1.5 bg-[#1CC081] absolute"
                style={{ width: `${percentage}%` }}
              ></span>
            </div>
            <div
              className={
                'text-[15px] ' +
                (!percentage ? 'text-[#FF304A]' : 'text-[#1CC081]')
              }
            >
              {percentage || 0}%
            </div>
          </div>
        </section>
        <div className="bg-white grid mt-3 gap-4 p-3 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3">
          <div className="m-2 bg-[#F9F9FF] rounded-2xl p-5 text-center">
            {}
            <InputLabel
              withoutLabel={false}
              column={true}
              handleCheckbox={handleCheckboxTypeIdea}
              isCheckedCheckbox={isCheckedCheckboxTypeIdea}
              label={'نوع الفكرة'}
              message={'لا يوجد درجات'}
              type={'checkbox'}
              items={innovativeFormIdeaTypes}
            />
          </div>
          <div className="m-2 bg-[#F9F9FF] rounded-2xl p-5 text-center">
            <InputLabel
              withoutLabel={false}
              column={true}
              handleCheckbox={handleCheckboxIndicatorRelation}
              isCheckedCheckbox={isCheckedCheckboxInidcatorRelation}
              label={'مدى الأرتباط بالمؤشر'}
              message={'لا يوجد درجات'}
              type={'checkbox'}
              items={indicatorRelations}
            />
          </div>
          <div className="m-2 bg-[#F9F9FF] rounded-2xl p-5 text-center">
            <InputLabel
              withoutLabel={false}
              column={true}
              handleCheckbox={handleCheckboxMaturityIdea}
              isCheckedCheckbox={isCheckedCheckboxMaturityIdea}
              label={'نضج الفكرة'}
              message={'لا يوجد درجات'}
              type={'checkbox'}
              items={ideaMaturitys}
            />
          </div>
        </div>
        <div className="bg-white grid mt-3 gap-4 p-3 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4">
          <div className="m-2 bg-[#F9F9FF] rounded-2xl p-5 text-center">
            {}
            <InputLabel
              withoutLabel={false}
              column={true}
              handleCheckbox={handleCheckboxFinancialImpact}
              isCheckedCheckbox={isCheckedCheckboxFinancialImpact}
              label={'الأثر المالي'}
              message={'لا يوجد درجات'}
              type={'checkbox'}
              items={financialImpacts}
            />
          </div>
          <div className="m-2 bg-[#F9F9FF] rounded-2xl p-5 text-center">
            <InputLabel
              withoutLabel={false}
              column={true}
              handleCheckbox={handleCheckboxImpactOnBeneficiaries}
              isCheckedCheckbox={isCheckedCheckboxImpactOnBeneficiaries}
              label={'الأثر على المستفيدين'}
              message={'لا يوجد درجات'}
              type={'checkbox'}
              items={impactOnBeneficiaries}
            />
          </div>
          <div className="m-2 bg-[#F9F9FF] rounded-2xl p-5 text-center">
            <InputLabel
              withoutLabel={false}
              column={true}
              handleCheckbox={handleCheckboxImpactOnEntity}
              isCheckedCheckbox={isCheckedCheckboxImpactOnEntity}
              label={'الأثر على الجهة'}
              message={'لا يوجد درجات'}
              type={'checkbox'}
              items={impactOnEntities}
            />
          </div>
          <div className="m-2 bg-[#F9F9FF] rounded-2xl p-5 text-center">
            <InputLabel
              withoutLabel={false}
              column={true}
              handleCheckbox={handleCheckboxReliabilityAndCompetitiveness}
              isCheckedCheckbox={isCheckedCheckboxReliabilityAndCompetitiveness}
              label={'الاعتماد والتنافسية'}
              message={'لا يوجد درجات'}
              type={'checkbox'}
              items={reliabilityAndCompetitiveness}
            />
          </div>
        </div>
        <section className="flex flex-row justify-between items-center mt-5">
          <div>
            <button
              className="relative rounded-3xl h-[50px] w-36 bg-[#DBE0EF] text-[#2A206A] text-center"
              onClick={() => history.push(`/creativeCards/${id}/details`)}
            >
              السابق
            </button>
          </div>
          <div className="flex flex-row items-center">
            <Button
              title={'حفظ كمسودة'}
              style={{ width: '12rem' }}
              onClick={() => addEvaluation()}
            />
            <button
              className="relative rounded-3xl mr-5 h-[50px] w-36 bg-[#1CC081] text-white text-center"
              onClick={() => addEvaluation()}
            >
              أرسال
            </button>
          </div>
        </section>
      </div>
    </div>
  );
};

export default CardEvaluation;
