import React, { useContext } from 'react';
import answer from './../../../../../../../assets/icons/answer.svg';
import NextBtn from '../../components/buttons/nextBtn';
import { useAnswerContext } from '../../answerContext';
import {
  isAdmin,
  isInquiryManager,
} from '../../../../../../../helpers/isPermission';
import { showRequirement } from '../utils';
import { StageContext } from '../../../../../../../contexts/stageContext';

const NotesForAgency = ({ setMenuElement }) => {
  const { newExpertManagerAnswer, expertManagerAnswer } = useAnswerContext();
  const { currentStage } = useContext(StageContext);
  const isFifthStage = currentStage?.stageNumber === 5;

  return (
    <>
      <div className="min-h-[400px] p-3">
        <div className="w-[100%] flex flex-row justify-between items-center">
          <div className="flex flex-row items-center">
            <img
              className="bg-transparent ml-2"
              src={answer}
              alt="strategic planning"
            />
            <h1 className="text-[#292069] font-black text-[20px]">الملاحظات</h1>
          </div>
        </div>

        <div className="w-[100%]  pr-[88px] flex flex-col  items-start overflow-y-auto scrollbar-track-gray-100">
          <div className="w-[87%] mb-6">
            <h2 className="text-[#292069] text-lg font-bold mb-1">
              ملاحظات المدقق:
            </h2>
            <div
              dangerouslySetInnerHTML={{
                __html: newExpertManagerAnswer?.reviewerNoteTemplate || '',
              }}
            ></div>
            <ul className="px-2">
              {expertManagerAnswer?.reviewerRequirementAnswers?.map(
                (requirement, index) => (
                  <li>
                    {showRequirement(requirement)
                      ? requirement.standardRequirementTitle
                      : ''}
                    <ul>
                      {requirement.reviewerSubRequirementAnswers.map(
                        (subRequirement, i) => (
                          <>
                            {(subRequirement.decision == 0 ||
                              !subRequirement.decision) &&
                            subRequirement?.donotApply == false ? (
                              <li className="mx-10">
                                {subRequirement.standardRequirementTitle}
                              </li>
                            ) : (
                              ''
                            )}
                          </>
                        )
                      )}
                    </ul>
                  </li>
                )
              )}
            </ul>
            <div
              dangerouslySetInnerHTML={{
                __html: newExpertManagerAnswer?.reviewerNotContent || '',
              }}
            ></div>
          </div>
          <div className="w-[87%] mb-6">
            <h2 className="text-[#292069] text-lg font-bold mb-1">
              ملاحظات الخبير:
            </h2>
            <div
              dangerouslySetInnerHTML={{
                __html: newExpertManagerAnswer?.expertNoteTemplate,
              }}
            ></div>
            <ul className="px-2">
              {expertManagerAnswer?.expertRequirementAnswers?.map(
                (requirement, index) => (
                  <li>
                    {showRequirement(requirement)
                      ? requirement.standardRequirementTitle
                      : ''}
                    <ul>
                      {requirement.expertSubRequirementAnswers.map(
                        (subRequirement, i) => (
                          <>
                            {(subRequirement.decision == 0 ||
                              !subRequirement.decision) &&
                            subRequirement?.donotApply == false ? (
                              <li className="mx-10">
                                {subRequirement.standardRequirementTitle}
                              </li>
                            ) : (
                              ''
                            )}
                          </>
                        )
                      )}
                    </ul>
                  </li>
                )
              )}
            </ul>
            <div
              dangerouslySetInnerHTML={{
                __html: newExpertManagerAnswer?.expertNotContent || '',
              }}
            ></div>
          </div>
          <div className="w-[87%] mb-6">
            <h2 className="text-[#292069] text-lg font-bold mb-1">
              ملاحظات ضابط الاتصال:
            </h2>
            {/* <div
              dangerouslySetInnerHTML={{
                __html:
                  newExpertAnswer?.pocOfficerNoteForAgency || "لا يوجد ملاحظات",
              }}
            ></div> */}
            <div
              dangerouslySetInnerHTML={{
                __html: newExpertManagerAnswer?.pocOfficerNoteTemplate || '',
              }}
            ></div>
            <ul className="px-2">
              {expertManagerAnswer?.pocOfficerRequirementAnswers?.map(
                (requirement, index) => (
                  <li>
                    {showRequirement(requirement)
                      ? requirement.standardRequirementTitle
                      : ''}
                    <ul>
                      {requirement.pocOfficerSubRequirementAnswers.map(
                        (subRequirement, i) => (
                          <>
                            {(subRequirement.decision == 0 ||
                              !subRequirement.decision) &&
                            subRequirement?.donotApply == false ? (
                              <li className="mx-10">
                                {subRequirement.standardRequirementTitle}
                              </li>
                            ) : (
                              ''
                            )}
                          </>
                        )
                      )}
                    </ul>
                  </li>
                )
              )}
            </ul>
            <div
              dangerouslySetInnerHTML={{
                __html: newExpertManagerAnswer?.pocOfficerNotContent || '',
              }}
            ></div>
            {!expertManagerAnswer?.pocOfficerRequirementAnswers?.length &&
              !expertManagerAnswer?.pocOfficerNoteTemplate &&
              !expertManagerAnswer?.pocOfficerNotContent && (
                <div>لا يوجد ملاحظات</div>
              )}
          </div>
        </div>
      </div>
      {!isAdmin && !isInquiryManager && !isFifthStage && (
        <NextBtn onClick={() => setMenuElement(7)} />
      )}
    </>
  );
};

export default NotesForAgency;
