import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { mailBoxIcon } from '../../assets/icons/Index';
import { logout, notfi } from '../../assets/icons/header';
import { logoutUser, permissionsCheck } from '../../helpers/utils';
import { get, post } from '../../services/Request';
import environment from '../../services/environment';
import StageTimer from './stageTimer';

import NewTooltip from '../../components/NewTooltip';

const NewHeader = () => {
  const history = useHistory();
  const menuRef = useRef(null);

  const { t, i18n } = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [notificationsCount, setNotificationsCount] = useState(0);

  useEffect(() => {
    // getAllNotifications();
    document.addEventListener('click', handleClickOutside, false);

    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, []);

  document.body.dir = i18n.dir();

  function getAllNotifications() {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    get(
      environment.getAllNotifications + '?skipCount=0&maxResultCount=5',
      config,
      (res) => {
        if (res?.status == 200) {
          setNotificationsCount(res?.data?.result?.unreadCount);
        }
      }
    );
  }

  function seenNotification(id) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    let data = {
      id: id,
    };
    // return true
    post(environment.setNotificationAsRead, data, config, (res) => {
      if (res.status == 200) {
        getAllNotifications();
      }
    });
  }

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsMenuOpen(false);
    }
  };

  const getInitials = (fullName) => {
    if (fullName === null) return '- -';
    const initials = fullName
      .split(' ')
      .map((word) => word[0])
      .join(' ')
      .toUpperCase();
    return initials;
  };

  return (
    <div className="flex flex-row justify-between items-center p-5 m-5">
      <div className="flex flex-row items-center gap-4">
        <div className="rounded w-[52px] h-[52px] bg-[#7337AE] flex justify-center items-center">
          <span className="text-white">
            {getInitials(localStorage.getItem('fullName'))}
          </span>
        </div>

        <div
          className="flex flex-col items-start cursor-pointer"
          onClick={() =>
            permissionsCheck('Pages.UserType.POC') ||
            permissionsCheck('Pages.UserType.CIO') ||
            permissionsCheck('Pages.UserType.Delegate')
              ? history.push(`/users/${localStorage.getItem('userId')}/details`)
              : history.push(`/users/${localStorage.getItem('userId')}/details`)
          }
        >
          <span className="text-lg text-black">
            {localStorage.getItem('fullName')}
          </span>
          <span className="text-sm text-[#A1A9C4]">
            {localStorage.getItem('userType')}
          </span>
        </div>
      </div>
      <StageTimer />

      {/* icons */}
      <div className="flex flex-row justify-between gap-4 items-center">
        {permissionsCheck('Pages.MailBox.GetAll') ? (
          <NewTooltip content={'صندوق الرسائل'}>
            <Link to="/mailbox" className="cursor-pointer w-[50px] h-[50px]">
              <img src={mailBoxIcon} alt="Message Icon" />
            </Link>
          </NewTooltip>
        ) : null}

        {/* Notifications icon */}
        <div className="relative inline-block group">
          {notificationsCount > 0 && (
            <span className="-left-1 -top-1 absolute p-[5px] rounded-full bg-emerald-500 flex justify-center items-center text-white">
              <span className="-mt-1">{notificationsCount}</span>
            </span>
          )}
          <NewTooltip content={'الأشعارات'}>
            <div
              onClick={() => history.push('/notifications')}
              className="cursor-pointer w-[50px] h-[50px]"
              // onClick={toggleMenu}
            >
              <img src={notfi} alt="notification Icon" />
            </div>
          </NewTooltip>

          {/*  Notifications Dropdown  */}
          <div
            ref={menuRef}
            className={`notification-menu absolute ${
              isMenuOpen ? 'block' : 'hidden'
            } left-0 w-[509px] max-h-[560px] mt-2 overflow-y-auto  bg-white border border-gray-200 rounded-[20px] shadow-xl  z-50`}
          ></div>
        </div>

        <NewTooltip content={'تسجيل الخروج'}>
          <div
            role="button"
            zIndex={1}
            aria-label="logout"
            className="cursor-pointer w-[50px] h-[50px]"
            onClick={() => {
              logoutUser();
              window.location.reload();
            }}
          >
            <img src={logout} alt="logout Icon" />
          </div>
        </NewTooltip>
      </div>
    </div>
  );
};

export default NewHeader;
