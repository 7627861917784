import HeaderWithBreadcrumbLayout from '../../../../layouts/headerWithBreadcrumbLayout';
// import { standerdsBreadcumbs } from "./content";
import { useQuery } from '@tanstack/react-query';
import { useHistory, useParams } from 'react-router';
import { programsIcon } from '../../../../assets/icons/Index';
import { permissionsCheck } from '../../../../helpers/utils';
import environment from '../../../../services/environment';
import { axiosGetRequest } from '../../../../services/Request';
import StanderdList from './standerdsList';

const StanderdsPage = () => {
  const { surveyId, perspectiveId, interlocutorId, indicatorId } = useParams();
  const history = useHistory();
  const { data: surveyStageData } = useQuery({
    queryKey: ['surveyStage', surveyId],
    queryFn: () => {
      return axiosGetRequest(environment.getSurveyStagesBySuveyId, {
        surveyId: surveyId,
      });
    },
  });

  const standerdsBreadcumbs = [
    {
      icon: programsIcon,
      title: 'استبيان التحول الرقمى',
      url: `/indicators/${indicatorId || 1}`,
    },
    {
      title: 'المناظير',
      url: `/indicators/${indicatorId}/programs/${surveyId}/perspectives`,
    },
    {
      title: 'المحاور',
      url: `/indicators/${indicatorId}/programs/${surveyId}/perspectives/${perspectiveId}/interlocutor`,
    },
    {
      title: 'المعايير',
    },
  ];

  return (
    <HeaderWithBreadcrumbLayout
      breadcrumbArr={standerdsBreadcumbs}
      btnLabel="إضافة معيار"
      handleBtnClick={() =>
        history.push(
          `/indicators/${indicatorId}/programs/${surveyId}/perspectives/${perspectiveId}/interlocutor/${interlocutorId}/standards/add`
        )
      }
      hideBtn={
        permissionsCheck('Pages.Standards.Create')
          ? // &&
            // localStorage.getItem("surveyStageId") ===
            // localStorage.getItem("stageForDashboard")
            !!surveyStageData?.isPublished === true
          : true
      }
    >
      <StanderdList />
    </HeaderWithBreadcrumbLayout>
  );
};

export default StanderdsPage;
