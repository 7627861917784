import React, { useState, useEffect } from 'react';
import { t } from 'i18next';
import { noAnswerDegreeId, useAnswerContext } from '../../answerContext';
import UploadAdapter from '../../../../../../../components/UploadAdapter';
import { DropdownMultiSelect } from '../../../../../../../components';
import { axiosGetRequest } from '../../../../../../../services/Request';
import environment from '../../../../../../../services/environment';
import { showRequirement } from '../utils';
import { extractTextFromHtml } from '../../../../../../../helpers/utils';
import AppEditor from '../../../../../../../components/form/AppEditor';

const ExpertNoteTemplate = () => {
  const { newExpertAnswer, expertAnswer, setNewExpertAnswer, newAgencyAnswer } =
    useAnswerContext();
  const [selectedTemplatesForAgency, setSelectedTemplatesForAgency] =
    useState('');

  const [templateListOptions, setTemplateListOptions] = useState([]);

  useEffect(() => {
    const prevTemplateNote = templateListOptions
      ?.filter((item) => item.value === newExpertAnswer?.templateIdForAgency)
      .at(0);

    newExpertAnswer.selectedTemplatesForAgency =
      prevTemplateNote?.value || null;
    setSelectedTemplatesForAgency(prevTemplateNote);
  }, [newExpertAnswer?.templateIdForAgency, templateListOptions.length]);

  useEffect(() => {
    getAllTemplates();
  }, []);

  const getAllTemplates = async () => {
    const res = await axiosGetRequest(environment.getAllNoteTemplate, {
      AgencyCommitmentLevelsIds:
        newAgencyAnswer?.checkedAgencyDegree?.id == null
          ? noAnswerDegreeId
          : newAgencyAnswer?.checkedAgencyDegree?.id,

      CorrectorCommitmentLevelsIds:
        newExpertAnswer?.checkedExpertDegree?.id == null
          ? noAnswerDegreeId
          : newExpertAnswer?.checkedExpertDegree?.id,
      MaxResultCount: 1000000,
      SkipCount: 0,
    });

    if (res?.success) {
      let newTemplates = [];
      res?.result?.items.forEach((item) => {
        newTemplates.push({
          label: extractTextFromHtml(item.templateContent) || '',
          value: item.id,
        });
      });
      setTemplateListOptions(newTemplates);
    }
  };

  const handleChangeTemplatesForAgency = (value) => {
    newExpertAnswer.selectedTemplatesForAgency = value?.value || null;
    setSelectedTemplatesForAgency(value || []);
  };

  return (
    <>
      <div className="w-[87%] mb-2 ">
        <div className="w-[87%] mb-6">
          <h2 className="text-[#292069] text-lg font-bold mb-1">
            ملاحظات الي الجهة:
          </h2>
          <div
            dangerouslySetInnerHTML={{
              __html: newExpertAnswer?.expertNoteTemplate,
            }}
          ></div>
          <ul className="px-2">
            {expertAnswer?.standardRequirements.map((requirement, index) => (
              <li>
                {showRequirement(requirement) ? requirement.title : ''}
                <ul>
                  {requirement.subStandardRequirements.map(
                    (subRequirement, i) => (
                      <>
                        {(subRequirement.decision == 0 ||
                          !subRequirement.decision) &&
                        subRequirement?.donotApply == false ? (
                          <li className="mx-10">{subRequirement.title}</li>
                        ) : (
                          ''
                        )}
                      </>
                    )
                  )}
                </ul>
              </li>
            ))}
          </ul>
          <div
            dangerouslySetInnerHTML={{
              __html: newExpertAnswer?.expertNotContent || 'لا يوجد ملاحظات',
            }}
          ></div>
        </div>

        <div className="my-6">
          <div className="pb-5">
            <DropdownMultiSelect
              isSearchable={true}
              value={selectedTemplatesForAgency}
              handleChange={handleChangeTemplatesForAgency}
              placeholder={t('اختر من القوالب')}
              label={t('قوالب الملاحظات للجهة')}
              options={templateListOptions}
              isMultiple={false}
            />
          </div>
          {!selectedTemplatesForAgency ? null : (
            <div className="mt-2 mb-2">
              <div className="text-[#202969] font-bold text-[15px]">
                القالب الذي تم اختياره{' '}
              </div>
              <div
                className="bg-[#DBE0EF] p-2 rounded-xl mt-2 mb-3"
                dangerouslySetInnerHTML={{
                  __html: selectedTemplatesForAgency.label ?? '',
                }}
              ></div>
            </div>
          )}
          {newExpertAnswer?.standardRequirements.length == 0 ? null : (
            <div className="mt-2 mb-2">
              <div className="text-[#202969] font-bold text-[15px]">
                المتطلبات التي لم يتم اختيارها{' '}
              </div>
              <ul className="p-2">
                {newExpertAnswer?.standardRequirements.map(
                  (requirement, index) => (
                    <li>
                      {showRequirement(requirement) ? requirement.title : ''}
                      <ul>
                        {requirement.subStandardRequirements.map(
                          (subRequirement, i) => (
                            <>
                              {(subRequirement.decision == 0 ||
                                !subRequirement.decision) &&
                              subRequirement?.donotApply == false ? (
                                <li className="mx-10">
                                  {subRequirement.title}
                                </li>
                              ) : (
                                ''
                              )}
                            </>
                          )
                        )}
                      </ul>
                    </li>
                  )
                )}
              </ul>
            </div>
          )}

          <AppEditor
            value={
              // selectedTemplatesForAgency?.label ||
              // newExpertAnswer?.expertSelectedTemplateForAgency
              newExpertAnswer?.expertSelectedTemplateForAgency
            }
            onChange={(event, editor) => {
              const data = editor.getData();

              setNewExpertAnswer({
                ...newExpertAnswer,
                expertSelectedTemplateForAgency: data,
              });

              editor.plugins.get('FileRepository').createUploadAdapter =
                function (loader) {
                  return new UploadAdapter(loader);
                };
            }}
          />
        </div>
      </div>
    </>
  );
};

export default ExpertNoteTemplate;
