import { useState } from 'react';
import DrawerFilter from '../../../../components/DrawerFilter';
import { AppInput, InputLabel } from '../../../../components';

function SurveyResponsesFilter({ setFilterInputs, isExternal }) {
  const initialValues = {
    agencyTitle: '',
    agencyCode: '',
    answerDate: '',
    userName: '',
  };
  const [inputs, setInputs] = useState(initialValues);

  const handleChangeInputs = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  return (
    <div className="flex justify-end mx-2">
      <DrawerFilter
        handleSearch={() => {
          setFilterInputs(inputs);
        }}
        handleClear={() => setInputs(initialValues)}
      >
        {isExternal && (
          <>
            <div className="w-full">
              <AppInput
                name="agencyTitle"
                value={inputs.agencyTitle}
                onChange={handleChangeInputs}
                label={'اسم الجهة'}
                isRequired={false}
              />
            </div>

            <div className="w-full">
              <AppInput
                name="agencyCode"
                value={inputs.agencyCode}
                onChange={handleChangeInputs}
                label={'كود الجهة'}
                isRequired={false}
              />
            </div>
          </>
        )}

        <div className="w-full">
          <InputLabel
            placeholder="dd-mm-yyyy"
            label={'التاريخ'}
            name="answerDate"
            onChange={handleChangeInputs}
            isRequired={false}
            value={inputs.answerDate}
            type="date"
          />
        </div>

        <div className="w-full">
          <AppInput
            name="userName"
            value={inputs.userName}
            onChange={handleChangeInputs}
            label={'اسم المستخدم'}
            isRequired={false}
          />
        </div>
      </DrawerFilter>
    </div>
  );
}

export default SurveyResponsesFilter;
