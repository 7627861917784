import { useState } from 'react';
import { useHistory } from 'react-router';
import { useQuery } from '@tanstack/react-query';
import HeaderWithBreadcrumbLayout from '../../../../layouts/headerWithBreadcrumbLayout/index.jsx';
import NewTable from '../../../../components/NewTable.jsx';
import { programsIcon } from '../../../../assets/icons/Index.js';
import leftArrow from '../../../../assets/icons/leftArrow.svg';
import { axiosGetRequest } from '../../../../services/Request.jsx';
import environment from '../../../../services/environment.jsx';
import { agencyId, isAdmin } from '../../../../helpers/isPermission.js';
import ViewSurveysForAdmin from './ViewSurveysForAdmin.jsx';
import AppBadge from '../../../../components/AppBadge.jsx';
import ViewSurveysFilter from './ViewSurveysFilter.jsx';
import { inPast } from '../../../../helpers/utils.jsx';

const viewSurveysBreadcumbs = [
  {
    icon: programsIcon,
    title: 'الاستبيانات',
    url: '/satisfiction-survey',
  },
];

const sortColumnField = {
  title: 0,
  description: 1,
  startDate: 2,
  endDate: 3,
};

function ViewSurveys() {
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(0);
  const [maxResult, setMaxResult] = useState({ label: '5', value: 5 });
  const [filterInputs, setFilterInputs] = useState({});
  const [sortData, setSortData] = useState({
    sortType: 'asc',
    sortColumn: '',
  });

  const { data: surveys, isLoading: isGettingSurveys } = useQuery({
    queryKey: [
      'GetCurrentPublishedRateSurveyForAnswer',
      filterInputs,
      sortData,
      maxResult.value,
      currentPage,
    ],
    queryFn: () =>
      axiosGetRequest(environment.GetCurrentPublishedRateSurveyForAnswer, {
        surveyId: +localStorage.getItem('surveyId'),
        skipCount: currentPage * maxResult.value,
        maxResultCount: maxResult.value,
        orderType: sortData?.sortType === 'asc' ? '0' : '1',
        orderIndex: sortColumnField[sortData.sortColumn],
        ...filterInputs,
      }),
    select: (res) => res?.result,
  });

  const handleSort = (column, sortDirection) => {
    setSortData({
      sortColumn: column.accessorKey,
      sortType: sortDirection,
    });
  };

  const columns = [
    {
      name: 'اسم الاستبيان',
      center: true,
      accessorKey: 'title',
      id: 'title',
      sortable: true,
      selector: (row) => row.title,
    },
    {
      name: 'الوصف',
      center: true,
      accessorKey: 'description',
      id: 'description',
      sortable: true,
      selector: (row) => row.description,
    },
    {
      name: 'تاريخ البداية',
      center: true,
      accessorKey: 'startDate',
      id: 'startDate',
      sortable: true,
      selector: (row) => new Date(row.startDate).toLocaleDateString(),
    },
    {
      name: 'تاريخ النهاية',
      center: true,
      accessorKey: 'endDate',
      id: 'endDate',
      sortable: true,
      selector: (row) => new Date(row.endDate).toLocaleDateString(),
    },
    {
      name: 'حالة الاستبيان',
      center: true,
      cell: (row) => (
        <div>
          <AppBadge
            badgeColor={!inPast(row?.endDate) ? '#1cc081' : '#808080'}
            size="sm"
          >
            {!inPast(row?.endDate) ? 'نشط' : 'مغلق'}
          </AppBadge>
        </div>
      ),
    },
    {
      name: 'الاجراءات',
      selector: (row) => row.actions,
      header: 'actions',
      accessorKey: 'actions',
      footer: (props) => props.column.actions,
      cell: (row) => (
        <div className="flex gap-2">
          <button
            title="عرض الاجابات"
            className={
              'cursor-pointer w-[40px] h-[40px] transform scale-85 mr-2 rounded-full bg-[#1CC081] text-center'
            }
            onClick={() => {
              history.push(`/satisfiction-survey/${row.id}`);
            }}
          >
            <img src={leftArrow} alt="arrow" className="mx-auto" />
          </button>
        </div>
      ),
    },
  ];

  const handlePageClick = (event) => {
    const newOffset = event.selected;
    setCurrentPage(newOffset);
  };

  return isAdmin ? (
    <ViewSurveysForAdmin />
  ) : (
    <HeaderWithBreadcrumbLayout breadcrumbArr={viewSurveysBreadcumbs}>
      <ViewSurveysFilter setFilterInputs={setFilterInputs} />

      <NewTable
        columns={columns}
        data={surveys?.items.map((item) => item?.rateSurvey) || []}
        loading={isGettingSurveys}
        withMaxResultChange={false}
        pagination={true}
        withExcel={false}
        excelLoading={false}
        handleSort={handleSort}
        defaultSortAsc={sortData.sortType === 'asc'}
        defaultSortFieldId={sortData.sortColumn}
        maxResultValue={maxResult}
        setMaxResultValue={setMaxResult}
        totalPages={Math.ceil(surveys?.totalCount / maxResult.value)}
        onPageChange={handlePageClick}
        currentPage={currentPage}
      />
    </HeaderWithBreadcrumbLayout>
  );
}

export default ViewSurveys;
