import NewHeader from '../../../../layouts/header/NewHeader';
import { DropdownMultiSelect, InputLabel, Title } from '../../../../components';
import { plusLight, programsIcon } from '../../../../assets/icons/Index';
import AppButton from '../../../../components/buttons/appButton';
import { useEffect, useState } from 'react';
import useAxiosGetRequest from '../../../../helpers/customHooks/useAxiosGetRequest';
import environment from '../../../../services/environment';
import { useHistory, useParams } from 'react-router-dom';
import { axiosPutRequest } from '../../../../services/Request';
import { toast } from 'react-toastify';
import { NewForm } from '../../../../components/form/NewForm';
import { ErrorMessage } from 'formik';
import NewSwitch from '../../../../components/NewSwitch';
import { permissionsCheck } from '../../../../helpers/utils';
import NewDropdownMultiSelect from '../../../../components/NewDropDownMultiSelect';
import { useLocation } from 'react-router';

function AgencyQuestions() {
  const history = useHistory();
  const { id } = useParams();

  const { state } = useLocation();

  const [isSub, setIsSub] = useState(state?.isSubAgency || '');
  const [isSeekHelp, setIsSeekHelp] = useState(
    state?.isHasConsultantCompany || ''
  );
  const [dateStyle, setDateStyle] = useState({ border: '2px solid #f4f7fe' });
  const [submitLoading, setSubmitLoading] = useState(false);

  const [date, setDate] = useState(
    state?.agencyEstablishingDate?.slice(0, 10) || ''
  );
  const [employeesNum, setEmployeesNum] = useState(state?.employeesCount || '');
  const [servicesNum, setServicesNum] = useState(state?.servicesCount || '');
  const [budget, setBudget] = useState(
    state?.technologyDepartmentAnnuualBudget || ''
  );
  const [realJobName, setRealJobName] = useState(state?.actualJobTitle || '');
  const [consultingCompany, setConsultingCompany] = useState(
    state?.consultantCompanyName || ''
  );
  const [subscriptionLevel, setSubscriptionLevel] = useState(
    {
      label: state?.digitalTransformationSharingLevelName,
      value: state?.digitalTransformationSharingLevel,
    } || ''
  );
  const [typeOfBeneficiaries, setTypeOfBeneficiaries] = useState(
    state?.agencyBeneficiaries?.map((ele) => {
      return {
        label: ele?.beneficiariesTypeName,
        value: ele?.beneficiariesType,
      };
    }) || []
  );

  const handleChangeDate = (e) => {
    setDate(e.target.value);
  };
  const handleSubscriptionLevelChange = (value) => {
    setSubscriptionLevel(value || '');
  };
  const handletypeOfBeneficiariesChange = (value) => {
    setTypeOfBeneficiaries(value || []);
  };

  // ---------------------------------------------------- //
  const [selectedParentAgency, setSelectedParentAgency] = useState(
    {
      label: state?.parentAgencyName,
      value: state?.parentId,
    } || ''
  );

  const handleAllAgenciesChange = (value) => {
    setSelectedParentAgency(value || '');
  };

  const [getAllAgencies, allAgencies] = useAxiosGetRequest();

  useEffect(() => {
    getAllAgencies(environment.getAllAgencyWithoutPagination);
  }, []);

  let allAgenciesOptions =
    allAgencies?.result &&
    allAgencies?.result?.map((item) => {
      return {
        label: item.nameInArabic || '',
        value: item.id,
        name: item.name || '',
      };
    });

  allAgenciesOptions = allAgenciesOptions?.filter(
    (ele) => ele.value !== state.id
  );

  // ---------------------------------------------------- //

  let body = {
    id: id,
    agencyEstablishingDate: date,
    parentId: isSub ? selectedParentAgency.value : null,
    digitalTransformationSharingLevel: isSub ? subscriptionLevel.value : null,
    beneficiariesTypes: typeOfBeneficiaries?.map((ele) => {
      return { beneficiariesType: ele.value };
    }),
    employeesCount: employeesNum,
    servicesCount: servicesNum,
    technologyDepartmentAnnuualBudget: budget,
    isHasConsultantCompany: isSeekHelp,
    consultantCompanyName: isSeekHelp ? consultingCompany : null,
    actualJobTitle: realJobName,
  };

  const clear = () => {
    setIsSub(false);
    setIsSeekHelp(false);
    setTypeOfBeneficiaries([]);
    setSubscriptionLevel('');
    setConsultingCompany('');
    setRealJobName('');
    setBudget('');
    setEmployeesNum('');
    setDate('');
    setServicesNum('');
    setSelectedParentAgency('');
  };

  const handleSubmit = async () => {
    setSubmitLoading(true);
    const res = await axiosPutRequest(
      environment.updateExtraDataForAgency,
      body
    );
    setSubmitLoading(false);
    if (res?.success) {
      toast.success('تم تعديل بيانات الجهة بنجاح');
      history.push(`/entities/${id}/details`);
    }
  };

  // ---------------------- validation -------------------
  const initialValues = {
    isSub: false,
    isSeekHelp: false,
    date: '',
    employeesNum: '',
    servicesNum: '',
    budget: '',
    realJobName: '',
    consultingCompany: '',
    subscriptionLevel: '',
    typeOfBeneficiaries: [],
    selectedParentAgency: '',
  };

  const validate = () => {
    const errors = {};
    if (!date) {
      errors.date = 'هذا الحقل مطلوب';
    }

    if (new Date(date) >= new Date()) {
      errors.date = 'لابد ان يكون تاريخ انشاء الجهة في الماضي';
    }

    if (isSub) {
      if (!selectedParentAgency)
        errors.selectedParentAgency = 'هذا الحقل مطلوب';
      if (!subscriptionLevel) errors.subscriptionLevel = 'هذا الحقل مطلوب';
    }

    if (!employeesNum) {
      errors.employeesNum = 'هذا الحقل مطلوب';
    }
    if (!servicesNum) {
      errors.servicesNum = 'هذا الحقل مطلوب';
    }
    if (!budget) {
      errors.budget = 'هذا الحقل مطلوب';
    }
    if (!typeOfBeneficiaries.length) {
      errors.typeOfBeneficiaries = 'هذا الحقل مطلوب';
    }
    if (isSeekHelp) {
      if (!consultingCompany) errors.consultingCompany = 'هذا الحقل مطلوب';
    }
    if (permissionsCheck('Pages.UserType.CIO')) {
      if (!realJobName) {
        errors.realJobName = 'هذا الحقل مطلوب';
      }
    }

    return errors;
  };
  // -----------------------------------------------------
  return (
    <div className="bg-white rounded-[30px] m-5 pb-4">
      <NewHeader />
      <Title
        iconTitle={programsIcon}
        title={'تفاصيل الجهة'}
        subTitle={'اسئلة الجهة'}
        toPage={() => history.push(`/entities/${id}/details`)}
        textStyle={{ color: 'white', fontSize: 18 }}
        seconed={true}
        withoutButton
        withIcon={true}
        icon={plusLight}
        style={{ backgroundColor: '#2B2969', width: 200 }}
      />

      <div className="bg-[#F9F9FF] rounded-[30px] p-5 m-5">
        <div className="flex items-center justify-between mb-3">
          <p className="text-lg mb-4 ">الاٍجابة على أسئلة الجهة</p>
        </div>

        <NewForm
          initialValues={initialValues}
          validate={validate}
          onSubmit={handleSubmit}
        >
          <div className="flex flex-col gap-5">
            <div className="bg-white rounded-xl p-6 pb-7 flex flex-col gap-5">
              <div className="flex flex-col md:flex-row gap-4">
                <div className="flex-1 space-y-1">
                  <div className=" flex flex-col space-y-1 ">
                    <label className="label_input text-lg text-[#292069] ">
                      تاريخ تأسيس الجهة
                    </label>

                    <input
                      style={dateStyle}
                      onChange={handleChangeDate}
                      value={date}
                      type="date"
                      name="date"
                      className="text-[14px] text-[#7D8BAB] bg-[#F9F9FF] h-[42px] border-none rounded-3xl py-3 px-5"
                      placeholder="22-10-2023"
                    />
                  </div>
                  <ErrorMessage
                    className="text-red-700"
                    name="date"
                    component="div"
                  />
                </div>
                <div className="flex-1"></div>
              </div>
            </div>

            <div className="bg-white rounded-xl p-6 pb-7 flex flex-col gap-3">
              <div className="flex flex-col gap-5">
                <NewSwitch
                  value={isSub}
                  setValue={setIsSub}
                  label={'هل الجهة فرعية من جهة أخرى؟'}
                />

                {isSub && (
                  <div className="flex flex-col md:flex-row gap-4">
                    <div className="flex-1 space-y-1">
                      <DropdownMultiSelect
                        isClearable={true}
                        withoutLabel={false}
                        isSearchable={true}
                        isMultiple={false}
                        value={selectedParentAgency}
                        options={allAgenciesOptions || []}
                        handleChange={handleAllAgenciesChange}
                        placeholder=""
                        name={'selectedParentAgency'}
                        label={'الجهة الرئيسية'}
                      />
                      <ErrorMessage
                        className="text-red-700"
                        name="selectedParentAgency"
                        component="div"
                      />
                    </div>
                    <div className="flex-1 space-y-1">
                      <DropdownMultiSelect
                        isClearable={true}
                        withoutLabel={false}
                        isSearchable={true}
                        isMultiple={false}
                        name={'subscriptionLevel'}
                        value={subscriptionLevel}
                        options={[
                          {
                            label: 'اشتراك كامل',
                            value: 1,
                          },
                          {
                            label: 'اشتراك جزئي',
                            value: 2,
                          },
                          {
                            label: 'لا اشتراك',
                            value: 3,
                          },
                        ]}
                        handleChange={handleSubscriptionLevelChange}
                        placeholder=""
                        label={'مستوى الاشتراك بأهداف التحول الرقمي'}
                      />
                      <ErrorMessage
                        className="text-red-700"
                        name="subscriptionLevel"
                        component="div"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="bg-white rounded-xl p-6 pb-7 flex flex-col gap-5">
              <div className="flex flex-col md:flex-row gap-4">
                <div className="flex-1 space-y-1">
                  <div className=" flex flex-col gap-3 ">
                    <InputLabel
                      value={employeesNum}
                      type={'number'}
                      onChange={(e) => setEmployeesNum(e.target.value)}
                      name={'employeesNum'}
                      label={'عدد الموظفين'}
                    />
                  </div>
                  <ErrorMessage
                    className="text-red-700"
                    name="employeesNum"
                    component="div"
                  />
                </div>
                <div className="flex-1 space-y-1">
                  <div className=" flex flex-col gap-3 ">
                    <InputLabel
                      value={servicesNum}
                      type={'number'}
                      onChange={(e) => setServicesNum(e.target.value)}
                      name={'servicesNum'}
                      label={'عدد الخدمات'}
                    />
                  </div>
                  <ErrorMessage
                    className="text-red-700"
                    name="servicesNum"
                    component="div"
                  />
                </div>
              </div>

              <div className="flex flex-col md:flex-row gap-4">
                <div className="flex-1 space-y-1">
                  <div className=" flex flex-col gap-3 ">
                    <InputLabel
                      value={budget}
                      type={'number'}
                      onChange={(e) => setBudget(e.target.value)}
                      name={'budget'}
                      label={' الميزانية السنوية لتقنية المعلومات'}
                    />
                  </div>

                  <ErrorMessage
                    className="text-red-700"
                    name="budget"
                    component="div"
                  />
                </div>
                <div className="flex-1 space-y-1">
                  <NewDropdownMultiSelect
                    isClearable={true}
                    withoutLabel={false}
                    isSearchable={true}
                    isMultiple={true}
                    value={typeOfBeneficiaries}
                    options={[
                      {
                        label: 'حكومي',
                        value: 1,
                      },
                      {
                        label: 'أعمال',
                        value: 2,
                      },
                      {
                        label: 'مستهلك',
                        value: 3,
                      },
                    ]}
                    handleChange={handletypeOfBeneficiariesChange}
                    placeholder=""
                    label={'نوع المستفيدين'}
                    name={'typeOfBeneficiaries'}
                  />

                  <ErrorMessage
                    className="text-red-700"
                    name="typeOfBeneficiaries"
                    component="div"
                  />
                </div>
              </div>
            </div>

            <div className="bg-white rounded-xl p-6 pb-7 flex flex-col gap-5">
              <NewSwitch
                value={isSeekHelp}
                setValue={setIsSeekHelp}
                label={'هل الجهة تستعين بشركة استشارية لقياس التحول الرقمي؟'}
              />

              {isSeekHelp && (
                <div className="flex flex-col md:flex-row gap-4">
                  <div className="flex-1 space-y-1">
                    <div className=" flex flex-col gap-3 ">
                      <InputLabel
                        value={consultingCompany}
                        type={'text'}
                        onChange={(e) => setConsultingCompany(e.target.value)}
                        name={'consultingCompany'}
                        label={'اسم الشركة الاستشارية'}
                      />
                    </div>
                    <ErrorMessage
                      className="text-red-700"
                      name="consultingCompany"
                      component="div"
                    />
                  </div>
                  <div className="flex-1"></div>
                </div>
              )}
            </div>

            {permissionsCheck('Pages.UserType.CIO') && (
              <div className="bg-white rounded-xl p-6 pb-7 flex flex-col gap-5">
                <div className="flex flex-col md:flex-row gap-4">
                  <div className="flex-1 space-y-1">
                    <div className=" flex flex-col gap-3 ">
                      <InputLabel
                        value={realJobName}
                        type={'text'}
                        onChange={(e) => setRealJobName(e.target.value)}
                        name={'realJobName'}
                        withoutLabel={false}
                        label={'المسمى الوظيفي الفعلي'}
                      />
                    </div>
                    <ErrorMessage
                      className="text-red-700"
                      name="realJobName"
                      component="div"
                    />
                  </div>

                  <div className="flex-1"></div>
                </div>
              </div>
            )}

            {/*  */}
            <div className="flex gap-5">
              {permissionsCheck('Pages.UserType.Delegate') ? null : (
                <AppButton
                  loading={submitLoading}
                  variant="purple"
                  type="submit"
                >
                  اٍرسال الاٍجابة
                </AppButton>
              )}
              {permissionsCheck('Pages.UserType.Delegate') ? null : (
                <AppButton onClick={clear} variant="danger">
                  اٍلغاء
                </AppButton>
              )}
            </div>
          </div>
        </NewForm>
      </div>
    </div>
  );
}

export default AgencyQuestions;
