import { useState } from 'react';
import AppBadge from '../../../../../components/AppBadge';
import SubTable from './SubTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import NewTooltip from '../../../../../components/NewTooltip';
import AppModal from '../../../../../components/Models/appModal';
import { convertHtmlToString } from '../../../../../helpers/utils';

function AuditorRow({ data }) {
  const [answerNote, setAnswerNote] = useState('');
  const [noteForAgency, setNoteForAgency] = useState({});
  const [openShowAnswerNoteModal, setOpenShowAnswerNoteModal] = useState(false);
  const [openShowNotForAgencyeModal, setOpenShowNotForAgencyeModal] =
    useState(false);

  const [checkedRequirements, setCheckedRequirements] = useState([]);
  const [opencheckedRequirementsModal, setOpencheckedRequirementsModal] =
    useState(false);

  const [uncheckedRequirements, setUncheckedRequirements] = useState([]);
  const [openUncheckedRequirementsModal, setOpenUncheckedRequirementsModal] =
    useState(false);

  const columns = [
    {
      name: 'اسم المدقق',
      sortable: true,
      cell: (info) => (
        <div className="text-[#292069] text-base font-semibold">
          {info.reviewerName}
        </div>
      ),
    },
    {
      name: 'اٍجابة المدقق',
      sortable: true,
      center: true,
      cell: (info) => (
        <div className="text-center w-fit mx-auto">
          {info.reviewerAnswer && (
            <AppBadge badgeColor={info.reviewerAnswerColor}>
              {info.reviewerAnswer}
            </AppBadge>
          )}
        </div>
      ),
    },
    {
      name: 'تاريخ اٍجابة المدقق',
      center: true,
      sortable: true,
      cell: (row) => (
        <div className="text-center">{row.reviewerAnswerDate}</div>
      ),
    },
    {
      name: 'الدرجة المقترحة من النظام',
      center: true,
      sortable: true,
      cell: (row) => (
        <div className="text-center w-fit mx-auto">
          <AppBadge badgeColor={row.reviewerSuggestedDegreeColor}>
            {row.reviewerSuggestedDegree}
          </AppBadge>
        </div>
      ),
    },
    {
      name: 'سبب تغيير الاٍجابة عن النظام',
      sortable: true,
      center: true,
      cell: (row) => (
        <div className="flex justify-center">
          <NewTooltip content={'عرض التفاصيل'}>
            <FontAwesomeIcon
              icon={faEye}
              className="text-sky-400 bg-sky-100 mr-1 rounded-full p-2 cursor-pointer"
              onClick={() => {
                setOpenShowAnswerNoteModal(true);
                setAnswerNote(row.reviewerNote);
              }}
            />
          </NewTooltip>
        </div>
      ),
    },

    {
      name: 'المتطلبات المتحققة',
      center: true,
      sortable: true,
      cell: (row) => (
        <div className="flex justify-center">
          <NewTooltip content={'المتطلبات المتحققة'}>
            <FontAwesomeIcon
              icon={faEye}
              className="text-emerald-400 bg-emerald-100 mr-1 rounded-full p-2 cursor-pointer"
              onClick={() => {
                setOpencheckedRequirementsModal(true);
                setCheckedRequirements(row.reviewerRequirements);
              }}
            />
          </NewTooltip>
        </div>
      ),
    },

    {
      name: 'المتطلبات غير المتحققة',
      center: true,
      sortable: true,
      cell: (row) => (
        <div className="flex justify-center">
          <NewTooltip content={'المتطلبات غير المتحققة'}>
            <FontAwesomeIcon
              icon={faEye}
              className="text-red-400 bg-red-100 mr-1 rounded-full p-2 cursor-pointer"
              onClick={() => {
                setOpenUncheckedRequirementsModal(true);
                setUncheckedRequirements(row.reviewerRequirements);
              }}
            />
          </NewTooltip>
        </div>
      ),
    },

    {
      name: 'الملاحظة',
      sortable: true,
      center: true,
      cell: (row) => (
        <div className="flex justify-center">
          <NewTooltip content={'عرض التفاصيل'}>
            <FontAwesomeIcon
              icon={faEye}
              className="text-orange-400 bg-orange-100 mr-1 rounded-full p-2 cursor-pointer"
              onClick={() => {
                setOpenShowNotForAgencyeModal(true);
                setNoteForAgency(row.reviewerAgencyNote);
              }}
            />
          </NewTooltip>
        </div>
      ),
    },
    {
      name: 'يحتاج زيارة (المدقق)',
      sortable: true,
      center: true,
      cell: (info) => (
        <div className="text-center">
          {info.reviewerNeedVisit ? 'نعم' : 'لا'}
        </div>
      ),
    },
  ];

  const renderdAgencyNote =
    (noteForAgency.noteTemplate?.templateContent || '') +
    (noteForAgency.unCheckRequirement || '') +
    (noteForAgency.content || '') +
    (noteForAgency.template || '');

  return (
    <>
      <SubTable data={data} columns={columns} headerColor="#3c92dc" />

      <AppModal
        open={openShowAnswerNoteModal}
        setOpen={setOpenShowAnswerNoteModal}
        headerTitle={'سبب تغيير الاٍجابة عن النظام'}
        isFooter={false}
      >
        <div className="text-xl h-full pb-2">{answerNote}</div>
      </AppModal>

      <AppModal
        open={openShowNotForAgencyeModal}
        setOpen={setOpenShowNotForAgencyeModal}
        headerTitle={'الملاحظة'}
        isFooter={false}
      >
        <div className="text-xl h-full pb-2 flex flex-col gap-2">
          <div dangerouslySetInnerHTML={{ __html: renderdAgencyNote }}></div>
        </div>
      </AppModal>

      <AppModal
        open={opencheckedRequirementsModal}
        setOpen={setOpencheckedRequirementsModal}
        headerTitle={'المتطلبات المتحققة'}
        isFooter={false}
      >
        <div className="text-xl h-full pb-2 flex flex-col gap-2">
          <ol>
            {checkedRequirements?.map((checkedRequirement, index) => (
              <li>
                {checkedRequirement?.decision && !checkedRequirement?.donotApply
                  ? checkedRequirement?.standardRequirementTitle
                  : '-'}
                <ol className="ps-5">
                  {checkedRequirement?.reviewerSubRequirementAnswers?.map(
                    (subRequirement) => (
                      <li>
                        {subRequirement?.decision && !subRequirement?.donotApply
                          ? subRequirement?.subStandardRequirementTitle
                          : '-'}
                      </li>
                    )
                  )}
                </ol>
              </li>
            ))}
          </ol>
        </div>
      </AppModal>

      <AppModal
        open={openUncheckedRequirementsModal}
        setOpen={setOpenUncheckedRequirementsModal}
        headerTitle={'المتطلبات غير المتحققة'}
        isFooter={false}
      >
        <div className="text-xl h-full pb-2 flex flex-col gap-2">
          {uncheckedRequirements?.map((uncheckedRequirement) => (
            <ol>
              <li>
                {!uncheckedRequirement?.decision &&
                !uncheckedRequirement?.donotApply
                  ? uncheckedRequirement?.standardRequirementTitle
                  : '-'}
                <ol className="ps-5">
                  {uncheckedRequirement?.reviewerSubRequirementAnswers?.map(
                    (subRequirement) => (
                      <li>
                        {!subRequirement?.decision &&
                        !subRequirement?.donotApply
                          ? subRequirement?.subStandardRequirementTitle
                          : '-'}
                      </li>
                    )
                  )}
                </ol>
              </li>
            </ol>
          ))}
        </div>
      </AppModal>
    </>
  );
}

export default AuditorRow;
