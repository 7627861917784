import {
  interlocator,
  prespictive,
  standards,
} from '../../assets/icons/dashboard';
import DashboardStats from '../../components/dashboard/DashboardStats';
import { useDashboardContext } from '../../contexts/DashboardContext';

function AuditorExpertDashboardStatsPhase4() {
  const { auditorsDashboardStats, loading } = useDashboardContext();

  const allStandardsCount =
    auditorsDashboardStats?.finishedStandardsCount +
    auditorsDashboardStats?.unfinishedStandardsCount;

  const list = [
    {
      id: 1,
      title: 'عدد المناظير',
      number: auditorsDashboardStats?.perspectivesCount,
      icon: prespictive,
      color: '#67d4ff26',
    },
    {
      id: 2,
      title: 'عدد المحاور',
      number: auditorsDashboardStats?.interlocutorsCount,
      icon: interlocator,
      color: '#25f81e26',
    },
    {
      id: 3,
      title: 'عدد المعايير الكلية',
      number: allStandardsCount,
      icon: standards,
      color: '#0046de25',
    },
    {
      id: 4,
      title: 'عدد المعايير الكلية المدققة',
      number: auditorsDashboardStats?.finishedStandardsCount,
      icon: standards,
      color: '#a6edff8f',
    },
    {
      id: 5,
      title: 'عدد المعايير الكلية المتبقية',
      number: auditorsDashboardStats?.unfinishedStandardsCount,
      icon: standards,
      color: '#ffa21726',
    },
  ];

  return (
    <>
      <DashboardStats lists={list} loading={loading} />;
    </>
  );
}

export default AuditorExpertDashboardStatsPhase4;
