import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';
import { useHistory } from 'react-router-dom';
import Skeleton from 'react-skeleton-loader';
import { toast } from 'react-toastify';
import { programsIcon, search } from '../../../assets/icons/Index.js';
import Button from '../../../components/Button.js';
import { Title } from '../../../components/index.js';
import environment from '../../../services/environment.jsx';
import { axiosGetRequest, get, post } from '../../../services/Request.jsx';

import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import table from '../../../assets/icons/table.svg';
import Filter from '../../../components/Filter.jsx';
import NewTable from '../../../components/NewTable.jsx';
import { formatDate, permissionsCheck } from '../../../helpers/utils.jsx';
import NewHeader from '../../../layouts/header/NewHeader.jsx';
import activeBlocks from './../../../assets/icons/activeBlocks.svg';
import Card from './Card.jsx';

const initialInnovativeAnswers = [
  {
    id: 1,
    name: 'النموذج الاول',
    agencyTitle: 'National Competitiveness Center (NCC)',
    creationTime: '18-02-2024',
  },
  {
    id: 2,
    name: 'wakeb',
    agencyTitle: 'Saudi Health Council',
    creationTime: '12-04-2024',
  },
];

const res1 = {
  result: {
    totalCount: 2,
    items: [
      {
        id: 1,
        name: 'النموذج الاول',
        agencyTitle: 'National Competitiveness Center (NCC)',
        creationTime: '02-02-2024',
      },
      {
        id: 2,
        name: 'wakeb',
        agencyTitle: 'Saudi Health Council',
        creationTime: '12-04-2024',
      },
    ],
  },
  targetUrl: null,
  success: true,
  error: null,
  unAuthorizedRequest: false,
  __abp: true,
};

const CreativeModelsList = ({ number }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [message, setMessage] = useState({});
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedEvaluator, setSelectedEvaluator] = useState('');
  const [agencyAnswerId, setAgencyAnswerId] = useState('');

  const [innovativeAnswers, setInnovativeAnswers] = useState(
    initialInnovativeAnswers
  );
  const [totalCounts, setTotalCounts] = useState(0);

  const [selectedEndDate, setSelectedEndDate] = useState('');
  const [selectedStartDate, setSelectedStartDate] = useState('');
  const [selectedEntities, setSelectedEntities] = useState([]);
  const [showMenu, setShowMenu] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [totalPages, setTotalPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(false);
  const [showTable, setShowTable] = useState(true);
  const [openDelete, setOpenDelete] = useState(false);

  function handleMenu() {
    setShowMenu(!showMenu);
  }

  const handleChangeEntitiesFilter = (value) => {
    setSelectedEntities(value);
  };

  const arr = [
    {
      filter: true,
      withoutLabel: false,
      isSearchable: true,
      isMultiple: true,
      isClearable: true,
      label: 'الجهة الحكومية',
      value: 'الحالة',
      placeholder: 'وزارة الحج والعمرة',
      options: [
        { value: 'active', label: 'وزارة الحج والعمرة' },
        { value: 'unactive', label: 'وزارة الحج والعمرة' },
      ],
    },
    {
      filter: true,
      withoutLabel: false,
      isSearchable: true,
      isMultiple: true,
      isClearable: true,
      label: 'الحالة',
      value: 'الحالة',
      placeholder: 'الكل',
      options: [
        {
          value: 1,
          label:
            'استخدام أصول تقنية المعلومات الحكومية و الأجهزةالشخصية التقنية',
        },
        {
          value: 2,
          label:
            'هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على ال...',
        },
        { value: 3, label: 'أرشفة الوثائق والبيانات الإلكترونية' },
      ],
    },
  ];

  useEffect(() => {
    getAll(0);
  }, []);
  useEffect(() => {
    // getAllUsers();
  }, []);

  const handlePageClick = (event) => {
    const newOffset = event.selected;

    setCurrentPage(newOffset);
    getAll(newOffset);
  };
  const getAll = (page) => {
    setInnovativeAnswers([]);
    // debugger;
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    let startDate = '';
    let endDate = '';

    if (selectedStartDate != null) {
      startDate = '&FromDate=' + selectedStartDate;
    }

    if (selectedEndDate != null) {
      endDate = '&ToDate=' + selectedEndDate;
    }

    axiosGetRequest(
      environment.getInnovativeFormAgencyAnswerGetAll +
        '?SkipCount=' +
        page * 6 +
        '&MaxResultCount=' +
        6 +
        '&agencyId=' +
        localStorage.getItem('agencyId'),
      null,
      false
    ).then((res) => {
      if (res.status == 403) {
        setMessage(
          { type: 'error', message: res.data.error.message },
          setOpenConfirmModal(false, setLoading(false))
        );
      } else if (res.status == 500) {
        setMessage(
          { type: 'error', message: res.data.error.message },
          setOpenConfirmModal(false, setLoading(false))
        );
      } else if (res) {
        setTotalPages(res.result.items.length / 6);
        setTotalCounts(res.result.items.length);
        setInnovativeAnswers(res.result.items);

        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    });
  };

  const openModalAssignReviewer = (cardId) => {
    setAgencyAnswerId(cardId, setOpen(true));
  };

  return (
    <div className="bg-white rounded-[30px] m-5">
      <NewHeader />
      <Title
        title={'النماذج الابداعية'}
        withoutButton={
          !permissionsCheck('Pages.InnovativeFormAgencyAnswers.Create')
        }
        titleButton="إضافة نماذج"
        iconTitle={programsIcon}
        subTitle={'النموذج'}
        subTitleSeconed={'تقييم النموذج'}
        single={true}
        titleButtonSeconed="تحميل النموذج الإبداعي"
        onClickSeconed={() => history.push('/creativeModels/form')}
        onClick={() => history.push('/creativeModels/form')}
      />

      <div className="px-5 bg-white rounded-2xl flex flex-col hidden">
        <div className="bg-[#F9F9FF] rounded-xl">
          <div
            className="flex flex-row cursor-pointer justify-between p-8 items-center text-[#292069] text-[18px] transition ease-in-out duration-200"
            onClick={handleMenu}
          >
            تصفية حسب
            <FontAwesomeIcon
              className="text-[#7D8BAB]"
              icon={showMenu ? faAngleDown : faAngleUp}
            />
          </div>
          <div className="bg-white w-[100%] h-[2px] p-0"></div>
          {showMenu && (
            <div className="grid sm:grid-cols-1 py-7 px-10 md:grid-cols-2 lg:grid-cols-4 mb-8">
              {arr.map((element) => (
                <Filter
                  label={element.label}
                  withoutLabel={element.withoutLabel}
                  filter={element.filter}
                  isSearchable={element.isSearchable}
                  isMultiple={element.isMultiple}
                  isClearable={element.isClearable}
                  key={element.label}
                  value={selectedEntities[arr.indexOf(element)]}
                  options={element.options}
                  handleChange={handleChangeEntitiesFilter}
                  placeholder={'-- اختر ' + element.label + ' --'}
                />
              ))}
              <Button
                withIcon={false}
                icon={search}
                style={{
                  padding: 10,
                  borderColor: '#2B2969',
                  marginLeft: 'auto',
                  marginTop: '5px',
                  backgroundColor: '#2B2969',
                  alignSelf: 'left',
                }}
                styleText={{ color: 'white' }}
                title={t('modules.main.users.users.filter.search')}
                onClick={() => {}}
                typeColor="light"
              />
            </div>
          )}
        </div>
      </div>

      <div className="px-5 bg-white rounded-2xl">
        <div className="pt-3"></div>
        {!showTable && loading ? (
          <div className="grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3">
            {innovativeAnswers.map((program, index) => {
              return (
                <Skeleton
                  key={index}
                  animated={true}
                  width={'100%'}
                  height={'200px'}
                  style={{ marginTop: 10 }}
                  color={'#2B296969'}
                  count={1}
                />
              );
            })}
          </div>
        ) : (
          <div>
            {innovativeAnswers.length == 0 ? (
              <div className="flex justify-center items-center">
                <span className="text-lg text-[#2B2969]">
                  {t('لا يوجد نماذج')}
                </span>
              </div>
            ) : (
              <>
                <div className="flex flex-row justify-between items-center">
                  <h3 className="my-8 mx-1">{totalCounts} عنصر بالقائمة</h3>

                  <div className="flex flex-row justify-between items-center hidden">
                    <div
                      onClick={() => setShowTable(true)}
                      className="bg-[#F9F9FF] rounded p-5 cursor-pointer"
                    >
                      <img
                        src={table}
                        alt="table view"
                        className={`${
                          showTable ? 'color-[#292069]' : 'color-[#F9F9FF]'
                        } w-[25px]`}
                      />
                    </div>
                    <div
                      onClick={() => setShowTable(false)}
                      className="bg-[#F9F9FF] rounded p-5 mr-2 ml-2 cursor-pointer"
                    >
                      <img
                        src={activeBlocks}
                        alt="blocks view"
                        className={`${
                          showTable ? 'color-[#F9F9FF]' : 'color-[#292069]'
                        } w-[25px]`}
                      />
                    </div>
                  </div>
                </div>
                {!showTable ? (
                  <div className="grid mt-3 gap-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3">
                    {innovativeAnswers.map((card, index) => (
                      <Card
                        key={card.id}
                        openModal={() => openModalAssignReviewer(card.id)}
                        id={card.id}
                        onClick={() => {}}
                        name={card.agencyTitle}
                        ratio={card.formFinalDegree}
                        date={card.creationTime}
                        reviewed="completed"
                      />
                    ))}
                  </div>
                ) : (
                  <div className="bg-white p-10">
                    <NewTable
                      data={innovativeAnswers}
                      handleRowClick={(data) => {
                        history.push(`creativeCards/${data.id}/details`);
                      }}
                      columns={columns}
                      loading={loading}
                      pagination={false}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        )}
        <div className="pb-5 pt-5">
          <ReactPaginate
            nextLabel={loading == true ? null : t('general.next')}
            onPageChange={handlePageClick}
            initialPage={currentPage}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={totalPages}
            previousLabel={loading == true ? null : t('general.previous')}
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item-previous"
            previousLinkClassName="page-link-previous"
            nextClassName="page-item-next"
            nextLinkClassName="page-link-next"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </div>
      </div>
    </div>
  );
};

export default CreativeModelsList;

const columns = [
  {
    name: 'أسم النموذج',
    accessorKey: 'name',
    selector: (row) => row.name,
    sortable: false,
    cell: (info) => <div className="text-sm text-[#292069]">{info.name}</div>,
  },
  {
    name: 'الوصف',
    accessorKey: 'description',
    selector: (row) => row.description,
    sortable: false,
    cell: (info) => (
      <div className="text-sm text-[#292069]">{info.description}</div>
    ),
  },
  {
    name: 'تاريخ الإطلاق',
    accessorKey: 'launchDate',
    selector: (row) => row.launchDate,
    sortable: false,
    cell: (info) => (
      <div className="text-sm text-[#292069]">
        {isValidStringDate(info.launchDate)
          ? formatDate(new Date(info.launchDate))
          : 'لا يوجد'}
      </div>
    ),
  },
  {
    name: 'الجهة',
    accessorKey: 'agencyTitle',
    selector: (row) => row.agencyTitle,
    sortable: false,
    cell: (info) => (
      <div className="text-sm text-[#292069]">{info.agencyTitle}</div>
    ),
  },
  {
    name: 'نوع الفكرة',
    accessorKey: 'innovativeFormIdeaTypeTitle',
    selector: (row) => row.innovativeFormIdeaTypeTitle,
    sortable: false,
    cell: (info) => (
      <div className="text-sm text-[#292069]">
        {info.innovativeFormIdeaTypeTitle}
      </div>
    ),
  },
  {
    name: 'درجة نضوج الفكرة',
    accessorKey: 'innovativeFormIdeaMaturityTitle',
    selector: (row) => row.innovativeFormIdeaMaturityTitle,
    sortable: false,
    cell: (info) => (
      <div className="text-sm text-[#292069]">
        {info.innovativeFormIdeaMaturityTitle}
      </div>
    ),
  },
  {
    name: 'اثر الفكرة',
    accessorKey: 'ideaImpact',
    selector: (row) => row.ideaImpact,
    sortable: false,
    cell: (info) => (
      <div className="text-sm text-[#292069]">{info.ideaImpact}</div>
    ),
  },
  {
    name: 'اثر مالي',
    accessorKey: 'financialImpact',
    selector: (row) => row.financialImpact,
    sortable: false,
    cell: (info) => (
      <div className="text-sm text-[#292069]">{info.financialImpact}</div>
    ),
  },
];

function isValidStringDate(stringDate) {
  const date = new Date(stringDate);
  return date instanceof Date && !isNaN(date);
}
