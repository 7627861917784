import AdminDashboardCardsPhase2 from './AdminDashboardCardsPhase2';
import AdminDashboardStatsPhase2 from './AdminDashboardStatsPhase2';

function AdminDashboardPhase2() {
  return (
    <div>
      <AdminDashboardStatsPhase2 />
      <AdminDashboardCardsPhase2 />
    </div>
  );
}

export default AdminDashboardPhase2;
