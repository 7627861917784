import { createContext, useContext, useEffect, useState } from 'react';
import { axiosGetRequest } from '../services/Request';
import environment from '../services/environment';

const AccountMangerDashboardContext = createContext();

function AccountMangerDashboardContextProvider({ children }) {
  const [dashboardStats, setDashboardStats] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedAgency, setSelectedAgency] = useState('');
  const [allAgenciesOptions, setAllAgenciesOptions] = useState([]);

  useEffect(() => {
    handleGetAllAgencies();
  }, []);

  async function handleGetAllAgencies() {
    const data = await axiosGetRequest(
      environment.getAllAgencyWithoutPagination
    );

    const agenciesOptions = data?.result?.map((item) => ({
      label: item.nameInArabic || '',
      value: item.id,
      name: item.name || '',
    }));

    setAllAgenciesOptions(agenciesOptions);
    setSelectedAgency(agenciesOptions[0]);
  }

  function handleSelectAgency(selectedAgencyObj) {
    setSelectedAgency(selectedAgencyObj || '');
  }

  return (
    <AccountMangerDashboardContext.Provider
      value={{
        dashboardStats,
        setDashboardStats,
        setLoading,
        loading,

        handleSelectAgency,
        selectedAgency,
        allAgenciesOptions,
      }}
    >
      {children}
    </AccountMangerDashboardContext.Provider>
  );
}

const useAccountMangerDashboardContext = () => {
  const context = useContext(AccountMangerDashboardContext);
  if (!context)
    throw new Error(
      'Calling useAgencyDashboardContext Outside AgencyDashboardContextProvider'
    );
  return context;
};

export {
  useAccountMangerDashboardContext,
  AccountMangerDashboardContextProvider,
};
