import AdminDashboardCardsPhase4 from './AdminDashboardCardsPhase4';
import AdminDashboardStatsPhase4 from './AdminDashboardStatsPhase4';

function AdminDashboardPhase4() {
  return (
    <div>
      <AdminDashboardStatsPhase4 />
      <AdminDashboardCardsPhase4 />
    </div>
  );
}

export default AdminDashboardPhase4;
