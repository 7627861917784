import AppButton from '../../../../../../components/buttons/appButton';
import AppActions from '../../../../../../components/appActions';
import UpdateSurveyModal from './updateSurveyModal';
import { useContext, useState } from 'react';
import AppDeleteItemModal from '../../../../../../components/Models/appDeleteItemModal';
import environment from '../../../../../../services/environment';
import { IndicatorsContext } from '../../../../../../contexts/indicatorsContext';
import { useHistory } from 'react-router-dom';

const IndicatorSurveyItem = ({
  id,
  indicatorId,
  title,
  status,
  interlocutorsCount,
  standardsCount,
  perspectivesCount,
  goPageSetting,
}) => {
  const { removeDeletedIndicatorSurveys } = useContext(IndicatorsContext);
  const [openEditModal, setopenEditModal] = useState(false);
  const [openDeleteModal, setopenDeleteModal] = useState(false);
  const history = useHistory();

  return (
    <div className="w-full">
      <div className="border-[1px] bg-white  shadow hover:shadow-lg border-gray-200 rounded-2xl  p-3 px-5">
        <h3 className="text-blue">{title}</h3>

        <div className="flex justify-between items-end mt-3 mb-1">
          <div className="flex justify-between flex-wrap  gap-x-8 gap-2 mb-1">
            <div className="flex gap-4">
              <p className="text-blue text-sm">عدد المناظير</p>
              <p className=" text-green">{perspectivesCount}</p>
            </div>
            <div className="flex gap-4">
              <p className="text-blue text-sm">عدد المحاور</p>
              <p className=" text-green">{interlocutorsCount}</p>
            </div>
            <div className="flex gap-4">
              <p className="text-blue text-sm">عدد المعايير</p>
              <p className=" text-green">{standardsCount}</p>
            </div>
          </div>

          <div className="flex items-end gap-3">
            <div className="mt-1 flex flex-row">
              <AppActions
                handleEditClick={() => {
                  setopenEditModal(true);
                }}
                handleDeleteClick={() => {
                  setopenDeleteModal(true);
                }}
                handleSettingClick={goPageSetting}
                hideDelete={status == 2}
                hideEdit={status == 2}
                hideSettings={status == 2}
              />
              <AppDeleteItemModal
                id={id}
                modalTitle="حذف استبيان"
                itemTitle={title}
                openModal={openDeleteModal}
                setOpenModal={setopenDeleteModal}
                api={environment.deleteSurvey}
                params={{ id }}
                removeItemFromList={removeDeletedIndicatorSurveys}
              />
              <UpdateSurveyModal
                openModal={openEditModal}
                setOpenModal={setopenEditModal}
                values={{ id, title, indicatorId }}
              />
            </div>
            <div>
              <AppButton
                more
                fullwidth
                onClick={() =>
                  history.push(
                    `/indicators/${indicatorId}/programs/${id}/perspectives`
                  )
                }
              >
                المزيد
              </AppButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndicatorSurveyItem;
