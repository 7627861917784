import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import AppDivider from '../../../../../../components/appDivider';
import { axiosGetRequest } from '../../../../../../services/Request';
import environment from '../../../../../../services/environment';
import AddEquivalentResult from '../addEquivalentResult';
import EquivalentResultItem from './equivalentResultItem';
import EquivalentResultItemSkeleton from './equivalentResultItemSkeleton';

const EquivalentResultList = () => {
  const { surveyId } = useParams();
  const [loading, setLoading] = useState(false);
  const [equivalentResultList, setEquivalentResultList] = useState([]);

  const getEquivalentResultList = async () => {
    setLoading(true);
    const res = await axiosGetRequest(environment.getAllEquivalentResult, {
      surveyId,
      SkipCount: 0,
      MaxResultCount: 1000,
    });
    if (res?.success) {
      setEquivalentResultList(res?.result?.items);
    }
    setLoading(false);
  };

  useEffect(() => {
    getEquivalentResultList();
  }, []);

  const renderedDegreeList = equivalentResultList?.map((item) => (
    <EquivalentResultItem
      {...item}
      key={item.id}
      surveyId={surveyId}
      setEquivalentResultList={setEquivalentResultList}
      equivalentResultList={equivalentResultList}
    />
  ));

  return (
    <>
      <AddEquivalentResult
        equivalentResultList={equivalentResultList}
        setEquivalentResultList={setEquivalentResultList}
      />
      <AppDivider />
      {loading ? (
        <>
          <EquivalentResultItemSkeleton />
          <EquivalentResultItemSkeleton />
        </>
      ) : (
        renderedDegreeList
      )}
    </>
  );
};

export default EquivalentResultList;
