import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import LoadingBar from 'react-top-loading-bar';
import { LogoHeader } from '../../../assets/imgs/Index';
import { FModal } from '../../../components';
import Button from '../../../components/Button';
import CModal from '../../../components/CModal';
import ECard from '../../../components/ECard';
import Input from '../../../components/Input';
import InputLabel from '../../../components/InputLabel';
import PCard from '../../../components/PCard';
import SModal from '../../../components/SModal';
import Title from '../../../components/Title';
import useText from '../../../helpers/customHooks/useText';
import environment from '../../../services/environment';
import { get, post, put, remove } from '../../../services/Request';
import { useTranslation } from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import Skeleton from 'react-skeleton-loader';
import { plusLight } from '../../../assets/icons/Index';
import NewHeader from '../../../layouts/header/NewHeader';
const InnovativeFormIdeaMaturity = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(0);
  const [values, handleChange] = useText();
  const [role, setRole] = useState('');
  const [message, setMessage] = useState({});
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const [openDelete, setOpenDelete] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);

  const [departments, setDepartments] = useState([]);
  const [department, setDepartment] = useState('');

  const [ids, setIds] = useState([]);

  const handleCheckbox = (event) => {
    var updatedAbilities = [...ids];
    if (event.target.checked) {
      updatedAbilities = [...ids, event.target.value];
    } else {
      updatedAbilities = updatedAbilities.filter(
        (id) => event.target.value != id
      );
    }
    setIds(updatedAbilities.map((i) => i));
  };

  const isCheckedCheckbox = (id) => {
    return ids.map((i) => i).includes(id);
  };

  useEffect(() => {
    getAllDepartments();
  }, []);
  const getAllDepartments = () => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    get(environment.getInnovativeFormIdeaMaturity, config, (res) => {
      if (res.status == 403) {
        setMessage(
          { type: 'error', message: res.data.error.message },
          setOpenConfirmModal(true)
        );
      } else if (res.status == 500) {
        setMessage(
          { type: 'error', message: res.data.error.message },
          setOpenConfirmModal(true)
        );
      } else if (res.status == 200) {
        setDepartments(res.data.result.items);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    });
  };

  const addDepartment = (values) => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    let data = {
      title: values.title,
      description: values.description,
    };

    post(environment.addnnovativeFormIdeaMaturity, data, config, (res) => {
      if (res.status == 500) {
        setMessage(
          { type: 'error', message: res.data?.error?.message },
          setOpenConfirmModal(
            true,
            setOpenEditModal(
              false,
              setIds([], setLoading(false, getAllDepartments()))
            )
          )
        );
      } else {
        setMessage(
          { type: 'success', message: t('تم اضافة نضج الفكرة بنجاح') },
          setOpenConfirmModal(
            true,
            setOpenEditModal(
              false,
              setIds([], setLoading(false, getAllDepartments()))
            )
          )
        );
      }
    });
  };

  const deleteDepartment = () => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    remove(
      environment.deletennovativeFormIdeaMaturity + '?id=' + department.id,
      config,
      (res) => {
        setMessage(
          { type: 'success', message: t('تم حذف نضج الفكرة بنجاح') },
          setOpenConfirmModal(
            true,
            setOpenDelete(
              false,
              setOpenEditModal(false, setLoading(false, getAllDepartments()))
            )
          )
        );
      }
    );
  };

  const editDepartment = (values) => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    let data = {
      id: department.id,
      title: values.title,
      description: values.description,
    };

    put(environment.editnnovativeFormIdeaMaturity, data, config, (res) => {
      setMessage(
        { type: 'success', message: t('تم تعديل نضج الفكرة بنجاح') },
        setOpenConfirmModal(
          true,
          setOpenEditModal(
            false,
            setOpenEditModal(false, setLoading(false, getAllDepartments()))
          )
        )
      );
    });
  };

  return (
    <div className="bg-white rounded-[30px] m-5">
      <NewHeader />
      <SModal
        type={message.type}
        open={openConfirmModal}
        setOpen={() => setOpenConfirmModal(false)}
        subTitle={message.message}
      />
      <CModal
        confirmModal={() => deleteDepartment()}
        open={openDelete}
        setOpen={() => setOpenDelete(false)}
        subTitle={t('هل انت متاكد من حذف نضج الفكرة') + ' ' + department.title}
      />
      <Title
        title={t('النماذج و البناء')}
        seconed={true}
        onClick={() => setDepartment('', setOpenEditModal(true))}
        subTitle={t('نضج الفكرة')}
        withoutButton={false}
        textStyle={{ color: 'white', fontSize: 18 }}
        titleButton={t('اضافة نضج الفكرة')}
        withIcon={true}
        icon={plusLight}
        style={{ backgroundColor: '#2B2969' }}
      />
      <div className="m-5 p-5 bg-white rounded-xl">
        {loading ? (
          <div className="grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 xl:grid-cols-3">
            {departments.map((departments, index) => (
              <Skeleton
                key={index}
                animated={true}
                width={'100%'}
                height={'100px'}
                style={{ marginTop: 10 }}
                color={'#2B296969'}
                count={1}
              />
            ))}
          </div>
        ) : (
          <div>
            {departments.length == 0 ? (
              <div className="flex justify-center items-center">
                <span className="text-lg text-[#2B2969]">
                  {'لا يوجد نضج الفكرة'}
                </span>
              </div>
            ) : (
              <div className="grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 xl:grid-cols-3 mt-5">
                {departments.map((department, index) => (
                  <PCard
                    openDelete={() =>
                      setDepartment(department, setOpenDelete(true))
                    }
                    openEdit={() =>
                      setDepartment(department, setOpenEditModal(true))
                    }
                    title={department.title}
                  />
                ))}
              </div>
            )}
          </div>
        )}
      </div>
      <FModal
        content={
          <div className="mt-5 bg-white rounded p-5 m-2 w-[100%]">
            <Formik
              initialValues={{
                title: department.title == null ? '' : department.title,
                description:
                  department.description == null ? '' : department.description,
              }}
              validate={(values) => {
                const errors = {};
                if (!values.title) {
                  errors.title = t('اسم نضج الفكرة مطلوب');
                }
                if (!values.description) {
                  errors.description = t('وصف نضج الفكرة مطلوب');
                }
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                if (department.title == null) {
                  addDepartment(values);
                } else {
                  editDepartment(values);
                }
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit}>
                  <InputLabel
                    style={{
                      borderColor: errors.title
                        ? 'red'
                        : values.title != ''
                          ? '#22bb33'
                          : 'transparent',
                    }}
                    label={t('اسم نضج الفكرة')}
                    placeholder={t(
                      'modules.main.users.permissions.form.placeholderTitle'
                    )}
                    type="text"
                    value={values.title}
                    onChange={handleChange}
                    name="title"
                  />
                  <div className="text-sm text-red-600 mt-3">
                    {errors.title && touched.title && errors.title}
                  </div>
                  <InputLabel
                    style={{
                      borderColor: errors.description
                        ? 'red'
                        : values.description != ''
                          ? '#22bb33'
                          : 'transparent',
                      resize: 'none',
                    }}
                    rows={6}
                    type={'textarea'}
                    value={values.description}
                    onChange={handleChange}
                    name={'description'}
                    label={t('وصف نضج الفكرة')}
                    placeholder={t('ادخل وصف نضج الفكرة')}
                  />
                  <div className="text-sm text-red-600 mt-3">
                    {errors.description &&
                      touched.description &&
                      errors.description}
                  </div>

                  <div className="text-center">
                    <Button
                      type={'submit'}
                      loading={loading}
                      withIcon={true}
                      icon={plusLight}
                      title={
                        department == ''
                          ? t('اضافة نضج الفكرة')
                          : t('تعديل نضج الفكرة')
                      }
                      typeColor={'dark'}
                      style={{ backgroundColor: '#00114E', width: 160 }}
                    />
                  </div>
                </form>
              )}
            </Formik>
          </div>
        }
        open={openEditModal}
        titleButton={
          department == '' ? t('اضافة نضج الفكرة') : t('تعديل نضج الفكرة')
        }
        widthButton={false}
        width={160}
        confirm={editDepartment}
        setOpen={() => setOpenEditModal(false)}
        headerTitle={
          department == '' ? t('اضافة نضج الفكرة جديد') : t('تعديل نضج الفكرة')
        }
      />
    </div>
  );
};

export default InnovativeFormIdeaMaturity;
