import { useContext } from 'react';
import { AddStandardContext } from '../addStandardContext';
import StandardRequirementItem from './standardRequirementItem';

const StandardRequirementList = ({ disabled }) => {
  const { addFinalValue, selectedRequirementAgenciesForEdit } =
    useContext(AddStandardContext);

  const renderedRequirements =
    addFinalValue.standardRequirements?.length > 0 &&
    addFinalValue.standardRequirements.map((item, index) => (
      <StandardRequirementItem
        key={item.title}
        {...item}
        index={index}
        disabled={disabled}
        selectedRequirementAgenciesForEdit={selectedRequirementAgenciesForEdit}
        number={addFinalValue.standardRequirements?.length - (index + 1) + 1}
      />
    ));

  return <div> {renderedRequirements}</div>;
};

export default StandardRequirementList;
