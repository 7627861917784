import { useTranslation } from 'react-i18next';
import NewHeader from '../../../layouts/header/NewHeader';
import {
  DropdownMultiSelect,
  InputLabel,
  SModal,
  Title,
} from '../../../components';
import { useEffect, useState } from 'react';
import environment from '../../../services/environment';
import { get, post } from '../../../services/Request';
import ticketsIcon from '../../../assets/icons/ticketsIcon.svg';
import { useHistory } from 'react-router-dom';
import UploadAdapter from '../../../components/UploadAdapter';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import AppButton from '../../../components/buttons/appButton';
import { permissionsCheck } from '../../../helpers/utils';
import AppEditor from '../../../components/form/AppEditor';

const NewTicket = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState({});
  const [loading, setLoading] = useState(false);
  const [standardsLoading, setStandardsLoading] = useState(false);
  const [loadingModel, setLoadingModel] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState('');
  const [priority, setPriority] = useState('');
  const [standard, setStandard] = useState('');
  const [files, setFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState('');
  const [categories, setCategories] = useState([]);
  const [priorities, setPriorities] = useState([]);
  const [standards, setStandards] = useState([]);

  const [addLoading, setAddLoading] = useState(false);

  useEffect(() => {
    getAllCategories();
    getAllPriorities();
    getAllStandards();
  }, []);

  const getAllCategories = () => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    get('services/app/Category/GetAll', config, (res) => {
      if (res.status == 403) {
        setMessage({ type: 'error', message: res.data.error.message });
        setOpenModal(true);
        setLoading(false);
      } else if (res.status == 500) {
        setMessage({ type: 'error', message: res.data.error.message });
        setOpenModal(true);
        setLoading(false);
      } else if (res.status == 200) {
        if (res.data.result.items && res.data.result.items.length > 0) {
          var array = [];
          for (var i = 0; i < res.data.result.items.length; i++) {
            array.push({
              value: res.data.result.items[i].id,
              label: res.data.result.items[i].name.toString(),
              isSupported: res.data.result.items[i].isSupportStandard,
              isSelected: false,
            });
          }
          setCategories(array);
        }
        setTimeout(() => {
          setLoading(false);
        }, 500);
      }
    });
  };

  const getAllPriorities = () => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    get(environment.getAllPriorities, config, (res) => {
      if (res.status == 403) {
        setMessage({ type: 'error', message: res.data.error.message });
        setOpenModal(true);
        setLoading(false);
      } else if (res.status == 500) {
        setMessage({ type: 'error', message: res.data.error.message });
        setOpenModal(true);
        setLoading(false);
      } else if (res.status == 200) {
        if (res.data.result.items && res.data.result.items.length > 0) {
          var array = [];
          for (var i = 0; i < res.data.result.items.length; i++) {
            array.push({
              value: res.data.result.items[i].id,
              label: res.data.result.items[i].name,
              isSelected: false,
            });
          }
          setPriorities(array);
        }
        setTimeout(() => {
          setLoading(false);
        }, 500);
      }
    });
  };

  const getAllStandards = () => {
    setStandardsLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    get(
      `${
        environment.getStandards
      }?MaxResultCount=1000&SurveyId=${localStorage.getItem('surveyId')}`,
      config,
      (res) => {
        setStandardsLoading(false);
        if (res.status == 403) {
          setMessage({ type: 'error', message: res.data.error.message });
          setOpenModal(true);
        } else if (res.status == 500) {
          setMessage({ type: 'error', message: res.data.error.message });
          setOpenModal(true);
        } else if (res.status == 200) {
          if (res.data.result.items && res.data.result.items.length > 0) {
            var array = [];
            for (var i = 0; i < res.data.result.items.length; i++) {
              array.push({
                value: res.data.result.items[i].id,
                label: res.data.result.items[i].code,
                isSelected: false,
              });
            }
            setStandards(array);
          }
        }
      }
    );
  };

  const onChangeFile = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleChangeCategory = (value) => {
    setCategory(value);
  };

  const handleChangePriority = (value) => {
    setPriority(value);
  };

  const handleChangeStandard = (value) => {
    setStandard(value);
  };

  const sendTicket = (values, attachmentId) => {
    setAddLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    let data = {
      description: description,
      categoryId: category.value,
      subject: values.subject,
      attachmentId: attachmentId,
      priorityId: priority.value,
      standardId: standard.value,
    };
    post(environment.createTicket, data, config, (res) => {
      setAddLoading(false);
      if (res.status == 403) {
        toast.error(res?.data?.error?.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      } else if (res.status == 500) {
        toast.error(res?.data?.error?.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      } else if (res.status == 200) {
        toast.success(t('تم اٍرسال الاستفسار بنجاح'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
        history.push('/tickets');
      }
    });
  };

  const onFileUpload = (values) => {
    if (!selectedFile) return;
    setAddLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    const formData = new FormData();
    formData.append('Name', selectedFile.name);
    formData.append('FileType', 'image/*');
    formData.append('EntityType', '');
    formData.append('File', selectedFile);
    post(environment.attachmentFile, formData, config, (res) => {
      setAddLoading(false);
      sendTicket(values, res.data.result.id);
    });
  };

  return (
    <div className="bg-white rounded-[30px] m-5">
      <NewHeader />
      <Title
        style={{ backgroundColor: '#2B2969' }}
        single={true}
        withIcon={true}
        title={t('modules.main.tickets.newTicket')}
        iconTitle={ticketsIcon}
        withoutButton={true}
      />
      <SModal
        type={message.type}
        open={openModal}
        setOpen={() => setOpenModal(false)}
        subTitle={message.message}
      />
      <div className="w-full">
        <Formik
          initialValues={{
            category: '',
            priority: '',
            subject: '',
            standardId: '',
          }}
          validate={(values) => {
            const errors = {};
            if (!values.subject) {
              errors.subject = t('modules.main.mailbox.subjectRequired');
            }
            if (!category.value) {
              errors.category = t('يجب اختيار فئة');
            }
            if (!priority.value) {
              errors.priority = t('يجب اختيار اولوية');
            }
            if (category.value == 3 && !standard.value) {
              errors.standardId = t('يجب اختيار معيار');
            }
            return errors;
          }}
          onSubmit={(values) => {
            !selectedFile ? sendTicket(values, null) : onFileUpload(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="w-full">
                <div className="mb-2 w-full flex flex-row items-center justify-between">
                  <div className="p-5 w-6/12">
                    <label className="label_input text-lg text-[#0D062D] mb-3 w-11/12">
                      {t('modules.main.tickets.category')}
                    </label>
                    <DropdownMultiSelect
                      withoutLabel={true}
                      isSearchable={true}
                      value={category}
                      handleChange={handleChangeCategory}
                      placeholder={t('modules.main.tickets.category')}
                      label={t('modules.main.tickets.category')}
                      options={categories}
                      isMultiple={false}
                      name="category"
                      style={{
                        borderColor: errors.category
                          ? 'red'
                          : values.category != ''
                            ? '#22bb33'
                            : '',
                      }}
                    />
                    <div className="text-sm text-red-600 mt-3">
                      {errors.category && touched.category && errors.category}
                    </div>
                  </div>
                  <div className="p-5 w-6/12">
                    <label className="label_input text-lg text-[#0D062D] mb-3 w-11/12">
                      {t('modules.main.tickets.priority')}
                    </label>
                    <DropdownMultiSelect
                      withoutLabel={true}
                      isSearchable={true}
                      value={priority}
                      handleChange={handleChangePriority}
                      placeholder={t('modules.main.tickets.priority')}
                      label={t('modules.main.tickets.priority')}
                      options={priorities}
                      isMultiple={false}
                      name="priority"
                      style={{
                        borderColor: errors.priority
                          ? 'red'
                          : values.priority != ''
                            ? '#22bb33'
                            : '',
                      }}
                    />
                    <div className="text-sm text-red-600 mt-3">
                      {errors.priority && touched.priority && errors.priority}
                    </div>
                  </div>
                </div>
                <div className="mb-2 w-full flex flex-row items-center justify-between">
                  {category && category.isSupported == true && (
                    <div className="p-5 w-6/12">
                      <label className="label_input text-lg text-[#0D062D] mb-3 w-11/12">
                        {t('modules.main.tickets.standard')}
                      </label>
                      <DropdownMultiSelect
                        withoutLabel={true}
                        isSearchable={true}
                        value={standard}
                        handleChange={handleChangeStandard}
                        placeholder={t('modules.main.tickets.standard')}
                        label={t('modules.main.tickets.standard')}
                        options={standards}
                        loading={standardsLoading}
                        isMultiple={false}
                        name="standard"
                        style={{
                          borderColor: errors.standardId
                            ? 'red'
                            : values.standardId != ''
                              ? '#22bb33'
                              : '',
                        }}
                      />
                      <div className="text-sm text-red-600 mt-3">
                        {errors.standardId &&
                          touched.standardId &&
                          errors.standardId}
                      </div>
                    </div>
                  )}
                  <div className="p-5 w-6/12">
                    <InputLabel
                      label={t('modules.main.tickets.subject')}
                      type={'text'}
                      value={values.subject}
                      onChange={handleChange}
                      style={{
                        borderColor: errors.subject
                          ? 'red'
                          : values.subject != ''
                            ? '#22bb33'
                            : '',
                      }}
                      name="subject"
                    />
                    <div className="text-sm text-red-600 mt-3">
                      {errors.subject && touched.subject && errors.subject}
                    </div>
                  </div>
                </div>
                <div className="mb-2 w-full flex flex-col items-center justify-between">
                  <div className="p-5 w-full">
                    <label className="label_input text-lg text-[#0D062D]">
                      {t('modules.main.tickets.description')}
                    </label>
                    <div className=" mt-3">
                      <AppEditor
                        value={description}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setDescription(data);
                          editor.plugins.get(
                            'FileRepository'
                          ).createUploadAdapter = function (loader) {
                            return new UploadAdapter(loader);
                          };
                        }}
                      />
                    </div>
                  </div>
                  <div className="p-5 w-full">
                    <InputLabel
                      style={{ display: 'none' }}
                      fileInputWidth={'100%'}
                      type={'file'}
                      id={'fileUpload'}
                      label={t('modules.main.tickets.file')}
                      isImage={true}
                      multiple={false}
                      onChange={onChangeFile}
                      fileName={selectedFile.name}
                      isRequired={false}
                    />
                  </div>
                </div>

                <div className="w-full flex flex-row justify-end items-center px-5">
                  {permissionsCheck('Pages.Tickets.Create') ? (
                    <AppButton type={'submit'} loading={addLoading}>
                      اٍرسال
                    </AppButton>
                  ) : null}
                  <button
                    className="p-3 m-3 text-white bg-[#B40001] w-[99px] border-none rounded-[30px]"
                    onClick={() => history.push('/tickets')}
                  >
                    {t('modules.main.mailbox.cancel')}
                  </button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default NewTicket;
