import { useQueryClient, useQuery } from '@tanstack/react-query';
import environment from '../../../../services/environment';
import { axiosGetRequest } from '../../../../services/Request';

export const useGetAnswersReport = ({
  currentPage,
  maxResult,
  filterInputs,
}) => {
  const queryClient = useQueryClient();
  const { data, isLoading: isGettingAnswersReport } = useQuery({
    queryKey: [
      'GetAnswersReport',
      currentPage,
      maxResult,
      filterInputs.agencyName,
      filterInputs.standardCode,
      filterInputs.auditorName,
      filterInputs.expertName,
    ],
    queryFn: () =>
      axiosGetRequest(environment.GetAnswersReport, {
        keyword: filterInputs.agencyName,
        StandardCode: filterInputs.standardCode,
        ReviewerName: filterInputs.auditorName,
        ExpertName: filterInputs.expertName,
        SkipCount: currentPage * maxResult,
        MaxResultCount: maxResult,
      }),
  });

  const answersReport = data?.result?.items;
  const totalCount = data?.result?.totalCount;
  const totalPages = Math.ceil(totalCount / maxResult);

  return {
    queryClient,
    answersReport,
    totalCount,
    totalPages,
    data,
    isGettingAnswersReport,
  };
};
