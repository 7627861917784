import { produce } from 'immer';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import Skeleton from 'react-skeleton-loader';
import {
  AppInput,
  DropdownMultiSelect,
  Input,
} from '../../../../../components';
import NewDropdownMultiSelect from '../../../../../components/NewDropDownMultiSelect';
import { handleInputValidation } from '../../../../../helpers/utils';
import { AddStandardContext } from './addStandardContext';
import { addStandardValidations } from './addStandardValidation';

const defaultValues = {
  title: '',
  royalOrdersAndCabinetDecisions: '',
  answerWay: '',

  target: '',
  weight: '',
  standardRelations: '',
  agencyStandards: '',
  commonAgencies: '',
  mainAgency: '',
};

const AddAboutStandard = ({ from, disabled = true }) => {
  const { surveyId } = useParams();
  const [errors, setErrors] = useState(defaultValues);
  const {
    loading,
    getAllStandards,
    standardList,
    selectedAgencies,
    setSelectedAgencies,
    selectedCommonAgencies,
    setSelectedCommonAgencies,
    agencyList,
    getAllAgency,
    addFinalValue,
    setAddFinalValue,
    mainAgency,
    setMainAgency,
    loadingAgenciesList,
    nonReTurnableStandard,
    setNonReTurnableStandard,
    expiryDate,
    setExpiryDate,
  } = useContext(AddStandardContext);

  useEffect(() => {
    getAllStandards(surveyId);
    getAllAgency();
  }, []);

  const validateInput = async (name, value) => {
    const { valid, errMsg } = await handleInputValidation(
      addStandardValidations,
      name,
      value
    );

    if (valid) {
      setErrors({ ...errors, [name]: '' });
    } else if (errMsg) {
      setErrors({ ...errors, [name]: errMsg });
    }
  };

  const agencyListOptions =
    agencyList?.length > 0
      ? agencyList?.map((item) => ({
          label: item.nameInArabic?.toString() || '',
          value: item.id,
          id: item.id,
          agencyId: item.id,
          parentAgencyId: null,
          isParentAgency: false,
        }))
      : [];

  const handleChangeAgencies = async (value) => {
    setSelectedAgencies(value || []);

    const agencies = value?.map((item) => ({
      agencyId: item.id,
      parentAgencyId: null,
      isParentAgency: false,
    }));
    setAddFinalValue(
      produce((draft) => {
        draft.agencyStandards = { ...draft.agencyStandards, agencies };
      })
    );

    const isAllAgenciesSelected =
      value?.filter((item) => item.agencyId == 0) || [];
    setAddFinalValue(
      produce((draft) => {
        if (isAllAgenciesSelected?.length > 0) {
          draft.allAgencies = true;
        } else {
          draft.allAgencies = false;
        }
      })
    );
    await validateInput('agencyStandards', value || []);
  };
  const handleChangeCommonAgencies = async (value) => {
    setSelectedCommonAgencies(value || []);

    if (!value || value?.length == 0) {
      setMainAgency('');
    }

    await validateInput('commonAgencies', value);

    if (mainAgency) {
      let agencies =
        selectedCommonAgencies?.length > 0
          ? selectedCommonAgencies.map((item) => {
              if (item.agencyId == mainAgency?.id) {
                return {
                  agencyId: item.id,
                  parentAgencyId: mainAgency?.id,
                  isParentAgency: false,
                };
              } else {
                return {
                  agencyId: item.id,
                  parentAgencyId: mainAgency?.id,
                  isParentAgency: true,
                };
              }
            })
          : [];

      agencies = agencies?.filter((item) => item.agencyId != 0);

      setAddFinalValue(
        produce((draft) => {
          draft.agencyStandards = {
            ...draft.agencyStandards,
            commonAgencies: agencies,
          };
        })
      );
      setErrors({
        ...errors,
        mainAgency: '',
      });
    }
    if (!mainAgency && (value?.length > 0 || !value)) {
      setErrors({
        ...errors,
        mainAgency: 'يجب اختيار جهة رئيسية للجهات المشتركة فى المعيار',
      });
    } else {
      setErrors({
        ...errors,
        mainAgency: '',
      });
    }
  };

  const handleChangeMainAgencies = async (value) => {
    setMainAgency(value || '');

    await validateInput('mainAgency', value || {});

    if (!value && selectedCommonAgencies?.length > 0) {
      setErrors({
        ...errors,
        mainAgency: 'يجب اختيار جهة رئيسية للجهات المشتركة فى المعيار',
      });
    } else {
      setErrors({
        ...errors,
        mainAgency: '',
      });
    }

    let agencies =
      selectedCommonAgencies?.length > 0
        ? selectedCommonAgencies.map((item) => {
            if (item.agencyId == value?.id) {
              return {
                agencyId: item.id,
                parentAgencyId: value?.id,
                isParentAgency: false,
              };
            } else {
              return {
                agencyId: item.id,
                parentAgencyId: value?.id,
                isParentAgency: true,
              };
            }
          })
        : [];

    agencies = agencies?.filter((item) => item.agencyId != 0);

    setAddFinalValue(
      produce((draft) => {
        draft.agencyStandards = {
          ...draft.agencyStandards,
          commonAgencies: agencies,
        };
      })
    );
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    validateInput(name, value);

    setAddFinalValue(
      produce((draft) => {
        draft[e.target.name] = e.target.value;
        if (e.target.name == 'title') {
          draft.titleArabic = e.target.value;
        }
      })
    );
  };

  function handleNonReTurnableStandard(e) {
    setNonReTurnableStandard(e.target.checked);
    handleInputChange({
      target: {
        name: e.target.name,
        value: e.target.checked,
      },
    });
  }

  function handleExpiryDate(e) {
    setExpiryDate(e.target.value);
    handleInputChange({
      target: {
        name: e.target.name,
        value: e.target.value,
      },
    });
  }

  return (
    <div>
      <h2 className="font-semibold mb-4">عن المعيار</h2>
      <div className="bg-white p-5 pb-7 rounded-lg">
        <AppInput
          variant="gray"
          label="اسم المعيار"
          placeholder="اسم المعيار"
          name="title"
          value={addFinalValue.title}
          onChange={handleInputChange}
          errorMessage={errors.title}
          disabled={disabled}
        />

        <div className="row gap-y-3 mt-3">
          <div className="col-lg-6">
            <AppInput
              variant="gray"
              label="هدف المعيار"
              placeholder="هدف المعيار"
              name="target"
              value={addFinalValue.target}
              onChange={handleInputChange}
              errorMessage={errors.target}
              disabled={disabled}
            />
          </div>
          <div className="col-lg-6">
            {loading ? (
              <Skeleton width="100%" height="40px" count="2" />
            ) : (
              <NewDropdownMultiSelect
                isClearable={true}
                withoutLabel={false}
                isSearchable={true}
                value={selectedAgencies || []}
                handleChange={handleChangeAgencies}
                placeholder="الجهات الحكومية"
                label={'الجهات الحكومية'}
                options={agencyListOptions || []}
                isMultiple={true}
                errorMessage={errors.agencyStandards}
                loading={loadingAgenciesList}
                disabled={disabled}
              />
            )}
          </div>
          {from == 'add' ? null : (
            <div className="col-lg-6">
              <AppInput
                variant="gray"
                label="رقم المعيار"
                placeholder="1.0.0"
                name="code"
                value={addFinalValue.code}
                onChange={handleInputChange}
                errorMessage={errors.code}
                disabled={disabled}
              />
            </div>
          )}

          <div className="col-lg-6">
            {loading ? (
              <Skeleton width="100%" height="40px" count="2" />
            ) : (
              <NewDropdownMultiSelect
                isClearable={true}
                withoutLabel={false}
                isSearchable={true}
                value={selectedCommonAgencies}
                handleChange={handleChangeCommonAgencies}
                placeholder="الجهات المشتركة فى المعيار"
                label={'الجهات المشتركة فى المعيار'}
                options={selectedAgencies || []}
                isMultiple={true}
                isRequired={false}
                errorMessage={errors.commonAgencies}
                disabled={disabled}
              />
            )}
          </div>
          <div className="col-lg-6">
            {loading ? (
              <Skeleton width="100%" height="40px" count="2" />
            ) : (
              <DropdownMultiSelect
                isClearable={true}
                withoutLabel={false}
                isSearchable={true}
                value={mainAgency}
                handleChange={handleChangeMainAgencies}
                placeholder="الجهة الرئيسية"
                label={'الجهة الرئيسية'}
                isRequired={selectedCommonAgencies?.length > 0}
                options={selectedCommonAgencies}
                isMultiple={false}
                errorMessage={errors.mainAgency}
                disabled={disabled}
              />
            )}
          </div>
          <div className="col-lg-6 mb-4">
            {loading ? (
              <Skeleton width="100%" height="40px" count="2" />
            ) : (
              <div className="flex flex-row items-end mb-3 h-full">
                <input
                  className="ml-2 mr-1 bg-[#DBE0EF] border-none rounded-md w-[24px] h-[24px]"
                  type="checkbox"
                  name="nonReTurnableStandard"
                  checked={nonReTurnableStandard}
                  onChange={handleNonReTurnableStandard}
                />
                <label className="text-[16px] w-[85%] mr-3">
                  معيار غير قابل للإرجاع
                </label>
              </div>
            )}
          </div>

          <div className="col-lg-6 mb-4">
            {loading ? (
              <Skeleton width="100%" height="40px" count="2" />
            ) : (
              <div className="flex flex-col items-start mb-3 h-full gap-1 justify-start">
                <label className="flex justify-between items-center label_input text-[#292069] mb-3">
                  تاريخ انتهاء المعيار
                </label>

                <Input
                  style={{ border: '2px solid #f4f7fe' }}
                  onChange={handleExpiryDate}
                  value={expiryDate}
                  type="datetime-local"
                  name={'expiryDate'}
                  className="text-[14px] text-[#7D8BAB] bg-[#F9F9FF] border-none rounded-3xl py-3 px-5"
                  withLabel
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAboutStandard;
