import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

const AppInput = ({
  label,
  name,
  value,
  onChange,
  type = 'text',
  placeholder,
  id,
  errorMessage = '',
  variant = 'white',
  className = '',
  isRequired = true,
  disabled = false,
}) => {
  const inputClasses = classNames(
    className,
    'py-2 border-gray-200 w-full h-12 rounded-full',
    {
      'border-red-600': errorMessage,
      ' bg-gray_light': variant === 'gray',
      ' bg-white': variant === 'white',
    }
  );
  return (
    <div>
      {label && (
        <label
          className="label_input mb-2 block text-lg text-[#292069]"
          htmlFor={id}
        >
          {label}
          {isRequired && (
            <span className="mr-1">
              <FontAwesomeIcon className="w-2 text-red-700" icon={faAsterisk} />
            </span>
          )}
        </label>
      )}
      <input
        className={inputClasses}
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        id={id}
        disabled={disabled}
        spellCheck={true}
      />
      {errorMessage && <p className="text-error">{errorMessage}</p>}
    </div>
  );
};

export default AppInput;
