import './Component.css';
import { Modal } from 'flowbite-react';
import { closeCircleIcon } from '../assets/icons/Index';
import AppButton from './buttons/appButton';

const FModal = ({
  open,
  setOpen,
  content,
  headerTitle,
  action,
  submitLabel,
  loading,
  onClose,
  form,
  type,
  actions = true,
}) => {
  return (
    <Modal
      size="lg"
      dismissible
      position={'center'}
      show={open}
      onClose={() => {
        onClose?.();
        setOpen(false);
      }}
      className="relative flex items-center justify-center sm:pt-[150px] md:pt-[100px] bg-gray-900 bg-opacity-50"
      theme={{
        content: {
          base: 'relative h-full p-4 md:h-auto',
          inner:
            'md:w-[600px] relative rounded-[43px] bg-white shadow dark:bg-gray-700 flex flex-col max-h-[90vh]',
        },
        body: {
          base: 'p-6 flex-1 overflow-auto',
          popup: 'pt-0',
        },
        header: {
          base: 'flex items-start justify-between rounded-t dark:border-gray-600 border-b p-5',
          popup: 'p-2 border-b-0',
          title: 'text-xl font-medium text-gray-900 dark:text-white',
          close: {
            base: 'ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white',
            icon: 'h-5 w-5',
          },
        },
        footer: {
          base: 'flex items-center space-x-2 rounded-b border-gray-200 p-6 dark:border-gray-600',
          popup: 'border-t',
        },
      }}
    >
      <div className=" z-[-5] absolute bg-violet-950 w-[112%] h-[300px] -left-[6%] top-[-80px] rounded-[43px] flex justify-center p-2.5">
        <span className="text-white text-[25px] font-normal">
          {headerTitle}
        </span>

        <button className="absolute left-7 top-5">
          <img
            alt="icon"
            src={closeCircleIcon}
            className="cursor-pointer bg-[#1CC081] rounded-full p-1.5"
            onClick={() => {
              onClose?.();
              setOpen(false);
            }}
          />
        </button>
      </div>

      <div className="z-50">
        <Modal.Body className="rounded-[43px] min-h-[300px] max-h-[400px]">
          <div className="bg-white rounded-[43px] p-3 ">{content}</div>
        </Modal.Body>
        <Modal.Footer className="flex gap-3 justify-end">
          {actions == false ? null : (
            <AppButton
              onClick={() => {
                action();
              }}
              variant="purple"
              type={type}
              form={form}
              loading={loading}
            >
              {submitLabel || 'حفظ'}
            </AppButton>
          )}
          <AppButton
            onClick={() => {
              onClose?.();
              setOpen(false);
            }}
            variant="danger"
          >
            إلغاء
          </AppButton>
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export default FModal;
