import { useState } from 'react';
import NewSwitch from '../../../../../../../components/NewSwitch';
import UploadAdapter from '../../../../../../../components/UploadAdapter';
import { useAnswerContext } from '../../answerContext';
import NextBtn from '../../components/buttons/nextBtn';
import strategicPlanning from './../../../../../../../assets/icons/strategicPlanning.svg';
import AppEditor from '../../../../../../../components/form/AppEditor';

const ExpertVisit = ({ loading, setMenuElement }) => {
  const { newExpertAnswer, setNewExpertAnswer } = useAnswerContext();
  const [isVisit, setIsVisit] = useState(newExpertAnswer.needVisit);
  const [descriptionSiteVisit, setDescriptionSiteVisit] = useState('');

  const handleChangeIsVisit = (value) => {
    setIsVisit(
      value,
      setNewExpertAnswer({ ...newExpertAnswer, needVisit: value })
    );
  };

  return (
    <>
      <div className="min-h-[440px] p-3">
        <div className="w-[100%] flex flex-row justify-between items-center">
          <div className="flex flex-row items-center">
            <img
              className="bg-transparent ml-2"
              src={strategicPlanning}
              alt="strategic planning"
            />
            <h1 className="text-[#292069] font-black text-[20px]">
              زيارة ميدانية
            </h1>
          </div>
        </div>
        <div>
          <div className="bg-white rounded-xl p-6 pb-7 flex flex-col gap-5 rtl">
            <NewSwitch
              value={isVisit}
              setValue={handleChangeIsVisit}
              label={'هل تريد زيارة ميدانية ؟'}
            />

            {isVisit && (
              <AppEditor
                value={descriptionSiteVisit || newExpertAnswer.visitNote}
                onChange={(event, editor) => {
                  const data = editor.getData();

                  setNewExpertAnswer({
                    ...newExpertAnswer,
                    visitNote: editor.getData(),
                  });
                  editor.plugins.get('FileRepository').createUploadAdapter =
                    function (loader) {
                      return new UploadAdapter(loader);
                    };
                }}
              />
            )}
          </div>
        </div>
      </div>

      <NextBtn onClick={() => setMenuElement(6)} />
    </>
  );
};

export default ExpertVisit;
