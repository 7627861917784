import AuditorsCardPhase4 from './cards/AuditorsCard';
import ExpertsCardPhase4 from './cards/ExpertsCard';
import Phase4Standards from './cards/Phase4Standards';
import PocOfficerCardPhase4 from './cards/PocOfficerCard';

const cards = [
  { id: 1, fullWidth: true, component: <AuditorsCardPhase4 /> },
  { id: 2, fullWidth: true, component: <ExpertsCardPhase4 /> },
  { id: 3, fullWidth: true, component: <PocOfficerCardPhase4 /> },
  { id: 4, fullWidth: true, component: <Phase4Standards /> },
];

function AdminDashboardCardsPhase2() {
  return (
    <div className="p-5 grid grid-cols-1 lg:grid-cols-2 gap-5">
      {cards.map((card) => (
        <div
          key={card.id}
          className={card.fullWidth ? 'col-span-2' : 'col-span-2 lg:col-span-1'}
        >
          {card.component}
        </div>
      ))}
    </div>
  );
}

export default AdminDashboardCardsPhase2;
