const GTabs = ({ tabs, setActiveTab, activeTab }) => {
  return (
    <div className="ml-5">
      <div className="w-full bg-white my-5 flex flex-col">
        <div className="w-full flex justify-end"></div>
        <div className="flex flex-col justify-start pr-5 rounded-3xl">
          <div className="flex flex-row">
            {tabs.map((tab, index) => (
              <div
                key={index}
                className={
                  'md:mr-5 mx-3 cursor-pointer flex flex-row items-center justify-center w-[12.5rem] h-12 rounded-t-[20px] ' +
                  (activeTab == index
                    ? 'bg-emerald-500 text-white'
                    : 'text-slate-400 bg-slate-50')
                }
                onClick={() => setActiveTab(index)}
              >
                <button className="text-lg -mt-1 mr-2 text-center">
                  {tab.title}
                </button>
              </div>
            ))}
          </div>
          <div className="p-5 mb-5 bg-slate-50 rounded-xl w-full">
            {tabs.map((tab, index) => (
              <div key={index}>{activeTab == index && tab.content}</div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GTabs;
