import { faFileLines } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import { Badge } from 'flowbite-react';
import { useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import NewTable from '../../../../components/NewTable';
import NewTooltip from '../../../../components/NewTooltip';
import { useDashboardContext } from '../../../../contexts/DashboardContext';
import useExcelExport from '../../../../helpers/customHooks/useExcelExport';
import FlipCard from '../../../../modules/main/home/FlipCard';
import { axiosGetRequest } from '../../../../services/Request';
import environment from '../../../../services/environment';
import { useGetAgenciesStartedAnswering } from '../api/useGetAgenciesStartedAnswering';

const getStandardRatioBadge = (ratio) => {
  let badgeColor = '#3498db';

  if (ratio === 0) {
    badgeColor = '#575757';
  } else if (ratio < 50) {
    badgeColor = '#f05868';
  } else if (ratio < 70) {
    badgeColor = '#ffa92b';
  } else if (ratio === 100) {
    badgeColor = '#1cc182';
  }

  return (
    <Badge
      className="text-white text-[16px] font-medium pb-3 px-5 rounded-xl"
      size="sm"
      style={{ backgroundColor: badgeColor }}
    >
      {`${ratio?.toFixed(2)}%`}
    </Badge>
  );
};

function AgenciesStartedAnswering() {
  ChartJS.register(ArcElement, Tooltip, Legend);
  ChartJS.defaults.font.family = 'DiodrumArabic';

  const [excelLoading, setExcelLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [filterValue, setFilterValue] = useState(null);

  const { includedAgenciesCount } = useDashboardContext();

  const surveyId = localStorage.getItem('surveyId');

  const history = useHistory();

  const columns = [
    {
      name: 'كود الجهة',
      selector: (row) => row.agencyCode,
      header: 'agencyCode',
      accessorKey: 'agencyCode',
      width: '100px',
    },
    {
      name: 'اسم الجهة',
      selector: (row) => row.nameInArabic,
      header: 'nameInArabic',
      accessorKey: 'nameInArabic',
      center: true,
      cell: (row) => <div className="text-center">{row.nameInArabic}</div>,
    },
    {
      name: 'عدد المعايير الموكلة اٍلي الجهة',
      selector: (row) => row.agencyAssignedStandardCount,
      header: 'agencyAssignedStandardCount',
      accessorKey: 'agencyAssignedStandardCount',
      center: true,
    },
    {
      name: 'عدد المعايير المجابة',
      selector: (row) => row.agencyStandardAnswerCount,
      header: 'agencyStandardAnswerCount',
      accessorKey: 'agencyStandardAnswerCount',
      center: true,
    },
    {
      name: 'نسبة الاٍنجاز',
      selector: (row) => row.agencyStandardAnswerRatio,
      header: 'agencyStandardAnswerRatio',
      accessorKey: 'agencyStandardAnswerRatio',
      center: true,
      cell: (row) => getStandardRatioBadge(row.agencyStandardAnswerRatio),
    },
    {
      name: 'عرض الاٍجابات',
      selector: (row) => row.id,
      header: 'id',
      accessorKey: 'id',
      center: true,
      cell: (row) => (
        <NewTooltip content={'عرض الاٍجابات'}>
          <FontAwesomeIcon
            icon={faFileLines}
            className="text-orange-400 bg-orange-100 mr-1 rounded-full p-2 cursor-pointer"
            onClick={() => {
              history.push(`entities/${row.id}/analysis`);
            }}
          />
        </NewTooltip>
      ),
    },
  ];

  const {
    agenciesStartedAnswering,
    agenciesStartedAnsweringCount,
    isGettingAgenciesStartedAnswering,
    totalPages,
  } = useGetAgenciesStartedAnswering({ currentPage, filterValue });

  const handlePageClick = (event) => {
    const newOffset = event.selected;
    setCurrentPage(newOffset);
  };

  // ---------- ChartJS -------------------
  const percentage =
    (agenciesStartedAnsweringCount / includedAgenciesCount) * 100;

  const data = {
    datasets: [
      {
        label: 'جهة',
        data: [percentage, 100 - percentage],
        backgroundColor: ['#373172', '#e1ddff'],
        borderColor: ['#373172', '#e1ddff'],
        cutout: '70%',
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        enabled: false,
      },
      legend: {
        display: false,
      },

      title: {
        display: false,
        text: 'Chart.js Line Chart',
      },
    },
  };
  // ---------- ChartJS -------------------

  const downloadExcel = useExcelExport();

  const handleExcelExport = async () => {
    setExcelLoading(true);
    const res = await axiosGetRequest(environment.getAgenciesStartedAnswering, {
      surveyId: surveyId,
      KeyWord: filterValue,
      SkipCount: 0,
      MaxResultCount: 500,
    });

    setExcelLoading(false);

    const dataForExcel = res?.result?.items?.map((ele) => {
      return {
        'كود الجهة': ele?.agencyCode,
        'اسم الجهة': ele?.nameInArabic,
        'عدد المعايير الموكلة اٍلي الجهة': ele?.agencyAssignedStandardCount,
        'عدد المعايير المجابة': ele?.agencyStandardAnswerCount,
        'نسبة الاٍنجاز': `${ele?.agencyStandardAnswerRatio?.toFixed(2)}%`,
      };
    });

    downloadExcel(`جهات بدأت بحل المعايير`, dataForExcel);
  };

  const handleFilter = (value) => {
    setFilterValue(value);
  };

  return (
    <FlipCard
      title={`جهات بدأت بحل المعايير (${includedAgenciesCount}/${agenciesStartedAnsweringCount})`}
      frontContent={
        <div className="relative h-[400px] w-full flex justify-center items-center">
          <div className="h-[300px]">
            <Doughnut data={data} options={options} />
          </div>

          <div className="absolute font-semibold text-3xl top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            {`${(
              (agenciesStartedAnsweringCount / includedAgenciesCount) *
              100
            ).toFixed(2)}%`}
          </div>

          <div className="absolute right-0 top-0 flex flex-col gap-5">
            <div className="flex gap-3 items-center ">
              <div className="w-3 h-3 bg-[#e1ddff]"></div>
              <p className="text-sm font-semibold whitespace-nowrap -mt-3">
                جهات لم تبدأ بحل المعايير
              </p>
            </div>

            <div className="flex gap-3 items-center ">
              <div className="w-3 h-3 bg-[#373172]"></div>
              <p className="text-sm font-semibold whitespace-nowrap -mt-3">
                جهات بدأت بحل المعايير
              </p>
            </div>
          </div>
        </div>
      }
      backContent={
        <NewTable
          withSearch
          onSearch={handleFilter}
          columns={columns}
          excelLoading={excelLoading}
          withExcel
          onExportExcel={handleExcelExport}
          data={agenciesStartedAnswering || []}
          loading={isGettingAgenciesStartedAnswering}
          pagination={true}
          totalPages={totalPages}
          onPageChange={handlePageClick}
          currentPage={currentPage}
        />
      }
    />
  );
}

export default AgenciesStartedAnswering;
