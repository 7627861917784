const AnswerDegreeItem = ({
  title,
  circleColor = '#fefefe',
  degree,
  range,
}) => {
  return (
    <div className="flex my-1 w-full lg:w-1/2 xl:w-1/3 flex-wrap items-center">
      <span className="text-base text-[#7D8BAB] ml-2"> {title}</span>
      <div className="flex flex-row">
        <div
          style={{
            backgroundColor: circleColor,
          }}
          className={`rounded-full h-[10px] w-[10px] mr-2 ml-2 mt-2`}
        ></div>
        <span className="text-sm text-[#7C32C9]">{degree}</span>
      </div>
      {range && <span className="text-base text-[#292069] ">( {range} )</span>}
    </div>
  );
};

export default AnswerDegreeItem;
