import { useContext } from 'react';
import { AddStandardContext } from '../addStandardContext';
import StandardProofFileItem from './standardProofFileItem';

const StandardProofFilesList = ({ disabled }) => {
  const { addFinalValue } = useContext(AddStandardContext);

  const renderedProofFiles =
    addFinalValue.standardProofs?.length > 0 &&
    addFinalValue.standardProofs?.map((item, index) => (
      <StandardProofFileItem
        key={item.title}
        title={item.title}
        {...item}
        index={index}
        disabled={disabled}
        selectedProofFileAgenciesForEdit={item?.agencyStandardProofs || []}
        selectedStandardProofRequirementsForEdit={
          item?.standardProofRequirements || []
        }
        number={addFinalValue.standardProofs?.length - (index + 1) + 1}
      />
    ));

  return (
    <div>
      {renderedProofFiles}
      {/* <StandardProofFileItem number={1} title="Doc 01" /> */}
    </div>
  );
};

export default StandardProofFilesList;
