import { Badge } from 'flowbite-react';
import { useHistory } from 'react-router-dom';

function AgencyRow({ row }) {
  const history = useHistory();
  return (
    <div className="flex gap-5">
      <div
        className="flex justify-center items-center cursor-pointer"
        onClick={() => history.push(`/entities/${row?.agencyId}/details`)}
      >
        <Badge
          className="text-white font-medium bg-[#1cc081] pb-3 px-5 rounded-xl"
          size="sm"
        >
          {row?.agencyCode}
        </Badge>
      </div>

      <div className="flex flex-col">
        <h2 className="font-semibold">{row?.agencyName}</h2>
        <p className="text-gray-600">عدد ممثلي الجهة : {row?.usersCount}</p>
      </div>
    </div>
  );
}

export default AgencyRow;
