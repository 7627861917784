import { ErrorMessage } from 'formik';
import { t } from 'i18next';
import { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { AppInput, DropdownMultiSelect } from '../../../../../components';
import AppModal from '../../../../../components/Models/appModal';
import { NewForm } from '../../../../../components/form/NewForm';
import AppColorInput from '../../../../../components/form/appColorInput';
import { axiosPutRequest } from '../../../../../services/Request';
import environment from '../../../../../services/environment';
import { commitmentOperatorsOptions } from './constants';

const UpdateEquivalentResultListModal = ({
  id,
  openModal,
  setOpenModal,
  values,
  equivalentResultList,
  setEquivalentResultList,
}) => {
  const [loading, setLoading] = useState(false);
  const [inputs, setInputs] = useState(
    values || {
      id: values.id || '',
      surveyId: values.surveyId || '',
      title: '',
      color: '#000000',
      rangeFrom: '',
      rangeTo: '',
      rangeFromOperator: null,
      rangeToOperator: null,
    }
  );

  const handleInputChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const handleRangeOperatorChange = (val, key) => {
    setInputs({
      ...inputs,
      [key]: val,
    });
  };

  const handleSubmit = async () => {
    setLoading(true);
    const res = await axiosPutRequest(environment.updateEquivalentResult, {
      ...inputs,
      rangeFromOperator: inputs?.rangeFromOperator?.id,
      rangeToOperator: inputs?.rangeToOperator?.id,
    });
    setLoading(false);
    if (res?.success) {
      toast.info('تم تحديث النتيجة بنجاح');

      const editValue = equivalentResultList?.map((item) => {
        if (item.id == id) return { ...item, ...res.result };
        return item;
      });
      setEquivalentResultList(editValue);
      setOpenModal(false);
    }
  };

  // --------------------------------------------- //
  const formRef = useRef(null);

  const handleFormik = () => {
    if (formRef.current) {
      formRef.current?.handleSubmit();
    }
  };

  const initialValues = {
    title: '',
    color: '#000000',
    rangeFrom: '',
    rangeTo: '',
    rangeFromOperator: null,
    rangeToOperator: null,
  };

  const validate = (values) => {
    const errors = {};
    if (!inputs.title) {
      errors.title = 'هذا الحقل مطلوب';
    }
    if (
      +inputs.rangeFrom > +inputs.rangeTo ||
      +inputs.rangeFrom < 0 ||
      +inputs.rangeFrom > 100
    ) {
      errors.rangeFrom =
        'لابد من اٍدخال نسبة من 0 اٍلي 100 وتكون أصغر من (النسبة اٍلي)';
    }
    if (
      +inputs.rangeTo < +inputs.rangeFrom ||
      +inputs.rangeTo < 0 ||
      +inputs.rangeTo > 100
    ) {
      errors.rangeTo =
        'لابد من اٍدخال نسبة من 0 اٍلي 100 وتكون أكبر من (النسبة من)';
    }

    if (!inputs?.rangeFromOperator?.label) {
      errors.rangeFromOperator = 'هذا الحقل مطلوب';
    }
    if (!inputs?.rangeToOperator?.label) {
      errors.rangeToOperator = 'هذا الحقل مطلوب';
    }

    return errors;
  };

  // ----------------------------------------------------- //

  return (
    <AppModal
      headerTitle="تحديث النتيجة"
      open={openModal}
      setOpen={setOpenModal}
      handleSubmit={handleFormik}
      type={'submit'}
      loading={loading}
      submitLabel="تحديث"
    >
      <NewForm
        initialValues={initialValues}
        validate={validate}
        innerRef={formRef}
        onSubmit={handleSubmit}
      >
        <div className="mb-4 w-full">
          <div className="mb-1">
            <AppInput
              id={`title-${0}-${inputs.title}`}
              label={t('النتيجة')}
              variant="gray"
              value={inputs.title}
              name="title"
              onChange={(e) => handleInputChange(e)}
            />
            <ErrorMessage
              className="text-red-700"
              name="title"
              component="div"
            />
          </div>
        </div>
        <div className="mb-4 w-full">
          <div className="mb-1">
            <AppColorInput
              label={t('اللون')}
              value={inputs.color}
              name="color"
              onChange={(e) => handleInputChange(e)}
            />
          </div>
        </div>
        <div className="mb-4 w-full flex flex-row items-end">
          <div className="mb-1 col-md-6">
            <AppInput
              id={`title-${0}-${inputs.title}`}
              label={t('النسبة من')}
              type="number"
              variant="gray"
              value={inputs.rangeFrom}
              name="rangeFrom"
              onChange={(e) => handleInputChange(e)}
            />
            <ErrorMessage
              className="text-red-700"
              name="rangeFrom"
              component="div"
            />
          </div>

          <div className="mb-1 col-md-6">
            <DropdownMultiSelect
              filter={true}
              withoutLabel={false}
              isSearchable={false}
              value={inputs?.rangeFromOperator}
              handleChange={(val) =>
                handleRangeOperatorChange(val, 'rangeFromOperator')
              }
              placeholder={'أختر علامة'}
              options={commitmentOperatorsOptions}
              isMultiple={false}
            />

            <ErrorMessage
              className="text-red-700"
              name="rangeFromOperator"
              component="div"
            />
          </div>
        </div>
        <div className="mb-4 w-full flex flex-row items-end">
          <div className="mb-1 col-md-6">
            <AppInput
              id={`title-${0}-${inputs.title}`}
              label={t('النسبة إلى')}
              type="number"
              variant="gray"
              value={inputs.rangeTo}
              name="rangeTo"
              onChange={(e) => handleInputChange(e)}
            />
            <ErrorMessage
              className="text-red-700"
              name="rangeTo"
              component="div"
            />
          </div>
          <div className="mb-1 col-md-6">
            <DropdownMultiSelect
              filter={true}
              withoutLabel={false}
              isSearchable={false}
              value={inputs?.rangeToOperator}
              handleChange={(val) =>
                handleRangeOperatorChange(val, 'rangeToOperator')
              }
              placeholder={'أختر علامة'}
              options={commitmentOperatorsOptions}
              isMultiple={false}
            />

            <ErrorMessage
              className="text-red-700"
              name="rangeToOperator"
              component="div"
            />
          </div>
        </div>
      </NewForm>
    </AppModal>
  );
};

export default UpdateEquivalentResultListModal;
