import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { programsIcon } from '../../../assets/icons/Index';
import {
  AppInput,
  DropdownMultiSelect,
  Input,
  SModal,
  Title,
} from '../../../components';
import DrawerFilter from '../../../components/DrawerFilter';
import AppModal from '../../../components/Models/appModal';
import NewTable from '../../../components/NewTable';
import {
  formatDate,
  formatTime,
  permissionsCheck,
} from '../../../helpers/utils';
import NewHeader from '../../../layouts/header/NewHeader';
import { axiosPostRequest, get } from '../../../services/Request';
import environment from '../../../services/environment';
import {
  downloadFile,
  generateRandomString,
} from '../features/answersReport/utils';

const ActivityLog = () => {
  const [loading, setLoading] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(null);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [selectedServiceName, setSelectedServiceName] = useState(null);
  const [selectedMethodName, setSelectedMethodName] = useState(null);
  const [userName, setUserName] = useState('');
  const [serviceNames, setServiceNames] = useState([]);
  const [methodNames, setMethodNames] = useState([]);
  const [exportModal, setExportModal] = useState(false);

  // default export from date is 10 days ago
  const defaultExportFromDate = new Date() - 1000 * 60 * 60 * 24 * 10;

  const [excelExtraLoading, setExcelExtraLoading] = useState(false);
  const [exportFromDate, setExportFromDate] = useState(defaultExportFromDate);
  const [exportToDate, setExportToDate] = useState(new Date());

  const handleChangeServiceNames = (value) => {
    setSelectedServiceName(value);
  };
  const handleChangeMethodNames = (value) => {
    setSelectedMethodName(value);
  };

  // ------------------------------------- //
  const [dateInputs, setDateInputs] = useState({
    from: '',
    to: '',
  });

  const handleChangeDate = (e) => {
    setDateInputs({ ...dateInputs, [e.target.name]: e.target.value });
  };
  // ------------------------------------- //

  const getServiceNames = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    get(environment.GetServiceNamesLookUp, config, (res) => {
      if (res.status === 200) {
        setServiceNames(res.data?.result);
      }
    });
  };

  const getMethodNames = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    get(environment.GetMethodNamesLookUp, config, (res) => {
      if (res.status === 200) {
        setMethodNames(res.data?.result);
      }
    });
  };

  useEffect(() => {
    getServiceNames();
    getMethodNames();
  }, []);

  const serviceNamesOptions =
    serviceNames &&
    Object.entries(serviceNames).map(([key, value]) => {
      return { label: value, value: key };
    });

  const serviceMethodOptions =
    methodNames &&
    Object.entries(methodNames).map(([key, value]) => {
      return { label: value, value: key };
    });

  const [logs, setLogs] = useState([]);
  const [message, setMessage] = useState({});

  useEffect(() => {
    getAllLogs(0);
  }, []);

  const getAllLogs = (page) => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    let serviceNameQuery = '';
    if (selectedServiceName?.value) {
      serviceNameQuery = '&ServiceName=' + selectedServiceName.value;
    }

    let startDate = '';
    if (dateInputs.from !== '') {
      startDate = `&StartDate=${dateInputs.from}`;
    }
    let endDate = '';
    if (dateInputs.to !== '') {
      endDate = `&EndDate=${dateInputs.to}`;
    }
    let userNameQuery = '';
    if (userName !== '') {
      userNameQuery = `&UserName=${userName}`;
    }
    let methodNameQuery = '';
    if (selectedMethodName?.value) {
      methodNameQuery = `&MethodName=${selectedMethodName.value}`;
    }

    get(
      `${environment.getActivityLogs}?MaxResultCount=8&SkipCount=${
        page * 8
      }${serviceNameQuery}${startDate}${endDate}${userNameQuery}${methodNameQuery}`,
      config,
      (res) => {
        if (res.status == 403) {
          setMessage(
            { type: 'error', message: 'لا يوجد لديك صلاحيات' },
            setOpenConfirmModal(true)
          );
        } else if (res.status == 500) {
          setMessage(
            {
              type: 'error',
              message: 'هناك مشكلة في الخدمة يجب العودة للدعم الفني',
            },
            setOpenConfirmModal(true)
          );
        } else if (res.status == 200) {
          setTotalPages(Math.ceil(res.data.result.totalCount / 8));
          setLogs(res.data.result.items, setLoading(false));
        }
      }
    );
  };

  const handlePageClick = (event) => {
    const newOffset = event.selected;
    setCurrentPage(newOffset);
    getAllLogs(newOffset);
  };

  const search = () => {
    getAllLogs(0);
  };
  const clear = () => {
    setUserName('');
    setSelectedServiceName(null);
    setSelectedMethodName(null);
    setDateInputs({
      from: '',
      to: '',
    });
  };

  const ActivityList = [
    {
      name: 'التاريخ / الوقت',
      selector: (row) => row.executionTime,
      header: 'التاريخ / الوقت',
      accessorKey: 'executionTime',
      footer: (props) => props.column.id,
      cell: (info) => (
        <div>
          <span className="text-sm text-black">
            {formatDate(new Date(info.executionTime)) +
              ' / ' +
              formatTime(new Date(info.executionTime))}
          </span>
        </div>
      ),
    },
    {
      name: 'عنوان ال IP',
      selector: (row) => row.clientIpAddress,
      header: 'عنوان ال ip',
      accessorKey: 'clientIpAddress',
      center: true,
      footer: (props) => props.column.id,
      cell: (info) => (
        <div>
          <span className="text-sm text-black">{info.clientIpAddress}</span>
        </div>
      ),
    },
    {
      name: 'رقم المستخدم',
      selector: (row) => row.userId,
      header: 'رقم المستخدم',
      accessorKey: 'userId',
      footer: (props) => props.column.id,
      center: true,
      width: '95px',
      cell: (info) => (
        <div>
          <span className="text-sm text-black">{info.userId}</span>
        </div>
      ),
    },
    {
      name: 'الحساب',
      selector: (row) => row.role,
      header: 'الحساب',
      accessorKey: 'role',
      footer: (props) => props.column.id,
      center: true,
      cell: (info) => (
        <div className="py-3">
          <span
            className={`text-center align-baseline inline-flex px-4 py-2 mr-auto items-center font-semibold tracking-wide	 text-sm leading-none rounded-lg bg-[#eff6ff] text-qiyas_blue`}
          >
            {info.role}
          </span>
        </div>
      ),
    },

    {
      name: 'اسم الجهة',
      selector: (row) => row.agencyName,
      header: 'الجهة',
      accessorKey: 'agencyName',
      footer: (props) => props.column.id,
      center: true,
      cell: (info) => (
        <div className="py-3">
          <span
            className={`text-center align-baseline inline-flex px-4 py-2 mr-auto items-center font-semibold tracking-wide	 text-sm leading-none rounded-lg bg-[#eff6ff] text-qiyas_blue`}
          >
            {info.agencyName == '' ? 'لا يوجد' : info.agencyName}
          </span>
        </div>
      ),
    },
    {
      name: 'المستخدم',
      selector: (row) => row.userName,
      header: 'المستخدم',
      accessorKey: 'userName',
      footer: (props) => props.column.id,
      center: true,
      cell: (info) => (
        <div className="flex gap-3">
          <div className="text-sm">
            <div className="font-medium text-gray-700">{info.userName}</div>
            <div className="text-gray-400">{info.emailAddress}</div>
          </div>
        </div>
      ),
    },
    {
      name: 'الهوية',
      selector: (row) => row.iqama,
      header: 'الهوية',
      accessorKey: 'iqama',
      footer: (props) => props.column.id,
      sortable: true,
      width: '200px',
      cell: (info) => <div>{info.iqama == '' ? 'لا يوجد ' : info.iqama}</div>,
    },
    {
      name: 'اسم الخدمة',
      selector: (row) => row.serviceName,
      header: 'اسم الخدمة',
      accessorKey: 'serviceName',
      footer: (props) => props.column.id,
      center: true,
      cell: (info) => (
        <div>
          <span className="text-sm text-[#1276B2]">{info.serviceName}</span>
        </div>
      ),
    },
    {
      name: 'اسم الملف',
      selector: (row) => row.AttachmentFile,
      header: 'اسم الملف',
      accessorKey: 'AttachmentFile',
      footer: (props) => props.column.id,
      center: true,
      cell: (row) => (
        <div className="text-center">
          {row.methodName == 'AttachmentFile'
            ? JSON.parse(row.parameters)?.input?.name != 'undefined'
              ? JSON.parse(row.parameters)?.input?.name
              : '-'
            : '-'}
        </div>
      ),
    },
    {
      name: 'التفاعل',
      selector: (row) => row.methodName,
      header: 'التفاعل',
      accessorKey: 'methodName',
      footer: (props) => props.column.id,
      center: true,
      cell: (info) => (
        <div>
          <span className="text-sm text-[#1276B2]">{info.methodName}</span>
        </div>
      ),
    },
  ];

  function handleExcelExportOpenExportModal() {
    setExportModal(true);
  }

  async function handleExcelExport() {
    setExcelExtraLoading(true);

    const requestId = generateRandomString(10);
    const payload = {
      startDate: exportFromDate,
      endDate: exportToDate,
      requestId,
    };

    // the response will be sent to the event listener
    axiosPostRequest(environment.exportExcelAuditLogs, payload);

    try {
      const delayedPromise = new Promise((resolve, reject) => {
        document.addEventListener('reportReady', (data) => {
          if (data.detail.isSuccess && data.detail.requestId === requestId) {
            resolve(data.detail.result);
          } else if (
            !data.detail.isSuccess &&
            data.detail.requestId === requestId
          ) {
            setExcelExtraLoading(false);
            reject(data.detail);
          }
        });
        // });
      });

      toast.promise(delayedPromise, {
        pending: '...جاري تحميل الملف قد يستغرق بعض الوقت',
        success: 'تم تحميل الملف بنجاح',
        error: 'حدث خطأ أثناء تحميل البيانات',
      });

      const res = await delayedPromise;

      setExcelExtraLoading(false);
      downloadFile(res, 'سجل النشاطات.xlsx');
    } catch (error) {
      setExcelExtraLoading(false);
    }

    setExportModal(false);
  }

  return (
    <div className="bg-white rounded-[30px] m-5">
      <NewHeader />
      <Title
        iconTitle={programsIcon}
        withoutButton={true}
        title={'سجل النشاطات'}
        single={true}
        subTitle={'المستخدمين'}
        textStyle={{ color: 'white', fontSize: 18 }}
        titleButton="إضافة مستخدم"
        style={{ backgroundColor: '#2B2969' }}
      />
      <SModal
        type={message.type}
        open={openConfirmModal}
        setOpen={() => setOpenConfirmModal(false)}
        subTitle={message.message}
      />
      <div className="px-5 ">
        <div className="my-5 mb-7 flex justify-end">
          <DrawerFilter
            handleSearch={() => search()}
            handleClear={() => clear()}
          >
            <div className="w-full">
              <DropdownMultiSelect
                filter={true}
                withoutLabel={false}
                isSearchable={true}
                value={selectedServiceName}
                options={serviceNamesOptions}
                isMultiple={false}
                name="serviceName"
                placeholder=""
                handleChange={handleChangeServiceNames}
                label={'اسم الخدمة'}
              />
            </div>
            <div className="w-full">
              <DropdownMultiSelect
                filter={true}
                withoutLabel={false}
                isSearchable={true}
                value={selectedMethodName}
                options={serviceMethodOptions}
                isMultiple={false}
                name="methodName"
                placeholder=""
                handleChange={handleChangeMethodNames}
                label={'اسم العملية'}
              />
            </div>

            <AppInput
              type={'text'}
              label={'اسم المستخدم'}
              name="userName"
              variant="gray"
              value={userName}
              isRequired={false}
              onChange={(e) => setUserName(e.target.value)}
            />

            <div className="w-full flex gap-3">
              <div className="flex flex-1 flex-col space-y-1 ">
                <label className="label_input text-lg text-[#292069] ">
                  الفترة من:
                </label>

                <input
                  style={{ border: '2px solid #f4f7fe' }}
                  onChange={handleChangeDate}
                  value={dateInputs.from}
                  type="date"
                  name="from"
                  className="text-[14px] text-[#7D8BAB] bg-[#F9F9FF] h-[42px] border-none rounded-3xl py-3 px-5"
                  placeholder="22-10-2023"
                />
              </div>

              <div className="flex flex-1 flex-col space-y-1 ">
                <label className="label_input text-lg text-[#292069] ">
                  اٍلي
                </label>

                <input
                  style={{ border: '2px solid #f4f7fe' }}
                  onChange={handleChangeDate}
                  value={dateInputs.to}
                  type="date"
                  name="to"
                  className="text-[14px] text-[#7D8BAB] bg-[#F9F9FF] h-[42px] border-none rounded-3xl py-3 px-5"
                  placeholder="22-10-2023"
                />
              </div>
            </div>
          </DrawerFilter>
        </div>
        {permissionsCheck('Pages.Administration.AuditLogs') ? (
          <NewTable
            loading={loading}
            columns={ActivityList}
            data={logs}
            pagination={true}
            totalPages={totalPages}
            onPageChange={handlePageClick}
            currentPage={currentPage}
            customExcelLoading={excelExtraLoading}
            withCustomExcelExport={true}
            customExportExcelText="استخراج ملف اكسل"
            onCustomExportExcel={handleExcelExportOpenExportModal}
          />
        ) : (
          <div className="text-[#bb2124] text-xl text-center flex justify-center items-center">
            {'ليس لديك صلاحية لهذا'}
          </div>
        )}
        <AppModal
          open={exportModal}
          setOpen={setExportModal}
          headerTitle=" إستخراج البيانات"
          handleSubmit={() => handleExcelExport()}
          submitLabel="إستخراج"
        >
          <div className="flex flex-col gap-4 p-4">
            <div className="flex items-center content-center">
              <label className="text-[16px] text-[#292069] mb-3">
                من تاريخ: &nbsp;
              </label>
              <Input
                style={{ border: '2px solid #f4f7fe' }}
                onChange={(e) => setExportFromDate(e.target.value)}
                value={exportFromDate}
                type="datetime-local"
                className="text-[14px] text-[#7D8BAB] bg-[#F9F9FF] border-none rounded-3xl py-3 px-5"
              />
            </div>

            <div className="flex items-center content-center">
              <label className="text-[16px] text-[#292069] mb-3">
                الى تاريخ: &nbsp;
              </label>
              <Input
                style={{ border: '2px solid #f4f7fe' }}
                onChange={(e) => {
                  setExportToDate(e.target.value);
                }}
                value={exportToDate}
                type="datetime-local"
                className="text-[14px] text-[#7D8BAB] bg-[#F9F9FF] border-none rounded-3xl py-3 px-5"
              />
            </div>
          </div>
        </AppModal>
      </div>
    </div>
  );
};

export default ActivityLog;
