import { View, Text } from '@react-pdf/renderer';
import { StandardView } from './StandardView';

export function InterlocutorView({ interlocutor, styles }) {
  return (
    <>
      <View style={{ ...styles.tableHead, marginTop: 8 }}>
        <Text style={{ textAlign: 'right', direction: 'rtl' }}>
          {interlocutor.code} {interlocutor.title}
        </Text>
      </View>
      {interlocutor?.standards?.sort(sortByCode).map((standard) => {
        return <StandardView standard={standard} styles={styles} />;
      })}
    </>
  );
}

function sortByCode(a, b) {
  const aNumber = parseFloat(a.code.split('.').join(''));
  const bNumber = parseFloat(b.code.split('.').join(''));

  return aNumber - bNumber;
}
