import { useContext } from 'react';
import { StageContext } from '../../../../../../../contexts/stageContext';
import { useAnswerContext } from '../../answerContext';
import NextBtn from '../../components/buttons/nextBtn';
import { showRequirement } from '../utils';
import answer from './../../../../../../../assets/icons/answer.svg';

const NotesForAgency = ({ setMenuElement }) => {
  const { currentStage } = useContext(StageContext);
  const isFifthStage = currentStage?.stageNumber === 5;
  const { newReviewerAnswer, reviewerAnswer } = useAnswerContext();

  return (
    <>
      <div className="min-h-[440px] p-3">
        <div className="w-[100%] flex flex-row justify-between items-center">
          <div className="flex flex-row items-center">
            <img
              className="bg-transparent ml-2"
              src={answer}
              alt="strategic planning"
            />
            <h1 className="text-[#292069] font-black text-[20px]">الملاحظات</h1>
          </div>
        </div>

        <div className="w-[100%]  pr-[88px] flex flex-col  items-start overflow-y-auto h-[300px] scrollbar scrollbar-thumb-[#292069] scrollbar-track-gray-100">
          <div className="w-[87%] mb-6">
            <h2 className="text-[#292069] text-lg font-bold mb-1">
              ملاحظات المدقق:
            </h2>
            <div
              dangerouslySetInnerHTML={{
                __html: newReviewerAnswer?.reviewerNoteTemplate || '',
              }}
            ></div>
            <ul className="px-2">
              {reviewerAnswer?.reviewerRequirementAnswers?.map(
                (requirement) => (
                  <li>
                    {showRequirement(requirement)
                      ? requirement.standardRequirementTitle
                      : ''}
                    <ul>
                      {requirement?.reviewerSubRequirementAnswers?.map(
                        (subRequirement: any) => (
                          <>
                            {(subRequirement.decision == 0 ||
                              !subRequirement.decision) &&
                            subRequirement?.donotApply == false ? (
                              <li className="mx-10">
                                {subRequirement.standardRequirementTitle}
                              </li>
                            ) : (
                              ''
                            )}
                          </>
                        )
                      )}
                    </ul>
                  </li>
                )
              )}
            </ul>
            <div
              dangerouslySetInnerHTML={{
                __html: newReviewerAnswer?.reviewerNotContent || '',
              }}
            ></div>
            {!reviewerAnswer?.reviewerNoteTemplate &&
              !reviewerAnswer?.reviewerAnswerNote &&
              !reviewerAnswer?.reviewerRequirementAnswers?.length && (
                <div>لا يوجد ملاحظات</div>
              )}
          </div>
          <div className="w-[87%] mb-6">
            <h2 className="text-[#292069] text-lg font-bold mb-1">
              ملاحظات الخبير:
            </h2>
            <div
              dangerouslySetInnerHTML={{
                __html: newReviewerAnswer?.expertNoteTemplate,
              }}
            ></div>
            <ul className="px-2">
              {reviewerAnswer?.expertRequirementAnswers?.map((requirement) => (
                <li>
                  {showRequirement(requirement) ? requirement.title : ''}
                  <ul>
                    {requirement?.expertSubRequirementAnswers?.map(
                      (subRequirement: any) => (
                        <>
                          {(subRequirement.decision == 0 ||
                            !subRequirement.decision) &&
                          subRequirement?.donotApply == false ? (
                            <li className="mx-10">{subRequirement.title}</li>
                          ) : (
                            ''
                          )}
                        </>
                      )
                    )}
                  </ul>
                </li>
              ))}
            </ul>
            <div
              dangerouslySetInnerHTML={{
                __html: newReviewerAnswer?.expertNotContent || '',
              }}
            ></div>
            {!reviewerAnswer?.standardRequirements?.length &&
              !reviewerAnswer?.expertNoteTemplate &&
              !reviewerAnswer?.expertNotContent && <div>لا يوجد ملاحظات</div>}
          </div>
          <div className="w-[87%] mb-6">
            <h2 className="text-[#292069] text-lg font-bold mb-1">
              ملاحظات ضابط الاتصال:
            </h2>
            {/* <div
              dangerouslySetInnerHTML={{
                __html:
                  newExpertAnswer?.pocOfficerNoteForAgency || "لا يوجد ملاحظات",
              }}
            ></div> */}
            <div
              dangerouslySetInnerHTML={{
                __html: newReviewerAnswer?.pocOfficerNoteTemplate || '',
              }}
            ></div>
            <ul className="px-2">
              {reviewerAnswer?.pocOfficerRequirementAnswers?.map(
                (requirement) => (
                  <li>
                    {showRequirement(requirement)
                      ? requirement.standardRequirementTitle
                      : ''}
                    <ul>
                      {requirement?.subStandardRequirements?.map(
                        (subRequirement: any) => (
                          <>
                            {(subRequirement.decision == 0 ||
                              !subRequirement.decision) &&
                            subRequirement?.donotApply == false ? (
                              <li className="mx-10">
                                {subRequirement.standardRequirementTitle}
                              </li>
                            ) : (
                              ''
                            )}
                          </>
                        )
                      )}
                    </ul>
                  </li>
                )
              )}
            </ul>
            <div
              dangerouslySetInnerHTML={{
                __html: newReviewerAnswer?.pocOfficerNotContent || '',
              }}
            ></div>
            {!reviewerAnswer?.pocOfficerNoteTemplate &&
              !newReviewerAnswer?.pocOfficerNotContent &&
              !reviewerAnswer?.standardRequirements?.length && (
                <div>لا يوجد ملاحظات</div>
              )}
          </div>
        </div>
      </div>
      {isFifthStage && <NextBtn onClick={() => setMenuElement(7)} />}
    </>
  );
};

export default NotesForAgency;
