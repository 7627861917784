import { useQueryClient, useQuery } from '@tanstack/react-query';
import environment from '../../../../services/environment';
import { axiosGetRequest } from '../../../../services/Request';
import {
  expertId,
  pocOfficerId,
  reviewerId,
  surveyId,
} from '../../../../helpers/isPermission';
import { StageContext } from '../../../../contexts/stageContext';
import { useContext } from 'react';

export const useGetAuditorExpertPerspectives = ({ currentPage }) => {
  const surveyStageId = localStorage.getItem('surveyStageId');
  const queryClient = useQueryClient();

  const { currentStage } = useContext(StageContext);
  const phase4StageId = currentStage?.stageIds?.[3];

  const { data, isLoading: isGettingAuditorExpertPerspectives } = useQuery({
    queryKey: ['auditorExpertPerspectives', currentPage, surveyStageId],
    queryFn: () =>
      axiosGetRequest(environment.getAuditorExpertPerspectives, {
        SurveyStageId: surveyStageId,
        SurveyId: surveyId(),
        ReviewerId: reviewerId,
        ExpertId: expertId,
        POCOfficerId: pocOfficerId,
        SkipCount: currentPage * 5,
        MaxResultCount: 5,
        StageId: phase4StageId,
      }),
    enabled: surveyStageId != null,
  });

  const perspectives = data?.result?.items;
  const totalCount = data?.result?.totalCount;
  const totalPages = Math.ceil(data?.result?.totalCount / 5);

  return {
    queryClient,
    perspectives,
    totalCount,
    totalPages,
    data,
    isGettingAuditorExpertPerspectives,
  };
};
