import React, { useContext, useEffect, useState } from 'react';
import HeaderWithBreadcrumbLayout from '../../../../../layouts/headerWithBreadcrumbLayout';
import StandardEntitiesList from './standardEntitiesList';
import { standerdEntitesBreadcumbs } from '../content';
import { useParams } from 'react-router-dom';
import TableCardSwitch from '../../../../../components/TableCardSwitch';
import StandardEntitiesTable from './standardEntitiesList/StandardEntitiesTable';
import AppPagination from '../../../../../components/Pagination';
import ReviewedStatusCards from './ReviewedStatusCards';
import { useGetAgenciesByStandardId } from './useGetAgenciesByStandardId';
import TableSearch from '../../../../../components/TableSearch';
import { useLocation } from 'react-router';
import useQueryParams from '../../../../../hooks/useQueryParams';
import { useHistory } from 'react-router-dom';
import {
  AppInput,
  DropdownMultiSelect,
  FModal,
  InputLabel,
} from '../../../../../components';
import AppButton from '../../../../../components/buttons/appButton';
import { getDegrees } from '../standardAnswer/answers/useGetPrevAnswer/usePrevExpertManagerAnswer';
import { axiosGetRequest, post } from '../../../../../services/Request';
import environment from '../../../../../services/environment';
import { toast } from 'react-toastify';
import { StageContext } from '../../../../../contexts/stageContext';
import { useTranslation } from 'react-i18next';
import { permissionsCheck } from '../../../../../helpers/utils';
import NewDropdownMultiSelect from '../../../../../components/NewDropDownMultiSelect';
import DrawerFilter from '../../../../../components/DrawerFilter';
import { useQuery } from '@tanstack/react-query';

const StandardEntities = () => {
  const { surveyId, indicatorId, interlocutorId, perspectiveId, standardId } =
    useParams();
  const history = useHistory();

  const query = useQueryParams();

  const { currentStage } = useContext(StageContext);

  const { t } = useTranslation();

  const [inputPageNum, setInputPageNum] = useState('');
  const [reviewedStatus, setReviewedStatus] = useState(1);
  const [showTable, setShowTable] = useState(true);
  const [filterValue, setFilterValue] = useState(null);
  const [donotApply, setDonotApply] = useState(null);
  const [currentPage, setCurrentPage] = useState(
    Number(query.get('page')) || 0
  );
  const [maxResult, setMaxResult] = useState({ label: '10', value: 10 });
  const [openRequestChangeModal, setOpenRequestChangeModal] = useState(false);
  const [selectedAgencies, setSelectedAgencies] = useState([]);
  const [editRequestReason, setEditRequestReason] = useState('');
  const [selectedCommitmentLevel, setSelectedCommitmentLevel] = useState(null);
  const [reopenStandardChecked, setReopenStandardChecked] = useState(null);
  const [selectedSaveAsDraft, setSelecetSaveAsDraft] = useState(null);

  const [finalCommitmentLevelId, setFinalCommitmentLevelId] = useState(null);
  const [reopened, setReopened] = useState(null);
  const [isDraft, setIsDraft] = useState(null);
  const [sorting, setSorting] = useState(null);

  const {
    allAgenciesByStandardId,
    isGettingAllAgenciesByStandardId,
    totalPages,
    allItemsCount,
    totalNotReviewedByExpert,
    totalNotReviewedByPoc,
    totalNotReviewedByReviewer,
    totalReviewedByExpert,
    totalReviewedByPoc,
    totalReviewedByReviewer,
  } = useGetAgenciesByStandardId({
    currentPage,
    standardId,
    reviewedStatus,
    filterValue,
    maxResult: maxResult.value,
    finalCommitmentLevelId,
    reopened,
    isDraft,
    sorting,
  });

  const { data: commitmentLevels = [] } = useQuery({
    queryKey: ['getCommitmentLevelBySurveyId', surveyId],
    queryFn: () =>
      axiosGetRequest(environment.getCommitmentLevelBySurveyId, {
        SurveyId: surveyId,
      }),
    select: (data) =>
      data?.result?.items.filter((item) => {
        return item.title !== 'لا يوجد اجابة';
      }) ?? [],
  });

  const { state, search } = useLocation();
  const standardTitle = state?.standardTitle;

  const goToPage = (pageNumber) => {
    setCurrentPage(pageNumber);
    history.push(`?page=${pageNumber}`);
  };

  const getDonotApplyDegree = async () => {
    const surveyDegrees = await getDegrees(standardId);

    const donotApplyDegree =
      surveyDegrees?.filter((item) => item?.title == 'لا ينطبق')?.[0] || '';

    setDonotApply(donotApplyDegree);
  };

  useEffect(() => {
    getDonotApplyDegree();
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(search);
    const page = parseInt(params.get('page')) || 0; // Get the 'page' parameter from the URL
    setCurrentPage(page); // Set the current page based on the URL parameter
  }, [search]);

  useEffect(() => {
    const params = new URLSearchParams(search);
    const page = parseInt(params.get('page')) || 0; // Get the 'page' parameter from the URL
    if (currentPage !== page) {
      setCurrentPage(page); // Update the current page if it doesn't match the URL parameter
    }
  }, [currentPage, search]);

  const handlePageClick = (event) => {
    const newOffset = event.selected;
    goToPage(newOffset);
  };

  const onSearch = (value) => {
    setFilterValue(value);
  };

  const sendEditRequest = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    post(
      environment.SendReAnswerRequest,
      {
        standardId: Number(standardId),
        stageId: currentStage?.id,
        agencyIds: selectedAgencies.map((item) => item.value),
        notes: editRequestReason,
      },
      config,
      (res) => {
        if (res.status == 200) {
          toast.success(t('تم اٍرسال الطلب بنجاح'), {
            theme: 'colored',
          });
          setOpenRequestChangeModal(false);
        } else if (res.status == 500) {
          toast.error(res.data?.error?.message, {
            theme: 'colored',
          });
        }
      }
    );
  };

  const getStandardsOptions = () =>
    allAgenciesByStandardId?.map((item) => {
      return { label: item.nameInArabic, value: item.id };
    });

  const handleChangeSelectedStandards = (value) => {
    setSelectedAgencies(value);
  };

  const onRequestChange = () => {
    setOpenRequestChangeModal(true);
  };

  function handleSelectedCommitmentLevel(value) {
    setSelectedCommitmentLevel(value);
  }

  function handleSelectReopen(value) {
    setReopenStandardChecked(value);
  }

  function handleSelectedSaveAsDraft(value) {
    setSelecetSaveAsDraft(value);
  }

  function handleSearch() {
    setFinalCommitmentLevelId(selectedCommitmentLevel?.value);
    if (reopenStandardChecked?.value !== undefined) {
      setReopened(reopenStandardChecked?.value === '1');
    }
    if (selectedSaveAsDraft?.value !== undefined) {
      setIsDraft(selectedSaveAsDraft?.value === '1');
    }
  }

  function handleClear() {
    setSelectedCommitmentLevel(null);
    setReopenStandardChecked(null);
    setIsDraft(null);
    setSelecetSaveAsDraft(null);
  }

  return (
    <HeaderWithBreadcrumbLayout
      breadcrumbArr={standerdEntitesBreadcumbs(
        indicatorId || 1,
        surveyId,
        perspectiveId,
        interlocutorId,
        standardTitle
      )}
    >
      <div>
        <ReviewedStatusCards
          loading={isGettingAllAgenciesByStandardId}
          cardsData={{
            all: allItemsCount,
            totalNotReviewedByExpert,
            totalNotReviewedByPoc,
            totalNotReviewedByReviewer,
            totalReviewedByExpert,
            totalReviewedByPoc,
            totalReviewedByReviewer,
          }}
          setReviewedStatus={setReviewedStatus}
        />
      </div>

      <div className="flex justify-end">
        <DrawerFilter handleSearch={handleSearch} handleClear={handleClear}>
          <div className="w-full">
            <DropdownMultiSelect
              filter={true}
              withoutLabel={false}
              isSearchable={false}
              value={selectedCommitmentLevel}
              handleChange={handleSelectedCommitmentLevel}
              placeholder={'مستوى الالتزام'}
              label={'مستوى الالتزام'}
              options={[
                ...commitmentLevels.map((item) => ({
                  label: item.title,
                  value: item.id,
                })),
                {
                  label: 'لم يتم تحديد مستوى الالتزام',
                  value: 1,
                },
              ]}
              isMultiple={false}
            />
          </div>

          <div className="w-full">
            <DropdownMultiSelect
              filter={true}
              withoutLabel={false}
              isSearchable={false}
              value={reopenStandardChecked}
              handleChange={handleSelectReopen}
              placeholder={'معايير الاعادة'}
              label={'معايير الاعادة'}
              options={[
                { label: 'المعايير المفتوحة', value: '1' },
                { label: 'المعايير المغلقة', value: '0' },
              ]}
              isMultiple={false}
            />
          </div>

          <div className="w-full">
            <DropdownMultiSelect
              filter={true}
              withoutLabel={false}
              isSearchable={false}
              value={selectedSaveAsDraft}
              handleChange={handleSelectedSaveAsDraft}
              placeholder={'مسودة'}
              label={'مسودة'}
              options={[
                { label: 'نعم', value: '1' },
                { label: 'لا', value: '0' },
              ]}
              isMultiple={false}
            />
          </div>
        </DrawerFilter>
      </div>

      <div className={`m-5 mt-14 flex justify-between ${!showTable && 'py-5'}`}>
        <TableSearch onSearch={onSearch} />
        <div className="flex items-center gap-5">
          {permissionsCheck('Pages.UserType.Expert') && (
            <AppButton onClick={onRequestChange}>طلب تعديل</AppButton>
          )}
          <TableCardSwitch showTable={showTable} setShowTable={setShowTable} />
        </div>
      </div>

      {showTable ? (
        <StandardEntitiesTable
          maxResult={maxResult}
          setMaxResult={setMaxResult}
          entities={allAgenciesByStandardId}
          loading={isGettingAllAgenciesByStandardId}
          donotApplyDegree={donotApply}
          reviewedStatus={reviewedStatus}
          filterValue={filterValue}
          selectedAgencies={selectedAgencies}
          setSorting={setSorting}
        />
      ) : (
        <StandardEntitiesList
          entities={allAgenciesByStandardId}
          loading={isGettingAllAgenciesByStandardId}
        />
      )}

      <div className="pb-5 mx-auto">
        <AppPagination
          loading={isGettingAllAgenciesByStandardId}
          totalPages={totalPages}
          currentPage={currentPage}
          handlePageClick={handlePageClick}
        />

        <div className="py-2 max-w-[290px] mx-auto gap-2 flex items-center">
          <AppInput
            type="number"
            value={inputPageNum}
            placeholder="رقم الصفحة"
            onChange={(e) => {
              setInputPageNum(e.target.value);
            }}
          />
          <AppButton
            className="mx-auto text-sm"
            onClick={() => {
              goToPage(inputPageNum - 1);
            }}
          >
            الذهاب للصفحة
          </AppButton>
        </div>
      </div>

      {openRequestChangeModal && (
        <FModal
          open={openRequestChangeModal}
          setOpen={setOpenRequestChangeModal}
          width={160}
          headerTitle={'طلب تعديل'}
          submitLabel={'إرسال الطلب'}
          action={sendEditRequest}
          stopScroll
          content={
            <div>
              <NewDropdownMultiSelect
                filter={true}
                withoutLabel={false}
                isSearchable={false}
                value={selectedAgencies}
                handleChange={handleChangeSelectedStandards}
                placeholder={t('الجهات')}
                label={t('الجهات')}
                options={getStandardsOptions()}
                isMultiple={true}
                disableSelectAll={true}
              />
              <InputLabel
                label={'سبب طلب التعديل'}
                placeholder={'سبب التعديل'}
                value={editRequestReason}
                onChange={(e) => setEditRequestReason(e.target.value)}
                isRequired={false}
              />
            </div>
          }
        />
      )}
    </HeaderWithBreadcrumbLayout>
  );
};

export default StandardEntities;
