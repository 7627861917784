import { Image, Page, StyleSheet } from '@react-pdf/renderer';
import Slide2 from '../assets/Slide2.PNG';

export function IndexPage({ globalStyles }) {
  const styles = StyleSheet.create({
    ...globalStyles,
  });

  return (
    <Page size="A4">
      <Image style={styles.fullPage} src={Slide2} />
    </Page>
  );
}
