import React, { useContext } from 'react';
import AppTextarea from '../../../../../../../components/form/appTextarea';
import PastProofFilesDropdown from '../../standardAnswerProofFiles/ui/pastProofFilesDropdown';
import UploadFilesNew from '../../../../../../../components/uploadFiles/NewFileUpload';
import ShowUploadedFiles from '../../standardAnswerProofFiles/ui/showUploadedFiles';
import StandardAnswerProofFiles from '../../standardAnswerProofFiles';
import { useParams } from 'react-router-dom';
import { useAnswerContext } from '../../answerContext';
import { StageContext } from '../../../../../../../contexts/stageContext';

const AgencyProofFiles = ({ setMenuElement, standerRequirements }) => {
  const { entityId, standardId } = useParams();
  const { currentStage, stageRoles } = useContext(StageContext);
  const isThirdStage = currentStage?.displayOrder === 3;

  const { newAgencyAnswer, setNewAgencyAnswer } = useAnswerContext();

  const standerRequirementsIds = standerRequirements?.map((req) => req.id);

  const standardProofs =
    !isThirdStage || !standerRequirements
      ? newAgencyAnswer?.standardProofs
      : newAgencyAnswer?.standardProofs.filter((proof) =>
          proof.standardProofRequirements.some((req) => {
            return standerRequirementsIds.includes(req.standardRequirementId);
          })
        );

  const handleChangeNotes = (file, note) => {
    const updatedNotes = standardProofs?.map((document) => {
      if (document?.standardProofId == file?.standardProofId) {
        return {
          ...document,
          note,
        };
      }
      return document;
    });

    setNewAgencyAnswer({ ...newAgencyAnswer, standardProofs: updatedNotes });
  };

  const renderedFiles = standardProofs?.map((file, index) => (
    <div
      key={file?.id}
      className="max-w-[750px] pl-8 mb-14 pr-[80px] flex flex-col justify-beween items-start "
    >
      <div className="w-full">
        <div className="relative mt-4 flex flex-row items-start justify-between">
          <div className="flex items-start">
            <span className="bg-[#D2F2E6] w-4 h-4 rounded-full absolute flex justify-center items-center mt-2">
              <span className="bg-[#1CC081] w-[6px] h-[6px] rounded-full absolute"></span>
            </span>
            <h4 className="mr-6 ml-6 text-[#292069]">
              {file?.title}
              {file.standardProofTypeId === 1 && (
                <span className="text-red-500">*</span>
              )}
            </h4>
          </div>
          <p className="text-[#7D8BAB] mb-2">{file?.fileSize + 'MB'}</p>
        </div>
        <ShowUploadedFiles
          uploadedFiles={file?.standardProofAttachments}
          document={file}
        />

        {stageRoles.includes(localStorage.getItem('userType')) && (
          <>
            <UploadFilesNew
              label={file?.title}
              attachmentIndx={1}
              agencyId={entityId}
              from="Standards_Proofs"
              proofId={file?.id}
              standardProofId={file?.standardProofId}
              standardId={standardId}
              document={file}
            />

            <PastProofFilesDropdown
              proofId={file?.id}
              index={index}
              standardProofId={file.standardProofId}
            />

            <div className="bg-[#F9F9FF] mt-5 rounded-lg">
              <AppTextarea
                rows={4}
                placeholder="اكتب تعليق"
                defaultValue={file.note}
                style={{ resize: 'none' }}
                onChange={(e) => handleChangeNotes(file, e.target.value)}
              />
            </div>
          </>
        )}
      </div>
    </div>
  ));

  return (
    <StandardAnswerProofFiles setMenuElement={setMenuElement}>
      {renderedFiles}
    </StandardAnswerProofFiles>
  );
};

export default AgencyProofFiles;
