import { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import AppModal from '../../../../../components/Models/appModal';
import { StageContext } from '../../../../../contexts/stageContext';
import {
  isAdmin,
  isAgency,
  isViewer,
} from '../../../../../helpers/isPermission';
import { permissionsCheck } from '../../../../../helpers/utils';
import NewHeader from '../../../../../layouts/header/NewHeader';
import environment from '../../../../../services/environment';
import { get } from '../../../../../services/Request';
import StandardAnswerTopTaps from '../standardAnswerProgram/taps/standardAnswerTopTaps';
import AddstandardInquiry from './addstandardInquiry';
import { useAnswerContext } from './answerContext';
import AgenciesAnswer from './answers/Agency';
import ExpertAnswer from './answers/expert';
import ExpertManagerAnswer from './answers/expertManager';
import PocOfficerAnswer from './answers/pocOfficer';
import ReviewerAnswer from './answers/reviewer';
import StandardAnswerDegrees from './standardAnswerDegrees';
import StandardInquiry from './standardInquiry';
import StandardAnswerSideTaps from './taps/standardAnswerSideTaps';
import { useGetInquiryList } from './useGetInquiryList';

const NewStandardAnswer = () => {
  const { stageRoles, currentStage } = useContext(StageContext);
  const isFifthStage = currentStage?.stageNumber === 5;
  const { entityId, standardId, indicatorId } = useParams();

  const { newAgencyAnswer } = useAnswerContext();

  const history = useHistory();
  const [openQuestionModal, setOpenQuestionModel] = useState(false);
  const [menuElement, setMenuElement] = useState(0);

  const [showSection, setShowSection] = useState('standard');
  const [openWarningModal, setOpenWarningModal] = useState(false);

  const [description, setDescription] = useState('');

  const [priority, setPriority] = useState({});
  const [subject, setSubject] = useState('');

  const [priorities, setPriorities] = useState([]);

  const [selectedFile, setSelectedFile] = useState('');

  const [loading, setLoading] = useState(false);
  const [inquiries, setInquiries] = useState([]);
  const [standard, setStandard] = useState('');
  const [selectedStatusInquiry, setSelectedStatusInquiry] = useState('');
  const [activeTab, setActiveTab] = useState(0);

  const isConfidentialAgency = newAgencyAnswer.isConfidentialAgency;
  const isThirdStage = currentStage?.displayOrder === 3;

  const {} = useGetInquiryList(
    selectedStatusInquiry,
    selectedStatusInquiry,
    setInquiries,
    setStandard,
    setLoading
  );

  const handleChangePriority = (value) => {
    setPriority(value);
  };
  const handleChangeSubject = (e) => {
    setSubject(e.target.value);
  };

  const onChangeFile = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  // const clickChild=(func)=>{
  //   sendTicket(func)
  // }

  useEffect(() => {
    getAllPriorities();
  }, []);

  const getAllPriorities = () => {
    // setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    get(environment.getAllPriorities, config, (res) => {
      if (res.status == 403) {
        // setMessage({ type: "error", message: res.data.error.message });
        // setLoading(false);
      } else if (res.status == 500) {
        // setMessage({ type: "error", message: res.data.error.message });
        // setLoading(false);
      } else if (res.status == 200) {
        if (res.data.result.items && res.data.result.items.length > 0) {
          var array = [];
          for (var i = 0; i < res.data.result.items.length; i++) {
            array.push({
              value: res.data.result.items[i].id,
              label: res.data.result.items[i].name,
              isSelected: false,
            });
          }
          setPriorities(array);
        }
        // setTimeout(() => {
        //   setLoading(false);
        // }, 500);
      }
    });
  };

  function handleTabChange(value) {
    // select elements with scroll bar
    const scrollGroup = document.querySelector('.scrollGroup');

    // reset scroll
    if (scrollGroup) scrollGroup.scrollTop = 0;
    setMenuElement(value);
  }

  return (
    <div className="bg-white rounded-[30px] m-5">
      <NewHeader />
      <AddstandardInquiry
        handleChangeSubject={handleChangeSubject}
        onChangeFile={onChangeFile}
        priorities={priorities}
        selectedFile={selectedFile}
        setDescription={setDescription}
        description={description}
        handleChangePriority={handleChangePriority}
        openQuestionModal={openQuestionModal}
        priority={priority}
        setOpenQuestionModel={setOpenQuestionModel}
        subject={subject}
        setLoading={setLoading}
        setInquiries={setInquiries}
        setStandard={setStandard}
        selectedStatusInquiry={selectedStatusInquiry}
      />
      <StandardAnswerTopTaps
        showSection={showSection}
        setShowSection={setShowSection}
      />
      <AppModal
        open={openWarningModal}
        setOpen={setOpenWarningModal}
        headerTitle=""
        submitLabel="اغلاق"
        handleSubmit={() => setOpenWarningModal(false)}
      >
        <h2>الجهة لم تجاوب بعد ، نرجو الانتظار</h2>{' '}
      </AppModal>

      {showSection == 'standard' ? (
        <>
          <div className="bg-[#F9F9FF] ">
            <div className="p-4 pb-5 flex justify-between flex-wrap gap-5 bg-slate-200 pr-10">
              <div className="">
                <span
                  className="text-indigo-700 cursor-pointer"
                  onClick={() =>
                    history.push(
                      `/indicators/${indicatorId}/programs/${localStorage.getItem(
                        'surveyId'
                      )}/standards`
                    )
                  }
                >
                  المعايير
                </span>
                <span className="px-3">/</span>
                <span
                  className="text-indigo-700 ml-2 font-bold text-lg cursor-pointer"
                  onClick={() => history.goBack()}
                >
                  ( {newAgencyAnswer?.code} )
                </span>
                <span
                  className="text-indigo-700 cursor-pointer"
                  onClick={() => history.goBack()}
                >
                  {newAgencyAnswer?.title}
                </span>

                <span className="px-3">/</span>
                {isAdmin || isViewer ? (
                  <span
                    className="text-indigo-700 cursor-pointer"
                    onClick={() => {
                      history.push(
                        `/previousSurveys?agencyId=${newAgencyAnswer?.agencyId}`
                      );
                    }}
                  >
                    {newAgencyAnswer?.agencyName}
                  </span>
                ) : (
                  <span className="text-gray-500">
                    {newAgencyAnswer?.agencyName}
                  </span>
                )}
              </div>
              {(permissionsCheck('Pages.UserType.Viewer') ||
                permissionsCheck('Pages.UserType.Admin') ||
                permissionsCheck('Pages.UserType.ExpertManager') ||
                permissionsCheck('Pages.UserType.Expert') ||
                permissionsCheck('Pages.UserType.Reviewer') ||
                permissionsCheck('Pages.UserType.POCOfficer')) && (
                <div className="pl-5 mr-auto">
                  <Link
                    to={`/Standard/${standardId}/agency/${entityId}/answer-details`}
                    className="flex-shrink-0 text-sky-800 bg-sky-100 p-3 pt-2 rounded-lg"
                    target="_blank"
                  >
                    سجل الاجابات
                  </Link>
                </div>
              )}
            </div>
            <div className="flex flex-row items-start justify-around mr-3 rounded-xl px-3">
              <StandardAnswerSideTaps
                menuElement={menuElement}
                setMenuElement={handleTabChange}
                activeTab={activeTab}
              />

              {(permissionsCheck('Pages.UserType.POC') ||
                permissionsCheck('Pages.UserType.CIO') ||
                permissionsCheck('Pages.UserType.Delegate') ||
                permissionsCheck('Pages.UserType.InquiryManager')) && (
                <AgenciesAnswer
                  showSection={showSection}
                  menuElement={menuElement}
                  handleTabChange={handleTabChange}
                  setActiveTab={setActiveTab}
                  activeTab={activeTab}
                />
              )}

              {permissionsCheck('Pages.UserType.Reviewer') && (
                <ReviewerAnswer
                  showSection={showSection}
                  menuElement={menuElement}
                  setMenuElement={setMenuElement}
                />
              )}

              {permissionsCheck('Pages.UserType.Expert') && (
                <ExpertAnswer
                  showSection={showSection}
                  menuElement={menuElement}
                  setMenuElement={setMenuElement}
                />
              )}

              {/* {isConfidentialAgency && permissionsCheck("Pages.UserType.Expert") && (
                <ExpertAnswerPhase3
                  showSection={showSection}
                  menuElement={menuElement}
                  setMenuElement={setMenuElement}
                />
              )} */}
              {!isConfidentialAgency &&
                isThirdStage &&
                (permissionsCheck('Pages.UserType.ExpertManager') ||
                  permissionsCheck('Pages.UserType.Admin') ||
                  permissionsCheck('Pages.UserType.Viewer')) && (
                  <AgenciesAnswer
                    showSection={showSection}
                    menuElement={menuElement}
                    handleTabChange={handleTabChange}
                    setActiveTab={setActiveTab}
                    activeTab={activeTab}
                  />
                )}

              {!(!isConfidentialAgency && isThirdStage) &&
                (permissionsCheck('Pages.UserType.ExpertManager') ||
                  permissionsCheck('Pages.UserType.Admin') ||
                  permissionsCheck('Pages.UserType.Viewer')) && (
                  <ExpertManagerAnswer
                    showSection={showSection}
                    menuElement={menuElement}
                    setMenuElement={setMenuElement}
                  />
                )}

              {permissionsCheck('Pages.UserType.POCOfficer') && (
                <PocOfficerAnswer
                  showSection={showSection}
                  menuElement={menuElement}
                  setMenuElement={setMenuElement}
                />
              )}
            </div>
          </div>
        </>
      ) : (
        <StandardInquiry
          loading={loading}
          setLoading={setLoading}
          inquiries={inquiries}
          setInquiries={setInquiries}
          standard={standard}
          setStandard={setStandard}
          selectedStatusInquiry={selectedStatusInquiry}
          setSelectedStatusInquiry={setSelectedStatusInquiry}
        />
      )}
      {(stageRoles.includes(localStorage.getItem('userType')) ||
        isAgency ||
        isFifthStage) && (
        <>
          <StandardAnswerDegrees />
        </>
      )}
    </div>
  );
};

export default NewStandardAnswer;
