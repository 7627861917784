import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { StageContext } from '../../../../contexts/stageContext';
import { surveyId } from '../../../../helpers/isPermission';
import { axiosGetRequest } from '../../../../services/Request';
import environment from '../../../../services/environment';

export const useGetBestFiveVisitors = () => {
  const { stageForDashboard } = useContext(StageContext);
  const { currentStage } = useContext(StageContext);
  const phaseOneStageId = currentStage?.stageIds?.[0];

  const { data, isLoading: isGettingBestFiveVisitors } = useQuery({
    queryKey: ['AdminBestFiveVisitors', stageForDashboard, phaseOneStageId],
    queryFn: ({ signal }) =>
      axiosGetRequest(
        environment.getAgenciesTopFiveUsers,
        {
          surveyId: surveyId(),
          StageId: stageForDashboard || 0,
          SkipCount: 0,
          MaxResultCount: 5,
          surveyStageId: phaseOneStageId,
        },
        true,
        signal
      ),
    enabled: !!stageForDashboard && !!phaseOneStageId,
  });

  const bestFiveVisitors = data?.result;

  return { data, bestFiveVisitors, isGettingBestFiveVisitors };
};
