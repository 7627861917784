import React, { useContext, useEffect } from 'react';
import AddStandardRequirements from './addStandardRequirements';
import StandardRequirementList from './standardRequirementList';
import { useParams } from 'react-router';
import { AddStandardContext } from '../addStandardContext';

const StandardRequirements = ({ disabled = true }) => {
  const { surveyId } = useParams();
  const { getClassifications } = useContext(AddStandardContext);

  useEffect(() => {
    getClassifications(surveyId);
  }, []);

  return (
    <div className="mt-8">
      <h2 className="font-semibold mb-4">متطلبات التطبيق</h2>
      {!disabled && <AddStandardRequirements />}
      <StandardRequirementList disabled={false} />
    </div>
  );
};

export default StandardRequirements;
