import { useContext, useEffect, useState } from 'react';
import StandardFinalAnswer from '../../standardAgencyAnswer';
import { StageContext } from '../../../../../../../contexts/stageContext';
import { useAnswerContext } from '../../answerContext';
import AppCheckbox from '../../../../../../../components/form/appCheckbox';
import useCreateReviewerAnswer from '../useCreateAnswer/useCreateReviewerAnswer';
import AppTextarea from '../../../../../../../components/form/appTextarea';

const ExpertFinalAnswer = ({ setMenuElement, expertAnswerOnPhase2 }) => {
  const { currentStage } = useContext(StageContext);
  const isFourthStage = currentStage?.displayOrder === 4;
  const { createReviewerAnswer } = useCreateReviewerAnswer();
  const { newExpertAnswer, setNewExpertAnswer, expertDegree } =
    useAnswerContext();
  const [checkedDegree, setCheckedDegree] = useState(null);

  const handleDegreeChange = (degree) => {
    setCheckedDegree(degree === checkedDegree ? '' : degree);

    setNewExpertAnswer({
      ...newExpertAnswer,
      checkedExpertDegree: degree === checkedDegree ? '' : degree,
    });
  };

  useEffect(() => {
    setCheckedDegree(newExpertAnswer?.checkedExpertDegree);
  }, []);

  const handleChangeNote = (note) => {
    setNewExpertAnswer({
      ...newExpertAnswer,
      expertAnswerNote: note,
    });
  };

  const handleChangeDifferentNote = (note) => {
    setNewExpertAnswer({
      ...newExpertAnswer,
      differentBetweenOldAndNewExpertAnswerNote: note,
    });
  };

  const handleChangeDifferentAgencyNote = (note) => {
    setNewExpertAnswer({
      ...newExpertAnswer,
      differentBetweenAgencyAndExpertAnswerNote: note,
    });
  };

  const renderedDegrees = newExpertAnswer?.surveyDegrees?.map((item) => (
    <AppCheckbox
      key={item?.value}
      id={item.value}
      name={item.label}
      label={item.label}
      value={item.value}
      checked={checkedDegree?.value === item?.value}
      onChange={() => handleDegreeChange(item)}
    />
  ));

  return (
    <StandardFinalAnswer
      setMenuElement={setMenuElement}
      currentStage={currentStage}
      createReviewerAnswer={createReviewerAnswer}
      label="إجابة الخبير"
    >
      <div className="flex w-full flex-wrap">
        <div className="shrink-0 max-w-[200px]">{renderedDegrees}</div>
        <div className="flex-auto space-y-10">
          {expertDegree?.expertSelfEvaluationCommitmentLevel?.title !==
            newExpertAnswer?.checkedExpertDegree?.title && (
            <div className="mt-5 ml-8 min-w-[350px] grow">
              <AppTextarea
                rows={5}
                label="سبب تغيير الإجابة عن إجابة النظام"
                placeholder="الرجاء كتابة سبب تغيير الإجابة عن إجابة النظام"
                style={{
                  marginBottom: '24px',
                }}
                value={newExpertAnswer?.expertAnswerNote || ''}
                onChange={(e) => handleChangeNote(e.target.value?.trimStart())}
                warningMessage="سبب التغيير يجب أن يحتوي على 4 حروف على الأقل بدون رموز"
              />
            </div>
          )}
          {isFourthStage &&
            expertAnswerOnPhase2?.result?.lastAnswer?.expertFinalCommitmentLevel
              ?.title !== newExpertAnswer?.checkedExpertDegree?.title && (
              <div className="mt-5 ml-8 min-w-[350px] grow">
                <AppTextarea
                  rows={5}
                  label="سبب تغيير الإجابة عن إجابة التحقق الأولى"
                  placeholder="الرجاء كتابة سبب تغيير الإجابة عن إجابة التحقق الأولى"
                  style={{
                    marginBottom: '24px',
                  }}
                  value={
                    newExpertAnswer?.differentBetweenOldAndNewExpertAnswerNote ||
                    ''
                  }
                  onChange={(e) =>
                    handleChangeDifferentNote(e.target.value?.trimStart())
                  }
                  warningMessage="سبب التغيير يجب أن يحتوي على 4 حروف على الأقل بدون رموز"
                />
              </div>
            )}
          {newExpertAnswer?.agencyFinalCommitmentLevel?.title !==
            newExpertAnswer?.checkedExpertDegree?.title && (
            <div className="mt-5 ml-8 min-w-[350px] grow">
              <AppTextarea
                rows={5}
                label="سبب تغيير الإجابة عن إجابة الجهة"
                placeholder="الرجاء كتابة سبب تغيير الإجابة عن إجابة الجهة"
                style={{
                  marginBottom: '24px',
                }}
                value={
                  newExpertAnswer?.differentBetweenAgencyAndExpertAnswerNote ||
                  ''
                }
                onChange={(e) =>
                  handleChangeDifferentAgencyNote(e.target.value?.trimStart())
                }
                warningMessage="سبب التغيير يجب أن يحتوي على 4 حروف على الأقل بدون رموز"
              />
            </div>
          )}
        </div>
      </div>
    </StandardFinalAnswer>
  );
};

export default ExpertFinalAnswer;
