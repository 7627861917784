import React from 'react';
import { drawLeftGreen } from '../../../../../../assets/icons/Index';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import NewTooltip from '../../../../../../components/NewTooltip';
import { isAgency } from '../../../../../../helpers/isPermission';
import AppBadge from '../../../../../../components/AppBadge';
import IconBasedOnRevieweStatus from '../IconBasedOnRevieweStatus';

const StandardEntitiesItem = ({ item, surveyId, standardId, indicatorId }) => {
  return (
    <div className="col-4" key={item?.id}>
      <div
        className={`border-brdr h-full flex flex-col gap-3 justify-between border-[1px] p-4 px-5 rounded-lg `}
      >
        <div className="flex justify-between items-start">
          <h2 className="text-blue_text">
            {item?.nameInArabic} <br /> {item?.name}
          </h2>

          <IconBasedOnRevieweStatus row={item} />
        </div>

        <div className="flex flex-row items-center justify-between">
          <div>
            {!isAgency && item?.hasVisit && (
              <AppBadge badgeColor={'#ffa92b'}>زيارة ميدانية</AppBadge>
            )}
          </div>

          <div className="flex justify-end items-center gap-2 mt-4">
            <NewTooltip content="اجابة الجهة">
              <Link
                to={`/indicators/${indicatorId}/programs/${surveyId}/program/${item?.id}/entity/${standardId}/standard`}
              >
                <img src={drawLeftGreen} alt="more icon" />
              </Link>
            </NewTooltip>

            <NewTooltip content="بيانات الجهة">
              <Link to={`/entities/${item?.id}/details`}>
                <FontAwesomeIcon
                  icon={faEye}
                  className="text-sky-500 bg-sky-200 mr-1 rounded-full p-2 cursor-pointer"
                />
              </Link>
            </NewTooltip>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StandardEntitiesItem;
