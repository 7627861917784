import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import FilePreview from '../../../../../../../components/upload/filePreview';
import { permissionsCheck } from '../../../../../../../helpers/utils';
import { useAnswerContext } from '../../answerContext';
import { StageContext } from '../../../../../../../contexts/stageContext';

const ShowUploadedFiles = ({
  uploadedFiles,
  isAgency = false,
  document,
  viewOnly,
}) => {
  const { newAgencyAnswer, setNewAgencyAnswer } = useAnswerContext();
  const { stageRoles } = useContext(StageContext);

  const handleRemoveFile = (fileId) => {
    const standardProofs = newAgencyAnswer?.standardProofs?.map((item) => {
      if (item?.id == document?.id) {
        const updatedItem = item.standardProofAttachments?.filter((file) => {
          return file?.id != fileId;
        });

        return { ...item, standardProofAttachments: updatedItem };
      }
      return item;
    });

    //

    setNewAgencyAnswer({
      ...newAgencyAnswer,
      standardProofs,
    });
  };

  const currentYear = new Date().getFullYear();

  const renderedUploadedFiles = uploadedFiles?.map((file) => (
    <div
      key={file?.id}
      className="bg-slate-100 flex justify-between items-center px-2 py-2 mt-2 mb-2 rounded-md"
    >
      <p className="text-[#161318] text-[14px] mzl-5">
        {file?.attachment?.name}
      </p>
      <div className="flex items-center">
        {file?.fromPastYear == true ? (
          getFileAttachmentCreationYear(file) !== currentYear ? (
            <>
              <p className="bg-red-50 pt-1 pb-2 px-3 rounded font-semibold text-[10px] text-red-800">
                من قياس {getFileAttachmentCreationYear(file)}
              </p>
              <FilePreview
                id={file.attachment?.id}
                fileName={file.attachment?.name}
              />
              {!viewOnly &&
                (permissionsCheck('Pages.UserType.POC') ||
                  permissionsCheck('Pages.UserType.CIO') ||
                  permissionsCheck('Pages.UserType.Delegate')) &&
                stageRoles.includes(localStorage.getItem('userType')) && (
                  <FontAwesomeIcon
                    icon={faTrashCan}
                    className="hover:text-red-600 cursor-pointer ml-2 mr-2"
                    onClick={() => handleRemoveFile(file?.id)}
                  />
                )}
            </>
          ) : (
            <>
              <p className="bg-yellow-50 pt-1 pb-2 px-3 rounded font-semibold text-[10px] text-yellow-500">
                من مرحلة فتح النظام
              </p>
              <FilePreview
                id={file.attachment?.id}
                fileName={file.attachment?.name}
              />
              {!viewOnly &&
                (permissionsCheck('Pages.UserType.POC') ||
                  permissionsCheck('Pages.UserType.CIO') ||
                  permissionsCheck('Pages.UserType.Delegate')) &&
                stageRoles.includes(localStorage.getItem('userType')) && (
                  <FontAwesomeIcon
                    icon={faTrashCan}
                    className="hover:text-orange-500 cursor-pointer ml-2 mr-2"
                    onClick={() => handleRemoveFile(file?.id)}
                  />
                )}
            </>
          )
        ) : (
          <>
            <p className="bg-emerald-100 pt-1 pb-2 px-3 rounded font-semibold text-[10px] text-emerald-700">
              ملف جديد
            </p>
            <FilePreview
              id={file.attachment?.id}
              fileName={file.attachment?.name}
            />
            {!viewOnly &&
              (permissionsCheck('Pages.UserType.POC') ||
                permissionsCheck('Pages.UserType.CIO') ||
                permissionsCheck('Pages.UserType.Delegate')) &&
              stageRoles.includes(localStorage.getItem('userType')) && (
                <FontAwesomeIcon
                  icon={faTrashCan}
                  className="hover:text-red-600 cursor-pointer ml-2 mr-2"
                  onClick={() => handleRemoveFile(file?.id)}
                />
              )}
          </>
        )}
      </div>
    </div>
  ));

  return renderedUploadedFiles;
};

export default ShowUploadedFiles;

function getFileAttachmentCreationYear(file) {
  if (file.attachment && file.attachment.creationTime) {
    return new Date(file.attachment.creationTime).getFullYear();
  } else {
    return undefined;
  }
}
