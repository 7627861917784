import {
  isAdmin,
  isInquiryManager,
} from '../../../../../../helpers/isPermission';
import { permissionsCheck } from '../../../../../../helpers/utils';
import AgencyAnswerDegrees from './agencyAnswerDegrees';
import ExpertAnswerDegrees from './expertAnswerDegrees';
import ExpertManagerAnswerDegrees from './expertManagerAnswerDegrees';
import PocOfficerAnswerDegrees from './pocOfficerAnswerDegrees';
import ReviewerAnswerDegrees from './reviewerAnswerDegrees';

const StandardAnswerDegrees = () => {
  return (
    <>
      {(permissionsCheck('Pages.UserType.CIO') ||
        permissionsCheck('Pages.UserType.POC') ||
        permissionsCheck('Pages.UserType.Delegate')) && <AgencyAnswerDegrees />}

      {permissionsCheck('Pages.UserType.POCOfficer') && (
        <PocOfficerAnswerDegrees />
      )}

      {permissionsCheck('Pages.UserType.Reviewer') && <ReviewerAnswerDegrees />}

      {/* * Expert */}
      {permissionsCheck('Pages.UserType.Expert') && <ExpertAnswerDegrees />}

      {(permissionsCheck('Pages.UserType.Viewer') ||
        isAdmin ||
        isInquiryManager ||
        permissionsCheck('Pages.UserType.ExpertManager')) && (
        <ExpertManagerAnswerDegrees />
      )}
    </>
  );
};

export default StandardAnswerDegrees;
