import { errorToast, successToast } from '../../../../../../../helpers/toast';
import { validateAnswerNote } from '../../../../../../../helpers/utils';
import { axiosPostRequest } from '../../../../../../../services/Request';
import environment from '../../../../../../../services/environment';
import { useAnswerContext } from '../../answerContext';
import usePrevExpertAnswer from '../useGetPrevAnswer/usePrevExpertAnswer';
import { showRequirement } from '../utils';

const useCreateExpertAnswer = (expertAnswerOnPhase2) => {
  const { getPrevExpertAnswer } = usePrevExpertAnswer();
  const {
    newExpertAnswer,
    setCreateAnswerLoading,
    setCreateDraftAnswerLoading,
    expertDegree,
    expertAnswer,
    newAgencyAnswer,
  } = useAnswerContext();

  const createExpertAnswer = async (
    standardId,
    entityId,
    currentStage,
    isDraft
  ) => {
    const expertRequirementAnswers = newExpertAnswer?.standardRequirements?.map(
      (req) => {
        const subStandardRequirements = req?.subStandardRequirements?.map(
          (subReq) => ({
            subStandardRequirementId: subReq?.id,
            decision: subReq?.decision,
            note: req?.subReq || '',
            recommendation: req?.subReq || '',
            donotApply: subReq?.donotApply,
          })
        );

        return {
          standardRequirementId: req?.id,
          decision: req?.decision,
          note: req?.note || '',
          recommendation: req?.note || '',
          donotApply: req?.donotApply,
          expertSubRequirementAnswers: subStandardRequirements,
        };
      }
    );

    const surveyExpertAnswerStandardProofs =
      newExpertAnswer?.standardProofs
        ?.filter((proof) => proof?.expertProofNotes)
        .map((proof) => {
          return {
            standardProofId: proof?.standardProofId || null,
            expertProofNotes: proof?.expertProofNotes || '',
          };
        }) || [];

    // validation
    if (newExpertAnswer?.checkedExpertDegree?.value == null && !isDraft) {
      return errorToast('لابد من وجود اجابة للتدقيق');
    }

    // newExpertAnswer?.selectedTemplatesForReviewer +

    const data = {
      standardId: Number(standardId),
      surveyStageId: Number(currentStage),
      expertRequirementAnswers,
      surveyExpertAnswerStandardProofs,

      noteForAgency: {
        unCheckRequirement:
          newExpertAnswer?.newRequirementsAgency == undefined
            ? ''
            : `<ul style="padding : 6px 0 ">` +
              newExpertAnswer?.newRequirementsAgency +
              '</ul>',
        template: '',
        content: newExpertAnswer?.expertSelectedTemplateForAgency,
        noteTemplateId: newExpertAnswer?.selectedTemplatesForAgency,
      },
      noteForReviewer: {
        content: '<ul>' + newExpertAnswer?.newRequirementsReviewer + '</ul>',
        template: newExpertAnswer?.expertSelectedTemplateForReviewer,
      },

      expertId: Number(localStorage.getItem('userId')),
      agencyId: Number(entityId),
      finalDegree: newExpertAnswer?.checkedExpertDegree?.fixedDegree,
      donotApply: newExpertAnswer?.donotApply,
      finalCommitmentLevelId: newExpertAnswer?.checkedExpertDegree?.value,
      selfEvaluationCommitmentLevelId:
        expertDegree?.expertSelfEvaluationCommitmentLevel?.id || null,
      selfEvaluationDegree: expertDegree?.expertSelfEvaluationDegree || 0,

      expertAnswerNote:
        expertDegree?.expertSelfEvaluationCommitmentLevel?.title !==
        newExpertAnswer?.checkedExpertDegree?.title
          ? newExpertAnswer?.expertAnswerNote
          : null,
      differentBetweenOldAndNewExpertAnswerNote:
        expertAnswer?.reviewerFinalCommitmentLevel?.title !==
        newExpertAnswer?.checkedExpertDegree?.title
          ? newExpertAnswer?.differentBetweenOldAndNewExpertAnswerNote
          : null,
      differentBetweenAgencyAndExpertAnswerNote:
        newAgencyAnswer?.checkedAgencyDegree?.title !==
        newExpertAnswer?.checkedExpertDegree?.title
          ? newExpertAnswer?.differentBetweenAgencyAndExpertAnswerNote
          : null,
      needVisit: newExpertAnswer?.needVisit,
      visitNote: newExpertAnswer?.visitNote,
      isDraft,
    };

    // return true

    if (
      expertDegree?.expertSelfEvaluationCommitmentLevel?.title !==
        newExpertAnswer?.checkedExpertDegree?.title &&
      !newExpertAnswer?.expertAnswerNote &&
      !isDraft
    ) {
      errorToast(
        'الرجاء كتابة سبب تغيير اجابتك عن إجابة الدرجة المقترحة من النظام فى إجابة الخبير'
      );
      return;
    }

    if (
      expertAnswerOnPhase2?.result?.lastAnswer?.expertFinalCommitmentLevel
        ?.title !== newExpertAnswer?.checkedExpertDegree?.title &&
      !newExpertAnswer?.differentBetweenOldAndNewExpertAnswerNote &&
      !isDraft
    ) {
      errorToast('الرجاء كتابة سبب تغيير اجابتك عن إجابة التحقق الأولى');
      return;
    }

    if (
      newExpertAnswer?.agencyFinalCommitmentLevel?.title !==
        newExpertAnswer?.checkedExpertDegree?.title &&
      !newExpertAnswer?.differentBetweenAgencyAndExpertAnswerNote &&
      !isDraft
    ) {
      errorToast('الرجاء كتابة سبب تغيير اجابتك عن إجابة الجهة');
      return;
    }

    if (validateAnswerNote(data?.expertAnswerNote) && !isDraft) {
      errorToast('سبب التغيير يجب أن يحتوي على 4 حروف على الأقل بدون رموز');
      return;
    }

    if (
      validateAnswerNote(data?.differentBetweenOldAndNewExpertAnswerNote) &&
      !isDraft
    ) {
      errorToast('سبب التغيير يجب أن يحتوي على 4 حروف على الأقل بدون رموز');
      return;
    }

    if (
      validateAnswerNote(data?.differentBetweenAgencyAndExpertAnswerNote) &&
      !isDraft
    ) {
      errorToast('سبب التغيير يجب أن يحتوي على 4 حروف على الأقل بدون رموز');
      return;
    }

    const hasRequiredRequirements =
      newExpertAnswer?.standardRequirements?.filter((requirement) =>
        showRequirement(requirement)
      )?.length > 0;

    if (
      hasRequiredRequirements &&
      !newExpertAnswer?.selectedTemplatesForAgency &&
      !isDraft
    ) {
      errorToast('الرجاء اختيار قالب الملاحظات للجهة');
      return;
    }

    // if (
    //   hasRequiredRequirements &&
    //   !newExpertAnswer?.expertSelectedTemplateForAgency
    // ) {
    //   errorToast("الرجاء كتاية ملاحظات التدقيق للجهة");
    //   return;
    // }

    if (!isDraft) {
      setCreateAnswerLoading(true);
    } else {
      setCreateDraftAnswerLoading(true);
    }

    const res = await axiosPostRequest(environment.addExpertAnswer, data);
    if (res?.success) {
      successToast('تم اضافة الاجابة بنجاح');
      getPrevExpertAnswer(
        Number(standardId),
        Number(entityId),
        Number(currentStage)
      );
    }
    setCreateAnswerLoading(false);
    setCreateDraftAnswerLoading(false);
  };
  return { createExpertAnswer };
};

export default useCreateExpertAnswer;
