import { useEffect } from 'react';
import {
  interlocator,
  prespictive,
  standards,
  unAnswerdStandard,
} from '../../../assets/icons/dashboard';
import DashboardStats from '../../../components/dashboard/DashboardStats';
import { useDashboardContext } from '../../../contexts/DashboardContext';
import { useGetPhase2Stats } from './api/useGetPhase2Stats';

function AdminDashboardStatsPhase2() {
  const { phase2Stats, isGettingPhase2Stats } = useGetPhase2Stats();
  const { setLoading, setAdminDashboardStats } = useDashboardContext();

  useEffect(() => {
    setAdminDashboardStats((prevStats) => ({
      ...prevStats,
      reviewersCount: phase2Stats?.reviewersCount,
    }));
    setLoading(isGettingPhase2Stats);
  }, [phase2Stats?.reviewersCount, isGettingPhase2Stats]);

  const list = [
    {
      id: 1,
      title: 'نسبة اٍنجاز التدقيق',
      number: `${Math.round(phase2Stats?.reviewOperationRate)}%`,
      icon: unAnswerdStandard,
      color: '#25f81e26',
    },
    {
      id: 2,
      title: 'عدد المعايير الكلية',
      number: phase2Stats?.totalAgenciesCountInAllStandards,
      icon: unAnswerdStandard,
      color: '#a6edff8f',
    },
    {
      id: 3,
      title: 'عدد المعايير الكلية المجابة',
      number: phase2Stats?.totalAnsweredAgenciesCountInAllStandards,
      icon: unAnswerdStandard,
      color: '#f7dffdcc',
    },
    {
      id: 4,
      title: 'عدد المعايير الكلية المدققة',
      number: phase2Stats?.totalReviewedAgenciesCountInAllStandards,
      icon: unAnswerdStandard,
      color: '#ffa21726',
    },
    {
      id: 5,
      title: 'عدد المدققين',
      number: phase2Stats?.reviewersCount,
      icon: prespictive,
      color: '#67d4ff26',
    },
    {
      id: 6,
      title: 'عدد الخبراء',
      number: phase2Stats?.expertCount,
      icon: interlocator,
      color: '#f8251e26',
    },
    {
      id: 7,
      title: 'عدد رؤساء الخبراء',
      number: phase2Stats?.expertManagerCount,
      icon: standards,
      color: '#0046de25',
    },
    {
      id: 8,
      title: 'عدد ضباط الاتصال الداخليين',
      number: phase2Stats?.pocOfficerCount,
      icon: unAnswerdStandard,
      color: '#25f81e26',
    },
  ];

  return <DashboardStats lists={list} loading={isGettingPhase2Stats} />;
}

export default AdminDashboardStatsPhase2;
