import { errorToast, successToast } from '../../../../../../../helpers/toast';
import { validateAnswerNote } from '../../../../../../../helpers/utils';
import { axiosPostRequest } from '../../../../../../../services/Request';
import environment from '../../../../../../../services/environment';
import { useAnswerContext } from '../../answerContext';
import usePrevPocOfficerAnswer from '../useGetPrevAnswer/usePrevPocOfficerAnswer';
import { showRequirement } from '../utils';

const useCreatePocOfficerAnswer = (pocOfficerAnswerOnPhase2) => {
  const { getPrevPocOfficerAnswer } = usePrevPocOfficerAnswer();

  const {
    newPocOfficerAnswer,
    pocOfficerDegree,
    setCreateAnswerLoading,
    setCreateDraftAnswerLoading,
  } = useAnswerContext();

  const createPocOfficerAnswer = async (
    standardId,
    entityId,
    currentStage,
    isDraft
  ) => {
    const pocOfficerRequirementAnswers =
      newPocOfficerAnswer?.standardRequirements?.map((req) => {
        const subStandardRequirements = req?.subStandardRequirements?.map(
          (subReq) => ({
            subStandardRequirementId: subReq?.id,
            decision: subReq?.decision,
            note: req?.subReq || '',
            recommendation: req?.subReq || '',
            donotApply: subReq?.donotApply,
          })
        );

        return {
          standardRequirementId: req?.id,
          decision: req?.decision,
          note: req?.note || '',
          recommendation: req?.note || '',
          donotApply: req?.donotApply,
          pocOfficerSubRequirementAnswers: subStandardRequirements,
        };
      });

    const surveyPOCOfficerAnswerStandardProofs =
      newPocOfficerAnswer?.standardProofs
        ?.filter((proof) => proof?.pocOfficerProofNotes)
        .map((proof) => ({
          standardProofId: proof?.standardProofId || null,
          pocOfficerProofNotes: proof?.pocOfficerProofNotes || '',
        })) || [];

    // validation
    if (
      newPocOfficerAnswer?.checkedPocOfficerDegree?.value == null &&
      !isDraft
    ) {
      return errorToast('لابد من وجود اجابة للتدقيق');
    }

    const data = {
      standardId: Number(standardId),
      surveyStageId: Number(currentStage),
      pocOfficerRequirementAnswers,
      surveyPOCOfficerAnswerStandardProofs,
      noteForAgency: {
        unCheckRequirement:
          newPocOfficerAnswer?.newRequirementsForPOCOfficer == undefined
            ? ''
            : `<ul style="padding : 6px 0 ">` +
              newPocOfficerAnswer?.newRequirementsForPOCOfficer +
              '</ul>',
        template: '',
        content: newPocOfficerAnswer?.pocOfficerSelectedTemplate,
        noteTemplateId: newPocOfficerAnswer?.selectedTemplatesForAgency,

        // content:
        //   newPocOfficerAnswer?.selectedTemplatesForAgency == undefined
        //     ? "<p>لا يوجد</p>" +
        //       "<ul>" +
        //       newPocOfficerAnswer?.newRequirementsForPOCOfficer +
        //       "</ul>"
        //     : newPocOfficerAnswer?.selectedTemplatesForAgency +
        //       "<ul>" +
        //       newPocOfficerAnswer?.newRequirementsForPOCOfficer +
        //       "</ul>",
        // template: newPocOfficerAnswer?.pocOfficerSelectedTemplate,
      },
      noteForReviewer: {
        content: '',
      },
      pocOfficerId: Number(localStorage.getItem('userId')),
      agencyId: Number(entityId),
      finalDegree: newPocOfficerAnswer?.checkedPocOfficerDegree?.fixedDegree,
      finalCommitmentLevelId:
        newPocOfficerAnswer?.checkedPocOfficerDegree?.value,
      selfEvaluationCommitmentLevelId:
        pocOfficerDegree?.pocOfficerSelfEvaluationCommitmentLevel?.id || null,
      selfEvaluationDegree:
        pocOfficerDegree?.pocOfficerSelfEvaluationDegree || 0,

      pocOfficerAnswerNote:
        pocOfficerDegree?.pocOfficerSelfEvaluationCommitmentLevel?.title !==
        newPocOfficerAnswer?.checkedPocOfficerDegree?.title
          ? newPocOfficerAnswer?.pocOfficerAnswerNote
          : null,
      differentBetweenOldAndNewExpertAnswerNote:
        pocOfficerAnswerOnPhase2?.result?.lastAnswer
          ?.pocOfficerFinalCommitmentLevel?.title !==
        newPocOfficerAnswer?.checkedExpertDegree?.title
          ? newPocOfficerAnswer?.differentBetweenOldAndNewExpertAnswerNote
          : null,
      differentBetweenAgencyAndExpertAnswerNote:
        newPocOfficerAnswer?.agencyFinalCommitmentLevel?.title !==
        newPocOfficerAnswer?.checkedExpertDegree?.title
          ? newPocOfficerAnswer?.differentBetweenAgencyAndExpertAnswerNote
          : null,

      needVisit: newPocOfficerAnswer?.needVisit || false,
      visitId: newPocOfficerAnswer?.visitId || null,
      visitNote: newPocOfficerAnswer?.visitNote || '',
      visitRecommendation: newPocOfficerAnswer?.visitRecommendation || '',
      donotApply: newPocOfficerAnswer?.donotApply || false,
      isDraft,
    };

    if (
      pocOfficerDegree?.pocOfficerSelfEvaluationCommitmentLevel?.title !==
        newPocOfficerAnswer?.checkedPocOfficerDegree?.title &&
      !newPocOfficerAnswer?.pocOfficerAnswerNote &&
      !isDraft
    ) {
      errorToast(
        'الرجاء كتابة سبب تغيير اجابتك عن إجابة الدرجة المقترحة من النظام فى إجابة ضابط الاتصال'
      );
      return;
    }

    if (
      pocOfficerAnswerOnPhase2?.result?.lastAnswer
        ?.pocOfficerFinalCommitmentLevel?.title !==
        newPocOfficerAnswer?.checkedPocOfficerDegree?.title &&
      !newPocOfficerAnswer?.differentBetweenOldAndNewExpertAnswerNote &&
      !isDraft
    ) {
      errorToast('الرجاء كتابة سبب تغيير اجابتك عن إجابة التحقق الأولى');
      return;
    }

    if (
      newPocOfficerAnswer?.agencyFinalCommitmentLevel?.title !==
        newPocOfficerAnswer?.checkedPocOfficerDegree?.title &&
      !newPocOfficerAnswer?.differentBetweenAgencyAndExpertAnswerNote &&
      !isDraft
    ) {
      errorToast('الرجاء كتابة سبب تغيير اجابتك عن إجابة الجهة');
      return;
    }

    if (validateAnswerNote(data?.pocOfficerAnswerNote) && !isDraft) {
      errorToast('سبب التغيير يجب أن يحتوي على 4 حروف على الأقل بدون رموز');
      return;
    }

    if (
      validateAnswerNote(data?.differentBetweenOldAndNewExpertAnswerNote) &&
      !isDraft
    ) {
      errorToast('سبب التغيير يجب أن يحتوي على 4 حروف على الأقل بدون رموز');
      return;
    }

    if (
      validateAnswerNote(data?.differentBetweenAgencyAndExpertAnswerNote) &&
      !isDraft
    ) {
      errorToast('سبب التغيير يجب أن يحتوي على 4 حروف على الأقل بدون رموز');
      return;
    }

    const hasRequiredRequirements =
      newPocOfficerAnswer?.standardRequirements?.filter((requirement) =>
        showRequirement(requirement)
      )?.length > 0;

    if (
      hasRequiredRequirements &&
      !newPocOfficerAnswer?.selectedTemplatesForAgency &&
      !isDraft
    ) {
      errorToast('الرجاء اختيار قالب الملاحظات للجهة');
      return;
    }

    // if (
    //   hasRequiredRequirements &&
    //   !newPocOfficerAnswer?.pocOfficerSelectedTemplate
    // ) {
    //   errorToast("الرجاء كتاية ملاحظات التدقيق للجهة");
    //   return;
    // }

    if (!isDraft) {
      setCreateAnswerLoading(true);
    } else {
      setCreateDraftAnswerLoading(true);
    }
    const res = await axiosPostRequest(environment.addPocOfficerAnswer, data);
    if (res?.success) {
      successToast('تم اضافة الاجابة بنجاح');

      getPrevPocOfficerAnswer(
        Number(standardId),
        Number(entityId),
        Number(currentStage)
      );
    }
    setCreateAnswerLoading(false);
    setCreateDraftAnswerLoading(false);
  };
  return { createPocOfficerAnswer };
};

export default useCreatePocOfficerAnswer;
