const NewCard = ({
  title,
  children,
  addTask,
  fullWidth,
  allButton = false,
  handleShowMore,
}) => {
  return (
    <div
      className="shadow-lg h-full flex flex-col"
      style={{
        borderRadius: '20px',
        border: '1px solid #F5F5F5',
        background: 'white',
        width: fullWidth ? '100%' : 'auto',
      }}
    >
      <div
        style={{
          borderBottom: '2px solid #F5F5F5',
        }}
        className="flex flex-row justify-between w-full items-center py-[21px] px-[23px] "
      >
        <p className="text-base font-semibold text-[#2a2069]">{title}</p>

        {addTask && (
          <button
            style={{
              color: '#fff',
              borderRadius: '200px',
              backgroundColor: '#1CC081',
              fontSize: ' 14px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: 'normal',
              letterSpacing: '0.36px',
              padding: '4px 10px 8px',
            }}
          >
            إضافة مهمة
          </button>
        )}

        {allButton && (
          <button
            onClick={handleShowMore}
            style={{
              color: '#7D8BAB',
              fontSize: ' 15px',
              fontStyle: 'normal',
              fontWeight: 'bold',
              lineHeight: 'normal',
              letterSpacing: '0.36px',
            }}
          >
            المزيد
          </button>
        )}
      </div>

      <div className="py-[21px] px-[23px] flex-1">{children}</div>
    </div>
  );
};

export default NewCard;
