import AppMoreButton from '../../../../../components/buttons/appMoreButton';
import { useEffect, useRef, useState } from 'react';
import environment from '../../../../../services/environment';
import {
  axiosGetRequest,
  axiosPutRequest,
  remove,
} from '../../../../../services/Request';
import DeleteIcon from '../../../../../components/DeleteIcon';
import { toast } from 'react-toastify';
import AppModal from '../../../../../components/Models/appModal';
import { DropdownMultiSelect, InputLabel } from '../../../../../components';
import { pen } from '../../../../../assets/icons/Index';
import NewTooltip from '../../../../../components/NewTooltip';
import { NewForm } from '../../../../../components/form/NewForm';
import { ErrorMessage } from 'formik';
import { useHistory } from 'react-router';
import { permissionsCheck } from '../../../../../helpers/utils';

//
const PrespectiveItem = ({
  id,
  code,
  weight,
  description,
  surveyTitle,
  title,
  templateId,
  onDataUpdate,
  indicatorId,
  interlocutorsCount,
  department,
}) => {
  const history = useHistory();
  const [editLoading, setEditLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState('');

  const [inputs, setInputs] = useState({
    title: title || '',
    description: description || '',
    weight: weight || '',
    code: code || '',
  });

  const body = {
    templateId: templateId,
    id: id,
    ...inputs,
    departmentId: selectedDepartment?.value || '',
  };

  const handleChangeDepartment = (value) => {
    setSelectedDepartment(value);
  };

  // for multiSelect
  const [allDepartments, setAllDepartments] = useState([]);

  const getAllDepartments = async () => {
    const res = await axiosGetRequest(environment.getDepartments);
    if (res?.success) {
      setAllDepartments(res);
      const choosenDepartment = res?.result?.items
        ?.filter((item) => item?.id == department.id)
        ?.map((item) => ({ label: item.title, value: item.id }));

      setSelectedDepartment(choosenDepartment?.[0] || '');
    }
  };

  useEffect(() => {
    getAllDepartments();
  }, []);

  const allDepartmentsOptions =
    allDepartments?.result?.items &&
    allDepartments?.result?.items?.map((item) => {
      return { label: item.title, value: item.id };
    });

  const handleInputChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const handleEdit = async () => {
    setEditLoading(true);
    // return true
    const res = await axiosPutRequest(environment.editEndoscope, body);
    setEditLoading(false);
    if (res?.success) {
      toast.info(`تم تحديث منظور ${title} بنجاح`);
      setOpenEditModal(false);
      onDataUpdate();
    }
  };

  const handelDelete = () => {
    setDeleteLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    remove(environment.deleteEndoscope + '?id=' + id, config, (res) => {
      if (res.status == 200) {
        toast.success(`تم حذف منظور ${title} بنجاح`);
        setDeleteLoading(false);
        onDataUpdate();
      } else {
        toast.error('حدث خطأ');
      }
    });
  };

  // ----------------------------------------------------- //
  const formRef = useRef(null);

  const handleSubmitAdd = () => {
    if (formRef.current) {
      formRef.current?.handleSubmit();
    }
  };

  const initialValues = {
    title: '',
    weight: '',
    code: '',
    selectedDepartment: '',
  };

  const validate = (values) => {
    const errors = {};
    if (!inputs.title) {
      errors.title = 'هذا الحقل مطلوب';
    }
    if (!inputs.weight || inputs.weight < 0 || inputs.weight > 100) {
      errors.weight = 'هذا الحقل لا بد ان يحتوي على رقم من 0 اٍلي 100';
    }
    if (!inputs.code) {
      errors.code = 'هذا الحقل مطلوب';
    } else if (!inputs.code.match(/^\d+$/)) {
      errors.code = 'تنسيق الكود على الطريقة 1';
    }
    if (!selectedDepartment) {
      errors.selectedDepartment = 'هذا الحقل مطلوب';
    }

    return errors;
  };
  // ----------------------------------------------------- //

  return (
    <div className="p-1">
      <div className="p-5 hover:shadow-lg transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-[1.02] duration-300 border-brdr border-[1px] rounded-lg ">
        <div className="flex justify-between">
          <h2 className="text-blue_text">{title}</h2>
          <div className="flex flex-2">
            {permissionsCheck('Pages.Perspectives.Edit') ? (
              <NewTooltip content={'تعديل'}>
                <div
                  onClick={() => setOpenEditModal(true)}
                  className="rounded-full p-1 bg-[#FFF6DC] cursor-pointer mr-2 ml-2"
                >
                  <img src={pen} alt="edit icon" />
                </div>
              </NewTooltip>
            ) : null}
            {permissionsCheck('Pages.Perspectives.Delete') ? (
              <DeleteIcon
                loading={deleteLoading}
                deletedName={`منظور ${title}`}
                deleteFunction={() => handelDelete(id)}
              />
            ) : null}
          </div>
        </div>
        <div className="mt-5 mb-12">
          <div className="">
            <p className="text-light text-xs mb-1">الاستبيان</p>
            <h3 className="text-sm">{surveyTitle}</h3>
          </div>

          <div className="flex mt-5">
            <p className="text-light text-xs mb-1 min-w-[80px] flex-shrink-0">
              الوزن
            </p>
            <h3 className="text-sm">{weight.toFixed(2)}</h3>
          </div>
          <div className="flex mt-3">
            <p className="text-light text-xs mb-1 min-w-[80px]">رقم المنظور</p>
            <h3 className="text-sm">{code}</h3>
          </div>
        </div>
        <div>
          <AppMoreButton
            fullwidth
            onClick={() =>
              history.push(
                `/indicators/${indicatorId}/programs/${templateId}/perspectives/${id}/interlocutor`
              )
            }
          >
            المحاور ( {interlocutorsCount} )
          </AppMoreButton>
        </div>
        {/* </Link> */}

        <AppModal
          loading={editLoading}
          handleSubmit={handleSubmitAdd}
          type={'submit'}
          headerTitle={`تحديث منظور ${title}`}
          open={openEditModal}
          setOpen={setOpenEditModal}
          submitLabel={'تحديث'}
        >
          <NewForm
            initialValues={initialValues}
            validate={validate}
            innerRef={formRef}
            onSubmit={handleEdit}
          >
            <div className="flex flex-col gap-3">
              <div className="space-y-1">
                <InputLabel
                  label={'اسم المنظور'}
                  name="title"
                  value={inputs.title}
                  onChange={handleInputChange}
                />
                <ErrorMessage
                  className="text-red-700"
                  name="title"
                  component="div"
                />
              </div>
              <div className="space-y-1">
                <InputLabel
                  value={inputs.weight}
                  type={'number'}
                  onChange={handleInputChange}
                  name={'weight'}
                  label={'الوزن'}
                />
                <ErrorMessage
                  className="text-red-700"
                  name="weight"
                  component="div"
                />
              </div>
              <div className="space-y-1">
                <InputLabel
                  value={inputs.code}
                  onChange={handleInputChange}
                  name={'code'}
                  type="number"
                  label={'الكود'}
                />
                <ErrorMessage
                  className="text-red-700"
                  name="code"
                  component="div"
                />
              </div>
              <div className="space-y-1">
                <DropdownMultiSelect
                  withoutLabel={false}
                  isSearchable={false}
                  value={selectedDepartment}
                  handleChange={handleChangeDepartment}
                  placeholder={''}
                  label={'القسم'}
                  name={'selectedDepartment'}
                  options={allDepartmentsOptions}
                  isMultiple={false}
                />
                <ErrorMessage
                  className="text-red-700"
                  name="selectedDepartment"
                  component="div"
                />
              </div>
              <div className="space-y-1">
                <InputLabel
                  rows={4}
                  type={'textarea'}
                  value={inputs.description}
                  onChange={handleInputChange}
                  name={'description'}
                  label={'الوصف'}
                  isRequired={false}
                />
              </div>
            </div>
          </NewForm>
        </AppModal>
      </div>
    </div>
  );
};

export default PrespectiveItem;
