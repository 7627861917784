import { useMemo } from 'react';
import { useLocation } from 'react-router';
import * as Yup from 'yup';
import NotFound from '../services/NotFound';
import { Badge, Progress } from 'flowbite-react';
import { format, parse } from 'date-fns';
import { arSA, enUS } from 'date-fns/locale';
import ReactDOMServer from 'react-dom/server';

export function debounce(func, wait = 500) {
  let timeout;

  return function (...args) {
    const later = () => {
      clearTimeout(timeout);
      func.apply(this, args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

export const Lang = {
  AR: 'ar',
  EN: 'en',
};

// TODO: Check this url
export const url = 'http://tahataha12-001-site3.atempurl.com/api';

export function useQueryCustom() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export function getUniqueValues(array, key) {
  // Use a map to keep track of unique objects based on the key
  let uniqueMap = new Map();
  array?.forEach((obj) => {
    uniqueMap.set(obj[key], obj);
  });
  // Return an array of unique objects
  return Array.from(uniqueMap.values());
}

export const logoutUser = () => {
  localStorage.removeItem('loggedIn');
  localStorage.removeItem('accessToken');
  localStorage.setItem('i18nextLng', 'ar');
  localStorage.removeItem('agencyId');
  localStorage.removeItem('programId');
  localStorage.removeItem('userId');
  localStorage.removeItem('IndicatorId');
  localStorage.removeItem('surveyId');
  localStorage.removeItem('userType');
  localStorage.clear();
  // window.location.href = "/hello";
};

export function getFileExtension(filename) {
  return filename.slice(((filename.lastIndexOf('.') - 1) >>> 0) + 2);
}

export const formatDate = (d) => {
  let month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();
  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
};

export const userType = (userType) => {
  if (localStorage.getItem('userType') == userType) {
    return userType;
  }
};

export const formatTime = (t) => {
  var now = new Date(t);
  var utc = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
  let hours = '' + utc.getHours(),
    minutes = '' + utc.getMinutes(),
    seconds = '' + utc.getSeconds();

  hours = hours < 10 ? '0' + hours : hours;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  seconds = seconds < 10 ? '0' + seconds : seconds;

  return [hours, minutes, seconds].join(':');
};

export const handleFormValidation = async (schema, values) => {
  let validForm = true;
  let formErrors = false;
  try {
    await schema.validate(values, { abortEarly: false });
    validForm = true;
    formErrors = false;
  } catch (errors) {
    const validationErrors = {};
    errors?.inner?.forEach((error) => {
      validationErrors[error.path] = error.message;
    });
    validForm = false;
    formErrors = validationErrors;
  }
  return {
    validForm,
    formErrors,
  };
};

export const handleInputValidation = async (schema, name, value) => {
  let valid = true;
  let errMsg = false;

  try {
    await Yup.reach(schema, name).validate(value);
    errMsg = false;
    valid = true;
  } catch (err) {
    valid = false;
    errMsg = err.message;
  }
  return { valid, errMsg };
};

export function getUniqueObjectValue(array, property) {
  // Create a Set to hold unique values
  let uniqueValues = new Set();

  // Filter out duplicates based on the property
  return array.filter((item) => {
    if (!uniqueValues.has(item[property])) {
      uniqueValues.add(item[property]);
      return true;
    }
    return false;
  });
}

export function checkRoute(permission) {
  const gotPemission = JSON.parse(localStorage.getItem('perm')).some((obj) => {
    // Check if any property value of the object is in arrayOfStrings
    return Object.values(obj).some((value) => permission.includes(value));
  });

  if (!gotPemission && !permission?.includes('all')) {
    return <NotFound />;
  }
}

export function permissionsCheck(permission) {
  let newPermissions = [];
  JSON.parse(localStorage.getItem('perm'))?.forEach((item) => {
    newPermissions.push(item.name);
  });
  return newPermissions?.includes(permission);
}

export const getStandardRatioBadge = (ratio) => {
  let badgeColor = '#3498db';

  if (ratio === 0) {
    badgeColor = '#575757';
  } else if (ratio < 50) {
    badgeColor = '#f05868';
  } else if (ratio < 70) {
    badgeColor = '#ffa92b';
  } else if (ratio === 100) {
    badgeColor = '#1cc182';
  }

  return (
    <Badge
      className="text-white text-[15px] font-medium pb-3 px-5 rounded-xl"
      size="sm"
      style={{ backgroundColor: badgeColor }}
    >
      {`${ratio?.toFixed(2)}%`}
    </Badge>
  );
};

export const getCompletionRateProgressBar = (completionRate = 0) => {
  let badgeColor = 'bg-[#3498db]';

  if (completionRate === 0) {
    badgeColor = 'bg-[#575757]';
  } else if (completionRate < 50) {
    badgeColor = 'bg-[#f05868]';
  } else if (completionRate < 70) {
    badgeColor = 'bg-[#ffa92b]';
  } else if (completionRate === 100) {
    badgeColor = 'bg-[#1cc182]';
  }

  return (
    <div className="px-3 py-4 w-full">
      <span className="text-sm text-[#A2A2A2]">{`${
        Math.round(completionRate) || 0
      }%`}</span>

      <Progress
        theme={{
          color: {
            dark: badgeColor,
          },
        }}
        color={'dark'}
        progress={completionRate}
      />
    </div>
  );
};

export function convertHtmlToString(htmlString, defaultValue = 'لا يوجد') {
  if (!htmlString) {
    return defaultValue;
  }

  let doc = new DOMParser().parseFromString(htmlString, 'text/html');
  let textContent = '';

  function traverse(node) {
    if (node.nodeType === Node.TEXT_NODE) {
      textContent += node.textContent.trim() + ' ';
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      for (let childNode of node.childNodes) {
        traverse(childNode);
      }
    }
  }

  traverse(doc.body);
  return textContent.trim();
}

export function arabicFormatForDateTime(dateString) {
  try {
    // Regular expressions for Arabic and English AM/PM
    const arabicAmPmRegex = /(ص|م)/;
    const englishAmPmRegex = /(AM|PM|am|pm)/;

    // Detect if the input is in Arabic or English
    const isArabic = arabicAmPmRegex.test(dateString);
    const isEnglish = englishAmPmRegex.test(dateString);

    // Determine the appropriate format string
    let formatString;
    let locale;

    if (isArabic) {
      formatString = 'dd-MM-yyyy h:mm a';
      locale = arSA;
    } else if (isEnglish) {
      formatString = 'dd-MM-yyyy h:mm a';
      locale = enUS;
    } else {
      throw new Error('Unknown date format');
    }

    // Parse the date string using the determined format and locale
    const parsedDate = parse(dateString, formatString, new Date(), { locale });

    if (isNaN(parsedDate)) {
      throw new Error('Invalid date');
    }

    // Format the date and time separately
    const formattedDate = format(parsedDate, 'dd-MM-yyyy', { locale: arSA });
    const formattedTime = format(parsedDate, 'h:mm a', { locale: arSA });

    // Combine the formatted date and time
    const formattedDateTime = `التاريخ: ${formattedDate} - التوقيت: ${formattedTime}`;

    return formattedDateTime;
  } catch (error) {
    console.error('Error formatting date:', error);
    return 'Invalid date';
  }
}

export function guardIsNumber(number) {
  if (isNaN(number) || number === null || number === undefined) {
    return '';
  } else {
    return number;
  }
}

export function validateAnswerNote(note) {
  return (
    note !== null &&
    (!/^[a-zA-Z0-9\u0600-\u06FF \p{P}\p{S}]+$/u.test(note) || note?.length < 4)
  );
}

export const cleanText = (text) =>
  text.replace(/[^\p{L}\p{N}\p{P}\p{Z}]/gu, '');

export function numberToFixed(num = 0, d = 2) {
  const number = Number.isFinite(num) ? Number(num) : 0;
  return number.toFixed(d);
}

export function getTextByValue(value) {
  if (value >= 0 && value < 10) {
    return 'البناء';
  } else if (value >= 10 && value < 40) {
    return 'الإتاحة';
  } else if (value >= 40 && value < 80) {
    return 'التحسين';
  } else if (value >= 80 && value < 90) {
    return 'التكامل';
  } else if (value >= 90 && value <= 100) {
    return 'الإبداع';
  } else {
    return 'القيمة خارج النطاق';
  }
}

export function getColorByValue(value) {
  if (value < 10) {
    return '#b40000'; // Building/البناء
  } else if (value >= 10 && value < 40) {
    return '#ff6d00'; // Availability/الاٍتاحة
  } else if (value >= 40 && value < 80) {
    return '#ff9d00'; // Improvement/التحسين
  } else if (value >= 80 && value < 90) {
    return '#1cc182'; // Integration/التكامل
  } else if (value >= 90 && value <= 100) {
    return '#006633'; // Innovation/الإبداع
  } else {
    return '#000000'; // Out of range
  }
}

export function extractTextFromJSX(jsxElement) {
  if (!jsxElement || typeof jsxElement !== 'object') {
    return '';
  }
  const staticMarkup = ReactDOMServer.renderToStaticMarkup(jsxElement);
  return staticMarkup.replace(/<[^>]*>/g, ''); // Removes HTML tags
}

export function byId(arr, id) {
  return arr.find((item) => item.id === id);
}

export function jsonParse(str) {
  return str ? JSON.parse(str) : null;
}

export function isValidDate(dateString) {
  const date = new Date(dateString);
  return date instanceof Date && !isNaN(date);
}

export function inPast(dateString) {
  const date = new Date(dateString);
  return date < new Date();
}

export default function undefinedToString(value) {
  return !isDefined(value) ? '' : value;
}

export function isDefined(value) {
  return value !== undefined && value !== null;
}

export function extractTextFromHtml(htmlString) {
  const div = document.createElement('div');
  div.innerHTML = htmlString;
  return div.textContent || div.innerText || '';
}

export const TOAST_OPTIONS = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'colored',
};

export const getSurveyAnswersRoute = () =>
  permissionsCheck('Pages.UserType.Admin')
    ? `/indicators/${localStorage.getItem(
        'IndicatorId'
      )}/programs/${localStorage.getItem('surveyId')}/perspectives`
    : `/indicators/${localStorage.getItem(
        'IndicatorId'
      )}/programs/${localStorage.getItem('surveyId')}/standards`;

export const newStandardAnswerProgramRoute =
  '/indicators/:indicatorId/programs/:programId/program/:entityId/entity/:standardId/standard';

export const standardEntitiesRoute =
  '/indicators/:indicatorId/programs/:surveyId/standards/:standardId/entities';
export function truncateString(str, num) {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + '...';
}
