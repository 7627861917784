import { useEffect, useState } from 'react';
import {
  AppInput,
  DropdownMultiSelect,
  FModal,
  SModal,
  Title,
} from '../../../components';
import { axiosGetRequest, get, remove } from '../../../services/Request';
import environment from '../../../services/environment';
import { useTranslation } from 'react-i18next';
import { Viewer, Worker } from '@react-pdf-viewer/core';
// import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import {
  formatDate,
  formatTime,
  permissionsCheck,
} from '../../../helpers/utils';
import NewHeader from '../../../layouts/header/NewHeader';
import { programsIcon } from '../../../assets/icons/Index';
import NewTable from '../../../components/NewTable';
import DrawerFilter from '../../../components/DrawerFilter';
import { toast } from 'react-toastify';
import { Accordion } from 'flowbite-react';
import useExcelExport from '../../../helpers/customHooks/useExcelExport';
import useAxiosGetRequest from '../../../helpers/customHooks/useAxiosGetRequest';
import CustomTable from '../../../components/CustomTable';
import { Tooltip as ReactTooltip } from 'react-tooltip';

// const defaultLayoutPluginInstance = defaultLayoutPlugin();

let obj = {
  forms: [
    { id: 1, answers: [{ value: 'test' }] },
    { id: 1, answers: [{ value: 'test' }] },
  ],
};

const Reports = () => {
  const { t } = useTranslation();
  const [downloadReportsLoading, setDownloadReportsLoading] = useState(false);

  const [loading, setLoading] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [perspective, setPerspective] = useState('');
  const [interlocutor, setInterlocutor] = useState('');
  const [open, setOpen] = useState(false);
  const [totalPages, setTotalPages] = useState(null);
  const [standards, setStandards] = useState([]);
  const [standardsForExcel, setStandardsForExcel] = useState([]);

  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  // const [selectedServiceName, setSelectedServiceName] = useState(null);
  const [userName, setUserName] = useState('');
  const [serviceName, setServiceName] = useState('');
  const [methodName, setMethodName] = useState('');
  // const [selectedUserName, setSelectedUserName] = useState(null);
  const [showMenu, setShowMenu] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  const [imageUrl, setImageUrl] = useState(null);
  const [statuses, setStatuses] = useState([
    { id: 1, label: 'نشط', value: true },
    { id: 2, label: 'غير نشط', value: false },
  ]);

  const [selectedAgency, setSelectedAgency] = useState('');
  const [agencies, setAgencies] = useState([]);

  // ------------------------------------- //
  const [dateInputs, setDateInputs] = useState({
    from: '',
    to: '',
  });

  const handleChangeDate = (e) => {
    setDateInputs({ ...dateInputs, [e.target.name]: e.target.value });
  };
  // ------------------------------------- //

  const [message, setMessage] = useState({});

  const [getPerspective, allPerspectives] = useAxiosGetRequest();
  const allPerspectivesOptions =
    allPerspectives?.result?.items &&
    allPerspectives?.result?.items?.map((item) => {
      return { label: item.title, value: item.id };
    });

  const [getInterlocutor, allInterlocutors] = useAxiosGetRequest();
  const allInterlocutorsOptions =
    allInterlocutors?.result?.items &&
    allInterlocutors?.result?.items?.map((item) => {
      return { label: item.title, value: item.id };
    });
  useEffect(() => {
    getAllStandards();
    getPerspective(environment.getEndoscopes, {
      SurveyId: localStorage.getItem('surveyId'),
      MaxResultCount: 1000000,
    });
    getInterlocutor(environment.getInterviewers, {
      SurveyId: localStorage.getItem('surveyId'),
      MaxResultCount: 1000000,
    });
  }, []);

  const getAllStandardsForExcel = () => {
    // setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    let interlocutorId = '';
    if (interlocutor && interlocutor.value) {
      interlocutorId = `&InterlocutorId=${interlocutor.value}`;
    }
    let perspectiveId = '';
    if (perspective && perspective.value) {
      perspectiveId = `&PerspectiveId=${perspective.value}`;
    }

    let agencyId = '';
    if (selectedAgency && selectedAgency.value) {
      agencyId = `&AgencyId=${selectedAgency.value}`;
    }
    get(
      `${environment.getStandardsReport}?SurveyId=${localStorage.getItem(
        'surveyId'
      )}${interlocutorId}${perspectiveId}${agencyId}`,
      config,
      (res) => {
        if (res.status == 403) {
          setMessage(
            { type: 'error', message: 'لا يوجد لديك صلاحيات' },
            setOpenConfirmModal(true)
          );
        } else if (res.status == 500) {
          setMessage(
            {
              type: 'error',
              message: 'هناك مشكلة في الخدمة يجب العودة للدعم الفني',
            },
            setOpenConfirmModal(true)
          );
        } else if (res.status == 200) {
          // setStandardsForExcel(res.data.result.items);
          const result = res?.data?.result?.items?.map((item) => {
            const {
              standardProofs,
              standardRelations,
              agencyStandards,
              pastSurveyStandardAssociations,
              ...rest
            } = item;
            return {
              ...rest,
              standardRequirementTitles: item?.standardRequirements
                ?.map(
                  (obj) =>
                    ' [ ' +
                    obj?.title +
                    ' ( ' +
                    +obj?.agencyStandardRequirements?.length +
                    ' جهة ) ]'
                )
                .join(' - '),
              standardRequirements: item?.standardRequirements
                ?.map((obj) =>
                  obj?.agencyStandardRequirements?.map(
                    (ele) => ele.agencyNameInArabic
                  )
                )
                .join(' - '),
            };
          });
          setStandardsForExcel(result);
          setFilteredData(res?.data?.result?.items);
        }
      }
    );
  };

  const filterExcel = filteredData?.map((item) => {
    const {
      standardProofs,
      standardRelations,
      agencyStandards,
      pastSurveyStandardAssociations,
      ...rest
    } = item;

    return {
      ...rest,
      standardRequirementTitles: item?.standardRequirements
        ?.map(
          (obj) =>
            ' [ ' +
            obj?.title +
            ' ( ' +
            +obj?.agencyStandardRequirements?.length +
            ' جهة ) ]'
        )
        .join(' - '),
      standardRequirements: item?.standardRequirements
        ?.map((obj) =>
          obj?.agencyStandardRequirements?.map((ele) => ele.agencyNameInArabic)
        )
        .join(' - '),
    };
  });

  useEffect(() => {
    getAllStandardsForExcel();
  }, []);

  const getAllStandards = () => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    let interlocutorId = '';
    if (interlocutor !== '') {
      interlocutorId = `&InterlocutorId=${interlocutor.value}`;
    }
    let perspectiveId = '';
    if (perspective !== '') {
      perspectiveId = `&PerspectiveId=${perspective.value}`;
    }
    let agencyId = '';
    if (selectedAgency !== '') {
      agencyId = `&AgencyId=${selectedAgency.value}`;
    }
    get(
      `${environment.getStandardsReport}?SurveyId=${localStorage.getItem(
        'surveyId'
      )}${interlocutorId}${perspectiveId}${agencyId}`,
      config,
      (res) => {
        if (res.status == 403) {
          setMessage(
            { type: 'error', message: 'لا يوجد لديك صلاحيات' },
            setOpenConfirmModal(true)
          );
        } else if (res.status == 500) {
          setMessage(
            {
              type: 'error',
              message: 'هناك مشكلة في الخدمة يجب العودة للدعم الفني',
            },
            setOpenConfirmModal(true)
          );
        } else if (res.status == 200) {
          setTotalPages(Math.ceil(res.data.result.totalCount / 8));
          setFilteredData(res.data.result.items);
          setStandards(res.data.result.items, setLoading(false));
        }
      }
    );
  };

  const onPageChange = (page) => setCurrentPage(page, getAllStandards());

  const handlePageClick = (event) => {
    const newOffset = event.selected;
    setCurrentPage(newOffset);
    getAllStandards(newOffset);
  };

  const search = () => {
    getAllStandards();
  };
  const clear = () => {
    setUserName('');
    setServiceName('');
    setMethodName('');
    setDateInputs({
      from: '',
      to: '',
    });
  };

  const removeLog = (id) => {
    setDeleteLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    remove(environment.deleteUser + '?id=' + id, config, (res) => {
      if (res.status == 200) {
        setDeleteLoading(false);
        getAllStandards();
        toast.success('تم حذف هذا النشاط بنجاح');
      } else {
        toast.error('حدث خطأ');
      }
    });
  };

  const ReportList = [
    {
      name: 'رقم المعيار',
      selector: (row) => row.titleArabic,
      header: 'رقم المعيار',
      accessorKey: 'code',
      center: true,
      footer: (props) => props.column.id,
      cell: (info) => (
        <div>
          <span
            className={`text-center align-baseline inline-flex px-4 py-2 mr-auto items-center font-semibold tracking-wide	 text-sm leading-none rounded-lg bg-[#eff6ff] text-qiyas_blue`}
          >
            {info.code}
          </span>
        </div>
      ),
    },
    {
      name: 'وزن المعيار',
      selector: (row) => row.weight,
      header: 'وزن المعيار',
      accessorKey: 'weight',
      footer: (props) => props.column.id,
      cell: (info) => (
        <div>
          <span
            className={`text-center align-baseline inline-flex px-4 py-2 mr-auto items-center font-semibold tracking-wide	 text-sm leading-none rounded-lg bg-[#5bc0de30] text-[#5bc0de]`}
          >
            {Math.round(info.weight)}
          </span>
        </div>
      ),
    },
    {
      name: 'المنظور',
      selector: (row) => row.perspectiveTitle,
      header: 'المنظور',
      accessorKey: 'perspectiveTitle',
      footer: (props) => props.column.id,
      cell: (info) => (
        <div>
          <span className="text-sm text-black">{info.perspectiveTitle}</span>
        </div>
      ),
    },
    {
      name: 'وزن المنظور',
      selector: (row) => row.perspectiveWeight,
      header: 'وزن المنظور',
      accessorKey: 'perspectiveWeight',
      footer: (props) => props.column.id,
      cell: (info) => (
        <div>
          <span
            className={`text-center align-baseline inline-flex px-4 py-2 mr-auto items-center font-semibold tracking-wide	 text-sm leading-none rounded-lg bg-[#5bc0de30] text-[#5bc0de]`}
          >
            {info.perspectiveWeight}
          </span>
        </div>
      ),
    },
    {
      name: 'المحور',
      selector: (row) => row.interlocutorTitle,
      header: 'المحور',
      accessorKey: 'interlocutorTitle',
      footer: (props) => props.column.id,
      cell: (info) => (
        <div>
          <span className="text-sm text-black">{info.interlocutorTitle}</span>
        </div>
      ),
    },
    {
      name: 'وزن المحور',
      selector: (row) => row.interlocutorWeight,
      header: 'وزن المحور',
      accessorKey: 'interlocutorWeight',
      footer: (props) => props.column.id,
      cell: (info) => (
        <div>
          <span
            className={`text-center align-baseline inline-flex px-4 py-2 mr-auto items-center font-semibold tracking-wide	 text-sm leading-none rounded-lg bg-[#5bc0de30] text-[#5bc0de]`}
          >
            {info.interlocutorWeight}
          </span>
        </div>
      ),
    },
    {
      name: 'الجهات',
      selector: (row) => row.agencyStandards,
      header: 'الجهات',
      accessorKey: 'agencyStandards',
      footer: (props) => props.column.id,
      cell: (info) => <Agencies standard={info} />,
    },
    {
      name: 'المتطلبات',
      selector: (row) => row.requirements,
      header: 'المتطلبات',
      accessorKey: 'requirements',
      footer: (props) => props.column.id,
      cell: (info) => <Requirements standard={info} />,
    },
    {
      name: 'المستندات',
      selector: (row) => row.proofs,
      header: 'المستندات',
      accessorKey: 'proofs',
      footer: (props) => props.column.id,
      cell: (info) => <Proofs standard={info} />,
    },
  ];

  function Agencies({ standard }) {
    const [openModal, setOpenModal] = useState(false);
    return (
      <div>
        <FModal
          content={
            <div>
              <div>
                <div className="text-[#202969] text-lg"> الجهات الحكومية :</div>
                {standard?.agencyStandards.length == 0 ? (
                  <div className="mt-3">لا يوجد جهات حكومية</div>
                ) : (
                  <ul className="mt-3">
                    {standard?.agencyStandards?.map((item, index) => (
                      <li>{item.agencyTitle}</li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          }
          open={openModal}
          titleButton={t('modules.main.users.entities.titleAddButton')}
          widthButton={false}
          // action={handleSubmitAddAgency}
          type={'submit'}
          actions={false}
          width={160}
          setOpen={() => setOpenModal(false)}
          headerTitle={t('الجهات')}
        />
        <span
          onClick={() => setOpenModal(true)}
          className={`text-center align-baseline inline-flex px-4 py-2 mr-auto items-center font-semibold tracking-wide	 text-sm leading-none rounded-lg bg-[#5bc0de30] text-[#5bc0de] cursor-pointer`}
        >
          عرض
        </span>
      </div>
    );
  }

  function Requirements({ standard }) {
    const [openModal, setOpenModal] = useState(false);
    return (
      <div>
        <FModal
          content={
            <div>
              <Accordion>
                {standard?.standardRequirements?.map((item, index) => (
                  <Accordion.Panel>
                    <Accordion.Title>{item.title}</Accordion.Title>
                    <Accordion.Content>
                      <div>
                        <div className="text-[#202969] text-lg">
                          {' '}
                          المتطلبات الفرعية :
                        </div>
                        {item?.subStandardRequirements.length == 0 ? (
                          <div className="mt-3">لا يوجد متطلبات فرعية</div>
                        ) : (
                          <ul className="mt-3">
                            {item?.subStandardRequirements?.map(
                              (subItem, index) => (
                                <li>{subItem.title}</li>
                              )
                            )}
                          </ul>
                        )}
                      </div>
                      <div className="mt-7">
                        <div className="text-[#202969] text-lg">
                          الجهات الحكومية الخاصة بالمتطلب :
                        </div>
                        {item?.agencyStandardRequirements.length == 0 ? (
                          <div className="mt-3">لا يوجد جهات</div>
                        ) : (
                          <ul className="mt-3">
                            {item?.agencyStandardRequirements?.map(
                              (subItem, index) => (
                                <li>{subItem.agencyNameInArabic}</li>
                              )
                            )}
                          </ul>
                        )}
                      </div>
                    </Accordion.Content>
                  </Accordion.Panel>
                ))}
              </Accordion>
            </div>
          }
          open={openModal}
          titleButton={t('modules.main.users.entities.titleAddButton')}
          widthButton={false}
          // action={handleSubmitAddAgency}
          type={'submit'}
          actions={false}
          width={160}
          setOpen={() => setOpenModal(false)}
          headerTitle={t('المتطلبات')}
        />
        <span
          onClick={() => setOpenModal(true)}
          className={`text-center align-baseline inline-flex px-4 py-2 mr-auto items-center font-semibold tracking-wide	 text-sm leading-none rounded-lg bg-[#22bb3330] text-[#22bb33] cursor-pointer`}
        >
          عرض
        </span>
      </div>
    );
  }

  function Proofs({ standard }) {
    const [openModal, setOpenModal] = useState(false);
    return (
      <div>
        <FModal
          content={
            <div>
              <Accordion>
                {standard?.standardProofs?.map((item, index) => (
                  <Accordion.Panel>
                    <Accordion.Title>{item.title}</Accordion.Title>
                    <Accordion.Content>
                      <div>
                        <div className="text-[#202969] text-lg">
                          معلومات المستند :
                        </div>
                        <ul className="mt-3">
                          <li>عدد الملفات : {item.fileCount}</li>
                          <li>حجم الملف : {item.fileSize}</li>
                          <li>
                            نوع حجم الملف :{' '}
                            {item.standardProofFileSizeTypeTitle}
                          </li>
                        </ul>
                      </div>
                      <div className="mt-7">
                        <div className="text-[#202969] text-lg">
                          الجهات الحكومية الخاصة بالمستند :
                        </div>
                        {item?.agencyStandardProofs.length == 0 ? (
                          <div className="mt-3">لا يوجد جهات</div>
                        ) : (
                          <ul className="mt-3">
                            {item?.agencyStandardProofs?.map(
                              (subItem, index) => (
                                <li>{subItem.agencyNameInArabic}</li>
                              )
                            )}
                          </ul>
                        )}
                      </div>
                    </Accordion.Content>
                  </Accordion.Panel>
                ))}
              </Accordion>
            </div>
          }
          open={openModal}
          titleButton={t('modules.main.users.entities.titleAddButton')}
          widthButton={false}
          // action={handleSubmitAddAgency}
          actions={false}
          type={'submit'}
          width={160}
          setOpen={() => setOpenModal(false)}
          headerTitle={t('المستندات')}
        />
        <span
          onClick={() => setOpenModal(true)}
          className={`text-center align-baseline inline-flex px-4 py-2 mr-auto items-center font-semibold tracking-wide	 text-sm leading-none rounded-lg bg-[#f0ad4e30] text-[#f0ad4e] cursor-pointer`}
        >
          عرض
        </span>
      </div>
    );
  }

  const downloadExcel = useExcelExport();

  const handleFilterData = (value) => {
    if (!value) {
      setFilteredData(standards);
    } else {
      var searchData = standards.filter((item) => {
        if (
          item.code.toString().toLowerCase().includes(value.toLowerCase()) ||
          item.perspectiveTitle
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          item.interlocutorTitle
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          item.interlocutorWeight
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          item.perspectiveWeight
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          Math.round(item.weight)
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        ) {
          return item;
        }
      });
      setFilteredData(searchData);
    }
  };

  const handlePerspective = (value) => {
    setPerspective(value);
  };
  const handleInterlocutor = (value) => {
    setInterlocutor(value);
  };

  const clearSearch = () => {
    setInterlocutor(null);
    setPerspective(null);
  };

  useEffect(() => {
    getAllAgencies();
  }, []);

  const getAllAgencies = async () => {
    const res = await axiosGetRequest(environment.getAllAgency, {
      MaxResultCount: 6000,
    });

    if (res?.success) {
      const result = res?.result?.items
        ?.filter((item) => item?.nameInArabic)
        .map((item) => ({
          value: item.id,
          agencyId: item.id,
          label: item.nameInArabic?.toString() || '',
          isSelected: false,
        }));
      setAgencies(result);
    }
  };

  const handleChangeAgency = (value) => {
    setSelectedAgency(value);
  };
  return (
    <div className="bg-white rounded-[30px] m-5">
      <NewHeader />
      <Title
        iconTitle={programsIcon}
        withoutButton={true}
        onClick={() => setOpen(true)}
        title={'التقارير'}
        subTitle={'تقرير الجودة'}
        toPage={''}
        single={false}
        seconed={true}
        textStyle={{ color: 'white', fontSize: 18 }}
        titleButton="إضافة مستخدم"
        style={{ backgroundColor: '#2B2969' }}
      />

      {/* <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.js">
        <div style={{ height: '750px' }}>
            <Viewer
                fileUrl={`${process.env.PUBLIC_URL}/test.pdf`}
                // plugins={[
                //     defaultLayoutPluginInstance,
                // ]}
            />
        </div>
    </Worker> */}
      <SModal
        type={message.type}
        open={openConfirmModal}
        setOpen={() => setOpenConfirmModal(false)}
        subTitle={message.message}
      />
      <div className="px-5">
        <div className="my-5 mb-7 flex justify-end">
          <DrawerFilter
            handleSearch={() =>
              getAllStandards(environment.getStandardsReport, {
                SurveyId: localStorage.getItem('surveyId'),
                InterlocutorId: interlocutor?.value,
                PerspectiveId: perspective?.value,
              })
            }
            handleClear={clearSearch}
          >
            <div className="w-full">
              <div className="w-full">
                <DropdownMultiSelect
                  filter={true}
                  withoutLabel={false}
                  isSearchable={true}
                  value={perspective}
                  options={allPerspectivesOptions}
                  isMultiple={false}
                  name="perspective"
                  handleChange={handlePerspective}
                  label={'المنظور'}
                />
              </div>

              <DropdownMultiSelect
                filter={true}
                withoutLabel={false}
                isSearchable={true}
                value={interlocutor}
                options={allInterlocutorsOptions}
                isMultiple={false}
                name="interlocutor"
                handleChange={handleInterlocutor}
                label={' المحور'}
              />
              <DropdownMultiSelect
                filter={true}
                // isClearable={true}
                withoutLabel={false}
                isSearchable={true}
                value={selectedAgency}
                handleChange={handleChangeAgency}
                placeholder="الجهات الحكومية"
                label={'الجهات الحكومية'}
                options={agencies}
                className="relative z-30"
                isMultiple={false}
                // errorMessage={errors.agencyStandards}
              />
            </div>
          </DrawerFilter>
        </div>
        {permissionsCheck('Pages.Reports') ? (
          <div className="mb-5 pb-5">
            <CustomTable
              loading={loading}
              columns={ReportList}
              data={filteredData || standards}
              pagination={false}
              totalPages={totalPages}
              onPageChange={handlePageClick}
              currentPage={currentPage}
              withExcel
              onExportExcel={() =>
                downloadExcel('reports', filterExcel || standardsForExcel)
              }
              withSearch
              permissionPage={'Reports'}
              onSearch={handleFilterData}
              actions={false}
            />
          </div>
        ) : (
          <div className="text-[#bb2124] text-xl text-center flex justify-center items-center">
            {'ليس لديك صلاحية لهذا'}
          </div>
        )}
      </div>
    </div>
  );
};

export default Reports;
