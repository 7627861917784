import { useContext, useEffect, useState } from 'react';
import FlipCard from '../../../../modules/main/home/FlipCard';
import { axiosGetRequest } from '../../../../services/Request';
import environment from '../../../../services/environment';
import NewTable from '../../../../components/NewTable';
import NewTooltip from '../../../../components/NewTooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import useExcelExport from '../../../../helpers/customHooks/useExcelExport';
import { useGetIncludedAgencies } from '../api/useGetIncludedAgencies';
import { useDashboardContext } from '../../../../contexts/DashboardContext';
import { StageContext } from '../../../../contexts/stageContext';

function IncludedAgencies() {
  const { currentStage } = useContext(StageContext);
  const phaseThreeStageId = currentStage?.stageIds?.[2];
  const [excelLoading, setExcelLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [filterValue, setFilterValue] = useState(null);

  const { setIncludedAgenciesCount } = useDashboardContext();

  const downloadExcel = useExcelExport();

  const history = useHistory();

  const surveyId = localStorage.getItem('surveyId');

  const columns = [
    {
      name: 'كود الجهة',
      selector: (row) => row.agencyCode,
      header: 'agencyCode',
      accessorKey: 'agencyCode',
      width: '100px',
    },
    {
      name: 'اسم الجهة',
      selector: (row) => row.nameInArabic,
      header: 'nameInArabic',
      accessorKey: 'nameInArabic',
      cell: (row) => (
        <div className="whitespace-nowrap">{row.nameInArabic}</div>
      ),
    },
    {
      name: 'عرض',
      selector: (row) => row.id,
      header: 'id',
      accessorKey: 'id',
      center: true,
      cell: (row) => (
        <NewTooltip content={'عرض التفاصيل'}>
          <FontAwesomeIcon
            icon={faEye}
            className="text-sky-400 bg-sky-100 mr-1 rounded-full p-2 cursor-pointer"
            onClick={() => {
              history.push(`entities/${row.id}/details`);
            }}
          />
        </NewTooltip>
      ),
    },
  ];

  const {
    includedAgencies,
    includedAgenciesCount,
    isGettingIncludedAgencies,
    totalPages,
  } = useGetIncludedAgencies({ currentPage, filterValue });

  useEffect(() => {
    setIncludedAgenciesCount(includedAgenciesCount);
  }, [includedAgenciesCount]);

  const handlePageClick = (event) => {
    const newOffset = event.selected;
    setCurrentPage(newOffset);
  };

  const handleExcelExport = async () => {
    setExcelLoading(true);
    const res = await axiosGetRequest(environment.getIncludedAgencies, {
      surveyId: surveyId,
      KeyWord: filterValue,
      SkipCount: 0,
      MaxResultCount: 500,
      stageId: phaseThreeStageId,
    });

    setExcelLoading(false);

    const dataForExcel = res?.result?.items?.map((ele) => {
      return {
        agencyCode: ele.agencyCode,
        agencyName: ele.nameInArabic,
      };
    });

    downloadExcel('الجهات المشمولة', dataForExcel);
  };

  const handleFilter = (value) => {
    setFilterValue(value);
  };

  return (
    <FlipCard
      title="الجهات المشمولة"
      frontContent={
        <div className="w-full h-[400px] flex flex-col gap-5 justify-center items-center">
          <p className="text-blue font-semibold text-3xl">
            {includedAgenciesCount}
          </p>
          <p className="text-blue font-semibold text-3xl">الجهات المشمولة</p>
        </div>
      }
      backContent={
        <NewTable
          withSearch
          onSearch={handleFilter}
          withExcel
          excelLoading={excelLoading}
          onExportExcel={handleExcelExport}
          columns={columns}
          data={includedAgencies || []}
          loading={isGettingIncludedAgencies}
          pagination={true}
          totalPages={totalPages}
          onPageChange={handlePageClick}
          currentPage={currentPage}
        />
      }
    />
  );
}

export default IncludedAgencies;
