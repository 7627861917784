import { v4 } from 'uuid';
import { programsIcon } from '../../../../assets/icons/Index';

export const indicatorsBreadcumbs = [
  {
    icon: programsIcon,
    title: 'المؤشرات',
    url: '/indicators',
  },
];
export const getIndicatorDetailsBreadcumbs = (indicatorId) => [
  {
    icon: programsIcon,
    title: 'المؤشرات',
    url: '/indicators',
  },
  {
    title: 'تفاصيل المؤشر',
    url: `/indicators/${indicatorId}`,
  },
];

export const indicatorsList = [
  {
    id: v4(),
    title: 'اسم المؤشر',
    desc: 'استخدام أصول تقنية المعلومات الحكومية و الأجهزة الشخصية التقنية',
    moreLink: '/indicators/1',
  },
  {
    id: v4(),
    title: 'اسم المؤشر',
    desc: 'استخدام أصول تقنية المعلومات الحكومية و الأجهزة الشخصية التقنية',
    moreLink: '/indicators/1',
  },
  {
    id: v4(),
    title: 'اسم المؤشر',
    desc: 'استخدام أصول تقنية المعلومات الحكومية و الأجهزة الشخصية التقنية',
    moreLink: '/indicators/1',
  },
  {
    id: v4(),
    title: 'اسم المؤشر',
    desc: 'استخدام أصول تقنية المعلومات الحكومية و الأجهزة الشخصية التقنية',
    moreLink: '/indicators/1',
  },
  {
    id: v4(),
    title: 'اسم المؤشر',
    desc: 'استخدام أصول تقنية المعلومات الحكومية و الأجهزة الشخصية التقنية',
    moreLink: '/indicators/1',
  },
  {
    id: v4(),
    title: 'اسم المؤشر',
    desc: 'استخدام أصول تقنية المعلومات الحكومية و الأجهزة الشخصية التقنية',
    moreLink: '/indicators/1',
  },
  {
    id: v4(),
    title: 'اسم المؤشر',
    desc: 'استخدام أصول تقنية المعلومات الحكومية و الأجهزة الشخصية التقنية',
    moreLink: '/indicators/1',
  },
];
export const indicatorDetailsList = [
  {
    id: v4(),
    status: 'الحالة نشط',
    title: 'استخدام أصول تقنية المعلومات الحكومية و الأجهزة الشخصية التقنية',
    startDate: '22-10-2023',
    endDate: '22-10-2023',
    finalDegree: '50%',
    moreLink: '/indicators/1',
  },
  {
    id: v4(),
    status: 'الحالة نشط',
    title: 'استخدام أصول تقنية المعلومات الحكومية و الأجهزة الشخصية التقنية',
    startDate: '22-10-2023',
    endDate: '22-10-2023',
    finalDegree: '50%',
    moreLink: '/indicators/1',
  },
  {
    id: v4(),
    status: 'الحالة نشط',
    title: 'استخدام أصول تقنية المعلومات الحكومية و الأجهزة الشخصية التقنية',
    startDate: '22-10-2023',
    endDate: '22-10-2023',
    finalDegree: '50%',
    moreLink: '/indicators/1',
  },
  {
    id: v4(),
    status: 'الحالة نشط',
    title: 'استخدام أصول تقنية المعلومات الحكومية و الأجهزة الشخصية التقنية',
    startDate: '22-10-2023',
    endDate: '22-10-2023',
    finalDegree: '50%',
    moreLink: '/indicators/1',
  },
  {
    id: v4(),
    status: 'الحالة نشط',
    title: 'استخدام أصول تقنية المعلومات الحكومية و الأجهزة الشخصية التقنية',
    startDate: '22-10-2023',
    endDate: '22-10-2023',
    finalDegree: '50%',
    moreLink: '/indicators/1',
  },
];
