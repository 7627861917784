import { useEffect, useState } from 'react';
import jwt_decode from 'jwt-decode';
import { logoutUser } from '../helpers/utils';

const getTimeRemainingInSeconds = () => {
  const token = localStorage.getItem('accessToken');
  if (!token) return 0;
  const decodedToken = jwt_decode(token);
  const exp = decodedToken.exp;
  const expirationDate = new Date(exp * 1000);
  const currentTime = new Date();
  const diffInMilliseconds = expirationDate.getTime() - currentTime.getTime();
  const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
  return diffInSeconds;
};

const formatTime = (time) => {
  const minutes = Math.floor(time / 60);
  const seconds = time % 60;
  return {
    minutes: String(minutes).padStart(2, '0'),
    seconds: String(seconds).padStart(2, '0'),
  };
};

const LogoutCountDown = () => {
  const [timeLeft, setTimeLeft] = useState(null);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    let timeOutId;
    let intervalId;

    if (!showAlert) {
      setTimeLeft(getTimeRemainingInSeconds());
      timeOutId = setTimeout(
        () => {
          setShowAlert(true);
        },
        (getTimeRemainingInSeconds() - 300) * 1000
      );
    }

    if (showAlert) {
      intervalId = setInterval(() => {
        if (getTimeRemainingInSeconds() <= 0) {
          setTimeLeft(null);
          clearInterval(intervalId);
          logoutUser();
          window.location.href = '/login';
        } else {
          setTimeLeft(getTimeRemainingInSeconds() - 1);
        }
      }, 1000);
    }
    return () => {
      clearTimeout(timeOutId);
      clearInterval(intervalId);
    };
  }, [showAlert]);

  const { minutes, seconds } =
    timeLeft !== null ? formatTime(timeLeft) : { minutes: '00', seconds: '00' };

  if (!showAlert) return null;

  return (
    <div className="fixed bottom-4 left-1/2 transform -translate-x-1/2 bg-black bg-opacity-70 text-white p-4 rounded-lg shadow-xl z-50 countdown-display">
      <p>سيتم تسجيل الخروج خلال</p>
      <div className="flex items-center">
        <div className="text-center mx-4">
          <span className="text-4xl font-bold animate-pulse">{seconds}</span>
          <span className="block text-sm text-gray-300">ثواني</span>
        </div>
        <div className="text-4xl font-bold">:</div>
        <div className="text-center mx-4">
          <span className="text-4xl font-bold animate-pulse">{minutes}</span>
          <span className="block text-sm text-gray-300">دقائق</span>
        </div>
      </div>
    </div>
  );
};

export default LogoutCountDown;
