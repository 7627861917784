import NextBtn from '../components/buttons/nextBtn';
import RequirementHeader from './ui/requirementHeader';

const StandardAnswerRequirement = ({
  setMenuElement,
  children,
  hideNextBtn,
  label,
}) => {
  return (
    <>
      <div className="min-h-[440px] p-3">
        <RequirementHeader label={label} />
        <div className="w-[90%] mt-3 p-2 mr-[88px] flex flex-col items-start overflow-y-scroll">
          <div className="flex flex-col items-start w-[100%]">{children}</div>
        </div>
      </div>
      {!hideNextBtn && <NextBtn onClick={() => setMenuElement(4)} />}
    </>
  );
};

export default StandardAnswerRequirement;
