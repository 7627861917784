import React, { useContext, useRef, useState } from 'react';
import { AppInput } from '../../../../../components';
import AppTextarea from '../../../../../components/form/appTextarea';
import AppModal from '../../../../../components/Models/appModal';
import { axiosPostRequest } from '../../../../../services/Request';
import environment from '../../../../../services/environment';
import { IndicatorsContext } from '../../../../../contexts/indicatorsContext';
import { toast } from 'react-toastify';
import { ErrorMessage, Formik } from 'formik';

const AddSurveyModal = ({ openAddModal, setOpenAddModal, indicatorId }) => {
  const { addnewIndicatorSurveys } = useContext(IndicatorsContext);
  const [loading, setLoading] = useState(false);
  const [inputs, setInputs] = useState({
    indicatorId,
    title: '',
    description: '',
  });

  const handleInputChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    setLoading(true);
    const res = await axiosPostRequest(environment.addSurvey, inputs);
    setLoading(false);
    if (res?.success) {
      addnewIndicatorSurveys(res?.result);
      toast.success('تم اضافة الاستبيان');
      setOpenAddModal(false);
      setInputs({
        indicatorId,
        title: '',
        description: '',
      });
    }
  };

  const formRef = useRef(null);

  const handleSubmitAdd = () => {
    if (formRef.current) {
      formRef.current?.handleSubmit();
    }
  };

  const initialValues = {
    title: '',
    description: '',
  };

  const validate = (values) => {
    const errors = {};
    if (!inputs.title.trim()) {
      errors.title = 'هذا الحقل مطلوب';
    }
    // else if (!/^[a-zA-Z0-9\s]+$/.test(inputs.title.trim())) {
    //   errors.title = "يرجى إدخال قيمة صالحة بدون استخدام أحرف خاصة";
    // }

    return errors;
  };

  return (
    <AppModal
      headerTitle="إضافة استبيان جديد"
      open={openAddModal}
      setOpen={setOpenAddModal}
      handleSubmit={handleSubmitAdd}
      type={'submit'}
      loading={loading}
      submitLabel="إضافة"
    >
      <Formik
        initialValues={initialValues}
        validate={validate}
        innerRef={formRef}
        onSubmit={handleSubmit}
      >
        {({ handleChange, values }) => (
          <div>
            <div>
              <AppInput
                type={'text'}
                label={'إسم الاستبيان'}
                name="title"
                variant="gray"
                value={inputs.title}
                onChange={handleInputChange}
              />
              <ErrorMessage
                className="text-red-700"
                name="title"
                component="div"
              />
            </div>
            <div className="mt-3">
              <AppTextarea
                label={'الوصف'}
                name="description"
                value={inputs.description}
                onChange={handleInputChange}
              />
            </div>
          </div>
        )}
      </Formik>
    </AppModal>
  );
};

export default AddSurveyModal;
