import { useState } from 'react';
import { axiosGetRequest } from '../../services/Request';

const useAxiosGetRequest = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const getData = async (api, params) => {
    setLoading(true);
    const res = await axiosGetRequest(api, params);
    if (res?.success) {
      setData(res);
    }
    setLoading(false);
  };

  return [getData, data, loading];
};

export default useAxiosGetRequest;
