import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import Select from 'react-tailwindcss-select';

const DropdownMultiSelect = ({
  value,
  options,
  handleChange,
  isSearchable,
  isMultiple,
  placeholder,
  label,
  withoutLabel,
  key,
  isClearable,
  filter,
  className,
  loading,
  errorMessage,
  isRequired = false,
  handleDownloadChange,
  disabled = false,
}) => {
  return (
    <>
      <div
        className={
          filter
            ? 'flex flex-col container_dropdown_mult_select_white'
            : 'flex flex-col container_dropdown_mult_select'
        }
      >
        {withoutLabel ? null : (
          <label className="flex justify-between items-center label_input text-[#292069] mb-3">
            <span>
              {label}
              {isRequired && (
                <span className="mr-1">
                  <FontAwesomeIcon
                    className="w-2 text-red-700"
                    icon={faAsterisk}
                  />
                </span>
              )}
            </span>
            {handleDownloadChange && (
              <p
                onClick={handleDownloadChange}
                className="text-green_dark underline "
              >
                استعراض
              </p>
            )}
          </label>
        )}
        <Select
          value={value}
          key={key}
          loading={loading}
          onChange={handleChange}
          isMultiple={isMultiple}
          options={options}
          isSearchable={isSearchable}
          isClearable={isClearable}
          isDisabled={disabled}
          classNames={
            className || {
              list: 'h-[150px] overflow-y-scroll',
              searchBox:
                'bg-white pr-6 pb-3 pt-0 w-[100%] border-light border-[1px] leading-8 border-0 rounded-[30px] outline-none',
              tagItem: ({ item }) =>
                `max-w-[600px] whitespace-pre-line  text-white rounded-full flex flex-row items-center pr-4 pb-2 bg-[#5e6fa3]`,
              tagItemText: 'text-white text-[14px]',
              tagItemIconContainer: 'px-6',
              listItem: ({ isSelected }) =>
                `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded ${
                  isSelected
                    ? `text-[#37326d] font-semibold bg-blue-500`
                    : `text-gray-500 hover:bg-blue-100 hover:text-blue-500`
                }`,
            }
          }
          searchInputPlaceholder={'بحث'}
          placeholder={placeholder}
        />
      </div>
      {errorMessage && <p className="text-error">{errorMessage}</p>}
    </>
  );
};

export default DropdownMultiSelect;
