import React, { useContext, useEffect, useState } from 'react';
import { DropdownMultiSelect } from '../../../../../components';
import { AddStandardContext } from '../addStandard/addStandardContext';
import SubRequirementItemForEdit from './subRequirementItemForEdit';

const RequirementItemForEdit = ({
  title,
  standardRequirementClassificationId,
  index,
  subStandardRequirements,
  id,
}) => {
  const { editRequirementClassifications, setEditRequirementClassifications } =
    useContext(AddStandardContext);
  const { classifications } = useContext(AddStandardContext);
  const [requirement, setRequirement] = useState({
    title,
    standardRequirementClassificationId,
    id,
  });
  const [errors, setErrors] = useState({
    standardRequirementClassificationId: '',
  });
  const [selectedClassifications, setSelectedClassifications] = useState('');
  const classificationOptions =
    classifications?.length > 0
      ? classifications?.map((item) => ({
          label: item.title,
          value: item.id,
          id: item.id,
        }))
      : [];

  useEffect(() => {
    const selectedClass = classificationOptions?.filter(
      (item) => item?.id == standardRequirementClassificationId
    );

    setSelectedClassifications(selectedClass?.[0] || '');
  }, [classifications]);

  const handleChangeClassifications = (value) => {
    setSelectedClassifications(value);

    if (!value) {
      setErrors({
        ...errors,
        standardRequirementClassificationId: 'يجب اختيار التصنيف',
      });
    } else {
      setErrors({ ...errors, standardRequirementClassificationId: '' });
    }
    setRequirement({
      ...requirement,
      standardRequirementClassificationId: value?.id ? value?.id : 0,
    });

    const updateChangedClassifications = editRequirementClassifications?.map(
      (req) => {
        if (req?.id == id) {
          return {
            ...req,
            standardRequirementClassificationId: value?.id,
          };
        }
        return req;
      }
    );

    setEditRequirementClassifications(updateChangedClassifications);
  };

  //

  const renderedSubRequirements =
    subStandardRequirements?.length > 0 &&
    subStandardRequirements.map((item, index) => (
      <SubRequirementItemForEdit
        key={item.title}
        {...item}
        standardRequirementClassificationId={
          item?.standardRequirementClassificationId
        }
        index={index}
        reqId={id}
      />
    ));

  return (
    <div className="col-xl-12 px-1">
      <div className="border-2 border-indigo-800 rounded p-4">
        <h2 className=" mb-2 text-[16px]">{title}</h2>
        <div className="max-w-[350px] pr-3">
          <DropdownMultiSelect
            isClearable={true}
            withoutLabel={false}
            isSearchable={true}
            value={selectedClassifications}
            handleChange={handleChangeClassifications}
            label="تصنيف"
            placeholder="اكتب هنا"
            options={classificationOptions}
            isMultiple={false}
            isRequired={true}
            errorMessage={errors?.standardRequirementClassificationId}
          />
        </div>
        {subStandardRequirements?.length > 0 && (
          <div className="row gap-y-2 px-4">
            <div className="col-12">
              <h2 className="font-bold mb-2 mt-5">المتطلبات الفرعية</h2>
            </div>

            {renderedSubRequirements}
          </div>
        )}
      </div>
    </div>
  );
};

export default RequirementItemForEdit;
