import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { checkGreenCircle } from '../../../../../assets/icons/Index';
import {
  isExpert,
  isExpertManager,
  isReviewer,
} from '../../../../../helpers/isPermission';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { removeCircleDarkRed } from '../../../../../assets/icons/dashboard';

function IconBasedOnRevieweStatus({ row }) {
  if (row.isReviewed) {
    return <img src={checkGreenCircle} alt="done mark" width={'26px'} />;
  } else if (
    (isExpert || isExpertManager || isExpert) &&
    row.isReviewedByReviewer
  ) {
    return (
      <FontAwesomeIcon
        icon={faCircleExclamation}
        className="text-yellow-300 text-[24px]"
      />
    );
  } else if (isReviewer && row.isReviewed) {
    return <img src={checkGreenCircle} alt="done mark" width={'26px'} />;
  }

  return (
    <img src={removeCircleDarkRed} width={'26px'} alt="not answered standard" />
  );
}

export default IconBasedOnRevieweStatus;
