import SecondarySideLinks from './secondarySideLinks';
import Skeleton from 'react-skeleton-loader';
import HeaderWithBreadcrumbLayout from '../headerWithBreadcrumbLayout';
import classNames from 'classnames';

const SecondaryTapsLayout = ({
  linksArr,
  breadcrumbs,
  loading,
  children,
  bgWhite = false,
}) => {
  const classes = classNames(
    'flex-grow  min-h-[60vh] rounded-lg overflow-x-auto',
    {
      'bg-gray_light p-5': !bgWhite,
    }
  );
  return (
    <HeaderWithBreadcrumbLayout>
      {breadcrumbs}
      <div className="flex gap-4 pb-5">
        <div className="min-w-[280px] flex-shrink-0 rounded-lg bg-gray_light p-5">
          {loading ? (
            <Skeleton height="16px" count={4} width="100%" />
          ) : (
            <SecondarySideLinks linksArr={linksArr} />
          )}
        </div>
        <div className={classes}>{children}</div>
      </div>
    </HeaderWithBreadcrumbLayout>
  );
};

export default SecondaryTapsLayout;
