import React, { useEffect, useState } from 'react';
import { Chart as ChartJS, Legend, ArcElement, Tooltip } from 'chart.js';
import useExcelExport from '../../../../helpers/customHooks/useExcelExport';
import FlipCard from '../../../../modules/main/home/FlipCard';
import { Doughnut } from 'react-chartjs-2';
import NewTable from '../../../../components/NewTable';
import { useGetAuditorExpertFinishedStandards } from '../api/useGetAuditorExpertFinishedStandards';
import { useDashboardContext } from '../../../../contexts/DashboardContext';

const mainColor = '#1cc182';
const secondaryColor = '#c2eedd';

function AuditorFinishedStandardsPhase4() {
  ChartJS.register(ArcElement, Tooltip, Legend);
  ChartJS.defaults.font.family = 'DiodrumArabic';

  const { setAuditorsDashboardStats, setLoading } = useDashboardContext();

  const [currentPage, setCurrentPage] = useState(0);

  const {
    finishedStandards,
    isGettingAuditorExpertFinishedStandards,
    allStandardsCount,
    totalPages,
    finishedStandardsCount,
  } = useGetAuditorExpertFinishedStandards({
    currentPage,
  });

  useEffect(() => {
    setAuditorsDashboardStats((prevStats) => ({
      ...prevStats,
      finishedStandardsCount: finishedStandardsCount,
    }));
    setLoading(isGettingAuditorExpertFinishedStandards);
  }, [finishedStandardsCount, isGettingAuditorExpertFinishedStandards]);

  const handlePageClick = (event) => {
    const newOffset = event.selected;
    setCurrentPage(newOffset);
  };

  const columns = [
    {
      name: 'رقم المعيار',
      selector: (row) => row.standardCode,
      width: '100px',
    },
    {
      name: 'اسم المعيار',
      selector: (row) => row.standardTitle,
      center: true,
    },
    {
      name: 'اسم الجهة',
      selector: (row) => row.agencyNameInArabic,
      center: true,
      cell: (row) => (
        <div className="text-center">{row.agencyNameInArabic}</div>
      ),
    },
  ];

  // ---------- ChartJS -------------------
  const percentage = (finishedStandardsCount / allStandardsCount) * 100;

  const data = {
    datasets: [
      {
        label: 'معيار',
        data: [percentage, 100 - percentage],
        backgroundColor: [mainColor, secondaryColor],
        borderColor: [mainColor, secondaryColor],
        cutout: '70%',
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        enabled: false,
      },
      legend: {
        display: false,
      },

      title: {
        display: false,
        text: 'Chart.js Line Chart',
      },
    },
  };
  // ---------- ChartJS -------------------

  const downloadExcel = useExcelExport();

  return (
    <>
      <FlipCard
        title={`المعايير الكلية التي تم الانتهاء من تدقيقها (${allStandardsCount}/${finishedStandardsCount})`}
        frontContent={
          <div className="relative w-full h-[400px] flex justify-center items-center">
            <div className="h-[300px]">
              <Doughnut data={data} options={options} />
            </div>

            <div className="absolute font-semibold text-3xl top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
              {`${((finishedStandardsCount / allStandardsCount) * 100).toFixed(
                2
              )}%`}
            </div>

            <div className="absolute right-0 top-0 flex flex-col gap-5">
              <div className="flex gap-3 items-center ">
                <div
                  className="w-3 h-3"
                  style={{
                    backgroundColor: mainColor,
                  }}
                ></div>
                <p className="text-sm font-semibold whitespace-nowrap -mt-3">
                  المعايير التي تم الانتهاء من تدقيقها
                </p>
              </div>

              <div className="flex gap-3 items-center ">
                <div
                  className="w-3 h-3"
                  style={{
                    backgroundColor: secondaryColor,
                  }}
                ></div>
                <p className="text-sm font-semibold whitespace-nowrap -mt-3">
                  المعايير المتبقية
                </p>
              </div>
            </div>
          </div>
        }
        backContent={
          <>
            <NewTable
              // withExcel
              onExportExcel={() => downloadExcel('المعايير المجابة', [])}
              columns={columns}
              data={finishedStandards || []}
              loading={isGettingAuditorExpertFinishedStandards}
              pagination={true}
              totalPages={totalPages}
              onPageChange={handlePageClick}
              currentPage={currentPage}
            />
          </>
        }
      />
    </>
  );
}

export default AuditorFinishedStandardsPhase4;
