import { useQuery } from '@tanstack/react-query';
import { useContext, useState } from 'react';
import { useParams } from 'react-router';
import { StageContext } from '../../../../../../../contexts/stageContext';
import {
  axiosGetRequest,
  axiosPostRequest,
} from '../../../../../../../services/Request';
import environment from '../../../../../../../services/environment';
import ReadOnlyRequirements from '../../components/readOnlyRequirements';
import StandardAnswerRequirement from '../../standardAnswerRequirements';

const ExpertRequirementAnswerPhase4ShowingPhase2 = ({ setMenuElement }) => {
  const [selectedRequirementsTab, setSelectedRequirementsTab] =
    useState('expert');
  const { currentStage } = useContext(StageContext);
  const { entityId, standardId } = useParams();

  const phase2StageId = currentStage?.stageIds?.[1];
  const isForthStage = currentStage?.stageNumber === 4;
  const isFifthStage = currentStage?.stageNumber === 5;

  const { data: expertAnswerOnPhase2, isLoading: isGettingDashboardStats } =
    useQuery({
      queryKey: ['GetExpertAnswer', entityId, phase2StageId, standardId],
      queryFn: () =>
        axiosGetRequest(environment.getExpertAnswer, {
          agencyId: entityId,
          stageId: phase2StageId,
          standardId: standardId,
          expertId: localStorage.getItem('userId'),
        }),
      enabled: !!phase2StageId,
    });

  const { data: phaseOneAgencyAnswer } = useQuery({
    queryKey: ['prepareStandardForAnswer', standardId, entityId],
    queryFn: ({ signal }) =>
      axiosPostRequest(
        environment.prepareStandardForAnswer,
        {
          StandardId: standardId,
          AgencyId: entityId,
          StageId: currentStage?.stageIds?.[0],
        },
        null,
        true,
        true,
        false,
        signal
      ),
    enabled: !!(isForthStage || isFifthStage),
  });

  const reviewerRequirementsAnswer =
    phaseOneAgencyAnswer?.result?.standardRequirements?.map((requirement) => {
      const reqAnswer =
        expertAnswerOnPhase2?.result?.lastAnswer?.reviewerRequirementAnswers?.find(
          (reqAns) => reqAns.standardRequirementId === requirement?.id
        );

      if (reqAnswer?.reviewerSubRequirementAnswers) {
        const { reviewerSubRequirementAnswers, ...reqs } = reqAnswer;

        const subRequirements = requirement?.subStandardRequirements?.map(
          (subReq) => {
            const sub = reviewerSubRequirementAnswers?.find(
              (item) => subReq?.id === item?.subStandardRequirementId
            );

            return {
              ...subReq,
              ...sub,
              id: subReq.id,
              decision: sub?.decision,
              donotApply: sub?.donotApply,
            };
          }
        );

        return {
          ...requirement,
          ...reqs,
          decision: reqs?.decision,
          donotApply: reqs?.donotApply,
          id: requirement.id,
          recommendation: '',
          subStandardRequirements: [...subRequirements],
        };
      }

      const subStandardRequirements = requirement?.subStandardRequirements?.map(
        (item) => ({
          ...item,
          decision: 0,
          donotApply: false,
        })
      );
      return {
        ...requirement,
        decision: 0,
        donotApply: false,
        subStandardRequirements,
      };
    });

  const pocOfficerRequirementsAnswer =
    phaseOneAgencyAnswer?.result?.standardRequirements?.map((requirement) => {
      const reqAnswer =
        expertAnswerOnPhase2?.result?.lastAnswer?.pocOfficerRequirementAnswers?.find(
          (reqAns) => reqAns.standardRequirementId === requirement?.id
        );

      if (reqAnswer?.pocOfficerSubRequirementAnswers) {
        const { pocOfficerSubRequirementAnswers, ...reqs } = reqAnswer;

        const subRequirements = requirement?.subStandardRequirements?.map(
          (subReq) => {
            const sub = pocOfficerSubRequirementAnswers?.find(
              (item) => subReq?.id === item?.subStandardRequirementId
            );

            return {
              ...subReq,
              ...sub,
              id: subReq.id,
              decision: sub?.decision,
              donotApply: sub?.donotApply,
            };
          }
        );

        return {
          ...requirement,
          ...reqs,
          decision: reqs?.decision,
          donotApply: reqs?.donotApply,
          id: requirement.id,
          recommendation: '',
          subStandardRequirements: [...subRequirements],
        };
      }

      const subStandardRequirements = requirement?.subStandardRequirements?.map(
        (item) => ({
          ...item,
          decision: 0,
          donotApply: false,
        })
      );
      return {
        ...requirement,
        decision: 0,
        donotApply: false,
        subStandardRequirements,
      };
    });

  const expertRequirementsAnswer =
    phaseOneAgencyAnswer?.result?.standardRequirements?.map((requirement) => {
      const reqAnswer =
        expertAnswerOnPhase2?.result?.surveyExpertAnswer?.expertRequirementAnswers?.find(
          (reqAns) => reqAns.standardRequirementId === requirement?.id
        );

      if (reqAnswer?.expertSubRequirementAnswers) {
        const { expertSubRequirementAnswers, ...reqs } = reqAnswer;

        const subRequirements = requirement?.subStandardRequirements?.map(
          (subReq) => {
            const sub = expertSubRequirementAnswers?.find(
              (item) => subReq?.id === item?.subStandardRequirementId
            );

            return {
              ...subReq,
              ...sub,
              id: subReq.id,
              decision: sub?.decision,
              donotApply: sub?.donotApply,
            };
          }
        );

        return {
          ...requirement,
          ...reqs,
          decision: reqs?.decision,
          donotApply: reqs?.donotApply,
          id: requirement.id,
          recommendation: '',
          subStandardRequirements: [...subRequirements],
        };
      }

      const subStandardRequirements = requirement?.subStandardRequirements?.map(
        (item) => ({
          ...item,
          decision: 0,
          donotApply: false,
        })
      );
      return {
        ...requirement,
        decision: 0,
        donotApply: false,
        subStandardRequirements,
      };
    });

  return (
    <div>
      <StandardAnswerRequirement setMenuElement={setMenuElement}>
        <div className="flex py-5 w-full justify-center">
          <div className="flex bg-[#F9F9FF] py-2 px-8 rounded-3xl">
            <p
              className={
                selectedRequirementsTab == 'reviewer'
                  ? 'bg-[#2B47AD] text-white px-5 pb-2.5  rounded-2xl ml-4 cursor-pointer'
                  : 'bg-transparent px-5 pb-2.5  rounded-2xl ml-4 cursor-pointer'
              }
              onClick={() => setSelectedRequirementsTab('reviewer')}
            >
              المدقق
            </p>

            <p
              className={
                selectedRequirementsTab == 'expert'
                  ? 'bg-[#2B47AD] text-white px-5 pb-2.5  rounded-2xl ml-4 cursor-pointer'
                  : 'bg-transparent px-5 pb-2.5  rounded-2xl ml-4 cursor-pointer'
              }
              onClick={() => setSelectedRequirementsTab('expert')}
            >
              الخبير
            </p>
            <p
              className={
                selectedRequirementsTab == 'pocOfficer'
                  ? 'bg-[#2B47AD] text-white px-5 pb-2.5  rounded-2xl cursor-pointer'
                  : 'bg-transparent px-5 pb-2.5  rounded-2xl  cursor-pointer'
              }
              onClick={() => setSelectedRequirementsTab('pocOfficer')}
            >
              ضابط الاتصال
            </p>
          </div>
        </div>

        {selectedRequirementsTab === 'reviewer' && (
          <ReadOnlyRequirements
            requirements={reviewerRequirementsAnswer ?? []}
          />
        )}

        {selectedRequirementsTab === 'expert' && (
          <>
            <ReadOnlyRequirements
              requirements={expertRequirementsAnswer ?? []}
            />
          </>
        )}

        {selectedRequirementsTab === 'pocOfficer' && (
          <ReadOnlyRequirements
            requirements={pocOfficerRequirementsAnswer ?? []}
          />
        )}
      </StandardAnswerRequirement>
    </div>
  );
};

export default ExpertRequirementAnswerPhase4ShowingPhase2;
