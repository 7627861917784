import {
  answerdStandard,
  interlocator,
  prespictive,
  standards,
  unAnswerdStandard,
} from '../../../assets/icons/dashboard';
import DashboardStats from '../../../components/dashboard/DashboardStats';
import { useGetAdminDashboardStats } from './api/useGetDashboardStats';

function AdminDashboardStatsPhase1() {
  const { adminStats, isGettingDashboardStats } = useGetAdminDashboardStats();

  const list = [
    {
      id: 1,
      title: 'عدد المناظير',
      number: adminStats?.perspectivesCount,
      icon: prespictive,
      color: '#67d4ff26',
    },
    {
      id: 2,
      title: 'عدد المحاور',
      number: adminStats?.interlocutorsCount,
      icon: interlocator,
      color: '#25f81e26',
    },
    {
      id: 3,
      title: 'عدد المعايير',
      number: adminStats?.standardsCount,
      icon: standards,
      color: '#0046de25',
    },
    {
      id: 4,
      title: 'عدد الجهات الحكومية التي دخلت النظام',
      number: adminStats?.loggedAgenciesCount,
      icon: unAnswerdStandard,
      color: '#f8251e26',
    },
    {
      id: 5,
      title: 'عدد ممثلي الجهات الحكومية',
      number: adminStats?.loggedUsersCount,
      icon: prespictive,
      color: '#ffa21726',
    },

    {
      id: 7,
      title: 'عدد إجمالي الاستفسارات الواردة من الجهات',
      number: adminStats?.ticketCount,
      icon: answerdStandard,
      color: '#a6edff8f',
    },
    {
      id: 7,
      title: 'عدد الاستفسارات المجاب عليها',
      number: adminStats?.answeredTicketCount,
      icon: unAnswerdStandard,
      color: '#f7dffdcc',
    },
  ];

  return <DashboardStats lists={list} loading={isGettingDashboardStats} />;
}

export default AdminDashboardStatsPhase1;
