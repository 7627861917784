import { Link } from 'react-router-dom';

function NoSurveyFound() {
  return (
    <div className="h-screen flex justify-center items-center bg-white">
      <div className="flex flex-col gap-10 justify-center items-center p-10 bg-gray-200">
        <p className="font-semibold text-3xl text-[#202969]">
          المستخدم لم يقم باختيار مؤشر، برجاء العودة ثم اختيار مؤشر
        </p>

        <Link to={`/ChooseCurrentIndicator`}>
          <button>
            <div className="text-white py-2 pb-4  px-6 min-w-[140px] pt-1  rounded-full flex items-center justify-center mt-[5px] mb-[-5px] bg-[#1CC081] hover:bg-[#1cc081e5] transition-all text-lg">
              الرجوع الي صفحة المؤشرات
            </div>
          </button>
        </Link>
      </div>
    </div>
  );
}

export default NoSurveyFound;
