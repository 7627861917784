import { removeCircleDarkRed } from '../../../../assets/icons/dashboard';
import { checkGreenCircle } from '../../../../assets/icons/Index';
import NewTooltip from '../../../../components/NewTooltip';

export function IconBasedOnHasAttachmentStatus({ hasAttachment }) {
  if (hasAttachment) {
    return (
      <NewTooltip content={'له مرفقات'}>
        <img src={checkGreenCircle} alt="done mark" width={'26px'} />
      </NewTooltip>
    );
  }
  return (
    <NewTooltip content={'ليس له مرفقات'}>
      <img
        src={removeCircleDarkRed}
        width={'26px'}
        alt="not answered standard"
      />
    </NewTooltip>
  );
}
