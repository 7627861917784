import { useState } from 'react';
import { AppInput, DropdownMultiSelect } from '../../../../components';
import DrawerFilter from '../../../../components/DrawerFilter';
import { isAdmin } from '../../../../helpers/isPermission';

function ViewSurveysFilter({ setFilterInputs }) {
  const surveyStatus = [
    { label: 'نشط', value: true },
    { label: 'غير نشط', value: false },
  ];
  const initialValues = {
    title: null,
    isPublished: null,
  };
  const [inputs, setInputs] = useState(initialValues);
  const handleChangeInputs = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const handleChangeSurveyStatus = (val) => {
    setInputs({ ...inputs, isPublished: val.value });
  };

  return (
    <div className="flex justify-end mx-2">
      <DrawerFilter
        handleSearch={() => {
          setFilterInputs(inputs);
        }}
        handleClear={() =>
          setInputs({
            title: '',
            isPublished: null,
          })
        }
      >
        <div className="w-full">
          <AppInput
            name="title"
            value={inputs.title}
            onChange={handleChangeInputs}
            label={'اسم الاستبيان'}
            isRequired={false}
          />
        </div>

        {isAdmin && (
          <div className="w-full">
            <DropdownMultiSelect
              filter={true}
              withoutLabel={false}
              isSearchable={true}
              value={surveyStatus.find(
                (item) => item.value === inputs.isPublished
              )}
              options={surveyStatus}
              isMultiple={false}
              name="isPublished"
              handleChange={handleChangeSurveyStatus}
              placeholder={'اختر حالة الاستبيان'}
              label={'حالة الاستبيان'}
            />
          </div>
        )}
      </DrawerFilter>
    </div>
  );
}

export default ViewSurveysFilter;
