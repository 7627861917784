import { useContext } from 'react';
import { StageContext } from '../contexts/stageContext';
import NotFound from '../services/NotFound';

const CheckRoles = ({ children, permission }) => {
  const stageContext = useContext(StageContext);
  const gotPemission = JSON.parse(localStorage.getItem('perm'))?.some((obj) => {
    return Object.values(obj).some((value) => permission?.includes(value));
  });

  stageContext.setHasPermission(gotPemission);
  if (!stageContext.hasPermission && !permission?.includes('all')) {
    return <NotFound />;
  }

  return <>{children}</>;
};

export default CheckRoles;
