import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

function DashboardTabs({ tabs, activeTab, setActiveTab }) {
  const history = useHistory();
  const { search } = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(search);
    const phase = params.get('phase');

    // If there's no `phase` parameter, set it to 1 by default
    if (phase === null) {
      params.set('phase', 1);
      history.replace({ search: params.toString() });
      setActiveTab(0); // Corresponds to the first tab internally
    } else {
      setActiveTab(Number(phase) - 1); // Convert 1-indexed phase to 0-indexed tab
    }
  }, [search, setActiveTab, history]);

  const handleTabClick = (index) => {
    setActiveTab(index);
    const params = new URLSearchParams(search);
    params.set('phase', index + 1); // Store phase as 1-based index in the URL
    history.push({ search: params.toString() });
  };

  return (
    <div className="flex flex-col justify-start">
      <div className="flex flex-row">
        {tabs?.map((tab, index) => (
          <div
            key={tab.title}
            className={
              'mr-5 cursor-pointer flex flex-row items-center justify-center pb-4 pt-2 px-5 rounded-t-[10px] ' +
              (activeTab === index
                ? 'text-white bg-[#202969]'
                : 'bg-slate-100 text-[#202969]')
            }
            onClick={() => handleTabClick(index)}
          >
            <button className="font-semibold mr-2 text-center">
              {tab.title}
            </button>
          </div>
        ))}
      </div>

      <div className="w-full">
        {tabs.map((tab, index) => (
          <div key={index}>{activeTab === index && tab.content}</div>
        ))}
      </div>
    </div>
  );
}

export default DashboardTabs;
