import React from 'react';
import models from '../../../assets/icons/models.svg';
import newPen from '../../../assets/icons/newPen.svg';
import programsIcon from '../../../assets/icons/programsIcon.svg';
import plusLight from '../../../assets/icons/plusLight.svg';

import computer from '../../../assets/icons/computer.svg';
import NewTitle from '../../../components/NewTitle';
import Button from '../../../components/Button';
import Title from '../../../components/Title';

import { useHistory } from 'react-router-dom';
import CreativeCards from './CreativeCards';
import NewHeader from './../../../layouts/header/NewHeader';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
const CreativeModels = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [showForms, setShowForms] = useState(false);

  return (
    <div className="bg-white rounded-[30px] m-5">
      <NewHeader />
      {/* <button className="btn relative inline-flex items-center justify-start overflow-hidden transition-all bg-white rounded hover:bg-white group">

<span className="w-0 h-0 rounded bg-purple-600 absolute top-0 left-0 ease-out duration-500 transition-all group-hover:w-full group-hover:h-full -z-1"></span>
<span className="w-full text-black transition-colors duration-300 ease-in-out group-hover:text-white z-10">
  hover effect 2
</span>
</button> */}
      <Title
        iconTitle={programsIcon}
        title={t('النماذج الابداعية')}
        withoutButton={true}
        titleButton={t('modules.main.surveys.survey.button.add')}
        style={{ backgroundColor: '#2B2969' }}
        withIcon={true}
        icon={plusLight}
        single={true}
      />
      <div className="bg-white p-5 rounded-[30px] flex flex-col justify-center">
        <div>
          {/* <NewTitle icon={models} titlesArr={["النماذج الأبداعية"]}/> */}
          <div className="bg-[#F9F9FF] p-5 mt-5 rounded-xl flex flex-col">
            <section className="bg-white p-5 mb-5 flex flex-row rounded-xl">
              <div>
                <img src={newPen} alt="pen" />
              </div>
              <div className="mr-5">
                <h1 className="text-[#292069] text-[20px] font-bold">
                  آلية تقديم النماذج الابداعية:
                </h1>
                <ul className="text-[#7D8BAB] list-disc list-outside text-[16px]">
                  <li className="flex flex-row items-center my-3">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="ml-2"
                    >
                      <circle cx="8" cy="8" r="8" fill="#D2F2E6" />
                      <circle cx="8" cy="8" r="3" fill="#1CC081" />
                    </svg>
                    يتم استقبال 3 نماذج إبداعية لكل جهة كحد اقصى.
                  </li>
                  <li className="flex flex-row items-center my-3">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="ml-2"
                    >
                      <circle cx="8" cy="8" r="8" fill="#D2F2E6" />
                      <circle cx="8" cy="8" r="3" fill="#1CC081" />
                    </svg>
                    يتم استبعاد النماذج غير المحققة لاشتراطات القبول.
                  </li>
                  <li className="flex flex-row items-center my-3">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="ml-2"
                    >
                      <circle cx="8" cy="8" r="8" fill="#D2F2E6" />
                      <circle cx="8" cy="8" r="3" fill="#1CC081" />
                    </svg>
                    يتم تعبئة النماذج الإبداعية على نظام قياس في القسم الخاص
                    بالإبداع مع استيفاء جميع المتطلبات.
                  </li>
                </ul>
              </div>
            </section>
            <section className="bg-white p-5 mb-5 flex flex-row rounded-xl">
              <div className="w-1/6">
                <img src={computer} alt="computer" />
              </div>
              <div className="mr-5">
                <h1 className="text-[#292069] text-[20px] font-bold">
                  اشتراطات قبول النموذج:
                </h1>
                <ul className="text-[#7D8BAB] list-disc pl-3 list-outside text-[16px]">
                  <li className="flex flex-row items-center my-3">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="ml-2"
                    >
                      <circle cx="8" cy="8" r="8" fill="#D2F2E6" />
                      <circle cx="8" cy="8" r="3" fill="#1CC081" />
                    </svg>
                    ألا يكون قد سبق تقديمه في دورات قياس الماضية.
                  </li>
                  <li className="flex flex-row items-center my-3">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="ml-2"
                    >
                      <circle cx="8" cy="8" r="8" fill="#D2F2E6" />
                      <circle cx="8" cy="8" r="3" fill="#1CC081" />
                    </svg>
                    أن يكون النموذج الإبداعي مطبق فعليا.
                  </li>
                  <li className="flex flex-row items-center my-3">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="ml-2"
                    >
                      <circle cx="8" cy="8" r="8" fill="#D2F2E6" />
                      <circle cx="8" cy="8" r="3" fill="#1CC081" />
                    </svg>
                    أن لا يكون مضى على تنفيذ النموذج الإبداعي أكثر من 5 سنوات.
                  </li>
                  <li className="flex flex-row my-3">
                    <svg
                      width="30"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="ml-2 mt-2"
                    >
                      <circle cx="8" cy="8" r="8" fill="#D2F2E6" />
                      <circle cx="8" cy="8" r="3" fill="#1CC081" />
                    </svg>
                    توظيف التقنيات الناشئة* في النموذج الإبداعي. التقنيات
                    الناشئة: هي التقنيات الحديثة الداعمة لأعمال الحكومة الرقمية
                    والتي حققت طفرة نوعية في التحول الرقمي ولا تزال تطبيقاتها
                    قابلة للتطوير، مثل الذكاء الاصطناعي وإنترنت الأشياء، وسلسلة
                    الكتل، وغيرها.
                  </li>
                  <li className="flex flex-row items-center my-3">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="ml-2"
                    >
                      <circle cx="8" cy="8" r="8" fill="#D2F2E6" />
                      <circle cx="8" cy="8" r="3" fill="#1CC081" />
                    </svg>
                    استكمال بيانات النموذج الإبداعي في النظام.
                  </li>
                </ul>
              </div>
            </section>
            <section className="bg-white p-5 flex flex-row rounded-xl">
              <div>
                <img src={newPen} alt="pen" />
              </div>
              <div className="mr-5">
                <h1 className="text-[#292069] text-[20px] font-bold">
                  معايير تقييم النموذج:
                </h1>
                <ul className="text-[#7D8BAB] list-disc list-outside text-[16px]">
                  <li className="flex flex-row items-center my-3">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="ml-2"
                    >
                      <circle cx="8" cy="8" r="8" fill="#D2F2E6" />
                      <circle cx="8" cy="8" r="3" fill="#1CC081" />
                    </svg>
                    نوعية النموذج الإبداعي.
                  </li>
                  <li className="flex flex-row items-center my-3">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="ml-2"
                    >
                      <circle cx="8" cy="8" r="8" fill="#D2F2E6" />
                      <circle cx="8" cy="8" r="3" fill="#1CC081" />
                    </svg>
                    أثر تطبيق النموذج الإبداعي.
                  </li>
                  <li className="flex flex-row items-center my-3">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="ml-2"
                    >
                      <circle cx="8" cy="8" r="8" fill="#D2F2E6" />
                      <circle cx="8" cy="8" r="3" fill="#1CC081" />
                    </svg>
                    ارتباط النموذج الابداعي بأحد محاور معايير التحول الرقمي.
                  </li>
                  <li className="flex flex-row items-center my-3">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="ml-2"
                    >
                      <circle cx="8" cy="8" r="8" fill="#D2F2E6" />
                      <circle cx="8" cy="8" r="3" fill="#1CC081" />
                    </svg>
                    نضج الفكرة الإبداعية.
                  </li>
                </ul>
              </div>
            </section>
            <section className="w-full flex justify-end">
              <Button
                title={'تحميل النموذج الإبداعي'}
                style={{ width: '15rem', marginTop: '20px' }}
                onClick={() => history.push('/creativeModels/form')}
              />
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreativeModels;
