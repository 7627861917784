import { createContext, useContext, useState } from 'react';
import { axiosGetRequest } from '../../../../../services/Request';
import environment from '../../../../../services/environment';

const AnswerContext = createContext();
export const noAnswerDegreeId = 393;

function AnserContextProvider(props) {
  const [standardForAnswer, setStandardForAnswer] = useState({});
  const [loadStandardForAnswer, setLoadStandardForAnswer] = useState(false);
  const [prevAgencyAnswer, setPrevAgencyAnswer] = useState({});
  const [newAgencyAnswer, setNewAgencyAnswer] = useState({});
  const [newAdmin, setNewAdmin] = useState({});
  const [loadPrevAgencyAnswer, setLoadPrevAgencyAnswer] = useState(false);
  const [selectedPrevfilesArray, setSelectedPrevfilesArray] = useState([]);
  const [selfEvaluation, setSelfEvaluation] = useState({
    selfEvaluationCommitmentLevel: null,
    selfEvaluationDegree: null,
  });
  const [agencyDegree, setAgencyDegree] = useState({
    agencySelfEvaluationCommitmentLevel: null,
    agencySelfEvaluationDegree: null,
  });

  const [createAnswerLoading, setCreateAnswerLoading] = useState(false);
  const [createDraftAnswerLoading, setCreateDraftAnswerLoading] =
    useState(false);

  const [loadPreReviewerAnswer, setLoadPreReviewerAnswer] = useState({});
  const [newReviewerAnswer, setNewReviewerAnswer] = useState({});
  const [reviewerAnswer, setReviewerAnswer] = useState({});
  const [reviewerDegree, setReviewerDegree] = useState({
    reviewerSelfEvaluationCommitmentLevel: null,
    reviewerSelfEvaluationDegree: null,
  });

  const [loadPrePocOfficerAnswer, setLoadPrePocOfficerAnswer] = useState({});
  const [newPocOfficerAnswer, setNewPocOfficerAnswer] = useState({});
  const [pocOfficerAnswer, setPocOfficerAnswer] = useState({});
  const [pocOfficerDegree, setPocOfficerDegree] = useState({
    pocOfficerSelfEvaluationCommitmentLevel: null,
    pocOfficerSelfEvaluationDegree: null,
  });

  const [loadPreExpertAnswer, setLoadPreExpertAnswer] = useState({});
  const [newExpertAnswer, setNewExpertAnswer] = useState({});
  const [expertAnswer, setExpertAnswer] = useState({});
  const [expertDegree, setExpertDegree] = useState({
    expertSelfEvaluationCommitmentLevel: null,
    expertSelfEvaluationDegree: null,
  });

  const [loadPreExpertManagerAnswer, setLoadPreExpertManagerAnswer] = useState(
    {}
  );
  const [newExpertManagerAnswer, setNewExpertManagerAnswer] = useState({});
  const [expertManagerAnswer, setExpertManagerAnswer] = useState({});
  const [expertManagerDegree, setExpertManagerDegree] = useState({
    expertManagerSelfEvaluationCommitmentLevel: null,
    expertManagerSelfEvaluationDegree: null,
  });

  let newRequirementsAgency = '';

  newRequirementsAgency = newExpertAnswer?.standardRequirements
    ?.map((item) =>
      '<li style="list-style-type:circle" key="' +
        item.id +
        '">' +
        item.decision ==
        0 || !item.decision
        ? '<p>' +
          item.title +
          '</p>' +
          '<ul>' +
          item.subStandardRequirements
            .map((subRequirement) =>
              subRequirement.decision == 0
                ? '<li style="margin-right:50px" key="' +
                  subRequirement.id +
                  '">' +
                  subRequirement.title +
                  '</li>'
                : ''
            )
            .join('') +
          '</ul>'
        : '' +
          '<ul>' +
          item.subStandardRequirements
            .map((subRequirement) =>
              subRequirement.decision == 0
                ? '<li style="margin-right:50px" key="' +
                  subRequirement.id +
                  '">' +
                  subRequirement.title +
                  '</li>'
                : ''
            )
            .join('') +
          '</ul>' +
          '</li>'
    )
    .join('');

  if (newExpertAnswer?.newRequirementsAgency) {
    newExpertAnswer.newRequirementsAgency = newRequirementsAgency;
  }

  let newRequirementsAgencyForReviewer = '';

  newRequirementsAgencyForReviewer = newReviewerAnswer?.standardRequirements
    ?.map((item) =>
      '<li style="list-style-type:circle" key="' +
        item.id +
        '">' +
        item.decision ==
        0 || !item.decision
        ? '<p>' +
          item.title +
          '</p>' +
          '<ul>' +
          item.subStandardRequirements
            .map((subRequirement) =>
              subRequirement.decision == 0
                ? '<li style="margin-right:50px" key="' +
                  subRequirement.id +
                  '">' +
                  subRequirement.title +
                  '</li>'
                : ''
            )
            .join('') +
          '</ul>'
        : '' +
          '<ul>' +
          item.subStandardRequirements
            .map((subRequirement) =>
              subRequirement.decision == 0 || !subRequirement.decision
                ? '<li style="margin-right:50px" key="' +
                  subRequirement.id +
                  '">' +
                  subRequirement.title +
                  '</li>'
                : ''
            )
            .join('') +
          '</ul>' +
          '</li>'
    )
    .join('');

  newReviewerAnswer.newRequirementsAgency = newRequirementsAgencyForReviewer;

  let newRequirementsReviewer = '';

  newRequirementsReviewer = newExpertAnswer?.standardRequirements
    ?.map((item) =>
      '<li style="list-style-type:circle" key="' +
        item.id +
        '">' +
        item.decision ==
        0 || !item.decision
        ? '<p>' +
          item.title +
          '</p>' +
          '<ul>' +
          item.subStandardRequirements
            .map((subRequirement) =>
              subRequirement.decision == 0
                ? '<li style="margin-right:50px" key="' +
                  subRequirement.id +
                  '">' +
                  subRequirement.title +
                  '</li>'
                : ''
            )
            .join('') +
          '</ul>'
        : '' +
          '<ul>' +
          item.subStandardRequirements
            .map((subRequirement) =>
              subRequirement.decision == 0 || !subRequirement.decision
                ? '<li style="margin-right:50px" key="' +
                  subRequirement.id +
                  '">' +
                  subRequirement.title +
                  '</li>'
                : ''
            )
            .join('') +
          '</ul>' +
          '</li>'
    )
    .join('');

  if (newExpertAnswer?.newRequirementsReviewer) {
    newExpertAnswer.newRequirementsReviewer = newRequirementsReviewer;
  }

  let newRequirementsAgencyForExpertManager = '';

  newRequirementsAgencyForExpertManager =
    newExpertManagerAnswer?.standardRequirements
      ?.map((item) =>
        '<li style="list-style-type:circle" key="' +
          item.id +
          '">' +
          item.decision ==
          0 || !item.decision
          ? '<p>' +
            item.title +
            '</p>' +
            '<ul>' +
            item.subStandardRequirements
              .map((subRequirement) =>
                subRequirement.decision == 0
                  ? '<li style="margin-right:50px" key="' +
                    subRequirement.id +
                    '">' +
                    subRequirement.title +
                    '</li>'
                  : ''
              )
              .join('') +
            '</ul>'
          : '' +
            '<ul>' +
            item.subStandardRequirements
              .map((subRequirement) =>
                subRequirement.decision == 0 || !subRequirement.decision
                  ? '<li style="margin-right:50px" key="' +
                    subRequirement.id +
                    '">' +
                    subRequirement.title +
                    '</li>'
                  : ''
              )
              .join('') +
            '</ul>' +
            '</li>'
      )
      .join('');

  if (newExpertManagerAnswer?.newRequirementsAgencyForExpertManager) {
    newExpertManagerAnswer.newRequirementsAgencyForExpertManager =
      newRequirementsAgencyForExpertManager;
  }

  let newRequirementsReviewerForExpertManager = '';

  newRequirementsReviewerForExpertManager =
    newExpertManagerAnswer?.standardRequirements
      ?.map((item) =>
        '<li style="list-style-type:circle" key="' +
          item.id +
          '">' +
          item.decision ==
          0 || !item.decision
          ? '<p>' +
            item.title +
            '</p>' +
            '<ul>' +
            item.subStandardRequirements
              .map((subRequirement) =>
                subRequirement.decision == 0
                  ? '<li style="margin-right:50px" key="' +
                    subRequirement.id +
                    '">' +
                    subRequirement.title +
                    '</li>'
                  : ''
              )
              .join('') +
            '</ul>'
          : '' +
            '<ul>' +
            item.subStandardRequirements
              .map((subRequirement) =>
                subRequirement.decision == 0 || !subRequirement.decision
                  ? '<li style="margin-right:50px" key="' +
                    subRequirement.id +
                    '">' +
                    subRequirement.title +
                    '</li>'
                  : ''
              )
              .join('') +
            '</ul>' +
            '</li>'
      )
      .join('');

  if (newExpertManagerAnswer?.newRequirementsReviewerForExpertManager) {
    newExpertManagerAnswer.newRequirementsReviewerForExpertManager =
      newRequirementsReviewerForExpertManager;
  }

  let newRequirementsForPOCOfficer = '';

  newRequirementsForPOCOfficer = newPocOfficerAnswer?.standardRequirements
    ?.map((item) =>
      '<li style="list-style-type:circle" key="' +
        item.id +
        '">' +
        (item.decision == 0 || !item.decision) && item?.donotApply == false
        ? '<p>' +
          item.title +
          '</p>' +
          '<ul>' +
          item.subStandardRequirements
            .map((subRequirement) =>
              subRequirement.decision == 0 &&
              subRequirement?.donotApply == false
                ? '<li style="margin-right:50px" key="' +
                  subRequirement.id +
                  '">' +
                  subRequirement.title +
                  '</li>'
                : ''
            )
            .join('') +
          '</ul>'
        : '<ul>' +
          item.subStandardRequirements
            .map((subRequirement) =>
              subRequirement.decision == 0 &&
              subRequirement?.donotApply == false
                ? '<li style="margin-right:50px" key="' +
                  subRequirement.id +
                  '">' +
                  subRequirement.title +
                  '</li>'
                : ''
            )
            .join('') +
          '</ul>' +
          '</li>'
    )
    .join('');

  if (newPocOfficerAnswer.newRequirementsForPOCOfficer) {
    newPocOfficerAnswer.newRequirementsForPOCOfficer =
      newRequirementsForPOCOfficer;
  }

  const convertCheckedDegreesParams = (requirements) => {
    const checkedRequirements = [];
    const checkedSubRequirements = [];
    const checkedDonotApplyRequirements = [];
    const checkedDonotApplySubRequirements = [];

    requirements?.forEach((requirement) => {
      if (requirement?.decision == 1) {
        checkedRequirements.push(requirement?.id);
      } else if (requirement?.donotApply) {
        checkedDonotApplyRequirements.push(requirement?.id);
      }

      if (requirement?.subStandardRequirements?.length > 0) {
        requirement?.subStandardRequirements?.forEach((subReq) => {
          if (subReq?.decision == 1) {
            checkedSubRequirements.push(subReq?.id);
          } else if (subReq?.donotApply) {
            checkedDonotApplySubRequirements.push(subReq?.id);
          }
        });
      }
    });

    return {
      checkedRequirements,
      checkedSubRequirements,
      checkedDonotApplyRequirements,
      checkedDonotApplySubRequirements,
    };
  };

  const getSelfEvaluation = async (
    standardId,
    reqs,
    agencyId,
    role = 'expert'
  ) => {
    const {
      checkedRequirements,
      checkedSubRequirements,
      checkedDonotApplyRequirements,
      checkedDonotApplySubRequirements,
    } = convertCheckedDegreesParams(reqs);

    const requirements =
      checkedRequirements
        ?.map((reqId) => `&CheckedRequirementsIds=${reqId}`)
        .join('') || '';

    const subRequirements =
      checkedSubRequirements
        ?.map((subReqId) => `&CheckedSubRequirementsIds=${subReqId}`)
        .join('') || '';

    const donotApplyRequirements =
      checkedDonotApplyRequirements
        ?.map((donotApplyId) => `&DonotAppliedRequirementsIds=${donotApplyId}`)
        .join('') || '';

    const donotApplySubRequirements =
      checkedDonotApplySubRequirements
        ?.map(
          (donotApplyId) => `&DonotAppliedSubRequirementsIds=${donotApplyId}`
        )
        .join('') || '';

    const commitmentLevel = await axiosGetRequest(
      `${environment.getSuggestedDegree}?standardId=${
        standardId +
        '&AgencyId=' +
        agencyId +
        requirements +
        subRequirements +
        donotApplyRequirements +
        donotApplySubRequirements
      }`
    );

    role === 'agency' &&
      setAgencyDegree({
        ...agencyDegree,
        agencySelfEvaluationCommitmentLevel:
          await commitmentLevel?.result?.commitmentLevel,
        agencySelfEvaluationDegree:
          await commitmentLevel?.result?.suggestedDegree,
      });

    role === 'reviewer' &&
      setReviewerDegree({
        ...reviewerDegree,
        reviewerSelfEvaluationCommitmentLevel:
          await commitmentLevel?.result?.commitmentLevel,
        reviewerSelfEvaluationDegree:
          await commitmentLevel?.result?.suggestedDegree,
      });
    role === 'pocOfficer' &&
      setPocOfficerDegree({
        ...pocOfficerDegree,
        pocOfficerSelfEvaluationCommitmentLevel:
          await commitmentLevel?.result?.commitmentLevel,
        pocOfficerSelfEvaluationDegree:
          await commitmentLevel?.result?.suggestedDegree,
      });

    role === 'expert' &&
      setExpertDegree({
        ...expertDegree,
        expertSelfEvaluationCommitmentLevel:
          await commitmentLevel?.result?.commitmentLevel,
        expertSelfEvaluationDegree:
          await commitmentLevel?.result?.suggestedDegree,
      });

    role === 'expertManager' &&
      setExpertManagerDegree({
        ...expertManagerDegree,
        expertManagerSelfEvaluationCommitmentLevel:
          await commitmentLevel?.result?.commitmentLevel,
        expertManagerSelfEvaluationDegree:
          await commitmentLevel?.result?.suggestedDegree,
      });
  };

  return (
    <AnswerContext.Provider
      value={{
        standardForAnswer,
        setStandardForAnswer,
        prevAgencyAnswer,
        setPrevAgencyAnswer,
        newAgencyAnswer,
        setNewAgencyAnswer,
        loadStandardForAnswer,
        setLoadStandardForAnswer,
        loadPrevAgencyAnswer,
        setLoadPrevAgencyAnswer,
        selectedPrevfilesArray,
        setSelectedPrevfilesArray,
        convertCheckedDegreesParams,
        getSelfEvaluation,
        selfEvaluation,
        setSelfEvaluation,
        agencyDegree,
        setAgencyDegree,

        createAnswerLoading,
        setCreateAnswerLoading,

        createDraftAnswerLoading,
        setCreateDraftAnswerLoading,

        newReviewerAnswer,
        setNewReviewerAnswer,
        reviewerAnswer,
        setReviewerAnswer,
        reviewerDegree,
        setReviewerDegree,
        loadPreReviewerAnswer,
        setLoadPreReviewerAnswer,

        newPocOfficerAnswer,
        setNewPocOfficerAnswer,
        pocOfficerAnswer,
        setPocOfficerAnswer,
        pocOfficerDegree,
        setPocOfficerDegree,
        loadPrePocOfficerAnswer,
        setLoadPrePocOfficerAnswer,

        newExpertAnswer,
        setNewExpertAnswer,
        expertAnswer,
        setExpertAnswer,
        expertDegree,
        setExpertDegree,
        loadPreExpertAnswer,
        setLoadPreExpertAnswer,

        newExpertManagerAnswer,
        setNewExpertManagerAnswer,
        expertManagerAnswer,
        setExpertManagerAnswer,
        expertManagerDegree,
        setExpertManagerDegree,
        loadPreExpertManagerAnswer,
        setLoadPreExpertManagerAnswer,

        newAdmin,
        setNewAdmin,
      }}
    >
      {props.children}
    </AnswerContext.Provider>
  );
}

export { AnserContextProvider, AnswerContext };

export const useAnswerContext = () => useContext(AnswerContext);
