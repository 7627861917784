const agencyType = {
  1: 'جهة رئيسية لجهة أخرى',
  2: 'جهة فرعية من جهة أخرى',
  3: 'جهة رئيسية لجهة أخرى وفرعية من جهة أخرى',
  4: 'جهة مستقلة',
};

function EntityExtraData({ agencyDetails }) {
  return (
    <>
      <p className="text-lg mb-4 mt-9">معلومات إضافيــة</p>
      <div className="bg-white rounded-xl p-6 pb-7 flex flex-col gap-3">
        <div className="grid grid-cols-2 gap-4">
          <div className="w-full">
            <p>تاريخ تأسيس الجهة</p>
            <div className="flex gap-3 mt-3 pr-6 items-center p-3 rounded-full bg-[#F9F9FF]">
              <p className="mb-1">
                {agencyDetails?.agencyEstablishingDate?.slice(0, 10) || '-'}
              </p>
            </div>
          </div>

          <div className="w-full">
            <p>نوع الجهة</p>
            <div className="flex gap-3 mt-3 pr-6 items-center p-3 rounded-full bg-[#F9F9FF]">
              <p className="mb-1">
                {agencyType[agencyDetails?.agencyType] || '-'}
              </p>
            </div>
          </div>
        </div>

        {agencyDetails?.isSubAgency && (
          <div className="grid grid-cols-2 gap-4">
            <div className="w-full">
              <p>الجهة الرئيسية</p>
              <div className="flex gap-3 mt-3 pr-6 items-center p-3 rounded-full bg-[#F9F9FF]">
                <p className="mb-1">{agencyDetails?.parentAgencyName || '-'}</p>
              </div>
            </div>

            <div className="w-full">
              <p>نوع الاشتراك</p>
              <div className="flex gap-3 mt-3 pr-6 items-center p-3 rounded-full bg-[#F9F9FF]">
                <p className="mb-1">
                  {agencyDetails?.digitalTransformationSharingLevelName || '-'}
                </p>
              </div>
            </div>
          </div>
        )}

        <div className="grid grid-cols-2 gap-4">
          <div className="w-full">
            <p>عدد الموظفين</p>
            <div className="flex gap-3 mt-3 pr-6 items-center p-3 rounded-full bg-[#F9F9FF]">
              <p className="mb-1">{agencyDetails?.employeesCount || '-'}</p>
            </div>
          </div>
          <div className="w-full">
            <p>عدد الخدمات</p>
            <div className="flex gap-3 mt-3 pr-6 items-center p-3 rounded-full bg-[#F9F9FF]">
              <p className="mb-1">{agencyDetails?.servicesCount || '-'}</p>
            </div>
          </div>
          <div className="w-full">
            <p>الميزانية السنوية لتقنية المعلومات</p>
            <div className="flex gap-3 mt-3 pr-6 items-center p-3 rounded-full bg-[#F9F9FF]">
              <p className="mb-1">
                {agencyDetails?.technologyDepartmentAnnuualBudget || '-'}
              </p>
            </div>
          </div>
          <div className="w-full">
            <p>نوع المستفيدين</p>
            <div className="flex flex-wrap gap-3 mt-3 pr-6 items-center p-3 rounded-full bg-[#F9F9FF]">
              {agencyDetails?.agencyBeneficiaries?.length > 0
                ? agencyDetails?.agencyBeneficiaries?.map((ele) => (
                    <p
                      className="mb-1 px-6 py-1 pb-3 bg-slate-300 rounded-3xl"
                      key={ele.beneficiariesTypeName}
                    >
                      {ele.beneficiariesTypeName}
                    </p>
                  ))
                : '-'}
            </div>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4">
          {agencyDetails?.isHasConsultantCompany && (
            <div className="w-full">
              <p>اسم الشركة الاستشارية</p>
              <div className="flex gap-3 mt-3 pr-6 items-center p-3 rounded-full bg-[#F9F9FF]">
                <p className="mb-1">
                  {agencyDetails?.consultantCompanyName || '-'}
                </p>
              </div>
            </div>
          )}

          {agencyDetails?.actualJobTitle && (
            <div className="w-full">
              <p>المسمى الوظيفي الفعلي لل CIO</p>
              <div className="flex gap-3 mt-3 pr-6 items-center p-3 rounded-full bg-[#F9F9FF]">
                <p className="mb-1">{agencyDetails?.actualJobTitle || '-'}</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default EntityExtraData;
