import * as signalR from '@microsoft/signalr';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import LogoutCountDown from './components/logoutCountDown';
import { GlobalProvider } from './contexts/globalContext';
import LoginWithNafath from './modules/auth/LoginWithNafath';
import Logout from './modules/auth/Logout';
import IndicatorAfterLogin from './modules/main/home/IndicatorAfterLogin';
import RaqmiPage from './modules/main/home/RaqmiPage';
import { Home, Login } from './routes/Index';
import NoSurveyFound from './routes/NoSurveyFound';
import ProtectedRoute from './routes/ProtectedRoute ';
import { routes } from './routes/Routes';

const signalRConnect = async () => {
  const connection = new signalR.HubConnectionBuilder()
    .withUrl(
      `${process.env.REACT_APP_URL}/dashboardReportHub`,
      signalR.HttpTransportType.LongPolling
    )
    .withAutomaticReconnect()
    .build();

  connection.start().then((res1) => {
    localStorage.setItem('connectionId', connection.connectionId);
    connection.on('ReceivingAnswerReports', (message) => {
      document.dispatchEvent(
        new CustomEvent('reportReady', { detail: message })
      );
    });

    connection.on('ReceivingCopyIndicator', (message) => {
      console.log('🚀 ~ connection.on ~ message:', message);

      document.dispatchEvent(
        new CustomEvent('ReceivingCopyIndicator', { detail: message })
      );
    });
  });

  return connection;
};

function App() {
  useEffect(() => {
    const connection = signalRConnect();
    return () => {
      if (connection && typeof connection.stop === 'function') {
        connection.stop();
      }
    };
  }, []);

  const [queryClient] = useState(
    new QueryClient({
      defaultOptions: {
        queries: {
          staleTime: 0,
        },
      },
    })
  );

  return (
    <QueryClientProvider client={queryClient}>
      <div style={{ display: 'flex', height: '100vh', overflow: 'hidden' }}>
        <GlobalProvider>
          <div className="flex w-[100%]">
            {window.location.pathname !== '/login' &&
            localStorage.getItem('accessToken') != null &&
            localStorage.getItem('surveyId') != null &&
            window.location.pathname !== '/ChooseCurrentIndicator' &&
            window.location.pathname !==
              `/survey/${localStorage.getItem('surveyId')}/polices/accept` &&
            window.location.pathname !== '/hello' &&
            window.location.pathname !== '/notFound' &&
            window.location.pathname !== '/NoSurveyFound' &&
            window.location.pathname !== '/login' ? (
              <Home />
            ) : null}
            <div
              className={
                window.location.pathname !== '/login' &&
                localStorage.getItem('accessToken') != null
                  ? window.location.pathname === '/ChooseCurrentIndicator' ||
                    window.location.pathname ===
                      `/survey/${localStorage.getItem(
                        'surveyId'
                      )}/polices/accept` ||
                    window.location.pathname === '/hello' ||
                    window.location.pathname === '/notFound' ||
                    window.location.pathname === '/qyias/auth/admin'
                    ? 'w-[100%] h-[100vh] overflow-y-scroll'
                    : 'w-[100%] h-[100vh] overflow-y-scroll bg-[#292069]'
                  : 'w-[100%]'
              }
            >
              {!localStorage.getItem('accessToken') ? (
                <Switch>
                  <Route path="/hello" component={LoginWithNafath} />
                  <Route path="/Nafath" component={RaqmiPage} />

                  {process.env.REACT_APP_LOGIN_URL !== 'NULL' && (
                    <Route
                      path="/8G7kJ2fL1pQxR9sD0wZ4bC6tN5vM8uY"
                      component={Login}
                    />
                  )}

                  <Redirect to={'/hello'} />
                </Switch>
              ) : !localStorage.getItem('surveyId') ? (
                <Switch>
                  <Route
                    path="/ChooseCurrentIndicator"
                    component={IndicatorAfterLogin}
                  />
                  <Route path="/NoSurveyFound" component={NoSurveyFound} />
                  <Route path="/logout" component={Logout} />

                  <Redirect to={'/NoSurveyFound'} />
                </Switch>
              ) : (
                <>
                  <Switch>
                    {routes.map((route, index) => (
                      <ProtectedRoute
                        key={index}
                        path={route.path}
                        exact={route.exact}
                        component={route.component}
                      />
                    ))}
                    <Redirect to={'/'} />
                  </Switch>
                  <LogoutCountDown />
                </>
              )}
            </div>
          </div>
        </GlobalProvider>
        <ToastContainer />
      </div>
    </QueryClientProvider>
  );
}

export default App;
