import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chart } from 'chart.js';
import { Badge } from 'flowbite-react';
import { useState } from 'react';
import { Bar } from 'react-chartjs-2';
import AppDivider from '../../../../components/appDivider';
import AppLoader from '../../../../components/loader';
import AppModal from '../../../../components/Models/appModal';
import NewTable from '../../../../components/NewTable';
import NewTooltip from '../../../../components/NewTooltip';
import useExcelExport from '../../../../helpers/customHooks/useExcelExport';
import FlipCard from '../../../../modules/main/home/FlipCard';
import { useGetBestFiveVisitors } from '../api/useGetBestFiveVisitors';

function BestFiveVisitors() {
  const [openModal, setOpenModal] = useState(false);
  const [answerdStandards, setAnswerdStandards] = useState([]);

  const { bestFiveVisitors, isGettingBestFiveVisitors } =
    useGetBestFiveVisitors();

  const getStandardRatioBadge = (ratio) => {
    let badgeColor = '#3498db';

    if (ratio === 0) {
      badgeColor = '#575757';
    } else if (ratio < 50) {
      badgeColor = '#f05868';
    } else if (ratio < 70) {
      badgeColor = '#ffa92b';
    } else if (ratio === 100) {
      badgeColor = '#1cc182';
    }

    return (
      <Badge
        className="text-white text-[16px] font-medium pb-3 px-5 rounded-xl"
        size="sm"
        style={{ backgroundColor: badgeColor }}
      >
        {`${ratio?.toFixed(2)}%`}
      </Badge>
    );
  };

  const columns = [
    {
      name: 'اسم ممثل الجهة',
      selector: (row) => row.userName,
      header: 'userName',
      accessorKey: 'userName',
      cell: (row) => <div className="whitespace-nowrap">{row.userName}</div>,
    },
    {
      name: 'اسم الجهة',
      selector: (row) => row.agencyName,
      header: 'agencyName',
      accessorKey: 'agencyName',
      cell: (row) => <div className="text-center">{row.agencyName}</div>,
      center: true,
    },
    {
      name: 'عدد المعايير المجابة بواسطة الجهة',
      selector: (row) => row.answeredAgencyStandardsCount,
      header: 'answeredAgencyStandardsCount',
      accessorKey: 'answeredAgencyStandardsCount',
      center: true,
    },

    {
      name: 'عدد المعايير المجابة بواسطة ممثل الجهة',
      selector: (row) => row.answerCount,
      header: 'answerCount',
      accessorKey: 'answerCount',
      center: true,
    },

    {
      name: 'نسبة اٍجابات ممثل الجهة',
      selector: (row) => row.userAnsweredStanadrdsRatio,
      header: 'userAnsweredStanadrdsRatio',
      accessorKey: 'userAnsweredStanadrdsRatio',
      center: true,
      cell: (row) => getStandardRatioBadge(row.userAnsweredStanadrdsRatio),
    },

    {
      name: 'المعايير',
      selector: (row) => row.id,
      header: 'id',
      accessorKey: 'id',
      center: true,
      width: '150px',
      cell: (row) => (
        <>
          {row.standardCount != 0 && (
            <NewTooltip content={'عرض المعايير'}>
              <FontAwesomeIcon
                icon={faEye}
                className="text-sky-400 bg-sky-100 mr-1 rounded-full p-2 cursor-pointer"
                onClick={() => {
                  setOpenModal(true);
                  setAnswerdStandards(row.answeredStandrds);
                }}
              />
            </NewTooltip>
          )}
        </>
      ),
    },
  ];

  // ---------- ChartJS -------------------
  const labels = bestFiveVisitors?.map((ele) => `${ele.userName}`);
  Chart.defaults.font.size = 14;
  Chart.defaults.font.weight = 'bold';
  Chart.defaults.font.family = 'DiodrumArabic';

  const chartData = {
    labels,
    datasets: [
      {
        label: 'عدد المعايير المجاب عنها بواسطة هذه الجهة',
        data: bestFiveVisitors?.map((ele) => ele.answeredAgencyStandardsCount),
        backgroundColor: '#35ccb8',
        barThickness: 50,
      },
      // {
      //   label: "عدد المعايير المجاب عنها بواسطة هذه الجهة",
      //   // data: data?.map((ele) => ele.asignedStandardCount),
      //   data: [10, 40],
      //   backgroundColor: "#6ccc35",
      //   barThickness: 50,
      // },
      {
        label: 'عدد المعايير المجاب عنها بواسطة ممثل الجهة',
        data: bestFiveVisitors?.map((ele) => ele.answerCount),
        backgroundColor: '#fccb2b',
        barThickness: 50,
      },
    ],
  };

  const options = {
    type: 'bar',
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: false,
        text: 'أكثر خمس ممثلين جهات نشطين',
        font: {
          size: 17,
          family: 'DiodrumArabic', // Apply custom font
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        minmax: 10,
        ticks: {
          stepSize: 1,
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };
  // ---------- ChartJS -------------------

  const downloadExcel = useExcelExport();

  const excelData = bestFiveVisitors?.map((item) => {
    return {
      'اسم ممثل الجهة': item?.userName,
      'اسم الجهة': item?.agencyName,
      'عدد المعايير المجابة بواسطة الجهة': item?.answeredAgencyStandardsCount,
      'عدد المعايير المجابة بواسطة ممثل الجهة': item?.answerCount,
      'نسبة اٍجابات ممثل الجهة': `${item?.userAnsweredStanadrdsRatio?.toFixed(
        2
      )}%`,
      'المعايير المجابة': item?.answeredStandrds
        .map((ele) => `(${ele.standardCode})`)
        .join(', '),
    };
  });

  return (
    <FlipCard
      title="أكثر خمس ممثلين جهات نشطين"
      frontContent={
        <div className="relative h-full w-full flex justify-center items-center">
          <div className="h-[400px] w-full flex justify-center items-center">
            {isGettingBestFiveVisitors ? (
              <AppLoader />
            ) : (
              <Bar data={chartData} options={options} />
            )}
          </div>
        </div>
      }
      backContent={
        <>
          <NewTable
            withExcel
            onExportExcel={() =>
              downloadExcel('أكثر خمس ممثلين جهات نشطين', excelData)
            }
            columns={columns}
            data={bestFiveVisitors || []}
            loading={isGettingBestFiveVisitors}
            pagination={false}
          />

          <AppModal
            isFooter={false}
            headerTitle={'المعايير المجابة بواسطة ممثل الجهة'}
            open={openModal}
            setOpen={setOpenModal}
          >
            <div className="text-xl h-full flex flex-col gap-1">
              {answerdStandards?.map((standard, index) => (
                <>
                  <div className="flex gap-4 items-center">
                    {index + 1} {' - '}
                    <span className="font-semibold">
                      {standard.standardCode}
                    </span>
                  </div>
                  {answerdStandards?.length !== index + 1 && <AppDivider />}
                </>
              ))}
            </div>
          </AppModal>
        </>
      }
    />
  );
}

export default BestFiveVisitors;
