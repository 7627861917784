import React from 'react';
import AppButton from '../../../../../../../components/buttons/appButton';

const NextBtn = ({ onClick }) => {
  return (
    <div className=" sticky bottom-0 left-0 w-full h-16 ">
      <div className="bg-white_gred h-full flex justify-end items-start pl-8">
        <AppButton className="text-lg" onClick={onClick}>
          التالى
        </AppButton>
      </div>
    </div>
  );
};

export default NextBtn;
