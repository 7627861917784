import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import NewCard from '../../../../components/dashboard/NewCard';
import NewTable from '../../../../components/NewTable';
import { useAccountMangerDashboardContext } from '../../../../contexts/AccountMangerDashboardContext';
import { StageContext } from '../../../../contexts/stageContext';
import environment from '../../../../services/environment';
import { axiosGetRequest } from '../../../../services/Request';

const UnstanderedCard = ({ title }) => {
  const history = useHistory();

  const { currentStage, stageForDashboard } = useContext(StageContext);
  const [currentPage, setCurrentPage] = useState(0);
  const [count, setCount] = useState(0);
  const [notAnsweredStandards, setNotAnsweredStandards] = useState([]);
  const [loading, setLoading] = useState(false);
  const phase2StageId = currentStage?.stageIds?.[1];
  const { selectedAgency } = useAccountMangerDashboardContext();
  const agencyId = selectedAgency?.value;

  const getNotAnsweredStandards = async (page) => {
    setLoading(true);

    if (currentStage?.id) {
      const res = await axiosGetRequest(
        environment.getNotAnsweredStandardForAgency,
        {
          surveyStageId: stageForDashboard,
          agencyId: agencyId,
          MaxResultCount: 5,
          SkipCount: page * 5,
          stageId: phase2StageId,
        }
      );
      if (res?.success) {
        setNotAnsweredStandards(res?.result?.items);
        setCount(res?.result?.totalCount);
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    getNotAnsweredStandards(0);
  }, [stageForDashboard, agencyId]);

  const handlePageClick = (event) => {
    const newOffset = event.selected;
    setCurrentPage(newOffset);
    getNotAnsweredStandards(newOffset);
  };

  const columns = [
    {
      name: 'رقم المعيار',
      selector: (row) => row.standardCode,
      header: 'رقم المعيار',
      accessorKey: 'standardCode',
      footer: (props) => props.column.standardCode,
      width: '150px',
    },
    {
      name: 'اسم المعيار',
      selector: (row) => row.standardTitle,
      header: 'اسم المعيار',
      accessorKey: 'standardTitle',
      footer: (props) => props.column.standardTitle,
    },

    {
      name: 'عدد المتطلبات',
      selector: (row) => row.requirementsCount,
      header: 'عدد المتطلبات',
      accessorKey: 'requirementsCount',
      footer: (props) => props.column.requirementsCount,
      center: true,
    },
    {
      name: 'عدد المستندات',
      selector: (row) => row.proofsCount,
      header: 'عدد المستندات',
      accessorKey: 'proofsCount',
      footer: (props) => props.column.proofsCount,
      center: true,
    },
  ];
  return (
    <NewCard title={title || 'نسبة الانجاز'}>
      <NewTable
        loading={loading}
        columns={columns}
        data={notAnsweredStandards || []}
        pagination={true}
        totalPages={Math.ceil(count / 5)}
        onPageChange={handlePageClick}
        currentPage={currentPage}
      />
    </NewCard>
  );
};

export default UnstanderedCard;
