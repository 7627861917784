import { avatar } from '../../assets/icons/Index';
import FilePreview from '../../components/upload/filePreview';
import { formatDate, formatTime } from '../../helpers/utils';

const NewNotificationCard = ({
  profileImage,
  name,
  body,
  attachmentId,
  attachmentName,
  creationTime,
  type,
  standard,
  agencies,
  users,
  seenNotification,
  isRead,
}) => {
  return (
    <li
      className={`${
        isRead ? 'bg-white' : 'bg-slate-50'
      } cursor-pointer px-5 py-10`}
      onClick={seenNotification}
    >
      <div className="flex flex-col md:flex-row justify-between sm:gap-6 md:gap-0">
        <div className="flex gap-4 w-full">
          <img
            className="w-10 h-10 rounded-full"
            src={profileImage || avatar}
            alt="profile avatar"
          />
          <div className="flex flex-col gap-2 w-[80%]">
            <p className="text-base tracking-wide">{name}</p>
            {(standard || agencies || users) && (
              <section className="flex flex-row justify-between items-center">
                {standard && (
                  <span className="text-slate-400 text-xs px-2">
                    {'المعيار: ' + standard}
                  </span>
                )}
              </section>
            )}
            <div
              className="text-slate-400 text-[15px] font-normal not-italic leading-normal tracking-[0.36px]"
              dangerouslySetInnerHTML={{ __html: body }}
            ></div>
          </div>
        </div>

        <div className="flex md:flex-col md:gap-2 justify-between">
          <div className="flex items-center gap-2">
            <span className=" flex items-center justify-center pt-0 pb-2 px-4 text-slate-400">
              <span>
                {formatDate(new Date(creationTime)) == formatDate(new Date())
                  ? 'Today' + ' ' + formatTime(new Date(creationTime))
                  : formatDate(new Date(creationTime)) +
                    '  ' +
                    formatTime(new Date(creationTime))}
              </span>
            </span>
            <span className=" flex items-center justify-center pt-0 pb-2 px-4 bg-emerald-500 rounded-[5px] text-white">
              <span>{type}</span>
            </span>
          </div>

          {attachmentId && (
            <div className="flex justify-end">
              <FilePreview id={attachmentId} fileName={attachmentName} />
            </div>
          )}
        </div>
      </div>
    </li>
  );
};

export default NewNotificationCard;
