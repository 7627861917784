import { greenPen } from '../../../../../assets/icons/Index';
import AppButton from '../../../../../components/buttons/appButton';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { permissionsCheck } from '../../../../../helpers/utils';
import DownloadButton from '../../../../../components/DownloadButton';
import useExcelExport from '../../../../../helpers/customHooks/useExcelExport';

const EntityData = ({ agencyDetails }) => {
  const {
    id,
    agencyShortName,
    nameInArabic,
    name,
    isConfidentialAgency,
    agencyStatus,
    agencyCategoryTitleArabic,
    regionNameInArabic,
    agencyClassificationName,
    agencySectorName,
    subAgencies,
    weight,
    BriefDescriptionAr,
    BriefDescription,
    cioName,
    agencyCode,
    pocName,
  } = agencyDetails;

  const history = useHistory();

  const handleUpdateClick = () => {
    history.push({
      pathname: `/entities/${id}/details-update`,
      state: agencyDetails,
    });
  };

  const dataForExcel = [
    {
      'كود الجهة': agencyCode,
      'اسم الجهة': nameInArabic,
      'تاريخ تأسيس الجهة':
        agencyDetails?.agencyEstablishingDate?.slice(0, 10) || '-',
      'نوع الاشتراك':
        agencyDetails?.digitalTransformationSharingLevelName || '-',
      'عدد الموظفين': agencyDetails?.employeesCount || '-',
      'عدد الخدمات': agencyDetails?.servicesCount || '-',
      'الميزانية السنوية لتقنية المعلومات':
        agencyDetails?.technologyDepartmentAnnuualBudget || '-',
      'نوع المستفيدين': agencyDetails?.agencyBeneficiaries
        ?.map((ele) => ele.beneficiariesTypeName)
        .join(', '),
      'هل الجهة لها شركة استشارية': agencyDetails?.isHasConsultantCompany
        ? 'نعم'
        : 'لا',

      'اسم الشركة الاستشارية': agencyDetails?.consultantCompanyName || '-',
      'المسمى الوظيفي الفعلي لل CIO': agencyDetails?.actualJobTitle || '-',
    },
  ];
  const downloadExcel = useExcelExport();

  return (
    <>
      <div className="flex items-center justify-between mb-3">
        <p className="text-lg mb-4">بيانات الجهة</p>
        {permissionsCheck('Pages.UserType.Admin') && (
          <div className="flex gap-3 items-center">
            <AppButton onClick={handleUpdateClick}>
              تحديث بيانات الجهة
            </AppButton>
            <DownloadButton
              onClick={() =>
                downloadExcel(`تقرير جهة ${nameInArabic}`, dataForExcel)
              }
            >
              تصدير ملف الجهة
            </DownloadButton>
          </div>
        )}

        {(permissionsCheck('Pages.UserType.POC') ||
          permissionsCheck('Pages.UserType.CIO')) && (
          <AppButton
            onClick={() =>
              history.push({
                pathname: `/entities/${id}/agency-questions`,
                state: agencyDetails,
              })
            }
          >
            معلومات إضافية
          </AppButton>
        )}
      </div>
      <div className="bg-white rounded-xl py-4 px-6">
        {/* Header */}
        <header className="flex items-start justify-between pb-6">
          <div className="flex items-center gap-8">
            <img src={greenPen} alt="green pen icon" />
            <div className="">
              {agencyShortName && <p>{agencyShortName}</p>}
              <p className="text-[#7D8BAB] my-1">{nameInArabic}</p>
              <p className="text-[#7D8BAB]">{name}</p>
              <p className="text-[#7D8BAB]"> كود الجهة : {agencyCode}</p>
            </div>
          </div>
          {!(
            permissionsCheck('Pages.UserType.POC') ||
            permissionsCheck('Pages.UserType.CIO') ||
            permissionsCheck('Pages.UserType.Delegate')
          ) ? (
            <p
              className={`py-4 px-10 rounded-3xl ${
                isConfidentialAgency
                  ? 'bg-[#1CC081] text-white'
                  : 'bg-[#F9F9FF] '
              }`}
            >
              {isConfidentialAgency ? 'جهة سرية' : 'جهة غير سرية'}
            </p>
          ) : (
            isConfidentialAgency && (
              <p className={`py-4 px-10 rounded-3xl bg-[#1CC081] text-white`}>
                جهة سرية
              </p>
            )
          )}
        </header>
        <div className="w-[90%] mb-6 border-[1px] mx-auto border-gray-200 border-dashed"></div>

        <div className="text-[#7D8BAB]">
          <p>{BriefDescriptionAr || 'لا يوجد تفاصيل لهذه الجهة'}</p>
          <p className="mt-3 max-w-[90%] text-left mr-auto pb-4">
            {BriefDescription || 'There are no details for this agency'}
          </p>
        </div>
      </div>

      <p className="text-lg mb-4 mt-9">معلومات الجهة</p>
      <div className="bg-white rounded-xl p-6 pb-7 flex flex-col gap-3">
        <div className="flex gap-4">
          <div className="w-full">
            <p>حالة الجهة</p>
            <div className="flex gap-3 mt-3 pr-6 items-center p-3 rounded-full bg-[#F9F9FF]">
              <p className="mb-1">{agencyStatus}</p>
            </div>
          </div>
          <div className="w-full">
            <p>المنطقة</p>
            <div className="flex gap-3 mt-3 pr-6 items-center p-3 rounded-full bg-[#F9F9FF]">
              <p className="mb-1">{regionNameInArabic || '-'}</p>
            </div>
          </div>
        </div>
        <div className="flex gap-4">
          <div className="flex-1">
            <p>اسم ال CIO</p>
            <div className="flex gap-3 mt-3 pr-6 items-center p-3 rounded-full bg-[#F9F9FF]">
              <p className="mb-1">{cioName || '-'}</p>
            </div>
          </div>

          <div className="flex-1">
            <p>اسم ضباط الاتصال للجهة</p>
            <div className="flex gap-3 mt-3 pr-6 items-center p-3 rounded-full bg-[#F9F9FF]">
              <p className="mb-1">{pocName || '-'}</p>
            </div>
          </div>
        </div>
        <div className="w-full">
          <p>مجموعة الجهات التابعة</p>
          <div className="flex flex-wrap gap-3 mt-3 pr-6 items-center p-3 rounded-full bg-[#F9F9FF]">
            {subAgencies?.length > 0
              ? subAgencies?.map((ele) => (
                  <p
                    className="mb-1 px-6 py-1 pb-3 bg-slate-300 rounded-3xl"
                    key={ele.nameInArabic}
                  >
                    {ele.nameInArabic}
                  </p>
                ))
              : '-'}
          </div>
        </div>
      </div>
    </>
  );
};

export default EntityData;
