import { useQuery } from '@tanstack/react-query';
import { axiosGetRequest } from '../../../services/Request';
import environment from '../../../services/environment';

export const useGetNotifications = ({ currentPage, option, date }) => {
  const { data, isLoading: isGettingNotifications } = useQuery({
    queryKey: ['notifications', currentPage, option.value, date],
    queryFn: () =>
      axiosGetRequest(environment.getAllNotifications, {
        SkipCount: currentPage * 6,
        MaxResultCount: 6,
        IsRead: option.value,
        Date: date,
      }),
  });

  const notifications = data?.result?.items;
  const totalCount = data?.result?.totalCount;
  const totalPages = Math.ceil(data?.result?.totalCount / 6);

  return {
    notifications,
    totalCount,
    totalPages,
    data,
    isGettingNotifications,
  };
};
