import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import { useContext, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Link, useParams } from 'react-router-dom';
import AppModal from '../../../../components/Models/appModal';
import NewTable from '../../../../components/NewTable';
import { StageContext } from '../../../../contexts/stageContext';
import useExcelExport from '../../../../helpers/customHooks/useExcelExport';
import FlipCard from '../../../../modules/main/home/FlipCard';
import { axiosPostRequest } from '../../../../services/Request';
import environment from '../../../../services/environment';
import { useGetAnsweredStandards } from '../api/useGetAnsweredStandards';

function AnsweredStandards() {
  ChartJS.register(ArcElement, Tooltip, Legend);
  ChartJS.defaults.font.family = 'DiodrumArabic';

  const { indicatorId } = useParams();
  const { currentStage } = useContext(StageContext);
  const phaseThreeStageId = currentStage?.stageIds?.[2];

  const [excelLoading, setExcelLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [filterValue, setFilterValue] = useState(null);

  const [openAnsweredAgenciesModel, setOpenAnsweredAgenciesModel] =
    useState(false);

  const surveyId = localStorage.getItem('surveyId');

  const [standardAgencies, setStandardAgencies] = useState(null);
  const [standardId, setStandardId] = useState(0);

  const columns = [
    {
      name: 'رقم المعيار',
      selector: (row) => row.standardCode,
      header: 'standardCode',
      accessorKey: 'standardCode',
      width: '100px',
    },
    {
      name: 'عدد الجهات',
      selector: (row) => row.totalAgenciesCount,
      header: 'totalAgenciesCount',
      accessorKey: 'totalAgenciesCount',
      center: true,
      cell: (row) => (
        <div className="whitespace-nowrap">{row.totalAgenciesCount}</div>
      ),
    },
    {
      name: 'الجهات المجاوبة',
      selector: (row) => row.answeredAgenciesCount,
      header: 'answeredAgenciesCount',
      accessorKey: 'answeredAgenciesCount',
      center: true,
      cell: (row) => (
        <div
          className="whitespace-nowrap cursor-pointer"
          onClick={() => {
            setStandardId(row.standardId);
            setStandardAgencies(row.agencies);
            setOpenAnsweredAgenciesModel(true);
          }}
        >
          <FontAwesomeIcon icon={faEye} className="text-lg text-cyan-600" />
        </div>
      ),
    },
    {
      name: 'عدد الجهات المجاوبة',
      selector: (row) => row.answeredAgenciesCount,
      header: 'answeredAgenciesCount',
      accessorKey: 'answeredAgenciesCount',
      center: true,
      cell: (row) => (
        <div className="whitespace-nowrap">{row.answeredAgenciesCount}</div>
      ),
    },
  ];

  const handlePageClick = (event) => {
    const newOffset = event.selected;
    setCurrentPage(newOffset);
  };

  const {
    answeredStandards,
    isGettingAnsweredStandards,
    totalAnsweredStandardsCount,
    totalAnsweredNum,
    totalPages,
  } = useGetAnsweredStandards({ currentPage, filterValue });

  // ---------- ChartJS -------------------
  const percentage = (totalAnsweredStandardsCount / totalAnsweredNum) * 100;

  const data = {
    datasets: [
      {
        label: 'جهة',
        data: [percentage, 100 - percentage],
        backgroundColor: ['#ffa92b', '#fdeaec'],
        borderColor: ['#ffa92b', '#fdeaec'],
        cutout: '70%',
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        enabled: false,
      },
      legend: {
        display: false,
      },

      title: {
        display: false,
        text: 'Chart.js Line Chart',
      },
    },
  };
  // ---------- ChartJS -------------------

  const downloadExcel = useExcelExport();

  const handleExcelExport = async () => {
    setExcelLoading(true);
    const res = await axiosPostRequest(environment.PrepareAnsweredStandards, {
      KeyWord: filterValue,
      surveyId: surveyId,
      SkipCount: 0,
      MaxResultCount: 100000,
      stageId: phaseThreeStageId,
    });

    setExcelLoading(false);

    const dataForExcel = res?.result?.items?.map((ele) => {
      return {
        'رقم المعيار': ele?.standardCode,
        'عدد الجهات': ele?.totalAgenciesCount,
        'عدد الجهات المجاوبة': ele?.answeredAgenciesCount,
        'الجهات المجاوبة': ele?.agencies?.map((ele) => ele.name).join(', '),
      };
    });

    downloadExcel('المعايير المجابة', dataForExcel);
  };

  const handleFilter = (value) => {
    setFilterValue(value);
  };

  return (
    <>
      <FlipCard
        title={`المعايير المجابة (${totalAnsweredStandardsCount}/${totalAnsweredNum})`}
        frontContent={
          <div className="relative w-full h-[400px] flex justify-center items-center">
            <div className="h-[300px]">
              <Doughnut data={data} options={options} />
            </div>

            <div className="absolute font-semibold text-3xl top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
              {`${(
                (totalAnsweredStandardsCount / totalAnsweredNum) *
                100
              ).toFixed(2)}%`}
            </div>

            <div className="absolute right-0 top-0 flex flex-col gap-5">
              <div className="flex gap-3 items-center ">
                <div className="w-3 h-3 bg-[#fdeaec]"></div>
                <p className="text-sm font-semibold whitespace-nowrap -mt-3">
                  المعايير غير المجابة
                </p>
              </div>

              <div className="flex gap-3 items-center ">
                <div className="w-3 h-3 bg-[#ffa92b]"></div>
                <p className="text-sm font-semibold whitespace-nowrap -mt-3">
                  المعايير المجابة
                </p>
              </div>
            </div>
          </div>
        }
        backContent={
          <>
            <NewTable
              withSearch
              onSearch={handleFilter}
              withExcel
              excelLoading={excelLoading}
              onExportExcel={handleExcelExport}
              columns={columns}
              data={answeredStandards || []}
              loading={isGettingAnsweredStandards}
              pagination={true}
              totalPages={totalPages}
              onPageChange={handlePageClick}
              currentPage={currentPage}
            />
            <AppModal
              open={openAnsweredAgenciesModel}
              onClose={() => setOpenAnsweredAgenciesModel(false)}
              setOpen={setOpenAnsweredAgenciesModel}
              headerTitle="الجهات المجاوبة على المعيار"
              handleSubmit={() => setOpenAnsweredAgenciesModel(false)}
            >
              <div className="border-t-2">
                {standardAgencies?.map((ele) => (
                  <Link
                    to={`/indicators/${indicatorId}/programs/${localStorage.getItem(
                      'surveyId'
                    )}/program/${ele.agencyId}/entity/${standardId}/standard`}
                    className="block text-lg border-b-2 py-3 pb-3 cursor-pointer hover:text-sky-600 transition-all duration-100"
                  >
                    {ele.name}
                  </Link>
                ))}
              </div>
            </AppModal>
          </>
        }
      />
    </>
  );
}

export default AnsweredStandards;
