import AppButton from '../../../../../components/buttons/appButton';
import { axiosPostRequest } from '../../../../../services/Request';
import environment from '../../../../../services/environment';
import { useContext, useEffect, useState } from 'react';
import { AddStandardContext } from './addStandardContext';
import { useParams } from 'react-router';
import { produce } from 'immer';
import { errorToast, successToast } from '../../../../../helpers/toast';
import { useHistory } from 'react-router-dom';

const SubmitAddStandard = () => {
  const { surveyId, perspectiveId, interlocutorId, indicatorId } = useParams();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const { addFinalValue, setAddFinalValue, subRequirementCount } =
    useContext(AddStandardContext);
  useEffect(() => {
    setAddFinalValue(
      produce((draft) => {
        draft.interlocutorId = Number(interlocutorId);
      })
    );
  }, []);

  const handleSubmit = async () => {
    const agencyStandards = [
      ...addFinalValue?.agencyStandards?.agencies,
      ...addFinalValue?.agencyStandards?.commonAgencies,
    ];
    if (
      !addFinalValue?.title ||
      // !addFinalValue?.code ||
      !addFinalValue?.target ||
      // !addFinalValue?.weight ||
      agencyStandards?.length == 0
    ) {
      errorToast('يجب ادخال البيانات المطلوبة فى عن المعيار');
      return;
    }
    //  if (!addFinalValue?.code.match(/^\d+\.\d+\.\d+$/)) {
    //   errorToast("تنسيق الكود على الطريقة 1.0.0")
    //   return true
    // }
    if (addFinalValue?.standardRequirements?.length == 0) {
      errorToast('يجب ادخال متطلب على الأقل من متطلبات التطبيق');
      return;
    }
    if (addFinalValue?.standardProofs?.length == 0) {
      errorToast('يجب ادخال مستند على الأقل فى طريقة الإثبات');
      return;
    }

    const standardRequirements = [
      ...addFinalValue?.standardRequirements,
    ]?.reverse();
    let standardProofs = [...addFinalValue?.standardProofs]?.reverse();

    standardProofs = standardProofs?.map((item) => ({
      ...item,
      agencyStandardProofs: item?.agencyStandardProofs
        ?.map((ele) => ({
          agencyId: ele.value,
        }))
        .filter((item) => item?.agencyId),
    }));

    const standardRequirementList = standardRequirements?.map((item) => ({
      ...item,
      // standardRequirementClassificationId:
      //   item?.standardRequirementClassificationId?.id,
    }));

    const pastSurveyStandardAssociations =
      addFinalValue?.pastSurveyStandardAssociations?.map((item) => ({
        pastSurveyStandardId: item?.id,
      }));

    setLoading(true);
    let res;

    try {
      res = await axiosPostRequest(environment.addStandard, {
        ...addFinalValue,
        agencyStandards,
        standardRequirements: standardRequirementList,
        standardProofs,
        pastSurveyStandardAssociations,
      });
    } catch (err) {
      errorToast('حدث خطأ ');
    }

    setLoading(false);
    if (res.success) {
      successToast('تم اضافة المعيار بنجاح');
      history.push(
        `/indicators/${indicatorId}/programs/${surveyId}/perspectives/${perspectiveId}/interlocutor/${interlocutorId}/standards`
      );
    }
  };

  return (
    <div className="my-8 mt-12 flex justify-between rounded-lg items-center flex-wrap gap-x-6 px-6 py-3 bg-white fixed bottom-6 left-20  shadow-xl">
      <div className="flex gap-2 mb-1">
        <p>
          {addFinalValue.standardRequirements?.length || '0'}{' '}
          <span className="text-light">متطلب مضاف ،</span>
        </p>
        <p>
          {addFinalValue.standardProofs?.length || '0'}{' '}
          <span className="text-light">مستند مضاف ،</span>
        </p>
        <p>
          {subRequirementCount || '0'}{' '}
          <span className="text-light">متطلب فرعى مضاف</span>
        </p>
      </div>
      <div className="text-end">
        <AppButton loading={loading} onClick={handleSubmit}>
          حفظ
        </AppButton>
      </div>
    </div>
  );
};

export default SubmitAddStandard;
