import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import AppLoader from '../../../../../../../components/loader';
import { StageContext } from '../../../../../../../contexts/stageContext';
import environment from '../../../../../../../services/environment';
import {
  axiosGetRequest,
  axiosPostRequest,
} from '../../../../../../../services/Request';
import { useAnswerContext } from '../../answerContext';
import ReadOnlyRequirements from '../../components/readOnlyRequirements';
import StandardAnswerData from '../../standardAnswerData';
import StandardAnswerNotes from '../../standardAnswerNotes';
import StandardAnswerRequirement from '../../standardAnswerRequirements';
import Tabs from '../Tabs';
import useCreateExpertAnswer from '../useCreateAnswer/useCreateExpertAnswer';
import usePrevExpertAnswer from '../useGetPrevAnswer/usePrevExpertAnswer';
import {
  getReOpenRequirementIds,
  getStandardProofs,
  getStandardRequirements,
  hasAtLeastOneReOpenRequirement,
} from '../utils';
import ExpertFinalAnswer from './expertFinalAnswer';
import ExpertFinalAnswerPhase4ShowingPhase2 from './expertFinalAnswerPhase4ShowingPhase2';
import ExpertNoteTemplate from './expertNoteTemplates';
import ExpertProofFiles from './expertProofFiles';
import ExpertRequirementAnswerPhase4 from './ExpertRequirementAnswerPhase4';
import ExpertRequirementAnswerPhase4ShowingPhase2 from './ExpertRequirementAnswerPhase4ShowingPhase2';
import ExpertVisit from './expertVisit';
import NotesForAgency from './notesForAgency';
import NotesForAgencyPhase4 from './notesForAgencyPhase4';
import usePermissionCheck from '../../../../../../../helpers/customHooks/usePermissionCheck';

function Phase4ExpertAnswer({ menuElement, setMenuElement }) {
  const queryClient = useQueryClient();
  const { entityId, standardId, programId } = useParams();
  const { currentStage } = useContext(StageContext);

  const { getPrevExpertAnswer } = usePrevExpertAnswer();
  const { newAgencyAnswer } = useAnswerContext();
  const { loadPreExpertAnswer, newExpertAnswer, expertAnswer } =
    useAnswerContext();
  const isForthStage = currentStage?.stageNumber === 4;
  const isConfidentialAgency = newAgencyAnswer.isConfidentialAgency;
  const phase2StageId = currentStage?.stageIds?.[1];
  const isFifthStage = currentStage?.stageNumber === 5;
  const phase4StageId = currentStage?.stageIds?.[3];

  const stagePermissionCheck = usePermissionCheck();

  useEffect(() => {
    if (currentStage) {
      if (isFifthStage) {
        getPrevExpertAnswer(standardId, entityId, phase4StageId);
      } else {
        getPrevExpertAnswer(standardId, entityId, currentStage?.id);
      }
    }
  }, [currentStage]);

  const { data: isExpertAllowedToSendReAnswerRequest = false } = useQuery({
    queryKey: ['IsExpertAllowedToAnswer', standardId, entityId],
    queryFn: () =>
      axiosPostRequest(
        environment.IsExpertAllowedToAnswer,
        {
          standardId,
          stageId: currentStage?.id,
          agencyId: entityId,
        },
        null,
        true,
        true
      ),
    enabled: !!currentStage,
    select: (data) => !data.result,
  });

  const { data: phaseOneAgencyAnswer } = useQuery({
    queryKey: [
      'prepareStandardForAnswer',
      standardId,
      entityId,
      currentStage?.stageIds?.[0],
    ],
    queryFn: ({ signal }) =>
      axiosPostRequest(
        environment.prepareStandardForAnswer,
        {
          StandardId: standardId,
          AgencyId: entityId,
          StageId: currentStage?.stageIds?.[0],
        },
        null,
        true,
        true,
        false,
        signal
      ),
    enabled: !!(isForthStage || isFifthStage),
  });

  const { data: phaseThreeAgencyAnswer } = useQuery({
    queryKey: [
      'prepareStandardForAnswer',
      standardId,
      entityId,
      currentStage?.stageIds?.[2],
    ],
    queryFn: ({ signal }) =>
      axiosPostRequest(
        environment.prepareStandardForAnswer,
        {
          StandardId: standardId,
          AgencyId: entityId,
          StageId: currentStage?.stageIds?.[2],
        },
        null,
        true,
        true,
        false,
        signal
      ),
    enabled: !!currentStage?.stageIds?.[2],
  });

  const { data: expertAnswerOnPhase2 } = useQuery({
    queryKey: ['GetExpertAnswer', entityId, phase2StageId, standardId],
    queryFn: () =>
      axiosGetRequest(environment.getExpertAnswer, {
        agencyId: entityId,
        stageId: phase2StageId,
        standardId: standardId,
        expertId: localStorage.getItem('userId'),
      }),
    enabled: !!phase2StageId,
  });

  const { createExpertAnswer } = useCreateExpertAnswer(expertAnswerOnPhase2);

  const { data: standardRequirementsPhaseThree } = useQuery({
    queryKey: [
      environment.getStandardWithRequirement,
      currentStage?.displayOrder,
      entityId,
      standardId,
      programId,
    ],
    queryFn: ({ signal }) =>
      axiosGetRequest(
        environment.getStandardWithRequirement,
        {
          standardId,
          agencyId: entityId,
          stageId: currentStage?.stageIds?.[2],
          surveyId: programId,
        },
        undefined,
        signal
      ),
    enabled: !!currentStage?.stageIds?.[2],
  });

  const isNotReopenedStander = !!(
    standardRequirementsPhaseThree?.result?.standardRequirements?.length === 0
  );

  const disableSecondAuditPhase = isNotReopenedStander && !isConfidentialAgency;

  function createAnswer() {
    createExpertAnswer(standardId, entityId, currentStage?.id).finally(() => {
      queryClient.invalidateQueries('IsExpertAllowedToAnswer');
    });
  }

  function createAnswerAsDraft() {
    createExpertAnswer(standardId, entityId, currentStage?.id, true);
  }

  const reOpenRequirementIds = getReOpenRequirementIds(
    standardRequirementsPhaseThree
  );

  const phaseOneStanderProofs = getStandardProofs(phaseOneAgencyAnswer);
  const phaseThreeStanderProofs = getStandardProofs(
    phaseThreeAgencyAnswer
  ).filter((item) =>
    hasAtLeastOneReOpenRequirement(item, reOpenRequirementIds)
  );
  const phaseOneAgencyAnswerRequirements =
    getStandardRequirements(phaseOneAgencyAnswer) ?? [];
  const phaseThreeAgencyAnswerRequirements =
    getStandardRequirements(phaseThreeAgencyAnswer) ?? [];

  if (menuElement === 0) {
    return (
      <div className="w-full mx-2 bg-white mt-4 overflow-y-scroll relative rounded-md h-[505px] scrollGroup">
        <StandardAnswerData
          onNext={() => setMenuElement(1)}
          loading={loadPreExpertAnswer}
        />
      </div>
    );
  }

  if (loadPreExpertAnswer && menuElement !== 0) {
    return (
      <div className="w-full mx-2 bg-white mt-4 overflow-y-scroll relative rounded-md h-[505px] scrollGroup">
        <div className="min-h-[400px] flex justify-center items-center">
          <AppLoader />
        </div>
      </div>
    );
  }

  if (menuElement === 1) {
    return (
      <Tabs.TabList key={1}>
        <Tabs.Tab
          title="فتح النظام"
          omit={!stagePermissionCheck('Pages.Stage.Stage1.View')}
        >
          <div className="w-full mx-2 bg-white overflow-y-scroll relative rounded-md h-[505px] scrollGroup">
            <ExpertProofFiles
              setMenuElement={setMenuElement}
              standardProofs={phaseOneStanderProofs ?? []}
            />
          </div>
        </Tabs.Tab>
        <Tabs.Tab
          title="إعادة فتح النظام"
          omit={
            !(
              stagePermissionCheck('Pages.Stage.Stage3.View') &&
              !disableSecondAuditPhase
            )
          }
        >
          <div className="w-full mx-2 bg-white overflow-y-scroll relative rounded-md h-[505px] scrollGroup">
            <ExpertProofFiles
              setMenuElement={setMenuElement}
              standardProofs={phaseThreeStanderProofs ?? []}
            />
          </div>
        </Tabs.Tab>
      </Tabs.TabList>
    );
  }

  if (menuElement === 3) {
    return (
      <Tabs.TabList key={2}>
        <Tabs.Tab
          title="فتح النظام"
          omit={!stagePermissionCheck('Pages.Stage.Stage1.View')}
        >
          <div className="w-full mx-2 bg-white overflow-y-scroll relative rounded-md h-[505px] scrollGroup">
            <StandardAnswerRequirement setMenuElement={setMenuElement}>
              <ReadOnlyRequirements
                requirements={phaseOneAgencyAnswerRequirements}
              />
            </StandardAnswerRequirement>
          </div>
        </Tabs.Tab>
        <Tabs.Tab
          title="التحقق  الأولى"
          omit={!stagePermissionCheck('Pages.Stage.Stage2.View')}
        >
          <div className="w-full mx-2 bg-white overflow-y-scroll relative rounded-md h-[505px] scrollGroup">
            <ExpertRequirementAnswerPhase4ShowingPhase2
              setMenuElement={setMenuElement}
            />
          </div>
        </Tabs.Tab>
        <Tabs.Tab
          title="إعادة فتح النظام"
          omit={
            !(
              stagePermissionCheck('Pages.Stage.Stage3.View') &&
              !disableSecondAuditPhase
            )
          }
        >
          <div className="w-full mx-2 bg-white overflow-y-scroll relative rounded-md h-[505px] scrollGroup">
            <StandardAnswerRequirement setMenuElement={setMenuElement}>
              <ReadOnlyRequirements
                requirements={phaseThreeAgencyAnswerRequirements.filter(
                  (item) =>
                    reOpenRequirementIds.includes(item.standardRequirementId)
                )}
              />
            </StandardAnswerRequirement>
          </div>
        </Tabs.Tab>
        <Tabs.Tab
          title="التحقق النهائي"
          omit={
            !(
              stagePermissionCheck('Pages.Stage.Stage4.View') &&
              !disableSecondAuditPhase
            )
          }
        >
          <div className="w-full mx-2 bg-white overflow-y-scroll relative rounded-md h-[505px] scrollGroup">
            <ExpertRequirementAnswerPhase4 setMenuElement={setMenuElement} />
          </div>
        </Tabs.Tab>
      </Tabs.TabList>
    );
  }

  if (menuElement === 4) {
    return (
      <Tabs.TabList key={3}>
        <Tabs.Tab
          title="التحقق  الأولى"
          omit={!stagePermissionCheck('Pages.Stage.Stage2.View')}
        >
          <div className="w-full mx-2 bg-white overflow-y-scroll relative rounded-md h-[505px] scrollGroup">
            <ExpertFinalAnswerPhase4ShowingPhase2
              setMenuElement={setMenuElement}
              expertAnswerOnPhase2={expertAnswerOnPhase2}
            />
          </div>
        </Tabs.Tab>
        <Tabs.Tab
          title="التحقق النهائي"
          omit={
            !(
              stagePermissionCheck('Pages.Stage.Stage4.View') &&
              !disableSecondAuditPhase
            )
          }
        >
          <div className="w-full mx-2 bg-white overflow-y-scroll relative rounded-md h-[505px] scrollGroup">
            <ExpertFinalAnswer
              setMenuElement={setMenuElement}
              expertAnswerOnPhase2={expertAnswerOnPhase2}
            />
          </div>
        </Tabs.Tab>
      </Tabs.TabList>
    );
  }

  if (menuElement === 5) {
    return (
      <div className="w-full mx-2 bg-white mt-4 overflow-y-scroll relative rounded-md h-[505px] scrollGroup">
        <ExpertVisit setMenuElement={setMenuElement} />
      </div>
    );
  }

  if (menuElement === 6) {
    return (
      <Tabs.TabList key={3}>
        <Tabs.Tab
          title="التحقق  الأولى"
          omit={!stagePermissionCheck('Pages.Stage.Stage2.View')}
        >
          <div className="w-full mx-2 bg-white overflow-y-scroll relative rounded-md h-[505px] scrollGroup">
            <NotesForAgencyPhase4
              setMenuElement={setMenuElement}
              expertAnswerOnPhase2={expertAnswerOnPhase2}
              phaseOneAgencyAnswer={phaseOneAgencyAnswer}
            />
          </div>
        </Tabs.Tab>
        <Tabs.Tab
          title="التحقق النهائي"
          omit={
            !(
              stagePermissionCheck('Pages.Stage.Stage4.View') &&
              !disableSecondAuditPhase
            )
          }
        >
          <div className="w-full mx-2 bg-white overflow-y-scroll relative rounded-md h-[505px] scrollGroup">
            <NotesForAgency setMenuElement={setMenuElement} />
          </div>
        </Tabs.Tab>
      </Tabs.TabList>
    );
  }

  if (menuElement === 7) {
    return (
      <div className="w-full mx-2 bg-white mt-4 overflow-y-scroll relative rounded-md h-[505px] scrollGroup">
        <StandardAnswerNotes
          createAnswer={createAnswer}
          createAnswerAsDraft={createAnswerAsDraft}
          isExpertAllowedToSendReAnswerRequest={
            isExpertAllowedToSendReAnswerRequest
          }
          disableSave={disableSecondAuditPhase}
        >
          <ExpertNoteTemplate />
        </StandardAnswerNotes>
      </div>
    );
  }
}

export default Phase4ExpertAnswer;
