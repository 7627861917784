import { useQuery } from '@tanstack/react-query';
import environment from '../../../services/environment';
import { axiosGetRequest } from '../../../services/Request';
import { useContext } from 'react';
import { StageContext } from '../../../contexts/stageContext';
import { agencyId } from '../../../helpers/isPermission';

export const useGetAgencyStats = () => {
  const { stageForDashboard } = useContext(StageContext);

  const surveyStageId = localStorage.getItem('stageForDashboard');

  const { data, isLoading: isGettingDashboardStats } = useQuery({
    queryKey: ['AgencyDashboardStats', stageForDashboard],
    queryFn: () =>
      axiosGetRequest(environment.getAllAgencyStatstics, {
        agencyId: agencyId,
        surveyStageId: stageForDashboard || surveyStageId || 0,
      }),
    enabled: !!(stageForDashboard || surveyStageId || 0),
  });

  const agencyStats = data?.result;

  return { data, agencyStats, isGettingDashboardStats };
};
