import { useState } from 'react';
import StarRatings from 'react-star-ratings';
import { activityIcon } from '../assets/icons/Index';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { permissionsCheck } from '../helpers/utils';

function NewAccordion({ items, changeRating, rating, selectedFaqId }) {
  const [open, setOpen] = useState(null);
  return (
    <div className="border-2 border-gray-200 p-5 rounded-lg flex flex-col transition-all duration-300 ease-in-out">
      {items.map((faq, index) => (
        <div
          key={index}
          className={`transition-all duration-150  ease-in-out px-4 p-2 ${
            open === index ? 'bg-[#fffaf5] rounded-lg py-4' : ''
          }`}
        >
          <div
            className="flex justify-between select-none transition-all duration-300 ease-in-out cursor-pointer star-rating-rtl"
            onClick={() => setOpen(open === index ? null : index)}
          >
            <div className="relative mb-3  select-none">
              <span className="absolute flex items-center justify-center -start-2 w-10 h-10">
                <img src={activityIcon} alt="icon" />
              </span>

              <div className="ml-10 mr-10 flex flex-col">
                <h2 className="font-semibold text-lg">{faq?.question}</h2>

                {permissionsCheck('Pages.UserType.POC') ||
                permissionsCheck('Pages.UserType.CIO') ||
                permissionsCheck('Pages.UserType.Delegate') ? (
                  <div className="flex justify-start">
                    <StarRatings
                      rating={faq?.rate}
                      starRatedColor="#FFD700"
                      numberOfStars={5}
                      name="rating"
                      starHoverColor="#FFD700"
                      starDimension="20px"
                      starSpacing="2px"
                    />
                  </div>
                ) : null}
              </div>
            </div>

            <div className="flex items-start gap-5 mb-3">
              {permissionsCheck('Pages.UserType.Admin') ? (
                <div>
                  <StarRatings
                    rating={faq?.rate}
                    starRatedColor="#FFD700"
                    numberOfStars={5}
                    name="rating"
                    starHoverColor="#FFD700"
                    starDimension="20px"
                    starSpacing="2px"
                  />
                </div>
              ) : (
                <div>
                  <StarRatings
                    rating={faq?.rate}
                    starRatedColor="#FFD700"
                    changeRating={(value) => changeRating(value, faq)}
                    numberOfStars={5}
                    name="rating"
                    starHoverColor="#FFD700"
                    starDimension="25px"
                    starSpacing="2px"
                  />
                </div>
              )}

              <div className="mt-1 transition-all duration-300 ease-in-out">
                {open === index ? (
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    className="cursor-pointer"
                    onClick={() => setOpen(null)}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faChevronUp}
                    className="cursor-pointer"
                    onClick={() => setOpen(index)}
                  />
                )}
              </div>
            </div>
          </div>
          {open === index && (
            <div
              className="faq px-10 pb-5 leading-relaxed text-[#666d7c] font-[DiodrumArabic] ck ck-content"
              dangerouslySetInnerHTML={{ __html: faq?.answer }}
            ></div>
          )}
        </div>
      ))}
    </div>
  );
}

export default NewAccordion;
