import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Spinner } from 'flowbite-react';
import { t } from 'i18next';

const AppMoreButton = ({
  variant = 'primary',
  fullwidth,
  children,
  onClick,
  className,
  loading,
}) => {
  const btnClasses = classNames(
    ' text-white  py-2 pb-1  px-2 min-w-[140px] pt-1 mt-2 rounded-full',
    {
      'opacity-50': loading,
      'bg-green hover:bg-green_hover transition-all': variant === 'primary',
      'bg-blue hover:bg-blue_hover transition-all': variant === 'secondary',
      'bg-danger': variant === 'danger',
      'bg-purple': variant === 'purple',
      'block w-full': fullwidth,
    },
    className
  );
  return (
    <button disabled={loading} className={btnClasses} onClick={onClick}>
      {loading ? (
        <div className="flex  flex-row justify-center items-center">
          <Spinner aria-label="Info spinner example" color="info" />
          <span className="pl-3 pr-3 text-sm text-white">
            {t('general.loading')}
          </span>
        </div>
      ) : (
        <div className="flex justify-between items-center">
          <div className="w-3"></div>
          <div className="mb-2">{children}</div>
          <div className="flex justify-center items-center bg-black  bg-opacity-10 w-10 h-10 rounded-full">
            <FontAwesomeIcon icon={faArrowLeft} className="text-white" />
          </div>
        </div>
      )}
    </button>
  );
};

export default AppMoreButton;
