import AdminDashboardCardsPhase1 from './AdminDashboardCardsPhase1';
import AdminDashboardStatsPhase1 from './AdminDashboardStatsPhase1';

function AdminDashboardPhase1() {
  return (
    <div>
      <AdminDashboardStatsPhase1 />
      <AdminDashboardCardsPhase1 />
    </div>
  );
}

export default AdminDashboardPhase1;
