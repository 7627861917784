import { Badge } from 'flowbite-react';

function AppBadge({ badgeColor, children, size = 'md' }) {
  return (
    <Badge
      style={{
        backgroundColor: badgeColor,
      }}
      className="text-white font-medium pb-3 px-5 rounded-xl"
      size={size}
    >
      {children}
    </Badge>
  );
}

export default AppBadge;
