import { useQuery, useQueryClient } from '@tanstack/react-query';
import { axiosGetRequest } from '../../../../services/Request';
import environment from '../../../../services/environment';
import { StageContext } from '../../../../contexts/stageContext';
import { useContext } from 'react';

export const useGetIncludedAgencies = ({
  currentPage,
  setIncludedAgenciesCount,
  filterValue,
}) => {
  const queryClient = useQueryClient();
  const surveyId = localStorage.getItem('surveyId');
  const { currentStage } = useContext(StageContext);
  const phaseOneStageId = currentStage?.stageIds?.[0];

  const { data, isLoading: isGettingIncludedAgencies } = useQuery({
    queryKey: ['IncludedAgencies', currentPage, filterValue, phaseOneStageId],
    queryFn: ({ signal }) =>
      axiosGetRequest(
        environment.getIncludedAgencies,
        {
          surveyId: surveyId,
          KeyWord: filterValue,
          SkipCount: currentPage * 4,
          MaxResultCount: 4,
          surveyStageId: phaseOneStageId,
        },
        true,
        signal
      ),
  });

  const includedAgencies = data?.result?.items;
  const includedAgenciesCount = data?.result?.totalCount;
  const totalPages = Math.ceil(includedAgenciesCount / 4);

  return {
    queryClient,
    data,
    includedAgencies,
    includedAgenciesCount,
    totalPages,
    isGettingIncludedAgencies,
  };
};
