import React from 'react';
import AppTextarea from '../../../../../../components/form/appTextarea';

const RequirementTextarea = ({
  defaultValue,
  onChange,
  placeholder = 'التوصية',
}) => {
  return (
    <div className="mt-5 mb-6 w-[92%]">
      <AppTextarea
        disabled={true}
        defaultValue={defaultValue}
        onChange={onChange}
        placeholder={placeholder}
        rows="3"
      />
    </div>
  );
};

export default RequirementTextarea;
