import { useState } from 'react';
import { DropdownMultiSelect } from '../../components';
import {
  AccountMangerDashboardContextProvider,
  useAccountMangerDashboardContext,
} from '../../contexts/AccountMangerDashboardContext';
import AgencyDashboardCards2 from './phase2/AgencyDashboardCards';
import AgencyDashboardStats2 from './phase2/AgencyDashboardStats';
import AgencyDashboardCards4 from './phase2/AgencyDashboardCards';
import AgencyDashboardStats4 from './phase2/AgencyDashboardStats';
import DashboardTabs from '../../components/DashboardTabs';

function AccountMangerDashboard() {
  const [activeTab, setActiveTab] = useState(0);

  const dashboardPhases = [
    {
      title: 'التحقق  الأولى',
      content: (
        <>
          <AccountMangerDashboardHeader />
          <AgencyDashboardStats2 />
          <AgencyDashboardCards2 />
        </>
      ),
    },
    {
      title: 'التحقق النهائي',
      content: (
        <>
          <AccountMangerDashboardHeader />
          <AgencyDashboardStats4 />
          <AgencyDashboardCards4 />
        </>
      ),
    },
  ];

  return (
    <AccountMangerDashboardContextProvider>
      <DashboardTabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabs={dashboardPhases}
      />
    </AccountMangerDashboardContextProvider>
  );
}

export default AccountMangerDashboard;

function AccountMangerDashboardHeader() {
  const { selectedAgency, handleSelectAgency, allAgenciesOptions } =
    useAccountMangerDashboardContext();
  return (
    <div className="flex justify-start mr-6 mt-4">
      <div className="w-1/2">
        <DropdownMultiSelect
          placeholder={'أختر جهة حكومية'}
          isClearable={true}
          withoutLabel={false}
          isSearchable={true}
          value={selectedAgency}
          handleChange={handleSelectAgency}
          options={allAgenciesOptions || []}
          isMultiple={false}
          isRequired={false}
          label={'أختر جهة حكومية'}
        />
      </div>
    </div>
  );
}
