import { PDFViewer } from '@react-pdf/renderer';
import { Font } from '@react-pdf/renderer';
import { DigitalTransformationReport } from './DigitalTransformationReport';
import DiodrumArabicBold from '../../../assets/fonts/DiodrumArabic-Bold.ttf';
import DiodrumArabicMedium from '../../../assets/fonts/DiodrumArabic-Medium.ttf';
import DiodrumArabicSemibold from '../../../assets/fonts/DiodrumArabic-Semibold-1.ttf';
import testData from './testData.json';

// Register font
Font.register({
  family: 'DiodrumArabic',
  fonts: [
    {
      src: DiodrumArabicMedium,
    },
    {
      src: DiodrumArabicSemibold,
      fontWeight: 'semibold',
    },
    {
      src: DiodrumArabicBold,
      fontWeight: 'bold',
    },
  ],
});

const arabicUnicode = {
  ا: '\u0627',
  ب: '\u0628',
  ت: '\u062A',
  ث: '\u062B',
  ج: '\u062C',
  ح: '\u062D',
  خ: '\u062E',
  د: '\u062F',
  ذ: '\u0630',
  ر: '\u0631',
  ز: '\u0632',
  س: '\u0633',
  ش: '\u0634',
  ص: '\u0635',
  ض: '\u0636',
  ط: '\u0637',
  ظ: '\u0638',
  ع: '\u0639',
  غ: '\u063A',
  ف: '\u0641',
  ق: '\u0642',
  ك: '\u0643',
  ل: '\u0644',
  م: '\u0645',
  ن: '\u0646',
  ه: '\u0647',
  و: '\u0648',
  ي: '\u064A',
  ء: '\u0621',
  آ: '\u0622',
  أ: '\u0623',
  ؤ: '\u0624',
  إ: '\u0625',
  ئ: '\u0626',
  ى: '\u0649',
  ة: '\u0629',
  'ً': '\u064B',
  'ٌ': '\u064C',
  'ٍ': '\u064D',
  'َ': '\u064E',
  'ُ': '\u064F',
  'ِ': '\u0650',
  'ّ': '\u0651',
  'ْ': '\u0652',
};

export function fixText(text, included) {
  const fixer = '\u200B'; // Zero-width Space
  // to fix "الاسلاميه"
  if (text === 'وزارة الشؤون الإسلامية والدعوة والإرشاد') {
    return 'وزارة الشؤون الـإسلامية والدعوة والـإرشاد';
    return `وزارة الشؤون ا${fixer}لإسلامية وا${fixer}لدعوة والإرشاد`
      .replace(/\s+/g, ' ')
      .trim();

    // !no need to this code for now but leave it !
    const wordToReplace = 'الإسلامية';
    const invisibleChar = '\u200B'; // Zero-width Space

    // Insert the invisible character in the middle of "الإ"
    const fixedWord = 'ا' + invisibleChar + 'لإسلامية'; // Breaking "الإ" with the invisible character

    // Replace only the first occurrence
    const enhancedSentence = text.replace(wordToReplace, fixedWord);
    return enhancedSentence;
  }
  // to fix any sentence have "الاتصالات"
  if (text.includes('الاتصالات')) {
    // New solution just return the text with the correct characters
    if (text === 'وزارة الاتصالات وتقنية المعلومات') {
      return 'وزارة الإتصالات وتقنية المعلومات';
    }

    if (text === 'هيئة الاتصالات والفضاء والتقنية') {
      return 'هيئة الإتصالات والفضاء والتقنية';
    }
    // Old solution
    const str = text.split(' ');

    let i = str.indexOf('الاتصالات');
    let j = str.indexOf('المعلومات');
    let k = str.indexOf('والتقنية');

    str[i] = `ا${fixer}لإتصالات`;
    str[j] = `الـ${fixer}معلومات`;

    if (!included) {
      str[k] = `وا${fixer}لتقنية`;
    }

    return str.join(' ').normalize('NFKC').replace(/\s+/g, ' ').trim() + ' ';
  }

  if (text === 'جامعة الإمام محمد بن سعود الإسلامية') {
    return 'جامعة الـإمام محمد بن سعود الإسلامية';
  }

  if (text === 'مكتب الإدارة الإستراتيجية') {
    return 'مكتب الـإدارة الإستراتيجية';
  }
  if (text === 'ديوان المظالم') {
    return ' ديوان الـمـظـالم ';
  }

  if (text === 'جامعة الباحة') {
    return text.normalize('NFKC');
  }

  if (text === 'الهيئة العليا للأمن الصناعي') {
    return 'الهيئة العليا للـأمن الصناعي';
  }
  if (text === 'بنك التصدير والاستيراد السعودي') {
    return 'بنك التصدير والإستيراد السعودي';
  }

  return text.normalize('NFKC').replace(/\s+/g, ' ').trim() + '\u200B';
}
export const AGENCIES_ENUM = [
  'وزارة الشؤون الإسلامية والدعوة والإرشاد',
  'المركز الوطني للوثائق والمحفوظات',
  'وزارة الموارد البشرية والتنمية الاجتماعية',
  'إمارة منطقة حائل',
  'جامعة شقراء',
  'كلية الملك فهد الأمنية',
  'هيئة المدن والمناطق الاقتصادية الخاصة',
  'هيئة الحكومة الرقمية',
  'هيئة تقويم التعليم والتدريب',
  'الهيئة السعودية للمراجعين و المحاسبين',
  'الهيئة العامة للمنافسة',
  'وزارة المالية',
  'هيئة السوق المالية',
  'الرئاسة العامة لهيئة الأمر بالمعروف والنهي عن المنكر',
  'المكتب الاستراتيجي لتطوير منطقة جازان',
  'جامعة حائل',
  'هيئة تطوير بوابة الدرعية',
  'مركز دعم اتخاذ القرار',
  'هيئة تقويم التعليم والتدريب',
  'جامعة حفر الباطن',
  'وكالة الأنباء السعودية',
  'المركز السعودي لكفاءة الطاقة',
  'المركز السعودي للاعتماد',
  'المركز الوطني لقياس أداء الأجهزة العامة',
  'الهيئة العامة للمنشآت الصغيرة والمتوسطة',
  'الهيئة العامة للأوقاف',
  'الهيئة السعودية للمهندسين',
  'الهيئة العامة للولاية على أموال القاصرين ومن في حكمهم',
  'وزارة الحرس الوطني',
];
export const INCLUDED_AGENCIES_ENUM = [
  'وزارة الشؤون الإسلامية والدعوة والإرشاد',
  'مكتب الإدارة الإستراتيجية',
  'جامعة الإمام محمد بن سعود الإسلامية',
  'وزارة الاتصالات وتقنية المعلومات',
  'هيئة الاتصالات والفضاء والتقنية',
  'ديوان المظالم',
  'جامعة الباحة',
  'الهيئة العليا للأمن الصناعي',
  'بنك التصدير والاستيراد السعودي',
];

const AgencyAnswerReport = () => {
  return (
    <div className="bg-white rounded-lg m-5 p-5">
      <PDFViewer width="100%" height="1000px">
        <DigitalTransformationReport {...testData} />
      </PDFViewer>
    </div>
  );
};

export default AgencyAnswerReport;
