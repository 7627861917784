export const getSurveyAnswerOfQuestion = (question, answerValue) => {
  const questionTypeId = question?.questionType.id;
  const { answer } = answerValue;

  const parseQuestionTrueOrFalseAnswer = (answer) => {
    if (answer?.toLowerCase() === '1') return 'نعم';
    else if (answer?.toLowerCase() === '2') return 'لا';
    else return 'لا توجد اجابة';
  };

  const parseQuestionMultiChoicesAnswer = (questionMultiChoices, answer) => {
    const filteredData = questionMultiChoices?.filter((item) =>
      answer?.split(',')?.includes(item.id?.toString())
    );
    return filteredData?.length
      ? filteredData.map((i) => i.title)?.join(',')
      : 'لا توجد اجابة';
  };

  switch (questionTypeId) {
    case 1: {
      return parseQuestionTrueOrFalseAnswer(answer);
    }
    case 2:
    case 4: {
      return answer;
    }
    case 3: {
      const { questionMultiChoices } = question;
      return parseQuestionMultiChoicesAnswer(questionMultiChoices, answer);
    }
    default: {
      return null;
    }
  }
};
