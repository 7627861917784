import FilePreview from '../../../components/upload/filePreview';
import { permissionsCheck } from '../../../helpers/utils';
import { useState } from 'react';
import { parseDateTime } from '../../../helpers/dateFormat';
import ReplyActions from './ReplyActions';
import ReplyActionsBadge from './ReplyActionsBadge';

function SingleComment({ item, reFetching, ticketStatus }) {
  return (
    <div key={item.id} className={`flex flex-col justify-between p-3 w-full`}>
      <div className="flex flex-col w-full mb-2">
        <div className="flex flex-row justify-between items-center w-full">
          <div className="flex flex-row items-center justify-start">
            {item.photo == null || item.photo == '' ? (
              <div className="bg-[#292069]/[.15] ml-3 mr-3 text-[#292069] p-2 rounded-full w-[40px] h-[40px] flex flex-row items-center justify-center">
                {item.creatorUserName.split(' ')[0][0]?.toUpperCase()}
              </div>
            ) : (
              <img
                alt="personal"
                src={`${process.env.REACT_APP_FRONT_URL}${item.photo}`}
                className="ml-3 mr-3 rounded-full w-[40px] h-[40px]"
              />
            )}
            <div>
              <h1 className="text-[15px] text-black">
                {/* the user is agency and the comment dosen't created by him */}
                {(permissionsCheck('Pages.UserType.POC') ||
                  permissionsCheck('Pages.UserType.CIO') ||
                  permissionsCheck('Pages.UserType.Delegate')) &&
                !item.isCreatorUserRoleEqualLoginUserRole
                  ? 'الفريق الوطني لقياس'
                  : item.creatorUserName}
              </h1>
              <h2 className="text-sm text-[#7D8BAB]">
                {`اليوم : ${
                  parseDateTime(item?.creationTime)?.date
                }  -  الوقت : ${parseDateTime(item?.creationTime)?.time}`}
              </h2>
            </div>
          </div>

          <div className="flex gap-4 items-center">
            {permissionsCheck('Pages.TicketReply.ShowActionsButtons') && (
              <div className="flex gap-1 items-center">
                <ReplyActionsBadge item={item} />
                {ticketStatus !== 2 && (
                  <div className="flex gap-1.5">
                    <ReplyActions reFetching={reFetching} item={item} />
                  </div>
                )}
              </div>
            )}

            {item.attachmentUri && (
              <FilePreview
                id={item.attachmentId}
                fileName={item.attachmentName}
              />
            )}
          </div>
        </div>

        <div className="mr-10 pb-2 pr-6 bg-slate-100 border-gray-200/80 border-2 rounded-lg mt-2">
          <div
            className=" text-[#292069] text-sm"
            dangerouslySetInnerHTML={{
              __html: item.comment,
            }}
          ></div>
        </div>
      </div>
    </div>
  );
}

export default SingleComment;
