import { useEffect, useState } from 'react';
import { Badge, Progress } from 'flowbite-react';
import NewCard from '../../../../components/dashboard/NewCard';
import NewTable from '../../../../components/NewTable';
import { useGetAuditorExpertPerspectives } from '../api/useGetAuditorExpertPerspectives';
import { useDashboardContext } from '../../../../contexts/DashboardContext';
import {
  expertId,
  pocOfficerId,
  reviewerId,
  surveyId,
} from '../../../../helpers/isPermission';
import { axiosGetRequest } from '../../../../services/Request';
import environment from '../../../../services/environment';
import useExcelExport from '../../../../helpers/customHooks/useExcelExport';

const AuditorPerspectivePhase4 = ({ title }) => {
  const surveyStageId = localStorage.getItem('surveyStageId');
  const { setAuditorsDashboardStats, setLoading } = useDashboardContext();

  const [currentPage, setCurrentPage] = useState(0);

  const {
    perspectives,
    isGettingAuditorExpertPerspectives,
    totalCount,
    totalPages,
  } = useGetAuditorExpertPerspectives({ currentPage });

  useEffect(() => {
    setAuditorsDashboardStats((prevStats) => ({
      ...prevStats,
      perspectivesCount: totalCount,
    }));
    setLoading(isGettingAuditorExpertPerspectives);
  }, [totalCount, isGettingAuditorExpertPerspectives]);

  const handlePageClick = (event) => {
    const newOffset = event.selected;
    setCurrentPage(newOffset);
  };

  const statusContent = (status) => {
    switch (status) {
      case 0:
        return (
          <Badge
            className="text-white font-medium bg-[#575757] pb-3 px-5 rounded-xl"
            size="sm"
          >
            لم يبدأ
          </Badge>
        );
      case 1:
        return (
          <Badge
            className="text-white font-medium bg-[#ffa92b] pb-3 px-5 rounded-xl"
            size="sm"
          >
            تحت الاٍجراء
          </Badge>
        );
      case 2:
        return (
          <Badge
            className="text-white font-medium bg-[#1cc182] pb-3 px-5 rounded-xl"
            size="sm"
          >
            مكتمل
          </Badge>
        );

      default:
        return (
          <div>
            <span>—</span>
          </div>
        );
    }
  };

  const columns = [
    {
      name: 'المنظور',
      selector: (row) => row.perspectiveTitle,
    },
    {
      name: 'الحالة',
      center: true,
      cell: (info) => statusContent(info.perspectiveStatus),
    },
    {
      name: 'عدد المعايير الكلية المدققة',
      center: true,
      selector: (row) => row.completedAgenciesCount,
    },
    {
      name: 'عدد المعايير الكلية المتبقية',
      selector: (row) => row.remainingAgenciesCount,
      center: true,
    },
    {
      name: 'معدل الاكتمال',
      cell: (info) => (
        <div className="px-3 py-4 w-full">
          <span className="text-sm text-[#A2A2A2]">{`${
            Math.round(info.completionRate) || 0
          }%`}</span>

          <Progress
            theme={{
              color: {
                dark:
                  info.status === '0'
                    ? 'bg-red-500'
                    : info.status === '1'
                      ? 'bg-[#FFC000]'
                      : 'bg-green',
              },
            }}
            color={'dark'}
            progress={info.completionRate}
          />
        </div>
      ),
    },
  ];

  // excel
  const [excelLoading, setExcelLoading] = useState(false);

  const downloadExcel = useExcelExport();

  const handleExcelExport = async () => {
    setExcelLoading(true);
    const res = await axiosGetRequest(
      environment.getAuditorExpertPerspectives,
      {
        SurveyStageId: surveyStageId,
        SurveyId: surveyId(),
        ReviewerId: reviewerId,
        ExpertId: expertId,
        POCOfficerId: pocOfficerId,
        SkipCount: 0,
        MaxResultCount: 10000,
      }
    );

    setExcelLoading(false);

    const dataForExcel = res?.result?.items?.map((ele) => {
      return {
        المنظور: ele?.perspectiveTitle,
        الحالة:
          ele?.perspectiveStatus == 0
            ? 'لم يبدأ'
            : ele?.perspectiveStatus == 1
              ? 'تحت الاٍجراء'
              : ' مكتمل',
        'عدد المعايير الكلية المدققة': ele?.completedAgenciesCount,
        'عدد المعايير الكلية المتبقية': ele?.remainingAgenciesCount,
        'معدل الاكتمال': ele?.completionRate?.toFixed(2),
      };
    });

    downloadExcel('المناظير', dataForExcel);
  };

  return (
    <NewCard title={title || `المناظير (${totalCount})`}>
      <NewTable
        withExcel
        excelLoading={excelLoading}
        onExportExcel={handleExcelExport}
        loading={isGettingAuditorExpertPerspectives}
        columns={columns}
        data={perspectives || []}
        pagination={true}
        totalPages={totalPages}
        onPageChange={handlePageClick}
        currentPage={currentPage}
      />
    </NewCard>
  );
};

export default AuditorPerspectivePhase4;
