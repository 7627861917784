import { faFileArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Spinner } from 'flowbite-react';
import { useEffect, useState } from 'react';

function DownloadButton({
  children,
  onClick,
  loading: initialLoading = false,
  ...props
}) {
  const [loading, setLoading] = useState(initialLoading);

  useEffect(() => {
    setLoading(initialLoading);
  }, [initialLoading]);

  const handleClick = async () => {
    setLoading(true);
    await onClick?.();
    setLoading(false);
  };

  return (
    <button
      {...props}
      onClick={handleClick}
      disabled={loading}
      className={`${
        props.disabled ? 'cursor-not-allowed' : ''
      } flex gap-3 pb-2 pt-1 px-5 text-[#00A777] items-center border-2 border-[#00A777] rounded-xl`}
    >
      {loading ? (
        <div className="flex flex-row  justify-center items-center">
          <Spinner
            aria-label="Info spinner example"
            className="text-[#00A777]"
          />
          <span className="pl-3 pr-3 text-sm font-semibold text-[#00A777]">
            جاري تحميل الملف
          </span>
        </div>
      ) : (
        <>
          <span>
            <FontAwesomeIcon icon={faFileArrowDown} />
          </span>
          {((typeof children === 'string' && !!children?.trim()) ||
            typeof children === 'object') && (
            <span className="font-semibold">{children}</span>
          )}
        </>
      )}
    </button>
  );
}

export default DownloadButton;
