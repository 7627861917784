import { useState } from 'react';
import axios from 'axios';
import { errorToast } from '../helpers/toast';

const usePdfDownloader = () => {
  const [loading, setLoading] = useState(false);

  const downloadPdf = async (
    api,
    params,
    filename,
    errorMessage,
    showError = true
  ) => {
    setLoading(true);
    try {
      const response = await axios.get(api, {
        responseType: 'blob',
        params: params,
      });

      const pdfBlob = new Blob([response.data], { type: 'application/pdf' });

      const url = window.URL.createObjectURL(pdfBlob);

      const tempLink = document.createElement('a');
      tempLink.href = url;
      tempLink.setAttribute('download', `${filename}.pdf`);

      document.body.appendChild(tempLink);
      tempLink.click();

      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      showError && errorToast(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return { loading, downloadPdf };
};

export default usePdfDownloader;
