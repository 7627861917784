import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { programsIcon, settingsIcon } from '../../../assets/icons/Index';
import { FModal, InputLabel, SModal, Title } from '../../../components';
import AppButton from '../../../components/buttons/appButton';
import DeleteIcon from '../../../components/DeleteIcon';
import EditIcon from '../../../components/EditIcon';
import AppEditor from '../../../components/form/AppEditor';
import NewDropdownMultiSelect from '../../../components/NewDropDownMultiSelect';
import NewTable from '../../../components/NewTable';
import UploadAdapter from '../../../components/UploadAdapter';
import useAxiosGetRequest from '../../../helpers/customHooks/useAxiosGetRequest';
import { errorToast } from '../../../helpers/toast';
import { permissionsCheck } from '../../../helpers/utils';
import NewHeader from '../../../layouts/header/NewHeader';
import environment from '../../../services/environment';
import {
  axiosDeleteRequest,
  axiosGetRequest,
  axiosPostRequest,
  axiosPutRequest,
  get,
} from '../../../services/Request';
import AddVisitTargetModal from './AddVisitTargetModal';
import TypeOfVisitTable from './TypeOfVisitTable';

const VisitSettings = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [message] = useState({});
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [title, setTitle] = useState('');
  const [color, setColor] = useState('');
  const [isSupported, setIsSupported] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [teams, setTeams] = useState([]);
  const [editLoading, setEditLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);

  const [selectedMemberTeam, setSelectedMemberTeam] = useState([]);
  const [agencyList, setAgencyList] = useState([]);
  const [selectedAgencies, setSelectedAgencies] = useState([]);

  const handleChangeMemberTeam = (value) => {
    setSelectedMemberTeam(value);
  };
  const handleChangeAgencies = (value) => {
    setSelectedAgencies(value);
  };

  const getAllAgency = async () => {
    const res = await axiosGetRequest(
      environment.getAllAgencyWithoutPagination
    );
    if (res.success) {
      setAgencyList(res?.result);
    }
  };

  const allAgenciesOptions = agencyList.map((ele) => {
    return {
      label: ele.nameInArabic,
      value: ele.id,
    };
  });

  useEffect(() => {
    getAllAgency();
  }, []);

  const [getAllAgencySectors, allAgencySectors] = useAxiosGetRequest();
  const [selectedClassification, setSelectedClassification] = useState('');
  const [selectedSector, setSelectedSector] = useState('');
  const [selectedAgency, setSelectedAgency] = useState([]);
  const [visitAgenda, setVisitAgenda] = useState('');
  const [standards, setStandards] = useState([]);
  const [selectedVisitTeamStandards, setSelectedVisitTeamStandards] = useState(
    []
  );
  const [selectedMemberTeamExternal, setSelectedMemberTeamExternal] = useState(
    []
  );

  const getUsers = () => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    get(environment.getAllUsers + '?MaxResultCount=' + 50000, config, (res) => {
      if (res.status == 403) {
        toast.error(t('general.authError'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      } else if (res.status == 500) {
        toast.error(t('general.serverError'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      } else if (res.status == 200) {
        let newUsers = [];
        let newArr = [];
        res.data.result.items.forEach((user, index) => {
          let obj = {
            label: user.fullName,
            id: user.id,
            value: user.id,
            showRoleId: user.showRole.id,
          };
          newUsers.push(obj);
        });
        newArr = newUsers.filter(
          (item, index) =>
            item.showRoleId != 40 &&
            item.showRoleId != 39 &&
            item.showRoleId != 41
        );
        setUsers(newArr);
      }
    });
  };

  useEffect(() => {
    getUsers();
  }, []);

  const getAllStandard = async () => {
    const res = await axiosPostRequest(
      'services/app/GetForSelection/Standards' +
        '?surveyId=' +
        localStorage.getItem('surveyId') || null
    );
    if (res.success) {
      setStandards(res?.result);
    }
  };

  const allStandardsOptions = standards.map((ele) => {
    return {
      label: ele.code,
      value: ele.id,
    };
  });

  useEffect(() => {
    getAllStandard();
  }, []);

  const handleChangeStandards = (value) => {
    setSelectedVisitTeamStandards(value);
  };

  useEffect(() => {
    getAllAgencySectors(environment.getAllAgencySectors);
  }, []);

  const allSectorsOptions =
    allAgencySectors?.result?.items &&
    allAgencySectors?.result?.items?.map((item) => {
      return { label: item?.nameInArabic || '', value: item?.id };
    });

  const handleSelectedSector = (value) => {
    setSelectedSector(value || '');
  };

  const [getAllAgencyClassification, allAgencyClassification] =
    useAxiosGetRequest();

  useEffect(() => {
    getAllAgencyClassification(environment.getAllAgencyClassification);
  }, []);

  const allClassificationsOptions =
    allAgencyClassification?.result?.items &&
    allAgencyClassification?.result?.items?.map((item) => {
      return { label: item?.nameInArabic || '', value: item?.id };
    });

  const handleSelectedClassification = (value) => {
    setSelectedClassification(value || '');
  };

  const columns = [
    {
      name: t('اسم الفريق'),
      selector: (row) => row.teamName,
      header: 'teamName',
      accessorKey: 'teamName',
      footer: (props) => props.column.id,
      cell: (row) => (
        <div className="text-slate-400 text-[15px] text-right">
          {row.teamName}
        </div>
      ),
    },
    {
      name: 'الاجراءات',
      selector: (row) => row.actions,
      header: 'actions',
      accessorKey: 'actions',
      footer: (props) => props.column.actions,
      cell: (row) => {
        return (
          permissionsCheck('Pages.VisitTeam.Edit') && (
            <div className="flex gap-2">
              <EditIcon
                initialValuesFunction={() => {
                  setTitle(row?.teamName);
                  setSelectedMemberTeam(
                    row?.visitTeamMembers?.map((ele) => {
                      return {
                        label: ele.teamMember.userName,
                        value: ele.teamMember.id,
                      };
                    })
                  );
                  setVisitAgenda(row.visitAgenda);
                  setSelectedAgencies(
                    row.visitTeamAgenceis.map((ele) => {
                      return {
                        label: ele.teamAgency.nameInArabic,
                        value: ele.teamAgency.id,
                      };
                    })
                  );
                  setSelectedVisitTeamStandards(
                    row.visitTeamStandards.map((ele) => {
                      return {
                        label: ele.teamStandard.code,
                        value: ele.teamStandard.id,
                      };
                    })
                  );
                }}
                loading={editLoading}
                editFunction={(e) => handleUpdateTeam(row)}
                editedName={row.teamName}
                editModalContent={
                  <div className="flex flex-col gap-3">
                    <InputLabel
                      label={'اسم الفريق'}
                      name={'title'}
                      type={'text'}
                      value={title}
                      onChange={handleChangeTitle}
                    />

                    <section className="w-full flex flex-col">
                      <NewDropdownMultiSelect
                        isClearable={true}
                        withoutLabel={false}
                        isSearchable={true}
                        value={selectedMemberTeam}
                        handleChange={handleChangeMemberTeam}
                        placeholder="ابحث باسم العضو"
                        label="أعضاء الفريق"
                        options={users}
                        isMultiple={true}
                      />
                      <div className="text-sm text-red-600 mt-1"></div>
                    </section>

                    <div className="flex flex-col gap-5">
                      <div>
                        <div className="w-full flex flex-col">
                          <NewDropdownMultiSelect
                            isClearable={true}
                            withoutLabel={false}
                            isSearchable={true}
                            value={selectedAgencies}
                            handleChange={handleChangeAgencies}
                            placeholder="اختر الجهات الحكومية"
                            label="الجهات المشمولة"
                            options={allAgenciesOptions}
                            isMultiple={true}
                          />

                          <div className="text-red-700 mt-1"></div>
                        </div>
                      </div>

                      <div>
                        <NewDropdownMultiSelect
                          isClearable={true}
                          withoutLabel={false}
                          isSearchable={true}
                          value={selectedVisitTeamStandards}
                          handleChange={handleChangeStandards}
                          label="المعايير"
                          options={allStandardsOptions}
                          isMultiple={true}
                        />
                        <div className="text-sm text-red-600 mt-3"></div>
                      </div>

                      <div className="w-full">
                        <label className="text-[16px] text-[#292069]">
                          أجندة الإجتماع
                        </label>
                        <div className="mt-3">
                          <AppEditor
                            value={visitAgenda}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setVisitAgenda(data);
                              editor.plugins.get(
                                'FileRepository'
                              ).createUploadAdapter = function (loader) {
                                return new UploadAdapter(loader);
                              };
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                }
              />
              <DeleteIcon
                loading={deleteLoading}
                id={row.id}
                deleteFunction={() => handleDeleteTeam(row.id)}
                deletedName={row.teamName}
              />
            </div>
          )
        );
      },
    },
  ];

  const handleChangeTitle = (e) => {
    setTitle(e.target.value);
  };

  useEffect(() => {
    getAllUsers();
    getAllList(0);
  }, []);

  const getAllList = (page) => {
    setCurrentPage(page);
    getTeams(page);
  };

  const getTeams = (page) => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    get(
      environment.getVisitTeam + '?MaxResultCount=6&SkipCount=' + page * 6,
      config,
      (res) => {
        if (res.status == 403) {
          toast.error(t('general.authError'), {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        } else if (res.status == 500) {
          toast.error(t('general.serverError'), {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        } else if (res.status == 200) {
          setTeams(res.data.result.items);
          setTotalPages(Math.ceil(res.data.result.totalCount / 6));
          setLoading(false);
        }
      }
    );
  };

  const getAllUsers = () => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    get(environment.getAllUsers, config, (res) => {
      if (res.status == 403) {
        toast.error(t('general.authError'));
      } else if (res.status == 500) {
        toast.error(t('general.serverError'));
      } else if (res.status == 200) {
        if (res.data.result.items && res.data.result.items.length > 0) {
          var array = [];
          for (var i = 0; i < res.data.result.items.length; i++) {
            array.push({
              value: res.data.result.items[i].id,
              userId: res.data.result.items[i].id,
              label:
                res.data.result.items[i].name +
                ' ' +
                res.data.result.items[i].surname,
              isSelected: false,
            });
          }
          setUsers(array);
        }
        setTimeout(() => {
          setLoading(false);
        }, 500);
      }
    });
  };

  const [getAllVisitsTeams, allVisitsTeams] = useAxiosGetRequest();

  useEffect(() => {
    getAllVisitsTeams('services/app/VisitTeams/GetAll');
  }, []);

  const handleUpdateTeam = async (row) => {
    if (!title) {
      errorToast('لابد من ادخال اسم للفريق');
      return;
    } else if (!selectedMemberTeam.length) {
      errorToast('لابد من ادخال الخبراء ');
      return;
    } else if (!selectedAgencies.length) {
      errorToast('لابد من ادخال جهات ');
      return;
    } else if (!visitAgenda) {
      errorToast('لابد من ادخال اجندة ');
      return;
    }

    const body = {
      id: row.id,
      teamName: title,
      visitTeamMembers: selectedMemberTeam.map((ele) => {
        return {
          teamMemberId: ele.value,
        };
      }),
      visitTeamExternalMembers: allVisitsTeams?.visitTeamExternalMembers || [],
      visitTeamAgenceis: selectedAgencies.map((ele) => {
        return {
          teamAgencyId: ele.value,
        };
      }),
      visitTeamStandards: selectedVisitTeamStandards.map((ele) => {
        return {
          teamStandardId: ele.value,
        };
      }),
      visitAgenda: visitAgenda,
    };

    setEditLoading(true);
    const res = await axiosPutRequest('services/app/VisitTeams/Update', body);
    setEditLoading(false);
    if (res?.success) {
      toast.info(`تم تحديث الفريق بنجاح`);
      getTeams(0);
    } else {
      toast.error(res?.response?.data?.error?.message);
    }
  };

  const handleDeleteTeam = async (id) => {
    setDeleteLoading(true);
    const res = await axiosDeleteRequest('services/app/VisitTeams/DeleteById', {
      id: id,
    });
    setDeleteLoading(false);
    if (res?.success) {
      toast.success('تم الحذف بنجاح');
      getTeams(0);
    }
  };

  const handlePageClick = (event) => {
    const newOffset = event.selected;
    setCurrentPage(newOffset);
    getAllList(newOffset);
  };

  const [addTargetLoading, setAddTargetLoading] = useState(false);
  const [addTargetOfVisitInputs, setAddTargetOfVisitInputs] = useState({
    title: '',
    nameInArabic: '',
  });

  const [getAllVisitTargets, allVisitTargets, getAllVisitTargetsLoading] =
    useAxiosGetRequest();

  useEffect(() => {
    getAllVisitTargets(environment.getAllMeetingAndVisitGoal);
  }, []);

  const handleAddVisitTarget = async () => {
    const body = {
      name: addTargetOfVisitInputs.nameInArabic,
      nameInArabic: addTargetOfVisitInputs.nameInArabic,
    };

    setAddTargetLoading(true);
    const res = await axiosPostRequest(
      environment.createMeetingAndVisitGoal,
      body
    );
    setAddTargetLoading(false);

    if (res?.success) {
      toast.success(`تم اٍضافة نوع زيارة جديد بنجاح`);
      getAllVisitTargets(environment.getAllMeetingAndVisitGoal);
      setOpenAddModal(false);
      setAddTargetOfVisitInputs({
        title: '',
        nameInArabic: '',
      });
    } else {
      toast.error('حدث خطأ');
    }
  };

  const handleAddTargetOfVisitInputs = (e) => {
    setAddTargetOfVisitInputs({
      ...addTargetOfVisitInputs,
      [e.target.name]: e.target.value,
    });
  };

  const tabs = [
    {
      title: 'الفرق',
      table: 'teams',
      content: (
        <div>
          {/* MeetingAndVisitGoal */}
          {permissionsCheck('Pages.VisitTeam.GetAll') ? (
            <NewTable
              loading={loading}
              columns={columns}
              data={teams}
              pagination={true}
              totalPages={totalPages}
              onPageChange={handlePageClick}
              currentPage={currentPage}
            />
          ) : (
            <div className="text-[#bb2124] text-xl text-center flex justify-center items-center">
              {'ليس لديك صلاحية لهذا'}
            </div>
          )}
        </div>
      ),
    },
    {
      title: 'الهدف من الزيارة',
      table: 'targetOfVisit',
      content: (
        <div>
          {permissionsCheck('Pages.MeetingAndVisitGoal.GetAll') ? (
            <TypeOfVisitTable
              loading={getAllVisitTargetsLoading}
              allVisitTargets={allVisitTargets}
              getAllVisitTargets={getAllVisitTargets}
            />
          ) : (
            <div className="text-[#bb2124] text-xl text-center flex justify-center items-center">
              {'ليس لديك صلاحية لهذا'}
            </div>
          )}
        </div>
      ),
    },
  ];

  const formTarget = useRef(null);

  const handleSubmitAddTarget = () => {
    if (formTarget.current) {
      formTarget.current?.handleSubmit();
    }
  };

  return (
    <div className="bg-white rounded-[30px] m-5">
      <NewHeader />
      <Title
        iconTitle={settingsIcon}
        style={{ backgroundColor: '#2B2969' }}
        single={true}
        title={'اٍعدادات الزيارات'}
        withIcon={true}
        withoutButton={true}
      />
      <div className="w-full bg-white pb-5 my-5 flex flex-col">
        <div className="w-full flex justify-end h-[50px] px-5">
          <AppButton
            onClick={
              activeTab === 0
                ? () =>
                    permissionsCheck('Pages.VisitTeam.Create')
                      ? history.push('/visit/addTeam')
                      : () => {}
                : () => setOpenAddModal(true)
            }
          >
            {activeTab === 0
              ? 'اٍضافة فريق'
              : activeTab === 1
                ? 'اٍضافة هدف الزيارة'
                : ''}
          </AppButton>
        </div>
        <div className="w-full flex justify-end h-[50px] px-5"></div>

        <div className="flex flex-col justify-start px-5 rounded-3xl">
          <div className="flex flex-row">
            {tabs.map((tab, index) => (
              <div
                key={index}
                className={
                  'mr-5 cursor-pointer flex flex-row items-center justify-center w-[200px] h-12 rounded-t-[20px] ' +
                  (activeTab === index
                    ? 'bg-emerald-500 text-white'
                    : 'text-slate-400 bg-slate-50')
                }
                onClick={() => setActiveTab(index)}
              >
                <img
                  src={tab.icon || programsIcon}
                  alt="icon"
                  width={'24px'}
                  height={'24px'}
                />
                <button className="text-lg mr-2 text-center">
                  {tab.title}
                </button>
              </div>
            ))}
          </div>

          <div className="p-5 mb-5 bg-slate-50 rounded-xl w-full">
            {tabs.map((tab, index) => (
              <div key={index}>{activeTab === index && tab.content}</div>
            ))}
          </div>
        </div>
      </div>

      <FModal
        loading={activeTab === 0 ? '' : activeTab === 1 ? addTargetLoading : ''}
        submitLabel={'اٍضافة'}
        headerTitle={
          activeTab === 0 ? '' : activeTab === 1 ? 'اٍضافة نوع زيارة' : ''
        }
        content={
          activeTab === 0 ? (
            ''
          ) : activeTab === 1 ? (
            <AddVisitTargetModal
              innerRef={formTarget}
              onSubmit={handleAddVisitTarget}
              inputs={addTargetOfVisitInputs}
              onChange={handleAddTargetOfVisitInputs}
            />
          ) : (
            ''
          )
        }
        open={openAddModal}
        setOpen={() => setOpenAddModal(false)}
        type={'submit'}
        action={
          activeTab === 0 ? '' : activeTab === 1 ? handleSubmitAddTarget : ''
        }
      />

      <SModal
        type={message.type}
        open={openModal}
        setOpen={() => setOpenModal(false)}
        subTitle={message.message}
      />
    </div>
  );
};

export default VisitSettings;
