import { faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function BottomContent({ percentage, iconColor }) {
  return (
    <div className="absolute left-[50%] -translate-x-1/2 bottom-[-97px]">
      <div className="text-[#2a206a] flex flex-col justify-center items-center">
        <FontAwesomeIcon
          icon={faCaretUp}
          className={`${iconColor} text-[40px]`}
        />
        <p className="whitespace-nowrap  font-semibold -mt-2">{`${percentage}%`}</p>
        <p className="whitespace-nowrap font-semibold text-[#2a2069]">
          قياس 2022
        </p>
      </div>
    </div>
  );
}

export default BottomContent;
