import { Image, Page, StyleSheet, Text } from '@react-pdf/renderer';
import { Footer } from '../components/Footer';
import Slide4 from '../assets/Slide4.1.jpg';
import { fixText } from '../AgencyAnswerReport';

export function Page4({ agencyTitle, qiyasObj, globalStyles }) {
  const styles = StyleSheet.create({
    ...globalStyles,
    agencyTitleStyle: {
      color: '#fff',
      fontSize: 18,
      textAlign: 'right',
      position: 'absolute',
      top: '50%',
      right: 95,
      fontFamily: 'DiodrumArabic',
      width: '55%',
    },
    agencyClassificationTitleStyles: {
      color: '#28B981',
      fontSize: 12,
      textAlign: 'right',
      position: 'absolute',
      top: '61.3%',
      right: 185,
      fontFamily: 'DiodrumArabic',
    },
    dateText: {
      color: '#575757',
      fontSize: 17,
      textAlign: 'right',
      position: 'absolute',
      bottom: 72,
      left: 70,
      fontWeight: 'semibold',
      fontFamily: 'DiodrumArabic',
      direction: 'rtl',
    },
  });
  const agencyClassificationTitle = qiyasObj?.agencyClassificationTitle;

  return (
    <Page size="A4" style={styles.imagePage}>
      <Image style={styles.image} src={Slide4} />
      <Text style={styles.agencyTitleStyle}>{fixText(agencyTitle)}</Text>
      <Text style={styles.agencyClassificationTitleStyles}>
        {agencyClassificationTitle}
      </Text>
      <Footer agencyTitle={agencyTitle} />
    </Page>
  );
}
