import { useEffect, useState } from 'react';
import { AppInput, SModal, Title } from '../../../../../components';
import {
  axiosGetRequest,
  axiosPostRequest,
  get,
  remove,
} from '../../../../../services/Request';
import environment from '../../../../../services/environment';
import { useTranslation } from 'react-i18next';
import {
  formatDate,
  formatTime,
  permissionsCheck,
} from '../../../../../helpers/utils';
import NewHeader from '../../../../../layouts/header/NewHeader';
import { programsIcon } from '../../../../../assets/icons/Index';
import NewTable from '../../../../../components/NewTable';
// import DrawerFilter from "../../../components/DrawerFilter";
import { toast } from 'react-toastify';
import AppModal from '../../../../../components/Models/appModal';
import NewDropdownMultiSelect from '../../../../../components/NewDropDownMultiSelect';
import useAxiosGetRequest from '../../../../../helpers/customHooks/useAxiosGetRequest';
import { useParams } from 'react-router';

const AssignAgencies = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [experts, setExperts] = useState([]);
  const [entities, setEntities] = useState([]);
  const [reviewers, setReviewers] = useState([]);
  const [POCOfficers, setPOCOfficers] = useState([]);

  useEffect(() => {
    getAllExperts();
  }, []);

  useEffect(() => {
    getAllEntities();
  }, []);

  const getAllEntities = async () => {
    setLoading(true);
    const res = await axiosGetRequest(
      environment.getAllAgencyWithoutPagination,
      {
        StandardId: id,
      }
    );
    if (res?.success) {
      let newEntities = [];
      res.result.forEach((item) => {
        newEntities.push({
          label: item?.nameInArabic?.toString() || '',
          value: item?.id,
        });
      });
      setEntities(newEntities, getAllExperts());
    }
  };

  const getAllExperts = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };

    get(
      `${environment.getAllExpertsThatAssignedToStandard}?standardId=${id}`,
      config,
      (res) => {
        if (res?.status == 403) {
          toast.error('لا يوجد لديك صلاحيات', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        } else if (res?.status == 500) {
          toast.error('هناك مشكلة في الخدمة يجب العودة للدعم الفني', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        } else if (res?.status == 200) {
          setExperts(res?.data?.result?.items, getAllReviewers());
        }
      }
    );
  };

  const getAllReviewers = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };

    get(
      `${environment.getAllReviewersThatAssignedToStandard}?standardId=${id}`,
      config,
      (res) => {
        if (res?.status == 403) {
          toast.error('لا يوجد لديك صلاحيات', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        } else if (res?.status == 500) {
          toast.error('هناك مشكلة في الخدمة يجب العودة للدعم الفني', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        } else if (res?.status == 200) {
          setReviewers(res?.data?.result?.items, getAllPOCOfficers());
        }
      }
    );
  };

  const getAllPOCOfficers = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };

    get(
      `${environment.getAllPOCOfficersThatAssignedToStandard}?standardId=${id}`,
      config,
      (res) => {
        if (res?.status == 403) {
          toast.error('لا يوجد لديك صلاحيات', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        } else if (res?.status == 500) {
          toast.error('هناك مشكلة في الخدمة يجب العودة للدعم الفني', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        } else if (res?.status == 200) {
          setPOCOfficers(res?.data?.result?.items, setLoading(false));
        }
      }
    );
  };

  const ExpertsList = [
    {
      name: 'الخبير',
      selector: (row) => row.name,
      header: 'الخبير ',
      accessorKey: 'name',
      footer: (props) => props.column.id,
      cell: (info) => (
        <div>
          <span className="text-sm text-black">{info.name}</span>
        </div>
      ),
    },
    {
      name: ' الجهات',
      selector: (row) => row.agencies,
      header: ' الجهات ',
      accessorKey: 'agencies',
      footer: (props) => props.column.id,
      cell: (info) => <AssignAgenciesToExpert expert={info} />,
    },
  ];

  function AssignAgenciesToExpert({ expert }) {
    const [getEntitiesForFilter, entitiesForFilter] = useAxiosGetRequest();
    const { t, i18n } = useTranslation();
    const [showAssignModal, setShowAssignModal] = useState(false);

    const [selectedAgencies, setSelectedAgencies] = useState([]);

    useEffect(() => {
      let newAgencies = [];
      expert?.agencies?.forEach((agency, i) => {
        newAgencies?.push({
          label: agency?.nameInArabic,
          value: agency?.id,
          id: agency?.id,
        });
      });
      setSelectedAgencies(newAgencies);
    }, []);

    const handleChangeAgencies = (value) => {
      setSelectedAgencies(value || []);
    };

    const assignAgencyToExpert = async () => {
      setLoading(true);
      let newAssignedAgency = [];
      selectedAgencies?.forEach((agency, index) => {
        newAssignedAgency.push(agency?.value);
      });
      let data = {
        standardId: id,
        expertId: expert.id,
        agencyIds: newAssignedAgency,
      };
      const res = await axiosPostRequest(
        environment.assignAgencyToExpert,
        data
      );
      if (res?.success) {
        toast.success('تم تعيين جهات لخبير بنجاح', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
        setLoading(false, setShowAssignModal(false, getAllEntities()));
        // successToast("تم إضافة المعيار للمدقق");
      }
    };
    return (
      <>
        <div className="flex justify-center items-center space-x-2">
          <div></div>
          <AppModal
            handleSubmit={assignAgencyToExpert}
            open={showAssignModal}
            setOpen={setShowAssignModal}
            loading={loading}
            submitLabel="إضافة"
          >
            <div className="mt-3">
              <NewDropdownMultiSelect
                label={'الجهات'}
                placeholder={'اختر الجهات'}
                options={entities}
                handleChange={handleChangeAgencies}
                value={selectedAgencies}
                isMultiple={true}
              />
            </div>
          </AppModal>
          <div>
            <div
              className="text-sm text-white bg-[#202969] rounded-2xl p-3 cursor-pointer "
              onClick={() => setShowAssignModal(true)}
            >
              اضافة جهات
            </div>
          </div>
        </div>
      </>
    );
  }

  const ReviewersList = [
    {
      name: 'المدقق',
      selector: (row) => row.name,
      header: 'المدقق ',
      accessorKey: 'name',
      footer: (props) => props.column.id,
      cell: (info) => (
        <div>
          <span className="text-sm text-black">{info.name}</span>
        </div>
      ),
    },
    {
      name: ' الجهات',
      selector: (row) => row.agencies,
      header: ' الجهات ',
      accessorKey: 'agencies',
      footer: (props) => props.column.id,
      cell: (info) => <AssignAgenciesToReviewer reviewer={info} />,
    },
  ];

  function AssignAgenciesToReviewer({ reviewer }) {
    const [getEntitiesForFilter, entitiesForFilter] = useAxiosGetRequest();
    const { t, i18n } = useTranslation();
    const [showAssignModal, setShowAssignModal] = useState(false);
    const [selectedAgencies, setSelectedAgencies] = useState([]);

    const assignAgencyToReviewer = async () => {
      setLoading(true);
      let newAssignedAgency = [];
      selectedAgencies?.forEach((agency, index) => {
        newAssignedAgency.push(agency?.value);
      });
      let data = {
        standardId: id,
        reviewerId: reviewer.id,
        agencyIds: newAssignedAgency,
      };
      const res = await axiosPostRequest(
        environment.assignAgencyToReviewer,
        data
      );
      if (res?.success) {
        toast.success('تم تعيين جهات لمدقق بنجاح', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
        setLoading(false, setShowAssignModal(false, getAllEntities()));
        // successToast("تم إضافة المعيار للمدقق");
      }
    };

    useEffect(() => {
      let newAgencies = [];
      reviewer?.agencies?.forEach((agency, i) => {
        newAgencies?.push({
          label: agency?.nameInArabic,
          value: agency?.id,
          id: agency?.id,
        });
      });
      setSelectedAgencies(newAgencies);
    }, []);

    const handleChangeAgencies = (value) => {
      setSelectedAgencies(value || []);
    };
    return (
      <>
        <div className="flex justify-center items-center space-x-2">
          <div></div>
          <AppModal
            handleSubmit={assignAgencyToReviewer}
            open={showAssignModal}
            setOpen={setShowAssignModal}
            loading={loading}
            submitLabel="إضافة"
          >
            <div className="mt-3">
              <NewDropdownMultiSelect
                label={'الجهات'}
                placeholder={'اختر الجهات'}
                options={entities}
                handleChange={handleChangeAgencies}
                value={selectedAgencies}
                isMultiple={true}
              />
            </div>
          </AppModal>
          <div>
            <div
              className="text-sm text-white bg-[#202969] rounded-2xl p-3 cursor-pointer "
              onClick={() => setShowAssignModal(true)}
            >
              اضافة جهات
            </div>
          </div>
        </div>
      </>
    );
  }

  const POCOfficersList = [
    {
      name: 'المدقق',
      selector: (row) => row.name,
      header: 'المدقق ',
      accessorKey: 'name',
      footer: (props) => props.column.id,
      cell: (info) => (
        <div>
          <span className="text-sm text-black">{info.name}</span>
        </div>
      ),
    },
    {
      name: ' الجهات',
      selector: (row) => row.agencies,
      header: ' الجهات ',
      accessorKey: 'agencies',
      footer: (props) => props.column.id,
      cell: (info) => <AssignAgenciesToPOCOfficer pocofficer={info} />,
    },
  ];

  function AssignAgenciesToPOCOfficer({ pocofficer }) {
    const [getEntitiesForFilter, entitiesForFilter] = useAxiosGetRequest();
    const { t, i18n } = useTranslation();
    const [showAssignModal, setShowAssignModal] = useState(false);
    const [selectedAgencies, setSelectedAgencies] = useState([]);

    const assignAgencyToPOCOfficer = async () => {
      setLoading(true);
      let newAssignedAgency = [];
      selectedAgencies?.forEach((agency, index) => {
        newAssignedAgency.push(agency?.value);
      });
      let data = {
        standardId: id,
        pocOfficerId: pocofficer.id,
        agencyIds: newAssignedAgency,
      };
      const res = await axiosPostRequest(
        environment.assignAgencyToPOCOfficer,
        data
      );
      if (res?.success) {
        toast.success('تم تعيين جهات لضابط اتصال بنجاح', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
        setLoading(false, setShowAssignModal(false, getAllEntities()));
        // successToast("تم إضافة المعيار للمدقق");
      }
    };

    useEffect(() => {
      let newAgencies = [];
      pocofficer?.agencies?.forEach((agency, i) => {
        newAgencies?.push({
          label: agency?.nameInArabic,
          value: agency?.id,
          id: agency?.id,
        });
      });

      setSelectedAgencies(newAgencies);
    }, []);

    const handleChangeAgencies = (value) => {
      setSelectedAgencies(value || []);
    };
    return (
      <>
        <div className="flex justify-center items-center space-x-2">
          <div></div>
          <AppModal
            handleSubmit={assignAgencyToPOCOfficer}
            open={showAssignModal}
            setOpen={setShowAssignModal}
            loading={loading}
            submitLabel="إضافة"
          >
            <div className="mt-3">
              <NewDropdownMultiSelect
                label={'الجهات'}
                placeholder={'اختر الجهات'}
                options={entities}
                handleChange={handleChangeAgencies}
                value={selectedAgencies}
                isMultiple={true}
              />
            </div>
          </AppModal>
          <div>
            <div
              className="text-sm text-white bg-[#202969] rounded-2xl p-3 cursor-pointer "
              onClick={() => setShowAssignModal(true)}
            >
              اضافة جهات
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="bg-white rounded-[30px] m-5">
      <NewHeader />
      <Title
        iconTitle={programsIcon}
        withoutButton={true}
        // onClick={() => setOpen(true)}
        title={'المعيار'}
        seconed={true}
        subTitle={'اضافة جهات للمستخدمين'}
        textStyle={{ color: 'white', fontSize: 18 }}
        titleButton="إضافة مستخدم"
        style={{ backgroundColor: '#2B2969' }}
      />
      <div className="px-5 text-[#202969] text-[20px]">الخبراء</div>
      <div className="px-5 ">
        {permissionsCheck('Pages.UserType.Admin') ||
        permissionsCheck('Pages.UserType.ExpertManager') ? (
          <NewTable
            columns={ExpertsList}
            data={experts}
            pagination={false}
            loading={loading}
          />
        ) : (
          <div className="text-[#bb2124] text-xl text-center flex justify-center items-center">
            {'ليس لديك صلاحية لهذا'}
          </div>
        )}
      </div>

      <div className="pt-5 px-5 text-[#202969] text-[20px] ">المدققين</div>
      <div className="px-5 ">
        {permissionsCheck('Pages.UserType.Admin') ||
        permissionsCheck('Pages.UserType.ExpertManager') ? (
          <NewTable
            columns={ReviewersList}
            data={reviewers}
            pagination={false}
            loading={loading}
          />
        ) : (
          <div className="text-[#bb2124] text-xl text-center flex justify-center items-center">
            {'ليس لديك صلاحية لهذا'}
          </div>
        )}
      </div>

      <div className="pt-5 px-5 text-[#202969] text-[20px] ">ضباط الاتصال</div>
      <div className="px-5 ">
        {permissionsCheck('Pages.UserType.Admin') ||
        permissionsCheck('Pages.UserType.ExpertManager') ? (
          <NewTable
            columns={POCOfficersList}
            data={POCOfficers}
            pagination={false}
            loading={loading}
          />
        ) : (
          <div className="text-[#bb2124] text-xl text-center flex justify-center items-center">
            {'ليس لديك صلاحية لهذا'}
          </div>
        )}
      </div>
    </div>
  );
};

export default AssignAgencies;
