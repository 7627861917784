import React from 'react';
import { useParams } from 'react-router-dom';
import StandardEntitiesItem from './standardEntitiesItem';
import StandardEntitiesItemSkeleton from './standardEntitiesItemSkeleton';

const StandardEntitiesList = ({ entities, loading }) => {
  const { surveyId, standardId, indicatorId } = useParams();
  const renderedEntities = entities?.map((item) => (
    <StandardEntitiesItem
      item={item}
      key={item.id}
      standardId={standardId}
      surveyId={surveyId}
      hasVisit={item.hasVisit}
      hasAnswered={false}
      indicatorId={indicatorId}
    />
  ));

  return (
    <>
      <div className="row gap-y-5 px-5">
        {loading ? (
          <>
            <StandardEntitiesItemSkeleton />
            <StandardEntitiesItemSkeleton />
            <StandardEntitiesItemSkeleton />
            <StandardEntitiesItemSkeleton />
            <StandardEntitiesItemSkeleton />
            <StandardEntitiesItemSkeleton />
            <StandardEntitiesItemSkeleton />
            <StandardEntitiesItemSkeleton />
            <StandardEntitiesItemSkeleton />.
          </>
        ) : (
          <>{renderedEntities}</>
        )}
      </div>
    </>
  );
};

export default StandardEntitiesList;
