import { useContext, useEffect } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import {
  axiosGetRequest,
  axiosPostRequest,
} from '../../../../../../../services/Request';

import AppLoader from '../../../../../../../components/loader';
import { StageContext } from '../../../../../../../contexts/stageContext';
import environment from '../../../../../../../services/environment';
import { useAnswerContext } from '../../answerContext';
import ReadOnlyRequirements from '../../components/readOnlyRequirements';
import StandardAnswerData from '../../standardAnswerData';
import StandardAnswerNotes from '../../standardAnswerNotes';
import StandardAnswerRequirement from '../../standardAnswerRequirements';
import Tabs from '../Tabs';
import useCreateExpertManagerAnswer from '../useCreateAnswer/useCreateExpertManagerAnswer';
import usePrevExpertManagerAnswer from '../useGetPrevAnswer/usePrevExpertManagerAnswer';

import {
  getReOpenRequirementIds,
  getStandardProofs,
  getStandardRequirements,
  hasAtLeastOneReOpenRequirement,
} from '../utils';
import ExpertManagerFinalAnswer from './expertManagerFinalAnswer';
import ExpertManagerFinalAnswerPhase4ShowingPhase2 from './expertManagerFinalAnswerPhase4ShowingPhase2';
import ExpertManagerNoteTemplate from './expertManagerNoteTemplates';
import ExpertManagerProofFiles from './expertManagerProofFiles';
import ExpertManagerRequirementAnswerPhase4 from './expertManagerRequirementAnswerPhase4';
import ExpertManagerRequirementAnswerPhase4ShowingPhase2 from './expertManagerRequirementAnswerPhase4ShowingPhase2';
import NotesForAgency from './notesForAgency';
import NotesForAgencyPhase4 from './notesForAgencyPhase4';
import usePermissionCheck from '../../../../../../../helpers/customHooks/usePermissionCheck';

function Phase4ExpertMangerAnswer({ menuElement, setMenuElement }) {
  const { entityId, standardId, programId } = useParams();
  const { currentStage } = useContext(StageContext);
  const { getPrevExpertManagerAnswer } = usePrevExpertManagerAnswer();

  const { newAgencyAnswer } = useAnswerContext();
  const { loadPreExpertManagerAnswer } = useAnswerContext();

  const isForthStage = currentStage?.stageNumber === 4;
  const phase2StageId = currentStage?.stageIds?.[1];
  const isFifthStage = currentStage?.stageNumber === 5;
  const phase4StageId = currentStage?.stageIds?.[3];
  const isConfidentialAgency = newAgencyAnswer.isConfidentialAgency;

  const stagePermissionCheck = usePermissionCheck();

  useEffect(() => {
    if (currentStage) {
      // if fifth stage, get stage 4 answer
      if (isFifthStage) {
        getPrevExpertManagerAnswer(standardId, entityId, phase4StageId);
      } else {
        getPrevExpertManagerAnswer(standardId, entityId, currentStage?.id);
      }
    }
  }, [currentStage]);

  const { data: phaseOneAgencyAnswer } = useQuery({
    queryKey: [
      'prepareStandardForAnswer',
      standardId,
      entityId,
      currentStage?.stageIds?.[0],
    ],
    queryFn: ({ signal }) =>
      axiosPostRequest(
        environment.prepareStandardForAnswer,
        {
          StandardId: standardId,
          AgencyId: entityId,
          StageId: currentStage?.stageIds?.[0],
        },
        null,
        true,
        true,
        false,
        signal
      ),
    enabled: !!(isForthStage || isFifthStage),
  });

  const { data: phaseThreeAgencyAnswer } = useQuery({
    queryKey: [
      'prepareStandardForAnswer',
      standardId,
      entityId,
      currentStage?.stageIds?.[2],
    ],
    queryFn: ({ signal }) =>
      axiosPostRequest(
        environment.prepareStandardForAnswer,
        {
          StandardId: standardId,
          AgencyId: entityId,
          StageId: currentStage?.stageIds?.[2],
        },
        null,
        true,
        true,
        false,
        signal
      ),
    enabled: !!currentStage?.stageIds?.[2],
  });

  const { data: expertMangerAnswerOnPhase2 } = useQuery({
    queryKey: ['getExpertManagerAnswer', entityId, phase2StageId, standardId],
    queryFn: () =>
      axiosGetRequest(environment.getExpertManagerAnswer, {
        agencyId: entityId,
        stageId: phase2StageId,
        standardId: standardId,
        ExpertManagerId: localStorage.getItem('userId'),
      }),
    enabled: !!phase2StageId,
  });

  const { data: standardRequirementsPhaseThree } = useQuery({
    queryKey: [
      environment.getStandardWithRequirement,
      currentStage?.displayOrder,
      entityId,
      standardId,
      programId,
    ],
    queryFn: ({ signal }) =>
      axiosGetRequest(
        environment.getStandardWithRequirement,
        {
          standardId,
          agencyId: entityId,
          stageId: currentStage?.stageIds?.[2],
          surveyId: programId,
        },
        undefined,
        signal
      ),
    enabled: !!currentStage?.stageIds?.[2],
  });

  const { createExpertManagerAnswer } = useCreateExpertManagerAnswer(
    expertMangerAnswerOnPhase2
  );

  const reOpenRequirementIds = getReOpenRequirementIds(
    standardRequirementsPhaseThree
  );

  const phaseOneStanderProofs = getStandardProofs(phaseOneAgencyAnswer);
  const phaseThreeStanderProofs = getStandardProofs(
    phaseThreeAgencyAnswer
  ).filter((item) =>
    hasAtLeastOneReOpenRequirement(item, reOpenRequirementIds)
  );

  const phaseOneAgencyAnswerRequirements =
    getStandardRequirements(phaseOneAgencyAnswer) ?? [];
  const phaseThreeAgencyAnswerRequirements =
    getStandardRequirements(phaseThreeAgencyAnswer) ?? [];

  const isNotReopenedStander = !!(
    standardRequirementsPhaseThree?.result?.standardRequirements?.length === 0
  );

  const disableSecondAuditPhase = isNotReopenedStander && !isConfidentialAgency;

  if (menuElement === 0) {
    return (
      <div className="w-full mx-2 bg-white mt-4 overflow-y-scroll relative rounded-md h-[505px] scrollGroup">
        <StandardAnswerData
          onNext={() => setMenuElement(1)}
          loading={loadPreExpertManagerAnswer}
        />
      </div>
    );
  }

  if (loadPreExpertManagerAnswer && menuElement !== 0) {
    return (
      <div className="w-full mx-2 bg-white mt-4 overflow-y-scroll relative rounded-md h-[505px] scrollGroup">
        <div className="min-h-[400px] flex justify-center items-center">
          <AppLoader />
        </div>
      </div>
    );
  }

  if (menuElement === 1) {
    return (
      <Tabs.TabList key={1}>
        <Tabs.Tab
          title="فتح النظام"
          omit={!stagePermissionCheck('Pages.Stage.Stage1.View')}
        >
          <div className="w-full mx-2 bg-white overflow-y-scroll relative rounded-md h-[505px] scrollGroup">
            <ExpertManagerProofFiles
              setMenuElement={setMenuElement}
              standardProofs={phaseOneStanderProofs ?? []}
            />
          </div>
        </Tabs.Tab>
        <Tabs.Tab
          title="إعادة فتح النظام"
          omit={
            !(
              stagePermissionCheck('Pages.Stage.Stage3.View') &&
              !disableSecondAuditPhase
            )
          }
        >
          <div className="w-full mx-2 bg-white overflow-y-scroll relative rounded-md h-[505px] scrollGroup">
            <ExpertManagerProofFiles
              setMenuElement={setMenuElement}
              standardProofs={phaseThreeStanderProofs ?? []}
            />
          </div>
        </Tabs.Tab>
      </Tabs.TabList>
    );
  }

  if (menuElement === 3) {
    return (
      <Tabs.TabList key={2}>
        <Tabs.Tab
          title="فتح النظام"
          omit={!stagePermissionCheck('Pages.Stage.Stage1.View')}
        >
          <div className="w-full mx-2 bg-white overflow-y-scroll relative rounded-md h-[505px] scrollGroup">
            <StandardAnswerRequirement setMenuElement={setMenuElement}>
              <ReadOnlyRequirements
                requirements={phaseOneAgencyAnswerRequirements}
              />
            </StandardAnswerRequirement>
          </div>
        </Tabs.Tab>
        <Tabs.Tab
          title="التحقق  الأولى"
          omit={!stagePermissionCheck('Pages.Stage.Stage2.View')}
        >
          <div className="w-full mx-2 bg-white overflow-y-scroll relative rounded-md h-[505px] scrollGroup">
            <ExpertManagerRequirementAnswerPhase4ShowingPhase2
              setMenuElement={setMenuElement}
            />
          </div>
        </Tabs.Tab>
        <Tabs.Tab
          title="إعادة فتح النظام"
          omit={
            !(
              stagePermissionCheck('Pages.Stage.Stage3.View') &&
              !disableSecondAuditPhase
            )
          }
        >
          <div className="w-full mx-2 bg-white overflow-y-scroll relative rounded-md h-[505px] scrollGroup">
            <StandardAnswerRequirement setMenuElement={setMenuElement}>
              <ReadOnlyRequirements
                requirements={phaseThreeAgencyAnswerRequirements.filter(
                  (item) =>
                    reOpenRequirementIds.includes(item.standardRequirementId)
                )}
              />
            </StandardAnswerRequirement>
          </div>
        </Tabs.Tab>
        <Tabs.Tab
          title="التحقق النهائي"
          omit={
            !(
              stagePermissionCheck('Pages.Stage.Stage4.View') &&
              !disableSecondAuditPhase
            )
          }
        >
          <div className="w-full mx-2 bg-white overflow-y-scroll relative rounded-md h-[505px] scrollGroup">
            <ExpertManagerRequirementAnswerPhase4
              setMenuElement={setMenuElement}
            />
          </div>
        </Tabs.Tab>
      </Tabs.TabList>
    );
  }

  if (menuElement === 4) {
    return (
      <Tabs.TabList key={3}>
        <Tabs.Tab
          title="التحقق  الأولى"
          omit={!stagePermissionCheck('Pages.Stage.Stage2.View')}
        >
          <div className="w-full mx-2 bg-white overflow-y-scroll relative rounded-md h-[505px] scrollGroup">
            <ExpertManagerFinalAnswerPhase4ShowingPhase2
              setMenuElement={setMenuElement}
              expertAnswerOnPhase2={expertMangerAnswerOnPhase2}
            />
          </div>
        </Tabs.Tab>
        <Tabs.Tab
          title="التحقق النهائي"
          omit={
            !(
              stagePermissionCheck('Pages.Stage.Stage4.View') &&
              !disableSecondAuditPhase
            )
          }
        >
          <div className="w-full mx-2 bg-white overflow-y-scroll relative rounded-md h-[505px] scrollGroup">
            <ExpertManagerFinalAnswer
              setMenuElement={setMenuElement}
              expertMangerAnswerOnPhase2={expertMangerAnswerOnPhase2}
            />
          </div>
        </Tabs.Tab>
      </Tabs.TabList>
    );
  }

  if (menuElement === 6) {
    return (
      <Tabs.TabList key={3}>
        <Tabs.Tab
          title="التحقق  الأولى"
          omit={!stagePermissionCheck('Pages.Stage.Stage2.View')}
        >
          <div className="w-full mx-2 bg-white overflow-y-scroll relative rounded-md h-[505px] scrollGroup">
            <NotesForAgencyPhase4
              setMenuElement={setMenuElement}
              expertManagerAnswerOnPhase2={expertMangerAnswerOnPhase2}
              phaseOneAgencyAnswer={phaseOneAgencyAnswer}
            />
          </div>
        </Tabs.Tab>
        <Tabs.Tab
          title="التحقق النهائي"
          omit={
            !(
              stagePermissionCheck('Pages.Stage.Stage4.View') &&
              !disableSecondAuditPhase
            )
          }
        >
          <div className="w-full mx-2 bg-white overflow-y-scroll relative rounded-md h-[505px] scrollGroup">
            <NotesForAgency setMenuElement={setMenuElement} />
          </div>
        </Tabs.Tab>
      </Tabs.TabList>
    );
  }

  if (menuElement === 7) {
    return (
      <div className="w-full mx-2 bg-white mt-4 overflow-y-scroll relative rounded-md h-[505px] scrollGroup">
        <StandardAnswerNotes
          createAnswer={() =>
            createExpertManagerAnswer(standardId, entityId, currentStage?.id)
          }
          disableSave={disableSecondAuditPhase}
        >
          <ExpertManagerNoteTemplate />
        </StandardAnswerNotes>
      </div>
    );
  }
}

export default Phase4ExpertMangerAnswer;
