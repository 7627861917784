import { Document, StyleSheet } from '@react-pdf/renderer';
import { IntroPage } from './pages/IntroPage';
import { IndexPage } from './pages/IndexPage';
import { ThirdPage } from './pages/ThirdPage';
import { Page6 } from './pages/Page6';
import { FourthPage } from './pages/FourthPage';
import { Page5 } from './pages/Page5';
import { Page7 } from './pages/Page7';
import { Page9 } from './pages/Page9';
import { Page11 } from './pages/Page11';
import { Page12 } from './pages/Page12';
import { Page16 } from './pages/Page16';
import { Page8 } from './pages/Page8';
import { Page10 } from './pages/Page10';
import { Page15 } from './pages/Page15';
import { Page17 } from './pages/Page17';
import { Page13 } from './pages/Page13';
import { Page4 } from './pages/Page4';
import { fixText } from './AgencyAnswerReport';

export const DigitalTransformationReport = ({
  qiyasObj,
  agencyResults,
  agencyStandardsCount,
  agenciesByClassification,
  perspectivesWithAllDetails,
  agencyTitle = '[اسم الجهة]',
}) => {
  const styles = StyleSheet.create({
    indicatorArrowUp: {
      margin: 'auto',
      borderStyle: 'solid',
      borderColor: '#fff',
      borderWidth: 6,
      borderTopColor: '#7C32C9',
    },
    indicatorArrowDown: {
      margin: 'auto',
      borderStyle: 'solid',
      borderColor: '#fff',
      borderWidth: 6,
      borderBottomColor: '#7C32C9',
      marginBottom: 8,
    },
    page: {
      paddingVertical: 20,
      paddingHorizontal: 40,
      fontFamily: 'DiodrumArabic',
      direction: 'rtl',
    },
    imagePage: {
      paddingHorizontal: 0,
      paddingVertical: 0,
      fontFamily: 'DiodrumArabic',
      direction: 'rtl',
    },
    sectionTitle: {
      color: '#28a745',
      fontSize: 18,
      marginBottom: 10,
      textAlign: 'right',
      direction: 'rtl',
      fontWeight: 'bold',
    },
    sectionSecondaryTitle: {
      color: '#7C32C9',
      marginBottom: 5,
      textAlign: 'right',
      direction: 'rtl',
      fontSize: 14,
      fontWeight: 'semibold',
    },
    subtitle: {
      color: '#555',
      fontSize: 10,
      marginVertical: 5,
      textAlign: 'right',
      direction: 'rtl',
    },
    progressHeaderContainer: {
      flexDirection: 'row-reverse',
      alignItems: 'center',
      justifyContent: 'center',
      marginVertical: 1,
      width: '100%',
      padding: 5,
      paddingBottom: 2,
    },
    progressHeaderLabel: {
      fontSize: 8,
      color: '#7C32C9',
    },
    progressContainer: {
      flexDirection: 'row-reverse',
      alignItems: 'center',
      marginVertical: 1,
      width: '100%',
      padding: 5,
      backgroundColor: '#7b32c90e',
      border: '1px solid #D2CEF0 ',
      borderRadius: 4,
    },
    progressLabel: {
      width: '30%',
      fontSize: 10,
      textAlign: 'right',
      paddingLeft: 5,
    },
    progressWeightLabel: {
      width: '10%',
      fontSize: 8,
      textAlign: 'right',
      paddingLeft: 5,
    },
    progressBarWrapper: {
      width: '55%',
      backgroundColor: 'transparent',
      borderRadius: 10,
      height: 12,
      overflow: 'hidden',
      transform: 'scaleX(-1)',
    },
    progressBar: {
      height: '100%',
      borderRadius: 10,
      transform: 'scaleY(-1)',
    },
    progressText: {
      fontSize: 10,
      marginRight: 5,
      width: '10%',
      textAlign: 'center',
    },
    totalScore: {
      flexDirection: 'row-reverse',
      alignItems: 'center',
      marginVertical: 1,
      width: '100%',
      padding: 5,
      backgroundColor: 'transparent',
      border: '1px solid #D2CEF0',
      borderRadius: 4,
    },
    totalScoreLabel: {
      textAlign: 'right',
      fontSize: 10,
      color: '#2A2069',
      marginTop: 10,
    },
    totalScoreTextWrapper: {
      width: '70%',
      border: '1px solid #D2CEF0 ',
      alignItems: 'center',
      backgroundColor: '#7b32c90e',
      justifyContent: 'center',
      borderRadius: 4,
      height: 30,
      // paddingTop: "1px",
      marginRight: 'auto',
    },
    totalScoreText: {
      fontSize: 14,
      color: '#2A2069',
      marginBottom: '-13px',
    },
    scaleContainer: {
      marginTop: 30,
      width: '70%',
    },
    scaleTitle: {
      textAlign: 'right',
      fontSize: 14,
      marginBottom: 10,
    },
    scale: {
      flexDirection: 'row',
      height: 32,
      marginTop: 5,
    },
    scaleItem: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: '8px',
    },
    scaleItemText: {
      color: 'white',
      fontSize: 10,
      fontWeight: 'bold',
    },
    scaleItemFirst: {
      backgroundColor: '#B40002',
      borderTopRightRadius: 20,
      borderBottomRightRadius: 20,
    },
    scaleItemSecond: {
      backgroundColor: '#1DC183',
    },
    scaleItemThird: {
      backgroundColor: '#FF9D00',
    },
    scaleItemFourth: {
      backgroundColor: '#FF6B02',
    },
    scaleItemLast: {
      backgroundColor: '#085133',
      borderTopLeftRadius: 20,
      borderBottomLeftRadius: 20,
    },
    footer: {
      fontSize: 8,
      textAlign: 'left',
      color: '#555',
      marginTop: 100,
    },
    fullPage: {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
    image: {
      height: '95%',
    },
  });

  perspectivesWithAllDetails?.perspectivesList?.forEach((perspective) =>
    perspective?.interlocutors?.forEach((interlocutor) => {
      if (interlocutor?.standards) {
        interlocutor.standards = interlocutor.standards.filter(
          standardHasFinalCommitmentLevelTitle
        );
      }
    })
  );

  return (
    <Document language="ar">
      <IntroPage agencyTitle={agencyTitle} globalStyles={styles} />
      <IndexPage agencyTitle={agencyTitle} globalStyles={styles} />
      <ThirdPage agencyTitle={agencyTitle} globalStyles={styles} />
      <Page4
        agencyTitle={agencyTitle}
        perspectives={perspectivesWithAllDetails}
        qiyasObj={qiyasObj}
        globalStyles={styles}
      />
      <FourthPage agencyTitle={agencyTitle} globalStyles={styles} />

      <Page5
        agencyTitle={agencyTitle}
        perspectives={perspectivesWithAllDetails}
        qiyasObj={qiyasObj}
        globalStyles={styles}
      />
      <Page6 agencyTitle={agencyTitle} globalStyles={styles} />
      <Page7
        agencyTitle={agencyTitle}
        perspectives={perspectivesWithAllDetails}
        agencyResults={agencyResults}
        qiyasObj={qiyasObj}
        globalStyles={styles}
      />
      <Page8 agencyTitle={agencyTitle} globalStyles={styles} />
      <Page9
        globalStyles={styles}
        agencyTitle={agencyTitle}
        qiyasObj={qiyasObj}
        agencyResults={agencyResults}
        agenciesByClassification={agenciesByClassification}
      />
      <Page10 agencyTitle={agencyTitle} globalStyles={styles} />
      <Page12
        agencyTitle={agencyTitle}
        globalStyles={styles}
        perspectives={perspectivesWithAllDetails}
        agencyStandardsCount={agencyStandardsCount}
      />
      <Page11
        globalStyles={styles}
        agencyTitle={agencyTitle}
        perspectives={perspectivesWithAllDetails}
      />
      <Page13
        agencyTitle={agencyTitle}
        globalStyles={styles}
        perspectives={perspectivesWithAllDetails}
      />

      <Page15 agencyTitle={agencyTitle} globalStyles={styles} />
      <Page16
        agencyTitle={agencyTitle}
        globalStyles={styles}
        agenciesByClassification={agenciesByClassification}
      />
      <Page17 globalStyles={styles} />
    </Document>
  );
};

export function standardHasFinalCommitmentLevelTitle(standard) {
  return !!standard?.surveyExpertAnswer?.finalCommitmentLevel?.title;
}
