import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import AppLoader from '../../../../components/loader';
import NewTooltip from '../../../../components/NewTooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faFileLines } from '@fortawesome/free-solid-svg-icons';
import NewTable from '../../../../components/NewTable';
import AppModal from '../../../../components/Models/appModal';
import FlipCard from '../../../../modules/main/home/FlipCard';
import { Bar } from 'react-chartjs-2';
import { Chart } from 'chart.js';
import { Badge } from 'flowbite-react';
import AppDivider from '../../../../components/appDivider';
import useExcelExport from '../../../../helpers/customHooks/useExcelExport';
import { useGetBestFiveAgencies } from '../api/useGetBestFiveAgencies';

function BestFiveAgencies() {
  const [openModal, setOpenModal] = useState(false);
  const [answerdStandards, setAnswerdStandards] = useState([]);

  const { bestFiveAgencies, isGettingBestFiveAgencies } =
    useGetBestFiveAgencies();

  const history = useHistory();

  const getStandardRatioBadge = (ratio) => {
    let badgeColor = '#3498db';

    if (ratio === 0) {
      badgeColor = '#575757';
    } else if (ratio < 50) {
      badgeColor = '#f05868';
    } else if (ratio < 70) {
      badgeColor = '#ffa92b';
    } else if (ratio === 100) {
      badgeColor = '#1cc182';
    }

    return (
      <Badge
        className="text-white text-[16px] font-medium pb-3 px-5 rounded-xl"
        size="sm"
        style={{ backgroundColor: badgeColor }}
      >
        {`${ratio?.toFixed(2)}%`}
      </Badge>
    );
  };

  const columns = [
    {
      name: 'كود الجهة',
      selector: (row) => row.agencyCode,
      header: 'agencyCode',
      accessorKey: 'agencyCode',
      width: '100px',
    },
    {
      name: 'اسم الجهة',
      selector: (row) => row.agencyName,
      header: 'agencyName',
      accessorKey: 'agencyName',
      cell: (row) => <div className="whitespace-nowrap">{row.agencyName}</div>,
    },

    {
      name: 'عدد المعايير',
      selector: (row) => row.answeredStandardCount,
      header: 'answeredStandardCount',
      accessorKey: 'answeredStandardCount',
      width: '100px',
      center: true,
    },
    {
      name: 'نسبة الاٍنجاز',
      selector: (row) => row.standardRatio,
      header: 'standardRatio',
      accessorKey: 'standardRatio',
      center: true,
      cell: (row) => getStandardRatioBadge(row.standardRatio),
    },

    {
      name: 'المعايير',
      selector: (row) => row.id,
      header: 'id',
      accessorKey: 'id',
      center: true,
      cell: (row) => (
        <>
          {row.answeredStandardCount != 0 && (
            <NewTooltip content={'عرض المعايير'}>
              <FontAwesomeIcon
                icon={faEye}
                className="text-sky-400 bg-sky-100 mr-1 rounded-full p-2 cursor-pointer"
                onClick={() => {
                  setOpenModal(true);
                  setAnswerdStandards(row.answeredStandardList);
                }}
              />
            </NewTooltip>
          )}
        </>
      ),
    },

    {
      name: 'عرض الاٍجابات',
      selector: (row) => row.agencyId,
      header: 'agencyId',
      accessorKey: 'agencyId',
      center: true,
      cell: (row) => (
        <NewTooltip content={'عرض الاٍجابات'}>
          <FontAwesomeIcon
            icon={faFileLines}
            className="text-orange-400 bg-orange-100 mr-1 rounded-full p-2 cursor-pointer"
            onClick={() => {
              history.push(`entities/${row.agencyId}/analysis`);
            }}
          />
        </NewTooltip>
      ),
    },
  ];

  // ---------- ChartJS -------------------
  const labels = bestFiveAgencies?.map((ele) => ele.agencyName);
  Chart.defaults.font.size = 17;
  Chart.defaults.font.weight = 'bold';
  Chart.defaults.font.family = 'DiodrumArabic';

  const chartData = {
    labels,
    datasets: [
      {
        label: 'عدد المعايير المرفقة لهذه الجهة',
        data: bestFiveAgencies?.map((ele) => ele.assignedStandardCount),
        backgroundColor: '#35ccb8',
        barThickness: 75,
      },
      {
        label: 'عدد المعايير المجابة',
        data: bestFiveAgencies?.map((ele) => ele.answeredStandardCount),
        backgroundColor: '#fccb2b',
        barThickness: 75,
      },
    ],
  };

  const options = {
    type: 'bar',
    plugins: {
      legend: {
        position: 'top',
        labels: {
          font: {
            family: 'DiodrumArabic', // Apply custom font
          },
        },
      },
      title: {
        display: false,
        text: 'أعلى 5 جهات في إغلاق المعايير',
        font: {
          size: 17,
          family: 'DiodrumArabic', // Apply custom font
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        minmax: 10,
        ticks: {
          stepSize: 1,
          font: {
            family: 'DiodrumArabic', // Apply custom font to x-axis labels
          },
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };
  // ---------- ChartJS -------------------

  const downloadExcel = useExcelExport();

  const excelData = bestFiveAgencies?.map((item) => {
    return {
      ...item,
      standardRatio: `${item?.standardRatio?.toFixed(2)}%`,
      standardList: item?.answeredStandardList
        ?.map((ele) => `(${ele?.code})`)
        .join(', '),
    };
  });

  return (
    <FlipCard
      title={'أعلى 5 جهات في إغلاق المعايير'}
      frontContent={
        <div className="relative h-full w-full flex justify-center items-center">
          <div className="h-[400px] w-full flex justify-center items-center">
            {isGettingBestFiveAgencies ? (
              <AppLoader />
            ) : (
              <Bar data={chartData} options={options} />
            )}
          </div>
        </div>
      }
      backContent={
        <>
          <NewTable
            columns={columns}
            data={bestFiveAgencies || []}
            loading={isGettingBestFiveAgencies}
            pagination={false}
            withExcel
            onExportExcel={() =>
              downloadExcel('أعلى 5 جهات في إغلاق المعايير', excelData)
            }
          />
          <AppModal
            isFooter={false}
            headerTitle={'المعايير التي تمت الاٍجابة عليها'}
            open={openModal}
            setOpen={setOpenModal}
          >
            <div className="text-xl h-full flex flex-col gap-1">
              {answerdStandards?.map((standard, index) => (
                <>
                  <div className="flex gap-4 items-center">
                    {index + 1} {' - '}
                    <span className="font-semibold">
                      {standard.standardCode}
                    </span>
                  </div>
                  {answerdStandards?.length !== index + 1 && <AppDivider />}
                </>
              ))}
            </div>
          </AppModal>
        </>
      }
    />
  );
}

export default BestFiveAgencies;
