import DataTable from 'react-data-table-component';
import { emptyTable } from '../assets/imgs/Index';
import { useTranslation } from 'react-i18next';
import TableSkeleton from './table/TableSkeleton';

import DownloadButton from './DownloadButton';
import TableSearch from './TableSearch';
import AppPagination from './Pagination';
import DropdownMultiSelect from './DropdownMultiSelect';
import { classNames } from '@react-pdf-viewer/core';

const NewTable = ({
  columns,
  data,
  currentPage,
  onPageChange,
  totalPages,
  loading,
  pagination,
  expandableRows,
  expandOnRowClicked,
  expandableRowsComponent,
  withExcel = false,
  onExportExcel,
  exportExcelText = ' ',
  withSearch = false,
  onSearch,
  excelLoading,
  conditionalRowStyles = {},
  withSecondSearch,
  onSecondSearch,
  secondSearchPlaceholder,
  withMaxResultChange,
  maxResultValue,
  setMaxResultValue,
  handleRowClick,
  withCustomExcelExport,
  customExcelLoading,
  customExportExcelText,
  onCustomExportExcel,
  handleSort,
  defaultSortAsc,
  defaultSortFieldId,
  fixedHeader = true,
}) => {
  const { t } = useTranslation();
  const customStyles = {
    table: {
      style: {},
    },
    headRow: {
      style: {},
    },
    rows: {
      style: {
        color: 'black',
        backgroundColor: 'white',
        minHeight: '72px',
        fontSize: 14,
        '&:not(:last-of-type)': {},
        stripedStyle: {
          color: 'black',
          backgroundColor: '#FFFFFF',
          minHeight: '72px',
          fontSize: 14,
          '&:not(:last-of-type)': {},
        },
      },
      headCells: {
        style: {
          padding: '8px',
          backgroundColor: '#E8E8F3',
          minHeight: '40px',
          display: 'flex',
          justifyContent: 'center',
          color: '#292069',
          fontSize: '14px',
        },
      },
      cells: {
        style: {
          padding: '5px',
          minHeight: '72px',
          display: 'flex',
          justifyContent: 'center',
        },
      },
      pageButtonsStyle: {
        borderRadius: '50%',
        height: '40px',
        width: '40px',
        padding: '8px',
        margin: 'px',
        cursor: 'pointer',
        transition: '0.4s',
        color: '#656565',
        fill: '#656565',
        backgroundColor: 'transparent',
      },
    },
  };

  return (
    <div>
      <div className="mb-3 flex items-center justify-between">
        <div className="flex items-center gap-3">
          {withMaxResultChange && (
            <div className="w-[100px]">
              <DropdownMultiSelect
                filter
                withoutLabel
                isSearchable={false}
                value={maxResultValue}
                handleChange={(value) => setMaxResultValue(value)}
                options={[
                  { label: '5', value: 5 },
                  { label: '10', value: 10 },
                  { label: '25', value: 25 },
                  { label: '50', value: 50 },
                  { label: '100', value: 100 },
                  { label: '150', value: 150 },
                  { label: '200', value: 200 },
                  { label: '250', value: 250 },
                ]}
                isMultiple={false}
              />
            </div>
          )}
          {withSearch && <TableSearch onSearch={onSearch} />}
          {withSecondSearch && (
            <TableSearch
              onSearch={onSecondSearch}
              placeholder={secondSearchPlaceholder}
            />
          )}
        </div>

        <div
          className={classNames({
            flex: withCustomExcelExport,
          })}
        >
          {withExcel && (
            <div className="cursor-pointer p-3 self-end">
              <DownloadButton onClick={onExportExcel} loading={excelLoading}>
                {exportExcelText}
              </DownloadButton>
            </div>
          )}
          {withCustomExcelExport && (
            <div className="cursor-pointer p-3 self-end">
              <DownloadButton
                onClick={onCustomExportExcel}
                loading={customExcelLoading}
              >
                {customExportExcelText}
              </DownloadButton>
            </div>
          )}
        </div>
      </div>

      {loading ? (
        <TableSkeleton />
      ) : (
        <div className="mb-5">
          <div></div>
          <DataTable
            columns={columns}
            data={data}
            noDataComponent={
              <div className="flex flex-col items-center content-center p-5">
                <img src={emptyTable} alt="empty" />
                <span className="text-base text-[#656565]">
                  {t('general.noFoundTable')}
                </span>
              </div>
            }
            customStyles={customStyles}
            highlightOnHover={true}
            sortServer={false}
            onRowClicked={handleRowClick}
            fixedHeader={fixedHeader}
            striped={true}
            conditionalRowStyles={conditionalRowStyles}
            expandableRows={expandableRows}
            expandOnRowClicked={expandOnRowClicked}
            expandableRowsComponent={expandableRowsComponent}
            onSort={handleSort}
            defaultSortFieldId={defaultSortFieldId}
            defaultSortAsc={defaultSortAsc}
          />
        </div>
      )}

      {pagination && (
        <div className="w-[100%] m-auto bg-white p-5">
          <AppPagination
            currentPage={currentPage}
            handlePageClick={onPageChange}
            loading={loading}
            totalPages={totalPages}
          />
        </div>
      )}
    </div>
  );
};

export default NewTable;
