import classNames from 'classnames';
import { Spinner } from 'flowbite-react';

const AppButton = ({
  variant = 'primary',
  fullwidth,
  children,
  onClick,
  className,
  loading,
  loadingLabel = 'جارى الحفظ',
  disabled = false,
  type = 'button',
  form,
}) => {
  const btnClasses = classNames(
    className,
    {
      'opacity-50': loading,
      'bg-green hover:bg-green_hover transition-all': variant === 'secondary',
      'bg-danger': variant === 'danger',
      'bg-purple': variant === 'purple',
      'block w-full': fullwidth,
      'cursor-pointer': !disabled,
      'cursor-not-allowed opacity-50': disabled,
      'bg-blue hover:bg-blue_hover transition-all': variant === 'primary',
      'bg-orange-400 hover:bg-orange-300 transition-all': variant === 'warning',
    },
    ' text-white py-2 pb-4  px-4 min-w-[140px] pt-1 mt-0 rounded-full flex items-center justify-center'
  );
  return (
    <button
      type={type}
      disabled={loading || disabled}
      className={btnClasses}
      onClick={onClick}
      form={form}
    >
      {loading ? (
        <div className="flex flex-row  justify-center items-center">
          <Spinner aria-label="Info spinner example" color="info" />
          <span className="pl-3 pr-3 text-sm text-white">{loadingLabel}</span>
        </div>
      ) : (
        <div className="mb-[-5px]">{children}</div>
      )}
    </button>
  );
};

export default AppButton;
