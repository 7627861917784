import { View, Text, StyleSheet } from '@react-pdf/renderer';
import { cleanText } from '../../../../helpers/utils';
import { fixText } from '../AgencyAnswerReport';

// Group data into pairs
const groupData = (data) => {
  return data.reduce((grouped, item, index) => {
    if (index % 2 === 0) {
      grouped.push([item]);
    } else {
      grouped[grouped.length - 1].push(item);
    }
    return grouped;
  }, []);
};

const Table = ({ data = [] }) => {
  const groupedData = groupData(data);
  const cleanText = (input) => {
    if (input.includes('ئ'))
      return input.normalize('NFKC').replace(/\s+/g, ' ').trim();
    else return input.normalize('NFKD').replace(/\s+/g, ' ').trim();
  };

  return (
    <>
      {groupedData.map((pair, rowIndex) => (
        <View
          key={rowIndex}
          style={[
            styles.row,
            {
              backgroundColor: rowIndex % 2 !== 0 ? 'white' : '#ece8f5',
              textAlign: 'right',
              paddingRight: 6,
            },
          ]}
        >
          {pair.map(
            (item, columnIndex) =>
              item && (
                <View
                  key={item.id}
                  style={{
                    ...styles.cell,
                    direction: 'rtl',
                    display: 'flex',
                    // border: "1px solid #eee",
                    flexDirection: 'row-reverse',
                  }}
                >
                  <View
                    style={
                      {
                        // border: "1px solid #000",
                      }
                    }
                  >
                    <Text style={styles.cellText}>
                      {rowIndex + 1 + columnIndex * groupedData.length}-{' '}
                    </Text>
                  </View>
                  <View
                    style={
                      {
                        // border: "1px solid #000",
                      }
                    }
                  >
                    <Text style={styles.cellText}>
                      <Text>
                        <Text>
                          <Text>
                            <Text>
                              {' ' +
                                fixText(
                                  data[
                                    rowIndex + columnIndex * groupedData.length
                                  ]?.nameAr
                                )}
                            </Text>
                          </Text>
                        </Text>
                      </Text>
                    </Text>
                  </View>
                </View>
              )
          )}
        </View>
      ))}
    </>
  );
};

const styles = StyleSheet.create({
  page: {
    padding: 10,
  },
  row: {
    flexDirection: 'row-reverse',
    width: '100%',
    paddingTop: 10,
    justifyContent: 'flex-start',
  },
  cell: {
    width: '50%', // Each cell takes half of the row, set it to 48 to have a small gap between cells
  },
  cellText: {
    fontSize: 10,
    color: '#000',
    textAlign: 'right',
    direction: 'rtl',
  },
});

export default Table;

function mapEnglishNumsToArabic(englishNum) {
  const arabicNums = {
    0: '٠',
    1: '١',
    2: '٢',
    3: '٣',
    4: '٤',
    5: '٥',
    6: '٦',
    7: '٧',
    8: '٨',
    9: '٩',
  };
  return englishNum.toString().replace(/\d/g, (d) => arabicNums[d]);
}
