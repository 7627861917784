import React, { useEffect, useRef, useState } from 'react';
import HeaderWithBreadcrumbLayout from '../../../../layouts/headerWithBreadcrumbLayout';
import InterlocutorList from './interlocutorList';
import AppModal from '../../../../components/Models/appModal';
import { ErrorMessage } from 'formik';
import { InputLabel } from '../../../../components';
import useAxiosGetRequest from '../../../../helpers/customHooks/useAxiosGetRequest';
import environment from '../../../../services/environment';
import { axiosPostRequest } from '../../../../services/Request';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import InterlocutorItem from './interlocutorList/interlocutorItem';
import ReactPaginate from 'react-paginate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { NewForm } from '../../../../components/form/NewForm';
import { programsIcon } from '../../../../assets/icons/Index';
import { permissionsCheck } from '../../../../helpers/utils';

const InterlocutorPage = (props) => {
  const { perspectiveId, surveyId, indicatorId } = useParams();
  const [openAddModal, setOpenAddModal] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const initialInputState = {
    title: '',
    description: '',
    target: '',
    // code:""
  };
  const [inputs, setInputs] = useState(initialInputState);

  const handleInputChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const [getPerspectives, perspectives] = useAxiosGetRequest();
  useEffect(() => {
    getPerspectives(environment.getPerspectiveList, {
      SurveyId: surveyId,
    });
  }, []);

  const perspectivesOptions =
    perspectives?.result?.items &&
    perspectives?.result?.items?.map((item) => {
      return { label: item.title, value: item.id };
    });

  // const [indicatorName, setIndicatorName] = useState("");
  const [perspectiveName, setPerspectiveName] = useState({
    value: perspectiveId,
  });

  const handleChangeSearchPerspectiveName = (value) => {
    setPerspectiveName(value);
  };

  //fetch data
  const [getInterlocutor, interlocutor, getInterlocutorLoading] =
    useAxiosGetRequest();

  // refetch the data when i need
  const handleDataUpdate = async () => {
    await getInterlocutor(environment.getInterviewers, {
      SurveyId: surveyId,
      PerspectiveId: perspectiveName?.value,
    });
  };

  const renderedInterlocutor =
    interlocutor?.result?.items &&
    interlocutor?.result?.items?.map((item) => (
      <InterlocutorItem
        key={item.id}
        {...item}
        onDataUpdate={handleDataUpdate}
        surveyId={surveyId}
        indicatorId={indicatorId}
      />
    ));

  const body = {
    ...inputs,
    perspectiveId: perspectiveName?.value,
  };

  // add Interlocutor
  const handleSubmit = async () => {
    setAddLoading(true);
    const res = await axiosPostRequest(environment.addInterviewer, body);
    setAddLoading(false);
    if (res?.success) {
      toast.success('تم إضافة محور بنجاح');
      handleDataUpdate();
      setOpenAddModal(false);
      setInputs(initialInputState);
    } else {
      // errorToast(res?.response?.data?.error?.message);
      // setOpenAddModal(false);
    }
  };

  // get the data

  useEffect(() => {
    getInterlocutor(environment.getInterviewers, {
      SurveyId: surveyId,
      PerspectiveId: perspectiveId,
      SkipCount: 0,
      MaxResultCount: 6,
    });
  }, []);

  const handleGetAllInterlocutors = async (page) => {
    await getInterlocutor(environment.getInterviewers, {
      PerspectiveId: perspectiveId,
      SurveyId: surveyId,
      SkipCount: page * 6,
      MaxResultCount: 6,
    });
  };
  const [currentPage, setCurrentPage] = useState(0);

  const handlePageClick = (event) => {
    const newOffset = event.selected;
    setCurrentPage(newOffset);
    handleGetAllInterlocutors(newOffset);
  };

  // ----------------------------------------------------- //
  const formRef = useRef(null);

  const handleSubmitAdd = () => {
    if (formRef.current) {
      formRef.current?.handleSubmit();
    }
  };

  const initialValues = {
    title: '',
    target: '',
    // code:""
  };

  const validate = (values) => {
    const errors = {};
    if (!inputs.title) {
      errors.title = 'هذا الحقل مطلوب';
    }
    // if (!inputs.weight || inputs.weight < 0 || inputs.weight > 100) {
    //   errors.weight = "هذا الحقل لا بد ان يحتوي على رقم من 0 اٍلي 100";
    // }
    // if (!inputs.code) {
    //   errors.code = "هذا الحقل مطلوب";
    // }
    //  else if (!inputs.code.match(/^\d+\.\d+$/)) {
    //   errors.code = "تنسيق الكود على الطريقة 1.0";
    // }
    if (!inputs.target) {
      errors.target = 'هذا الحقل مطلوب';
    }

    return errors;
  };
  // ----------------------------------------------------- //

  const pageCount = Math.ceil(
    Math.ceil(interlocutor?.result?.totalCount / 6) || 1
  );

  const prespectiveBreadcumbs = [
    {
      icon: programsIcon,
      title: 'استبيان التحول الرقمى',
      url: `/indicators/${indicatorId || 1}`,
    },
    {
      title: 'المناظير',
      url: `/indicators/${indicatorId}/programs/${surveyId}/perspectives`,
    },
    {
      title: 'المحاور',
    },
  ];

  return (
    <HeaderWithBreadcrumbLayout
      breadcrumbArr={prespectiveBreadcumbs}
      btnLabel="إضافة محور"
      handleBtnClick={() => setOpenAddModal(true)}
      hideBtn={permissionsCheck('Pages.Interlocutors.Create') ? false : true}
    >
      <InterlocutorList
        renderedInterlocutor={renderedInterlocutor}
        loading={getInterlocutorLoading}
        getInterlocutor={getInterlocutor}
        handleChangeSearchPerspectiveName={handleChangeSearchPerspectiveName}
        perspectivesOptions={perspectivesOptions}
        perspectiveName={perspectiveName}
        setPerspectiveName={setPerspectiveName}
      />

      <div className="pb-5 pt-5">
        <ReactPaginate
          nextLabel={
            getInterlocutorLoading == true ? null : (
              <FontAwesomeIcon
                icon={faChevronLeft}
                style={{ color: '#A1A9C4', fontSize: 14 }}
              />
            )
          }
          onPageChange={handlePageClick}
          initialPage={currentPage}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          pageCount={pageCount}
          previousLabel={
            getInterlocutorLoading == true ? null : (
              <FontAwesomeIcon
                icon={faChevronRight}
                style={{ color: '#A1A9C4', fontSize: 14 }}
              />
            )
          }
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item-previous"
          previousLinkClassName="page-link-previous"
          nextClassName="page-item-next"
          nextLinkClassName="page-link-next"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          activeClassName="active"
          renderOnZeroPageCount={null}
        />
      </div>

      <AppModal
        loading={addLoading}
        handleSubmit={handleSubmitAdd}
        type={'submit'}
        headerTitle={'إضافة محور جديد'}
        open={openAddModal}
        setOpen={setOpenAddModal}
        submitLabel={'اٍضافة'}
      >
        <NewForm
          initialValues={initialValues}
          validate={validate}
          innerRef={formRef}
          onSubmit={handleSubmit}
        >
          <div className="flex flex-col gap-3">
            <div className="space-y-1">
              <InputLabel
                label={'اسم المحور'}
                name="title"
                value={inputs.title}
                onChange={handleInputChange}
              />
              <ErrorMessage
                className="text-red-700"
                name="title"
                component="div"
              />
            </div>
            <div className="space-y-1">
              <InputLabel
                value={inputs.weight}
                type={'number'}
                onChange={handleInputChange}
                name={'weight'}
                label={'الوزن'}
              />
              <ErrorMessage
                className="text-red-700"
                name="weight"
                component="div"
              />
            </div>
            {/* <div className="space-y-1">
              <InputLabel
                value={inputs.code}
                onChange={handleInputChange}
                name={"code"}
                label={"الكود"}
                type={"number"}
              />
              <ErrorMessage
                className="text-red-700"
                name="code"
                component="div"
              />
            </div> */}
            <div className="space-y-1">
              <InputLabel
                value={inputs.target}
                onChange={handleInputChange}
                name={'target'}
                label={'الهدف'}
              />
              <ErrorMessage
                className="text-red-700"
                name="target"
                component="div"
              />
            </div>
            <div className="space-y-1">
              <InputLabel
                rows={4}
                type={'textarea'}
                value={inputs.description}
                onChange={handleInputChange}
                name={'description'}
                label={'الوصف'}
                isRequired={false}
              />
            </div>
          </div>
        </NewForm>
      </AppModal>
    </HeaderWithBreadcrumbLayout>
  );
};

export default InterlocutorPage;
