import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import { useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import NewTable from '../../../../components/NewTable';
import NewTooltip from '../../../../components/NewTooltip';
import { useDashboardContext } from '../../../../contexts/DashboardContext';
import useExcelExport from '../../../../helpers/customHooks/useExcelExport';
import FlipCard from '../../../../modules/main/home/FlipCard';
import { axiosGetRequest } from '../../../../services/Request';
import environment from '../../../../services/environment';
import { useGetNotEnteredAgencies } from '../api/useGetNotEnteredAgencies';

function NotStartedAgencies({
  includedAgenciesCount: includedAgenciesCountProps,
}) {
  ChartJS.register(ArcElement, Tooltip, Legend);
  ChartJS.defaults.font.family = 'DiodrumArabic';

  const [currentPage, setCurrentPage] = useState(0);
  const [excelLoading, setExcelLoading] = useState(false);
  const [filterValue, setFilterValue] = useState(null);

  const { includedAgenciesCount } = useDashboardContext();

  const history = useHistory();

  const surveyId = localStorage.getItem('surveyId');

  const {
    isGettingNotEnteredAgencies,
    notStartedAgencies,
    notStartedAgenciesCount,
    totalPages,
  } = useGetNotEnteredAgencies({ currentPage, filterValue });

  const columns = [
    {
      name: 'كود الجهة',
      selector: (row) => row.agencyCode,
      header: 'agencyCode',
      accessorKey: 'agencyCode',
      width: '100px',
    },
    {
      name: 'اسم الجهة',
      selector: (row) => row.agencyNameInArabic,
      header: 'agencyNameInArabic',
      accessorKey: 'agencyNameInArabic',
      cell: (row) => (
        <div className="whitespace-nowrap">{row.agencyNameInArabic}</div>
      ),
    },
    {
      name: 'عرض',
      selector: (row) => row.agencyId,
      header: 'agencyId',
      accessorKey: 'agencyId',
      center: true,
      cell: (row) => (
        <NewTooltip content={'عرض التفاصيل'}>
          <FontAwesomeIcon
            icon={faEye}
            className="text-sky-400 bg-sky-100 mr-1 rounded-full p-2 cursor-pointer"
            onClick={() => {
              history.push(`entities/${row.agencyId}/details`);
            }}
          />
        </NewTooltip>
      ),
    },
  ];

  const handlePageClick = (event) => {
    const newOffset = event.selected;
    setCurrentPage(newOffset);
  };

  // ---------- ChartJS -------------------
  const percentage =
    (notStartedAgenciesCount /
      (includedAgenciesCountProps ?? includedAgenciesCount)) *
    100;
  const data = {
    datasets: [
      {
        label: 'جهة',
        data: [percentage, 100 - percentage],
        backgroundColor: ['#e05d46', '#ffc3b9'],
        borderColor: ['#e05d46', '#ffc3b9'],
        cutout: '70%',
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        enabled: false,
      },
      legend: {
        display: false,
      },

      title: {
        display: false,
        text: 'Chart.js Line Chart',
      },
    },
  };
  // ---------- ChartJS -------------------

  const downloadExcel = useExcelExport();

  const handleExcelExport = async () => {
    setExcelLoading(true);
    const res = await axiosGetRequest(environment.getNotStartedAgencies, {
      surveyId: surveyId,
      KeyWord: filterValue,
      SkipCount: 0,
      MaxResultCount: 500,
    });

    setExcelLoading(false);

    const dataForExcel = res?.result?.items?.map((ele) => {
      return {
        'كود الجهة': ele?.agencyCode,
        'اسم الجهة': ele?.agencyNameInArabic,
      };
    });

    downloadExcel('جهات لم تدخل النظام', dataForExcel);
  };

  const handleFilter = (value) => {
    setFilterValue(value);
  };

  return (
    <FlipCard
      title={`جهات لم تدخل النظام (${includedAgenciesCountProps ?? includedAgenciesCount}/${notStartedAgenciesCount})`}
      frontContent={
        <div className="relative w-full h-[400px] flex justify-center items-center">
          <div className="h-[300px]">
            <Doughnut data={data} options={options} />
          </div>

          <div className="absolute font-semibold text-3xl top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            {`${percentage.toFixed(2)}%`}
          </div>

          <div className="absolute right-0 top-0 flex flex-col gap-5">
            <div className="flex gap-3 items-center ">
              <div className="w-3 h-3 bg-[#ffc3b9]"></div>
              <p className="text-sm font-semibold whitespace-nowrap -mt-3">
                الجهات التي دخلت النظام {`${100 - percentage.toFixed(2)}%`}
              </p>
            </div>

            <div className="flex gap-3 items-center ">
              <div className="w-3 h-3 bg-[#e05d46]"></div>
              <p className="text-sm font-semibold whitespace-nowrap -mt-3">
                الجهات التي لم تدخل النظام {`${percentage.toFixed(2)}%`}
              </p>
            </div>
          </div>
        </div>
      }
      backContent={
        <NewTable
          withSearch
          onSearch={handleFilter}
          withExcel
          excelLoading={excelLoading}
          onExportExcel={handleExcelExport}
          columns={columns}
          data={notStartedAgencies || []}
          loading={isGettingNotEnteredAgencies}
          pagination={true}
          totalPages={totalPages}
          onPageChange={handlePageClick}
          currentPage={currentPage}
        />
      }
    />
  );
}

export default NotStartedAgencies;
