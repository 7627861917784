import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import SecondaryTapsLayout from '../../../../../layouts/secondaryTapsLayout';
import { surveySettingTabs } from '../data';
import { Title } from '../../../../../components';
import { programsIcon } from '../../../../../assets/icons/Index';
import { get } from '../../../../../services/Request';
import environment from '../../../../../services/environment';
import NewTable from '../../../../../components/NewTable';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { formatDate, formatTime } from '../../../../../helpers/utils';

const PolicesAndConditions = () => {
  const { surveyId, indicatorId } = useParams();
  const history = useHistory();
  const [totalPages, setTotalPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);

  const [agenciesTerms, setAgenciesTerms] = useState([]);

  useEffect(() => {
    getAllAgencyTerms(0);
  }, []);

  const getAllAgencyTerms = (page) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    get(
      environment.getAllAgenciesTerms +
        '?SkipCount=' +
        page * 6 +
        '&MaxResultCount=' +
        6,
      config,
      (res) => {
        if (res.status == 403) {
          toast.error(t('general.authError'), {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        } else if (res.status == 500) {
          toast.error(t('general.serverError'), {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        } else if (res.status == 200) {
          setAgenciesTerms(res.data.result.items);
          setTotalPages(Math.round(res.data.result.totalCount / 6));
        }
      }
    );
  };

  const handlePageChange = (event) => {
    const newOffset = event.selected;
    setCurrentPage(newOffset);
    getAllAgencyTerms(newOffset);
  };

  return (
    <SecondaryTapsLayout
      linksArr={surveySettingTabs(
        `/indicators/${indicatorId}/surveys/${surveyId}`
      )}
      breadcrumbs={
        <div className="-mx-5">
          <Title
            iconTitle={programsIcon}
            subTitle={'اعدادات الاستبيان'}
            thired={true}
            subTitleSeconed={'السياسات و الاحكام'}
            title={t('المؤشرات')}
            withoutButton={true}
            toPage={() => history.push(`/indicators`)}
            toPageSeconed={() => history.push(`/indicators/${indicatorId}`)}
          />
        </div>
      }
    >
      <div className="bg-gray_light p-6 flex justify-between items-center rounded-lg">
        <h3 className="text-blue_text text-lg">{t('السياسات و الاحكام')}</h3>
        <div className="flex justify-end flex-grow">
          <button
            className="text-white py-2 px-3 min-w-[140px] pt-1 mt-0 rounded-full bg-blue hover:bg-blue_text"
            onClick={() =>
              history.push(
                `/indicators/${indicatorId}/surveys/${surveyId}/settings/polices/add`
              )
            }
          >
            {t('إضافة شروط و احكام')}
          </button>
        </div>
      </div>
      <NewTable
        data={agenciesTerms}
        columns={[
          {
            name: 'اسم المستخدم',
            accessorKey: 'creatorUser',
            selector: (row) => row.creatorUser,
            sortable: true,
            cell: (info) => (
              <div className="text-sm text-[#108AE0]">
                {info.creatorUser?.fullName}
              </div>
            ),
          },
          {
            name: 'أسم الجهة',
            accessorKey: 'agency',
            selector: (row) => row.agency,
            sortable: true,
            cell: (info) => (
              <div className="text-sm text-[#292069]">
                {info.agency?.nameInArabic}
              </div>
            ),
          },
          {
            name: 'أسم الشركة',
            accessorKey: 'companyName',
            selector: (row) => row.companyName,
            sortable: true,
            cell: (info) => (
              <div className="text-sm text-[#292069]">
                {info.companyName == null ? 'لا يوجد' : info.companyName}
              </div>
            ),
          },
          {
            name: 'الحالة',
            accessorKey: 'status',
            selector: (row) => row.user,
            cell: (info) => (
              <div className="text-sm text-[#1CC081]">
                {info.creatorUser == null ? 'غير موافق' : 'وافق'}
              </div>
            ),
            sortable: true,
          },
          {
            name: 'التاريخ',
            accessorKey: 'creationTime',
            selector: (row) => row.creationTime,
            cell: (info) => (
              <div className="text-sm text-[#292069]">
                {formatDate(new Date(info.creationTime)) +
                  '  ' +
                  formatTime(new Date(info.creationTime))}
              </div>
            ),
            sortable: true,
          },
        ]}
        pagination={true}
        totalPages={totalPages}
        onPageChange={handlePageChange}
        currentPage={currentPage}
      />
      {/* <TestTimeline /> */}
      {/* <div className="mb-3">
        <p className="mb-3">السياسات والاحكام</p>
        <AppEditor
          editor={ClassicEditor}
          data={content}
          onReady={(editor) => {
            setContent(editor.getData());
            editor.plugins.get("FileRepository").createUploadAdapter = function(
              loader
            ) {
              
              return new UploadAdapter(loader);
            };
          }}
          config={{
            simpleUpload: {
              uploadUrl: `${process.env.REACT_APP_URL}/api/ckeditor/image-upload`,
              withCredentials: true,

              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            },
          }}
          onChange={(event, editor) => {
            const data = editor.getData();
            setContent(data);
            editor.plugins.get("FileRepository").createUploadAdapter = function(
              loader
            ) {
              
              return new UploadAdapter(loader);
            };
          }}
          onBlur={(event, editor) => {}}
          onFocus={(event, editor) => {}}
        />

        <div className="mt-3 text-end">
          <AppButton onClick={handleSubmit}>حفظ</AppButton>
        </div>
      </div> */}
    </SecondaryTapsLayout>
  );
};

export default PolicesAndConditions;
