import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';

import AddClassification from './addClassification';
import environment from '../../../../../services/environment';
import SecondaryTapsLayout from '../../../../../layouts/secondaryTapsLayout';
import { surveySettingTabs } from '../data';
import { Title } from '../../../../../components';
import { programsIcon } from '../../../../../assets/icons/Index';

import ClassificationList from './classificationList';
import { get } from '../../../../../services/Request';
import ReactPaginate from 'react-paginate';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ClassificationsList = () => {
  const [loading, setLoading] = useState(true);

  const { surveyId, indicatorId } = useParams();
  const history = useHistory();

  // --------------------------------------------- //
  const [allClassifications, setAllClassifications] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(null);

  const getAllClassifications = (page) => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    get(
      environment.getSurveyClassifications + '?SurveyId=' + surveyId,
      // "?SkipCount=" +
      // page * 6 +
      // "&MaxResultCount=" +
      // 6,
      config,
      (res) => {
        if (res?.status == 403) {
          setLoading(false);
        } else if (res.status == 500) {
          setLoading(false);
        } else if (res.status == 200) {
          setAllClassifications(res?.data?.result);
          setTotalPages(Math.ceil(res.data.result.totalCount / 6));
          setLoading(false);
        }
      }
    );
  };

  const handlePageClick = (event) => {
    const newOffset = event.selected;
    setCurrentPage(newOffset);
    getAllClassifications(newOffset);
  };

  const handleUpdateData = async () => {
    await getAllClassifications(currentPage || 0);
  };

  useEffect(() => {
    getAllClassifications(0);
  }, []);

  return (
    <SecondaryTapsLayout
      linksArr={surveySettingTabs(
        `/indicators/${indicatorId}/surveys/${surveyId}`
      )}
      breadcrumbs={
        <div className="-mx-5">
          <Title
            iconTitle={programsIcon}
            subTitle={'اعدادات الاستبيان'}
            thired={true}
            subTitleSeconed={'التصنيف والأوزان للمتطلبات'}
            title={t('المؤشرات')}
            toPage={() => history.push(`/indicators`)}
            toPageSeconed={() => history.push(`/indicators/${indicatorId}`)}
            withoutButton={true}
          />
        </div>
      }
    >
      <div className="mb-5">
        <AddClassification
          handleUpdateData={() => getAllClassifications(currentPage || 1)}
        />
      </div>

      <ClassificationList
        loading={loading}
        allClassifications={allClassifications}
        handleUpdateData={handleUpdateData}
      />

      <div className="m-5 mt-10">
        <ReactPaginate
          nextLabel={
            loading == true ? null : (
              <FontAwesomeIcon
                icon={faChevronLeft}
                style={{ color: '#A1A9C4', fontSize: 14 }}
              />
            )
          }
          onPageChange={handlePageClick}
          initialPage={currentPage}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          pageCount={totalPages}
          previousLabel={
            loading == true ? null : (
              <FontAwesomeIcon
                icon={faChevronRight}
                style={{ color: '#A1A9C4', fontSize: 14 }}
              />
            )
          }
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item-previous"
          previousLinkClassName="page-link-previous"
          nextClassName="page-item-next"
          nextLinkClassName="page-link-next"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          activeClassName="active"
          renderOnZeroPageCount={null}
        />
      </div>
    </SecondaryTapsLayout>
  );
};

export default ClassificationsList;
