import { useTranslation } from 'react-i18next';
import { FModal, InputLabel } from '../../../components';
import { useEffect, useRef, useState } from 'react';
import environment from '../../../services/environment';
import { get, post, put, remove } from '../../../services/Request';
import { useHistory } from 'react-router-dom';
import NewTable from '../../../components/NewTable';
import DeleteIcon from '../../../components/DeleteIcon';
import { toast } from 'react-toastify';
import EditIcon from '../../../components/EditIcon';
import AppColorInput from '../../../components/form/appColorInput';
import { NewForm } from '../../../components/form/NewForm';
import { ErrorMessage } from 'formik';
import { permissionsCheck } from '../../../helpers/utils';

const PrioritiesList = ({ open, setOpen }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [message, setMessage] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingModel, setLoadingModel] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [id, setId] = useState(0);
  const [title, setTitle] = useState('');
  const [color, setColor] = useState('#000000');
  const [priorities, setPriorities] = useState([]);
  const [data, setData] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(null);
  const [deletedId, setDeletedId] = useState('');
  const [openDelete, setOpenDelete] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  // --------------------------------------------- //
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);

  // ----------------------------------------------------- //
  const formRef = useRef(null);
  const formRefEdit = useRef(null);

  const handleSubmitAdd = () => {
    if (formRef.current) {
      formRef.current?.handleSubmit();
    }
  };
  const handleSubmitEdit = () => {
    if (formRefEdit.current) {
      formRefEdit.current?.handleSubmit();
    }
  };

  const initialValues = {
    title: '',
  };

  const validate = (values) => {
    const errors = {};
    if (!title) {
      errors.title = 'هذا الحقل مطلوب';
    }

    return errors;
  };
  // ----------------------------------------------------- //

  const columns = [
    {
      name: t('modules.main.tickets.priority'),
      selector: (row) => row.name,
      header: 'name',
      accessorKey: 'name',
      footer: (props) => props.column.id,
      cell: (row) => (
        <div className="text-slate-400 text-[15px] text-right">{row.name}</div>
      ),
    },
    {
      name: t('modules.main.tickets.color'),
      selector: (row) => row.color,
      header: 'color',
      accessorKey: 'color',
      footer: (props) => props.column.id,
      cell: (row) => (
        <div
          className="text-white w-28 h-12 rounded-xl text-center text-base"
          style={{ padding: '10px', backgroundColor: row.color }}
        >
          {row.color}
        </div>
      ),
    },
    {
      name: t('modules.main.mailbox.actions'),
      selector: (row) => row.id,
      header: 'id',
      accessorKey: 'id',
      footer: (props) => props.column.id,
      cell: (row) =>
        permissionsCheck('Pages.Priority.Edit') && (
          <div className="flex">
            <EditIcon
              onClose={() => clear()}
              initialValuesFunction={() => editPriority(row)}
              loading={editLoading}
              editFunction={handleSubmitEdit}
              type={'submit'}
              editedName={`الأولوية (${row.name})`}
              editModalContent={
                <NewForm
                  initialValues={initialValues}
                  validate={validate}
                  innerRef={formRefEdit}
                  onSubmit={updatePriority}
                >
                  <div className="flex flex-col gap-3">
                    <div className="space-y-1">
                      <InputLabel
                        label={t('modules.main.tickets.priority')}
                        type={'text'}
                        value={title}
                        name={'title'}
                        onChange={handleChangeTitle}
                      />
                      <ErrorMessage
                        className="text-red-700"
                        name="title"
                        component="div"
                      />
                    </div>
                    <div className="space-y-1">
                      <AppColorInput
                        label={'اللون'}
                        type={'color'}
                        name={'color'}
                        value={color}
                        onChange={handleChangeColor}
                      />
                    </div>
                  </div>
                </NewForm>
              }
            />
            {permissionsCheck('Pages.Priority.Delete') ? (
              <DeleteIcon
                loading={deleteLoading}
                deleteFunction={() => deletePriority(row.id)}
                deletedName={row.name}
              />
            ) : null}
          </div>
        ),
    },
  ];

  const handleChangeTitle = (e) => {
    setTitle(e.target.value);
  };

  const handleChangeColor = (e) => {
    setColor(e.target.value);
  };

  useEffect(() => {
    getAllList(0);
  }, []);

  const getAllList = (page) => {
    setPriorities([]);
    setCurrentPage(page);
    getAllPriorities(page);
  };

  const getAllPriorities = (page) => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    get(environment.getAllPriorities, config, (res) => {
      if (res.status == 403) {
        setMessage({ type: 'error', message: t('general.authError') });
        setOpenModal(true);
        setLoading(false);
      } else if (res.status == 500) {
        setMessage({ type: 'error', message: t('general.serverError') });
        setOpenModal(true);
        setLoading(false);
      } else if (res.status == 200) {
        setPriorities(res.data.result.items);
        setTotalPages(Math.ceil(res.data.result.totalCount / 10));
        setTimeout(() => {
          setLoading(false);
        }, 500);
      }
    });
  };

  const clear = () => {
    setData({});
    setId(0);
    setTitle('');
    setColor('#000');
  };

  const editPriority = (row) => {
    setData(row);
    setId(row.id);
    setTitle(row.name);
    setColor(row.color);
  };

  const savePriority = () => {
    if (id == 0) createPriority();
    else updatePriority();
  };

  const createPriority = () => {
    setAddLoading(true);
    if (!title) {
      toast.error(t('modules.main.calendar.nameRequired'));
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    let data = {
      name: title,
      color: color,
    };

    post(environment.createPriority, data, config, (res) => {
      setAddLoading(false);
      if (res.status == 403) {
        toast.error(t('general.authError'));
      } else if (res.status == 500) {
        toast.error(t('general.serverError'));
      } else if (res.status == 200) {
        toast.success('تم اٍضافة أولوية بنجاح');
        getAllList(0);
        setOpen(false);
        clear();
      }
    });
  };

  const updatePriority = () => {
    setEditLoading(true);
    if (!title) {
      toast.error(t('modules.main.calendar.nameRequired'));
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    let data = {
      id: id,
      name: title,
      color: color,
    };
    put(environment.updatePriority, data, config, (res) => {
      setEditLoading(false);
      if (res.status == 403) {
        toast.error(t('general.authError'));
      } else if (res.status == 500) {
        toast.error(t('general.serverError'));
      } else if (res.status == 200) {
        toast.info(`تم تحديث الأولوية بنجاح`);
        clear();
        getAllPriorities(0);
        getAllList(0);
      }
    });
  };

  const deletePriority = (id) => {
    setDeleteLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    remove(environment.deletePriority + '?Id=' + id, config, (res) => {
      setDeleteLoading(false);
      if (res.status == 403) {
        toast.error(t('general.authError'));
      } else if (res.status == 500) {
        toast.error(t('general.serverError'));
      } else if (res.status == 200) {
        toast.success('تم حذف الأولوية بنجاح');
        getAllList(0);
      }
    });
  };

  const handlePageClick = (event) => {
    const newOffset = event.selected;
    setCurrentPage(newOffset);

    getAllList(newOffset);
  };

  return (
    <div>
      <FModal
        loading={addLoading}
        submitLabel={'اٍضافة'}
        headerTitle={t('modules.main.tickets.addPriority')}
        action={handleSubmitAdd}
        type={'submit'}
        content={
          <NewForm
            initialValues={initialValues}
            validate={validate}
            innerRef={formRef}
            onSubmit={createPriority}
          >
            <div className="flex flex-col gap-3">
              <div className="space-y-1">
                <InputLabel
                  label={t('modules.main.tickets.priority')}
                  type={'text'}
                  value={title}
                  name={'title'}
                  onChange={handleChangeTitle}
                />
                <ErrorMessage
                  className="text-red-700"
                  name="title"
                  component="div"
                />
              </div>
              <div className="space-y-1">
                <AppColorInput
                  label={'اللون'}
                  type={'color'}
                  name={'color'}
                  value={color}
                  onChange={handleChangeColor}
                />
              </div>
            </div>
          </NewForm>
        }
        open={open}
        setOpen={() => setOpen(false)}
      />
      {permissionsCheck('Pages.Priority.GetAll') ? (
        <NewTable
          loading={loading}
          columns={columns}
          data={priorities}
          pagination={true}
          totalPages={totalPages}
          onPageChange={handlePageClick}
          currentPage={currentPage}
        />
      ) : (
        <div className="text-[#bb2124] text-xl text-center flex justify-center items-center">
          {'ليس لديك صلاحية لهذا'}
        </div>
      )}
    </div>
  );
};

export default PrioritiesList;
