import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router';
import AppLoader from '../../../../components/loader';
import { isAdmin } from '../../../../helpers/isPermission';
import { inPast, jsonParse } from '../../../../helpers/utils';
import useQueryParams from '../../../../hooks/useQueryParams';
import environment from '../../../../services/environment';
import { axiosGetRequest } from '../../../../services/Request';
import { SurveyRateAnswerForm } from './SurveyRateAnswerForm';

function SurveyRateAnswer() {
  let { satisfictionSurveyId } = useParams();
  const searchParams = useQueryParams();
  const userId = jsonParse(searchParams.get('userId'));

  const { data: questions = [], isLoading: isGettingSurveyQuestions } =
    useQuery({
      queryKey: [environment.GetAllRateSurveyQuestionByRateSurveyId],
      queryFn: () =>
        axiosGetRequest(environment.GetAllRateSurveyQuestionByRateSurveyId, {
          rateSurveyId: satisfictionSurveyId,
          maxResultCount: 1000,
        }),
      select: (res) => res?.result.items,
    });

  const {
    data: answersOnRateSurveyByUserId = [],
    isLoading: isGettingAnswersOnRateSurvey,
  } = useQuery({
    queryKey: [
      environment.GetAnswersOnRateSurveyByUserId,
      satisfictionSurveyId,
      userId,
    ],
    queryFn: () =>
      axiosGetRequest(environment.GetAnswersOnRateSurveyByUserId, {
        rateSurveyId: satisfictionSurveyId,
        userId: userId ?? localStorage.getItem('userId'),
      }),
    select: (res) => ({
      ...res?.result,
      questionAndAnswer:
        res?.result?.questionAndAnswer?.map((item) => {
          return {
            ...item?.question,
            ...item?.answer,
          };
        }) || null,
    }),
    enabled: !(isAdmin && !userId),
  });

  const { data: rateSurvey } = useQuery({
    queryKey: ['GetRateSurveyStatisticsById', satisfictionSurveyId],
    queryFn: () =>
      axiosGetRequest(environment.rateSurveyGetById, {
        rateSurveyId: satisfictionSurveyId,
      }),
    select: (res) => res?.result,
  });

  return (
    <div className="flex justify-center bg-slate-300 rounded-[30px] m-5 p-12">
      {isGettingSurveyQuestions && (
        <div className="h-[400px]">
          <AppLoader isLoading={isGettingSurveyQuestions} />
        </div>
      )}
      {!(isGettingSurveyQuestions || isGettingAnswersOnRateSurvey) && (
        <SurveyRateAnswerForm
          questions={questions}
          defaultAnswer={answersOnRateSurveyByUserId?.questionAndAnswer}
          isSubmmited={answersOnRateSurveyByUserId?.isSubmmited}
          inActive={inPast(rateSurvey?.endDate)}
        />
      )}
    </div>
  );
}

export default SurveyRateAnswer;
