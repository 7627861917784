import './Component.css';
import Button from './Button';
import { Modal } from 'flowbite-react';
import { checkSuccess, confirmIcon } from '../assets/icons/Index';
import { useTranslation } from 'react-i18next';

const SModal = ({ open, setOpen, subTitle, type }) => {
  const { t } = useTranslation();
  return (
    <Modal
      className="bg-[#FFFFFF99] shadow-lg"
      theme={{
        content: {
          base: 'relative h-full p-4 md:h-auto',
        },
      }}
      show={open}
      size="lg"
      popup
      onClose={() => setOpen(false)}
    >
      <Modal.Header />
      <Modal.Body className="flex flex-col gap-10">
        <div className="text-center flex flex-col justify-center items-center">
          {type == 'success' ? (
            <img src={checkSuccess} alt="success" />
          ) : (
            <img src={confirmIcon} alt="confirm" />
          )}
          <p className="text-2xl text-[#322E65] mt-5">{subTitle}</p>
        </div>
        <div className="items-center w-[100%] mr-auto ml-auto pt-2 text-center">
          <Button
            onClick={() => setOpen(false)}
            title={t('general.goToHome')}
            typeColor={'dark'}
            style={{
              backgroundColor: type === 'success' ? '#2B2969' : '#CD6200',
              borderRadius: 50,
              width: 100,
              borderColor: type === 'success' ? '#2B2969' : '#CD6200',
            }}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SModal;
