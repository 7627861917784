import React, { useContext } from 'react';
import { StandardProgramContext } from '../../../../../../contexts/StandardProgramContext';

import requirements from './../../../../../../assets/icons/requirements.svg';
import openBook from './../../../../../../assets/icons/openBook.svg';
import lamp from './../../../../../../assets/icons/lamp.svg';

const StandardAnswerSelectedRequirements = () => {
  const standardProgramContext = useContext(StandardProgramContext);
  return (
    <>
      <div className="w-[100%] flex flex-row justify-between items-center">
        <div className="flex flex-row items-center">
          <img
            className="bg-transparent ml-2"
            src={requirements}
            alt="requirements"
          />
          <div>
            <h1 className="text-[#292069] font-black text-[20px] mr-2">
              المتطلبات
            </h1>
            <div className="flex flex-row mr-2">
              <img className="bg-transparent ml-2" src={openBook} alt="book" />
              <p className="text-[#292069] text-[14px]">
                تمكنك من مشاهدة إرشادات المؤسسة بجوار كل متطلب
              </p>
            </div>
            <div />
          </div>
        </div>
        <div>
          <div className="group cursor-pointer relative inline-block  w-28 text-center">
            {/* <img src={lamb} className="w-[25px]"/> */}
            <img className="bg-transparent" src={lamp} alt="lamp" />
            <div className="opacity-0 w-28 bg-[#292069] text-white text-center text-xs rounded-lg py-2 absolute z-10 group-hover:opacity-100 bottom-full -left-1/2 ml-14 px-3 pointer-events-none">
              {standardProgramContext.standard?.answerWay == ''
                ? 'لا يوجد'
                : standardProgramContext.standard?.answerWay}
            </div>
          </div>
        </div>
      </div>
      <div className="w-[90%] mt-7 p-2 mr-[88px] flex flex-col justify-evenly items-start overflow-y-auto h-[220px] scrollbar scrollbar-thumb-[#292069] scrollbar-track-[#DBE0EF] text-[16px] scrollGroup">
        <div className="pt-10">
          <div className="text-lg text-[#292069]">
            تم اختيار هذه المتطلبات في المرحلة السابقة
          </div>
          {standardProgramContext.requirementsPrev.length === 0 ? (
            <div className="text-lg text-[#292069] text-center">
              لا يوجد متطلبات سابقة
            </div>
          ) : (
            <ul className="p-5">
              {standardProgramContext.requirementsPrev?.map((item, index) => (
                <>
                  {item.decision == 1 ? (
                    <li style={{ listStyleType: 'circle' }}>{item.title}</li>
                  ) : null}
                </>
              ))}
            </ul>
          )}
        </div>
      </div>
    </>
  );
};

export default StandardAnswerSelectedRequirements;
