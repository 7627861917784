import MultiChoices from './MultiChoices';
import RateNumber from './RateNumber';
import Text from './Text';
import TrueOrFalse from './TrueOrFalse';

export function QuestionType({
  question,
  value,
  attachmentId,
  onChange,
  onUploadStart,
  onUploadEnd,
  onAttachmentChange,
  requiredMessage,
  disabled,
}) {
  switch (question.questionType.id) {
    case 1:
      return (
        <TrueOrFalse
          disabled={disabled}
          value={value}
          attachmentId={attachmentId}
          question={question}
          onChange={onChange}
          onUploadStart={onUploadStart}
          onUploadEnd={onUploadEnd}
          onAttachmentChange={onAttachmentChange}
          requiredMessage={requiredMessage}
        />
      );
    case 2:
      return (
        <RateNumber
          disabled={disabled}
          value={value}
          attachmentId={attachmentId}
          question={question}
          onChange={onChange}
          onUploadStart={onUploadStart}
          onUploadEnd={onUploadEnd}
          onAttachmentChange={onAttachmentChange}
          requiredMessage={requiredMessage}
        />
      );
    case 3:
      return (
        <MultiChoices
          disabled={disabled}
          value={value}
          attachmentId={attachmentId}
          question={question}
          onChange={onChange}
          onUploadStart={onUploadStart}
          onUploadEnd={onUploadEnd}
          onAttachmentChange={onAttachmentChange}
          requiredMessage={requiredMessage}
        />
      );
    case 4:
      return (
        <Text
          disabled={disabled}
          value={value}
          attachmentId={attachmentId}
          question={question}
          onChange={onChange}
          onUploadStart={onUploadStart}
          onUploadEnd={onUploadEnd}
          onAttachmentChange={onAttachmentChange}
          requiredMessage={requiredMessage}
        />
      );
    default:
      return null;
  }
}
