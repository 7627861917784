import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import AppLoader from '../../../../../../../components/loader';
import { StageContext } from '../../../../../../../contexts/stageContext';
import { useAnswerContext } from '../../answerContext';
import StandardAnswerData from '../../standardAnswerData';
import StandardAnswerNotes from '../../standardAnswerNotes';
import useCreateExpertAnswer from '../useCreateAnswer/useCreateExpertAnswer';
import usePrevExpertAnswer from '../useGetPrevAnswer/usePrevExpertAnswer';
import ExpertFinalAnswer from './expertFinalAnswer';
import ExpertNoteTemplate from './expertNoteTemplates';
import ExpertProofFiles from './expertProofFiles';
import ExpertRequirementAnswer from './ExpertRequirementAnswer';
import ExpertRequirementAnswerConfidential from './ExpertRequirementAnswerConfidential';
import ExpertVisit from './expertVisit';
import NotesForAgency from './notesForAgency';
import Phase4ExpertAnswer from './phase4ExpertAnswer';
import { axiosPostRequest } from '../../../../../../../services/Request';
import environment from '../../../../../../../services/environment';
import { useQuery } from '@tanstack/react-query';

const ExpertAnswer = ({ menuElement, setMenuElement }) => {
  const { currentStage } = useContext(StageContext);
  const { getPrevExpertAnswer } = usePrevExpertAnswer();
  const { newAgencyAnswer } = useAnswerContext();
  const { loadPreExpertAnswer } = useAnswerContext();
  const { createExpertAnswer } = useCreateExpertAnswer();
  const { entityId, standardId } = useParams();
  const isForthStage = currentStage?.stageNumber === 4;
  const isFifthStage = currentStage?.stageNumber === 5;
  const isConfidentialAgency = newAgencyAnswer.isConfidentialAgency;

  useEffect(() => {
    if (currentStage) {
      if (!isFifthStage) {
        getPrevExpertAnswer(standardId, entityId, currentStage?.id);
      }
    }
  }, [currentStage]);

  const { data: isExpertAllowedToSendReAnswerRequest = false } = useQuery({
    queryKey: ['IsExpertAllowedToAnswer2', standardId, entityId],
    queryFn: () =>
      axiosPostRequest(
        environment.IsExpertAllowedToAnswer,
        {
          standardId,
          stageId: currentStage?.id,
          agencyId: entityId,
        },
        null,
        true,
        true
      ),
    enabled: !!currentStage,
    select: (data) => !data.result,
  });

  if (isForthStage || isFifthStage) {
    return (
      <Phase4ExpertAnswer
        menuElement={menuElement}
        setMenuElement={setMenuElement}
      />
    );
  }

  if (menuElement === 3 && isConfidentialAgency) {
    if (loadPreExpertAnswer && menuElement !== 0) {
      return (
        <div className="min-h-[400px] flex justify-center items-center">
          <AppLoader />
        </div>
      );
    } else {
      return (
        <ExpertRequirementAnswerConfidential setMenuElement={setMenuElement} />
      );
    }
  }

  function createAnswerAsDraft() {
    createExpertAnswer(standardId, entityId, currentStage?.id, true);
  }

  return (
    <div className="w-full mx-2 bg-white mt-4 overflow-y-scroll relative rounded-md h-[505px] scrollGroup">
      {menuElement === 0 && (
        <StandardAnswerData
          onNext={() => setMenuElement(1)}
          loading={loadPreExpertAnswer}
        />
      )}

      {loadPreExpertAnswer && menuElement !== 0 ? (
        <div className="min-h-[400px] flex justify-center items-center">
          <AppLoader />
        </div>
      ) : (
        <>
          {menuElement === 1 && (
            <ExpertProofFiles setMenuElement={setMenuElement} />
          )}
          {menuElement === 3 && (
            <ExpertRequirementAnswer setMenuElement={setMenuElement} />
          )}
          {menuElement === 4 && (
            <ExpertFinalAnswer setMenuElement={setMenuElement} />
          )}
          {menuElement === 5 && <ExpertVisit setMenuElement={setMenuElement} />}
          {menuElement === 6 && (
            <NotesForAgency setMenuElement={setMenuElement} />
          )}
          {menuElement === 7 && (
            <StandardAnswerNotes
              createAnswer={() =>
                createExpertAnswer(standardId, entityId, currentStage?.id)
              }
              createAnswerAsDraft={createAnswerAsDraft}
              isExpertAllowedToSendReAnswerRequest={
                isExpertAllowedToSendReAnswerRequest
              }
            >
              <ExpertNoteTemplate />
            </StandardAnswerNotes>
          )}
        </>
      )}
    </div>
  );
};

export default ExpertAnswer;
