import { ErrorMessage, Formik } from 'formik';
import { useContext, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { AppInput, DropdownMultiSelect } from '../../../../../components';
import AppTextarea from '../../../../../components/form/appTextarea';
import AppModal from '../../../../../components/Models/appModal';
import { IndicatorsContext } from '../../../../../contexts/indicatorsContext';
import environment from '../../../../../services/environment';
import { axiosGetRequest } from '../../../../../services/Request';
import { generateRandomString } from '../../answersReport/utils';

const CopyIndicatorModal = ({ openAddModal, setOpenAddModal }) => {
  const { addnewIndicator, getIndicatorList } = useContext(IndicatorsContext);
  const [loading, setLoading] = useState(false);
  const [inputs, setInputs] = useState({
    title: '',
    description: '',
    id: '',
  });

  const handleInputChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    const randomNumber = generateRandomString(5);

    const promise = axiosGetRequest(environment.CopyIndicatorBackgroundJob, {
      OriginalIndicatorId: inputs.id,
      CopyIndicatorTitle: inputs.title,
      CopyIndicatorDescription: inputs.description,
      requestId: randomNumber,
    });

    const delayedPromise = new Promise((resolve, reject) => {
      promise.then(() => {
        document.addEventListener('ReceivingCopyIndicator', (data) => {
          if (data.detail.isSuccess && data.detail.requestId === randomNumber) {
            resolve(data.detail.result);
          } else if (
            !data.detail.isSuccess &&
            data.detail.requestId === randomNumber
          ) {
            reject(data.detail);
          }
        });
      });
    });

    delayedPromise.then((data) => {
      getIndicatorList();
    });

    toast.promise(delayedPromise, {
      pending: 'جارى نسخ المؤشر',
      success: 'تم نسخ المؤشر بنجاح',
      error: 'حدث خطأ أثناء نسخ المؤشر',
    });

    setOpenAddModal(false);

    setInputs({
      title: '',
      description: '',
      id: '',
    });
  };

  const formRef = useRef(null);

  const handleSubmitAdd = () => {
    if (formRef.current) {
      formRef.current?.handleSubmit();
    }
  };

  const initialValues = {
    title: '',
    description: '',
    id: '',
  };

  const validate = (values) => {
    const errors = {};

    if (!inputs.id) {
      errors.id = 'هذا الحقل مطلوب';
    }

    if (!inputs.title.trim()) {
      errors.title = 'هذا الحقل مطلوب';
    }

    return errors;
  };
  const [selectedInterlocutor, setSelectedInterlocutor] = useState({});
  const { indicators: allInterlocutor } = useContext(IndicatorsContext);
  const handleChangeInterlocutor = (selectedInterlocutorObj) => {
    setSelectedInterlocutor(selectedInterlocutorObj || '');
    setInputs({ ...inputs, id: selectedInterlocutorObj.value });
  };
  const interlocutorOptions = allInterlocutor.map((item) => ({
    value: item.id,
    label: item.title,
  }));

  return (
    <AppModal
      headerTitle="نسخ مؤشر"
      open={openAddModal}
      setOpen={setOpenAddModal}
      handleSubmit={handleSubmitAdd}
      type={'submit'}
      loading={loading}
      submitLabel="نسخ"
      dismissible={false}
    >
      <Formik
        initialValues={initialValues}
        validate={validate}
        innerRef={formRef}
        onSubmit={handleSubmit}
      >
        {({ handleChange, values }) => (
          <div>
            <div className="w-full mb-5">
              <DropdownMultiSelect
                placeholder={'اختر المؤشر'}
                isClearable={true}
                withoutLabel={false}
                name={'id'}
                isSearchable={false}
                value={selectedInterlocutor}
                handleChange={handleChangeInterlocutor}
                options={interlocutorOptions || []}
                isMultiple={false}
                isRequired={true}
                label={'اختر المؤشر'}
              />
              <ErrorMessage
                className="text-red-700"
                name="id"
                component="div"
              />
            </div>
            <div>
              <AppInput
                type={'text'}
                label={'إسم المؤشر'}
                name="title"
                variant="gray"
                value={inputs.title}
                onChange={handleInputChange}
              />
              <ErrorMessage
                className="text-red-700"
                name="title"
                component="div"
              />
            </div>
            <div className="mt-3">
              <AppTextarea
                label={'الوصف'}
                name="description"
                value={inputs.description}
                onChange={handleInputChange}
              />
            </div>
          </div>
        )}
      </Formik>
    </AppModal>
  );
};

export default CopyIndicatorModal;
