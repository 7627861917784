import { useContext, useEffect, useState } from 'react';
import IndicatorItem from './indicatorItem';
import IndicatorItemSkeleton from './indicatorItemSkeleton';
import Skeleton from 'react-skeleton-loader';
import { IndicatorsContext } from '../../../../../contexts/indicatorsContext';
import DrawerFilter from '../../../../../components/DrawerFilter';
import { InputLabel } from '../../../../../components';
import { permissionsCheck } from '../../../../../helpers/utils';

const IndicatorList = () => {
  const [indicatorName, setIndicatorName] = useState('');
  const [description, setDescription] = useState('');

  const { indicators, getIndicatorList, loading } =
    useContext(IndicatorsContext);

  const clearSearch = () => {
    setIndicatorName('');
    setDescription('');
  };

  useEffect(() => {
    getIndicatorList(indicatorName, description);
  }, []);

  const renderedIndicators = indicators?.map((item) => (
    <IndicatorItem
      key={item.id}
      id={item.id}
      title={item?.title}
      description={item?.description}
      moreLink={`/indicators/${item?.id}`}
      loading={loading}
    />
  ));
  return (
    <div className="pt-0">
      <div className="flex justify-between my-3 p-2 items-center">
        <p className="text-light">
          {loading ? <Skeleton /> : `${indicators?.length} عنصر بالقائمة`}
        </p>

        <DrawerFilter
          handleSearch={() => getIndicatorList(indicatorName, description)}
          handleClear={clearSearch}
        >
          <div className="w-full">
            <InputLabel
              type={'text'}
              name="title"
              value={indicatorName}
              onChange={(e) => setIndicatorName(e.target.value)}
              label={'اسم المؤشر'}
              isRequired={false}
            />
          </div>
        </DrawerFilter>
      </div>
      {permissionsCheck('Pages.Indicators.GetAll') ? (
        <div className="grid mt-3 gap-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3">
          {loading ? (
            <>
              <IndicatorItemSkeleton />
              <IndicatorItemSkeleton />
              <IndicatorItemSkeleton />
              <IndicatorItemSkeleton />
              <IndicatorItemSkeleton />
              <IndicatorItemSkeleton />
            </>
          ) : (
            renderedIndicators
          )}
        </div>
      ) : (
        <div className="text-[#bb2124] text-xl text-center flex justify-center items-center">
          {'ليس لديك صلاحية لهذا'}
        </div>
      )}
    </div>
  );
};

export default IndicatorList;
