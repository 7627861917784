import { Formik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactSwitch from 'react-switch';
import { toast } from 'react-toastify';
import CModal from '../../../components/CModal';
import DropdownMultiSelect from '../../../components/DropdownMultiSelect';
import FModal from '../../../components/FModal';
import InputLabel from '../../../components/InputLabel';
import NewDropdownMultiSelect from '../../../components/NewDropDownMultiSelect';
import NewTooltip from '../../../components/NewTooltip';
import SModal from '../../../components/SModal';
import useAxiosGetRequest from '../../../helpers/customHooks/useAxiosGetRequest';
import { Lang } from '../../../helpers/utils';
import i18n from '../../../languages/i18n';
import { get, post, put, remove } from '../../../services/Request';
import environment from '../../../services/environment';
import { pen, removeIcon } from './../../../assets/icons/Index';
import { permissionsCheck } from './../../../helpers/utils';

let ar = {
  number: 'الرقم التسلسلي',
  name: 'الإسم',
  ssn: 'الهوية',
  permission: 'الدور',
  entity: 'الجهة الحكومية',
  phone: 'الرقم',
  edit: 'تعديل',
  delete: 'حذف',
  status: 'الحالة',
};
let en = {
  number: 'Number',
  name: 'Name',
  ssn: 'SSN',
  permission: 'Role',
  entity: 'Government entity',
  phone: 'Phone',
  edit: 'Edit',
  delete: 'Delete',
  status: 'Status',
};

const Users = [
  {
    name: i18n.language == Lang.AR ? ar.number : en.number,
    selector: (row) => row.id,
    header: i18n.language == Lang.AR ? ar.number : en.number,
    accessorKey: 'number',
    footer: (props) => props.column.id,
    sortable: true,
    width: '250px',
  },
  {
    name: i18n.language == Lang.AR ? ar.name : en.name,
    selector: (row) => row.fullName,
    header: i18n.language == Lang.AR ? ar.name : en.name,
    accessorKey: 'name',
    footer: (props) => props.column.id,
    cell: (info) => (
      <div className="flex flex-row justify-between items-center w-[100%]">
        <div className="flex items-center justify-center rounded-full w-[50px] h-[50px] bg-[#292069]/[.15] text-center">
          {info.profilePicture == null ? (
            info.fullName
              .split(' ')
              .map((word) => word[0])
              .join('')
              .toUpperCase()
          ) : (
            <img
              alt=""
              className="rounded-full w-[50px] h-[50px]"
              src={`${process.env.REACT_APP_FRONT_URL}${info.profilePicture}`}
            />
          )}
        </div>
        <div className="flex flex-col justify-between">
          <span className="text-sm text-black">{info.fullName}</span>
          <span className="text-sm text-[#7F7F7F]">{info.emailAddress}</span>
        </div>
      </div>
    ),
    width: '250px',
    sortable: true,
  },
  {
    name: i18n.language == Lang.AR ? ar.ssn : en.ssn,
    selector: (row) => row.iqama,
    header: i18n.language == Lang.AR ? ar.ssn : en.ssn,
    accessorKey: 'iqama',
    footer: (props) => props.column.id,
    sortable: true,
    width: '200px',
    cell: (info) => <div>{info.iqama == null ? 'لا يوجد ' : info.iqama}</div>,
  },
  {
    name: i18n.language == Lang.AR ? ar.permission : en.permission,
    selector: (row) => row.roleNames,
    header: i18n.language == Lang.AR ? ar.permission : en.permission,
    accessorKey: 'permission',
    footer: (props) => props.column.id,
    cell: (info) => (
      <div className="grid grid-cols-2">
        {' '}
        {info.roleNames.map((name, index) => (
          <div className="flex p-1 bg-[#1F925433] justify-center items-center rounded m-1">
            <span key={index} className="text-[10px] text-[#1F9254]">
              {name}
            </span>
          </div>
        ))}
      </div>
    ),
    width: '250px',
    sortable: true,
  },
  {
    name: i18n.language == Lang.AR ? ar.entity : en.entity,
    selector: (row) => row.agencyNameInArabic,
    header: i18n.language == Lang.AR ? ar.entity : en.entity,
    accessorKey: 'entity',
    sortable: true,

    footer: (props) => props.column.id,
    cell: (info) => (
      <div>
        <span>
          {info.agencyNameInArabic != null
            ? info.agencyNameInArabic
            : 'لا يوجد'}
        </span>
      </div>
    ),
    width: '250px',
  },
  {
    name: i18n.language == Lang.AR ? ar.phone : en.phone,
    selector: (row) => row.mobile,
    header: i18n.language == Lang.AR ? ar.phone : en.phone,
    accessorKey: 'mobile',
    sortable: true,

    footer: (props) => props.column.id,
    cell: (info) => (
      <div>
        <span className="text-[12px]">
          {info.mobile != '' ? info.mobile : 'لا يوجد'}
        </span>
      </div>
    ),
    width: '260px',
  },
  {
    name: i18n.language == Lang.AR ? ar.edit : en.edit,
    header: i18n.language == Lang.AR ? ar.edit : en.edit,
    accessorFn: (row) => row.status,
    footer: (props) => props.column.id,
    cell: (info) => <EditAction user={info} />,
  },
  {
    name: i18n.language == Lang.AR ? ar.delete : en.delete,
    header: i18n.language == Lang.AR ? ar.delete : en.delete,
    accessorFn: (row) => row.status,
    footer: (props) => props.column.id,
    cell: (info) => <DeleteAction user={info} />,
  },
  {
    name: i18n.language == Lang.AR ? ar.status : en.status,
    header: i18n.language == Lang.AR ? ar.status : en.status,
    accessorFn: (row) => row.status,
    footer: (props) => props.column.id,
    cell: (info) => <StatusAction user={info} />,
  },
];

function EditAction({ user }) {
  const { t } = useTranslation();
  const [openConfirmModal, setOpenConfirmModal] = useState('');
  const [role, setRole] = useState(null);
  const [entity, setEntity] = useState(null);
  const [rolesArr] = useState([]);
  const [experts, setExperts] = useState([]);
  const [getEntitiesForFilter, entitiesForFilter] = useAxiosGetRequest();

  const [message] = useState({});
  const [roleStatic, setRoleStatic] = useState(null);
  const formUserRef = useRef(null);

  const handleSubmitEditUser = () => {
    if (formUserRef.current) {
      formUserRef.current?.handleSubmit();
    }
  };
  const [open, setOpen] = useState(false);
  useEffect(() => {
    user.roleNames.forEach((item, index) => {
      let obj = { label: item, value: item };
      rolesArr.push(obj);
    });
    setRole(rolesArr);
    setEntity({ label: user.agencyTitle, value: user.agencyId });
    setRoleStatic({ label: user.staticRole, value: user.staticRole });
  }, []);
  const openConfirmationModal = () => {
    setOpenConfirmModal('popup-modal');
  };
  useEffect(() => {
    getEntitiesForFilter(environment.getAllAgencyWithoutPagination);
  }, []);

  const entitiesOptions =
    entitiesForFilter?.result &&
    entitiesForFilter?.result?.map((item) => {
      return { label: item?.nameInArabic?.toString() || '', value: item.id };
    });

  const [roles, setRoles] = useState([]);
  const [entities, setEntities] = useState([]);
  const [rolesStatic, setRolesStatic] = useState([]);
  const [selectedExperts, setSelectedExperts] = useState([]);

  const handleChangeExperts = (value) => {
    setSelectedExperts(value);
  };
  const handleChangeRoles = (value) => {
    setRole(value);
  };

  const handleChangeEntities = (value) => {
    setEntity(value);
  };

  const getAllRolesStatic = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    get(
      environment.getAllRoles + '?isStatic=' + true + '&MaxResultCount=' + 1000,
      config,
      (res) => {
        if (res.status == 403) {
          toast.error(res.data.error.message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        } else if (res.status == 500) {
          toast.error(res.data.error.message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        } else if (res.status == 200) {
          let arr = [];
          res.data.result.items.forEach((role) => {
            arr.push({ id: role.id, label: role.name, value: role.name });
          });

          setRolesStatic(arr, getAllAgency());
        }
      }
    );
  };

  const handleChangeRoleStatic = (value) => {
    setRoleStatic(value);
  };

  useEffect(() => {
    getAllRoles();
  }, []);

  const getAllRoles = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    get(environment.getAllRoles + '?isStatic=' + false, config, (res) => {
      if (res.status == 403) {
        toast.error(res.data.error.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      } else if (res.status == 500) {
        toast.error(res.data.error.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      } else if (res.status == 200) {
        let arr = [];
        res.data.result.items.forEach((role) => {
          arr.push({ label: role.name, value: role.name });
        });
        setRoles(arr, getAllRolesStatic());
      }
    });
  };

  const getAllAgency = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    get(environment.getAllAgencyWithoutPagination, config, (res) => {
      if (res?.status == 403) {
        toast.error(res?.data?.error?.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      } else if (res?.status == 500) {
        toast.error(res?.data?.error?.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      } else if (res?.status == 200) {
        getAllExperts();
        let arr = [];
        res.data.result.forEach((agency) => {
          arr.push({
            label: agency.nameInArabic.toString() || '',
            value: agency.id,
          });
        });
        setEntities(arr, getAllExperts());
      }
    });
  };
  const getAllExperts = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    get(environment.getAllExpertLookUp, config, (res) => {
      if (res.status == 403) {
        toast.error(res.data.error.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      } else if (res.status == 500) {
        toast.error(res.data.error.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      } else if (res.status == 200) {
        let arr = [];
        res.data.result.items.forEach((user) => {
          arr.push({ id: user.id, label: user.fullName, value: user.name });
        });
        setExperts(arr);
      }
    });
  };

  const editUser = (values, profilePictureId) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };

    let newExperts = [];
    if (roleStatic.value == 'Reviewer') {
      selectedExperts.forEach((expert) => {
        newExperts.push(expert.id);
      });
    }
    let newEntity = {
      id: entity.value,
      title: entity.label,
      titleArabic: entity.label,
    };

    let data = {
      id: user.id,
      userName: values.userName == null ? user.userName : values.userName,
      name: values.name == null ? user.name : values.name,
      surname: values.surname == null ? user.surname : values.surname,
      emailAddress:
        values.emailAddress == null ? user.emailAddress : values.emailAddress,
      isActive: true,
      roleNames: [roleStatic.value],

      agencyId: entity == null ? entity : newEntity.id,
      mobile: values.mobile == null ? user.mobile : values.mobile,
      fullName: values.name == null ? user.name : values.name,
      profilePictureId: profilePictureId,
    };

    if (selectedExperts.length) {
      data.experstIds = newExperts;
    }

    put(environment.editUser, data, config, (res) => {
      if (res.status == 400) {
        toast.success(res.response.data.error.details, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      } else {
        toast.success(t('modules.main.users.users.messages.editSuccess'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
        window.location.reload(false);
      }
    });
  };

  return (
    <>
      <div className="flex justify-center items-center space-x-2">
        <div></div>
        <SModal
          type={message.type}
          open={openConfirmModal}
          setOpen={() => setOpenConfirmModal(false)}
          subTitle={message.message}
        />
        <FModal
          content={
            <div>
              <Formik
                innerRef={formUserRef}
                initialValues={{
                  userName: user.userName,
                  name: user.name,
                  surname: user.surname,
                  emailAddress: user.emailAddress,
                  isActive: user.isActive,
                  roleNames: role,
                  agencyId: entity,
                  mobile: user.mobile,
                  profilePictureId: user.profilePictureId,
                  staticRole: roleStatic,
                }}
                validate={(values) => {
                  const errors = {};
                  if (!values.userName) {
                    errors.userName = t(
                      'modules.main.users.users.messages.userName'
                    );
                  }
                  if (!values.name) {
                    errors.name = t('modules.main.users.users.messages.name');
                  }

                  if (!values.emailAddress) {
                    errors.emailAddress = t(
                      'modules.main.users.users.messages.email'
                    );
                  }
                  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                  if (!regex.test(values.emailAddress)) {
                    errors.emailAddress = t(
                      'modules.main.users.users.messages.emailFormat'
                    );
                  }

                  if (roleStatic == null) {
                    errors.roleStatic = t(
                      'modules.main.users.users.messages.permissionStatic'
                    );
                  }

                  if (values.mobile) {
                    if (values.mobile.length != 14) {
                      errors.mobile = t(
                        'modules.main.users.users.messages.phoneLength'
                      );
                    }
                    if (!values.mobile.startsWith('0096')) {
                      errors.mobile = t(
                        'modules.main.users.users.messages.phoneStart'
                      );
                    }
                  }
                  return errors;
                }}
                onSubmit={(values) => {
                  editUser(values, user.profilePictureId);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <div>
                    <InputLabel
                      value={values.userName}
                      onChange={handleChange}
                      name="userName"
                      type={'text'}
                      label={t('modules.main.users.users.form.userName')}
                      placeholder={t(
                        'modules.main.users.users.placeholder.userName'
                      )}
                      style={{
                        borderColor: errors.userName
                          ? 'red'
                          : values.userName != ''
                            ? '#22bb33'
                            : 'transparent',
                      }}
                    />
                    <div className="text-sm text-red-600 mt-3">
                      {errors.userName && touched.userName && errors.userName}
                    </div>
                    <InputLabel
                      value={values.name}
                      onChange={handleChange}
                      name="name"
                      type={'text'}
                      label={t('modules.main.users.users.form.name')}
                      placeholder={t(
                        'modules.main.users.users.placeholder.name'
                      )}
                      style={{
                        borderColor: errors.name
                          ? 'red'
                          : values.name != ''
                            ? '#22bb33'
                            : 'transparent',
                      }}
                    />
                    <div className="text-sm text-red-600 mt-3">
                      {errors.name && touched.name && errors.name}
                    </div>
                    <InputLabel
                      value={values.surname}
                      onChange={handleChange}
                      name="surname"
                      isRequired={false}
                      type={'text'}
                      label={t('modules.main.users.users.form.surname')}
                      placeholder={t(
                        'modules.main.users.users.placeholder.surname'
                      )}
                      style={{
                        borderColor: errors.surname
                          ? 'red'
                          : values.surname != ''
                            ? '#22bb33'
                            : 'transparent',
                      }}
                    />
                    <div className="text-sm text-red-600 mt-3">
                      {errors.surname && touched.surname && errors.surname}
                    </div>
                    <InputLabel
                      style={{
                        borderColor: errors.emailAddress
                          ? 'red'
                          : values.emailAddress != ''
                            ? '#22bb33'
                            : 'transparent',
                      }}
                      value={values.emailAddress}
                      onChange={handleChange}
                      name="emailAddress"
                      type={'email'}
                      label={t('modules.main.users.users.form.email')}
                      placeholder={t(
                        'modules.main.users.users.placeholder.email'
                      )}
                    />
                    <div className="text-sm text-red-600 mt-3">
                      {errors.emailAddress &&
                        touched.emailAddress &&
                        errors.emailAddress}
                    </div>
                    <DropdownMultiSelect
                      isSearchable={true}
                      value={roleStatic}
                      handleChange={handleChangeRoleStatic}
                      placeholder={t(
                        'modules.main.users.users.placeholder.permissionStatic'
                      )}
                      label={t(
                        'modules.main.users.users.form.permissionsStatic'
                      )}
                      options={rolesStatic}
                      isMultiple={false}
                    />
                    <div className="text-sm text-red-600 mt-3">
                      {errors.roleNames &&
                        touched.roleNames &&
                        errors.roleNames}
                    </div>
                    <div>
                      <DropdownMultiSelect
                        isSearchable={true}
                        value={entity}
                        handleChange={handleChangeEntities}
                        placeholder={t(
                          'modules.main.users.users.placeholder.entity'
                        )}
                        label={t('modules.main.users.users.form.entity')}
                        options={entitiesOptions}
                        isMultiple={false}
                      />
                      <div className="text-sm text-red-600 mt-3">
                        {errors.agencyId && touched.agencyId && errors.agencyId}
                      </div>
                    </div>
                    <div className="text-sm text-[#202969] mt-3">
                      يتم اختيار الجهة في حالة كانت الصلاحية جهة
                    </div>

                    <div>
                      <NewDropdownMultiSelect
                        label={'الخبراء'}
                        placeholder={'اختر خبير'}
                        options={experts}
                        handleChange={handleChangeExperts}
                        value={selectedExperts}
                        isMultiple={true}
                      />
                      <div className="text-sm text-red-600 mt-3">
                        {errors.users && touched.users && errors.users}
                      </div>
                    </div>
                    <div className="text-sm text-[#202969] mt-3">
                      يتم اختيار الخبراء في حالة كانت الصلاحية مدقق
                    </div>

                    <InputLabel
                      style={{
                        borderColor: errors.mobile
                          ? 'red'
                          : values.mobile != ''
                            ? '#22bb33'
                            : 'transparent',
                      }}
                      value={values.mobile}
                      onChange={handleChange}
                      type={'text'}
                      name="mobile"
                      label={t('modules.main.users.users.form.phone')}
                      placeholder={t(
                        'modules.main.users.users.placeholder.phone'
                      )}
                    />
                    <div className="text-sm text-red-600 mt-3">
                      {errors.mobile && touched.mobile && errors.mobile}
                    </div>
                  </div>
                )}
              </Formik>
            </div>
          }
          open={open}
          titleButton={t('modules.main.users.users.titleEditButton')}
          setOpen={() => setOpen(false)}
          type={'submit'}
          action={handleSubmitEditUser}
          headerTitle={t('modules.main.users.users.titleEditButton')}
        />
        {permissionsCheck('Pages.Administration.Users.Edit') ? (
          <NewTooltip content={'تعديل'}>
            <div
              onClick={() => setOpen(true)}
              className="rounded-full bg-[#FFF6DC] p-3 cursor-pointer"
            >
              <img src={pen} alt="" />
            </div>
          </NewTooltip>
        ) : null}
      </div>
    </>
  );
}

function DeleteAction({ user }) {
  const { t, i18n } = useTranslation();
  const [message, setMessage] = useState({});
  const [openDelete, setOpenDelete] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const removeUser = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    remove(environment.deleteUser + '?id=' + user.id, config, (res) => {
      toast.success('تم حذف المستخدم بنجاح', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      window.location.reload(false);
    });
  };
  return (
    <>
      <div className="flex justify-center items-center space-x-2">
        <div></div>
        <SModal
          type={message.type}
          open={openConfirmModal}
          setOpen={() => setOpenConfirmModal(false)}
          subTitle={message.message}
        />
        <CModal
          confirmModal={() => removeUser()}
          open={openDelete}
          setOpen={() => setOpenDelete(false)}
          subTitle={`${
            t('modules.main.users.users.messages.confirmMessage') +
            ' ' +
            user.name
          }!`}
        />
        {permissionsCheck('Pages.Administration.Users.Delete') ? (
          <NewTooltip content={'حذف'}>
            <div
              onClick={() => setOpenDelete(true)}
              className="rounded-full bg-[#FEEDEF] p-3 cursor-pointer"
            >
              <img src={removeIcon} alt="remove" />
            </div>
          </NewTooltip>
        ) : null}
      </div>
    </>
  );
}

function StatusAction({ user }) {
  const { t, i18n } = useTranslation();
  const [message, setMessage] = useState({});
  const [openChangeStatus, setOpenChangeStatus] = useState(false);

  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const changeStatusUser = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    let data = {
      id: user.id,
    };
    let url = '';
    if (user.isActive == true) {
      url = environment.UnActiveUser;
    } else {
      url = environment.ActiveUser;
    }
    post(url, data, config, (res) => {
      toast.success('تم تغيير حالة المستخدم', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      window.location.reload(false);
    });
  };

  const handleChange = (checked) => {
    setOpenChangeStatus(true);
  };

  return (
    <>
      <div className="flex justify-center items-center space-x-2">
        <div></div>
        <SModal
          type={message.type}
          open={openConfirmModal}
          setOpen={() => setOpenConfirmModal(false)}
          subTitle={message.message}
        />
        <CModal
          status={true}
          confirmModal={() => changeStatusUser()}
          open={openChangeStatus}
          setOpen={() => setOpenChangeStatus(false)}
          subTitle={`${
            t('modules.main.users.users.messages.confirmMessageStatus') +
            ' ' +
            user.name
          }!`}
        />
        {permissionsCheck('Pages.Administration.Users.Status') ? (
          <ReactSwitch
            onChange={handleChange}
            checked={user.isActive}
            onColor={'#1F9254'}
            offColor="#E92C2C"
          />
        ) : null}
      </div>
    </>
  );
}

export default Users;
