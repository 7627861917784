import { Spinner } from 'flowbite-react';
import { useTranslation } from 'react-i18next';
import { hayaa } from './../../assets/imgs/Index';

const LoginWithNafath = () => {
  const { t } = useTranslation();

  const nafazLoading = false;

  return (
    <div
      dir="rtl"
      className="bg-white h-screen overflow-y-scroll relative flex items-center w-full"
    >
      <div className="flex flex-col md:flex-row items-center container mx-auto p-5 h-full">
        <div className="flex-1 flex justify-center items-center py-20">
          <div className="flex bg-[#F9F9FF] rounded-[20px] h-[70vh] w-full lg:w-[670px]   flex-col items-center justify-center gap-5 ">
            <div className="mb-10">
              <img src={'/logo.svg'} alt="qiyasLogo" className="w-[240px]" />
            </div>

            <h2 className="text-[33px] text-qiyas_violet">مرحباً بك فى قياس</h2>

            <p className="text-lg my-3 text-[#899ABF]">
              من فضلك قم بتسجيل الدخول عبر نفاذ{' '}
            </p>
            <button className="" type="button">
              {nafazLoading ? (
                <div className="flex flex-row  justify-center items-center">
                  <Spinner aria-label="Info spinner example" color="info" />
                  <span className="pl-3 pr-3 text-sm text-white">
                    {t('general.loading')}
                  </span>
                </div>
              ) : (
                <a
                  href={`${process.env.REACT_APP_FRONT_URL}/Nafath`}
                  className="mb-2 block w-full !bg-[#02aaad] max-w-72 hover:!bg-[#379496]  transition-all duration-300 rounded-xl  px-10 py-2 pb-4 text-white"
                >
                  تسجيل الدخول
                </a>
              )}
            </button>
          </div>
        </div>
      </div>

      <div className="absolute right-10 top-10">
        <img src={hayaa} alt="hayaa" width={203.5443037974684} height={60} />
      </div>
    </div>
  );
};

export default LoginWithNafath;
