import React, { createContext, useState } from 'react';
import { axiosGetRequest } from '../../../../../services/Request';
import environment from '../../../../../services/environment';
import { useQuery } from '@tanstack/react-query';

const AddStandardContext = createContext();

const defaultValues = {
  interlocutorId: '',
  title: '',
  titleArabic: '',
  royalOrdersAndCabinetDecisions: '',
  answerWay: '',
  code: '',
  target: '',
  // weight: "",
  standardRequirements: [],
  standardProofs: [],
  agencyStandards: {
    agencies: [],
    commonAgencies: [],
  },
  standardRelations: [],
  pastSurveyStandardAssociations: [],
  allAgencies: false,
};

function AddStandardProvider(props) {
  let [loading, setLoading] = useState(false);
  let [loadingAgenciesList, setLoadingAgenciesList] = useState(false);
  let [validValues, setValidValues] = useState({
    aboutStandard: false,
    requirmentList: false,
    proofFile: true,
  });
  let [aboutStandard, setAboutStandard] = useState({});
  let [addRequirmentsObj, setAddRequirmentsObj] = useState({
    title: '',
    recommendation: '',
    standardRequirementClassificationId: '',
    subStandardRequirements: [],
    agencyStandardRequirements: [],
  });
  let [requirmentList, setRequirmentList] = useState([]);
  let [proofFile, setProofFile] = useState([]);
  let [subRequirementCount, setSubRequirementCount] = useState(0);
  const [mainAgency, setMainAgency] = useState('');
  const [nonReTurnableStandard, setNonReTurnableStandard] = useState();
  const [expiryDate, setExpiryDate] = useState();

  let [selectedStandards, setSelectedStandards] = useState([]);
  let [selectedRequirementAgencies, setSelectedRequirementAgencies] = useState(
    []
  );
  let [standardList, setStandardList] = useState([]);
  let [oldStandardList, setOldStandardList] = useState([]);
  let [selectedOldStandardList, setSelectedOldStandardList] = useState('');

  let [selectedCommonAgencies, setSelectedCommonAgencies] = useState([]);
  let [selectedAgencies, setSelectedAgencies] = useState([]);
  let [agencyList, setAgencyList] = useState([]);
  let [classifications, setClassifications] = useState([]);

  const [editRequirementClassifications, setEditRequirementClassifications] =
    useState([]);

  let [
    selectedRequirementAgenciesForEdit,
    setSelectedRequirementAgenciesForEdit,
  ] = useState([]);
  // let [allAgenciesOptionsForEdit, setAllAgenciesOptionsForEdit] = useState([]);

  const [addFinalValue, setAddFinalValue] = useState(defaultValues);

  const chooseDocType = [
    { id: 1, label: 'اجبارية', value: 1 },
    { id: 2, label: 'اجبارية ما عدا الهيئات السرية ', value: 2 },
    { id: 3, label: 'اختيارية', value: 3 },
  ];
  const fileTypes = [
    { id: 2, label: 'Pdf', value: 2 },
    { id: 3, label: 'Word', value: 3 },
    { id: 4, label: 'Excel', value: 1 },
    { id: 5, label: 'CSV', value: 4 },
  ];

  const { data: FileTypeGetAll } = useQuery({
    queryKey: 'StandardProofFileTypeGetAll',
    queryFn: () => {
      return axiosGetRequest(environment.StandardProofFileTypeGetAll, {
        skipCount: 0,
        maxResultCount: 100,
      });
    },
    select: (data) => {
      return data?.result?.items?.map((item) => ({
        ...item,
        label: item.title,
        value: item.id,
      }));
    },
  });

  const sizeType = [
    { id: 1, label: 'للملف', value: 1 },
    { id: 2, label: 'لكل الملفات', value: 2 },
  ];

  const getClassifications = async (surveyId) => {
    setLoading(true);
    const res = await axiosGetRequest(environment.getSurveyClassifications, {
      SurveyId: surveyId,
    });
    if (res.success) {
      setClassifications(res?.result);
    }
    setLoading(false);
  };

  const getAllStandards = async (surveyId) => {
    setLoading(true);

    const res = await axiosGetRequest(environment.getStandards, {
      SurveyId: surveyId,
    });
    if (res.success) {
      setStandardList(res?.result?.items);
    }
    setLoading(false);
  };
  const getLastYearStandards = async () => {
    setLoading(true);

    const res = await axiosGetRequest(environment.getQiyas23Standards);
    if (res.success) {
      setOldStandardList(res?.result);
    }
    setLoading(false);
  };

  const getAllAgency = async () => {
    setLoadingAgenciesList(true);

    const res = await axiosGetRequest(
      environment.getAllAgencyWithoutPagination
    );
    if (res.success) {
      setAgencyList(res?.result);
    }
    setLoadingAgenciesList(false);
  };

  return (
    <AddStandardContext.Provider
      value={{
        loading,
        loadingAgenciesList,
        setLoadingAgenciesList,
        aboutStandard,
        setAboutStandard,
        requirmentList,
        setRequirmentList,
        addRequirmentsObj,
        setAddRequirmentsObj,
        subRequirementCount,
        setSubRequirementCount,
        selectedRequirementAgencies,
        setSelectedRequirementAgencies,

        proofFile,
        setProofFile,

        classifications,
        getClassifications,

        agencyList,
        getAllAgency,
        selectedAgencies,
        setSelectedAgencies,
        selectedCommonAgencies,
        setSelectedCommonAgencies,
        mainAgency,
        setMainAgency,
        nonReTurnableStandard,
        setNonReTurnableStandard,

        expiryDate,
        setExpiryDate,

        standardList,
        getAllStandards,
        selectedStandards,
        setSelectedStandards,
        getLastYearStandards,
        oldStandardList,
        setOldStandardList,
        selectedOldStandardList,
        setSelectedOldStandardList,

        editRequirementClassifications,
        setEditRequirementClassifications,

        chooseDocType,
        fileTypes: FileTypeGetAll || fileTypes,
        sizeType,

        validValues,
        setValidValues,

        addFinalValue,
        setAddFinalValue,

        selectedRequirementAgenciesForEdit,
        setSelectedRequirementAgenciesForEdit,
      }}
    >
      {props.children}
    </AddStandardContext.Provider>
  );
}

export { AddStandardContext, AddStandardProvider };
