import { useQuery, useQueryClient } from '@tanstack/react-query';
import { axiosGetRequest } from '../../../../services/Request';
import environment from '../../../../services/environment';
import { StageContext } from '../../../../contexts/stageContext';
import { useContext } from 'react';

export const useGetNewAgencies = ({ currentPage, filterValue }) => {
  const queryClient = useQueryClient();
  const surveyId = localStorage.getItem('surveyId');
  const { currentStage } = useContext(StageContext);
  const phaseOneStageId = currentStage?.stageIds?.[0];

  const { data, isLoading: isGettingNewAgencies } = useQuery({
    queryKey: ['NewAgencies', currentPage, filterValue, phaseOneStageId],
    queryFn: ({ signal }) =>
      axiosGetRequest(
        environment.getNewAgencies,
        {
          surveyId: surveyId,
          KeyWord: filterValue,
          SkipCount: currentPage * 4,
          MaxResultCount: 4,
          surveyStageId: phaseOneStageId,
        },
        true,
        signal
      ),
    enabled: !!phaseOneStageId,
  });

  const newAgencies = data?.result?.items;
  const newAgenciesCount = data?.result?.totalCount;
  const totalPages = Math.ceil(newAgenciesCount / 4);

  return {
    queryClient,
    data,
    newAgencies,
    newAgenciesCount,
    totalPages,
    isGettingNewAgencies,
  };
};
