import { useContext, useEffect, useState } from 'react';
import StandardFinalAnswer from '../../standardAgencyAnswer';
import { StageContext } from '../../../../../../../contexts/stageContext';
import { useAnswerContext } from '../../answerContext';
import AppCheckbox from '../../../../../../../components/form/appCheckbox';
import useCreateReviewerAnswer from '../useCreateAnswer/useCreateReviewerAnswer';
import AppTextarea from '../../../../../../../components/form/appTextarea';

const ExpertManagerFinalAnswer = ({
  setMenuElement,
  expertMangerAnswerOnPhase2,
}) => {
  const { currentStage } = useContext(StageContext);
  const isFourthStage = currentStage?.displayOrder === 4;
  const { createReviewerAnswer } = useCreateReviewerAnswer();
  const {
    newExpertManagerAnswer,
    setNewExpertManagerAnswer,
    expertManagerDegree,
  } = useAnswerContext();
  const [checkedDegree, setCheckedDegree] = useState(null);

  const handleDegreeChange = (degree) => {
    setCheckedDegree(degree === checkedDegree ? '' : degree);

    setNewExpertManagerAnswer({
      ...newExpertManagerAnswer,
      checkedExpertManagerDegree: degree === checkedDegree ? '' : degree,
    });
  };

  useEffect(() => {
    setCheckedDegree(newExpertManagerAnswer?.checkedExpertManagerDegree);
  }, []);

  const handleChangeNote = (note) => {
    setNewExpertManagerAnswer({
      ...newExpertManagerAnswer,
      expertManagerAnswerNote: note,
    });
  };

  const handleChangeDifferentNote = (note) => {
    setNewExpertManagerAnswer({
      ...newExpertManagerAnswer,
      differentBetweenOldAndNewExpertAnswerNote: note,
    });
  };

  const handleChangeDifferentAgencyNote = (note) => {
    setNewExpertManagerAnswer({
      ...newExpertManagerAnswer,
      differentBetweenAgencyAndExpertAnswerNote: note,
    });
  };

  const renderedDegrees = newExpertManagerAnswer?.surveyDegrees?.map((item) => (
    <AppCheckbox
      key={item?.value}
      id={item.value}
      name={item.label}
      label={item.label}
      value={item.value}
      checked={checkedDegree?.value === item?.value}
      onChange={() => handleDegreeChange(item)}
    />
  ));

  return (
    <StandardFinalAnswer
      setMenuElement={setMenuElement}
      currentStage={currentStage}
      createReviewerAnswer={createReviewerAnswer}
      label="إجابة رئيس الخبراء"
    >
      <div className="flex w-full flex-wrap">
        <div className="shrink-0 max-w-[200px]">{renderedDegrees}</div>
        <div className="flex-auto space-y-10">
          {expertManagerDegree?.expertManagerSelfEvaluationCommitmentLevel
            ?.title !==
            newExpertManagerAnswer?.checkedExpertManagerDegree?.title && (
            <div className="mt-5 ml-8 min-w-[350px] grow">
              <AppTextarea
                rows={5}
                label="سبب تغيير الإجابة عن إجابة النظام"
                placeholder="الرجاء كتابة سبب تغيير الإجابة عن إجابة النظام"
                style={{
                  marginBottom: '24px',
                }}
                value={newExpertManagerAnswer?.expertManagerAnswerNote || ''}
                onChange={(e) => handleChangeNote(e.target.value?.trimStart())}
                warningMessage="سبب التغيير يجب أن يحتوي على 4 حروف على الأقل بدون رموز"
              />
            </div>
          )}
          {isFourthStage &&
            expertMangerAnswerOnPhase2?.result?.lastAnswer
              ?.expertFinalCommitmentLevel?.title !==
              newExpertManagerAnswer?.checkedExpertManagerDegree?.title && (
              <div className="mt-5 ml-8 min-w-[350px] grow">
                <AppTextarea
                  rows={5}
                  label="سبب تغيير الإجابة عن إجابة التحقق الأولى"
                  placeholder="الرجاء كتابة سبب تغيير الإجابة عن إجابة التحقق الأولى"
                  style={{
                    marginBottom: '24px',
                  }}
                  value={
                    newExpertManagerAnswer?.differentBetweenOldAndNewExpertAnswerNote ||
                    ''
                  }
                  onChange={(e) =>
                    handleChangeDifferentNote(e.target.value?.trimStart())
                  }
                  warningMessage="سبب التغيير يجب أن يحتوي على 4 حروف على الأقل بدون رموز"
                />
              </div>
            )}
          {newExpertManagerAnswer?.agencyFinalCommitmentLevel?.title !==
            newExpertManagerAnswer?.checkedExpertManagerDegree?.title && (
            <div className="mt-5 ml-8 min-w-[350px] grow">
              <AppTextarea
                rows={5}
                label="سبب تغيير الإجابة عن إجابة الجهة"
                placeholder="الرجاء كتابة سبب تغيير الإجابة عن إجابة الجهة"
                style={{
                  marginBottom: '24px',
                }}
                value={
                  newExpertManagerAnswer?.differentBetweenAgencyAndExpertAnswerNote ||
                  ''
                }
                onChange={(e) =>
                  handleChangeDifferentAgencyNote(e.target.value?.trimStart())
                }
                warningMessage="سبب التغيير يجب أن يحتوي على 4 حروف على الأقل بدون رموز"
              />
            </div>
          )}
        </div>
      </div>
    </StandardFinalAnswer>
  );
};

export default ExpertManagerFinalAnswer;
