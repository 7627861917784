export function getStatusCategoryStyle(status) {
  if (status === 'استفسار عن معيار') {
    return 'bg-[#5bc0de] font-bold text-white rounded-lg';
  } else if (status === 'استفسار عام') {
    return 'bg-[#22bb33] font-bold text-white rounded-lg';
  } else if (status === 'فئة جهة الصحة') {
    return 'bg-[#bb2124] font-bold text-white rounded-lg';
  } else if (status === 'استفسار تقنى') {
    return 'bg-[#f0ad4e] font-bold text-white rounded-lg';
  } else {
    return 'bg-[#5bc0de] font-bold text-white rounded-lg';
  }
}

export function getStatusStyle(status) {
  if (status === 'جديد') {
    return 'bg-[#5bc0de] text-white';
  } else if (status === 'تحت الاٍجراء') {
    return 'bg-[#22bb33] text-white';
  } else if (status === 'مغلقة') {
    return 'bg-[#bb2124] text-white';
  } else if (status === 'تجاوز مستوى الخدمة') {
    return 'bg-[#f0ad4e] text-white';
  } else {
    return 'bg-[#5bc0de] text-white';
  }
}

export function getStatusText(status) {
  switch (status) {
    case 1:
      return 'تحت الاٍجراء';
    case 2:
      return 'مغلقة';
    case 3:
      return 'تجاوز مستوى الخدمة';
    case 4:
      return 'جديد';
    default:
      return 'تحت الاٍجراء';
  }
}

export function getEscalationStyle(escalation) {
  if (escalation === 1) {
    return 'bg-[#2dff00] text-white';
  } else if (escalation === 2) {
    return 'bg-[#4a86e8] text-white';
  } else if (escalation === 3) {
    return 'bg-[#ffff00] text-white';
  } else if (escalation === 4) {
    return 'bg-[#fd9901] text-white';
  } else if (escalation === 5) {
    return 'bg-[#fc0001] text-white';
  } else {
    return 'bg-[#2dff00] text-white';
  }
}

export function getEscalationText(escalation) {
  switch (escalation) {
    case 1:
      return 'تذكير ١';
    case 2:
      return 'تذكير ٢';
    case 3:
      return 'تصعيد ١';
    case 4:
      return 'تذكير لتصعيد ١';
    case 5:
      return 'تصعيد ٢';
    default:
      return 'تحت الاٍجراء';
  }
}
