import React, { useContext } from 'react';
import { InputLabel } from '../../../../../../components';
import { StandardProgramContext } from '../../../../../../contexts/StandardProgramContext';

import answer from '../../../../../../assets/icons/answer.svg';
import lamp from '../../../../../../assets/icons/lamp.svg';
import SaveBtn from '../components/buttons/saveBtn';
import NextBtn from '../components/buttons/nextBtn';
import { permissionsCheck } from '../../../../../../helpers/utils';

const StandardAgencyAnswer = ({
  setSelectedAgencyDegree,
  setSelectedId,
  selectedId,
  setSelectedReviewerDegree,
  setSelectedReviewerDegreeId,
  selectedReviewerDegreeId,
  setSelectedExpertDegree,
  setSelectedExpertDegreeId,
  selectedExpertDegreeId,
  setMenuElement,
  addAnswer,
  pocAnswer,
}) => {
  const standardProgramContext = useContext(StandardProgramContext);

  // useEffect(() => {
  //   if (standardProgramContext?.donotApply == true) setSelectedId(-1);
  // }, []);

  const handleCheckbox = (event) => {
    var updatedAbilities = [];

    //   var updatedAbilities = [...ids];
    if (event.target.checked) {
      //   updatedAbilities = [...ids, event.target.value];
      updatedAbilities = event.target.value;
    } else {
      updatedAbilities = updatedAbilities?.filter(
        (id) => event.target.value != id
      );
    }
    //   setIds(updatedAbilities?.map(i => i));
    const agencyDegre = standardProgramContext?.degrees.filter(
      (test) => event.target.value == test.id
    )[0];

    setSelectedAgencyDegree(agencyDegre);

    if (agencyDegre?.title == 'لا ينطبق') {
      standardProgramContext.setDonotApply(true);
    } else {
      standardProgramContext.setDonotApply(false);
    }

    setSelectedId(updatedAbilities);
  };

  const isCheckedCheckbox = (id) => {
    return selectedId == id;
    //   return ids.map(i => i).includes(id)
  };
  const handleCheckboxReviewer = (event) => {
    var updatedAbilities = '';
    //   var updatedAbilities = [...ids];
    if (event.target.checked) {
      //   updatedAbilities = [...ids, event.target.value];
      updatedAbilities = event.target.value;
    } else {
      updatedAbilities = updatedAbilities?.filter(
        (id) => event.target.value != id
      );
    }
    //   setIds(updatedAbilities?.map(i => i));
    const reviewerDegr = standardProgramContext?.degrees.filter(
      (test) => event.target.value == test.id
    )[0];
    setSelectedReviewerDegree(reviewerDegr);

    if (reviewerDegr?.title == 'لا ينطبق') {
      standardProgramContext.setDonotApply(true);
    }

    setSelectedReviewerDegreeId(
      updatedAbilities
      // getAllTemplate(standardProgramContext?.standard,updatedAbilities)
    );
  };

  const isCheckedCheckboReviewer = (id) => {
    return selectedReviewerDegreeId == id;
    //   return ids.map(i => i).includes(id)
  };

  const handleCheckboxExpert = (event) => {
    var updatedAbilities = '';
    //   var updatedAbilities = [...ids];
    if (event.target.checked) {
      //   updatedAbilities = [...ids, event.target.value];
      updatedAbilities = event.target.value;
    } else {
      updatedAbilities = updatedAbilities?.filter(
        (id) => event.target.value != id
      );
    }
    //   setIds(updatedAbilities?.map(i => i));
    const expertDegr = standardProgramContext?.degrees?.filter(
      (test) => event.target.value == test.id
    )[0];
    setSelectedExpertDegree(expertDegr);

    if (expertDegr?.title == 'لا ينطبق') {
      standardProgramContext.setDonotApply(true);
    }

    setSelectedExpertDegreeId(updatedAbilities);
  };
  const isCheckedCheckboxExpert = (id) => {
    return selectedExpertDegreeId == id;
    //   return ids.map(i => i).includes(id)
  };

  return (
    <>
      <div className="min-h-[440px] p-3">
        <div className="w-[100%] flex flex-row justify-between items-center">
          <div className="flex flex-row items-center">
            <img className="bg-transparent ml-2" src={answer} alt="answer" />
            <h1 className="text-[#292069] font-black text-[20px]">
              {permissionsCheck('Pages.UserType.POC') ||
              permissionsCheck('Pages.UserType.CIO') ||
              permissionsCheck('Pages.UserType.Delegate')
                ? 'اجابة الجهة'
                : permissionsCheck('Pages.UserType.Reviewer')
                  ? 'اجابة المدقق'
                  : permissionsCheck('Pages.UserType.Expert')
                    ? 'اجابة الخبير'
                    : permissionsCheck('Pages.UserType.ExpertManager')
                      ? 'اجابة رئيس الخبراء'
                      : permissionsCheck('Pages.UserType.POCOfficer')
                        ? 'اجابة ضابط الاتصال'
                        : 'اجابة الجهة'}
            </h1>
          </div>
          {standardProgramContext?.standard?.answerWay && (
            <div>
              <div className="group cursor-pointer relative inline-block  w-28 text-center">
                {/* <img src={lamb} className="w-[25px]"/> */}
                <img className="bg-transparent" src={lamp} alt="lamp" />
                <div className="opacity-0 w-28 bg-[#292069] text-white text-center text-xs rounded-lg py-2 absolute z-10 group-hover:opacity-100 bottom-full -left-1/2 ml-14 px-3 pointer-events-none">
                  {standardProgramContext?.standard?.answerWay == ''
                    ? 'لا يوجد'
                    : standardProgramContext?.standard?.answerWay}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="w-[100%] mt-7 pr-[88px] flex flex-col justify-evenly items-start overflow-y-auto h-[220px] scrollbar scrollbar-thumb-[#292069] scrollbar-track-gray-100">
          {permissionsCheck('Pages.UserType.POC') ||
          permissionsCheck('Pages.UserType.CIO') ||
          permissionsCheck('Pages.UserType.Delegate') ? (
            <InputLabel
              withoutLabel={true}
              column={true}
              handleCheckbox={handleCheckbox}
              isCheckedCheckbox={isCheckedCheckbox}
              label={'اجابة الجهة'}
              message={'لا يوجد درجات'}
              type={'checkbox'}
              items={standardProgramContext?.degrees}
              checkboxWidth="w-full"
            />
          ) : permissionsCheck('Pages.UserType.Reviewer') ? (
            <InputLabel
              withoutLabel={true}
              column={true}
              handleCheckbox={handleCheckboxReviewer}
              isCheckedCheckbox={isCheckedCheckboReviewer}
              label={'اجابة المدقق'}
              message={'لا يوجد درجات'}
              type={'checkbox'}
              items={standardProgramContext?.degrees}
              checkboxWidth="w-full"
            />
          ) : permissionsCheck('Pages.UserType.Expert') ||
            permissionsCheck('Pages.UserType.ExpertManager') ? (
            <InputLabel
              withoutLabel={true}
              column={true}
              handleCheckbox={handleCheckboxExpert}
              isCheckedCheckbox={isCheckedCheckboxExpert}
              label={'اجابة الخبير'}
              message={'لا يوجد درجات'}
              type={'checkbox'}
              items={standardProgramContext?.degrees}
              checkboxWidth="w-full"
            />
          ) : (
            <InputLabel
              withoutLabel={true}
              disabled={true}
              column={true}
              handleCheckbox={handleCheckbox}
              isCheckedCheckbox={isCheckedCheckbox}
              label={'اجابة الجهة'}
              message={'لا يوجد درجات'}
              type={'checkbox'}
              items={standardProgramContext?.degrees}
              checkboxWidth="w-full"
            />
          )}
        </div>
      </div>
      {permissionsCheck('Pages.UserType.CIO') ||
      permissionsCheck('Pages.UserType.POC') ||
      permissionsCheck('Pages.UserType.Delegate') ? (
        <SaveBtn onClick={addAnswer} pocAnswer={pocAnswer} />
      ) : (
        <NextBtn onClick={() => setMenuElement(6)} pocAnswer={pocAnswer} />
      )}
    </>
  );
};

export default StandardAgencyAnswer;
