import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';
import { useHistory } from 'react-router-dom';
import Skeleton from 'react-skeleton-loader';
import { toast } from 'react-toastify';
import { programsIcon, search } from '../../../assets/icons/Index';
import {
  CModal,
  DropdownMultiSelect,
  FModal,
  SModal,
  Title,
} from '../../../components';
import Button from '../../../components/Button';
import environment from '../../../services/environment';
import { get, post } from '../../../services/Request';

import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import table from '../../../assets/icons/table.svg';
import Filter from '../../../components/Filter';
import NewTable from '../../../components/NewTable.jsx';
import { formatDate } from '../../../helpers/utils';
import NewHeader from '../../../layouts/header/NewHeader';
import activeBlocks from './../../../assets/icons/activeBlocks.svg';
import Card from './Card';

const CreativeCards = ({ number }) => {
  const [message, setMessage] = useState({});
  const { t } = useTranslation();
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [openModel, setOpenModel] = useState(false);
  const [open, setOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedEvaluator, setSelectedEvaluator] = useState('');
  const [agencyAnswerId, setAgencyAnswerId] = useState('');

  const [innovativeAnswers, setInnovativeAnswers] = useState([]);
  const [totalCounts, setTotalCounts] = useState(0);

  const [selectedEndDate, setSelectedEndDate] = useState('');
  const [selectedStartDate, setSelectedStartDate] = useState('');
  const [selectedEntities, setSelectedEntities] = useState([]);
  const [showMenu, setShowMenu] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [totalPages, setTotalPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  function handleMenu() {
    setShowMenu(!showMenu);
  }

  const handleChangeEntitiesFilter = (value) => {
    setSelectedEntities(value);
  };

  const arr = [
    {
      filter: true,
      withoutLabel: false,
      isSearchable: true,
      isMultiple: true,
      isClearable: true,
      label: 'الجهة الحكومية',
      value: 'الحالة',
      placeholder: 'وزارة الحج والعمرة',
      options: [
        { value: 'active', label: 'وزارة الحج والعمرة' },
        { value: 'unactive', label: 'وزارة الحج والعمرة' },
      ],
    },
    {
      filter: true,
      withoutLabel: false,
      isSearchable: true,
      isMultiple: true,
      isClearable: true,
      label: 'الحالة',
      value: 'الحالة',
      placeholder: 'الكل',
      options: [
        {
          value: 1,
          label:
            'استخدام أصول تقنية المعلومات الحكومية و الأجهزةالشخصية التقنية',
        },
        {
          value: 2,
          label:
            'هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على ال...',
        },
        { value: 3, label: 'أرشفة الوثائق والبيانات الإلكترونية' },
      ],
    },
  ];
  const cardArr1 = [
    {
      name: 'وزارة التعمير و الإسكان',
      date: '20-10-2024',
      ratio: 90,
      reviewed: 'completed',
      id: 0,
    },
    {
      name: 'وزارة التعمير و الإسكان',
      date: '20-10-2024',
      ratio: 15,
      reviewed: 'not started',
      id: 1,
    },
    {
      name: 'وزارة التعمير و الإسكان',
      date: '20-10-2024',
      ratio: 50,
      reviewed: 'in progress',
      id: 2,
    },
    {
      name: 'وزارة التعمير و الإسكان',
      date: '20-10-2024',
      ratio: 70,
      reviewed: 'completed',
      id: 3,
    },
    {
      name: 'وزارة التعمير و الإسكان',
      date: '20-10-2024',
      ratio: 10,
      reviewed: 'not started',
      id: 4,
    },
    {
      name: 'وزارة التعمير و الإسكان',
      date: '20-10-2024',
      ratio: 20,
      reviewed: 'not started',
      id: 5,
    },
    {
      name: 'وزارة التعمير و الإسكان',
      date: '20-10-2024',
      ratio: 90,
      reviewed: 'completed',
      id: 6,
    },
    {
      name: 'وزارة التعمير و الإسكان',
      date: '20-10-2024',
      ratio: 15,
      reviewed: 'not started',
      id: 7,
    },
    {
      name: 'وزارة التعمير و الإسكان',
      date: '20-10-2024',
      ratio: 50,
      reviewed: 'in progress',
      id: 8,
    },
  ];

  useEffect(() => {
    getAll(0);
  }, []);
  const handlePageClick = (event) => {
    const newOffset = event.selected;

    setCurrentPage(newOffset);
    getAll(newOffset);
  };
  const getAll = (page) => {
    setInnovativeAnswers([]);

    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    let startDate = '';
    let endDate = '';

    if (selectedStartDate != null) {
      startDate = '&FromDate=' + selectedStartDate;
    }

    if (selectedEndDate != null) {
      endDate = '&ToDate=' + selectedEndDate;
    }
    get(
      environment.getAllInnovativeFormAgencyAnswer +
        '?SkipCount=' +
        page * 6 +
        '&MaxResultCount=' +
        6,
      config,
      (res) => {
        if (res.status == 403) {
          setMessage(
            { type: 'error', message: res.data.error.message },
            setOpenConfirmModal(false, setLoading(false))
          );
        } else if (res.status == 500) {
          setMessage(
            { type: 'error', message: res.data.error.message },
            setOpenConfirmModal(false, setLoading(false))
          );
        } else if (res.status == 200) {
          setTotalPages(res.data.result.items.length / 6);
          setTotalCounts(res.data.result.items.length);
          setInnovativeAnswers(res.data.result.items);
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        }
      }
    );
  };

  const openModalAssignReviewer = (cardId) => {
    setAgencyAnswerId(cardId, setOpen(true));
  };

  useEffect(() => {
    getAllUsers();
  }, []);
  const getAllUsers = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };

    get(environment.getAllUsersExceptAgencyUsers, config, (res) => {
      if (res.status == 403) {
        setMessage(
          { type: 'error', message: res.data.error.message },
          setOpenConfirmModal(true)
        );
      } else if (res.status == 500) {
        setMessage(
          { type: 'error', message: res.data.error.message },
          setOpenConfirmModal(true)
        );
      } else if (res.status == 200) {
        let arr = [];
        res.data.result.items.forEach((user) => {
          arr.push({ label: user.fullName, value: user.id });
        });
        arr.unshift({ label: 'المسؤولين', value: '' });

        setUsers(arr);
      }
    });
  };

  const handleChangeEvaluator = (value) => {
    setSelectedEvaluator(value);
  };

  const addEvaluator = (values) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    let data = {
      innovativeFormAgencyAnswerId: agencyAnswerId,
      correctorId: selectedEvaluator.value,
    };

    post(
      environment.innovativeFormAgencyAnswerCorrectorCreate,
      data,
      config,
      (res) => {
        setSelectedEvaluator('');
        if (res.status == 500) {
          toast.error(res.data.error.message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
          setOpen(false);
        } else {
          toast.success('تم اضافة مقيم بنجاح', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
          setOpen(false);
        }
      }
    );
  };

  return (
    <div className="bg-white rounded-[30px] m-5">
      <NewHeader />
      {/* <Title title={"المؤشرات"} withoutButton={true} iconTitle={programsIcon} single={true} /> */}
      <Title
        title={'النماذج الابداعية'}
        withoutButton={true}
        iconTitle={programsIcon}
        subTitle={'النموذج'}
        subTitleSeconed={'تقييم النموذج'}
        single={true}
      />

      {/* <LoadingBar
          color='rgb(43, 41, 105)'
          progress={loading}
          onLoaderFinished={() => setLoading(0)}
          height={2}
          containerStyle={{marginTop:'8vh'}}
        /> */}
      {/* <Title title={"المؤشرات"} withoutButton={true} single={true} /> */}
      <SModal
        open={openConfirmModal}
        setOpen={() => setOpenConfirmModal(false)}
        subTitle="تم حذف المستخدم بنجاح"
      />
      <CModal
        confirmModal={() => {
          setOpenConfirmModal(true);
        }}
        open={openDelete}
        subTitle={'هل أنت متأكد من حذف المستخدم 3001 محمد القحطاني!'}
      />
      <FModal
        content={
          <div>
            {/* <InputLabel
                label={"إسم الجهة عربي "}
                placeholder={"أمانة منطقة الرياض"}
              />
              <InputLabel
                label={"اسم الجهة انجليزي"}
                placeholder={"Al Riyadh Municipality"}
              />
              <InputLabel type={"file"} id={"fileUpload"} label={"الصورة"} /> */}
            <DropdownMultiSelect
              label={'المقيمين'}
              placeholder={'اختر مقيمين'}
              options={users}
              handleChange={handleChangeEvaluator}
              value={selectedEvaluator}
              isMultiple={false}
            />
            {/* <div className='text-sm text-red-600 mt-3'>{errors.expertId && touched.expertId && errors.expertId}</div> */}
            {/* <div className="text-left w-full pt-5 flex justify-end">
                 <div>
           <Button onClick={()=>addEvaluator()} loading={false} title={t("اضافة ")} typeColor={"dark"} withIcon={false} icon={plusLight} style={{backgroundColor:'#00114E',width:160}} />
           </div>
           <div>
           <Button onClick={()=>setOpen(false)} loading={false} title={t(" الغاء")} typeColor={"dark"} withIcon={false} icon={plusLight} style={{backgroundColor:'#B40001',borderColor:'#B40001',width:100,marginRight:20}} />
           </div>
           </div> */}
          </div>
        }
        open={open}
        titleButton={'إضافة'}
        widthButton={false}
        width={160}
        confirm={addEvaluator}
        setOpen={() => setOpen(false)}
        headerTitle={'إضافة مقييم'}
      />
      <div className="px-5 bg-white rounded-2xl flex flex-col hidden">
        {/* <NewTitle icon={icon} titlesArr={["المؤشرات"]}/> */}
        {/* <h1 className="text-lg">المؤشرات</h1> */}
        <div className="bg-[#F9F9FF] rounded-xl">
          <div
            className="flex flex-row cursor-pointer justify-between p-8 items-center text-[#292069] text-[18px] transition ease-in-out duration-200"
            onClick={handleMenu}
          >
            تصفية حسب
            <FontAwesomeIcon
              className="text-[#7D8BAB]"
              icon={showMenu ? faAngleDown : faAngleUp}
            />
          </div>
          <div className="bg-white w-[100%] h-[2px] p-0"></div>
          {showMenu && (
            <div className="flex justify-between p-4 items-center">
              <div className="flex items-center gap-4">
                {arr.map((element) => (
                  <div className="w-34">
                    <Filter
                      label={element.label}
                      withoutLabel={element.withoutLabel}
                      filter={element.filter}
                      isSearchable={element.isSearchable}
                      isMultiple={element.isMultiple}
                      isClearable={element.isClearable}
                      key={element.label}
                      value={selectedEntities[arr.indexOf(element)]}
                      options={element.options}
                      handleChange={handleChangeEntitiesFilter}
                      placeholder={'-- اختر ' + element.label + ' --'}
                    />
                  </div>
                ))}
              </div>
              <div className="w-18">
                <Button
                  withIcon={false}
                  icon={search}
                  style={{
                    padding: 10,
                    borderColor: '#2B2969',
                    marginLeft: 'auto',
                    marginTop: '5px',
                    backgroundColor: '#2B2969',
                    alignSelf: 'left',
                  }}
                  styleText={{ color: 'white' }}
                  title={t('modules.main.users.users.filter.search')}
                  onClick={() => {}}
                  typeColor="light"
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="px-5 bg-white rounded-2xl">
        {/* <div className="grid bg-[#F9F9FF] rounded-2xl sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-6 xl:grid-cols-6 w-[100%] p-5"> */}
        {/* <Input
              onFocus={(e)=>e.target.type = "date"}
              onBlur={(e) => (e.target.type = "text")}
              containerStyle={{marginLeft:5,marginRight:5}}
              withLabel={true}
              style={{
                backgroundColor: "white",
                border: "1px solid #A3AED0",
                marginTop: "5%",
              }}
              
              value={selectedStartDate}
              onChange={handleChangeStartDate}
              label={t("تاريخ البدء")}
              placeholder={t("تاريخ البدء")}
            /> */}
        {/* <NewInput
              onBlur={(e) => (e.target.type = "text")}
              containerStyle={{marginLeft:5,marginRight:5}}
              withLabel={true}
              style={{
                backgroundColor: "transparent",
                border: "1px solid #A3AED0",
                marginTop: "5%",
              }}
              type="date"
              value={selectedStartDate}
              onChange={handleChangeStartDate}
              label={t("تاريخ البدء")}
              placeholder={t("تاريخ البدء")}
            /> */}
        {/* <Input
              onFocus={(e)=>e.target.type = "date"}
              onBlur={(e) => (e.target.type = "text")}
              withLabel={true}
              style={{
                backgroundColor: "white",
                border: "1px solid #A3AED0",
                marginTop: "5%",
              }}
              
              value={selectedEndDate}
              onChange={handleChangeEndDate}
              label={t("تاريخ الانتهاء")}
              placeholder={t("تاريخ الانتهاء")}
            /> */}
        {/* <NewInput
              onBlur={(e) => (e.target.type = "text")}
              withLabel={true}
              style={{
                backgroundColor: "transparent",
                border: "1px solid #A3AED0",
                marginTop: "5%",
              }}
              type="date"
              value={selectedEndDate}
              onChange={handleChangeEndDate}
              label={t("تاريخ الانتهاء")}
              placeholder={t("تاريخ الانتهاء")}
            /> */}
        {/* <DropdownMultiSelect withoutLabel={true} isSearchable={false} value={selectedEntities} handleChange={handleChangeEntitiesFilter} placeholder={t("تاريخ البدء")} label={t("تاريخ البدء")} options={entities} isMultiple={false}/>
        <DropdownMultiSelect withoutLabel={true} isSearchable={false} value={selectedEntities} handleChange={handleChangeEntitiesFilter} placeholder={t("تاريخ الانتهاء")} label={t("تاريخ الانتهاء")} options={entities} isMultiple={false}/> */}
        {/* <DropdownMultiSelect
              withoutLabel={true}
              isSearchable={false}
              value={selectedEntities}
              handleChange={handleChangeEntitiesFilter}
              placeholder={t("الحالة")}
              label={t("الحالة")}
              options={entities}
              isMultiple={false}
            /> */}
        {/* <div className="pt-5"></div>
            <div className="pt-5"></div>
            <div className="pt-5"></div> */}
        <div className="pt-3">
          {/* <Button
                withIcon={true}
                icon={search}
                style={{
                  padding: 10,
                  borderColor: "rgb(43, 41, 105)",
                  marginLeft: "auto",
                }}
                styleText={{ color: "rgb(43, 41, 105)" }}
                title={t("modules.main.users.users.filter.search")}
                onClick={() => getAllPrograms(currentPage)}
                typeColor="light"
              /> */}
          {/* <Button withIcon={false} icon={search} style={{padding:10,borderColor:'#2B2969',marginLeft:'auto',backgroundColor:'#2B2969', 'grid-column-start': '6'}} styleText={{color:'white'}} title={t("modules.main.users.users.filter.search")} onClick={() => getAllPrograms(currentPage)} typeColor="light"/>
  
            </div> */}
        </div>
        {/* </div> */}

        {/* <div className="m-5 p-5 bg-white rounded-xl"> */}
        {/* <AnswerStandards/> */}
        {loading ? (
          <div className="grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3">
            {innovativeAnswers.map((program, index) => {
              return (
                <Skeleton
                  key={index}
                  animated={true}
                  width={'100%'}
                  height={'200px'}
                  style={{ marginTop: 10 }}
                  color={'#2B296969'}
                  count={1}
                />
              );
            })}
          </div>
        ) : (
          <div>
            {innovativeAnswers.length == 0 ? (
              <div className="flex justify-center items-center">
                <span className="text-lg text-[#2B2969]">
                  {t('لا يوجد نماذج')}
                </span>
              </div>
            ) : (
              <>
                <div className="flex flex-row justify-between items-center">
                  <h3 className="my-8 mx-1">{totalCounts} عنصر بالقائمة</h3>
                  <div className="flex flex-row justify-between items-center">
                    {/* <div onClick={() => setShowTable(true)} className={"flex flex-col justify-evenly items-center ml-2 p-2 cursor-pointer rounded-lg " + (showTable ? "bg-gray-500" : "bg-[#F9F9FF]")}> */}
                    <div
                      onClick={() => setShowTable(true)}
                      className="bg-[#F9F9FF] rounded p-5 cursor-pointer"
                    >
                      <img
                        src={table}
                        alt="table view"
                        className={`${
                          showTable ? 'color-[#292069]' : 'color-[#F9F9FF]'
                        } w-[25px]`}
                      />
                    </div>
                    {/* </div>
                  <div onClick={() => setShowTable(false)} className={"p-[6px] cursor-pointer rounded-lg " + (!showTable ? "bg-gray-500" : "bg-[#F9F9FF]")}> */}
                    <div
                      onClick={() => setShowTable(false)}
                      className="bg-[#F9F9FF] rounded p-5 mr-2 ml-2 cursor-pointer"
                    >
                      <img
                        src={activeBlocks}
                        alt="blocks view"
                        className={`${
                          showTable ? 'color-[#F9F9FF]' : 'color-[#292069]'
                        } w-[25px]`}
                      />
                    </div>
                    {/* </div> */}
                  </div>
                </div>
                {!showTable ? (
                  <div className="grid mt-3 gap-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3">
                    {innovativeAnswers.map((card, index) => (
                      <Card
                        key={card.id}
                        openModal={() => openModalAssignReviewer(card.id)}
                        id={card.id}
                        onClick={() => {}}
                        name={card.agencyTitle}
                        ratio={card.formFinalDegree}
                        date={card.creationTime}
                        reviewed="completed"
                      />
                    ))}
                  </div>
                ) : (
                  <div className="bg-white p-10">
                    <NewTable
                      data={innovativeAnswers}
                      columns={[
                        {
                          name: 'أسم الجهة',
                          accessorKey: 'agencyTitle',
                          selector: (row) => row.agencyTitle,
                          sortable: true,
                          cell: (info) => (
                            <div className="text-sm text-[#292069]">
                              {info.agencyTitle}
                            </div>
                          ),
                        },
                        {
                          name: 'التاريخ',
                          accessorKey: 'creationTime',
                          selector: (row) => row.creationTime,
                          sortable: true,
                          cell: (info) => (
                            <div className="text-sm text-[#292069]">
                              {formatDate(new Date(info.creationTime))}
                            </div>
                          ),
                        },
                      ]}
                      pagination={false}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        )}
        <div className="pb-5 pt-5">
          <ReactPaginate
            nextLabel={loading == true ? null : t('general.next')}
            onPageChange={handlePageClick}
            initialPage={currentPage}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={totalPages}
            previousLabel={loading == true ? null : t('general.previous')}
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item-previous"
            previousLinkClassName="page-link-previous"
            nextClassName="page-item-next"
            nextLinkClassName="page-link-next"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </div>
      </div>
    </div>
  );
};

export default CreativeCards;
