import { useContext, useState } from 'react';
import procedures from '../../../../../../assets/icons/procedures.svg';
import { FModal, InputLabel } from '../../../../../../components';
import { isAgency } from '../../../../../../helpers/isPermission';
import { useAnswerContext } from '../answerContext';

import SaveBtn from '../components/buttons/saveBtn';
import { post } from '../../../../../../services/Request';
import environment from '../../../../../../services/environment';
import { StageContext } from '../../../../../../contexts/stageContext';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const StandardAnswerNotes = ({
  createAnswer,
  children,
  isExpertAllowedToSendReAnswerRequest,
  createAnswerAsDraft = () => {},
  disableSave = false,
}) => {
  const { createAnswerLoading, createDraftAnswerLoading } = useAnswerContext();
  const { currentStage } = useContext(StageContext);
  const { standardId, entityId } = useParams();
  const { t } = useTranslation();

  const [openRequestChangeModal, setOpenRequestChangeModal] = useState(false);
  const [editRequestReason, setEditRequestReason] = useState('');

  const onRequestChange = () => {
    setOpenRequestChangeModal(true);
  };

  const sendEditRequest = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    post(
      environment.SendReAnswerRequest,
      {
        standardId: Number(standardId),
        stageId: currentStage?.id,
        agencyIds: [Number(entityId)],
        notes: editRequestReason,
      },
      config,
      (res) => {
        if (res.status == 200) {
          toast.success(t('تم اٍرسال الطلب بنجاح'), {
            theme: 'colored',
          });
          setOpenRequestChangeModal(false);
        } else if (res.status == 500) {
          toast.error(res.data?.error?.message, {
            theme: 'colored',
          });
        }
      }
    );
  };

  return (
    <>
      <div className="min-h-[440px]  p-3">
        <div className="w-[100%] flex flex-row justify-between items-center">
          <div className="flex flex-row items-center">
            <img
              className="bg-transparent ml-2"
              src={procedures}
              alt="procedures"
            />
            <h1 className="text-[#292069] font-black text-[20px]">
              {' '}
              ملاحظات التدقيق
            </h1>
          </div>
        </div>

        <div className="w-[100%] mt-7 pr-[88px] flex flex-col items-start overflow-y-auto scrollbar scrollbar-thumb-[#292069] scrollbar-track-gray-100 scrollGroup">
          {children}
        </div>
      </div>
      {!isAgency && (
        <>
          <SaveBtn
            onClick={createAnswer}
            loading={createAnswerLoading}
            saveDraftLoading={createDraftAnswerLoading}
            isExpertAllowedToSendReAnswerRequest={
              isExpertAllowedToSendReAnswerRequest
            }
            onSaveDraftClick={createAnswerAsDraft}
            onEditRequestButtonClick={onRequestChange}
            disableSave={disableSave}
          />
          {openRequestChangeModal && (
            <FModal
              open={openRequestChangeModal}
              setOpen={setOpenRequestChangeModal}
              width={160}
              headerTitle={'طلب تعديل'}
              submitLabel={'إرسال الطلب'}
              action={sendEditRequest}
              content={
                <div>
                  <InputLabel
                    label={'سبب طلب التعديل'}
                    placeholder={'سبب التعديل'}
                    value={editRequestReason}
                    onChange={(e) => setEditRequestReason(e.target.value)}
                    isRequired={false}
                  />
                </div>
              }
            />
          )}
        </>
      )}
    </>
  );
};

export default StandardAnswerNotes;
