import { useQuery } from '@tanstack/react-query';
import { axiosGetRequest } from '../../services/Request';
import environment from '../../services/environment';

export default function usePermissionCheck() {
  const roleId = localStorage.getItem('roleId');
  const fiveMinutes = 5 * 60 * 1000;

  const { data: permissionData } = useQuery({
    queryKey: ['getRoleForEdit', roleId],
    queryFn: () =>
      axiosGetRequest(environment.getRoleForEdit + '?Id=' + roleId),
    enabled: !!roleId,
    select: (data) => data?.result?.permissions?.map((item) => item.name) || [],
    staleTime: fiveMinutes,
  });

  return (permission: string) => !!permissionData?.includes(permission);
}
