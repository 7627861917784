import ChunkedUploady from '@rpldy/chunked-uploady';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import UploadFiles from './ui/uploadFiles';
import retryEnhancer from '@rpldy/retry-hooks';

const UploadFilesNew = ({
  label,
  agencyId,
  from,
  proofId,
  standardId,
  document,
  relatedRequirementId,
  standardProofId,
}) => {
  return (
    <DndProvider backend={HTML5Backend}>
      <ChunkedUploady
        chunkSize={1024 * 1024 * 10000}
        multiple
        method="POST"
        enhancer={retryEnhancer}
      >
        <UploadFiles
          label={label}
          agencyId={agencyId}
          from={from}
          proofId={proofId}
          standardId={standardId}
          standardProofId={standardProofId}
          document={document}
          relatedRequirementId={relatedRequirementId}
        />
      </ChunkedUploady>
    </DndProvider>
  );
};

export default UploadFilesNew;
