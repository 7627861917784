import React, { useContext, useEffect } from 'react';
import { StandardProgramContext } from '../../../../../../contexts/StandardProgramContext';
import AnswerDegreeItem from './answerDegreeItem';
import { axiosGetRequest } from '../../../../../../services/Request';
import environment from '../../../../../../services/environment';
import { useParams } from 'react-router';
import { permissionsCheck } from '../../../../../../helpers/utils';

const StandardAnswerDegrees = ({
  role,
  roles,
  agencySuggestedDegree,
  reviewerSuggestedDegree,
  expertSuggestedDegree,
  selectedAgencyDegree,
  selectedReviewerDegree,
  selectedExpertDegree,
  setSelectedReviewerDegreePercentage,
  setSelectedExpertDegreePercentage,
  setSelectedDegreePercentage,

  setReviewerSuggestedDegree,
  setExpertSuggestedDegree,
  setAgencySuggestedDegree,
}) => {
  const { standardId } = useParams();
  const standardProgramContext = useContext(StandardProgramContext);

  const getReviewerSuggestedDegree = (res) => {
    setReviewerSuggestedDegree(res);
    // setReviewerFixedDegree(res?.fixedDegree)
  };
  const getExpertSuggestedDegree = (res) => {
    setExpertSuggestedDegree(res);
    // setExpertFixedDegree(res?.fixedDegree)
  };
  const getAgencySuggestedDegree = (res) => {
    setAgencySuggestedDegree(res);
    // setAgencyFixedDegree(res?.fixedDegree)
  };

  const getDegree = async () => {
    // * Get commitment Level
    const res = await axiosGetRequest(
      environment.getSuggestedDegree +
        '?StandardId=' +
        standardId +
        '&CheckedRequirementsIds=0&DonotAppliedRequirementsIds=0'
    );

    if (res?.success) {
      if (permissionsCheck('Pages.UserType.Reviewer')) {
        getReviewerSuggestedDegree(res?.result);
        //   getReviewerDegree(res?.result)
      } else if (
        permissionsCheck('Pages.UserType.Expert') ||
        permissionsCheck('Pages.UserType.ExpertManager')
      ) {
        getExpertSuggestedDegree(res?.result);
        //   getExpertDegree(res?.result)
      } else {
        getAgencySuggestedDegree(res?.result);
        //   getAgencyDegree(res?.result)
      }
    }
    const degree = await axiosGetRequest(
      environment.getDegree +
        '?StandardId=' +
        standardId +
        '&CheckedRequirementsIds=0&DonotAppliedRequirementsIds=0'
    );
    if (degree?.success) {
      if (permissionsCheck('Pages.UserType.Reviewer')) {
        //   getReviewerDegree(res?.result)
        setSelectedReviewerDegreePercentage(degree?.result);
      } else if (
        permissionsCheck('Pages.UserType.Expert') ||
        permissionsCheck('Pages.UserType.ExpertManager')
      ) {
        //   getExpertDegree(res?.result)
        setSelectedExpertDegreePercentage(degree?.result);
      } else {
        //   getAgencyDegree(res?.result)
        setSelectedDegreePercentage(degree?.result);
      }
    }
  };

  useEffect(() => {
    if (standardProgramContext?.standard?.agencySuggestedDegree == null) {
      getDegree();
    }
  }, []);

  return (
    <div className="bg-[#F9F9FF] p-3 mr-3 rounded-[12px] mb-3">
      <div className="bg-white p-3 px-5 grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3 rounded-[9px]">
        {permissionsCheck('Pages.UserType.Expert') ||
        permissionsCheck('Pages.UserType.ExpertManager') ||
        permissionsCheck('Pages.UserType.Reviewer') ? (
          <>
            <AnswerDegreeItem
              title="الدرجة المقترحة للجهة"
              circleColor={
                agencySuggestedDegree == null
                  ? standardProgramContext?.standard?.agencySuggestedDegree
                      ?.color
                  : agencySuggestedDegree?.color
              }
              degree={
                agencySuggestedDegree == null
                  ? standardProgramContext?.standard?.agencySuggestedDegree ==
                    null
                    ? 'لايوجد'
                    : standardProgramContext?.standard?.agencySuggestedDegree
                        ?.title
                  : agencySuggestedDegree?.title
              }
            />
          </>
        ) : null}

        {!roles.includes(localStorage.getItem('userType')) ? null : (
          <>
            {(permissionsCheck('Pages.UserType.Expert') ||
              permissionsCheck('Pages.UserType.ExpertManager') ||
              permissionsCheck('Pages.UserType.Admin')) && (
              <AnswerDegreeItem
                title="الدرجة المقترحة للمدقق"
                circleColor={
                  reviewerSuggestedDegree == null
                    ? standardProgramContext?.standard?.reviewerSuggestedDegree
                        ?.color
                    : reviewerSuggestedDegree?.color
                }
                degree={
                  reviewerSuggestedDegree == null
                    ? standardProgramContext?.standard?.reviewerSuggestedDegree
                        ?.title
                    : reviewerSuggestedDegree?.title
                }
                range={
                  reviewerSuggestedDegree == null
                    ? standardProgramContext?.standard
                        ?.reviewerSuggestedDegree == null
                      ? 'لا يوجد'
                      : standardProgramContext?.standard
                          ?.reviewerSuggestedDegree?.rangeFrom +
                        ' %' +
                        ' - ' +
                        standardProgramContext?.standard
                          ?.reviewerSuggestedDegree?.rangeTo +
                        ' %'
                    : reviewerSuggestedDegree?.rangeFrom +
                      ' %' +
                      ' - ' +
                      reviewerSuggestedDegree?.rangeTo +
                      ' %'
                }
              />
            )}

            {(permissionsCheck('Pages.UserType.ExpertManager') ||
              permissionsCheck('Pages.UserType.Admin')) && (
              <AnswerDegreeItem
                title="الدرجة المقترحة للخبير"
                circleColor={
                  expertSuggestedDegree == null
                    ? standardProgramContext?.standard?.expertSuggestedDegree
                        ?.color
                    : expertSuggestedDegree?.color
                }
                degree={
                  expertSuggestedDegree == null
                    ? standardProgramContext?.standard?.expertSuggestedDegree
                        ?.title
                    : expertSuggestedDegree?.title
                }
                range={
                  expertSuggestedDegree == null
                    ? standardProgramContext?.standard?.expertSuggestedDegree ==
                      null
                      ? 'لا يوجد'
                      : standardProgramContext?.standard?.expertSuggestedDegree
                          ?.rangeFrom +
                        ' %' +
                        ' - ' +
                        standardProgramContext?.standard?.expertSuggestedDegree
                          ?.rangeTo +
                        ' %'
                    : expertSuggestedDegree?.rangeFrom +
                      ' %' +
                      ' - ' +
                      expertSuggestedDegree?.rangeTo +
                      ' %'
                }
              />
            )}
          </>
        )}
        {!roles.includes(localStorage.getItem('userType')) ? null : (
          <AnswerDegreeItem
            title="اجابة الجهة للمرحلة الأولى"
            circleColor={
              selectedAgencyDegree == null
                ? standardProgramContext?.standard?.agencyDegree?.color
                : selectedAgencyDegree?.color
            }
            degree={
              selectedAgencyDegree == null
                ? standardProgramContext?.standard?.agencyDegree == null
                  ? 'لايوجد'
                  : standardProgramContext?.standard?.agencyDegree?.title
                : selectedAgencyDegree.title
            }
          />
        )}
        {!roles.includes(localStorage.getItem('userType'))
          ? null
          : selectedReviewerDegree?.title && (
              <AnswerDegreeItem
                title="درجة المدقق"
                circleColor={
                  selectedReviewerDegree == null
                    ? standardProgramContext?.standard?.reviewerDegree?.color
                    : selectedReviewerDegree?.color
                }
                degree={
                  selectedReviewerDegree == null
                    ? standardProgramContext?.standard?.reviewerDegree?.title
                    : selectedReviewerDegree?.title
                }
                range={
                  selectedReviewerDegree == null
                    ? standardProgramContext?.standard?.reviewerDegree == null
                      ? 'لا يوجد'
                      : standardProgramContext?.standard?.reviewerDegree
                          ?.rangeFrom +
                        ' %' +
                        ' - ' +
                        standardProgramContext?.standard?.reviewerDegree
                          ?.rangeTo +
                        ' %'
                    : selectedReviewerDegree?.rangeFrom +
                      ' %' +
                      ' - ' +
                      selectedReviewerDegree?.rangeTo +
                      ' %'
                }
              />
            )}

        {!roles.includes(localStorage.getItem('userType'))
          ? null
          : selectedExpertDegree?.title && (
              <AnswerDegreeItem
                title="درجة الخبير"
                circleColor={
                  selectedExpertDegree == null
                    ? standardProgramContext?.standard?.expertDegree?.color
                    : selectedExpertDegree?.color
                }
                degree={
                  selectedExpertDegree == null
                    ? standardProgramContext?.standard?.expertDegree?.title
                    : selectedExpertDegree?.title
                }
                range={
                  selectedExpertDegree == null
                    ? standardProgramContext?.standard?.expertDegree == null
                      ? 'لا يوجد'
                      : standardProgramContext?.standard?.expertDegree
                          ?.rangeFrom +
                        ' %' +
                        ' - ' +
                        standardProgramContext?.standard?.expertDegree
                          ?.rangeTo +
                        ' %'
                    : selectedExpertDegree?.rangeFrom +
                      ' %' +
                      ' - ' +
                      selectedExpertDegree?.rangeTo +
                      ' %'
                }
              />
            )}
      </div>
    </div>
  );
};

export default StandardAnswerDegrees;
