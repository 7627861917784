import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AppEditor = ({
  value,
  onChange,
  name,
  label,
  isRequired = false,
  toolbar,
}) => {
  return (
    <>
      {label && (
        <label
          className="label_input text-lg block text-[#292069] mb-2"
          htmlFor={name}
        >
          {label}
          {isRequired && (
            <span className="mr-1">
              <FontAwesomeIcon className="w-2 text-red-700" icon={faAsterisk} />
            </span>
          )}
        </label>
      )}

      <div className="pl-5 pr-5 w-[100%]">
        <CKEditor
          editor={ClassicEditor}
          data={value}
          config={{
            language: 'ar',

            simpleUpload: {
              uploadUrl: `${process.env.REACT_APP_URL}/api/ckeditor/image-upload`,
              withCredentials: true,
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
              },
            },
            heading: {
              options: [
                {
                  model: 'paragraph',
                  title: 'Paragraph',
                  class: 'ck-heading_paragraph',
                },
                {
                  model: 'heading1',
                  view: 'h1',
                  title: 'Heading 1',
                }, // Corrected to 'h1'
                {
                  model: 'heading2',
                  view: 'h2',
                  title: 'Heading 2',
                }, // Corrected to 'h2'
                {
                  model: 'heading3',
                  view: 'h3',
                  title: 'Heading 3',
                }, // Corrected to 'h3'
              ],
            },

            toolbar: toolbar ?? [
              'undo',
              'redo',
              '|',
              'heading',
              '|',
              'bold',
              'italic',
              'Underline',
              '|',
              'link',
              'insertTable',
              'blockQuote',
              '|',
              'bulletedList',
              'numberedList',
              '|',
              // // 'outdent', 'indent',
            ],
          }}
          onChange={(event, editor) => {
            onChange?.(editor.getData(), editor, event);
          }}
        />
      </div>
    </>
  );
};

export default AppEditor;
