import React from 'react';
import HeaderWithBreadcrumbLayout from '../../../../../layouts/headerWithBreadcrumbLayout';
import AddAboutStandard from './addAboutStandard';
import AddStandardRequirements from './standardRequirements';
import { AddStandardProvider } from './addStandardContext';

import SubmitAddStandard from './submitAddStandard';
import StandardProofFiles from './standardProofFiles';
import StandardUploadOldFiles from './uploadOldFiles';
import StandardOrders from './standardOrders';
import { useParams } from 'react-router';
import { programsIcon } from '../../../../../assets/icons/Index';

const AddStandard = () => {
  const { surveyId, perspectiveId, interlocutorId, indicatorId } = useParams();
  const disableAddStandard = false;

  const addStanderdsBreadcumbs = [
    {
      icon: programsIcon,
      title: 'استبيان التحول الرقمي',
      url: `/indicators/${indicatorId || 1}`,
    },
    {
      title: 'المناظير',
      url: `/indicators/${indicatorId}/programs/${surveyId}/perspectives`,
    },
    {
      title: 'المحاور',
      url: `/indicators/${indicatorId}/programs/${surveyId}/perspectives/${perspectiveId}/interlocutor`,
    },
    {
      title: 'المعايير',
      url: `/indicators/${indicatorId}/programs/${surveyId}/perspectives/${perspectiveId}/interlocutor/${interlocutorId}/standards`,
    },
    {
      title: 'اضافة معيار',
    },
  ];
  return (
    <AddStandardProvider>
      <HeaderWithBreadcrumbLayout breadcrumbArr={addStanderdsBreadcumbs}>
        <div className="bg-gray_light p-5 mb-10 rounded-lg pb-36 relative">
          <AddAboutStandard from={'add'} disabled={disableAddStandard} />
          <StandardUploadOldFiles disabled={disableAddStandard} />
          <AddStandardRequirements disabled={disableAddStandard} />
          <StandardProofFiles disabled={disableAddStandard} />
          <StandardOrders disabled={disableAddStandard} />

          <SubmitAddStandard disabled={disableAddStandard} />
        </div>{' '}
      </HeaderWithBreadcrumbLayout>
    </AddStandardProvider>
  );
};

export default AddStandard;
