import {
  convertHtmlToString,
  extractTextFromJSX,
} from '../../../../../helpers/utils';
import { showRequirement } from '../../standerds/standardAnswer/answers/utils';

export default function getExertsNotes(row) {
  const templateContent = convertHtmlToString(
    row?.noteForAgency?.noteTemplate?.templateContent,
    ''
  );
  const requirementAnswers = extractTextFromJSX(
    <>
      {row?.surveyExpertAnswer?.expertRequirementAnswers?.map((requirement) => (
        <li>
          {showRequirement(requirement)
            ? requirement.standardRequirementTitle
            : ''}
          <ul>
            {requirement?.expertSubRequirementAnswers?.map((subRequirement) => (
              <>
                {(subRequirement.decision == 0 || !subRequirement.decision) &&
                subRequirement?.donotApply == false ? (
                  <li className="mx-10">
                    {subRequirement.subStandardRequirementTitle}
                  </li>
                ) : (
                  ''
                )}
              </>
            ))}
          </ul>
        </li>
      ))}
    </>
  );

  const content = row?.noteForAgency?.content;

  return `${templateContent ?? ''}${requirementAnswers ?? ''}${content ?? ''}`;
}
