import { programsIcon } from '../../../../assets/icons/Index';
import {
  isAgency,
  isExpert,
  isExpertManager,
  isPOCOfficer,
  isReviewer,
} from '../../../../helpers/isPermission';
import { permissionsCheck } from '../../../../helpers/utils';

export const standerdsBreadcumbs = [
  {
    icon: programsIcon,
    title: 'استبيان التحول الرقمى',
  },
  {
    title: 'المعايير',
  },
];
export const addStanderdsBreadcumbs = [
  {
    icon: programsIcon,
    title: 'استبيان التحول الرقمى',
  },
  {
    title: 'المعايير',
  },
  {
    title: 'إضافة',
  },
];
export const editStanderdsBreadcumbs = [
  {
    icon: programsIcon,
    title: 'استبيان التحول الرقمى',
  },
  {
    title: 'المعايير',
  },
  {
    title: 'تعديل',
  },
];
export const standerdEntitesBreadcumbs = (
  indicatorId,
  surveyId,
  perspectiveId,
  interlocutorId,
  standardTitle
) => {
  return [
    {
      icon: programsIcon,
      title: 'قياس ٢٠٢٤',
      url: permissionsCheck('Pages.UserType.Reviewer')
        ? null
        : '/indicators/' + indicatorId,
    },

    {
      title: standardTitle,
      url:
        isReviewer || isExpert || isAgency || isExpertManager || isPOCOfficer
          ? `/indicators/${indicatorId}/programs/${surveyId}/standards`
          : `/indicators/${indicatorId}/programs/${surveyId}/perspectives/${perspectiveId}/interlocutor/${interlocutorId}/standards`,
    },
    {
      title: 'الجهات الحكومية',
    },
  ];
};
