import React, { useContext, useRef, useState } from 'react';
import { axiosPutRequest } from '../../../../../../services/Request';
import environment from '../../../../../../services/environment';
import AppModal from '../../../../../../components/Models/appModal';
import { AppInput } from '../../../../../../components';
import { IndicatorsContext } from '../../../../../../contexts/indicatorsContext';
import { ErrorMessage, Formik } from 'formik';
import AppTextarea from '../../../../../../components/form/appTextarea';

const UpdateSurveyModal = ({ openModal, setOpenModal, values }) => {
  const { updateEditedIndicatorSurveys } = useContext(IndicatorsContext);
  const [loading, setLoading] = useState(false);
  const [inputs, setInputs] = useState(
    // values || {
    //   id: 1,
    //   title: "",
    //   description: "",
    // }
    values
  );

  const handleInputChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    setLoading(true);
    const res = await axiosPutRequest(environment.editSurvey, inputs);
    setLoading(false);
    if (res?.success) {
      updateEditedIndicatorSurveys(inputs.id, res?.result);
      setOpenModal(false);
    }
  };

  // --------------------------------------- //
  const formRef = useRef(null);

  const handleFormik = () => {
    if (formRef.current) {
      formRef.current?.handleSubmit();
    }
  };

  const initialValues = {
    title: '',
    description: '',
  };

  const validate = (values) => {
    const errors = {};
    if (!inputs.title) {
      errors.title = 'هذا الحقل مطلوب';
    }

    return errors;
  };
  // --------------------------------------- //

  return (
    <AppModal
      headerTitle="تعديل الاستبيان"
      open={openModal}
      setOpen={setOpenModal}
      handleSubmit={handleFormik}
      type={'submit'}
      loading={loading}
      submitLabel="تحديث"
    >
      <Formik
        initialValues={initialValues}
        validate={validate}
        innerRef={formRef}
        onSubmit={handleSubmit}
      >
        {({ handleChange, values }) => (
          <div>
            <div>
              <AppInput
                type={'text'}
                label={'إسم الاستبيان'}
                name="title"
                variant="gray"
                value={inputs.title}
                onChange={handleInputChange}
              />
              <ErrorMessage
                className="text-red-700"
                name="title"
                component="div"
              />
            </div>
            <div className="mt-3">
              <AppTextarea
                label={'الوصف'}
                name="description"
                value={inputs.description}
                onChange={handleInputChange}
              />
            </div>
          </div>
        )}
      </Formik>
    </AppModal>
  );
};

export default UpdateSurveyModal;
