import { createContext, useContext, useState } from 'react';

const AuditorExpertContext = createContext();

function AuditorExpertContextProvider({ children }) {
  const [dashboardStats, setDashboardStats] = useState({});
  const [loading, setLoading] = useState(false);

  return (
    <AuditorExpertContext.Provider
      value={{
        dashboardStats,
        setDashboardStats,
        setLoading,
        loading,
      }}
    >
      {children}
    </AuditorExpertContext.Provider>
  );
}

const useAuditorExpertContext = () => {
  const context = useContext(AuditorExpertContext);
  if (!context)
    throw new Error(
      'Calling useAuditorExpertContext Outside AuditorExpertContextProvider'
    );
  return context;
};

export { useAuditorExpertContext, AuditorExpertContextProvider };
