import { Formik } from 'formik';
import { useContext, useEffect, useRef, useState } from 'react';
import environment from '../../../../services/environment';
import { axiosGetRequest, get, post } from '../../../../services/Request';
import ListUsers from '../../home/Users';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { avatar, programsIcon } from '../../../../assets/icons/Index';
import {
  AppInput,
  DropdownMultiSelect,
  FModal,
  InputLabel,
  SModal,
} from '../../../../components';
import CustomTable from '../../../../components/CustomTable';
import DrawerFilter from '../../../../components/DrawerFilter';
import NewDropdownMultiSelect from '../../../../components/NewDropDownMultiSelect';
import { UsersContext } from '../../../../contexts/UsersContext';
import useExcelExport from '../../../../helpers/customHooks/useExcelExport';
import { permissionsCheck, TOAST_OPTIONS } from '../../../../helpers/utils';
import HeaderWithBreadcrumbLayout from '../../../../layouts/headerWithBreadcrumbLayout';

const UsersList = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const usersContext = useContext(UsersContext);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const [usersForExcel, setUsersForExcel] = useState([]);

  const [totalPages, setTotalPages] = useState(null);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [searchFilter, setSearchFilter] = useState('');

  const [statuses] = useState([
    { id: 1, label: 'الحالة', labelEn: 'Status', value: null },
    { id: 2, label: 'نشط', labelEn: 'Active', value: true },
    { id: 3, label: 'غير نشط', labelEn: 'Un active', value: false },
  ]);
  const [totalRows, setTotalRows] = useState(0);

  const [users, setUsers] = useState([]);
  const [message] = useState({});

  const [role, setRole] = useState([]);
  const [roleStatic, setRoleStatic] = useState(null);
  const [entity, setEntity] = useState(null);

  const [rolesStatic, setRolesStatic] = useState([]);
  const [perPage, setPerPage] = useState({ label: '10', value: 10 });
  const [experts, setExperts] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const [selectedExperts, setSelectedExperts] = useState([]);
  const [entitiesForFilter, setEntitiesForFilter] = useState([]);

  const formUserRef = useRef(null);

  const getEntitiesForFilter = async () => {
    const res = await axiosGetRequest(
      environment.getAllAgencyWithoutPagination
    );
    setEntitiesForFilter(res);
  };

  const handleSubmitAddUser = () => {
    if (formUserRef.current) {
      formUserRef.current?.handleSubmit();
    }
  };
  useEffect(() => {
    getEntitiesForFilter();
    getAllUsersExcel(0, 10);
    getAllUsers(0, 10);
    getAllRoles();
    getAllExperts();
  }, [searchText]);

  const entitiesOptions =
    entitiesForFilter?.result &&
    entitiesForFilter?.result?.map((item) => {
      return { label: item?.nameInArabic?.toString() || '', value: item.id };
    });

  const handleChangeExperts = (value) => {
    setSelectedExperts(value);
  };

  const handleChangeRoleStatic = (value) => {
    setRoleStatic(value);
  };

  const handleChangeRole = (value) => {
    usersContext.setSelectedRole(value);
  };

  const handleChangeStatus = (value) => {
    usersContext.setSelectedStatus(value);
  };

  const handleChangeEntities = (value) => {
    setEntity(value);
  };

  const handleChangeEntitiesFilter = (value) => {
    usersContext.setSelectedEntity(value);
  };

  const getAllUsersExcel = (page, perPage) => {
    setLoading(true);
    let arr = [];
    if (usersContext.selectedRole != null) {
      usersContext.selectedRole.forEach((item, index) => {
        arr.push(item.id);
      });
    } else {
      arr = [];
    }

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    let status = '';
    let entity = '';
    let search = '';
    let searchF = '';
    let roles = [];
    if (usersContext.selectedStatus.value != null) {
      status = '&IsActive=' + usersContext.selectedStatus.value;
    }
    if (usersContext.selectedEntity != null) {
      entity = '&AgencyId=' + usersContext.selectedEntity.value;
    }
    if (searchText != '') {
      search = '&Keyword=' + searchText;
    }
    if (searchFilter != '') {
      search = '&Keyword=' + searchFilter;
    }

    if (arr.length != 0) {
      arr.forEach((item, index) => {
        roles = '&Roles=' + item;
      });
    }

    get(
      environment.getAllUsers +
        '?SkipCount=' +
        page * perPage +
        '&MaxResultCount=' +
        1000000 +
        status +
        entity +
        search +
        searchF +
        roles,
      config,
      (res) => {
        if (res.status == 403) {
          toast.error(res.data.error.message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        } else if (res.status == 500) {
          toast.error(res.data.error.message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        } else if (res.status == 200) {
          setUsersForExcel(res.data.result.items, getAllRoles());
        }
      }
    );
  };
  const getAllUsers = (page, perPage) => {
    setLoading(true);
    let arr = [];
    if (usersContext.selectedRole != null) {
      usersContext.selectedRole.forEach((item, index) => {
        arr.push(item.id);
      });
    } else {
      arr = [];
    }

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    let status = '';
    let entity = '';
    let search = '';
    let searchF = '';
    let roles = [];
    if (usersContext.selectedStatus.value != null) {
      status = '&IsActive=' + usersContext.selectedStatus.value;
    }
    if (usersContext.selectedEntity != null) {
      entity = '&AgencyId=' + usersContext.selectedEntity.value;
    }
    if (searchText != '') {
      search = '&Keyword=' + searchText;
    }
    if (searchFilter != '') {
      searchF = '&Keyword=' + searchFilter;
    }

    if (arr.length != 0) {
      arr.forEach((item, index) => {
        roles = '&Roles=' + item;
      });
    }

    get(
      environment.getAllUsers +
        '?SkipCount=' +
        page * perPage +
        '&MaxResultCount=' +
        perPage +
        status +
        entity +
        search +
        searchF +
        roles,
      config,
      (res) => {
        if (res.status == 403) {
          toast.error(res.data.error.message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        } else if (res.status == 500) {
          toast.error(res.data.error.message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        } else if (res.status == 200) {
          getAllUsersExcel(page, perPage);
          setTotalPages(res.data.result.totalCount / perPage);
          setTotalRows(res.data.result.totalCount);
          setUsers(res.data.result.items);
          setFilteredData(res?.data?.result?.items, setLoading(false));
        }
      }
    );
  };
  const getAllRoles = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    get(environment.getAllRoles + '?isStatic=' + false, config, (res) => {
      if (res.status == 403) {
        toast.error(res.data.error.message, TOAST_OPTIONS);
      } else if (res.status == 500) {
        toast.error(res.data.error.message, TOAST_OPTIONS);
      } else if (res.status == 200) {
        let arr = [];
        res.data.result.items.forEach((role) => {
          arr.push({ id: role.id, label: role.name, value: role.name });
        });
        getAllRolesStatic();
      }
    });
  };

  const getAllRolesStatic = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    get(
      environment.getAllRoles + '?isStatic=' + true + '&MaxResultCount=' + 1000,
      config,
      (res) => {
        if (res?.status == 403) {
          toast.error(res.data?.error?.message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        } else if (res?.status == 500) {
          toast.error(res.data?.error?.message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        } else if (res?.status == 200) {
          let arr = [];
          res.data.result.items.forEach((role) => {
            arr.push({ id: role.id, label: role.name, value: role.name });
          });
          setRolesStatic(arr, getAllAgency());
        }
      }
    );
  };

  const passwordValidation = (password) => {
    var re = {
      capital: /(?=.*[A-Z])/,
      length: /(?=.{7,40}$)/,
      specialChar: /[ -\/:-@\[-\`{-~]/,
      digit: /(?=.*[0-9])/,
    };
    return (
      re.capital.test(password) &&
      re.length.test(password) &&
      re.specialChar.test(password) &&
      re.digit.test(password)
    );
  };

  const getAllAgency = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    get(environment.getAllAgencyWithoutPagination, config, (res) => {
      if (res?.status == 403) {
        toast.error(res.data?.error?.message, TOAST_OPTIONS);
      } else if (res?.status == 500) {
        toast.error(res.data?.error?.message, TOAST_OPTIONS);
      } else if (res?.status == 200) {
        if (res.data.result && res.data.result.length > 0) {
          var arr = [];
          for (var i = 0; i < res.data.result.length; i++) {
            arr.push({
              value: res.data.result[i].id,
              label: res.data.result[i].name.toString(),
              isSupported: res.data.result[i].isSupported,
              isSelected: false,
            });
          }

          arr.unshift({ label: 'جهة حكومية', value: '', id: '' });
        }
      }
    });
  };

  const getAllExperts = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };

    get(environment.getAllExpertLookUp, config, (res) => {
      if (res.status == 403) {
        toast.error(res.data.error.message, TOAST_OPTIONS);
      } else if (res.status == 500) {
        toast.error(res.data.error.message, TOAST_OPTIONS);
      } else if (res.status == 200) {
        let arr = [];
        res.data.result.items.forEach((user) => {
          arr.push({ id: user.id, label: user.fullName, value: user.name });
        });
        setExperts(arr, setLoading(false));
      }
    });
  };

  const addUser = (values, profilePictureId) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    let roleArr = [];
    role.forEach((role, index) => {
      roleArr.push(role.value);
    });
    roleArr.push(roleStatic.value);
    let newExperts = [];
    if (roleStatic.value == 'Reviewer') {
      selectedExperts.forEach((expert) => {
        newExperts.push(expert.id);
      });
    }

    let data = {
      userName: values.userName,
      name: values.name,
      surname: values.surname,
      emailAddress: values.emailAddress,
      isActive: true,
      roleNames: roleArr,
      password: values.password,
      agencyId: entity != null ? entity.value : '',

      mobile: values.mobile,

      experstIds: newExperts,
    };

    post(environment.addUser, data, config, (res) => {
      setRole([]);
      setRoleStatic('');
      setEntity('');
      if (res.status == 200) {
        toast.success(t('تم اضافة المستخدم بنجاح'), TOAST_OPTIONS);
        setOpen(false);
        setLoading(false, getAllUsers(currentPage, perPage.value));
      } else if (res.status == 400) {
        toast.error(t('اسم المستخدم يحب الا يحتوي علي رموز'), TOAST_OPTIONS);
        setLoading(false, getAllUsers(currentPage, perPage.value));
      } else if (res.status == 500) {
        toast.error(res.data.error.message, TOAST_OPTIONS);
        setLoading(false, getAllUsers(currentPage, perPage.value));
      } else {
        toast.error(res.data.error.message, TOAST_OPTIONS);
        setLoading(false, getAllUsers(currentPage, perPage.value));
      }
    });
  };

  const handlePageClick = (event) => {
    const newOffset = event.selected;
    setCurrentPage(newOffset);
    getAllUsers(newOffset, perPage.value);
  };

  const handlePerRowsChange = (value) => {
    setPerPage(value, getAllUsers(currentPage, value.value));
  };

  const downloadExcel = useExcelExport();

  const clearSearch = () => {
    usersContext.setSelectedRole([]);
    usersContext.setSelectedEntity(null);
    usersContext.setSelectedStatus({
      id: 1,
      label: 'الحالة',
      labelEn: 'Status',
      value: null,
    });
    setPerPage({ label: '5', value: 5 });
    setSearchFilter('');
  };

  const usersDetailsBreadcumbs = [
    {
      icon: programsIcon,
      title: 'مستخدمو النظام',
      url: '/users',
    },
    {
      title: 'المستخدمين',
      url: '/users',
    },
  ];

  const handleFilterData = (value) => {
    setSearchText(value, getAllUsers(currentPage, perPage.value));
  };

  return (
    <HeaderWithBreadcrumbLayout
      breadcrumbArr={usersDetailsBreadcumbs}
      btnLabel={'اٍضافة مستخدم'}
      handleBtnClick={() => setOpen(true)}
      hideBtn={
        permissionsCheck('Pages.Administration.Users.Create') ? false : true
      }
      handleBtnSeconedClick={() => history.push('/users/identities')}
      btnLabelSeconed={'اضافة هوية'}
    >
      <SModal
        type={message.type}
        open={openConfirmModal}
        setOpen={() => setOpenConfirmModal(false)}
        subTitle={message.message}
      />
      <FModal
        content={
          <div>
            <Formik
              innerRef={formUserRef}
              initialValues={{
                userName: '',
                name: '',
                surname: '',
                emailAddress: '',
                isActive: false,
                roleNames: [],
                password: '',
                agencyId: '',
                mobile: '',
                profilePictureId: '',
                roleStatic: '',
                experstIds: [],
              }}
              validate={(values) => {
                const errors = {};
                if (!values.userName) {
                  errors.userName = t(
                    'modules.main.users.users.messages.userName'
                  );
                }
                if (!values.name) {
                  errors.name = t('modules.main.users.users.messages.name');
                }
                var inValid = /^\S+$/;
                var inValidEnglish = /^[A-Za-z]+$/;

                if (
                  inValid.test(values.userName) == false ||
                  !inValidEnglish.test(values.userName)
                ) {
                  errors.userName = t(
                    'اسم المستخدم يجب الا يحتوي علي مسافة و يجب ان يكون انجليزي'
                  );
                }

                if (!values.surname) {
                  errors.surname = t(
                    'modules.main.users.users.messages.surname'
                  );
                }
                const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if (!regex.test(values.emailAddress)) {
                  errors.emailAddress = t(
                    'modules.main.users.users.messages.emailFormat'
                  );
                }
                if (!values.emailAddress) {
                  errors.emailAddress = t(
                    'modules.main.users.users.messages.email'
                  );
                }
                if (!values.password) {
                  errors.password = t(
                    'modules.main.users.users.messages.password'
                  );
                }
                if (values.password.length < 6) {
                  errors.password = t(
                    'modules.main.users.users.messages.lengthPassword'
                  );
                }
                if (!passwordValidation(values.password)) {
                  errors.password = t(
                    'modules.main.users.users.messages.charachterPassword'
                  );
                }

                if (roleStatic == null) {
                  errors.roleStatic = t(
                    'modules.main.users.users.messages.permissionStatic'
                  );
                }

                if (values.mobile) {
                  if (values.mobile.length != 14) {
                    errors.mobile = t(
                      'modules.main.users.users.messages.phoneLength'
                    );
                  }
                  if (!values.mobile.startsWith('0096')) {
                    errors.mobile = t(
                      'modules.main.users.users.messages.phoneStart'
                    );
                  }
                }
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                addUser(values);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <div>
                  <InputLabel
                    value={values.userName}
                    onChange={handleChange}
                    name="userName"
                    type={'text'}
                    label={t('modules.main.users.users.form.userName')}
                    placeholder={t(
                      'modules.main.users.users.placeholder.userName'
                    )}
                    style={{
                      borderColor: errors.userName
                        ? 'red'
                        : values.userName != ''
                          ? '#22bb33'
                          : 'transparent',
                    }}
                  />
                  <div className="text-sm text-red-600 mt-3">
                    {errors.userName && touched.userName && errors.userName}
                  </div>
                  <InputLabel
                    value={values.name}
                    onChange={handleChange}
                    name="name"
                    type={'text'}
                    label={t('modules.main.users.users.form.name')}
                    placeholder={t('modules.main.users.users.placeholder.name')}
                    style={{
                      borderColor: errors.name
                        ? 'red'
                        : values.name != ''
                          ? '#22bb33'
                          : 'transparent',
                    }}
                  />
                  <div className="text-sm text-red-600 mt-3">
                    {errors.name && touched.name && errors.name}
                  </div>
                  <InputLabel
                    value={values.surname}
                    onChange={handleChange}
                    name="surname"
                    type={'text'}
                    label={t('modules.main.users.users.form.surname')}
                    placeholder={t(
                      'modules.main.users.users.placeholder.surname'
                    )}
                    style={{
                      borderColor: errors.surname
                        ? 'red'
                        : values.surname != ''
                          ? '#22bb33'
                          : 'transparent',
                    }}
                  />
                  <div className="text-sm text-red-600 mt-3">
                    {errors.surname && touched.surname && errors.surname}
                  </div>
                  <InputLabel
                    value={values.emailAddress}
                    onChange={handleChange}
                    name="emailAddress"
                    type={'email'}
                    label={t('modules.main.users.users.form.email')}
                    placeholder={t(
                      'modules.main.users.users.placeholder.email'
                    )}
                    style={{
                      borderColor: errors.emailAddress
                        ? 'red'
                        : values.emailAddress != ''
                          ? '#22bb33'
                          : 'transparent',
                    }}
                  />
                  <div className="text-sm text-red-600 mt-3">
                    {errors.emailAddress &&
                      touched.emailAddress &&
                      errors.emailAddress}
                  </div>

                  <InputLabel
                    value={values.password}
                    onChange={handleChange}
                    name="password"
                    type={'password'}
                    label={t('modules.main.users.users.form.password')}
                    placeholder={t(
                      'modules.main.users.users.placeholder.password'
                    )}
                    style={{
                      borderColor: errors.password
                        ? 'red'
                        : values.password != ''
                          ? '#22bb33'
                          : 'transparent',
                    }}
                  />
                  <div className="text-sm text-red-600 mt-3">
                    {errors.password && touched.password && errors.password}
                  </div>

                  <DropdownMultiSelect
                    isSearchable={true}
                    value={roleStatic}
                    handleChange={handleChangeRoleStatic}
                    placeholder={t(
                      'modules.main.users.users.placeholder.permissionStatic'
                    )}
                    label={t('modules.main.users.users.form.permissionsStatic')}
                    options={rolesStatic}
                    isMultiple={false}
                  />
                  <div className="text-sm text-red-600 mt-3">
                    {errors.roleStatic &&
                      touched.roleStatic &&
                      errors.roleStatic}
                  </div>
                  <div>
                    <DropdownMultiSelect
                      isSearchable={true}
                      value={entity}
                      handleChange={handleChangeEntities}
                      placeholder={t(
                        'modules.main.users.users.placeholder.entity'
                      )}
                      label={t('modules.main.users.users.form.entity')}
                      options={entitiesOptions}
                      isMultiple={false}
                    />
                    <div className="text-sm text-red-600 mt-3">
                      {errors.agencyId && touched.agencyId && errors.agencyId}
                    </div>
                  </div>
                  {/* ) : null} */}
                  <div className="text-sm text-[#202969] mt-3">
                    يتم اختيار الجهة في حالة كانت الصلاحية جهة
                  </div>

                  {/* {roleStatic?.value == "Reviewer" ? ( */}
                  <div>
                    <NewDropdownMultiSelect
                      label={'الخبراء'}
                      placeholder={'اختر خبير'}
                      options={experts}
                      handleChange={handleChangeExperts}
                      value={selectedExperts}
                      isMultiple={true}
                    />
                    <div className="text-sm text-red-600 mt-3">
                      {errors.users && touched.users && errors.users}
                    </div>
                  </div>
                  {/* ) : null} */}
                  <div className="text-sm text-[#202969] mt-3">
                    يتم اختيار الخبراء في حالة كانت الصلاحية مدقق
                  </div>

                  <InputLabel
                    value={values.mobile}
                    onChange={handleChange}
                    type={'text'}
                    name="mobile"
                    label={t('modules.main.users.users.form.phone')}
                    placeholder={t(
                      'modules.main.users.users.placeholder.phone'
                    )}
                    style={{
                      borderColor: errors.mobile
                        ? 'red'
                        : values.mobile != ''
                          ? '#22bb33'
                          : 'transparent',
                    }}
                  />
                  <div className="text-sm text-red-600 mt-3">
                    {errors.mobile && touched.mobile && errors.mobile}
                  </div>
                </div>
              )}
            </Formik>
          </div>
        }
        open={open}
        titleButton={t('modules.main.users.users.titleAddButton')}
        setOpen={() => setOpen(false)}
        action={handleSubmitAddUser}
        type={'submit'}
        headerTitle={t('modules.main.users.users.titleAddNewButton')}
      />
      <div className="my-5">
        <div className="my-5 mb-7 flex justify-end">
          <DrawerFilter
            handleSearch={() => getAllUsers(currentPage, perPage.value)}
            handleClear={clearSearch}
          >
            <AppInput
              type={'text'}
              value={searchFilter}
              name={'searchFilter'}
              onChange={(e) => setSearchFilter(e.target.value)}
              label={'بحث'}
              variant="gray"
              isRequired={false}
            />
            <DropdownMultiSelect
              filter={true}
              withoutLabel={false}
              isSearchable={true}
              value={usersContext.selectedEntity}
              handleChange={handleChangeEntitiesFilter}
              placeholder={t('modules.main.users.users.filter.entity')}
              label={t('modules.main.users.users.filter.entity')}
              options={entitiesOptions}
              isMultiple={false}
            />
            <NewDropdownMultiSelect
              filter={true}
              withoutLabel={false}
              isSearchable={false}
              value={usersContext.selectedRole}
              handleChange={handleChangeRole}
              placeholder={t('modules.main.users.users.filter.permission')}
              label={t('modules.main.users.users.filter.permission')}
              options={rolesStatic}
              isMultiple={true}
            />
            <DropdownMultiSelect
              filter={true}
              withoutLabel={false}
              isSearchable={false}
              value={usersContext.selectedStatus}
              handleChange={handleChangeStatus}
              placeholder={t('modules.main.users.users.filter.action')}
              label={t('modules.main.users.users.filter.action')}
              options={statuses}
              isMultiple={false}
            />

            {/* <DropdownMultiSelect
              filter={true}
              withoutLabel={false}
              isSearchable={false}
              value={perPage}
              handleChange={handlePerRowsChange}
              placeholder={t("عدد العناصر في الجدول")}
              label={t("عدد العناصر في الجدول")}
              options={[
                { label: "5", value: 5 },
                { label: "10", value: 10 },
                { label: "25", value: 25 },
                { label: "50", value: 50 },
                { label: "100", value: 100 },
              ]}
              isMultiple={false}
            /> */}
          </DrawerFilter>
        </div>

        <div className="mt-3">
          {permissionsCheck('Pages.Administration.Users.GetAll') ? (
            <CustomTable
              onSearch={handleFilterData}
              withExcel
              withSearch
              loading={loading}
              columns={ListUsers}
              data={filteredData || users}
              totalPages={totalPages}
              onPageChange={handlePageClick}
              currentPage={currentPage}
              handlePerRowsChange={handlePerRowsChange}
              onExportExcel={() => downloadExcel('users', usersForExcel)}
              totalRows={totalRows}
              handlePerPageChange={handlePerRowsChange}
              perPage={perPage}
              showPerPage={true}
            />
          ) : (
            <div className="text-[#bb2124] text-xl text-center flex justify-center items-center">
              {'ليس لديك صلاحية لهذا'}
            </div>
          )}
        </div>
      </div>
    </HeaderWithBreadcrumbLayout>
  );
};

export default UsersList;
