import { useQuery } from '@tanstack/react-query';
import {
  axiosGetRequest,
  axiosPostRequest,
} from '../../../../services/Request';
import environment from '../../../../services/environment';
import { StageContext } from '../../../../contexts/stageContext';
import { useContext } from 'react';

export const useGetBestFiveAgencies = () => {
  const { currentStage } = useContext(StageContext);
  const phaseOneStageId = currentStage?.stageIds?.[0];

  const { data, isLoading: isGettingBestFiveAgencies } = useQuery({
    queryKey: ['AdminBestFiveAgencies', phaseOneStageId],
    queryFn: ({ signal }) =>
      axiosGetRequest(environment.GetTopFiveAgencyAnswered, null, true, signal),
  });

  const bestFiveAgencies = data?.result;

  return { data, bestFiveAgencies, isGettingBestFiveAgencies };
};
