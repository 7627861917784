import React, { useState } from 'react';
import AppDivider from '../../../../../../../components/appDivider';
import AppButton from '../../../../../../../components/buttons/appButton';
import AddSubRequirement from './addSubRequirement';
import SubRequirementList from './subRequirementList';

const StandardSubRequirements = ({ subReq, requirementIndx, disabled }) => {
  const [showAdd, setShowAdd] = useState(false);
  return (
    <div className="mt-7">
      <AppDivider />
      <div className="flex justify-between mt-8">
        <h3 className="font-semibold">المتطلبات الفرعية</h3>
        {!showAdd && (
          <AppButton className="px-5" onClick={setShowAdd((prev) => !prev)}>
            إضافة متطلب فرعى
          </AppButton>
        )}
      </div>
      {showAdd && !disabled && (
        <AddSubRequirement requirementIndx={requirementIndx} subReq={subReq} />
      )}
      <div className="mt-4">
        <SubRequirementList
          requirementIndx={requirementIndx}
          subReq={subReq}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default StandardSubRequirements;
