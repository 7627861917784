import { useEffect, useState } from 'react';
import IndicatorCard from '../../../components/IndicatorCard';
import { axiosGetRequest, get, post } from '../../../services/Request';
import environment from '../../../services/environment';
// import function to register Swiper custom elements
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { register } from 'swiper/element/bundle';
import { permissionsCheck } from '../../../helpers/utils';
// register Swiper custom elements
register();

const IndicatorAfterLogin = () => {
  const [loading, setLoading] = useState(false);

  let [indicators, setIndicators] = useState([]);

  const getIndicators = (page) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    get(environment.getAllIndicators, config, (res) => {
      if (res?.status === 200) {
        setIndicators(res?.data?.result?.items);
      }
    });
  };

  async function addLoginCustomAudit(surveyId) {
    await axiosGetRequest(environment.addLoginCustomAudit, {
      surveyId,
    });
  }

  const chooseIndicator = (indicatorId) => {
    localStorage.removeItem('IndicatorId');
    localStorage.setItem('IndicatorId', indicatorId);

    getCurrentSurvey(indicatorId);
  };

  const getCurrentSurvey = async (indicatorId) => {
    setLoading(true);

    const res = await axiosGetRequest(environment.getCurrentSurvey, {
      idicatorId: indicatorId,
    });

    if (res?.success) {
      localStorage.setItem(
        'surveyId',
        permissionsCheck('Pages.UserType.Admin')
          ? res?.result?.id || '136'
          : res?.result?.id
      );
      if (JSON.parse(localStorage.getItem('agencyId')) == null) {
        window.location.href = '/';
      } else {
        // addLoginCustomAudit(res?.result?.id);

        getCheckTermsUser(
          permissionsCheck('Pages.UserType.Admin') ? '136' : res?.result?.id
        );
      }
    } else {
      setLoading(false);
    }
  };

  const getCheckTermsUser = (surveyId) => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    post(
      environment.checkUserAcceptTerms +
        '?userId=' +
        localStorage.getItem('userId') +
        '&surveyId=' +
        surveyId,
      {},
      config,
      (res) => {
        setLoading(false);
        if (res.data.success == false) {
        } else if (res.data.success == false) {
        } else if (res.data.success == true) {
          localStorage.setItem('acceptedTerms', res.data.result);
          if (res.data.result == true) {
            window.location.href = '/';
          } else {
            window.location.href = `/survey/${surveyId}/polices/accept`;
          }
        }
      }
    );
  };

  // const [currentPage, setCurrentPage] = useState(0);
  // const [totalPages, setTotalPages] = useState(null);

  // const handlePageClick = (event) => {
  //   const newOffset = event.selected;
  //   setCurrentPage(newOffset);
  //   getIndicators(newOffset);
  // };
  const swiperEl = document.querySelector('swiper-container');

  useEffect(() => {
    getIndicators(0);
  }, []);

  const handleNext = () => {
    swiperEl.swiper.slideNext();
  };
  const handlePrev = () => {
    swiperEl.swiper.slidePrev();
  };

  const colors = ['#0484b5', '#08adb1', '#704b98'];

  return (
    <div className="w-full bg-white h-screen flex flex-col gap-10 justify-center items-center px-14 ">
      <div className="flex gap-5">
        <img src="/logo.svg" alt="qiyas" width={'270px'} />
        {/* <img src={LogoHeader2} alt="qiyas" width={"270px"} /> */}
      </div>
      {/* //grid grid-cols-1 md:grid-cols-3 gap-5 */}
      <div className="w-full relative px-16">
        <swiper-container
          slides-per-view={indicators?.length > 2 ? '3' : '1'}
          speed="200"
          loop={indicators?.length > 3}
          navigation="false"
          space-between={'25px'}
          centered-slides="true"
          className=""
        >
          {indicators.map((item, index) => (
            <swiper-slide>
              <IndicatorCard
                isActive={item?.isActive}
                bgColor={colors[index % colors.length]}
                title={item.title}
                body={item.description}
                id={item?.id}
                chooseIndicator={chooseIndicator}
                loading={loading}
              />
            </swiper-slide>
          ))}
        </swiper-container>
        <div className="absolute top-1/2  -translate-y-1/2 right-0    rounded-full">
          <div onClick={handleNext} className="">
            <button className="p-5 rounded-full  bg-blue text-white flex justify-center items-center">
              <FontAwesomeIcon icon={faArrowRight} />
            </button>
          </div>
        </div>
        <div className="absolute top-1/2 -translate-y-1/2 left-0  rounded-full">
          <div onClick={handlePrev} className="">
            <button className="p-5 rounded-full  flex bg-blue text-white justify-center items-center ">
              <FontAwesomeIcon icon={faArrowLeft} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndicatorAfterLogin;
