import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { pen, removeIcon, settingDark } from '../assets/icons/Index';
import { permissionsCheck } from '../helpers/utils';
import NewTooltip from './NewTooltip';

const AppActions = ({
  handleSettingClick,
  handleEditClick,
  handleDeleteClick,
  hideSettings = false,
  hideEdit = false,
  hideDelete = false,
  handleAssignAgenciesToExpertAndReviewerClick,
  page,
}) => {
  return (
    <div className="flex items-center justify-end">
      {handleAssignAgenciesToExpertAndReviewerClick && (
        <NewTooltip content={'اضافة الجهات لمستخدمين'}>
          <div
            onClick={handleAssignAgenciesToExpertAndReviewerClick}
            className="rounded-full p-1 bg-[#228B221A] cursor-pointer mr-2 ml-2 flex items-center"
          >
            <FontAwesomeIcon
              icon={faPlus}
              style={{ fontSize: 20, color: '#228B22' }}
            />
          </div>
        </NewTooltip>
      )}
      {handleSettingClick && !hideSettings && (
        <NewTooltip content={'الاٍعدادات'}>
          <div
            onClick={handleSettingClick}
            className="rounded-full p-1 bg-[#EE5D501A] cursor-pointer"
          >
            <img src={settingDark} alt="setting icon" />
          </div>
        </NewTooltip>
      )}
      {permissionsCheck('Pages.Indicators.Edit') ? (
        <div>
          {handleEditClick && !hideEdit && (
            <NewTooltip content={'تعديل'}>
              <div
                onClick={handleEditClick}
                className="rounded-full p-1 bg-[#FFF6DC] cursor-pointer mr-2 ml-2"
              >
                <img src={pen} alt="edit icon" />
              </div>
            </NewTooltip>
          )}
        </div>
      ) : null}
      {permissionsCheck('Pages.Indicators.Delete') ? (
        <div>
          {handleDeleteClick && !hideDelete && (
            <NewTooltip content={'حذف'}>
              <div
                onClick={handleDeleteClick}
                className="rounded-full p-1 bg-[#FEEDEF] cursor-pointer"
              >
                <img src={removeIcon} alt="delete icon" />
              </div>
            </NewTooltip>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default AppActions;
