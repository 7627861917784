import { useState } from 'react';
import { Formik } from 'formik';
import { useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { DropdownMultiSelect, InputLabel } from '../../../../components';
import { axiosGetRequest, post, put } from '../../../../services/Request';
import environment from '../../../../services/environment';
import { questionTypesMapper } from './SurveyQuestions';
import AppCheckbox from '../../../../components/form/appCheckbox';

export const AddEditQuestionForm = ({
  formRef,
  question,
  isAdd,
  refetchSurveyQuestions,
  setAddEditLoading,
  setQuestionForEdit,
  showModal,
  satisfictionSurveyId,
}) => {
  const [hasAttachment, setHasAttachment] = useState(!!question?.hasAttachment);
  const [choices, setChoices] = useState(
    question?.questionMultiChoices?.map((item) => item.title) || ['']
  );
  const [selectedQuestionType, setSelectedQuestionType] = useState(
    question?.questionType
      ? {
          label: questionTypesMapper[question?.questionType?.title],
          value: question?.questionType?.id?.toString(),
        }
      : null
  );

  const hasMultipleChoices = selectedQuestionType?.value === '3';

  const { data: questionTypes = [], isLoading: isGettingQuestionTypes } =
    useQuery({
      queryKey: ['GetRateSurveyQuestionTypes'],
      queryFn: () =>
        axiosGetRequest(environment.GetRateSurveyQuestionTypes, null),
      select: (res) => res?.result,
    });

  const handleChangeQuestionType = (value) => {
    setSelectedQuestionType(value);
  };

  const addQuestionToSurvey = ({ title, description, isRequired }) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };

    const data = {
      rateSurveyId: satisfictionSurveyId,
      title,
      description,
      questionType: Number(selectedQuestionType.value),
      questionMultiChoiceTitles: hasMultipleChoices ? choices : null,
      hasAttachment,
      isRequired,
    };
    setAddEditLoading(true);
    post(environment.CreateRateSurveyQuestion, data, config, (res) => {
      if (res.status === 200) {
        toast.success('تم اضافة السؤال بنجاح');
        showModal(false);
      } else if (res.status === 500 || res.status === 400) {
        toast.error(res.data.error.message);
      }
      setAddEditLoading(false);
      refetchSurveyQuestions();
    });
  };

  const editSurveyQuestion = ({ title, description, isRequired }) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };

    const data = {
      id: question?.id,
      rateSurveyId: satisfictionSurveyId,
      title,
      description,
      questionType: Number(selectedQuestionType?.value),
      questionMultiChoiceTitles: hasMultipleChoices ? choices : null,
      hasAttachment,
      isRequired,
    };
    setAddEditLoading(true);
    put(environment.UpdateRateSurveyQuestion, data, config, (res) => {
      if (res.status === 200) {
        toast.success('تم تعديل السؤال بنجاح');
        showModal(false);
      } else if (res.status === 500 || res.status === 400) {
        toast.error(res.data.error.message);
      }
      setAddEditLoading(false);
      setQuestionForEdit(null);
      refetchSurveyQuestions();
    });
  };

  const handleChoiceChange = (index, value) => {
    const updatedChoices = [...choices];
    updatedChoices[index] = value;
    setChoices(updatedChoices);
  };

  const addChoice = () => {
    setChoices([...choices, '']);
  };

  const removeChoice = (index) => {
    setChoices(choices.filter((_, i) => i !== index));
  };

  const handleSubmit = (values, isAdd) => {
    if (isAdd) {
      addQuestionToSurvey(values);
    } else {
      editSurveyQuestion(values);
    }
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={{
        title: question?.title || '',
        description: question?.description || '',
        questionType: selectedQuestionType?.value || '',
        hasAttachment: hasAttachment,
        isRequired: !!question?.isRequired,
      }}
      validate={(values) => {
        const errors = {};
        if (!values.title || !values.title.trim()) {
          errors.title = 'هذا الحقل مطلوب';
        }
        if (!values.description || !values.description.trim()) {
          errors.description = 'هذا الحقل مطلوب';
        }
        if (!selectedQuestionType?.value) {
          errors.questionType = 'هذا الحقل مطلوب';
        }
        const hasEmptyChoice = choices.some((choice) => !choice.trim());
        if (hasEmptyChoice && hasMultipleChoices) {
          errors.questionType = 'تأكد من ادخال جميع الاختيارات';
        }
        if (hasMultipleChoices && !choices?.length) {
          errors.questionType = 'يجب اضافة اختيار واحد على الاقل';
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        handleSubmit(values, isAdd);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <div>
          <InputLabel
            value={values.title}
            onChange={handleChange}
            name="title"
            type="text"
            label="السؤال"
            placeholder="السؤال"
          />
          <div className="text-sm text-red-600 my-3">
            {errors.title && touched.title && errors.title}
          </div>
          <InputLabel
            value={values.description}
            onChange={handleChange}
            name="description"
            type="text"
            label="وصف السؤال"
            placeholder="وصف السؤال"
          />
          <div className="text-sm text-red-600 my-3">
            {errors.description && touched.description && errors.description}
          </div>
          <DropdownMultiSelect
            isClearable={true}
            withoutLabel={false}
            isSearchable={false}
            isRequired={true}
            value={selectedQuestionType}
            handleChange={handleChangeQuestionType}
            options={questionTypes?.map((item) => ({
              value: item.id?.toString(),
              label: questionTypesMapper[item.title] || '',
            }))}
            isMultiple={false}
            label="اختر نوع السؤال"
            placeholder="اختر نوع السؤال"
            loading={isGettingQuestionTypes}
          />
          <div className="text-sm text-red-600 my-3">
            {errors.questionType && touched.questionType && errors.questionType}
          </div>
          {hasMultipleChoices && (
            <div className="mb-8">
              <h4>أدخل الخيارات:</h4>
              {choices.map((choice, index) => (
                <div key={index} className="flex items-center mb-2">
                  <div className="w-full">
                    <InputLabel
                      type="text"
                      value={choice}
                      onChange={(e) =>
                        handleChoiceChange(index, e.target.value)
                      }
                      placeholder={`خيار ${index + 1}`}
                      isRequired={false}
                    />
                  </div>
                  <button
                    type="button"
                    className="text-red-600 rounded-md px-10"
                    onClick={() => removeChoice(index)}
                  >
                    حذف
                  </button>
                </div>
              ))}
              <button type="button" className="text-green" onClick={addChoice}>
                أضف خيارًا جديدًا
              </button>
            </div>
          )}
          <AppCheckbox
            label="هل يوجد مرفق"
            id={'hasAttachment'}
            name={'hasAttachment'}
            checked={hasAttachment}
            defaultValue={hasAttachment}
            onChange={(e) => {
              setHasAttachment(e.target.checked);
            }}
          />
          <AppCheckbox
            label="اجباري"
            name="isRequired"
            id="isRequired"
            checked={values.isRequired}
            defaultValue={values.isRequired}
            onChange={handleChange}
          />
        </div>
      )}
    </Formik>
  );
};
