import React, { useContext, useEffect, useState } from 'react';
import { DropdownMultiSelect } from '../../../../../components';
import { AddStandardContext } from '../addStandard/addStandardContext';

const SubRequirementItemForEdit = ({
  title,
  standardRequirementClassificationId,
  index,
  id,
  reqId,
}) => {
  const { classifications } = useContext(AddStandardContext);
  const [subRequirement, setSubRequirement] = useState({
    id,
    title,
    standardRequirementClassificationId,
  });
  const { editRequirementClassifications, setEditRequirementClassifications } =
    useContext(AddStandardContext);

  const [errors, setErrors] = useState({
    standardRequirementClassificationId: '',
  });
  const [selectedClassifications, setSelectedClassifications] = useState('');
  const classificationOptions =
    classifications?.length > 0
      ? classifications?.map((item) => ({
          label: item.title,
          value: item.id,
          id: item.id,
        }))
      : [];

  useEffect(() => {
    const selectedClass = classificationOptions?.filter(
      (item) => item?.id == standardRequirementClassificationId
    );

    setSelectedClassifications(selectedClass?.[0] || '');
  }, [classifications]);

  const handleChangeClassifications = (value) => {
    setSelectedClassifications(value);

    if (!value) {
      setErrors({
        ...errors,
        standardRequirementClassificationId: 'يجب اختيار التصنيف',
      });
    } else {
      setErrors({ ...errors, standardRequirementClassificationId: '' });
    }
    setSubRequirement({
      ...subRequirement,
      standardRequirementClassificationId: value?.id ? value?.id : 0,
    });

    const updateChangedClassifications = editRequirementClassifications?.map(
      (req) => {
        if (req?.id == reqId) {
          const subStandardRequirements = req?.subStandardRequirements?.map(
            (subReq) => {
              if (subReq?.id == id) {
                return {
                  ...subReq,
                  standardRequirementClassificationId: value?.id,
                };
              }
              return subReq;
            }
          );

          return {
            ...req,
            subStandardRequirements,
          };
        }
        return req;
      }
    );

    setEditRequirementClassifications(updateChangedClassifications);
  };

  //

  return (
    <div className="col-xl-6 px-1">
      <div className="border-2 border-emerald-400 rounded p-4">
        <h2 className=" mb-2 text-[16px]">{title}</h2>
        <div className="max-w-[350px] pr-3">
          <DropdownMultiSelect
            isClearable={true}
            withoutLabel={false}
            isSearchable={true}
            value={selectedClassifications}
            handleChange={handleChangeClassifications}
            label="تصنيف"
            placeholder="اكتب هنا"
            options={classificationOptions}
            isMultiple={false}
            isRequired={true}
            errorMessage={errors?.standardRequirementClassificationId}
          />
        </div>
      </div>
    </div>
  );
};

export default SubRequirementItemForEdit;
