import NoAnswerComponent from '../../components/NoAnswerComponent';
import AuditTimeLine from './AuditTimeLine';

function AnswersHistory({ answersHistory, showNote = true, userType }) {
  return (
    <div>
      {!answersHistory?.length ? (
        <NoAnswerComponent />
      ) : (
        <AuditTimeLine
          events={answersHistory}
          showNote={showNote}
          userType={userType}
        />
      )}
    </div>
  );
}

export default AnswersHistory;
