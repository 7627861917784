import { checkGreenCircle } from '../../../../../assets/icons/Index';
import { all, removeCircleDark } from '../../../../../assets/icons/dashboard';

export function standardAgenciesCards(data) {
  let expertArr = [
    {
      value: 6,
      title: 'دققها المدقق',
      number: data.totalReviewedByReviewer,
      icon: checkGreenCircle,
      color: '#ffff6e2f',
    },
    {
      value: 7,
      title: ' لم يدققها المدقق',
      number: data.totalNotReviewedByReviewer,
      icon: removeCircleDark,
      color: '#ffff6e5e',
    },
    {
      value: 8,
      title: 'دققها ضابط الاتصال',
      number: data.totalReviewedByPoc,
      icon: checkGreenCircle,
      color: '#d375ff13',
    },
    {
      value: 9,
      title: ' لم يدققها ضابط الاتصال',
      number: data.totalNotReviewedByPoc,
      icon: removeCircleDark,
      color: '#d375ff42',
    },
    {
      value: 4,
      title: 'دققها الخبير',
      number: data.totalReviewedByExpert,
      icon: checkGreenCircle,
      color: '#8ae8ff2f',
    },
    {
      value: 5,
      title: ' لم يدققها الخبير',
      number: data.totalNotReviewedByExpert,
      icon: removeCircleDark,
      color: '#8ae8ff73',
    },
  ];

  return expertArr;
}
