import { useState } from 'react';
import AppActions from '../../../../../../components/appActions';
import AppDeleteItemModal from '../../../../../../components/Models/appDeleteItemModal';
import environment from '../../../../../../services/environment';
import { commitmentOperatorsOptions } from '../constants';
import UpdateEquivalentResultListModal from '../UpdateEquivalentResultListModal';

const EquivalentResultItem = ({
  id,
  title,
  rangeFrom,
  rangeFromOperator,
  rangeTo,
  rangeToOperator,
  surveyId,
  setEquivalentResultList,
  equivalentResultList,
  color,
}) => {
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const removeDeletedDegree = () => {
    const deleteItem = equivalentResultList.filter((item) => item.id != id);

    setEquivalentResultList(deleteItem);
  };

  return (
    <div className="bg-white rounded-lg row gap-y-6 mb-4 mx-1 p-6">
      <div className="col-xl-12">
        <div className="flex items-center mb-1">
          <p className="text-blue_text">النتيجة</p>
          <p className="px-3 text-sm text-light">{title}</p>
        </div>
      </div>
      <div className="col-md-4">
        <div className="flex items-center mb-1">
          <p className="text-blue_text">اللون</p>
          <div
            className="mx-3 rounded-lg text-sm w-[100px] h-[30px]"
            style={{ backgroundColor: color || '#09c' }}
          ></div>
        </div>
      </div>
      <div className="col-lg-12 col-xl-4">
        <div className="flex items-center mb-1">
          <p className="text-blue_text">النسبة من</p>
          <p className="px-3 text-sm text-green"> {rangeFrom} %</p>
          <p className="px-3 text-sm text-green">
            ({' '}
            {commitmentOperatorsOptions?.find(
              (item) => item.id == rangeFromOperator
            )?.label ?? ''}{' '}
            )
          </p>
        </div>
      </div>
      <div className="col-lg-12 col-xl-4">
        <div className="flex items-center mb-1">
          <p className="text-blue_text">النسبة الى</p>
          <p className="px-3 text-sm text-green"> {rangeTo} %</p>
          <p className="px-3 text-sm text-green">
            ({' '}
            {commitmentOperatorsOptions?.find(
              (item) => item.id == rangeToOperator
            )?.label ?? ''}{' '}
            )
          </p>
        </div>
      </div>

      <div className="col-xl-4">
        <AppActions
          handleEditClick={() => {
            setOpenUpdateModal(true);
          }}
          handleDeleteClick={() => {
            setOpenDeleteModal(true);
          }}
        />
      </div>

      <UpdateEquivalentResultListModal
        id={id}
        openModal={openUpdateModal}
        setOpenModal={setOpenUpdateModal}
        values={{
          id,
          title,
          rangeFrom,
          rangeTo,
          surveyId,
          color,
          rangeFromOperator: commitmentOperatorsOptions.find(
            (item) => item.id == rangeFromOperator
          ),
          rangeToOperator: commitmentOperatorsOptions.find(
            (item) => item.id == rangeToOperator
          ),
        }}
        setEquivalentResultList={setEquivalentResultList}
        equivalentResultList={equivalentResultList}
      />

      <AppDeleteItemModal
        id={id}
        modalTitle="حذف درجة"
        itemTitle={title}
        openModal={openDeleteModal}
        setOpenModal={setOpenDeleteModal}
        api={environment.deleteEquivalentResult}
        params={{ Id: id }}
        setDegreeList={setEquivalentResultList}
        degreeList={equivalentResultList}
        removeItemFromList={removeDeletedDegree}
      />
    </div>
  );
};

export default EquivalentResultItem;
