import StandardAnswerProofFiles from '../../../standardAnswerProofFiles';
import ShowUploadedFiles from '../../../standardAnswerProofFiles/ui/showUploadedFiles';

export default function AgencyProofFilesViewOnly({ agencyProofAnswers }) {
  const renderPreviousStageFiles = agencyProofAnswers?.map((file) => (
    <div
      key={file?.id}
      className="max-w-[750px] pl-8 mb-14 pr-[80px] flex flex-col justify-beween items-start "
    >
      <div className="w-full">
        <div className="relative mt-4 flex flex-row items-start justify-between">
          <div className="flex items-start">
            <span className="bg-[#D2F2E6] w-4 h-4 rounded-full absolute flex justify-center items-center mt-2">
              <span className="bg-[#1CC081] w-[6px] h-[6px] rounded-full absolute"></span>
            </span>
            <h4 className="mr-6 ml-6 text-[#292069]">
              {file?.standardProofTitle}
            </h4>
          </div>
          <p className="text-[#7D8BAB] mb-2">
            {file?.standardProofFileSize + 'MB'}
          </p>
        </div>
        <ShowUploadedFiles
          uploadedFiles={file?.standardProofAttachments}
          document={file}
          viewOnly
        />
      </div>
    </div>
  ));

  const isThereFiles = renderPreviousStageFiles?.length > 0;

  return (
    <>
      <StandardAnswerProofFiles
        hideNext
        label={'مستندات الإثبات في مرحلة فتح النظام'}
      >
        {isThereFiles && renderPreviousStageFiles}
        {!isThereFiles && (
          <p className="text-[#7D8BAB] text-center">لا توجد ملفات</p>
        )}
      </StandardAnswerProofFiles>
    </>
  );
}
