import { faArrowRight, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge } from 'flowbite-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { plusLight, programsIcon } from '../../../assets/icons/Index';
import searchIcon from '../../../assets/icons/searchIcon.svg';
import { DropdownMultiSelect, SModal, Title } from '../../../components';
import NewTable from '../../../components/NewTable';
import { permissionsCheck } from '../../../helpers/utils';
import NewHeader from '../../../layouts/header/NewHeader';
import { get, post } from '../../../services/Request';
import environment from '../../../services/environment';
import MailDetails from './MailDetails';

const Mailbox = ({ addNewMsg, updatedCats }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({});
  const [openModel, setOpenModel] = useState(false);
  const [showDetails, setShowDetails] = useState({});
  const { t } = useTranslation();

  const [allClicked, setAllClicked] = useState(false);
  const [isClicked, setIsClicked] = useState([]);
  const [list, setList] = useState([]);
  const [active, setActive] = useState({ id: 0, list: [] });
  const [cat, setCat] = useState('');
  const [allCont, setAllCont] = useState({
    inboxCount: 0,
    sentCount: 0,
    draftsCount: 0,
  });
  const [categoryMail, setCategoryMail] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(null);
  const [search, setSearch] = useState('');
  const [ids, setIds] = useState([]);
  const [dropdownCategories, setDropdownCategories] = useState([]);
  const [categories, setCategories] = useState([]);

  const handleChangeMessages = (event) => {
    var updatedAbilities = [...ids];
    if (event.target.checked) {
      updatedAbilities = [...ids, event.target.value];
    } else {
      updatedAbilities = updatedAbilities.filter(
        (id) => event.target.value != id
      );
    }
    setIds(updatedAbilities.map((i) => i));
  };

  const isCheckedCheckbox = (id) => {
    return ids.map((i) => i).includes(id);
  };

  const columns = [
    {
      id: 'number',
      width: '75px',
      name: <div className="text-violet-950 text-sm">اختر</div>,
      header: <input type="checkbox" className="bg-[#DBE0EF] border-none" />,
      cell: (info) => (
        <div>
          <input
            onChange={handleChangeMessages}
            checked={isCheckedCheckbox(info.id)}
            value={info.id}
            id={info.id}
            type="checkbox"
            className="bg-[#DBE0EF] border-none"
          />
        </div>
      ),
    },
    {
      id: 'name',
      name: <div className="text-violet-950 text-sm">الأسم</div>,
      cell: (row) => (
        <div
          onClick={() => setShowDetails(row)}
          className="text-violet-950 text-sm"
        >
          {active.id == 0
            ? row.from
            : row.to
                .reduce((result, item) => {
                  return `${result}${item},`;
                }, '')
                .slice(0, -1)}
        </div>
      ),

      sortable: true,
    },
    {
      id: 'description',
      name: <div className="text-violet-950 text-sm">الوصف</div>,
      cell: (row) => (
        <div
          onClick={() => setShowDetails(row)}
          className="text-slate-400 w-[600px] text-sm"
        >
          {row.subject} - <small>{row.textBody}</small>
        </div>
      ),
      sortable: true,
    },
    {
      id: 'date',
      name: <div className="text-violet-950 mr-5 text-sm">التاريخ</div>,
      cell: (row) => (
        <div
          onClick={() => setShowDetails(row)}
          className="text-slate-400 text-sm"
        >
          {row.dateTimeReceived}
        </div>
      ),
      sortable: true,
    },
    {
      id: 'الحالة',
      name: <div className="text-violet-950 mr-5 text-sm">الحالة</div>,
      header: 'الحالة',
      accessorKey: 'status',
      footer: (props) => props.column.id,
      cell: (info) => (
        <>
          {info.isRead && (
            <Badge
              className="text-white font-medium bg-[#1cc182] pb-3 px-5 rounded-xl"
              size="md"
            >
              مقروءة
            </Badge>
          )}

          {!info.isRead && (
            <Badge
              className="text-white font-medium bg-[#f05868] pb-3 px-5 rounded-xl"
              size="md"
            >
              غير مقروءة
            </Badge>
          )}
        </>
      ),

      sortable: true,
    },
  ];

  useEffect(() => {
    changeShowList(0, 0);
  }, []);

  updatedCats = categories;

  const handleClickAll = (e) => {
    setAllClicked(!allClicked);
    setIsClicked(list.map((li) => li.id));
    if (allClicked) {
      setIsClicked([]);
    }
  };

  const handleItemClick = (e) => {
    const { id, checked } = e.target;
    var array = [...isClicked];
    var index = array.indexOf(id);
    if (index !== -1) {
      array.splice(index, 1);
    } else {
      array.push(id);
    }
    setIsClicked(array);
  };

  const changeShowList = (id, page) => {
    setActive({ id: id, list: [] });
    setList([]);
    setTotalCount(0);
    setAllClicked(false);
    setIsClicked([]);
    setCurrentPage(page);
    switch (id) {
      case 0:
        getAllCounts();
        getAllInbox(page, '');
        break;
      case 1:
        getAllSent(page);
        break;
      case 2:
        getAllDrafts(page);
        break;
      default:
        break;
    }
  };

  const changeCategoryList = (page, id) => {
    getAllInbox(page, id);
  };

  const getAllInbox = (page, catId) => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    let categoryId = '';
    if (catId != '') {
      categoryId = '&EmailCategoryId=' + catId;
    }
    get(
      environment.getInbox +
        '?page=' +
        page +
        '&pageSize=4&search=' +
        search +
        categoryId,
      config,
      (res) => {
        setLoading(false);
        if (res.status == 403) {
        } else if (res.status == 500) {
        } else if (res.status == 200) {
          setList(res.data.result.list);
          setTotalCount(res.data.result.allCount);
          setTotalPages(Math.ceil(res.data.result.allCount / 10));
          setLoading(false);
        }
      }
    );
  };

  const getAllSent = (page) => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    get(
      environment.getSent + '?page=' + page + '&pageSize=4&search=' + search,
      config,
      (res) => {
        setLoading(false);

        if (res.status == 403) {
        } else if (res.status == 500) {
        } else if (res.status == 200) {
          setList(res.data.result.list);
          setTotalCount(res.data.result.allCount);
          setTotalPages(Math.ceil(res.data.result.allCount / 10));
        }
      }
    );
  };

  const getAllDrafts = (page) => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    get(
      environment.getDrafts + '?page=' + page + '&pageSize=4&search=' + search,
      config,
      (res) => {
        setLoading(false);
        if (res.status == 403) {
        } else if (res.status == 500) {
        } else if (res.status == 200) {
          setList(res.data.result.list);
          setTotalCount(res.data.result.allCount);
          setTotalPages(Math.ceil(res.data.result.allCount / 10));
          setLoading(false);
        }
      }
    );
  };

  const getAllCounts = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    get(environment.getAllMailCount, config, (res) => {
      if (res.status == 200) {
        setAllCont(res.data.result);
      }
    });
  };

  const clickOptions = (e) => {
    switch (e.target.value) {
      case '2':
        read();
        break;
      case '3':
        unRead();
        break;
      case '1':
        if (active.id === 2) deleteDraft();
        else deleteMail();
        break;
      default:
        break;
    }
  };

  const read = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    let data = {
      mailIds: ids,
    };
    post(environment.makeMailRead, data, config, (res) => {
      setLoading(false);
      if (res.status == 403) {
        toast.error(res.data.error.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      } else if (res.status == 500) {
        toast.error(res.data.error.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      } else if (res.status == 200) {
        toast.success(t('تم  جعل البريد مقروء بنجاح'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
        changeShowList(active.id, currentPage);
      }
    });
  };

  const unRead = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    let data = {
      mailIds: ids,
    };
    post(environment.makeMailUnRead, data, config, (res) => {
      setLoading(false);
      if (res.status == 403) {
        toast.error(res.data.error.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      } else if (res.status == 500) {
        toast.error(res.data.error.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      } else if (res.status == 200) {
        toast.success(t('تم جعل البريد غير مقروء بنجاح'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
        changeShowList(active.id, currentPage);
      }
    });
  };

  const deleteMail = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    let data = {
      mailIds: ids,
    };
    post(environment.deleteMail, data, config, (res) => {
      setLoading(false);
      if (res.status == 403) {
        toast.error(res.data.error.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      } else if (res.status == 500) {
        toast.error(res.data.error.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      } else if (res.status == 200) {
        toast.success(t('تم الحذف بنجاح'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
        changeShowList(active.id, currentPage);
      }
    });
  };

  const addCategory = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    let data = {
      name: cat,
      nameInArabic: cat,
    };
    post(environment.addCategoryInMailBox, data, config, (res) => {
      setLoading(false);
      if (res.status == 403) {
        toast.error(res.data.error.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      } else if (res.status == 500) {
        toast.error(res.data.error.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      } else if (res.status == 200) {
        toast.success(t('تم اضافة تصنيف بنجاح'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });

        getAllCategories();
      }
    });
  };

  const addLinkedEmail = (value) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    let data = {
      emailIds: ids,
      emailCategoryId: value.id,
    };
    post(environment.addLinkedEmailInMailBox, data, config, (res) => {
      setLoading(false);
      if (res.status == 403) {
        toast.error(res.data.error.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      } else if (res.status == 500) {
        toast.error(res.data.error.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      } else if (res.status == 200) {
        toast.success(t('تم اضافة تصنيف بنجاح'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
        changeShowList(active.id, currentPage);
        getAllCategories();
      }
    });
  };

  useEffect(() => {
    getAllCategories();
  }, []);

  const getAllCategories = () => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    get(environment.getCategoriesInMailbox, config, (res) => {
      if (res.status == 403) {
        setLoading(false);
        setMessage(
          { type: 'error', message: res.data.error.message },
          setOpenModel(true)
        );
      } else if (res.status == 500) {
        setLoading(false);
        setMessage(
          { type: 'error', message: t('general.serverError') },
          setOpenModel(true)
        );
      } else if (res.status == 200) {
        let arr = [];
        res.data.result.forEach((item) => {
          let obj = { label: item.name, id: item.id, value: item.value };
          arr.push(obj);
        });
        setDropdownCategories(arr);
        setCategories(res.data.result);

        setLoading(false);
      }
    });
  };

  const deleteDraft = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    let data = {
      mailIds: isClicked,
    };
    post(environment.deleteDraft, data, config, (res) => {
      setLoading(false);
      if (res.status == 403) {
        toast.error(res.data.error.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      } else if (res.status == 500) {
        toast.error(res.data.error.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      } else if (res.status == 200) {
        toast.success(t('تم  المسح الاحتياطي بنجاح'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
        changeShowList(active.id, currentPage);
      }
    });
  };

  const handlePageClick = (event) => {
    const newOffset = event.selected;

    setCurrentPage(newOffset);
    changeShowList(active.id, newOffset);
  };

  const handleChangeSearch = (event) => {
    setSearch(event.target.value);
  };

  const onSearch = (event) => {
    if (event.key == 'Enter') {
      setSearch(event.target.value);
      changeShowList(active.id, 0);
    }
  };

  const downloadBase64File = (base64Data, fileName) => {
    let contentType = '';
    var items = fileName.split('.');
    switch (items[items.length - 1]) {
      case 'pdf':
        contentType = 'application/pdf';
        break;
      case 'png':
        contentType = 'image/png';
        break;
      default:
        contentType = '';
        break;
    }
    const linkSource = `data:${contentType};base64,${base64Data}`;
    const downloadLink = document.createElement('a');
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const handleChangeCategoryMail = (value) => {
    setCategoryMail(value, addLinkedEmail(value));
  };

  return (
    <div className="bg-white rounded-[30px] m-5">
      <NewHeader />

      <Title
        iconTitle={programsIcon}
        title={t('صندوق البريد')}
        withoutButton={true}
        titleButton={t('modules.main.surveys.survey.button.add')}
        style={{ backgroundColor: '#2B2969' }}
        withIcon={true}
        icon={plusLight}
        single={true}
      />
      <SModal
        type={message.type}
        open={openModel}
        setOpen={() => setOpenModel(false)}
        subTitle={message.message}
      />
      <div className="m-5 bg-white rounded-xl flex flex-row gap-5">
        {/* Right Section */}
        <div className="bg-slate-50 rounded-xl w-1/4 h-[100%] divide-y-2 divide-white">
          {/* Top List */}
          <div className="p-5 flex flex-col gap-5">
            <ul className="text-violet-950 text-base flex flex-col justify-between items-center w-full">
              <li
                className={
                  'w-[100%] hover:bg-slate-100 flex flex-row justify-between items-center text-start py-3 px-6 rounded-[50px] mb-1 cursor-pointer relative ' +
                  (active.id === 0 ? 'bg-slate-100' : '')
                }
                onClick={() => changeShowList(0, 0)}
              >
                {t('modules.main.mailbox.inbox')}
                {active.id === 0 && allCont.inboxCount > 0 && (
                  <span className="text-emerald-500 text-lg absolute left-6 bg-teal-50 w-[30px] h-[30px] rounded-full text-center py-auto">
                    {allCont.inboxCount}
                  </span>
                )}
              </li>
              <li
                className={
                  'hover:bg-slate-100 w-[100%] flex flex-row justify-between items-center text-start py-3 px-6 rounded-[50px] mb-1 cursor-pointer relative ' +
                  (active.id === 1 ? 'bg-slate-100' : '')
                }
                onClick={() => changeShowList(1, 0)}
              >
                {t('modules.main.mailbox.sent')}
                {active.id === 1 && allCont.sentCount > 0 && (
                  <span className="text-emerald-500 text-lg absolute left-6 bg-teal-50 w-[30px] h-[30px] rounded-full text-center py-auto">
                    {allCont.sentCount}
                  </span>
                )}
              </li>
              <li
                className={
                  'hover:bg-slate-100 w-[100%] flex flex-row justify-between items-center text-start py-3 px-6 rounded-[50px] mb-1 cursor-pointer relative ' +
                  (active.id === 2 ? 'bg-slate-100' : '')
                }
                onClick={() => changeShowList(2, 0)}
              >
                {t('modules.main.mailbox.drafts')}
                {active.id === 2 && allCont.draftsCount > 0 && (
                  <span className="text-emerald-500 text-lg absolute left-6 bg-teal-50 w-[30px] h-[30px] rounded-full text-center py-auto">
                    {allCont.draftsCount}
                  </span>
                )}
              </li>
            </ul>
            <div className="flex flex-col gap-2">
              {permissionsCheck('Pages.MailBox.Create') ? (
                <button
                  onClick={() => history.push('/new-message')}
                  className="w-[191px] h-[50px] text-white text-base bg-emerald-500 rounded-[50px] mb-2 p-2 relative"
                >
                  <span className="absolute right-0 mr-4 w-[21px] h-[21px] p-auto bg-transparent border border-white rounded-full flex justify-center items-center">
                    <FontAwesomeIcon className="bg-transparent" icon={faPlus} />
                  </span>
                  اٍرسال رسالة
                </button>
              ) : null}

              <label className="text-violet-950 text-base px-6">المزيد</label>
            </div>
          </div>

          {/* Bottom List */}
          <div className="py-5 px-10 flex flex-col gap-5">
            <div className="overflow-y-auto flex flex-col gap-5 max-h-[200px]">
              {categories.map((item, index) => (
                <div
                  key={index}
                  className="text-center items-center w-full relative flex flex-row cursor-pointer"
                  onClick={() => changeCategoryList(0, item.id)}
                >
                  <div
                    className={'w-3 h-3 absolute text-center rounded-full mt-1'}
                    style={{ backgroundColor: 'green' }}
                  ></div>
                  <div className="text-left ml-5 mr-5">
                    {item.name} ({item.emailCount})
                  </div>
                </div>
              ))}
            </div>

            <div className="w-[100%] bg-white border border-slate-50 flex flex-row justify-center items-center rounded-[50px]">
              <input
                className="w-3/5 rounded-[50px] text-xs p-4 text-right outline-none"
                placeholder={t('modules.main.mailbox.newCategory')}
                value={cat}
                onChange={(e) => setCat(e.target.value)}
              />
              <button
                onClick={() => {
                  addCategory();
                  setCat('');
                }}
                className="text-sm border-[1px] ml-2 p-1 w-[104px] text-white text-[13px] h-[37px] bg-emerald-500 rounded-[50px]"
              >
                إضافة جديد
              </button>
            </div>
          </div>
        </div>

        {/* Left Section */}
        {!showDetails || !showDetails.id ? (
          <div className="w-3/4 bg-transparent">
            <div className="w-full">
              <div className="bg-slate-50 flex flex-col gap-5 rounded-xl p-5">
                <div className="flex flex-row justify-between items-center">
                  <div>
                    <h2 className="text-violet-950 text-lg">
                      الرسائل{' '}
                      {active.id === 0 ? t('modules.main.mailbox.inbox') : ''}
                      {active.id === 1 ? t('modules.main.mailbox.sent') : ''}
                      {active.id === 2 ? t('modules.main.mailbox.drafts') : ''}
                    </h2>
                    <p className="text-slate-400 text-[15px]">
                      {totalCount} رسالة{' '}
                      {active.id === 0 && allCont.inboxCount > 0
                        ? ' | ' + allCont.inboxCount + ' غير مقرؤة'
                        : ''}
                    </p>
                  </div>
                </div>

                <div className="flex gap-16 items-center">
                  <div className="flex flex-row items-center w-80 h-[50px] bg-white rounded-[50px] border border-slate-50 p-5">
                    <img src={searchIcon} className="w-6 h-6" alt="search" />
                    <input
                      placeholder="ابحث هنا"
                      className="w-full text-slate-600 text-[15px] px-3 outline-none"
                    />
                  </div>

                  <div className="flex flex-1 items-center gap-4">
                    <div className="flex-1">
                      <select
                        className="w-full rounded-[50px] border bg-[#f4f7fe] border-[#f4f7fe] text-[15px] text-center px-3"
                        onChange={clickOptions}
                      >
                        <option value={0}>
                          {t('modules.main.mailbox.actions')}
                        </option>
                        <option value={1}>
                          {t('modules.main.mailbox.delete')}
                        </option>
                        <option value={2}>
                          {t('modules.main.mailbox.markRead')}
                        </option>
                        <option value={3}>
                          {t('modules.main.mailbox.markUnRead')}
                        </option>
                      </select>
                    </div>

                    <div className="flex-1">
                      <DropdownMultiSelect
                        isSearchable={true}
                        value={categoryMail}
                        handleChange={handleChangeCategoryMail}
                        placeholder={t('اختر تصنيف')}
                        label={t(
                          'modules.main.users.users.form.permissionsStatic'
                        )}
                        options={dropdownCategories}
                        withoutLabel={true}
                        isMultiple={false}
                        className={''}
                      />
                    </div>
                  </div>
                </div>

                {permissionsCheck('Pages.MailBox.GetAll') ? (
                  <div className="w-full">
                    <NewTable
                      loading={loading}
                      columns={columns}
                      data={list}
                      totalPages={totalPages}
                      currentPage={currentPage}
                      onPageChange={handlePageClick}
                      pagination
                    />
                  </div>
                ) : (
                  <div className="text-[#bb2124] text-xl text-center flex justify-center items-center">
                    {'ليس لديك صلاحية لهذا'}
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="flex-1">
            {permissionsCheck('Pages.MailBox.Get') ? (
              <div>
                <div className="mr-2" onClick={() => setShowDetails({})}>
                  <FontAwesomeIcon icon={faArrowRight} />
                </div>
                <MailDetails
                  key={showDetails.id}
                  id={showDetails.id}
                  from={showDetails.from}
                  to={showDetails.to}
                  cc={showDetails.cc}
                  bcc={showDetails.bcc}
                  subject={showDetails.subject}
                  textBody={showDetails.textBody}
                  time={showDetails.dateTimeReceived}
                  files={showDetails.attachments}
                />
              </div>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
};

export default Mailbox;
