import { createContext, useState } from 'react';
import environment from '../services/environment';
import { get } from '../services/Request';

const StageContext = createContext();

function StageContextProvider(props) {
  const [currentStage, setCurrentStage] = useState('');
  const [stageForDashboard, setStageForDashboard] = useState('');

  const [endDate, setEndDate] = useState('');
  const [stageRoles, setStageRoles] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [role, setRole] = useState({});
  const [hasPermission, setHasPermission] = useState(true);

  const getCurrentSurveyStage = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    // Note: This api return "ExpertsManager" role instead of "ExpertManager"
    get(
      environment.getCurrentSurveyStage +
        '?surveyId=' +
        localStorage.getItem('surveyId'),
      config,
      (res) => {
        if (res?.status == 200) {
          const roles = res?.data?.result?.surveyStageRoles
            ? res?.data?.result?.surveyStageRoles?.map(
                (item) => item?.role?.name
              )
            : [];
          setCurrentStage(res?.data?.result);
          // TODO: check the logic behind this

          const stageIdsFirstStage = res?.data?.result?.stageIds?.slice(0, 2);
          const stageIdsSecondStage = res?.data?.result?.stageIds?.slice(2, 5);

          if (stageIdsFirstStage?.includes(res?.data?.result?.id)) {
            setStageForDashboard(stageIdsFirstStage?.[0]);
            localStorage.setItem('stageForDashboard', stageIdsFirstStage?.[0]);
          }
          if (stageIdsSecondStage?.includes(res?.data?.result?.id)) {
            setStageForDashboard(stageIdsSecondStage?.[0]);
            localStorage.setItem('stageForDashboard', stageIdsSecondStage?.[0]);
          }

          setEndDate(res?.data?.result?.endDate);
          setStageRoles(roles);
          localStorage.setItem('surveyStageId', res?.data?.result?.id);
        }
      }
    );
  };

  return (
    <StageContext.Provider
      value={{
        currentStage,
        stageForDashboard,
        getCurrentSurveyStage,
        endDate,
        stageRoles,
        permissions,
        setPermissions,
        role,
        setRole,
        hasPermission,
        setHasPermission,
      }}
    >
      {props.children}
    </StageContext.Provider>
  );
}

export { StageContext, StageContextProvider };
