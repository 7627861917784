import { Badge } from 'flowbite-react';

export const answersBadges = (value) => {
  switch (value) {
    case 'التزام كلى':
      return (
        <Badge
          className="text-white font-medium bg-[#1cc182] pb-3 px-5 rounded-xl"
          size="md"
        >
          التزام كلي
        </Badge>
      );

    case 'التزام جزئى':
      return (
        <Badge
          className="text-white font-medium bg-[#ffa92b] pb-3 px-5 rounded-xl"
          size="md"
        >
          التزام جزئي
        </Badge>
      );

    case 'لا ينطبق':
      return (
        <Badge
          className="text-white font-medium bg-[#8a817a] pb-3 px-5 rounded-xl"
          size="md"
        >
          لا ينطبق
        </Badge>
      );

    case 'عدم التزام':
      return (
        <Badge
          className="text-white font-medium bg-[#f05868] pb-3 px-5 rounded-xl"
          size="md"
        >
          عدم التزام
        </Badge>
      );
    case 'لا يوجد':
      return (
        <Badge
          className="text-white font-medium bg-[#000] pb-3 px-5 rounded-xl"
          size="md"
        >
          لا يوجد
        </Badge>
      );

    default: {
      return null;
    }
  }
};
