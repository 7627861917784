import { t } from 'i18next';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router';

import SecondaryTapsLayout from '../../../../../layouts/secondaryTapsLayout';
import { surveySettingTabs } from '../data';
import { Title } from '../../../../../components';
import { programsIcon } from '../../../../../assets/icons/Index';
import AppSwitch from '../../../../../components/form/appSwitch';
import AppDivider from '../../../../../components/appDivider';
import UpdateInnovativeForm from './updateInnovativeForm';

const ServeyInnovativeForm = () => {
  const { surveyId, indicatorId } = useParams();
  const [innovativeForm, setInnovativeForm] = useState(false);
  const history = useHistory();

  return (
    <SecondaryTapsLayout
      linksArr={surveySettingTabs(
        `/indicators/${indicatorId}/surveys/${surveyId}`
      )}
      breadcrumbs={
        <div className="-mx-5">
          <Title
            iconTitle={programsIcon}
            subTitle={'اعدادات الاستبيان'}
            thired={true}
            subTitleSeconed={'اعدادت النموذج الابداعي'}
            toPageSeconed={() => history.push(`/indicators/${indicatorId}`)}
            title={t('المؤشرات')}
            withoutButton={true}
            toPage={() => history.push(`/indicators`)}
          />
        </div>
      }
      bgWhite
    >
      <div className="bg-gray_light rounded-lg p-6">
        <div className="flex justify-between items-center">
          <h3 className="text-blue_text mb-1">
            هل الاستبيان يشتمل على نموذج إبداعي ؟
          </h3>
          <div className="flex justify-end flex-grow">
            <AppSwitch
              checked={innovativeForm}
              handleChange={() => {
                setInnovativeForm((prev) => !prev);
              }}
            />
          </div>
        </div>
        {innovativeForm ? (
          <div className="mt-10">
            <AppDivider />
            <UpdateInnovativeForm />
          </div>
        ) : null}
      </div>
    </SecondaryTapsLayout>
  );
};

export default ServeyInnovativeForm;
