import AppBadge from '../../components/AppBadge';
import NoAnswerBadge from '../../components/NoAnswerBadge';

function QiyasAnswer({ badgeColor, finalNationalQiyasTeamAnswer }) {
  return (
    <div className="flex items-center gap-3">
      <h2 className="text-xl mb-3">اٍجابة الفريق الوطني لقياس :</h2>
      {finalNationalQiyasTeamAnswer ? (
        <AppBadge badgeColor={badgeColor}>
          {finalNationalQiyasTeamAnswer}
        </AppBadge>
      ) : (
        <NoAnswerBadge />
      )}
    </div>
  );
}

export default QiyasAnswer;
