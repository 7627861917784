import { useEffect, useState } from 'react';

const NewTimer = ({ startDate, endDate }) => {
  const [timeUntilStart, setTimeUntilStart] = useState(
    calculateTimeUntil(startDate)
  );
  const [timeUntilEnd, setTimeUntilEnd] = useState(calculateTimeUntil(endDate));

  useEffect(() => {
    const startTimer = setInterval(() => {
      setTimeUntilStart(calculateTimeUntil(startDate));
    }, 1000);

    const endTimer = setInterval(() => {
      setTimeUntilEnd(calculateTimeUntil(endDate));
    }, 1000);

    return () => {
      clearInterval(startTimer);
      clearInterval(endTimer);
    };
  }, [startDate, endDate]);

  function calculateTimeUntil(targetDate) {
    const now = new Date();
    const target = new Date(targetDate);
    const difference = target - now;

    if (difference <= 0) {
      return '—';
    }

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));

    return `${days}ي ${hours}س ${minutes}د`;
  }

  return [timeUntilStart, timeUntilEnd];
};

export default NewTimer;
