import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { post } from '../../../services/Request';
import environment from '../../../services/environment';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import UploadAdapter from '../../../components/UploadAdapter';
import { AppInput, InputLabel } from '../../../components';
import { toast } from 'react-toastify';
import { permissionsCheck } from '../../../helpers/utils';
import AppEditor from '../../../components/form/AppEditor';

const MailDetails = ({
  from,
  to,
  cc,
  bcc,
  subject,
  textBody,
  time,
  files,
  id,
}) => {
  const { t } = useTranslation();
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const [allFiles, setAllFiles] = useState([]);

  const downloadBase64File = (base64Data, fileName) => {
    let contentType = '';
    var items = fileName.split('.');
    switch (items[items.length - 1]) {
      case 'pdf':
        contentType = 'application/pdf';
        break;
      case 'png':
        contentType = 'image/png';
        break;
      default:
        contentType = '';
        break;
    }
    const linkSource = `data:${contentType};base64,${base64Data}`;
    const downloadLink = document.createElement('a');
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const onChangeFile = (event) => {
    let attachments = [];
    setAllFiles(attachments);
    const files = event.target.files;
    for (let i = 0; i < files.length; i++) {
      let file = files.item(i);
      let reader = new FileReader();
      reader.onload = (e) => {
        let newFile = e.target.result;
        const regex = /data:.*base64,/;
        let obj = { fileName: file.name, fileByte: newFile.replace(regex, '') };
        attachments.push(obj);
        setAllFiles(attachments);
      };
      reader.readAsDataURL(file);
    }
  };

  const sendReply = (values) => {
    //  setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    let data = {
      mailId: id,
      //  cc: values.cc ? values.cc.split(";") : [],
      //  bcc: values.bcc ? values.bcc.split(";") : [],
      subject: values.subject,
      message: description,
      attachments: allFiles,
    };

    //  return true
    post(environment.sendReplyEmail, data, config, (res) => {
      setLoading(false);
      if (res.status == 403) {
        toast.error(res.data.error.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      } else if (res.status == 500) {
        toast.error(res.data.error.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      } else if (res.status == 200) {
        toast.success(t('تم الإرسال بنجاح'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
        //  setTimeout(() => {
        //    backToMailBox();
        //  }, 1000);
      }
    });
  };

  return (
    <div className="w-full">
      <div className="w-full p-7 bg-slate-50 rounded-xl flex flex-col justify-between">
        <div className="w-full flex flex-row items-center gap-5 bg-transparent mb-5">
          <div className="flex-1 bg-slate-200 ml-2 rounded-xl p-5 flex flex-col items-start justify-between">
            <div className="flex flex-row">
              <label className="text-violet-950 text-sm">أسم الراسل:</label>
              <label className="text-slate-400 text-sm mr-2">{from}</label>
            </div>
            <div className="flex flex-row">
              <label className="text-violet-950 text-sm">المرفقات:</label>
              {files.map((file) => {
                return (
                  <div
                    className="mr-2"
                    onClick={() =>
                      downloadBase64File(file.fileByte, file.fileName)
                    }
                  >
                    <FontAwesomeIcon icon={faFileDownload} />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="flex-1 bg-white rounded-xl p-5 flex flex-col items-start justify-between">
            <div className="flex flex-row">
              <label className="text-violet-950 text-sm">مرسل اليه:</label>
              {to && (
                <label className="text-slate-400 text-sm mr-2 w-full">
                  {to
                    .reduce((result, item) => {
                      return `${result}${item},`;
                    }, '')
                    .slice(0, -1)}
                </label>
              )}
              <br />
              {cc && (
                <label className="text-slate-400 text-sm mr-2 w-full">
                  {cc
                    .reduce((result, item) => {
                      return `${result}${item},`;
                    }, '')
                    .slice(0, -1)}
                </label>
              )}
              <br />
              {bcc && (
                <label className="text-slate-400 text-sm mr-2 w-full">
                  {bcc
                    .reduce((result, item) => {
                      return `${result}${item},`;
                    }, '')
                    .slice(0, -1)}
                </label>
              )}
              <br />
            </div>
          </div>
        </div>
        <div className="w-full p-5 bg-white rounded-xl flex flex-col items-start">
          <h1 className="text-violet-950 text-lg">الرسالة</h1>
          <h4 className="text-slate-400 text-sm mb-5">{time}</h4>
          <p className="w-full h-[102px] text-right text-black text-base">
            {subject}
          </p>
          <p className="w-full h-[102px] text-right text-black text-base">
            <small>{textBody}</small>
          </p>
        </div>
      </div>
      {/* replay */}
      <Formik
        initialValues={{
          // MailId:"",
          mailId: id,
          // cc: "",
          // bcc: "",
          subject: '',
          // Subject:"",
          message: '',
          // attachments:""
          // showCc: false,
          // showBcc: false,
        }}
        validate={(values) => {
          const errors = {};
          if (!values.mailId) {
            errors.mailId = t('modules.main.mailbox.toRequired');
          }
          if (!values.subject) {
            errors.subject = t('modules.main.mailbox.subjectRequired');
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          sendReply(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit} className="w-full">
            <div className="w-full flex flex-col gap-2">
              <div className="flex flex-row pt-5">
                <label className="mb-2 text-violet-950 text-lg font-medium">
                  إلى
                </label>
                <div className="mr-5 ml-5 text-sm text-[#206929]">{from}</div>
              </div>
              <div className="mb-2 w-full flex flex-col items-start justify-between pt-5">
                <div className="flex flex-col gap-3  w-full">
                  <AppInput
                    type="text"
                    name="subject"
                    value={values.subject}
                    onChange={handleChange}
                    placeholder="أكتب عنوان الرسالة"
                    label={'عنوان الرسالة'}
                  />
                </div>
              </div>
              <div className="text-sm text-red-600 my-3">
                {errors.subject && touched.subject && errors.subject}
              </div>

              <div className="flex flex-col gap-5 w-full">
                <label className="text-violet-950 text-lg font-medium">
                  الرسالة
                </label>
                <div className="w-full">
                  <AppEditor
                    value={description}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setDescription(data);
                      editor.plugins.get('FileRepository').createUploadAdapter =
                        function (loader) {
                          return new UploadAdapter(loader);
                        };
                    }}
                  />
                </div>
              </div>

              <div className="my-5 w-full">
                <InputLabel
                  style={{ display: 'none' }}
                  type={'file'}
                  id={'fileUpload'}
                  isImage={false}
                  multiple={true}
                  onChange={onChangeFile}
                  label={'ارفق ملف'}
                />
              </div>
              {permissionsCheck('Pages.MailBox.Replay') ? (
                <div className="flex justify-end my-5">
                  <div className="flex flex-row gap-4">
                    <button
                      type={'submit'}
                      className="p-3 mt-3 text-white bg-[#1CC081] w-[160px] border-none rounded-[30px]"
                    >
                      ارسال
                    </button>
                  </div>
                </div>
              ) : null}
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default MailDetails;
