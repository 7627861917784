import { useQueryClient, useQuery } from '@tanstack/react-query';
import { axiosGetRequest } from '../../../../services/Request';
import environment from '../../../../services/environment';
import { StageContext } from '../../../../contexts/stageContext';
import { useContext } from 'react';

export const useGetAllStandards = ({ currentPage, filterValue }) => {
  const surveyId = localStorage.getItem('surveyId');
  const queryClient = useQueryClient();
  const { currentStage } = useContext(StageContext);
  const phaseThreeStageId = currentStage?.stageIds?.[2];

  const { data, isLoading: isGettingAllStandards } = useQuery({
    queryKey: [
      'AdminAllStandards',
      currentPage,
      filterValue,
      phaseThreeStageId,
    ],
    queryFn: ({ signal }) =>
      axiosGetRequest(
        environment.GetStandardsOfSurvey,
        {
          KeyWord: filterValue,
          SurveyId: surveyId,
          SkipCount: currentPage * 5,
          MaxResultCount: 5,
          stageId: phaseThreeStageId,
        },
        true,
        signal
      ),
    enabled: !!phaseThreeStageId,
  });

  const allStandards = data?.result?.items;
  const totalCount = data?.result?.totalCount;
  const totalPages = Math.ceil(totalCount / 5);

  return {
    queryClient,
    allStandards,
    totalCount,
    totalPages,
    data,
    isGettingAllStandards,
  };
};
