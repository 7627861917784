export const columnsValues = {
  agencyName: 1,
  agencyCode: 2,
  standardCode: 3,
  expertRequirementAnswers: 4,
  expertRequirementAnswersWeight: 5,
  decision: 6,
  expertFinalDegree: 7,
  expertFinalCommitmentLevelTitle: 8,
  expertFinalCommitmentLevelFixedDegree: 9,
  expertSelfEvaluationCommitmentLevelTitle: 10,
  expertSelfEvaluationCommitmentLevelFixedDegree: 11,
  needVisit: 12,
  subExpertRequirementAnswers: 13,
  decisionInWeight: 14,
  relatedStandardProof: 15,
  answerUserType: 16,
  actuallyStandardWeight: 17,
  pocOfficerFinalDegree: 18,
  pocOfficerFinalCommitmentLevelTitle: 19,
  pocOfficerFinalCommitmentLevelFixedDegree: 20,
  pocOfficerSelfEvaluationCommitmentLevelTitle: 21,
  pocOfficerSelfEvaluationCommitmentLevelFixedDegree: 22,
};

export const exportColumns = [
  {
    name: 'اسم الجهة',
    value: columnsValues.agencyName,
  },
  {
    name: 'كود الجهة',
    value: columnsValues.agencyCode,
  },
  {
    name: 'كود المعيار',
    value: columnsValues.standardCode,
  },
  {
    name: 'اجابات المتطلبات الخبير',
    value: columnsValues.expertRequirementAnswers,
  },
  {
    name: 'وزن اجابات المتطلبات الخبير',
    value: columnsValues.expertRequirementAnswersWeight,
  },
  {
    name: 'القرار',
    value: columnsValues.decision,
  },
  {
    name: 'الدرجة النهائية للخبير',
    value: columnsValues.expertFinalDegree,
  },
  {
    name: 'مستوى الالتزام النهائي للخبير',
    value: columnsValues.expertFinalCommitmentLevelTitle,
  },
  {
    name: 'الدرجة الثابتة لمستوى الالتزام النهائي للخبير',
    value: columnsValues.expertFinalCommitmentLevelFixedDegree,
  },
  {
    name: 'مستوى الالتزام الذاتي للخبير',
    value: columnsValues.expertSelfEvaluationCommitmentLevelTitle,
  },
  {
    name: 'الدرجة الثابتة لمستوى الالتزام الذاتي للخبير',
    value: columnsValues.expertSelfEvaluationCommitmentLevelFixedDegree,
  },
  {
    name: 'الحاجة للزيارة',
    value: columnsValues.needVisit,
  },
  {
    name: 'اجابات المتطلبات الفرعية',
    value: columnsValues.subExpertRequirementAnswers,
  },
  {
    name: 'المتطلب بالوزن',
    value: columnsValues.decisionInWeight,
  },
  {
    name: 'البرهان المتعلق بالمعيار',
    value: columnsValues.relatedStandardProof,
  },
  {
    name: 'نوع المستخدم الذي قام بالاجابة',
    value: columnsValues.answerUserType,
  },
  {
    name: 'الوزن الفعلي للمعيار',
    value: columnsValues.actuallyStandardWeight,
  },
  {
    name: 'مستوى الالتزام النهائي لضابط الاتصال',
    value: columnsValues.pocOfficerFinalCommitmentLevelTitle,
  },
  {
    name: 'الدرجة الثابتة لمستوى الالتزام النهائي لضابط الاتصال',
    value: columnsValues.pocOfficerFinalCommitmentLevelFixedDegree,
  },
  {
    name: 'مستوى الالتزام الذاتي لضابط الاتصال',
    value: columnsValues.pocOfficerSelfEvaluationCommitmentLevelTitle,
  },
  {
    name: 'الدرجة الثابتة لمستوى الالتزام الذاتي لضابط الاتصال',
    value: columnsValues.pocOfficerSelfEvaluationCommitmentLevelFixedDegree,
  },
];
