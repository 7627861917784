import NewCard from '../../../../components/dashboard/NewCard';
import AgencyRow from '../../../../components/dashboard/Cards/AgencyRow';
import { useHistory } from 'react-router-dom';
import AppLoader from '../../../../components/loader';
import { useGetVisitors } from '../api/useGetVisitors';

function Visitors() {
  const history = useHistory();

  const { visitorsData, isGettingVisitors } = useGetVisitors();

  return (
    <NewCard
      title={'زوار اليوم حسب الجهة'}
      allButton
      handleShowMore={() => history.push(`/visitors/analytics`)}
    >
      <div className=" h-full w-full flex flex-col gap-8 py-2">
        {isGettingVisitors ? (
          <div className="w-full h-full flex justify-center items-center">
            <AppLoader />
          </div>
        ) : !visitorsData?.length ? (
          <p className="text-lg">لا يوجد زوار للنظام هذا اليوم..</p>
        ) : (
          visitorsData?.map((ele) => <AgencyRow key={ele.agencyId} row={ele} />)
        )}
      </div>
    </NewCard>
  );
}

export default Visitors;
