import Skeleton from 'react-skeleton-loader';
import { all } from '../../../../../assets/icons/dashboard';
import SCard from '../../../../../components/dashboard/SCard';
import {
  isExpert,
  isExpertManager,
  isPOCOfficer,
  isReviewer,
} from '../../../../../helpers/isPermission';
import { standardAgenciesCards } from './Role';

function ReviewedStatusCards({ setReviewedStatus, cardsData, loading }) {
  const list = [
    {
      value: 1,
      title: 'الكل',
      number: cardsData?.all,
      icon: all,
      color: '#fcd3d3',
    },
  ];

  if (isExpert || isExpertManager || isReviewer || isPOCOfficer) {
    standardAgenciesCards(cardsData).forEach((element) => {
      list.push(element);
    });
  }

  return (
    <>
      {list?.length ? (
        <div
          className="m-5 pt-5"
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(220px, 1fr))',
            gap: '20px',
          }}
        >
          {list.map((card, index) => (
            <SCard
              cursorPointer
              onClick={() => setReviewedStatus(card.value)}
              key={index}
              icon={card.icon}
              title={
                loading ? (
                  <div className="flex flex-col items-start">
                    <Skeleton
                      width={'50px'}
                      height={'10px'}
                      color={card.color}
                    />
                  </div>
                ) : (
                  card.title
                )
              }
              loading={loading}
              color={card.color}
              number={card.number || 0}
            />
          ))}
        </div>
      ) : null}
    </>
  );
}

export default ReviewedStatusCards;
