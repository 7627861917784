import { programsIcon } from '../../../assets/icons/Index';
import { Button, InputLabel, Title } from '../../../components';
import NewHeader from '../../../layouts/header/NewHeader';
import { useHistory, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import UploadAdapter from '../../../components/UploadAdapter';
import { get, post } from '../../../services/Request';
import environment from '../../../services/environment';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { errorToast } from '../../../helpers/toast';
import AppEditor from '../../../components/form/AppEditor';

const StandardRate = () => {
  const history = useHistory();
  const { id, visitId } = useParams();
  const { t } = useTranslation();
  const [description, setDescription] = useState('');
  const [recommendation, setRecommendation] = useState('');
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const [message, setMessage] = useState({});

  const [degrees, setDegrees] = useState([]);
  const [requirements, setRequirements] = useState([]);
  const [selectedDegree, setSelectedDegree] = useState('');
  const [idsRequirements, setIdsRequirements] = useState([]);
  const [standard, setStandard] = useState('');
  const [currentPhase, setCurrentPhase] = useState('');

  const handleCheckboxDegree = (event) => {
    var updatedAbilities = '';
    if (event.target.checked) {
      updatedAbilities = event.target.value;
    } else {
      updatedAbilities = updatedAbilities.filter(
        (id) => event.target.value != id
      );
    }
    setSelectedDegree(updatedAbilities);
  };

  const isCheckedCheckboxDegree = (id) => {
    return selectedDegree == id;
  };

  const handleCheckboxRequirement = (event) => {
    var updatedAbilities = [...idsRequirements];
    if (event.target.checked) {
      updatedAbilities = [...idsRequirements, event.target.value];
    } else {
      updatedAbilities = updatedAbilities.filter(
        (id) => event.target.value != id
      );
    }

    setIdsRequirements(updatedAbilities.map((i) => Number(i)));
  };

  const isCheckedCheckboxRequirement = (id) => {
    return idsRequirements.map((i) => Number(i)).includes(id);
  };

  useEffect(() => {
    getCurrentProgramPhase();
  }, []);

  const getCurrentProgramPhase = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    get(
      environment.getCurrentSurveyStage +
        '?surveyId=' +
        localStorage.getItem('surveyId'),
      config,
      (res) => {
        if (res.status == 403) {
          toast.error(t('general.authError'), {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        } else if (res.status == 500) {
          toast.error(t('general.serverError'), {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        } else if (res.status == 200) {
          setCurrentPhase(res.data.result, getStandard(res.data.result));
        }
      }
    );
  };

  const getStandard = (programPhase) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    get(environment.getStandardById + '?id=' + id, config, (res) => {
      if (res.status == 403) {
        toast.error(t('general.authError'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      } else if (res.status == 500) {
        toast.error(t('general.serverError'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      } else if (res.status == 200) {
        res.data.result.standardRequirements.forEach((requirement) => {
          requirement.label = requirement.title;
          requirement.value = requirement.id;
        });
        setRequirements(res.data.result.standardRequirements);
        setStandard(res.data.result, getDegrees());
      }
    });
  };

  const getDegrees = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    get(
      environment.getCommitmentLevels + '?standardId=' + id,
      config,
      (res) => {
        if (res.status == 403) {
          toast.error(t('general.authError'), {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        } else if (res.status == 500) {
          toast.error(t('general.serverError'), {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        } else if (res.status == 404) {
          setMessage(
            { type: 'error', message: t('general.serverError') },
            setOpenConfirmModal(true, setLoading(false))
          );
        } else if (res.status == 200) {
          res.data.result.forEach((degree) => {
            degree.label = degree.title;
            degree.value = degree.id;
          });
          setDegrees(res.data.result);
        }
      }
    );
  };

  const addRate = () => {
    if (!description) {
      errorToast('برجاء ادخال ملاحظة');
      return;
    } else if (!recommendation) {
      errorToast('برجاء ادخال توصية');
      return;
    } else if (!selectedDegree.length) {
      errorToast('برجاء اختيار مستوى التزام');
      return;
    } else if (!idsRequirements.length) {
      errorToast('برجاء اختيار متطلب');
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    let arrRequirements = [];
    idsRequirements.forEach((element) => {
      let obj = { standardRequirementId: element };
      arrRequirements.push(obj);
    });
    let data = {
      visitId: visitId,
      standardId: id,
      commitmentLevelId: selectedDegree,
      expertRequirementVisitAnswers: arrRequirements,
      recommendation: recommendation,
      note: description,
      surveyStageId: currentPhase.id,
    };
    post(environment.addVisitAnswer, data, config, (res) => {
      if (res.status == 500) {
        toast.error(res.data.error.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      } else {
        toast.success('تم اضافة تدقيق بنجاح', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    });
  };
  return (
    <div className="bg-white rounded-[30px] m-5">
      <NewHeader />
      <Title
        subTitle={standard.title}
        withoutButton={true}
        iconTitle={programsIcon}
        title={'الزيارات'}
        toPage={() => history.push('/visitsAndMeetings')}
        seconed={true}
      />
      <div className="p-5 bg-[#F9F9FF] rounded-lg m-5">
        <div className="flex flex-row items-center">
          <div className="text-[17px]  text-[#292069]">السؤال المعياري</div>
          <div className="text-[14px] font-semibold text-[#108AE0] mr-2 ml-2">
            {standard.code}
          </div>
        </div>

        <div className="m-2 rounded-2xl p-5 my-5">
          <InputLabel
            checkboxStyle={'flex flex-col pt-5'}
            withoutLabel={false}
            column={true}
            containerStyle={{ marginTop: 0 }}
            handleCheckbox={handleCheckboxDegree}
            isCheckedCheckbox={isCheckedCheckboxDegree}
            label={'مستوى الإلتزام'}
            message={'لا يوجد درجات'}
            type={'checkbox'}
            items={degrees}
          />
        </div>

        <div className="m-2 rounded-2xl p-5 my-5">
          <InputLabel
            checkboxStyle={'flex flex-col pt-5'}
            withoutLabel={false}
            column={true}
            containerStyle={{ marginTop: 0 }}
            handleCheckbox={handleCheckboxRequirement}
            isCheckedCheckbox={isCheckedCheckboxRequirement}
            label={'المتطلبات'}
            message={'لا يوجد متطلبات'}
            type={'checkbox'}
            items={requirements}
          />
        </div>

        <div className="grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 xl:grid-cols-2 px-5">
          <div className="m-2">
            <label className="text-[16px] text-[#292069]">التوصية</label>
            <div className="mt-5">
              <AppEditor
                value={recommendation}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setRecommendation(data);
                  editor.plugins.get('FileRepository').createUploadAdapter =
                    function (loader) {
                      return new UploadAdapter(loader);
                    };
                }}
              />
            </div>
          </div>
          <div>
            <div className="m-2">
              <label className="text-[16px] text-[#292069]">الملاحظة</label>
              <div className="mt-5">
                <AppEditor
                  value={description}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setDescription(data);
                    editor.plugins.get('FileRepository').createUploadAdapter =
                      function (loader) {
                        return new UploadAdapter(loader);
                      };
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="px-5 flex flex-row justify-end">
        <Button
          onClick={() => addRate()}
          loading={loading}
          title="حفظ"
          style={{ marginBottom: '50px', marginTop: '50px', width: '12rem' }}
        />
      </div>
    </div>
  );
};
export default StandardRate;
