import { Form, Formik } from 'formik';

export const NewForm = ({
  children,
  initialValues,
  validate,
  onSubmit,
  innerRef,
  ...rest
}) => {
  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={onSubmit}
      innerRef={innerRef}
    >
      {({ handleChange, values }) => <Form>{children}</Form>}
    </Formik>
  );
};
