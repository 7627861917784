import React from 'react';
import { Modal } from 'flowbite-react';
import AppButton from '../../../../components/buttons/appButton';
import { qiyasLogo } from '../../../../assets/imgs/Index';
import { useHistory } from 'react-router';

const ThanksSubmittingMessage = ({ open, setOpen }) => {
  const history = useHistory();

  return (
    <Modal
      size="lg"
      dismissible
      position={'center'}
      show={open}
      onClose={() => {
        // setOpen(false);
      }}
      className="relative flex items-center justify-center sm:pt-[150px] md:pt-[100px] bg-gray-900 bg-opacity-50"
      theme={{
        content: {
          base: 'relative h-full p-4 md:h-auto',
          inner:
            'md:w-[600px] relative rounded-[43px] bg-white shadow dark:bg-gray-700 flex flex-col max-h-[90vh]',
        },
        body: {
          base: 'p-6 flex-1 overflow-auto',
          popup: 'pt-0',
        },
        header: {
          base: 'flex items-start justify-between rounded-t dark:border-gray-600 border-b p-5',
          popup: 'p-2 border-b-0',
          title: 'text-xl font-medium text-gray-900 dark:text-white',
          close: {
            base: 'ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white',
            icon: 'h-5 w-5',
          },
        },
        footer: {
          base: 'flex items-center space-x-2 rounded-b border-gray-200 p-6 dark:border-gray-600',
          popup: 'border-t',
        },
      }}
    >
      <div className="z-50">
        <Modal.Body className="rounded-[43px] min-h-[300px] max-h-[400px]">
          <div className="bg-white rounded-[43px] p-3 ">
            <div className="flex flex-col items-center justify-center bg-gray-50 text-center p-6">
              <div className="bg-white shadow-md rounded-lg p-8 max-w-md">
                {/* Icon */}
                <div className="flex justify-center mb-3">
                  <img
                    className={
                      'cursor-pointer h-auto ease-in-out duration-300 w-[100px]'
                    }
                    onClick={() => history.push('/')}
                    src={qiyasLogo}
                    alt="Qiyas"
                  />
                </div>
                {/* Text */}
                <h1 className="text-2xl font-bold text-gray-800 mb-4">
                  شكراً لمساهمتك!
                </h1>
                <p className="text-gray-600">
                  تم إرسال استبيانك بنجاح. نقدّر وقتك ومشاركتك القيمة.
                </p>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="flex gap-3 justify-center">
          <AppButton
            onClick={() => {
              setOpen(false);
              history.push('/');
            }}
            variant="primary"
          >
            العودة إلى الصفحة الرئيسية
          </AppButton>
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export default ThanksSubmittingMessage;
