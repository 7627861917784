import { faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import FModal from './FModal';
import NewTooltip from './NewTooltip';

const EditIcon = ({
  loading,
  editFunction,
  editedName,
  editModalContent,
  initialValuesFunction,
  onClose,
  type,
  headerTitle = '',
}) => {
  const [openEdit, setOpenEdit] = useState(false);

  return (
    <>
      <NewTooltip content={'تعديل'}>
        <FontAwesomeIcon
          icon={faPen}
          className="text-yellow-400 bg-orange-100 mr-1 rounded-full p-2 cursor-pointer"
          onClick={() => {
            initialValuesFunction?.();
            setOpenEdit(true);
          }}
        />
      </NewTooltip>

      <FModal
        onClose={onClose}
        submitLabel={'تعديل'}
        headerTitle={
          headerTitle !== '' ? headerTitle : `تحديث بيانات ${editedName}`
        }
        content={editModalContent}
        open={openEdit}
        loading={loading}
        setOpen={() => setOpenEdit(false)}
        action={editFunction}
        type={type}
      />
    </>
  );
};

export default EditIcon;
