import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UploadFile from '../../../../components/UploadFile';
import AppRadio from '../../../../components/form/appRadio';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';

export default function TrueOrFalse({
  question,
  value,
  attachmentId,
  onChange,
  onUploadStart,
  onUploadEnd,
  onAttachmentChange,
  requiredMessage,
  disabled,
}) {
  return (
    <div className="px-5 flex flex-col mb-3 py-2">
      <div className="flex flex-row gap-2 mb-1 py-1">
        <h2 className="text-xl pb-2">
          {question.title}
          {question?.isRequired && (
            <span className="mr-2">
              <FontAwesomeIcon className="w-2 text-red-700" icon={faAsterisk} />
            </span>
          )}
        </h2>
      </div>
      <div className="flex flex-row gap-2 mb-1 py-1">
        <p> {question.description} </p>
      </div>
      <div className="flex flex-row gap-2 mb-2 py-2">
        <AppRadio
          label="نعم"
          value="1"
          checked={value === '1'}
          onChange={(e) => {
            onChange(e.target.value);
          }}
          disabled={disabled}
        />
        <AppRadio
          label="لا"
          value="2"
          checked={value === '2'}
          onChange={(e) => {
            onChange(e.target.value);
          }}
          disabled={disabled}
        />
      </div>

      <div className="">
        {question.hasAttachment && (
          <UploadFile
            onUploadStart={onUploadStart}
            onUploadEnd={onUploadEnd}
            value={attachmentId}
            withOutToolTip
            onChange={onAttachmentChange}
            readOnly={disabled}
          />
        )}
      </div>
      {requiredMessage && (
        <span className="text-red-600 ">{requiredMessage}</span>
      )}
    </div>
  );
}
