import AgenciesFinishedAnswering from './cards/AgenciesFinishedAnswering';
import AgenciesStartedAnswering from './cards/AgenciesStartedAnswering';
import AnsweredStandards from './cards/AnsweredStandards';
import BestFiveVisitors from './cards/BestFiveVisitors';
import FilesNumber from './cards/FilesNumber';
import IncludedAgencies from './cards/IncludedAgencies';
import NewAgencies from './cards/NewAgencies';
import NotStartedAgencies from './cards/NotStartedAgencies';
import StandardsCount from './cards/StandardsCount';
import Visitors from './cards/Visitors';
import BestFiveAgencies from './cards/bestFiveAgencies';
import AssignedToStandard from './cards/AssignedToStandard';
import AllStandards from './cards/AllStandards';

// Define the cards array
const cards = [
  { id: 1, fullWidth: false, component: <IncludedAgencies /> },
  { id: 2, fullWidth: false, component: <Visitors /> },
  { id: 3, fullWidth: true, component: <BestFiveAgencies /> },
  { id: 4, fullWidth: true, component: <BestFiveVisitors /> },
  { id: 5, fullWidth: false, component: <FilesNumber /> },
  { id: 6, fullWidth: false, component: <NewAgencies /> },
  { id: 7, fullWidth: true, component: <AgenciesStartedAnswering /> },
  { id: 8, fullWidth: false, component: <AgenciesFinishedAnswering /> },
  { id: 9, fullWidth: false, component: <NotStartedAgencies /> },
  { id: 10, fullWidth: true, component: <AllStandards /> },
  { id: 11, fullWidth: false, component: <StandardsCount /> },
  { id: 12, fullWidth: false, component: <AnsweredStandards /> },
  { id: 13, fullWidth: true, component: <AssignedToStandard /> },
];

function AdminDashboardCardsPhase1() {
  return (
    <div className="p-5 grid grid-cols-1 lg:grid-cols-2 gap-5">
      {cards.map((card) => (
        <div
          key={card.id}
          className={card.fullWidth ? 'col-span-2' : 'col-span-2 lg:col-span-1'}
        >
          {card.component}
        </div>
      ))}
    </div>
  );
}

export default AdminDashboardCardsPhase1;
