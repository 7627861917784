import { useState } from 'react';
import { DropdownMultiSelect, Input, InputLabel } from '../../../components';
import { useHistory } from 'react-router-dom';
import { post } from '../../../services/Request';
import environment from '../../../services/environment';
import { useTranslation } from 'react-i18next';
import { permissionsCheck } from '../../../helpers/utils';
import { notificationIcon } from '../../../assets/icons/Index';
import { avatar } from '../../../assets/icons/Index';
import NewNotificationCard from '../../../components/notifications/NewNotificationCard';
import NotificationSkeleton from '../../../components/notifications/NotificationSkeleton';
import HeaderWithBreadcrumbLayout from '../../../layouts/headerWithBreadcrumbLayout';
import { useGetNotifications } from './useGetNotification';
import AppPagination from '../../../components/Pagination';
import { useQueryClient } from '@tanstack/react-query';

const Notifications = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [currentPage, setCurrentPage] = useState(0);
  const [dateStyle] = useState({ border: '2px solid #f4f7fe' });
  const [date, setDate] = useState('');

  const [options] = useState([
    { id: 1, label: 'الكل', value: null },
    { id: 2, label: 'المقروءة', value: true },
    { id: 3, label: 'غير مقروءة', value: false },
  ]);

  const [option, setOption] = useState('');

  const handleChangeFilterOption = (value) => {
    setOption(value);
  };

  //LATER
  const { notifications, totalCount, totalPages, isGettingNotifications } =
    useGetNotifications({
      currentPage,
      option,
      date,
    });

  const markAllNoticeAsRead = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    post(environment.setAllNotificationsAsRead, {}, config, (res) => {
      if (res.status == 200) {
        queryClient.invalidateQueries({
          queryKey: ['notifications'],
        });
      }
    });
  };

  const seenNotification = (id) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    let data = {
      id: id,
    };
    // return true
    post(environment.setNotificationAsRead, data, config, (res) => {
      if (res.status == 200) {
        queryClient.invalidateQueries({
          queryKey: ['notifications'],
        });
      }
    });
  };

  const handlePageClick = (event) => {
    const newOffset = event.selected;
    setCurrentPage(newOffset);
  };

  function getTypeText(type) {
    switch (type) {
      case 0:
        return t('عام');
      case 1:
        return t('تحذير');
      case 2:
        return t('معلومة');
      case 3:
        return t('رسالة');
      default:
        return '';
    }
  }

  const notificationBreadcumbs = [
    {
      icon: notificationIcon,
      title: 'الاشعارات',
      url: '/notifications',
    },
  ];

  const handleChangeDate = (e) => {
    setDate(e.target.value);
  };

  return (
    <HeaderWithBreadcrumbLayout
      breadcrumbArr={notificationBreadcumbs}
      btnLabel={'اٍرسال اٍشعار جديد'}
      hideBtn={permissionsCheck('Pages.Notics.Create') ? false : true}
      handleBtnClick={() => history.push('/notifications/NewNotification')}
    >
      <div className="flex items-center justify-between p-5">
        {isGettingNotifications ? (
          <div role="status" className="max-w-sm animate-pulse">
            <div className="h-2.5 bg-slate-400 rounded-full w-48 -mb-2"></div>
            <span className="sr-only">Loading...</span>
          </div>
        ) : (
          <div>
            <p
              className="text-slate-400 text-base"
              style={{
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: 'normal',
                letterSpacing: '0.36px',
              }}
            >
              {totalCount} عناصر
            </p>
          </div>
        )}

        {permissionsCheck('Pages.Notics.Create') && (
          <div className="flex items-center gap-5">
            <div className="flex gap-2 items-center">
              <div className="flex-shrink-0">
                <Input
                  onChange={markAllNoticeAsRead}
                  containerStyle={{ marginTop: 0 }}
                  style={{ marginTop: 0 }}
                  type="checkbox"
                />
              </div>
              <p
                className="text-slate-400 text-base"
                style={{
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: 'normal',
                  letterSpacing: '0.36px',
                }}
              >
                تحديد الكل كمقروءة
              </p>
            </div>
          </div>
        )}
      </div>
      <div className="flex flex-row items-center justify-between">
        <div className="w-[45%]">
          <DropdownMultiSelect
            isSearchable={true}
            value={option}
            handleChange={handleChangeFilterOption}
            placeholder={t('اختر')}
            label={t('اختر')}
            options={options}
            isMultiple={false}
            withoutLabel={true}
            styleButton={{ width: '50%' }}
          />
        </div>
        <div className="w-[45%]">
          <InputLabel
            placeholder={'تاريخ'}
            label={'تاريخ'}
            withoutLabel={true}
            onChange={handleChangeDate}
            value={date}
            type="date"
            style={dateStyle}
          />
        </div>
      </div>
      {permissionsCheck('Pages.Notics.GetAll') ? (
        <div className="relative pt-5">
          <div className="bg-white rounded-xl">
            {isGettingNotifications ? (
              <div className="w-full flex flex-col gap-5">
                {Array.from({ length: 6 }, (_, index) => index).map((item) => (
                  <NotificationSkeleton key={item} />
                ))}
              </div>
            ) : (
              <div className="w-full flex flex-col">
                <ul className="flex flex-col bg-slate-50">
                  {notifications?.map((notify) => (
                    <NewNotificationCard
                      key={notify.id}
                      name={notify.notificationName}
                      body={notify.arabicMessage}
                      profileImage={avatar}
                      type={getTypeText(notify.type)}
                      creationTime={notify.creationTime}
                      attachmentId={notify.attachmentId}
                      attachmentName={notify.attachmentName}
                      standard={notify.standard}
                      agencies={notify.agencies}
                      users={notify.users}
                      seenNotification={() => seenNotification(notify.id)}
                      isRead={notify.isRead}
                    />
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="text-[#bb2124] text-xl text-center flex justify-center items-center">
          {'ليس لديك صلاحية لهذا'}
        </div>
      )}
      {permissionsCheck('Pages.Notics.GetAll') && (
        <div className="flex items-center justify-center p-5">
          <AppPagination
            currentPage={currentPage}
            totalPages={totalPages}
            loading={isGettingNotifications}
            handlePageClick={handlePageClick}
          />
        </div>
      )}
    </HeaderWithBreadcrumbLayout>
  );
};

export default Notifications;
