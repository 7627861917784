import { useParams } from 'react-router-dom';
import answer from '../../../../../../assets/icons/answer.svg';
import SaveBtn from '../components/buttons/saveBtn';
import NextBtn from '../components/buttons/nextBtn';
import { useAnswerContext } from '../answerContext';
import {
  isAgency,
  isExpertManager,
} from '../../../../../../helpers/isPermission';

const StandardFinalAnswer = ({
  currentStage,
  label,
  createAgencyAnswer,
  setMenuElement,
  children,
  hideSaveBtn,
}) => {
  const { standardId, entityId } = useParams();
  const { createAnswerLoading } = useAnswerContext();

  return (
    <div>
      <div className="min-h-[440px] p-3">
        <div className="w-[100%] flex flex-row justify-between items-center">
          <div className="flex flex-row items-center">
            <img className="bg-transparent ml-2" src={answer} alt="answer" />
            <h1 className="text-[#292069] font-black text-[20px]">{label}</h1>
          </div>
        </div>
        <div className="w-[100%] mt-7 pr-[88px] flex flex-col  items-start overflow-y-auto h-[300px] scrollbar scrollbar-thumb-[#292069] scrollbar-track-gray-100">
          {children}
        </div>
      </div>

      {createAgencyAnswer ? (
        !hideSaveBtn && (
          <SaveBtn
            onClick={() =>
              createAgencyAnswer(standardId, entityId, currentStage?.id)
            }
            loading={createAnswerLoading}
          />
        )
      ) : (
        <NextBtn
          onClick={() => {
            isAgency || isExpertManager ? setMenuElement(6) : setMenuElement(5);
          }}
        />
      )}
    </div>
  );
};

export default StandardFinalAnswer;
