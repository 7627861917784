import classNames from 'classnames';
import { IExpertEvaluation } from '../../../types';
import { showRequirement } from '../utils';
import AppLoader from '../../../../../../../components/loader';

const AgencyNoteTemplate = ({
  expertEvaluation,
  hideSubtitle = false,
  fullWidth = false,
  isLoading = false,
}: {
  expertEvaluation: IExpertEvaluation;
  hideSubtitle?: boolean;
  fullWidth?: boolean;
  isLoading?: boolean;
}) => {
  if (isLoading) {
    return (
      <div className="min-h-[200px] flex justify-center items-center">
        <AppLoader />
      </div>
    );
  }

  return (
    <>
      <div
        className={classNames('mb-2', {
          'w-[87%]': !fullWidth,
          'w-full': fullWidth,
        })}
      >
        <div
          className={classNames('mb-6', {
            'w-[87%]': !fullWidth,
            'w-full': fullWidth,
          })}
        >
          <h2 className="text-[#292069] text-lg font-bold mb-1">
            ملاحظات الفريق الوطنى لقياس:
          </h2>
          {expertEvaluation?.noteForAgency?.noteTemplate?.templateContent && (
            <div
              dangerouslySetInnerHTML={{
                __html:
                  expertEvaluation?.noteForAgency?.noteTemplate
                    ?.templateContent,
              }}
            ></div>
          )}
          <div className="mt-2 mb-2">
            {!hideSubtitle && (
              <div className="text-[#202969] font-bold text-[15px]">
                المتطلبات التي لم يتم اختيارها{' '}
              </div>
            )}
          </div>

          <ul className="p-2">
            {expertEvaluation?.expertRequirementAnswers.map((requirement) => (
              <li>
                {showRequirement(requirement)
                  ? requirement.standardRequirementTitle
                  : ''}
                <ul>
                  {requirement.expertSubRequirementAnswers.map(
                    (subRequirement) => (
                      <>
                        {(subRequirement.decision == 0 ||
                          !subRequirement.decision) &&
                        subRequirement?.donotApply == false ? (
                          <li className="mx-10">
                            {subRequirement.subStandardRequirementTitle}
                          </li>
                        ) : (
                          ''
                        )}
                      </>
                    )
                  )}
                </ul>
              </li>
            ))}
          </ul>

          <div
            dangerouslySetInnerHTML={{
              __html: expertEvaluation?.noteForAgency?.content || '',
            }}
          ></div>
          {!expertEvaluation?.noteForAgency?.noteTemplate?.templateContent &&
            !expertEvaluation?.noteForAgency?.content &&
            expertEvaluation?.expertRequirementAnswers?.length === 0 && (
              <div className="">لا يوجد ملاحظات</div>
            )}
        </div>
      </div>
    </>
  );
};

export default AgencyNoteTemplate;
