import { StyleSheet, View } from '@react-pdf/renderer';

export function Break({ marginVertical = 10 }) {
  const styles = StyleSheet.create({
    break: {
      width: '100% ',
      height: '1px',
      backgroundColor: 'gray',
      opacity: 0.5,
      marginVertical: marginVertical,
    },
  });

  return <View style={styles.break}></View>;
}
