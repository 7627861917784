import { useState } from 'react';
import axios from 'axios'; // Assuming you are using Axios

const useFileDownloaderAsZip = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const downloadFileAsZip = async (fileName, fileIds) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/services/app/Attachment/DownloadFilesAsZip?fileName=` +
          fileName,
        fileIds,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            // Add your authorization header if required
          },
          responseType: 'blob', // Important to get binary data
        }
      );

      // Create blob and initiate download
      const blob = new Blob([response.data], {
        type: 'application/octet-stream',
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName + '.zip'; // You can specify the file name here
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return { downloadFileAsZip, loading, error };
};

export default useFileDownloaderAsZip;
