import { EntityContextProvider } from '../../../../../contexts/entityDetailsContext';
import AnswerAnalysis from './answerAnalysis';
const EntityAnswerAnalysis = () => {
  return (
    <EntityContextProvider>
      <AnswerAnalysis />
    </EntityContextProvider>
  );
};

export default EntityAnswerAnalysis;
