import React, { useEffect, useRef, useState } from 'react';
import { pen } from '../../../../../assets/icons/Index';
import { axiosPutRequest, remove } from '../../../../../services/Request';
import environment from '../../../../../services/environment';
import { toast } from 'react-toastify';
import AppModal from '../../../../../components/Models/appModal';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { DropdownMultiSelect, InputLabel } from '../../../../../components';
import DeleteIcon from '../../../../../components/DeleteIcon';
import NewTooltip from '../../../../../components/NewTooltip';
import useAxiosGetRequest from '../../../../../helpers/customHooks/useAxiosGetRequest';
import { NewForm } from '../../../../../components/form/NewForm';
import { ErrorMessage } from 'formik';

const ClassificationCard = ({
  title,
  weight,
  determinants,
  id,
  getAllClassifications,
  handleUpdateData,
  loading,
}) => {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const { surveyId } = useParams();

  // for multiSelect
  const [getAllDeterminants, allDeterminants] = useAxiosGetRequest();

  useEffect(() => {
    getAllDeterminants(environment.getAllDeterminants);
  }, []);

  const allDeterminantsOptions =
    allDeterminants?.result?.items &&
    allDeterminants?.result?.items?.map((item) => {
      return { label: item.title, value: item.id };
    });

  const handleDeterminantsChange = (value) => {
    setSelectedDeterminants(value);
  };

  const [deleteLoading, setDeleteLoading] = useState(false);

  const [selectedDeterminants, setSelectedDeterminants] = useState(
    determinants?.map((ele) => {
      return {
        label: ele.determinantTitle,
        value: ele.determinantId,
      };
    })
  );

  // our data
  const query = selectedDeterminants?.map((ele) => {
    return { determinantId: ele.value };
  });

  const deleteClassification = (cardId) => {
    setDeleteLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    };
    remove(
      environment.deleteClassification + '?id=' + cardId,
      config,
      (res) => {
        if (res.status == 403) {
          setDeleteLoading(false);
          toast.error('حدث خطأ');
        } else if (res.status == 500) {
          setDeleteLoading(false);
          toast.error('حدث خطأ');
        } else if (res.status == 200) {
          setDeleteLoading(false);
          toast.success('تم حذف تصنيف بنجاح');

          handleUpdateData();
        }
      }
    );
  };
  const [inputs, setInputs] = useState({
    title: title || '',
    weight: weight || '',
  });
  const handleInputChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  let body = {
    id: id,
    surveyId: surveyId,
    ...inputs,
    standardRequirementClassificationDeterminants: query,
  };
  const handleEdit = async () => {
    setEditLoading(true);
    const res = await axiosPutRequest(environment.updateClassification, body);
    setEditLoading(false);
    if (res?.success) {
      toast.info(`تم تحديث تصنيف بنجاح`);
      setOpenEditModal(false);
      handleUpdateData();
    } else {
      toast.error('حدث خطأ');
    }
  };

  // --------------------------------------------- //
  const formRef = useRef(null);

  const handleFormik = () => {
    if (formRef.current) {
      formRef.current?.handleSubmit();
    }
  };

  const initialValues = {
    title: '',
    weight: '',
    selectedDeterminants: [],
  };

  const validate = (values) => {
    const errors = {};
    if (!inputs.title) {
      errors.title = 'هذا الحقل مطلوب';
    }
    if (!inputs.weight || inputs.weight < 0 || inputs.weight > 100) {
      errors.weight = 'هذا الحقل لا بد ان يحتوي على رقم من 0 اٍلي 100';
    }
    if (!selectedDeterminants?.length) {
      errors.selectedDeterminants = 'هذا الحقل مطلوب';
    }

    return errors;
  };

  // ----------------------------------------------------- //
  return (
    <div className="bg-white rounded-lg p-5">
      <div className="flex justify-between items-center">
        <div className="flex">
          <div className="flex items-center ml-16">
            <p className="text-blue_text pl-4">التصنيف</p>
            <p className="text-[15px] text-light">{title}</p>
          </div>
          <div className="flex items-center">
            <p className="text-blue_text pl-4">الوزن</p>
            <p className="text-[15px] text-light">{`${weight}`}</p>
          </div>
        </div>
        <div className="flex">
          <NewTooltip content={'تعديل'}>
            <div
              onClick={() => setOpenEditModal(true)}
              className="rounded-full p-1 bg-[#FFF6DC] cursor-pointer mr-2 ml-2"
            >
              <img src={pen} alt="edit icon" />
            </div>
          </NewTooltip>

          <NewTooltip content={'حذف'}>
            <DeleteIcon
              loading={deleteLoading}
              deletedName={`تصنيف ${title}`}
              deleteFunction={() => deleteClassification(id)}
            />
          </NewTooltip>
        </div>
      </div>

      <div className="flex items-center mt-5">
        <p className="text-blue_text pl-4 mb-1">المحددات</p>

        <div className="flex gap-2">
          {determinants?.map((determinant) => (
            <span className="bg-blue rounded-full p-1 px-4 pb-3 block text-white text-sm">
              {determinant.determinantTitle}
            </span>
          ))}
        </div>
      </div>

      <AppModal
        loading={editLoading}
        handleSubmit={handleFormik}
        type={'submit'}
        headerTitle={`تحديث تصنيف ${title}`}
        open={openEditModal}
        setOpen={setOpenEditModal}
        submitLabel={'تحديث'}
      >
        <NewForm
          initialValues={initialValues}
          validate={validate}
          innerRef={formRef}
          onSubmit={handleEdit}
        >
          <div className="">
            <div className="w-full md:flex">
              <div className="m-3 md:w-1/2 ">
                <InputLabel
                  label={'اسم التصنيف'}
                  // placeholder={"ادخل اسم التصنيف"}
                  name="title"
                  value={inputs.title}
                  onChange={handleInputChange}
                />
                <ErrorMessage
                  className="text-red-700"
                  name="title"
                  component="div"
                />
              </div>

              <div className="m-3 md:w-1/2">
                <InputLabel
                  value={inputs.weight}
                  type={'number'}
                  onChange={handleInputChange}
                  name={'weight'}
                  label={'الوزن'}
                  // placeholder={"ادخل الوزن"}
                />
                <ErrorMessage
                  className="text-red-700"
                  name="weight"
                  component="div"
                />
              </div>
            </div>
            <div className="flex items-end">
              <div className="m-3 md:w-2/3">
                <DropdownMultiSelect
                  isClearable={true}
                  withoutLabel={false}
                  isSearchable={true}
                  value={selectedDeterminants}
                  handleChange={handleDeterminantsChange}
                  placeholder=""
                  label="المحدد"
                  options={allDeterminantsOptions}
                  isMultiple={true}
                />
                <ErrorMessage
                  className="text-red-700"
                  name="selectedDeterminants"
                  component="div"
                />
              </div>
            </div>
          </div>
        </NewForm>
      </AppModal>
    </div>
  );
};

export default ClassificationCard;
