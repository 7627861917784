import React from 'react';
import EntityData from './entityData';
import EntityExtraData from './EntityExtraData';

const EntityDetailsContent = ({ agencyDetails }) => {
  return (
    <div className="bg-[#F9F9FF] rounded-[30px] p-5 m-5">
      <EntityData agencyDetails={agencyDetails} />
      <EntityExtraData agencyDetails={agencyDetails} />

      <div className="pt-5 mt-3"></div>

      {/* <EntityAddress address={agencyDetails?.address} />
      <p className="text-lg mb-4 mt-10">الموقع على الخريطة</p>
      <img src={mapPic} alt="map pic" className="w-full" />
      <EntityContacts agencyDetails={agencyDetails} /> */}
    </div>
  );
};

export default EntityDetailsContent;
