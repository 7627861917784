import { Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import Table from '../components/Table';
import { Footer } from '../components/Footer';

export function Page16({
  agencyTitle,
  globalStyles,
  agenciesByClassification,
}) {
  const styles = StyleSheet.create({
    ...globalStyles,
  });

  return (
    <Page style={styles.page} author="test">
      <Text style={styles.sectionTitle}>06. ملحقات </Text>
      <Text style={styles.sectionSecondaryTitle}>
        قائمة الجهات في المجموعة *
      </Text>

      <View
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          backgroundColor: '#ece8f5',
          border: 'solid',
        }}
      >
        <Text
          style={{
            fontSize: 14,
            paddingTop: 8,
            fontWeight: 'bold',
            textAlign: 'right',
            paddingRight: 5,
            backgroundColor: '#fbf9fe',
            color: '#2A2069',
          }}
        >
          {agenciesByClassification?.agencyClassificationName}
        </Text>
        <Table data={agenciesByClassification?.agencyClassificationResults} />
      </View>
      <Text
        style={{
          ...styles.subtitle,
          position: 'absolute',
          bottom: 50,
          right: 50,
          fontSize: 8,
        }}
      >
        {' '}
        * مرتبة حسب نتيجة الجهة.
      </Text>
      <Footer agencyTitle={agencyTitle} />
    </Page>
  );
}
