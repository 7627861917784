import React, { useContext } from 'react';
import AppButton from '../../../../../../../components/buttons/appButton';
import { StageContext } from '../../../../../../../contexts/stageContext';
import { permissionsCheck } from '../../../../../../../helpers/utils';
import { useAnswerContext } from '../../answerContext';
import SaveDraftBtn from './saveDraftBtn';
import { useAtomValue } from 'jotai';
import { uploadingFileAtom } from '../../../../../../../components/upload/uploadFilesContext';

const SaveBtn = ({
  onClick,
  onSaveDraftClick = () => {},
  loading,
  saveDraftLoading = false,
  isExpertAllowedToSendReAnswerRequest = false,
  disableSave = false,
  onEditRequestButtonClick = () => {},
}) => {
  const uploading = useAtomValue(uploadingFileAtom);
  const { stageRoles, currentStage } = useContext(StageContext);
  const { newAgencyAnswer } = useAnswerContext();

  const isThirdStage = currentStage?.stageNumber === 3;
  const isExpertUsers =
    permissionsCheck('Pages.UserType.ExpertManager') ||
    permissionsCheck('Pages.UserType.Expert') ||
    permissionsCheck('Pages.UserType.Reviewer') ||
    permissionsCheck('Pages.UserType.POCOfficer');

  const isExpertUsersExceptExpertManger =
    permissionsCheck('Pages.UserType.Expert') ||
    permissionsCheck('Pages.UserType.Reviewer') ||
    permissionsCheck('Pages.UserType.POCOfficer');

  const isAgencyUsers =
    permissionsCheck('Pages.UserType.POC') ||
    permissionsCheck('Pages.UserType.CIO') ||
    permissionsCheck('Pages.UserType.Delegate');

  const isExpertCanAnswer =
    isExpertUsers && isThirdStage && newAgencyAnswer.isConfidentialAgency;
  const isAgencyCanNotAnswer =
    isAgencyUsers && isThirdStage && newAgencyAnswer.isConfidentialAgency;
  const isSaveButtonDisabled = isExpertCanAnswer
    ? false
    : isAgencyCanNotAnswer
      ? true
      : !stageRoles.includes(localStorage.getItem('userType')) || disableSave;

  return (
    <div className=" sticky bottom-0 left-0 w-full h-16 ">
      {!permissionsCheck('Pages.UserType.Admin') && (
        <div className="bg-white_gred h-full flex justify-between  items-start pl-8">
          {isSaveButtonDisabled && (
            <p className="text-danger text-lg mt-3 pr-5">
              لا يمكنك الاجابة فى هذه المرحلة
            </p>
          )}
          <div className="mr-auto flex gap-3">
            {isExpertUsersExceptExpertManger ? (
              <>
                {isExpertAllowedToSendReAnswerRequest ? (
                  <AppButton
                    className="text-xl font-semibold px-12"
                    onClick={onEditRequestButtonClick}
                  >
                    طلب تعديل
                  </AppButton>
                ) : (
                  <>
                    <SaveDraftBtn
                      onClick={onSaveDraftClick}
                      loading={saveDraftLoading}
                      isDisabled={isSaveButtonDisabled}
                    />
                    <AppButton
                      disabled={isSaveButtonDisabled}
                      className="text-xl font-semibold px-12"
                      onClick={onClick}
                      loading={loading || uploading}
                      loadingLabel={
                        uploading ? 'جاري رفع الملفات...' : 'جاري الحفظ...'
                      }
                    >
                      حفظ
                    </AppButton>
                  </>
                )}
              </>
            ) : (
              <AppButton
                disabled={isSaveButtonDisabled}
                className="text-xl font-semibold px-12"
                onClick={onClick}
                loading={loading || uploading}
                loadingLabel={
                  uploading ? 'جاري رفع الملفات...' : 'جاري الحفظ...'
                }
              >
                حفظ
              </AppButton>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SaveBtn;
