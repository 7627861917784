import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { useParams } from 'react-router';
import AppCheckbox from '../../../../../../../components/form/appCheckbox';
import { StageContext } from '../../../../../../../contexts/stageContext';
import { axiosGetRequest } from '../../../../../../../services/Request';
import environment from '../../../../../../../services/environment';
import { useAnswerContext } from '../../answerContext';
import StandardFinalAnswer from '../../standardAgencyAnswer';

const ExpertFinalAnswerPhase4ShowingPhase2 = ({
  setMenuElement,
  expertAnswerOnPhase2,
}) => {
  const { currentStage } = useContext(StageContext);
  const { newExpertAnswer } = useAnswerContext();
  const { standardId } = useParams();

  const { data: degreesResult } = useQuery({
    queryKey: ['getCommitmentLevels', standardId],
    queryFn: () =>
      axiosGetRequest(environment.getCommitmentLevels, {
        standardId: standardId,
      }),
  });

  const checkedExpertDegree =
    degreesResult?.result?.filter(
      (item) =>
        item?.id ==
        expertAnswerOnPhase2?.result?.surveyExpertAnswer?.finalCommitmentLevel
          ?.id
    )?.[0] || null;

  const renderedDegrees = newExpertAnswer?.surveyDegrees?.map((item) => (
    <AppCheckbox
      key={item?.value}
      id={item.value}
      name={item.label}
      label={item.label}
      value={item.value}
      checked={checkedExpertDegree?.id === item?.id}
      disabled
    />
  ));

  return (
    <StandardFinalAnswer
      setMenuElement={setMenuElement}
      currentStage={currentStage}
      createReviewerAnswer={() => {}}
      label="إجابة الخبير"
    >
      <div className="flex w-full flex-wrap">
        <div className="shrink-0 max-w-[160px]">{renderedDegrees}</div>
      </div>
    </StandardFinalAnswer>
  );
};

export default ExpertFinalAnswerPhase4ShowingPhase2;
