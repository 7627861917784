import { isAdmin } from '../../../../helpers/isPermission';
import { jsonParse } from '../../../../helpers/utils';
import useQueryParams from '../../../../hooks/useQueryParams';

export function SurveyRateAnswerFormHeader({
  numOfQuestions,
  numOfAnsweredQuestions,
  inActive,
}) {
  const searchParams = useQueryParams();
  const isPreview = jsonParse(searchParams.get('isPreview'));

  return (
    <div className="flex justify-end font-bold text-xl">
      <span className="bold">
        {inActive && !isAdmin
          ? 'عذراً، انتهت فترة الإجابة على هذا الاستبيان.'
          : isPreview
            ? 'وضع المعاينة'
            : `${numOfQuestions} / ${numOfAnsweredQuestions}`}
      </span>
    </div>
  );
}
