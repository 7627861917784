import { Spinner } from 'flowbite-react';
import './Component.css';
import { useTranslation } from 'react-i18next';

const Button = ({
  title,
  onClick,
  style,
  typeColor,
  withIcon,
  icon,
  styleText,
  target,
  toggle,
  type,
  loading,
  className,
  classNameText,
  disabled,
}) => {
  const { t } = useTranslation();
  return (
    <div>
      <button
        type={type}
        data-modal-target={target}
        data-modal-toggle={toggle}
        style={style}
        onClick={onClick}
        disabled={disabled}
        className="btn border border-[#2b2969] w-[100%] p-[12px] relative z-0 inline-flex items-center justify-start overflow-hidden transition-all bg-[#2B2969] rounded-[20px] hover:border-[#21A096] hover:bg-red group"
      >
        <span
          className={`w-0 h-0 text-white rounded group-hover:border-[#21A096] absolute top-0 left-0 ease-out duration-500 transition-all group-hover:w-full group-hover:h-full -z-1`}
        ></span>
        <span className="w-full text-white transition-colors duration-300 ease-in-out group-hover:text-white z-10">
          {loading ? (
            <div className="flex flex-row justify-center items-center">
              <Spinner aria-label="Info spinner example" color="info" />
              <span className="pl-3 pr-3 text-sm text-white">
                {t('general.loading')}
              </span>
            </div>
          ) : (
            <div className="flex flex-row items-center justify-around">
              {withIcon ? <img src={icon} alt="" /> : null}
              <span className={'text-white mb-1 group-hover:text-white'}>
                {title}
              </span>
            </div>
          )}
        </span>
      </button>
    </div>
  );
};

export default Button;
