import AnswerDegreeItem from './answerDegreeItem';
import { useAnswerContext } from '../answerContext';
import {
  isAdmin,
  isInquiryManager,
} from '../../../../../../helpers/isPermission';
import {
  axiosGetRequest,
  axiosPostRequest,
} from '../../../../../../services/Request';
import environment from '../../../../../../services/environment';
import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { useParams } from 'react-router';
import { StageContext } from '../../../../../../contexts/stageContext';

const ExpertManagerAnswerDegrees = () => {
  const { newExpertManagerAnswer, expertManagerDegree } = useAnswerContext();
  const { entityId, standardId, programId } = useParams();
  const { newAgencyAnswer } = useAnswerContext();
  const { currentStage } = useContext(StageContext);
  const isForthStage = currentStage?.stageNumber === 4;
  const isFifthStage = currentStage?.stageNumber === 5;
  const phase2StageId = currentStage?.stageIds?.[1];
  const isConfidentialAgency = newAgencyAnswer.isConfidentialAgency;

  const { data: phaseOneAgencyAnswer } = useQuery({
    queryKey: ['prepareStandardForAnswer', standardId, entityId],
    queryFn: ({ signal }) =>
      axiosPostRequest(
        environment.prepareStandardForAnswer,
        {
          StandardId: standardId,
          AgencyId: entityId,
          StageId: currentStage?.stageIds?.[0],
        },
        null,
        true,
        true,
        false,
        signal
      ),
    enabled: !!isForthStage,
  });

  const { data: expertMangerAnswerOnPhase2 } = useQuery({
    queryKey: ['getExpertManagerAnswer', entityId, phase2StageId, standardId],
    queryFn: () =>
      axiosGetRequest(environment.getExpertManagerAnswer, {
        agencyId: entityId,
        stageId: phase2StageId,
        standardId: standardId,
        expertId: localStorage.getItem('userId'),
      }),
    enabled: !!phase2StageId,
  });

  const { data: standardRequirementsPhaseThree } = useQuery({
    queryKey: [
      environment.getStandardWithRequirement,
      currentStage?.displayOrder,
      entityId,
      standardId,
      programId,
    ],
    queryFn: ({ signal }) =>
      axiosGetRequest(
        environment.getStandardWithRequirement,
        {
          standardId,
          agencyId: entityId,
          stageId: currentStage?.stageIds?.[2],
          surveyId: programId,
        },
        undefined,
        signal
      ),
    enabled: !!currentStage?.stageIds?.[2],
  });

  const isNotReopenedStander = !!(
    standardRequirementsPhaseThree?.result?.standardRequirements?.length === 0
  );

  const disableSecondAuditPhase = isNotReopenedStander && !isConfidentialAgency;

  if ((isFifthStage || isForthStage) && !disableSecondAuditPhase) {
    return (
      <div className="bg-[#F9F9FF] p-3 mr-3 rounded-b-[12px] mb-3">
        <div className="bg-white p-3 px-5 flex flex-wrap">
          <AnswerDegreeItem
            degree={
              phaseOneAgencyAnswer?.result?.surveyAgencyAnswer
                ?.finalCommitmentLevel?.title || 'لا يوجد'
            }
            circleColor={
              phaseOneAgencyAnswer?.result?.surveyAgencyAnswer
                ?.finalCommitmentLevel?.color || '#fff'
            }
            title="إجابة الجهة للمرحلة الأولى"
          />
          <AnswerDegreeItem
            degree={
              expertMangerAnswerOnPhase2?.result?.lastAnswer
                ?.reviewerFinalCommitmentLevel?.title || 'لا يوجد'
            }
            circleColor={
              expertMangerAnswerOnPhase2?.result?.lastAnswer
                ?.reviewerFinalCommitmentLevel?.color || '#fff'
            }
            title="إجابة المدقق لمرحلة التحقق الأولى"
          />
          <AnswerDegreeItem
            degree={
              expertMangerAnswerOnPhase2?.result?.lastAnswer
                ?.pocOfficerFinalCommitmentLevel?.title || 'لا يوجد'
            }
            circleColor={
              expertMangerAnswerOnPhase2?.result?.lastAnswer
                ?.pocOfficerFinalCommitmentLevel?.color || '#fff'
            }
            title="إجابة ضابط الاتصال لمرحلة التحقق الأولى"
          />

          <AnswerDegreeItem
            degree={
              expertMangerAnswerOnPhase2?.result?.lastAnswer
                ?.expertFinalCommitmentLevel?.title || 'لا يوجد'
            }
            circleColor={
              expertMangerAnswerOnPhase2?.result?.lastAnswer
                ?.expertFinalCommitmentLevel?.color || '#fff'
            }
            title="إجابة الخبير لمرحلة التحقق الأولى"
          />
          <AnswerDegreeItem
            degree={
              expertMangerAnswerOnPhase2?.result?.lastAnswer
                ?.expertMangerFinalCommitmentLevel?.title || 'لا يوجد'
            }
            circleColor={
              expertMangerAnswerOnPhase2?.result?.lastAnswer
                ?.expertMangerFinalCommitmentLevel?.color || '#fff'
            }
            title="إجابة رئيس الخبراء لمرحلة التحقق الأولى"
          />
        </div>
        <div className="bg-[#F9F9FF] rounded-b-[12px] mb-3">
          <div className="bg-white p-3 px-5 flex flex-wrap">
            <AnswerDegreeItem
              degree={
                newExpertManagerAnswer?.agencyFinalCommitmentLevel?.title ||
                'لا يوجد'
              }
              circleColor={
                newExpertManagerAnswer?.agencyFinalCommitmentLevel?.color ||
                '#fff'
              }
              title="إجابة الجهة  لمرحلة إعادة فتح النظام"
            />
            <AnswerDegreeItem
              degree={
                newExpertManagerAnswer?.reviewerFinalCommitmentLevel?.title ||
                'لا يوجد'
              }
              circleColor={
                newExpertManagerAnswer?.reviewerFinalCommitmentLevel?.color ||
                '#fff'
              }
              title="إجابة المدقق لمرحلة التحقق النهائي"
            />
            <AnswerDegreeItem
              degree={
                newExpertManagerAnswer?.pocOfficerFinalCommitmentLevel?.title ||
                'لا يوجد'
              }
              circleColor={
                newExpertManagerAnswer?.pocOfficerFinalCommitmentLevel?.color ||
                '#fff'
              }
              title="إجابة ضابط الاتصال لمرحلة التحقق النهائي"
            />
            <AnswerDegreeItem
              degree={
                newExpertManagerAnswer?.expertFinalCommitmentLevel?.title ||
                'لا يوجد'
              }
              circleColor={
                newExpertManagerAnswer?.expertFinalCommitmentLevel?.color ||
                '#fff'
              }
              title="إجابة الخبير لمرحلة التحقق النهائي"
            />
            <AnswerDegreeItem
              degree={
                newExpertManagerAnswer?.checkedExpertManagerDegree?.title ||
                'لا يوجد'
              }
              circleColor={
                newExpertManagerAnswer?.checkedExpertManagerDegree?.color ||
                '#fff'
              }
              title="إجابة رئيس الخبراء لمرحلة التحقق النهائي"
            />
            {!isAdmin && !isInquiryManager && (
              <AnswerDegreeItem
                degree={
                  expertManagerDegree
                    ?.expertManagerSelfEvaluationCommitmentLevel?.title ||
                  'لا يوجد'
                }
                circleColor={
                  expertManagerDegree
                    ?.expertManagerSelfEvaluationCommitmentLevel?.color ||
                  '#fff'
                }
                title="الدرجة المقترحة من النظام"
              />
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-[#F9F9FF] p-3 mr-3 rounded-[12px] mb-3">
      <div className="bg-white p-3 px-5 flex flex-wrap rounded-[9px]">
        <AnswerDegreeItem
          degree={
            newExpertManagerAnswer?.agencyFinalCommitmentLevel?.title ||
            'لا يوجد'
          }
          circleColor={
            newExpertManagerAnswer?.agencyFinalCommitmentLevel?.color || '#fff'
          }
          title="إجابة الجهة للمرحلة الأولى"
        />
        <AnswerDegreeItem
          degree={
            newExpertManagerAnswer?.reviewerFinalCommitmentLevel?.title ||
            'لا يوجد'
          }
          circleColor={
            newExpertManagerAnswer?.reviewerFinalCommitmentLevel?.color ||
            '#fff'
          }
          title="إجابة المدقق لمرحلة التحقق  الأولى"
        />
        <AnswerDegreeItem
          degree={
            newExpertManagerAnswer?.pocOfficerFinalCommitmentLevel?.title ||
            'لا يوجد'
          }
          circleColor={
            newExpertManagerAnswer?.pocOfficerFinalCommitmentLevel?.color ||
            '#fff'
          }
          title="إجابة ضابط الاتصال لمرحلة التحقق  الأولى"
        />
        <AnswerDegreeItem
          degree={
            newExpertManagerAnswer?.expertFinalCommitmentLevel?.title ||
            'لا يوجد'
          }
          circleColor={
            newExpertManagerAnswer?.expertFinalCommitmentLevel?.color || '#fff'
          }
          title="إجابة الخبير لمرحلة التحقق  الأولى"
        />
        <AnswerDegreeItem
          degree={
            newExpertManagerAnswer?.checkedExpertManagerDegree?.title ||
            'لا يوجد'
          }
          circleColor={
            newExpertManagerAnswer?.checkedExpertManagerDegree?.color || '#fff'
          }
          title="إجابة رئيس الخبراء لمرحلة التحقق  الأولى"
        />
        {!isAdmin && !isInquiryManager && (
          <AnswerDegreeItem
            degree={
              expertManagerDegree?.expertManagerSelfEvaluationCommitmentLevel
                ?.title || 'لا يوجد'
            }
            circleColor={
              expertManagerDegree?.expertManagerSelfEvaluationCommitmentLevel
                ?.color || '#fff'
            }
            title="الدرجة المقترحة من النظام"
          />
        )}
      </div>
    </div>
  );
};

export default ExpertManagerAnswerDegrees;
